import { Card } from "components/Card";
import { EmailLink } from "components/ContactUs/ContactTypes";
import { OptionsList } from "components/OptionCard";
import React from "react";
import { Helmet } from "react-helmet";
import css from "../styles.module.css";

export function IgnoredUser() {
  return (
    <>
      <Helmet>
        <title>Get back on the path to student loan debt relief</title>
      </Helmet>

      <div className={css.container}>
        <Card className={css.infoCard}>
          <h3>Welcome back</h3>
          <p>
            It looks like we had some trouble with your assessment. Before we
            can get started again, we&rsquo;d like you to reach out to our team
            to make sure you get all the help you need to be successful with
            Lexria.
          </p>
          <OptionsList>
            <EmailLink />
          </OptionsList>
        </Card>
      </div>
    </>
  );
}
