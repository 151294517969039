import { Loading } from "components/Loading";
import { Wizard, WizardStepProps } from "components/Wizard";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { ErrorTranslationBackends, useLatestResult } from "utils/hooks";
import { Schema } from "yup";

interface GatheringStep<Data, Context> {
  testHasRequiredData:
    | Schema<any>
    | ((value: Data, context: Context) => boolean | Promise<boolean>);
  step: {
    new (value: Data, context?: Context): WizardStepProps<any, any>;
  };
}

interface GatherMissingDataWizardProps<Data, Context> {
  object: Data;
  context: Context;
  nextURL: string;
  backURL: string;
  gatheringSteps: GatheringStep<Data, Context>[];
  onSave?: (formState: any) => Promise<any>;
  subtitle?: React.ReactNode;
  doNotCacheObject?: boolean;
}

function InnerGatherMissingDataWizard<Data, Context>({
  object,
  context,
  nextURL,
  backURL,
  gatheringSteps,
  onSave = () => null,
  subtitle,
  doNotCacheObject = false,
}: GatherMissingDataWizardProps<Data, Context>) {
  // Cache the object by default, so that even after
  // we update it the user can go back and forth
  // between the different data gathering steps.
  // Careful with the step side effects here!
  const [cachedObject, setCachedObject] = React.useState(object);
  const [steps, setSteps] = React.useState(null);
  const [enqueue] = useLatestResult();

  React.useEffect(() => {
    if (doNotCacheObject) {
      setCachedObject(object);
    }
  }, [doNotCacheObject, object]);

  React.useEffect(() => {
    const computeSteps = async (value: Data, ctx: Context) => {
      const applicableSteps = [];
      for (const { testHasRequiredData, step } of gatheringSteps) {
        let hasRequiredData = false;
        if (typeof testHasRequiredData === "function") {
          hasRequiredData = await testHasRequiredData(value, ctx);
        } else {
          hasRequiredData = await testHasRequiredData.isValid(value);
        }

        if (!hasRequiredData) {
          applicableSteps.push(new step(value, ctx));
        }
      }
      return applicableSteps;
    };

    enqueue(computeSteps(cachedObject, context), setSteps);
  }, [gatheringSteps, cachedObject, context]);

  if (steps === null) {
    return <Loading />;
  }

  if (!steps.length) {
    return <Redirect to={backURL} />;
  }

  return (
    <>
      {subtitle && <h3 style={{ marginBottom: "0.5em" }}>{subtitle}</h3>}
      <Wizard
        steps={steps as any}
        nextURL={nextURL}
        backURL={backURL}
        onSave={onSave}
        errorTranslationBackend={ErrorTranslationBackends.GraphQL}
      />
    </>
  );
}

interface PublicGatherMissingDataWizardProps<Data, Context>
  extends Omit<GatherMissingDataWizardProps<Data, Context>, "object"> {
  object: Data | Promise<Data>;
}

export function GatherMissingDataWizard<Data, Context>(
  props: PublicGatherMissingDataWizardProps<Data, Context>
) {
  const [object, setObject] = React.useState(
    props.object instanceof Promise ? null : props.object
  );
  const [enqueue] = useLatestResult();

  if (props.object instanceof Promise) {
    enqueue(props.object, setObject);
  }

  if (object === null) return <Loading />;

  return <InnerGatherMissingDataWizard {...props} object={object} />;
}
