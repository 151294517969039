import DollarsInput from "components/DollarsInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";

export default function Employees({ navProps }: WizardRouteChildProps) {
  return (
    <>
      <p>In the average month, how much does this company spend on:</p>

      <div className="form-row">
        <DollarsInput
          name="monthlyEmployeePayroll"
          label="Net employee payroll"
          autoFocus
        />
        <DollarsInput
          name="monthlyEmployeeBenefits"
          label="Employee benefits"
          helpText="e.g. pension, 401(k), and insurance"
        />
      </div>

      <div className="form-row">
        <DollarsInput
          name="monthlyWorkersCompensation"
          label="Worker’s compensation"
        />
        <DollarsInput
          name="monthlyProfessionalFees"
          label="External professional services"
          helpText="e.g. legal & accounting services"
        />
      </div>

      <WizardNavigation {...navProps} />
    </>
  );
}
