import { InputRow } from "components/InputRow";
import { Select } from "components/Select";
import { setMonth, setYear, startOfToday } from "date-fns";
import * as React from "react";
import { NavbarElementProps } from "react-day-picker";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { months } from "utils/constants";
import styles from "./DateInput.module.scss";

interface DateInputNavProps extends NavbarElementProps {
  onChange: (newMonth: Date) => any;
}

export function DateInputNav({
  month: date,
  onPreviousClick,
  onNextClick,
  onChange,
}: DateInputNavProps) {
  const onMonthSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(setMonth(date, parseInt(e.target.value)));
  };
  const onYearSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(setYear(date, parseInt(e.target.value)));
  };
  const today = startOfToday();

  return (
    <div className={styles.daypickerCaption}>
      <button
        onClick={() => onPreviousClick()}
        className="btn flat icon"
        title="Previous month"
      >
        <FaChevronLeft />
      </button>
      <InputRow style={{ margin: 0 }} className={styles.inputRow}>
        <Select
          width="120px"
          emptyOption={false}
          value={date.getMonth().toString()}
          placeholder="Month"
          onChange={onMonthSelect}
          options={months.map((month, i) => ({
            label: month,
            value: i.toString(),
          }))}
        />
        <Select
          width="80px"
          options={[...Array(100).keys()].map((i) => {
            const year = today.getFullYear() - i;
            return {
              label: year.toString(),
              value: year.toString(),
            };
          })}
          placeholder="Year"
          emptyOption={false}
          value={date.getFullYear().toString()}
          onChange={onYearSelect}
        >
          {[...Array(100).keys()].map((i) => {
            const year = today.getFullYear() - i;
            return (
              <option key={i} value={year.toString()}>
                {year}
              </option>
            );
          })}
        </Select>
      </InputRow>
      <button
        onClick={() => onNextClick()}
        className="btn flat icon"
        title="Next month"
      >
        <FaChevronRight />
      </button>
    </div>
  );
}
