import React, { ReactNode } from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import { formatFancyNumber } from "utils/formatters";
import { parseFancyNumber } from "utils/validators";
import css from "./OwnedBusinesses.module.scss";

interface Props {
  business: FullOwnedBusiness;
}

const personnelFields = [
  "monthlyEmployeePayroll",
  "monthlyEmployeeBenefits",
  "monthlyWorkersCompensation",
  "monthlyProfessionalFees",
] as const;

const facilitiesFields = [
  "monthlyRent",
  "monthlyUtilities",
  "monthlyOfficeExpenses",
  "monthlyRepairsMaintenance",
] as const;

const expenditureFields = [
  "monthlyInventoryPurchasesCost",
  "monthlyTravelEntertainment",
  "monthlyVehicleExpenses",
  "monthlyFarmSuppliesCost",
  "monthlyEquipmentRental",
] as const;

const taxesFields = [
  "monthlyPayrollTaxes",
  "monthlyUnemploymentTaxes",
  "monthlyOtherTaxes",
] as const;

const otherFields = [
  "monthlyDebtPayments",
  "monthlyInsurance",
  "monthlyOtherExpenses",
] as const;

export const getBusinessesCashFlow = (
  businesses: FullOwnedBusiness[]
): ReactNode => {
  const monthlyExpenses = fancyNumberSum(
    businesses,
    ...personnelFields,
    ...facilitiesFields,
    ...expenditureFields,
    ...taxesFields,
    ...otherFields
  );
  const monthlyIncome = fancyNumberSum(
    businesses,
    "expectedGrossMonthlyIncome"
  );

  if (
    typeof monthlyExpenses === "undefined" ||
    typeof monthlyIncome === "undefined"
  ) {
    return undefined;
  }

  const cashFlow = formatFancyNumber(
    parseFancyNumber(monthlyIncome) - parseFancyNumber(monthlyExpenses)
  );
  return (
    <span
      style={{ color: cashFlow.startsWith("-") ? "var(--red)" : undefined }}
    >
      ${cashFlow.replace(/^-/, "")}
    </span>
  );
};

export default function BusinessExpensesSummary({ business }: Props) {
  return (
    <table className={css.businessTable}>
      <tbody>
        <tr>
          <th>Personnel</th>
          <td>${fancyNumberSum([business], 2, ...personnelFields)}</td>
        </tr>

        <tr>
          <th>Facilities</th>
          <td>${fancyNumberSum([business], 2, ...facilitiesFields)}</td>
        </tr>

        <tr>
          <th>Expenditures</th>
          <td>${fancyNumberSum([business], 2, ...expenditureFields)}</td>
        </tr>

        <tr>
          <th>Taxes</th>
          <td>${fancyNumberSum([business], 2, ...taxesFields)}</td>
        </tr>

        <tr>
          <th>Other</th>
          <td>${fancyNumberSum([business], 2, ...otherFields)}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr className={css.summaryRow}>
          <th>Monthly cash flow</th>
          <td>{getBusinessesCashFlow([business])}</td>
        </tr>
      </tfoot>
    </table>
  );
}
