import { useQuery } from "@apollo/client";
import { HiddenInput } from "components/HiddenInput";
import { Loading } from "components/Loading";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import { motion } from "framer-motion";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useNested } from "utils/hooks";
import { array, boolean, object } from "yup";
import { AddSpouse } from "./AddSpouse";
import { CoownersBreakdown } from "./CoownersBreakdown";
import GET_COOWNABLE from "./graphql/GetCoownable.gql";
import { HasMarkedNoCoowners } from "./HasMarkedNoCoowners";
import { Query } from "./Query";

export const CoOwnersSchema = object({
  coowners: array()
    .when("hasNoCoowners", {
      is: true,
      then: array().min(0),
      otherwise: array().min(
        1,
        "You haven’t indicated that this asset has no co-owners or added any co-owners."
      ),
    })
    .defined(),
  hasNoCoowners: boolean().required(),
}).required();

export interface CoownersWizardStepProps extends WizardRouteChildProps {
  relatedId: string;
  relatedTypename: string;
  displayName: string;
  knownOwners?: string[];
  registerOnSave: RegisterOnSaveType;
}

export default function CoownersWizardStep({
  navProps,
  relatedId,
  relatedTypename,
  displayName,
  knownOwners,
  registerOnSave,
}: CoownersWizardStepProps) {
  const { url, path } = useNested();

  const { data, loading } = useQuery<GetCoownable, GetCoownableVariables>(
    GET_COOWNABLE,
    {
      variables: { id: relatedId, typename: relatedTypename },
    }
  );

  const emptyCoowners = React.useMemo(() => [true], []);

  if (loading) return <Loading />;

  const { coowners, hasNoCoowners, currentValue, ownedValue } = data.coownable;
  const spouse = data.familyProfile?.spouse;
  const hasNoSpouse = data.familyProfile?.maritalStatus == "UNMARRIED";

  return (
    <div>
      <Switch>
        <Route
          path={path("/none")}
          render={() => (
            <>
              <HiddenInput name="hasNoCoowners" value={true} />
              <HiddenInput name="coowners" value={emptyCoowners} />
              <HasMarkedNoCoowners
                displayName={displayName}
                registerOnSave={registerOnSave}
              />
            </>
          )}
        />
        <Route
          path={path("/query")}
          render={() => (
            <>
              <HiddenInput name="hasNoCoowners" value={hasNoCoowners} />
              <Query
                displayName={displayName}
                relatedId={relatedId}
                relatedTypename={relatedTypename}
                hasNoSpouse={hasNoSpouse}
                spouse={spouse}
                knownOwners={knownOwners}
                addCoownerURL={url("/all/add_co_owner")}
                addSpouseURL={url("/add_spouse")}
                noCoownersURL={url("/none")}
              />
            </>
          )}
        />
        <Route
          path={path("/add_spouse")}
          render={() => (
            <>
              <HiddenInput name="hasNoCoowners" value={hasNoCoowners} />
              <AddSpouse
                displayName={displayName}
                relatedId={relatedId}
                relatedTypename={relatedTypename}
                spouse={spouse}
                currentValue={currentValue}
                addCoownerURL={url("/all/add_co_owner")}
                listURL={url("/all")}
              />
            </>
          )}
        />
        <Route
          path={path("/all")}
          render={() => (
            <>
              <HiddenInput name="hasNoCoowners" value={false} />
              <HiddenInput name="coowners" value={emptyCoowners} />
              <CoownersBreakdown
                displayName={displayName}
                relatedId={relatedId}
                relatedTypename={relatedTypename}
                currentValue={currentValue}
                ownedValue={ownedValue}
                coowners={coowners}
                filingPerson={data.filingPerson}
                registerOnSave={registerOnSave}
                noCoownersURL={url("/none")}
              />
            </>
          )}
        />
        <Route
          path={path("/")}
          render={() => {
            if (hasNoCoowners) return <Redirect to={url("/none")} />;
            if (!coowners.length) return <Redirect to={url("/query")} />;
            return <Redirect to={url("/all")} />;
          }}
        />
        <Route key="fallback">
          <Redirect to={url("/")} />
        </Route>
      </Switch>
      <motion.div positionTransition>
        <WizardNavigation {...navProps} />
      </motion.div>
    </div>
  );
}

export type RegisterOnSaveType = (onSave: () => Promise<any>) => void;

export function useDelegatedOnSave() {
  const onSaveRef = React.useRef(null);
  const registerOnSave: RegisterOnSaveType = React.useCallback(
    (onSave: () => Promise<any>) => {
      onSaveRef.current = onSave;
    },
    []
  );
  const onSave = React.useCallback(async () => {
    return onSaveRef?.current?.();
  }, [onSaveRef]);

  return [registerOnSave, onSave] as [typeof registerOnSave, typeof onSave];
}
