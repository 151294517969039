
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LegalMattersProgress"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Query"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SeizuresProgress"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"PreviousNamesProgress"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LawsuitsProgress"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CleanupProgress"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConsultationsProgress"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"TaxReturnsProgress"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BankruptcyCasesProgress"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CofilerProgress"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"PhotoIdProgress"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"TaxpayerIdProgress"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CreditCounselingProgress"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"MiscDocumentsProgress"},"directives":[]}]}}],"loc":{"start":0,"end":986}};
    doc.loc.source = {"body":"#import \"./Seizures/SeizuresProgress.gql\"\n#import \"./PreviousNames/PreviousNamesProgress.gql\"\n#import \"./Lawsuits/LawsuitsProgress.gql\"\n#import \"./EnvironmentalCleanupNotices/CleanupProgress.gql\"\n#import \"./Consultations/ConsultationsProgress.gql\"\n#import \"./Cofiler/CofilerProgress.gql\"\n#import \"components/FormModules/TaxReturns/TaxReturnsProgress.gql\"\n#import \"components/FormModules/BankruptcyCases/BankruptcyCasesProgress.gql\"\n#import \"./PhotoIdDocuments/PhotoIdProgress.gql\"\n#import \"./TaxpayerIdDocuments/TaxpayerIdProgress.gql\"\n#import \"./CreditCounseling/CreditCounselingProgress.gql\"\n#import \"./MiscDocuments/MiscDocumentsProgress.gql\"\n\n\nfragment LegalMattersProgress on Query {\n  ...SeizuresProgress\n  ...PreviousNamesProgress\n  ...LawsuitsProgress\n  ...CleanupProgress\n  ...ConsultationsProgress\n  ...TaxReturnsProgress\n  ...BankruptcyCasesProgress\n  ...CofilerProgress\n  ...PhotoIdProgress\n  ...TaxpayerIdProgress\n  ...CreditCounselingProgress\n  ...MiscDocumentsProgress\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./Seizures/SeizuresProgress.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./PreviousNames/PreviousNamesProgress.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./Lawsuits/LawsuitsProgress.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./EnvironmentalCleanupNotices/CleanupProgress.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./Consultations/ConsultationsProgress.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./Cofiler/CofilerProgress.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("components/FormModules/TaxReturns/TaxReturnsProgress.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("components/FormModules/BankruptcyCases/BankruptcyCasesProgress.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./PhotoIdDocuments/PhotoIdProgress.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./TaxpayerIdDocuments/TaxpayerIdProgress.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./CreditCounseling/CreditCounselingProgress.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./MiscDocuments/MiscDocumentsProgress.gql").definitions));


    // Collect any fragment/type references from a node, adding them to the refs Set
    function collectFragmentReferences(node, refs) {
      if (node.kind === "FragmentSpread") {
        refs.add(node.name.value);
      } else if (node.kind === "VariableDefinition") {
        var type = node.type;
        if (type.kind === "NamedType") {
          refs.add(type.name.value);
        }
      }

      if (node.selectionSet) {
        node.selectionSet.selections.forEach(function(selection) {
          collectFragmentReferences(selection, refs);
        });
      }

      if (node.variableDefinitions) {
        node.variableDefinitions.forEach(function(def) {
          collectFragmentReferences(def, refs);
        });
      }

      if (node.definitions) {
        node.definitions.forEach(function(def) {
          collectFragmentReferences(def, refs);
        });
      }
    }

    var definitionRefs = {};
    (function extractReferences() {
      doc.definitions.forEach(function(def) {
        if (def.name) {
          var refs = new Set();
          collectFragmentReferences(def, refs);
          definitionRefs[def.name.value] = refs;
        }
      });
    })();

    function findOperation(doc, name) {
      for (var i = 0; i < doc.definitions.length; i++) {
        var element = doc.definitions[i];
        if (element.name && element.name.value == name) {
          return element;
        }
      }
    }

    function oneQuery(doc, operationName) {
      // Copy the DocumentNode, but clear out the definitions
      var newDoc = {
        kind: doc.kind,
        definitions: [findOperation(doc, operationName)]
      };
      if (doc.hasOwnProperty("loc")) {
        newDoc.loc = doc.loc;
      }

      // Now, for the operation we're running, find any fragments referenced by
      // it or the fragments it references
      var opRefs = definitionRefs[operationName] || new Set();
      var allRefs = new Set();
      var newRefs = new Set();

      // IE 11 doesn't support "new Set(iterable)", so we add the members of opRefs to newRefs one by one
      opRefs.forEach(function(refName) {
        newRefs.add(refName);
      });

      while (newRefs.size > 0) {
        var prevRefs = newRefs;
        newRefs = new Set();

        prevRefs.forEach(function(refName) {
          if (!allRefs.has(refName)) {
            allRefs.add(refName);
            var childRefs = definitionRefs[refName] || new Set();
            childRefs.forEach(function(childRef) {
              newRefs.add(childRef);
            });
          }
        });
      }

      allRefs.forEach(function(refName) {
        var op = findOperation(doc, refName);
        if (op) {
          newDoc.definitions.push(op);
        }
      });

      return newDoc;
    }
    
    module.exports = doc;
    
        module.exports["LegalMattersProgress"] = oneQuery(doc, "LegalMattersProgress");
        
