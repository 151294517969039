import { GovernmentDisbursementWizard } from "components/FormModules/Income/GovernmentDisbursements/GovernmentDisbursementWizard";

import { DisbursementsCategoryDetail } from "./CategoryDetail";

interface Props {
  listURL: string;
  categoryDetail: DisbursementsCategoryDetail;
}

export default function CreateView(props: Props) {
  return (
    <GovernmentDisbursementWizard
      title={`Add ${props.categoryDetail.displayName}`}
      subtitle={props.categoryDetail.singleInterrogativeText}
      category={props.categoryDetail.category}
      backURL={props.listURL}
      nextURL={props.listURL}
    />
  );
}
