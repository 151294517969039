import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import BORROWED_ASSETS_MODULE from "./BorrowedAssetsModule.gql";

export default function BorrowedAssetsSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<BorrowedAssetsModule>(BORROWED_ASSETS_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.borrowedAssets}>
      {data?.filingProfile?.hasNoBorrowedAssets ? (
        <HasNoSummary>
          You aren&rsquo;t holding someone else&rsquo;s property in your
          possession or trust.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="held asset"
            maxValueLength={2}
            value={data?.borrowedAssets?.length}
            pluralizeLabel
          />
          <NumericSummary
            label="total value held"
            valuePrefix="$"
            maxValueLength={4}
            value={fancyNumberSum(data?.borrowedAssets, "totalValue")}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
