import { CategoryDetail } from "components/FormModules/Assets/HouseholdItems/CategorySection/CategoryDetail";
import { HouseholdItemCategoryChoice } from "globalTypes";
import * as React from "react";
import { FaQuestion } from "react-icons/fa";

const Examples = () => (
  <div>
    <p>Examples include:</p>
    <ul>
      <li>Health aids (oxygen tanks, CPAP machines, etc)</li>
    </ul>
  </div>
);

const OtherDetail: CategoryDetail = {
  category: HouseholdItemCategoryChoice.Other,
  displayName: "Other household items",
  displayLowercaseName: "other household items",
  hasNoProfileBooleanName: "hasNoHouseholdOtherItems",
  singleInterrogativeText: "Tell us about this item.",
  noAssetDeclarativeText:
    "You have indicated that neither you nor any member of your household owns items that do not fit into the other personal and household item categories.",
  booleanInterrogativeText: (
    <>
      <p className="required">
        <strong>
          Do you or any members of your household own any household and personal
          items that you did not already list?
        </strong>
      </p>
      <Examples />
    </>
  ),
  declarativeText: (
    <>
      <p className="required">
        <strong>
          Please describe all items you and your family members own that don’t
          fit into any of the other personal and household item categories.
        </strong>
      </p>
      <Examples />
    </>
  ),
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add items below.",
  urlPathName: "other",
  displayMenuName: "Other",
  menuIcon: FaQuestion,
};

export default OtherDetail;
