import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_SERVICE_PREPAYMENTS from "./DeleteServicePrepayment.gql";
import GET_SERVICE_PREPAYMENTS from "./GetServicePrepayment.gql";
import LIST_SERVICE_PREPAYMENTS from "./ListServicePrepayment.gql";
import UPSERT_SERVICE_PREPAYMENTS from "./UpsertServicePrepayment.gql";

const LIST_SERVICE_PREPAYMENTS_IDS = gql`
  query ListServicePrepaymentIds {
    servicePrepayments {
      id
    }
  }
`;

export function useListServicePrepayment(
  options?: QueryHookOptions<ListServicePrepayment>
) {
  return useQuery<ListServicePrepayment>(LIST_SERVICE_PREPAYMENTS, options);
}

export function useGetServicePrepayment(id: string) {
  return useQuery<GetServicePrepayment, GetServicePrepaymentVariables>(
    GET_SERVICE_PREPAYMENTS,
    {
      variables: { id },
    }
  );
}

export function useUpsertServicePrepayment<
  Schema extends { servicePrepayment: any } = UpsertServicePrepayment,
  Variables = UpsertServicePrepaymentVariables
>(query = UPSERT_SERVICE_PREPAYMENTS) {
  return useUpsert<
    Schema,
    Variables,
    ListServicePrepaymentIds,
    ServicePrepaymentInput
  >(
    query,
    LIST_SERVICE_PREPAYMENTS_IDS,
    "servicePrepayment",
    "servicePrepayments",
    (cache) => updateFilingProfileCache(cache, "hasNoServicePrepayments", false)
  );
}

export function useDeleteServicePrepayment() {
  return useDelete<DeleteServicePrepayment, ListServicePrepaymentIds>(
    DELETE_SERVICE_PREPAYMENTS,
    LIST_SERVICE_PREPAYMENTS_IDS,
    "deleteServicePrepayment",
    "servicePrepayments"
  );
}
