import { useQuery } from "@apollo/client";
import { useDeleteCreditCardDebt } from "api/graphql/CreditCardDebt";
import { ListViewProps } from "components/EditCreateList/stateless";
import { StatelessList } from "components/EditCreateList/StatelessList";
import ImportEntry from "components/ImportView/Entry";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { FaDownload } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import {
  formatErrorsFromSchema,
  formatName,
  formatOxfordComma,
  formatQuantity,
} from "utils/formatters";
import { useNested } from "utils/hooks";
import CREDIT_CARD_DEBTS_MODULE from "./CreditCardDebtsModule.gql";
import { CreditCardDebtSchema } from "./CreditCardDebtWizard";
import { useGetSession } from "api/graphql/Session";

export default function ListView(props: ListViewProps) {
  const { data, loading } = useQuery<CreditCardDebtsModule>(
    CREDIT_CARD_DEBTS_MODULE
  );
  const [onDelete] = useDeleteCreditCardDebt();
  const { path, url } = useNested();
  const { data: sessionData, loading: sessionLoading } = useGetSession();

  if (loading || sessionLoading) return <Loading />;
  const { creditCardDebts: items } = data;
  const { enableImportFinancialAccounts } =
    sessionData?.session.user.referralFirm.featureFlags;

  return (
    <>
      <Title>Your Credit Cards</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoCreditCardDebts"
              createURL={url("/decision")}
              nextURL={props.nextURL}
              addText="Add a credit card"
              question={
                <p className="required">
                  <strong>
                    In order to file your bankruptcy, we need to know about all
                    your credit card accounts. Do you have any open credit
                    cards?
                  </strong>
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you don&rsquo;t have any credit card
                  debts. If that&rsquo;s correct, you&rsquo;re ready to continue
                  to the next section. Otherwise, you may add debts below.
                </p>
              }
            />
          )}
        />
        <Route
          path={path("/decision")}
          render={() => (
            <ImportEntry
              manualCreateURL={props.createURL}
              nextURL={url("/")}
              items={items}
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Continue adding credit cards until all your open accounts are
                shown below.
              </p>

              {enableImportFinancialAccounts && (
                <Link
                  to={`/new-filing/financial-import?from=${window.location.pathname}&startImport=true`}
                  className="btn"
                  style={{ alignSelf: "flex-start", marginTop: "8px" }}
                >
                  <FaDownload />
                  <span>Import credit cards</span>
                </Link>
              )}

              <StatelessList
                {...props}
                emptyText="You haven’t added any credit card debts yet."
                addButtonText="Add a credit card"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(d) => (
                  <div>
                    {formatErrorsFromSchema(CreditCardDebtSchema, d)}

                    <strong>
                      Credit card {d.accountNumber} from {d.lenderName}
                    </strong>

                    {!!d.cosigners.length && (
                      <div className="supporting-text">
                        Cosigned with{" "}
                        {formatOxfordComma(...d.cosigners.map(formatName))}
                      </div>
                    )}

                    {!!d.collectors.length && (
                      <div className="supporting-text">
                        {formatQuantity(d.collectors.length, " debt collector")}
                      </div>
                    )}
                  </div>
                )}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
