import {
  useAssignCofiler,
  useGetCofilerCandidates,
  useUpsertCofiler,
} from "api/graphql/Cofiler";
import {
  OtherPersonDataValidationType,
  OtherPersonWithMissingDataValidationsForm,
} from "components/FormSections/OtherPersonWithMissingDataValidation";
import { Loading } from "components/Loading";
import * as React from "react";

export function CofilerForm({
  returnURL,
  id,
  relatedId,
  relatedTypename,
  title,
}: {
  returnURL: string;
  relatedId: string;
  relatedTypename: string;
  id?: string;
  title: string;
}) {
  const [assignCofiler] = useAssignCofiler();
  const [upsertCofiler] = useUpsertCofiler();
  const { data, loading } = useGetCofilerCandidates({
    id: relatedId,
    typename: relatedTypename,
  });

  const validations = React.useMemo(
    () => [
      OtherPersonDataValidationType.MailingAddress,
      OtherPersonDataValidationType.SSN,
    ],
    []
  );

  const onSubmit = React.useCallback((state) => {
    if (!id && "id" in state) {
      return assignCofiler({
        otherPersonId: state.id,
        relatedId,
        relatedTypename,
      });
    } else {
      return upsertCofiler({
        variables: {
          input: state as OtherPersonInput,
          relatedId,
          relatedTypename,
        },
      });
    }
  }, []);

  if (!id && loading) return <Loading />;

  return (
    <OtherPersonWithMissingDataValidationsForm
      withSSN
      validations={validations}
      id={id}
      candidates={data?.cofilable?.eligibleCofilers}
      relatedId={relatedId}
      relatedTypename={relatedTypename}
      relatedDisplayName="co-filer"
      title={title}
      backURL={returnURL}
      nextURL={returnURL}
      onSubmit={onSubmit}
      onFinalizeMissingDataCollection={assignCofiler}
    />
  );
}
