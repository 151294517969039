import { pickBy } from "lodash";
import * as React from "react";
import Joyride, { CallBackProps, Step } from "react-joyride";
import { useCheckMounted } from "utils/hooks";
import { Tooltip } from "./Tooltip";

export type WalkthroughStepProps = Omit<Step, "content"> & {
  children: React.ReactNode;
};

export function WalkthroughStep(props: WalkthroughStepProps): any {
  null;
}

function _stepToDataStructure(
  StepInstance: React.ReactElement<WalkthroughStepProps>
): Step {
  const { props } = StepInstance;
  return {
    ...pickBy(props, (_, key) => key !== "children"),
    disableBeacon:
      props.disableBeacon === undefined ? true : props.disableBeacon,
    content: props.children,
  } as Step;
}

export interface WalkthroughProps {
  children: React.ReactNode;
  shouldRun: (() => Promise<boolean>) | Promise<boolean> | boolean;
  setCompleted: () => Promise<any>;
  onEnd?: (() => void) | (() => Promise<any>);
  onStart?: () => any;
}

export function Walkthrough({
  children,
  shouldRun,
  setCompleted,
  onEnd,
  onStart,
}: WalkthroughProps) {
  const [run, setRun] = React.useState(false);
  const callback = React.useCallback(async (data: CallBackProps) => {
    if (data.type === "tour:end") {
      if (onEnd) {
        await onEnd();
      }
      await setCompleted();
    } else if (data.type === "tour:start") {
      if (onStart) {
        onStart();
      }
    }
  }, []);
  const checkMounted = useCheckMounted();

  React.useEffect(() => {
    const doRunCheck = async () => {
      const run_ = await (typeof shouldRun === "function"
        ? shouldRun()
        : shouldRun);
      if (checkMounted()) {
        setRun(run_);
      }
    };

    doRunCheck();
  }, [shouldRun]);

  return (
    <Joyride
      callback={callback}
      continuous
      disableCloseOnEsc
      disableOverlay
      disableOverlayClose
      floaterProps={{ disableFlip: true, disableAnimation: true } as any}
      run={run}
      scrollToFirstStep
      showSkipButton
      steps={React.Children.toArray(children).map(_stepToDataStructure)}
      styles={{
        options: {
          primaryColor: "var(--primary)",
          arrowColor: "var(--gray-6)",
        },
      }}
      tooltipComponent={Tooltip}
    />
  );
}
