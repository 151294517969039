import { useApolloClient } from "@apollo/client";
import React, { useCallback } from "react";
import { FaRegBuilding } from "react-icons/fa";
import TypeAhead from "../TypeAhead";
import css from "./AutocompleteApiEmployer.module.scss";
import SEARCH_API_EMPLOYERS from "./SearchApiEmployers.gql";

type SearchResult = ArrayItemType<SearchApiEmployers["employerSearch"]>;

interface Props {
  /** The CSS selector of the input to attach to. */
  inputSelector: string;
  /** The function to be called when a place suggestion is selected by the user. */
  onEmployerSearchSelected: (result: SearchResult) => void;
}

export function formatEmployerSearchResult(employer: SearchResult) {
  return (
    <div className={css.resultItem}>
      {employer?.logoUrl ? (
        <img
          className={css.logo}
          alt={`${employer.name} logo`}
          src={employer.logoUrl}
        />
      ) : (
        <FaRegBuilding className={css.blankLogo} />
      )}
      <span className={css.employer} data-employer-id={employer.remoteId}>
        {employer.name}
      </span>
    </div>
  );
}

/** Renders a dropdown allowing users to autofill an API-provided employer based on an input's value.
 *
 * The picker UI will be rendered
 * under the input, not inside the component.
 */
export default function AutocompleteApiEmployer({
  inputSelector,
  onEmployerSearchSelected,
}: Props) {
  const client = useApolloClient();

  const getSuggestions = useCallback(
    async (query: string) => {
      const { data } = await client.query<
        SearchApiEmployers,
        SearchApiEmployersVariables
      >({
        query: SEARCH_API_EMPLOYERS,
        variables: { query: query },
      });

      return data.employerSearch.slice(0, 10);
    },
    [client]
  );

  return (
    <TypeAhead
      inputSelector={inputSelector}
      getSuggestions={getSuggestions}
      onSelect={onEmployerSearchSelected}
      formatSuggestion={formatEmployerSearchResult}
      footer={({ suggestions }) => (
        <div className={css.helpText}>
          {suggestions.length >= 10 && "Keep typing to refine your search.  "}
          If you cannot find your employer via search, just type in the name as
          best you know it.
        </div>
      )}
    />
  );
}
