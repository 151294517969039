import { useQuery } from "@apollo/client";
import { useDeleteOwnedBusiness } from "api/graphql/OwnedBusiness";
import { ButtonRow } from "components/ButtonRow";
import { getBusinessesCashFlow } from "components/FormModules/OwnedBusinesses/BusinessExpensesSummary";
import { OwnedBusinessSchema } from "components/FormModules/OwnedBusinesses/validations";
import { ListItem } from "components/ListItem";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema } from "utils/formatters";
import { useNested } from "utils/hooks";
import OWNED_BUSINESSES_MODULE from "./OwnedBusinessesModule.gql";

interface Props {
  onEdit: (id: string) => any;
  createURL: string;
  nextURL: string;
}

export default function ListView({ onEdit, createURL, nextURL }: Props) {
  const { data } = useQuery<OwnedBusinessesModule>(OWNED_BUSINESSES_MODULE, {
    fetchPolicy: "cache-and-network",
  });
  const [onDelete] = useDeleteOwnedBusiness();
  const { path, url } = useNested();

  if (!data) return <Loading />;
  const { ownedBusinesses: items } = data;

  return (
    <>
      <Title>Your Owned Businesses</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoBusinesses"
              createURL={createURL}
              nextURL={nextURL}
              addText="Add a business"
              question={
                <>
                  <p>
                    Your lawyer will need more detailed information about any
                    businesses you&rsquo;re personally liable for. This type of
                    business includes self-employment, sole proprietorship, and
                    partnerships. It <em>does not</em> include limited liability
                    structures or corporations, such as LLCs, LLPs, and C
                    Corporations, which should be declared as{" "}
                    <Link to="/new-filing/section/assets/financial_investments">
                      financial investments
                    </Link>
                    .
                  </p>
                  <p className="required">
                    <strong>
                      Do you have ownership of businesses for which you are
                      personally liable?
                    </strong>
                  </p>
                </>
              }
              confirmation={
                <p>
                  You have indicated that you are not self-employed, and do not
                  own any businesses for which you&rsquo;re personally liable.
                  If that’s correct, you’re ready to continue to the next
                  section. Otherwise, you may add businesses below.
                </p>
              }
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                If you own multiple businesses for which you are personally
                liable, make sure to add them all below.
              </p>
              <ButtonRow
                style={{ marginBottom: "8px" }}
                left={
                  <Link to={createURL} className="btn secondary">
                    <FaPlus />
                    <span>Add business</span>
                  </Link>
                }
                right={
                  <Link to={nextURL} className="btn primary">
                    <span>Done</span>
                    <FaCheck />
                  </Link>
                }
              />
              {items.map((b) => {
                return (
                  <ListItem
                    key={b.id}
                    onEdit={() => onEdit(b.id)}
                    onDelete={() => onDelete(b.id)}
                  >
                    <div>
                      {formatErrorsFromSchema(OwnedBusinessSchema, b)}
                      <strong>{b.businessName}</strong>
                    </div>
                    <div>{b.percentOwnership}% owned by you</div>
                    <div>
                      <span>Monthly cash flow:</span>{" "}
                      {getBusinessesCashFlow([b])}
                    </div>
                  </ListItem>
                );
              })}
            </>
          )}
        />
      </Switch>
    </>
  );
}
