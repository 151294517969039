import { useUpsertGovernmentDisbursement } from "api/graphql/GovernmentDisbursement";
import { Wizard } from "components/Wizard";
import { ReactNode } from "react";
import Disbursement, { DisbursementSchema } from "./Disbursement";
import { omit } from "lodash";

interface Props {
  category: GovernmentDisbursementCategoryChoice;
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  /** The URL to switch to after the initial save. Created object ID will be appended */
  editURL?: string;
  subtitle?: ReactNode;
}

export const GovernmentDisbursementSchema = DisbursementSchema;

export function GovernmentDisbursementWizard({
  category,
  backURL,
  nextURL,
  initialValue,
  title,
  editURL = "edit",
  subtitle,
}: Props) {
  const [upsertGovernmentDisbursement] = useUpsertGovernmentDisbursement(
    undefined,
    category
  );

  return (
    <Wizard
      title={title}
      subtitle={subtitle}
      backURL={backURL}
      nextURL={nextURL}
      initialValue={initialValue}
      vertical
      steps={[
        {
          path: "/disbursement",
          title: "Government Disbursement Documentation",
          component: Disbursement,
          schema: DisbursementSchema,
          async onSave(formValues) {
            let vars = formValues;
            if (!(formValues.uploadFile instanceof File)) {
              vars = omit(formValues, ["uploadFile"]);
            }
            const { data } = await upsertGovernmentDisbursement(
              { ...vars, category },
              undefined
            );

            return data.governmentDisbursement;
          },
        },
      ]}
    />
  );
}
