import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import REAL_ESTATE_MODULE from "./RealEstateModule.gql";

export default function RealEstateSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<RealEstateModule>(REAL_ESTATE_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.realEstateProperty}>
      {data?.filingProfile?.hasNoRealEstate ? (
        <HasNoSummary>
          You don&rsquo;t own any real estate properties.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="owned property"
            maxValueLength={2}
            value={data?.realEstateProperties?.length}
            pluralizeLabel
          />
          <NumericSummary
            label="total investment"
            valuePrefix="$"
            maxValueLength={7}
            value={fancyNumberSum(data?.realEstateProperties, "purchasePrice")}
          />
          <NumericSummary
            label="total value"
            valuePrefix="$"
            maxValueLength={7}
            value={fancyNumberSum(data?.realEstateProperties, "currentValue")}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
