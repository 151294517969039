import { merge } from "lodash";

export interface MetaStateType {
  working: boolean;
  dirty: boolean;
  errorMessage?: string;
}

export type MetaActionType =
  | { type: "SET_WORKING" }
  | { type: "UNSET_WORKING" }
  | { type: "SET_DIRTY" }
  | { type: "UNSET_DIRTY" }
  | { type: "SET_ERROR_MESSAGE"; message?: string };

export type MetaType = [MetaStateType, React.Dispatch<MetaActionType>];

export function metaReducer(state: MetaStateType, action: MetaActionType) {
  switch (action.type) {
    case "SET_WORKING":
      return { ...state, working: true };
    case "UNSET_WORKING":
      return { ...state, working: false };
    case "SET_DIRTY":
      return { ...state, dirty: true };
    case "UNSET_DIRTY":
      return { ...state, dirty: false };
    case "SET_ERROR_MESSAGE":
      return { ...state, errorMessage: action.message };
    default:
      return state;
  }
}

export interface BufferStateType<T> {
  buffer: T;
  committed: T;
}

export type BufferActionType<T> =
  | { type: "UPDATE_BUFFER"; values: Partial<T>; merge?: boolean }
  | { type: "UPDATE_COMMITTED"; values: Partial<T> }
  | { type: "FLUSH"; values: T };

export type BufferType<T> = [
  BufferStateType<T>,
  React.Dispatch<BufferActionType<T>>
];

export function bufferReducer<T>(
  state: BufferStateType<T>,
  action: BufferActionType<T>
) {
  switch (action.type) {
    case "UPDATE_BUFFER":
      return {
        ...state,
        buffer: action.merge
          ? merge(state.buffer, action.values)
          : {
              ...state.buffer,
              ...action.values,
            },
      };
    case "UPDATE_COMMITTED":
      return {
        ...state,
        committed: {
          ...state.committed,
          ...action.values,
        },
      };
    case "FLUSH":
      return {
        ...state,
        buffer: { ...action.values },
        committed: { ...action.values },
      };
    default:
      return state;
  }
}
