import * as React from "react";

// I couldn't figure out how to create a typing for stuff in "react-modal/lib/components"
// without fucking up the main declarations, so this is a copy and paste job
const DEFAULT_BODY_OPEN_CLASSNAME = "ReactModal__Body--open";

export function useAwaitModalClose(
  bodyOpenClassName = DEFAULT_BODY_OPEN_CLASSNAME
) {
  const observerRef = React.useRef(null);
  const promiseRef = React.useRef(null);
  const resolveRef = React.useRef(null);
  const checkOpen = React.useCallback(
    () => document.body.classList.contains(bodyOpenClassName),
    []
  );
  const [isOpen, setOpen] = React.useState(checkOpen());

  React.useEffect(() => {
    if (!observerRef.current) {
      const observer = new MutationObserver((mutations) => {
        const [mutation] = mutations;
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class" &&
          mutation.target === document.body
        ) {
          setOpen(checkOpen());
        }
      });
      observerRef.current = observer;
      observer.observe(document.body, { attributes: true, childList: false });
    }
    return () => observerRef.current?.disconnect();
  }, []);

  React.useEffect(() => {
    if (!isOpen && resolveRef.current) {
      const resolve = resolveRef.current;
      resolveRef.current = null;
      promiseRef.current = null;
      resolve();
    }
  }, [isOpen]);

  return React.useCallback(() => {
    if (!promiseRef.current) {
      promiseRef.current = new Promise<void>((resolve) => {
        if (checkOpen()) {
          resolveRef.current = resolve;
        } else {
          resolve();
        }
      });
    }
    return promiseRef.current;
  }, []);
}
