import { useApolloClient } from "@apollo/client";
import { useDeleteTaxReturn, useUpsertTaxReturn } from "api/graphql/TaxReturn";
import cn from "classnames";
import { DeleteButtonWithConfirm } from "components/DeleteConfirmationModal";
import React, { useState } from "react";
import {
  FaCheck,
  FaCheckCircle,
  FaExclamationCircle,
  FaPencilAlt,
  FaPlus,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { updateQueryParams } from "utils/urls";
import css from "./TaxReturns.module.css";
import TAX_RETURNS_MODULE from "./TaxReturnsModule.gql";
import { ReturnLabels } from "./utils";
import { TaxReturnSchema } from "./Wizard";

interface ReturnBlockReturn {
  id: string;
  returnFiled: boolean;
  _type: TaxReturnTypeChoice;
  returnYear: number;
  totalTaxesDue?: Decimal;
  isOwingMoney: boolean;
  isCofiled?: boolean;
  isPrimaryFiler?: boolean;
  taxDebt?: {
    outstandingAmount: Decimal;
    entityName: string;
  } | null;
}

export default function ReturnBlock({
  taxReturn,
  createURL,
  onEdit,
  year,
  type,
}: {
  taxReturn?: ReturnBlockReturn;
  createURL: string;
  onEdit: (id: string) => any;
  year: number;
  type: TaxReturnTypeChoice;
}) {
  const client = useApolloClient();
  const { returnFiled, taxDebt } = taxReturn || {};
  const [loading, setLoading] = useState(false);
  const [createReturn] = useUpsertTaxReturn();
  const [deleteReturn] = useDeleteTaxReturn();

  const createEmptyReturn = async () => {
    setLoading(true);
    await createReturn({
      _type: type,
      returnYear: year,
      returnFiled: false,
      isOwingMoney: false,
      adjustedGrossIncome: null,
      totalIncome: null,
    });

    // The cache has been successfully updated,
    // but we need to manually do a cache read so that
    // the parent useQuery hook will trigger a re-render.
    //
    // I don't know why deletes don't also have this bug,
    // probably a difference in how useDelete and useUpsert are
    // implemented, and it wasn't immediately obvious to me how to
    // fix it.
    await client.query({ query: TAX_RETURNS_MODULE });
    setLoading(false);
  };

  return (
    <div>
      <div className="button-header">
        <div className={css.returnBlock}>
          {TaxReturnSchema.isValidSync(taxReturn) ? (
            <FaCheckCircle className={css.completionIndicator} size="24px" />
          ) : (
            <FaExclamationCircle
              className={css.attentionIndicator}
              size="24px"
            />
          )}
          <h3>{ReturnLabels[type]} return</h3>
        </div>
        {taxReturn && returnFiled ? (
          <div className={css.returnButtons}>
            <DeleteButtonWithConfirm
              className="flat"
              objectName="tax return"
              onDelete={() => deleteReturn(taxReturn.id)}
              relatedItems={{
                "tax debt":
                  taxDebt &&
                  `$${taxDebt.outstandingAmount} owed to ${taxDebt.entityName}`,
              }}
            />
            <button
              type="button"
              className="btn flat"
              onClick={() => onEdit(taxReturn.id)}
            >
              <FaPencilAlt />
              <span>Edit</span>
            </button>
          </div>
        ) : (
          <div className={css.returnButtons}>
            <Link
              to={updateQueryParams(createURL, { _type: type, year })}
              className={cn("btn ", {
                primary: returnFiled !== false,
                flat: returnFiled === false,
              })}
            >
              <FaPlus />
              <span>Add return</span>
            </Link>
            <button
              type="button"
              className={cn("btn", { loading })}
              disabled={returnFiled === false}
              onClick={createEmptyReturn}
            >
              {returnFiled === false && <FaCheck />}
              <span>Didn&rsquo;t file</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
