import { omit } from "lodash";
import { useUpsertRealEstate } from "api/graphql/RealEstateProperties";
import Financials, {
  FinancialsSchema,
} from "components/FormModules/Assets/RealEstate/RealEstateWizard/Financials";
import { Wizard } from "components/Wizard";
import {
  CoOwnersSchema,
  CoownersWizardStepProps,
  useDelegatedOnSave,
} from "components/WizardSteps/Coowners";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import Coowners from "./Coowners";
import Property, { PropertySchema } from "./Property";
import Upload, { UploadSchema } from "./Upload";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  /** The URL to switch to after the initial save. Created object ID will be appended */
  editURL?: string;
}

export const RealEstateSchema =
  PropertySchema.concat(FinancialsSchema).concat(CoOwnersSchema);

export function RealEstateWizard({
  backURL,
  nextURL,
  initialValue,
  title,
  editURL = "edit",
}: Props) {
  const { path, url } = useNested();
  const [upsertRealEstate] = useUpsertRealEstate();
  const history = useHistory();
  const [propertyId, setPropertyId] = useState(initialValue?.id);
  const [registerOnSaveCoowners, onSaveCoowners] = useDelegatedOnSave();

  const coownersURL = "/coowners";

  return (
    <Wizard
      title={title}
      backURL={backURL}
      nextURL={nextURL}
      initialValue={initialValue}
      vertical
      steps={[
        {
          path: "/property",
          title: "Property",
          component: Property,
          schema: PropertySchema,
        },
        {
          path: "/financials",
          title: "Financials",
          component: Financials,
          schema: FinancialsSchema,
        },
        {
          path: "/upload",
          title: "Upload documents",
          component: Upload,
          schema: UploadSchema,
          async onSave(formValues) {
            const { uploadMortgage, uploadDeed, uploadPropertyTax } =
              formValues;
            let otherVars = {};
            if (uploadMortgage instanceof File) {
              otherVars = { ...otherVars, uploadMortgage };
            }
            if (uploadDeed instanceof File) {
              otherVars = { ...otherVars, uploadDeed };
            }
            if (uploadPropertyTax instanceof File) {
              otherVars = { ...otherVars, uploadPropertyTax };
            }

            const { data } = await upsertRealEstate(
              omit(formValues, [
                "uploadDeed",
                "uploadMortgage",
                "uploadPropertyTax",
              ]),
              otherVars
              // typeof uploadMortgage === "string" ? {} : { uploadMortgage },
              // typeof uploadDeed === "string" ? {} : { uploadDeed },
              // typeof uploadPropertyTax === "string" ? {} : { uploadPropertyTax }
            );

            const { id } = data.realEstateProperty;
            setPropertyId(id);
            if (propertyId) {
              history.push(url(coownersURL));
            } else {
              history.push(`../${editURL}/${id}${coownersURL}`);
            }
            return data.realEstateProperty;
          },
        },
        {
          path: coownersURL,
          title: "Co-owners",
          component: React.useCallback(
            (props: CoownersWizardStepProps) => (
              <Coowners {...props} registerOnSave={registerOnSaveCoowners} />
            ),
            [registerOnSaveCoowners]
          ),
          schema: CoOwnersSchema,
          includeSubpaths: true,
          onSave: onSaveCoowners,
        },
      ]}
    />
  );
}
