import { StatelessEditCreateList } from "components/EditCreateList";
import { startOfToday, subMonths } from "date-fns";
import React, { useCallback } from "react";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

export interface ResidenceProps {
  next?: string;
  prev?: string;
  requiredFrom?: Date;
}

/** A Form Module used to manage a `Person`'s residential addresses. */
export default function Residence({
  next,
  prev,
  requiredFrom = subMonths(startOfToday(), 6),
}: ResidenceProps) {
  return (
    <StatelessEditCreateList
      prev={prev}
      next={next}
      CreateView={CreateView}
      ListView={useCallback(
        (props) => (
          <ListView {...props} requiredFrom={requiredFrom} />
        ),
        [requiredFrom]
      )}
      EditView={EditView}
    />
  );
}
