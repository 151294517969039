import { LearnMore } from "components/LearnMore";
import React from "react";

export function ReferralHelp() {
  return (
    <LearnMore>
      <h4>What type of link should I use?</h4>
      <p>
        The known customer referral link is useful for customers that you have
        already confirmed are a fit for bankruptcy. Most law firms use this link
        for customers that have already completed some level of screening, such
        as those who have participated in an initial consultation call (or
        more).
      </p>
      <p>
        This link is useful for customers that you don&rsquo;t know are a fit
        for bankruptcy. Most law firms use this link for customers that have are
        completely unknown, such as those referred directly from the law
        firm&rsquo;s website to Lexria.
      </p>
    </LearnMore>
  );
}
