import ClothingDetail from "./Clothing";
import CollectiblesDetail from "./Collectibles";
import ElectronicsDetail from "./Electronics";
import FirearmsDetail from "./Firearms";
import FurnishingsDetail from "./HouseholdGoodsAndFurnishings";
import JewelryDetail from "./Jewelry";
import NonFarmAnimalsDetail from "./NonFarmAnimals";
import OtherDetail from "./Other";
import SportsHobbyEquipmentDetail from "./SportsHobbyEquipment";

/* Note that the order here is very important,
   as this order is used to build the menu.
 */

export default [
  ClothingDetail,
  CollectiblesDetail,
  ElectronicsDetail,
  FirearmsDetail,
  FurnishingsDetail,
  JewelryDetail,
  NonFarmAnimalsDetail,
  SportsHobbyEquipmentDetail,
  OtherDetail,
];
