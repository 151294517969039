import { useState } from "react";
import { Form } from "components/Form";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { Modal, Button, Group, Loader } from "@mantine/core";
import { DateInput } from "components/MantineInputs/DateInput";
import { TextInput } from "components/MantineInputs/TextInput";
import { useUpdateFilingProfileDataMutation } from "api/graphql/FilingProfile";
import { useRegenerateBundle } from "api/graphql/ReferredUser";

const TrueIcon = () => <FaCheckCircle className="text-green-600" />;
const FalseIcon = () => (
  <FaTimesCircle className="text-red-600" size={"1rem"} />
);

import styled from "@emotion/styled";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;
const GridItemLabel = styled.div`
  font-weight: 600;
`;

export default function UserInfoModal({
  opened,
  onConfirm,
  onCancel,
  onClose,
  user,
}) {
  const [updateFilingProfileData] = useUpdateFilingProfileDataMutation();
  const [regenerateBundle] = useRegenerateBundle();

  const [loading, setLoading] = useState(false);
  const [regenerating, setRegenerating] = useState(false);

  const handleSubmission = async (data) => {
    setLoading(true);
    await updateFilingProfileData({
      userId: user.id,
      value: {
        documentsDueDate: data.documentsDueDate,
        lawFirmContact: data.lawFirmContact,
      },
    });
    setLoading(false);
    onConfirm();
  };
  return (
    <Modal
      opened={opened}
      transition={"fade"}
      title={<></>}
      onClose={onClose}
      size="xl"
      centered
    >
      <>
        <div className="flex items-center justify-center">
          <Form
            onSubmit={handleSubmission}
            initialValue={{
              documentsDueDate: user.documentsDueDate
                ? new Date(Date.parse(user.documentsDueDate))
                : "",
              lawFirmContact: user.lawFirmContact,
            }}
          >
            <h2>User information</h2>
            <div className="relative w-full h-full">
              <div className="mt-8">
                {loading && (
                  <div className="absolute z-10 flex items-center justify-center w-full h-full bg-white">
                    <Loader />
                  </div>
                )}
                <Grid>
                  <GridItemLabel>Full name</GridItemLabel>
                  <div>{user.name}</div>
                  <GridItemLabel>Email</GridItemLabel>
                  <div>{user.email}</div>
                  <GridItemLabel>Completion status</GridItemLabel>
                  <div>{user.completionStatus}</div>
                  <GridItemLabel>Archived</GridItemLabel>
                  <div>{user.isArchived ? <TrueIcon /> : <FalseIcon />}</div>
                  {/* <GridItemLabel>Credit report enabled</GridItemLabel>
                  <div>
                    {user.enableCreditReport ? <TrueIcon /> : <FalseIcon />}
                  </div> */}
                  <GridItemLabel>Registration date</GridItemLabel>
                  <div>{user.registeredAt?.toLocaleDateString()}</div>
                  <GridItemLabel>Last login</GridItemLabel>
                  <div>{user.lastLoginAt?.toLocaleDateString()}</div>
                  <GridItemLabel>Law firm contact</GridItemLabel>
                  <div>
                    <TextInput placeholder="Enter name" name="lawFirmContact" />
                  </div>
                  <GridItemLabel>Documents due date</GridItemLabel>
                  <div>
                    <DateInput
                      placeholder="Select date"
                      popoverProps={{ withinPortal: true }}
                      name="documentsDueDate"
                      clearable
                    />
                  </div>
                  {user?.uploadsBundleUrl && (
                    <>
                      <div>
                        <GridItemLabel>Document bundle</GridItemLabel>
                      </div>
                      <div className="flex flex-col">
                        {user.uploadsBundleGeneratedAt && (
                          <div>
                            {`Generated ${user.uploadsBundleGeneratedAt.toLocaleString()}`}
                          </div>
                        )}
                        <div className="flex gap-2 mt-1">
                          <a
                            key={"download-bundle"}
                            href={`${user.uploadsBundleUrl}`}
                            download={`${user?.name}_${
                              user.uploadsBundleGeneratedAt
                                ? user.uploadsBundleGeneratedAt.toLocaleString()
                                : ""
                            }.zip`.replace(/[,\s]+/g, "_")}
                          >
                            <Button
                              size="xs"
                              variant="outline"
                              title="Download document bundle"
                            >
                              Download
                            </Button>
                          </a>
                          <Button
                            size="xs"
                            variant="outline"
                            title="Regenerate document bundle"
                            onClick={async () => {
                              setRegenerating(true);
                              await regenerateBundle({ userId: user.id });
                              setRegenerating(false);
                            }}
                            loading={regenerating}
                          >
                            Regenerate
                          </Button>
                        </div>
                        <div className="mt-2">
                          <div className="text-sm text-gray-600">
                            New bundles can take up to 5 minutes to generate.
                            Please reload the page to see the newest bundle.
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Grid>
              </div>
            </div>
            <Group position="right" className="mt-12">
              <Button type="submit" variant="filled" disabled={loading}>
                Save
              </Button>
              <Button onClick={onCancel} variant="outline" disabled={loading}>
                Cancel
              </Button>
            </Group>
          </Form>
        </div>
      </>
    </Modal>
  );
}
