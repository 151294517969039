import { useGetLawsuit } from "api/graphql/Lawsuit";
import { Loading } from "components/Loading";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { LawsuitWizard } from "./LawsuitWizard";

export function EditView({
  id,
  listURL,
  ...props
}: {
  id: UUID;
  listURL: string;
}) {
  const { data, loading } = useGetLawsuit(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.lawsuit) {
    return <Redirect to={listURL} />;
  }

  const initialValue = {
    ...data.lawsuit,
    _payment:
      data.lawsuit.amountPaid || data.lawsuit.amountOwed
        ? "pay"
        : data.lawsuit.amountReceived
        ? "receive"
        : "none",
  };

  return (
    <LawsuitWizard
      title={"Updating Lawsuit"}
      backURL={listURL}
      nextURL={listURL}
      initialValue={initialValue}
    />
  );
}
