import React, { CSSProperties, useEffect, useState } from "react";
import cn from "classnames";

export function Loading({
  timeout = 500,
  color = "var(--primary)",
  style = {},
  size,
  className,
}: {
  timeout?: number;
  color?: string;
  style?: CSSProperties;
  size?: "S" | "M" | "L" | "XL";
  className?: string;
}) {
  // Don't display a loading screen unless absolutely necessary
  // Supposedly minimizing the number of intermediate states
  // makes things feel faster!

  const [tooLong, setTooLong] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => setTooLong(true), timeout);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialHeights = {
    S: "50px",
    M: "100px",
    L: "200px",
    XL: "400px",
  };

  return (
    <div
      className={cn("big loading", className)}
      title="Loading"
      style={{
        color,
        opacity: tooLong ? 1 : 0,
        transition: "opacity 0.15s ease-out",
        height: size ? initialHeights[size] : "100%",
        minHeight: size ? initialHeights[size] : "100px",
        ...style,
      }}
    />
  );
}
