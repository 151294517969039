import { TypedTypePolicies } from "api/graphql/__generated__/apollo-helpers";

const typePolicies: TypedTypePolicies = {
  FilingProfile: {
    // FilingProfile is a singleton. This lets us access it in the cache without knowing its id
    keyFields: [],
  },
  FamilyProfile: {
    keyFields: [],
  },
  ApplicantProgress: {
    keyFields: [],
  },
  ModuleProgress: {
    keyFields: ["description"],
  },
  Query: {
    fields: {
      borrowedAsset(_, { args, toReference }) {
        return toReference({
          __typename: "BorrowedAssets",
          id: args.id,
        });
      },
      cofilable(_, { args, toReference }) {
        return toReference({
          __typename: args.typename,
          id: args.id,
        });
      },
      cosignable(_, { args, toReference }) {
        return toReference({
          __typename: args.typename,
          id: args.id,
        });
      },
      collectable(_, { args, toReference }) {
        return toReference({
          __typename: args.typename,
          id: args.id,
        });
      },
      coownable(_, { args, toReference }) {
        return toReference({
          __typename: args.typename,
          id: args.id,
        });
      },
      educationalInstitution(_, { args, toReference }) {
        return toReference({
          __typename: "EducationalInstitution",
          id: args.id,
        });
      },
      educationalStudy(_, { args, toReference }) {
        return toReference({
          __typename: "EducationalStudy",
          id: args.id,
        });
      },
      employmentRecord(_, { args, toReference }) {
        return toReference({
          __typename: "EmploymentRecord",
          id: args.id,
        });
      },
      realEstateProperty(_, { args, toReference }) {
        return toReference({
          __typename: "RealEstateProperty",
          id: args.id,
        });
      },
      realEstateMortgage(_, { args, toReference }) {
        return toReference({
          __typename: "RealEstateMortgage",
          id: args.id,
        });
      },
      creditCardDebt(_, { args, toReference }) {
        return toReference({
          __typename: "CreditCardDebt",
          id: args.id,
        });
      },
      insurancePolicy(_, { args, toReference }) {
        return toReference({
          __typename: "InsurancePolicy",
          id: args.id,
        });
      },
      farmAsset(_, { args, toReference }) {
        return toReference({
          __typename: "FarmAsset",
          id: args.id,
        });
      },
      financialAccount(_, { args, toReference }) {
        return toReference({
          __typename: "FinancialAccount",
          id: args.id,
        });
      },
      financialInvestment(_, { args, toReference }) {
        return toReference({
          __typename: "FinancialInvestment",
          id: args.id,
        });
      },
      medicalDebt(_, { args, toReference }) {
        return toReference({
          __typename: "MedicalDebt",
          id: args.id,
        });
      },
      monthlyExpense(_, { args, toReference }) {
        return toReference({
          __typename: "MonthlyExpense",
          id: args.id,
        });
      },
      mxMember(_, { args, toReference }) {
        return toReference({
          __typename: "MxMember",
          id: args.id,
        });
      },
      otherDebt(_, { args, toReference }) {
        return toReference({
          __typename: "OtherDebt",
          id: args.id,
        });
      },
      ownedBusiness(_, { args, toReference }) {
        return toReference({
          __typename: "OwnedBusiness",
          id: args.id,
        });
      },
      taxDebt(_, { args, toReference }) {
        return toReference({
          __typename: "TaxDebt",
          id: args.id,
        });
      },
      taxReturn(_, { args, toReference }) {
        return toReference({
          __typename: "TaxReturn",
          id: args.id,
        });
      },
      otherIncome(_, { args, toReference }) {
        return toReference({
          __typename: "OtherIncome",
          id: args.id,
        });
      },
      ownedVehicle(_, { args, toReference }) {
        return toReference({
          __typename: "VehicleTitle",
          id: args.id,
        });
      },
      studentLoan(_, { args, toReference }) {
        return toReference({
          __typename: "StudentLoan",
          id: args.id,
        });
      },
      vehicleLease(_, { args, toReference }) {
        return toReference({
          __typename: "VehicleLease",
          id: args.id,
        });
      },
      vehicleLoan(_, { args, toReference }) {
        return toReference({
          __typename: "VehicleLoan",
          id: args.id,
        });
      },
      bankruptcyCase(_, { args, toReference }) {
        return toReference({
          __typename: "BankruptcyCase",
          id: args.id,
        });
      },
      householdItem(_, { args, toReference }) {
        return toReference({
          __typename: "HouseholdItem",
          id: args.id,
        });
      },
      retirementAccountLoan(_, { args, toReference }) {
        return toReference({
          __typename: "RetirementAccountLoan",
          id: args.id,
        });
      },
      servicePrepayment(_, { args, toReference }) {
        return toReference({
          __typename: "ServicePrepayment",
          id: args.id,
        });
      },
      storageContainer(_, { args, toReference }) {
        return toReference({
          __typename: "StorageContainer",
          id: args.id,
        });
      },
      seizure(_, { args, toReference }) {
        return toReference({
          __typename: "Seizure",
          id: args.id,
        });
      },
      previousName(_, { args, toReference }) {
        return toReference({
          __typename: "PreviousName",
          id: args.id,
        });
      },
      lawsuit(_, { args, toReference }) {
        return toReference({
          __typename: "Lawsuit",
          id: args.id,
        });
      },
      assetLoss(_, { args, toReference }) {
        return toReference({
          __typename: "AssetLoss",
          id: args.id,
        });
      },
      assetSale(_, { args, toReference }) {
        return toReference({
          __typename: "AssetSale",
          id: args.id,
        });
      },
      environmentalCleanupNotice(_, { args, toReference }) {
        return toReference({
          __typename: "EnvironmentalCleanupNotice",
          id: args.id,
        });
      },
      consultation(_, { args, toReference }) {
        return toReference({
          __typename: "Consultation",
          id: args.id,
        });
      },
      consultationPayment(_, { args, toReference }) {
        return toReference({
          __typename: "ConsultationPayment",
          id: args.id,
        });
      },
      residentialAddress(_, { args, toReference }) {
        return toReference({
          __typename: "ResidentialAddress",
          id: args.id,
        });
      },
    },
  },
};

export default {
  typePolicies,
};
