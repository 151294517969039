import cn from "classnames";
import * as React from "react";
import css from "./styles.module.css";

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

export function Container({
  children,
  className = "",
  ...divProps
}: ContainerProps) {
  return (
    <div className={cn(css.Container, className)} {...divProps}>
      {children}
    </div>
  );
}
