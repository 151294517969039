import { useUpsertVehicleLease } from "api/graphql/VehicleLease";
import { CollectorForm } from "components/FormSections/Collector";
import { CosignerForm } from "components/FormSections/Cosigner";
import { Wizard } from "components/Wizard";
import { CollectorsSchema } from "components/WizardSteps/Collectors";
import { CosignersSchema } from "components/WizardSteps/Cosigners";
import React, { useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import Collectors from "./Collectors";
import Cosigners from "./Cosigners";
import Details, { DetailsSchema } from "./Details";
import Lender, { LenderSchema } from "./Lender";
import Vehicle, { LeasedVehicleSchema } from "./Vehicle";
import VEHICLE_LEASE_WIZARD_VEHICLE_LEASE_UPSERT from "./VehicleLeaseWizardVehicleLeaseUpsert.gql";

const VehicleLeaseSaveSchema = LeasedVehicleSchema.concat(LenderSchema).concat(
  DetailsSchema
);

export const VehicleLeaseSchema = VehicleLeaseSaveSchema.concat(
  CosignersSchema
).concat(CollectorsSchema);

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
}

export function VehicleLeaseWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: Props) {
  const { path, url } = useNested();
  const [upsertVehicleLease] = useUpsertVehicleLease<
    VehicleLeaseWizardVehicleLeaseUpsert,
    VehicleLeaseWizardVehicleLeaseUpsertVariables
  >(VEHICLE_LEASE_WIZARD_VEHICLE_LEASE_UPSERT);
  const history = useHistory();
  const [vehicleLeaseId, setVehicleLeaseId] = useState(initialValue?.id);

  const cosignersURL = "/cosigners";
  const collectorsURL = "/collectors";

  return (
    <Switch>
      <Route
        path={path("/create_cosigner")}
        render={() => (
          <CosignerForm
            title="Add a Lease Cosigner"
            returnURL={url(cosignersURL)}
            relatedId={vehicleLeaseId}
            relatedTypename="VehicleLease"
          />
        )}
      />
      <Route
        path={path("/edit_cosigner/:cosignerId")}
        render={({ match }) => (
          <CosignerForm
            title="Updating Lease Cosigner"
            returnURL={url(cosignersURL)}
            relatedId={vehicleLeaseId}
            relatedTypename="VehicleLease"
            id={match.params.cosignerId}
          />
        )}
      />

      <Route
        path={path("/create_collector")}
        render={() => (
          <CollectorForm
            title="Add a Lease Debt Collector"
            returnURL={url(collectorsURL)}
            relatedId={vehicleLeaseId}
            relatedTypename="VehicleLease"
          />
        )}
      />
      <Route
        path={path("/edit_collector/:collectorId")}
        render={({ match }) => (
          <CollectorForm
            title="Updating Lease Debt Collector"
            returnURL={url(collectorsURL)}
            relatedId={vehicleLeaseId}
            relatedTypename="VehicleLease"
            id={match.params.collectorId}
          />
        )}
      />

      <Wizard
        title={title}
        backURL={backURL}
        nextURL={nextURL}
        // We cast here because vehicle mileage and year should be strings
        initialValue={
          initialValue && {
            ...initialValue,
            year: initialValue.year && String(initialValue.year),
            mileage: initialValue.mileage && String(initialValue.mileage),
          }
        }
        vertical
        steps={[
          {
            path: "/vehicle",
            title: "Vehicle",
            component: Vehicle,
            schema: LeasedVehicleSchema,
          },
          {
            path: "/lender",
            title: "Lender",
            component: Lender,
            schema: LenderSchema,
          },
          {
            path: "/details",
            title: "Vehicle Lease Details",
            component: Details,
            schema: DetailsSchema,
            async onSave(formState) {
              const { data } = await upsertVehicleLease(formState);
              const { id } = data.vehicleLease;
              setVehicleLeaseId(id);
              setTimeout(() => {
                if (vehicleLeaseId) {
                  history.push(url(cosignersURL));
                } else {
                  history.push(`../edit/${id}${cosignersURL}`);
                }
              }, 0);
              return data.vehicleLease;
            },
          },
          {
            path: cosignersURL,
            title: "Cosigners",
            component: Cosigners,
            schema: CosignersSchema,
          },
          {
            path: collectorsURL,
            title: "Collectors",
            component: Collectors,
            schema: CollectorsSchema,
          },
        ]}
      />
    </Switch>
  );
}
