import DocumentsUpload from "components/FormSections/DocumentsUpload";
import { LearnMore } from "components/LearnMore";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { array, object } from "yup";

export const DocumentsSchema = object({
  documents: array().min(1, "Missing documentation."),
}).required();

export function Documents({ navProps, isEdit }: WizardRouteChildProps) {
  return (
    <>
      <DocumentsUpload
        relatedTypename={"OtherIncome"}
        displayText={
          <p>
            Please upload at least one record of this income in PDF format, so
            we can verify it. This could be a statement from the payment issuer
            or a bank statement, as long as it documents income from this
            source.{" "}
            <LearnMore>
              <h4>
                What if I this income source hasn’t paid me or my family member
                yet?
              </h4>
              <p>
                We&rsquo;re only concerned with income already received in the
                last six months. If you or your family member aren&rsquo;t
                receiving payments yet, feel free to omit this income source.
              </p>
            </LearnMore>
          </p>
        }
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
