import { LearnMore } from "components/LearnMore";
import { RadioGroupInput } from "components/RadioGroupInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { object, string } from "yup";
import { LoanType } from "globalTypes";

export const PublicOrPrivateSchema = object({
  _type: string()
    .oneOf(Object.values(LoanType), "Please select the type of this loan.")
    .required("Please select the type of this loan."),
});

export default function PublicOrPrivate({ navProps }: WizardRouteChildProps) {
  return (
    <>
      <RadioGroupInput
        name="_type"
        label="Is this statement for public or private loan(s)?"
        options={[
          { value: LoanType.Public, label: "Public" },
          { value: LoanType.Private, label: "Private" },
        ]}
        helpText={
          <LearnMore linkText="How to tell whether a loan is public or private">
            <p>
              <strong>Public student loans</strong> are issued to you by the
              Department of Education. Examples include Stafford, Perkins,
              Direct Plus, and Parent Plus loans.
            </p>
            <p>
              <strong>Private student loans</strong> are made by a private
              entity (often a bank), and used for educational expenses such as
              tuition, room & board, fees, books, transportation, and supplies.
            </p>
          </LearnMore>
        }
      />

      <WizardNavigation {...navProps} isComplete={false} />
    </>
  );
}
