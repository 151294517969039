import { ButtonRow } from "components/ButtonRow";
import { Card } from "components/Card";
import { Modal } from "components/Modal";
import React from "react";

interface Props {
  isOpen: boolean;
  onClose: () => any;
  onContinue: () => any;
  employmentRecord: ArrayItemType<EmploymentRecordsModule["employmentRecords"]>;
}

export default function ApiEmployerEditConfirmationModal({
  isOpen,
  onClose,
  onContinue,
  employmentRecord,
}: Props) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Card>
        <div>
          <p>
            You have chosen to import your employment information for this
            employer. It cannot be edited.
          </p>
        </div>
        <ButtonRow
          right={
            <>
              <button className="btn primary modalButton" onClick={onClose}>
                Ok
              </button>
            </>
          }
        />
      </Card>
    </Modal>
  );
}
