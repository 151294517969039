import { LearnMore } from "components/LearnMore";
import * as React from "react";

export function ListHeaderDescription() {
  return (
    <p>
      We need to know about all paid educational studies you have started after
      high school, including programs you studied for but did not complete.{" "}
      <LearnMore>
        <h4>What type of schools or educational studies should I include?</h4>
        <p>
          If you paid, took loans for, or received a scholarship to attend a
          university, community college, or trade school, you should include
          that. If you paid for a certification or nano-degree, you should not
          include that.
        </p>

        <h4>What is a trade school?</h4>
        <p>
          A trade school is a vocational or technical school that is designed to
          give students the technical skills necessary to prepare the student
          for a specific occupation. Some commonly offered trade school programs
          include electrical work, cosmetology, nursing, cooking, software
          development, and massage therapy.
        </p>

        <h4>Should I include programs I began but did not complete?</h4>
        <p>If you paid for it and attended any classes, yes.</p>
      </LearnMore>
    </p>
  );
}
