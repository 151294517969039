export default function () {
  if (
    document.getElementById("assess-form") ||
    document.getElementById("assess-form-review")
  ) {
    import("./assess").then(({ initAssess }) => initAssess());
  }

  if (
    document.getElementById("verification-email-sent") ||
    document.getElementById("unverified-home")
  ) {
    import("./auth").then(({ initAuth }) => initAuth());
  }

  if (document.getElementById("referral-registration")) {
    import("./registration").then(({ initRegistration }) => initRegistration());
  }
}
