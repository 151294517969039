import cn from "classnames";
import { YupSchemaContext } from "components/Form";
import { MaskedInput } from "components/MaskedInput";
import { Select } from "components/Select";
import { TextInput, TextInputCommonProps } from "components/TextInput";
import {
  format,
  formatISO,
  isValid,
  parse,
  parseISO,
  startOfDay,
  startOfToday,
} from "date-fns";
import { set } from "lodash";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FaCalendar, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { RootOverlay } from "../DateInput/RootOverlay";
import styles from "./MonthInput.module.css";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const inputFormat = "MM/yyyy";
const outputFormat = "yyyy-MM-dd";

interface MonthInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "width">,
    TextInputCommonProps {
  value?: ISODate;
  onMonthChanged?: (month: ISODate) => any;
  /** Disable a given month by returning true when it's passed into this function.
   *
   * You can also return a string that will be displayed as an error message if the user
   * enters a disabled month in the input.
   */
  disabledMonths?: (month: Date) => boolean | string;
  minYear?: number;
  maxYear?: number;
}

/** An input field and associated pop-up picker and validation for selecting a month.
 *
 * Compatible with <Form></Form>
 */
function MonthInput({
  name,
  onChange: onMonthChanged = () => null,
  placeholder = inputFormat.toUpperCase(),
  disabledMonths = () => false,
  value,
  id,
  className,
  minYear,
  defaultValue,
  maxYear,
  style,
  ...otherProps
}: Omit<MonthInputProps, "onChange"> & {
  onChange?: MonthInputProps["onMonthChanged"];
}) {
  const initialDate = parseISO(`${defaultValue}`);
  const [isOpen, setOpen] = React.useState(false);
  const wrapperRef = React.useRef(document.createElement("div"));
  const inputRef = React.useRef(document.createElement("input"));
  const formGroupRef = React.useRef();
  const [inputValue, setInputValue] = React.useState(
    isValid(initialDate) ? format(initialDate, inputFormat) : ""
  );
  const today = startOfToday();
  const [year, setYear] = React.useState(
    isValid(initialDate) ? initialDate.getFullYear() : today.getFullYear()
  );
  const schema = React.useContext(YupSchemaContext);
  const form = useFormContext();

  const close = () => {
    setOpen(false);
  };
  const open = () => {
    setOpen(true);
  };

  const onBlur = () => {
    setTimeout(() => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(document.activeElement) && // remain open on button clicks inside the picker
        document.activeElement !== document.body // remain open on non-focusable clicks inside the picker
      ) {
        close();
      }
    }, 0);
  };

  React.useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      if (
        !(
          wrapperRef.current?.contains(e.target as Node) ||
          // @ts-ignore
          formGroupRef.current?.contains(e.target as Node)
        )
      ) {
        close();
      }
    };
    document.body.addEventListener("click", handleDocumentClick);
    return () =>
      document.body.removeEventListener("click", handleDocumentClick);
  }, []);

  const updateValue = (date: Date) => {
    if (isValid(date) && date.getFullYear() > 999) {
      setYear(date.getFullYear());
      onMonthChanged(format(date, outputFormat));
    } else {
      onMonthChanged("");
    }
  };

  React.useEffect(() => {
    if (!value) return;
    const date = parseISO(value);
    if (isValid(date)) {
      setYear(date.getFullYear());
      setInputValue(format(date, inputFormat));
    }
  }, [value]);

  const schemaDisabledMonth = (date: Date) => {
    if (!schema || !form) return false;
    try {
      schema.validateSyncAt(
        name,
        set(form.getValues(), name, formatISO(startOfDay(date)))
      );
      return false;
    } catch {
      return true;
    }
  };

  const monthIsDisabled = (month: Date) => {
    return !!disabledMonths(month) || schemaDisabledMonth(month);
  };

  const onMonthClick = (monthIndex: number) => {
    if (disabledMonths(new Date(year, monthIndex))) return;
    inputRef.current.focus();
    close();
    const newDate = new Date(year, monthIndex);
    updateValue(newDate);
    setInputValue(format(newDate, inputFormat));
  };

  const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    const parsed = parse(e.target.value, inputFormat, new Date());
    updateValue(parsed);
  };

  return (
    <div ref={formGroupRef} onBlur={onBlur} style={style}>
      <MaskedInput
        type="text"
        value={inputValue}
        onChange={onInputChanged}
        onFocus={open}
        onClick={open}
        placeholder={placeholder}
        autoComplete="nope"
        mask={[/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
        {...otherProps}
        id={id}
        className={className}
        ref={inputRef}
      />

      {isOpen && (
        <RootOverlay ref={wrapperRef} attachTo={formGroupRef.current}>
          <div className={styles.monthNav}>
            <button
              type="button"
              className={cn("btn flat icon", styles.iconLink)}
              tabIndex={-1}
              title="Previous year"
              disabled={year <= minYear}
              onClick={() => setYear((y) => y - 1)}
            >
              <FaChevronLeft />
            </button>

            <Select
              className={styles.year}
              value={year}
              onChange={(e) => setYear(parseInt(e.target.value))}
              emptyOption={false}
              placeholder="Year"
              options={[...Array(maxYear - minYear + 1).keys()].map((i) => {
                const year = maxYear - i;
                return {
                  label: year.toString(),
                  value: year.toString(),
                };
              })}
            />

            <button
              type="button"
              className={cn("btn flat icon", styles.iconLink)}
              tabIndex={-1}
              title="Next year"
              disabled={year >= maxYear}
              onClick={() => setYear((y) => y + 1)}
            >
              <FaChevronRight />
            </button>
          </div>
          <div className={styles.monthContainer}>
            {MONTHS.map((name, index) => (
              <button
                type="button"
                tabIndex={-1}
                onClick={() => onMonthClick(index)}
                disabled={!!monthIsDisabled(new Date(year, index))}
                className={cn("btn flat icon", styles.month, {
                  [styles.selected]:
                    inputValue === format(new Date(year, index), inputFormat),
                  [styles.currentMonth]:
                    index === today.getMonth() && year === today.getFullYear(),
                })}
                key={name}
              >
                {name}
              </button>
            ))}
          </div>
        </RootOverlay>
      )}
    </div>
  );
}

function Wrapped({
  name,
  rules = {},
  value,
  minYear = 1920,
  maxYear = startOfToday().getFullYear(),
  width = "200px",
  id,
  label,
  learnMore,
  helpText,
  onChange,
  addOnAfter = <FaCalendar />,
  addOnBefore,
  ...props
}: MonthInputProps) {
  // Because the user can enter a month as text, we need to ensure they haven't
  // entered a disabled month
  const rulesWithDisabled = {
    ...rules,
    validate: {
      ...rules.validate,
      validMonth: (v: string) => {
        if (!props.disabledMonths) return true;
        const isDisabled = props.disabledMonths(
          parse(v, outputFormat, new Date())
        );
        return typeof isDisabled === "string" ? isDisabled : !isDisabled;
      },
      validYear: (v: string) => {
        const date = parse(v, outputFormat, new Date());
        if (date.getFullYear() < minYear || date.getFullYear() > maxYear) {
          return `Please enter a month between ${minYear} and ${maxYear}.`;
        }
        return true;
      },
    },
  };
  const form = useFormContext();

  const getInput = () => (
    <MonthInput
      minYear={minYear}
      maxYear={maxYear}
      name={name}
      value={value}
      rules={rulesWithDisabled}
      {...props}
    />
  );

  return (
    <TextInput
      className={styles.root}
      width={width}
      id={id}
      label={label}
      name={name}
      learnMore={learnMore}
      helpText={helpText}
      rules={rulesWithDisabled}
      register={false}
      addOnAfter={addOnAfter}
      addOnBefore={addOnBefore}
      inputElement={
        form && name ? (
          <Controller name={name} as={getInput()} rules={rulesWithDisabled} />
        ) : (
          getInput()
        )
      }
    />
  );
}

export { Wrapped as MonthInput };
