import { useUpsertEducationalStudy } from "api/graphql/EducationalStudy";
import { Wizard } from "components/Wizard";
import React, { useState } from "react";
import { Overview, OverviewSchema } from "./Overview";
import { YearsAttended, YearsAttendedSchema } from "./YearsAttended";

export const EducationSchema = OverviewSchema.concat(YearsAttendedSchema);

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
}

export function EducationWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: Props) {
  const [upsertEducationalStudy] = useUpsertEducationalStudy();
  const [educationalStudyId, setEducationalStudyId] = useState(
    initialValue?.id
  );
  return (
    <Wizard
      title={title}
      backURL={backURL}
      nextURL={nextURL}
      initialValue={initialValue && EducationSchema.cast(initialValue)}
      vertical
      steps={[
        {
          path: "/overview",
          title: "Name & degree",
          component: Overview,
          schema: OverviewSchema,
        },
        {
          path: "/years_attended",
          title: "Years attended",
          component: YearsAttended,
          schema: YearsAttendedSchema,
          async onSave(formState) {
            const { data } = await upsertEducationalStudy(formState);
            const { id } = data.educationalStudy;
            setEducationalStudyId(id);
            return data.educationalStudy;
          },
        },
      ]}
    />
  );
}
