import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaCarAlt } from "react-icons/fa";
import VehicleLeasesSummary from "./Summary";
import { VehicleLeases } from "./VehicleLeases";

const VehicleLeasesSubmodule: SubModule = {
  path: "vehicle_leases",
  title: "Vehicle Leases",
  icon: FaCarAlt,
  component: VehicleLeases,
  summary: VehicleLeasesSummary,
  statusName: "vehicleLeases",
};

export default VehicleLeasesSubmodule;
