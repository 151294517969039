import { find } from "lodash";
import React, { useCallback, useRef } from "react";
import Helmet from "react-helmet";
import { FaMapMarker } from "react-icons/fa";
import { GOOGLE_PLACES_API_KEY } from "utils/constants/openSecrets";
import TypeAhead from "../TypeAhead";
import GoogleLogo from "./powered_by_google.png";

interface Props {
  /** The CSS selector of the input to attach to. */
  inputSelector: string;
  /** The function to be called when a place suggestion is selected by the user. */
  onPlaceSelected: (result: AutocompleteResult) => void;
}

export type AutocompleteResult = {
  line1: string;
  city: string;
  state: string;
  zipCode: string;
  result: google.maps.places.PlaceResult;
};

/** Renders a dropdown allowing users to autofill an address based on an input's value.
 *
 * The component should be placed in a location in the DOM where attributions
 * can be rendered (as text) if they exist (they usually don't). Using a component
 * is also required so we can load the maps SDK. The picker UI will be rendered
 * under the input, not inside the component.
 */
export default function AutocompleteAddress({
  inputSelector,
  onPlaceSelected,
}: Props) {
  const sessionToken = useRef<google.maps.places.AutocompleteSessionToken>();
  const refreshToken = () => {
    sessionToken.current = new window.google.maps.places.AutocompleteSessionToken();
  };

  const attributionsRef = useRef();

  const getSuggestions = useCallback((input: string): Promise<
    google.maps.places.AutocompletePrediction[]
  > => {
    if (typeof window.google === "undefined" || input.length === 0) {
      return Promise.resolve([]);
    }
    const { places } = window.google.maps;

    // We have to make sure window.google exists before we generate
    // the first session token.
    if (!sessionToken.current) {
      refreshToken();
    }

    return new Promise((res) => {
      new places.AutocompleteService().getPlacePredictions(
        {
          input,
          sessionToken: sessionToken.current,
          types: ["address"],
        },
        (results, status) => {
          if (status === places.PlacesServiceStatus.OVER_QUERY_LIMIT) {
            // This is a progressive enhancement and we met our quota
            // limit, goodbye
            console.error("Google Places API Quota exceeded.");
            return;
          }

          if (status === places.PlacesServiceStatus.ZERO_RESULTS || !results) {
            res([]);
            return;
          }

          res(results);
        }
      );
    });
  }, []);

  const getPlaceDetails = (placeId: string) => {
    return new Promise<AutocompleteResult>((res) => {
      new window.google.maps.places.PlacesService(
        attributionsRef.current
      ).getDetails(
        { placeId, sessionToken: sessionToken.current },
        (result) => {
          function getAddressComponent(attribute: string) {
            return find(result.address_components, (c) =>
              c.types.includes(attribute)
            );
          }

          refreshToken();

          const number = getAddressComponent("street_number")?.long_name || "";
          const street = getAddressComponent("route")?.long_name || "";

          res({
            line1: (number || street) && `${number} ${street}`.trim(),
            city: getAddressComponent("locality")?.long_name || "",
            state:
              getAddressComponent("administrative_area_level_1")?.short_name ||
              "",
            zipCode: getAddressComponent("postal_code")?.long_name || "",
            result,
          });
        }
      );
    });
  };

  return (
    <>
      {/* Prevent places SDK from being loaded twice */}
      {typeof window?.google?.maps?.places === "undefined" && (
        <Helmet>
          <script
            defer
            src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places`}
          />
        </Helmet>
      )}

      <div ref={attributionsRef} />

      <TypeAhead<google.maps.places.AutocompletePrediction>
        inputSelector={inputSelector}
        getSuggestions={getSuggestions}
        onSelect={async (place) => {
          const r = await getPlaceDetails(place.place_id);
          onPlaceSelected(r);
        }}
        formatSuggestion={(place) => (
          <>
            <FaMapMarker />
            <span>{place.description}</span>
          </>
        )}
        footer={<img src={GoogleLogo} />}
      />
    </>
  );
}
