import { useQuery } from "@apollo/client";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import GET_MORTGAGE_FORM_MORTGAGE from "./GetMortgageFormMortgage.gql";
import { MortgageWizard } from "./MortgageWizard";

interface Props {
  id: string;
  listURL: string;
}

export default function EditView({ id, listURL }: Props) {
  const { data, loading } = useQuery<
    GetMortgageFormMortgage,
    GetMortgageFormMortgageVariables
  >(GET_MORTGAGE_FORM_MORTGAGE, {
    variables: { id },
  });

  if (loading) {
    return <Loading />;
  } else if (!data?.realEstateMortgage) {
    return <Redirect to={listURL} />;
  }

  return (
    <MortgageWizard
      title="Updating Mortgage"
      backURL={listURL}
      nextURL={listURL}
      initialValue={data.realEstateMortgage}
    />
  );
}
