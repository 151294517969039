import BankruptcyCases from "components/FormModules/BankruptcyCases";
import PictureIdSubmodule from "components/FormModules/Legal/PhotoIdDocuments";
import PreviousNames from "components/FormModules/Legal/PreviousNames";
import CreditCounseling from "components/FormModules/Legal/CreditCounseling";
import TaxpayerIdSubmodule from "components/FormModules/Legal/TaxpayerIdDocuments";
import TaxReturns from "components/FormModules/TaxReturns";
import MiscDocumentsSubmodule from "./MiscDocuments";
import SubMenuModule from "components/FormModuleSubMenu";
import React from "react";
import { FaGavel } from "react-icons/fa";
import CofilerSubmodule from "./Cofiler";
import Consultations from "./Consultations";
import EnvironmentalCleanupNotices from "./EnvironmentalCleanupNotices";
import Lawsuits from "./Lawsuits";
import Seizures from "./Seizures";
import LegalSummary from "./Summary";

export type LegalProps = {
  next?: string;
  prev?: string;
  subMenuContainerID?: string;
};

const legalModules = [
  CofilerSubmodule,
  BankruptcyCases,
  CreditCounseling,
  TaxReturns,
  Seizures,
  PreviousNames,
  Lawsuits,
  EnvironmentalCleanupNotices,
  Consultations,
  PictureIdSubmodule,
  TaxpayerIdSubmodule,
  MiscDocumentsSubmodule,
];
export const legalStatusNames = legalModules.map((m) => m.statusName);

export function Legal({ prev, subMenuContainerID }: LegalProps) {
  return (
    <SubMenuModule
      subMenuContainerID={subMenuContainerID}
      title="Legal Matters"
      dashboardURL={prev}
      Index={LegalSummary}
      subModules={legalModules}
    />
  );
}

Legal.Icon = FaGavel;
