import { DecimalInput, DecimalInputProps } from "components/NumberInput";
import React from "react";

export default function DollarsInput(props: DecimalInputProps) {
  return (
    <DecimalInput
      includeThousandsSeparator
      allowDecimal
      decimalLimit={2}
      addOnBefore="$"
      {...props}
    />
  );
}
