import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaComments } from "react-icons/fa";
import { CreditCounseling } from "./CreditCounseling";
import CreditCounselingSummary from "./Summary";

const CreditCounselingSubmodule: SubModule = {
  path: "credit_counseling",
  icon: FaComments,
  title: "Credit Counseling",
  component: CreditCounseling,
  summary: CreditCounselingSummary,
  statusName: "creditCounseling",
};

export default CreditCounselingSubmodule;
