import { useQuery } from "@apollo/client";
import { useDeleteAssetSale } from "api/graphql/AssetSale";
import { ButtonRow } from "components/ButtonRow";
import { ListItem } from "components/ListItem";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import {
  formatErrorsFromSchema,
  formatName,
  formatOxfordComma,
} from "utils/formatters";
import { useNested } from "utils/hooks";
import SEIZURES_MODULE from "./AssetSalesModule.gql";
import { AssetSaleSchema } from "./AssetSaleWizard";

interface Props {
  onEdit: (id: string) => any;
  createURL: string;
  nextURL: string;
}

export function ListView({ onEdit, createURL, nextURL }: Props) {
  const { data, loading } = useQuery<AssetSalesModule>(SEIZURES_MODULE);
  const [onDelete] = useDeleteAssetSale();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { assetSales: items } = data;

  const saleExamples = (
    <ul>
      <li>Real estate sales</li>
      <li>Personal property sales</li>
      <li>Automobiles sales</li>
      <li>
        Property transferred to a former spouse in a property settlement
        agreement
      </li>
      <li>Property transferred to a trust</li>
      <li>
        Transfers made as security (e.g. granting of a security interest or
        mortgage on your property)
      </li>
    </ul>
  );

  return (
    <>
      <Title>Your Sold Assets</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              nextURL={nextURL}
              createURL={createURL}
              filingProfileBoolean="hasNoAssetSales"
              addText="Add a sold asset"
              question={
                <>
                  <p className="required">
                    <strong>
                      Have you have sold or transferred any assets or property
                      in the last two years?
                    </strong>
                  </p>

                  <p>
                    Examples you should report include but are not limited to:
                  </p>

                  {saleExamples}
                </>
              }
              confirmation={
                <p>
                  You have indicated that you haven’t sold or transferred any
                  assets or property in the last two years. If that’s correct,
                  you’re ready to continue to the next section. Otherwise, you
                  may add sold assets below.
                </p>
              }
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Tell us about all the assets you have sold or transferred in the
                last two years. Examples of asset sales you should report
                include but are not limited to:
              </p>

              {saleExamples}

              <ButtonRow
                style={{ marginBottom: "8px" }}
                left={
                  <Link to={createURL} className="btn secondary">
                    <FaPlus />
                    <span>Add a sold asset</span>
                  </Link>
                }
                right={
                  <Link to={nextURL} className="btn primary">
                    <span>Done</span>
                    <FaCheck />
                  </Link>
                }
              />

              {items.map((sale) => {
                return (
                  <ListItem
                    key={sale.id}
                    onEdit={() => onEdit(sale.id)}
                    onDelete={() => onDelete(sale.id)}
                    objectName="saleSale"
                  >
                    <div>
                      {formatErrorsFromSchema(AssetSaleSchema, sale)}
                      {sale.itemDescription}{" "}
                      {!!sale.saleDate || (!!sale.buyers.length && " sold")}
                      {!!sale.buyers.length &&
                        ` to ${formatOxfordComma(
                          ...sale.buyers.map(formatName)
                        )}`}
                      {sale.saleDate && ` on ${sale.saleDate} `}
                    </div>
                  </ListItem>
                );
              })}
            </>
          )}
        />
      </Switch>
    </>
  );
}
