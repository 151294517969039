import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaBusinessTime } from "react-icons/fa";
import { ServicePrepayments } from "./ServicePrepayments";
import PrepaymentsSummary from "./Summary";

const ServicePrepaymentsSubmodule: SubModule = {
  path: "security_deposits",
  icon: FaBusinessTime,
  title: "Prepayments",
  component: ServicePrepayments,
  summary: PrepaymentsSummary,
  statusName: "servicePrepayments",
};

export default ServicePrepaymentsSubmodule;
