import { LearnMore } from "components/LearnMore";
import { MonthlyExpenseCategoryChoice } from "globalTypes";
import React from "react";
import { FaMobile, FaPhone, FaTv, FaWifi } from "react-icons/fa";
import { ExpensesCategoryDetail } from "../../CategorySection/CategoryDetail";
import { LearnMoreCommon } from "../../LearnMoreCommon";
import TelecommunicationsLearnMoreCommon from "./TelecommunicationsLearnMoreCommon";

export const homeInternetCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.TelephonyInternet,
  displayName: "Home Internet",
  displayLowercaseName: "home internet",
  hasNoProfileBooleanName: "hasNoTelephonyInternetExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on home internet service(s) over the last six months? Be sure to include spending for all household members.",
  urlPathName: "home_internet",
  menuIcon: FaWifi,
  description: (
    <>
      <p>
        Home internet expenses include payments you make for maintaining
        internet connections at real estate property owned by a member of your
        household.
      </p>

      <p>
        <LearnMore>
          <TelecommunicationsLearnMoreCommon />
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};

export const homePhoneCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.TelephonyLandline,
  displayName: "Home Phone",
  displayLowercaseName: "home phone",
  hasNoProfileBooleanName: "hasNoTelephonyLandlineExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on home phone service(s) over the last six months? Be sure to include spending for all household members.",
  urlPathName: "home_phone",
  menuIcon: FaPhone,
  description: (
    <>
      <p>
        Home phone expenses include payments you make for maintaining service
        for non-mobile phone connections at real estate property owned or rented
        by a member of your household.
      </p>

      <p>
        <LearnMore>
          <TelecommunicationsLearnMoreCommon />
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};

export const mobilePhoneCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.TelephonyMobilePhone,
  displayName: "Mobile Phone",
  displayLowercaseName: "mobile phone",
  hasNoProfileBooleanName: "hasNoTelephonyMobileExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on mobile phone service(s) over the last six months? Be sure to include spending for all household members.",
  urlPathName: "mobile_phone",
  menuIcon: FaMobile,
  description: (
    <>
      <p>
        These expenses include payments you make for maintaining service for
        mobile phones owned by a member of your household.
      </p>

      <p>
        <LearnMore>
          <TelecommunicationsLearnMoreCommon />
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};

export const televisionCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.TelephonyTelevision,
  displayName: "Television",
  displayLowercaseName: "television",
  hasNoProfileBooleanName: "hasNoTelephonyTelevisionExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on television service(s) over the last six months? Be sure to include spending for all household members.",
  urlPathName: "television",
  menuIcon: FaTv,
  description: (
    <>
      <p>
        Television related expenses include but are not limited to purchases of:
      </p>
      <ul>
        <li>cable</li>
        <li>satellite</li>
        <li>premium channels</li>
        <li>streaming TV</li>
      </ul>

      <p>
        <LearnMore>
          <TelecommunicationsLearnMoreCommon />
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};
