import React from "react";
import { useMutation } from "@apollo/client";

import GENERATE_EMPLOYER_LINK_TOKEN from "./GenerateEmployerLinkToken.gql";

export function useGenerateEmployerLinkToken(
  query = GENERATE_EMPLOYER_LINK_TOKEN
) {
  const [doMutation] = useMutation(GENERATE_EMPLOYER_LINK_TOKEN);
  return React.useCallback(
    (input) => doMutation({ variables: { input } }),
    [doMutation]
  );
}
