import { QueryHookOptions, useMutation, useQuery } from "@apollo/client";
import { updateQuery, useDeleteEvict, useUpsert } from "api/graphql/utils";
import { omit, uniqBy } from "lodash";
import * as React from "react";
import stripTypename from "utils/tests/apollo/stripTypename";
import DELETE_FAMILY_MEMBER_RECORD from "./DeleteFamilyMemberRecord.gql";
import GET_FAMILY_PROFILE from "./GetFamilyProfile.gql";
import UPDATE_FAMILY_MEMBER_RECORD from "./UpdateFamilyMemberRecord.gql";
import UPDATE_FAMILY_PROFILE from "./UpdateFamilyProfile.gql";

export function useGetFamilyProfile(
  options?: QueryHookOptions<GetFamilyProfile>
) {
  return useQuery<GetFamilyProfile>(GET_FAMILY_PROFILE, options);
}

export function useUpdateFamilyProfile() {
  const [update] = useMutation<UpdateFamilyProfile>(UPDATE_FAMILY_PROFILE);
  return React.useCallback(
    (updatedData) => {
      const input = stripTypename(
        omit(updatedData, ["familyMembers", "eligibleFamilyMembers"])
      );
      return update({
        variables: { input },
      });
    },
    [update]
  );
}

export function useDeleteFamilyMemberRecord() {
  return useDeleteEvict(
    DELETE_FAMILY_MEMBER_RECORD,
    "deleteFamilyMemberRecord",
    "FamilyMemberRecord",
    (cache, _, data) => {
      const relatedPersonId = data.deleteFamilyMemberRecord.relatedPerson.id;
      if (relatedPersonId === null) {
        // the person was actually deleted
        return;
      } else {
        // the person still exists somewhere else, so add them back to the
        // eligible person list
        cache.modify({
          id: cache.identify({ __typename: "FamilyProfile" }),
          fields: {
            eligibleFamilyMembers: (existing, { toReference }) => [
              ...existing,
              toReference(data.deleteFamilyMemberRecord.relatedPerson),
            ],
          },
        });
      }
    }
  );
}

export function useUpsertFamilyMemberRecord() {
  return useUpsert(
    UPDATE_FAMILY_MEMBER_RECORD,
    GET_FAMILY_PROFILE,
    "familyMemberRecord",
    "familyProfile.familyMembers",
    (cache, data) => {
      const newlyAddedMember = data.familyMemberRecord.relatedPerson;
      updateQuery<GetFamilyProfile>(
        cache,
        GET_FAMILY_PROFILE,
        (state: Partial<GetFamilyProfile>) => ({
          familyProfile: {
            ...state.familyProfile,
            hasNoFamilyMembers: false,
            familyMembers: uniqBy(
              [...state.familyProfile.familyMembers, data.familyMemberRecord],
              "id"
            ),
            eligibleFamilyMembers:
              state.familyProfile.eligibleFamilyMembers.filter(
                (
                  eligibleFamilyMember: ArrayItemType<
                    GetFamilyProfile["familyProfile"]["familyMembers"]
                  >["relatedPerson"]
                ) => eligibleFamilyMember.id !== newlyAddedMember.id
              ),
          },
        })
      );
    }
  );
}
