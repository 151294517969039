import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { required } from "utils/defaultMessages";
import { IDLearnMore } from "./IDLearnMore";
import PrivacyInput from "../PrivacyInput";

export enum IDType {
  SSN = "Social security number",
  ITIN = "Taxpayer ID number",
}

interface Props {
  name?: string;
  required?: boolean;
  /** Whether the value has been saved to the backend. Determines placeholder and overrides `required`. */
  isSaved?: boolean;
  /** Whether the saved value is an ITIN. Determines the label when the input is empty. */
  isITIN?: boolean;
  width?: string;
  label?: string;
  showLearnMore?: boolean;
}

export function IDInput({
  name,
  required: isRequired = false,
  isITIN = false,
  isSaved = false,
  width = "240px",
  label,
  showLearnMore = true,
}: Props) {
  const initialType = isITIN ? IDType.ITIN : IDType.SSN;
  const [idType, setIDType] = useState(initialType);
  const [currentValue, setValue] = useState("");

  const isHot = currentValue || (!isSaved && required);
  const rules = isHot && {
    required: isRequired && !isSaved && required,
    pattern: {
      value: /^\d\d\d-?\d\d-?\d\d\d\d$/,
      message: `Please enter a valid SSN or TIN`,
    },
  };

  const onIDChange = (value: string) => {
    setValue(value);
    if (!value) {
      return setIDType(initialType);
    }

    const cleaned = value.replace(/-/g, "");
    const isNowITIN =
      cleaned && cleaned[0] === "9" && ["7", "8"].includes(cleaned[3]);

    if (isNowITIN) {
      setIDType(IDType.ITIN);
    } else {
      setIDType(IDType.SSN);
    }
  };

  return (
    <PrivacyInput
      name={name}
      width={width}
      rules={rules}
      onChange={onIDChange}
      label={label || idType}
      placeholder={isSaved ? "●●●●●●●●●●●" : "123-45-6789"}
      mask={[/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
      learnMore={showLearnMore && <IDLearnMore />}
    />
  );
}

IDInput.idTypes = IDType;
