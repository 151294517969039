import { BooleanInput } from "components/BooleanInput";
import { RadioGroupInput } from "components/RadioGroupInput";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import { useFormContext } from "react-hook-form";
import { DecimalSchema } from "utils/validators/yup";
import { boolean, object, string } from "yup";

export const FinancialsSchema = object({
  isDependent: boolean().required("This question is required."),
  _childSupport: string()
    .oneOf(["none", "pay", "receive"], "This field is required.")
    .required("This field is required"),
  childSupportAmount: DecimalSchema.when("_childSupport", {
    is: "none",
    then: DecimalSchema.optional().nullable(),
    otherwise: DecimalSchema.required("This question is required.").numMin(
      0.000001,
      "You must provide the child support amount or mark that there is no child support."
    ),
  }),
  isBackChildSupportOwed: boolean().when("_childSupport", {
    is: "none",
    then: boolean().optional().nullable(),
    otherwise: boolean().required("This question is required."),
  }),
  overdueChildSupportAmount: DecimalSchema.when("isBackChildSupportOwed", {
    is: false,
    then: DecimalSchema.optional().nullable(),
    otherwise: DecimalSchema.required("This question is required.").numMin(
      0.000001,
      "You must provide the overdue child support amount or mark that there is no overdue child support."
    ),
  }),
}).required();

export function Financials(props: WizardRouteChildProps) {
  const form = useFormContext();
  return (
    <>
      <BooleanInput
        name="isDependent"
        label={`Is ${
          form.watch("relatedPerson.firstName") || "this person"
        } your dependent?`}
      />

      <RadioGroupInput
        label="Do you pay or receive child support for them?"
        options={[
          { label: "I do not receive or pay child support", value: "none" },
          { label: "I pay child support", value: "pay" },
          { label: "I receive child support", value: "receive" },
        ]}
        name="_childSupport"
        onChange={(value) => {
          if (value == "none") {
            form.setValue("overdueChildSupportAmount", null);
            form.setValue("isBackChildSupportOwed", false);
            form.setValue("childSupportAmount", null);
          }
        }}
      />
      <div
        style={{
          display:
            form.watch("_childSupport") &&
            form.watch("_childSupport") !== "none"
              ? "block"
              : "none",
        }}
      >
        <div className="form-row">
          <TextInput
            name="childSupportAmount"
            label={`What is the total amount you ${props.form.watch(
              "_childSupport"
            )} in child support per month?`}
            addOnBefore="$"
            title="Monthly child support"
            rules={{ required: true }}
          />
        </div>

        <div className="form-row">
          <BooleanInput
            name="isBackChildSupportOwed"
            label={`Is overdue child support owed for ${
              form.watch("relatedPerson.firstName") || "this person"
            }?`}
            onChange={(value) => {
              if (!value) {
                form.setValue("overdueChildSupportAmount", null);
              }
            }}
          />
        </div>

        <div
          className="form-row"
          style={{
            display: form.watch("isBackChildSupportOwed") ? "flex" : "none",
          }}
        >
          <TextInput
            name="overdueChildSupportAmount"
            label={`What is the total overdue amount owed of child support?`}
            addOnBefore="$"
            title="Monthly child support"
            rules={{ required: true }}
          />
        </div>
      </div>
      <WizardNavigation {...props.navProps} />
    </>
  );
}
