import * as React from "react";

export type CssUnitType =
  | "%"
  | "cm"
  | "mm"
  | "Q"
  | "in"
  | "pc"
  | "pt"
  | "px"
  | "em"
  | "ex"
  | "ch"
  | "rem"
  | "lh"
  | "vw"
  | "vmin"
  | "vmax";

export const useCssVariable = (
  ref: React.MutableRefObject<HTMLElement>,
  variableName: string,
  unit?: CssUnitType
) => {
  const name = variableName.replace(/--/, "");
  return React.useMemo(
    () =>
      new Proxy<{ current: number | string }>(
        { current: "" },
        {
          get(target, prop) {
            if (prop === "current") {
              return ref.current?.style.getPropertyValue(`--${name}`);
            }
          },
          set(target, prop, value: string | number | undefined) {
            if (prop !== "current" || !ref.current) return false;
            ref.current?.style.setProperty(
              `--${name}`,
              value === undefined ? "" : `${value}${unit || ""}`
            );
            return true;
          },
        }
      ),
    [variableName, ref]
  );
};
