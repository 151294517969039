import { WizardNavigation } from "components/WizardNavigation";
import { Form } from "components/Form";
import { TextArea } from "components/TextArea";
import { object, string } from "yup";
import { useUpsertGigIncome } from "api/graphql/GigIncome";
import { useHistory } from "react-router-dom";

const GigIncomeSchema = object({
  description: string(),
}).required();
const defaultValues = {
  description: null,
};
export function CreateView({ listURL, ...props }: { listURL: string }) {
  const history = useHistory();
  const [upsertGigIncome] = useUpsertGigIncome();

  return (
    <Form
      schema={GigIncomeSchema}
      onSubmit={async (formState) => {
        await upsertGigIncome(formState);
        history.push(listURL);
      }}
      initialValue={{ ...defaultValues }}
    >
      <>
        <TextArea
          name="description"
          required
          label="Describe your gig work income"
          placeholder="For example: Doordash, delivered food 3 nights a week. Drove for Uber 20-30 hours a week."
          rows={5}
        />
        <WizardNavigation backURL={listURL} isComplete={true} />
      </>
    </Form>
  );
}
