import { LearnMore } from "components/LearnMore";
import { MonthInput } from "components/MonthInput";
import { DecimalInput } from "components/NumberInput";
import { TextArea } from "components/TextArea";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { DateSchema, DecimalSchema } from "utils/validators/yup";
import { object, string } from "yup";

export const ItemSchema = object({
  itemDescription: string().required(
    "Please describe the item or asset that was seized."
  ),
  itemTakenDate: DateSchema.nullable(),
  itemValue: DecimalSchema.required("This field is required.").test(
    "min",
    "Must be greater than zero.",
    (value: string) => value && Number(value.replace(/,/g, "")) > 0
  ),
}).required();

export function Item({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <TextArea
          name="itemDescription"
          label="Description"
          placeholder={
            "Describe the item that was seized." +
            "\n\n" +
            "Example: " +
            "$1600 in wages was garnished over the last three months by the IRS to repay my tax debts."
          }
          rows={5}
          autoFocus={!isEdit}
        />
      </div>
      <div className="form-row">
        <DecimalInput
          name="itemValue"
          label="Value"
          addOnBefore="$"
          includeThousandsSeparator
          allowDecimal
          learnMore={
            <LearnMore>
              <h4>What if I don’t know the value?</h4>
              <p>
                If it is a physical item, check ebay for recent sales. Otherwise
                contact our support team.
              </p>
            </LearnMore>
          }
        />
        <MonthInput
          name="itemTakenDate"
          label="Seizure Month"
          width="175px"
          rules={{ required: true }}
          learnMore={
            <LearnMore>
              <h4>
                What if the creditor hasn’t taken my asset yet, but is in the
                process of seizing it?
              </h4>
              <p>
                If your creditor has stated that they intend to seize the asset
                within the next 90 days, put down the expected seizure date.
                Otherwise, leave this field blank.
              </p>
            </LearnMore>
          }
        />
      </div>
      <WizardNavigation {...navProps} />
    </>
  );
}
