import { useMutation } from "@apollo/client";
import cn from "classnames";
import { ButtonRow } from "components/ButtonRow";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import MARK_NO_COOWNERS from "./graphql/MarkNoCoowners.gql";

export interface QueryProps {
  displayName: string;
  knownOwners?: string[];
  hasNoSpouse: boolean;
  relatedId: UUID;
  relatedTypename: string;
  spouse: any;
  addCoownerURL: string;
  addSpouseURL: string;
  noCoownersURL: string;
}

export function Query({
  displayName,
  knownOwners,
  hasNoSpouse,
  relatedId,
  relatedTypename,
  spouse,
  addCoownerURL,
  addSpouseURL,
  noCoownersURL,
}: QueryProps) {
  const history = useHistory();

  const [markNoCoowners, { loading: markingNoCoowners }] = useMutation<
    MarkNoCoowners,
    MarkNoCoownersVariables
  >(MARK_NO_COOWNERS, {
    variables: { id: relatedId, typename: relatedTypename },
  });

  return (
    <>
      <p className="required">
        {`Do you share ownership of this ${displayName} with anyone?`}
        {knownOwners?.length &&
          ` Our search of ${displayName} records shows these people are listed as ${displayName} owners:`}
      </p>
      {knownOwners?.length && (
        <ul>
          {knownOwners.map((value, index) => {
            return <li key={index}>{value}</li>;
          })}
        </ul>
      )}
      <ButtonRow
        left={
          <button
            type="button"
            className={cn("btn block", "secondary", {
              loading: markingNoCoowners,
            })}
            onClick={async () => {
              await markNoCoowners();
              history.push(noCoownersURL);
            }}
          >
            No
          </button>
        }
        right={
          <Link
            to={
              hasNoSpouse || !spouse || knownOwners?.length
                ? addCoownerURL
                : addSpouseURL
            }
            className={cn("btn block primary", {
              disabled: markingNoCoowners,
            })}
          >
            Yes
          </Link>
        }
      />
    </>
  );
}
