import { ControlledFileInput } from "components/FileInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import { last } from "lodash";
import React from "react";
import { boolean, mixed, object } from "yup";

export const StatementUploadSchema = object({
  statementFile: mixed<File | UploadedFile>().when("fromNsldsImport", {
    is: true,
    then: mixed<File | UploadedFile>().optional().nullable(),
    otherwise: mixed<File | UploadedFile>()
      .required("Please upload a PDF copy of this statement.")
      .test(
        "non-empty",
        "Please upload a PDF copy of this statement.",
        (value) => {
          return !!value;
        }
      ),
  }),
  fromNsldsImport: boolean().optional(),
});

export default function StatementUpload({
  form,
  navProps,
}: WizardRouteChildProps) {
  const statementFile = form.watch("statementFile");
  return (
    <>
      <p>
        Please upload a student loan statement. These statements should be sent
        to you by your lender or loan servicer, and may contain multiple loan
        accounts.
      </p>

      <ControlledFileInput
        accept=".pdf"
        name="statementFile"
        fileName={last(statementFile?.name.split("/")) || undefined}
        downloadUrl={statementFile?.url || undefined}
        multiple={false}
        helpText={last(statementFile?.name.split("/")) || undefined}
      />

      <WizardNavigation {...navProps} />
    </>
  );
}
