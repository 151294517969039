import { BooleanInput } from "components/BooleanInput";
import { LearnMore } from "components/LearnMore";
import { DecimalInput } from "components/NumberInput";
import { TextArea } from "components/TextArea";
import { WantToKeepLearnMore } from "components/WantToKeepLearnMore";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { DecimalSchema } from "utils/validators/yup";
import { mixed, object, string } from "yup";

export const ItemSchema = object({
  currentValue: DecimalSchema.required("This field is required."),
  description: string().required("This field is required."),
  wantToKeep: mixed<boolean | null>().required("This field is required."),
}).required();

export type ItemFormState = ReturnType<typeof ItemSchema.validateSync>;

export default function Item({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <TextArea
          name="description"
          label="Description"
          placeholder="Please succinctly describe this item."
        />
      </div>
      <DecimalInput
        name="currentValue"
        label="Value"
        addOnBefore="$"
        includeThousandsSeparator
        allowDecimal
        learnMore={
          <LearnMore>
            <h4>What if I’m not sure what this item is worth?</h4>
            <p>
              If you’re unsure about the value of an item, ask an appraiser or
              consult a pawn shop. If your item has no resale value, put down
              $0.
            </p>
          </LearnMore>
        }
      />
      <div className="form-row">
        <BooleanInput
          name="wantToKeep"
          label="Do you want to keep this after your bankruptcy?"
          learnMore={<WantToKeepLearnMore />}
        />
      </div>
      <WizardNavigation {...navProps} />
    </>
  );
}
