import { useQuery } from "@apollo/client";
import { useDeleteInsurancePolicy } from "api/graphql/InsurancePolicy";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema, formatQuantity } from "utils/formatters";
import { useNested } from "utils/hooks";
import { InsuranceCategoryDetail } from "../../HouseholdExpenses/CategorySection/CategoryDetail";
import INSURANCE_MODULE from "../InsuranceModule.gql";
import { InsurancePolicySchema } from "../InsurancePolicyWizard";

interface Props {
  createURL: string;
  nextURL: string;
  onEdit: (id: string) => any;
  categoryDetail: InsuranceCategoryDetail;
}

export default function ListView(props: Props) {
  const { data } = useQuery<InsuranceModule>(INSURANCE_MODULE);
  const [onDelete] = useDeleteInsurancePolicy();
  const { path, url } = useNested();

  if (!data) return <Loading />;
  const { insurancePolicies: items } = data;

  const myPolicies = items.filter(
    (p) => p.type === props.categoryDetail.category
  );

  const {
    displayLowercaseName,
    displayName,
    description,
    hasNoProfileBooleanName,
  } = props.categoryDetail;

  return (
    <>
      <Title>Your {displayName} Policies</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              nextURL={props.nextURL}
              createURL={props.createURL}
              filingProfileBoolean={hasNoProfileBooleanName}
              addText="Add a policy"
              question={
                <>
                  <p className="required">
                    <strong>
                      Do you have any {displayLowercaseName} policies for you or
                      members of your household?
                    </strong>
                  </p>
                  {description}
                </>
              }
              confirmation={
                <>
                  <p>
                    You indicated that you don&rsquo;t have any{" "}
                    {displayLowercaseName} policies for your household.
                  </p>
                  <p>
                    If that&rsquo;s correct, you&rsquo;re ready to continue to
                    the next section. Otherwise, you may add policies below.
                  </p>

                  {description}
                </>
              }
            />
          )}
        />
        {!myPolicies.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Tell us about all of your {displayLowercaseName} policies over
                the last six months. Be sure to include policies for all
                household members.
              </p>

              {description}

              <StatelessList
                {...props}
                emptyText="You haven’t added any policies yet."
                addButtonText="Add a policy"
                items={myPolicies}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(policy) => (
                  <div>
                    {formatErrorsFromSchema(InsurancePolicySchema, policy)}
                    <strong>
                      ${policy.premium}/month {displayLowercaseName} policy from{" "}
                      {policy.companyName}
                    </strong>
                    <div>{policy.description}</div>
                    {!policy.hasNoSupportingDocumentation && (
                      <div className="supporting-text">
                        {formatQuantity(policy.documents.length, " document")}
                      </div>
                    )}
                    {policy.beneficiary && (
                      <div className="supporting-text">
                        Beneficiary: {policy.beneficiary}
                      </div>
                    )}
                    {policy.surrenderValue && (
                      <div className="supporting-text">
                        Worth ${policy.surrenderValue}
                      </div>
                    )}
                  </div>
                )}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
