import { useListBankruptcyCourts } from "api/graphql/BankruptcyCourts";
import { Loading } from "components/Loading";
import { RadioGroupInput } from "components/RadioGroupInput";
import { Select } from "components/Select";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { number, object, string } from "yup";

export const CaseDetailsSchema = object({
  bankruptcyCourt: object({
    name: string().required(
      "Please select the bankruptcy court where this case was filed."
    ),
  }),
  caseNumber: string().required("Please enter the case number."),
  // we need to relax the requirement here since graphql doesn't have support
  // for either type unions of literals, or numbers in enums
  // https://github.com/graphql/graphiql/issues/586
  chapter: number().required(
    "Please select the chapter this bankruptcy was filed under."
  ),
}).required();

export function CaseDetails({ isEdit, navProps }: WizardRouteChildProps) {
  const { loading, data } = useListBankruptcyCourts();

  if (loading) return <Loading />;

  const courts = data.bankruptcyCourts.map(({ name }) => ({
    label: name,
    value: name,
  }));

  return (
    <>
      <p>First, let&rsquo;s identify your bankruptcy case.</p>
      <div className="form-row">
        <Select
          name="bankruptcyCourt.name"
          label="Bankruptcy Court"
          options={courts}
          width="200px"
          autoFocus={!isEdit}
        />
        <TextInput
          name="caseNumber"
          label="Case number"
          maxLength={128}
          width="200px"
        />
      </div>

      <RadioGroupInput<number>
        name="chapter"
        label="Bankruptcy chapter"
        options={[
          { label: "Chapter 7", value: 7 },
          { label: "Chapter 13", value: 13 },
        ]}
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
