import * as React from "react";
import { RouteProps, StaticContext } from "react-router";
import { Route, RouteComponentProps } from "react-router-dom";

type renderArgsType = RouteComponentProps<any, StaticContext, {}>;

export interface GuardedRouteProps extends RouteProps {
  /** If true, the route's render method will be used. Otherwise, renderFallback will */
  guard?: boolean;
  renderFallback?: (props: renderArgsType) => React.ReactNode;
}

export function GuardedRoute({
  guard = true,
  render,
  renderFallback,
  ...routeProps
}: GuardedRouteProps) {
  const routerProps = routeProps as any;
  if (render) {
    if (renderFallback) {
      routerProps.render = (renderArgs: renderArgsType) => {
        if (guard) {
          return render(renderArgs);
        } else {
          return renderFallback(renderArgs);
        }
      };
    } else {
      routerProps.render = render;
    }
  }
  return <Route {...routeProps} />;
}
