import { useApolloClient } from "@apollo/client";
import { useUpsertHouseholdContributor } from "api/graphql/HouseholdContributors";
import GET_OTHER_PERSON from "api/graphql/OtherPerson/GetOtherPerson.gql";
import { Wizard } from "components/Wizard";
import { omit } from "lodash";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { unnestIds } from "utils/ids";
import stripTypename from "utils/tests/apollo/stripTypename";
import About, { AboutSchema } from "./About";
import { Financials, FinancialsSchema } from "./Financials";
import { Residence, ResidenceSchema } from "./Residence";

interface HouseholdContributorWizardProps {
  backURL?: string;
  nextURL?: string;
  initialValue?: any;
}

export function HouseholdContributorWizard({
  backURL,
  nextURL,
  initialValue,
}: HouseholdContributorWizardProps) {
  const history = useHistory();
  const client = useApolloClient();
  const [upsert] = useUpsertHouseholdContributor();

  return (
    <Wizard
      title={`${initialValue ? "Editing" : "Add a"} contributor`}
      backURL={backURL}
      nextURL={nextURL}
      onSave={async (formData) => {
        let input = {
          ...unnestIds(formData),
          monthlyAmount: formData.monthlyAmount || "0",
        };

        try {
          if (input.relatedPersonId && input.relatedPerson) {
            // since partial updates aren't fully worked out yet
            // used when using and existing person, but with a
            // different mailing address
            const { data: relatedPerson } = await client.query<GetOtherPerson>({
              query: GET_OTHER_PERSON,
              variables: {
                id: input.relatedPersonId,
              },
            });

            input = unnestIds({
              ...input,
              relatedPerson: {
                ...stripTypename(
                  omit(
                    relatedPerson.otherPerson,
                    "hasSsnItin",
                    "isItin",
                    "mailingAddress"
                  )
                ),
                ...input.relatedPerson,
              },
            });
          }

          const { data, errors } = await upsert(input);
          if (errors) {
            throw errors;
          }
          history.push(nextURL);
          return data.householdContributor;
        } catch (e) {
          console.error(e);
          throw e; // stop navigation
        }
      }}
      vertical
      initialValue={initialValue}
      steps={[
        {
          path: "/about",
          title: "About",
          component: About,
          schema: AboutSchema,
          partialNestedUpdate: true,
        },
        {
          path: "/residence",
          title: "Residence",
          component: Residence,
          schema: ResidenceSchema,
          partialNestedUpdate: true,
        },
        {
          path: "/finances",
          title: "Finances",
          component: Financials,
          schema: FinancialsSchema,
        },
      ]}
    />
  );
}
