import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDeleteEvict, useUpsertModify } from "api/graphql/utils";
import { MonthlyExpenseCategoryChoice } from "globalTypes";
import DELETE_MONTHLY_EXPENSE from "./DeleteMonthlyExpense.gql";
import GET_MONTHLY_EXPENSE from "./GetMonthlyExpense.gql";
import LIST_MONTHLY_EXPENSE_BY_CATEGORY from "./ListMonthlyExpenseFilterByCategory.gql";
import UPSERT_MONTHLY_EXPENSE from "./UpsertMonthlyExpense.gql";

export function useListMonthlyExpense(
  options?: QueryHookOptions<
    ListMonthlyExpenseFilterByCategory,
    ListMonthlyExpenseFilterByCategoryVariables
  >
) {
  return useQuery<
    ListMonthlyExpenseFilterByCategory,
    ListMonthlyExpenseFilterByCategoryVariables
  >(LIST_MONTHLY_EXPENSE_BY_CATEGORY, options);
}

export function useGetMonthlyExpense(id: string) {
  return useQuery<GetMonthlyExpense, GetMonthlyExpenseVariables>(
    GET_MONTHLY_EXPENSE,
    {
      variables: { id },
    }
  );
}

export const getCategoryHasNoBooleanName = (
  category: MonthlyExpenseCategoryChoice
) => {
  switch (category) {
    case MonthlyExpenseCategoryChoice.ChildCare:
      return "hasNoChildCareExpenses";
    case MonthlyExpenseCategoryChoice.ChildSchool:
      return "hasNoChildEducationExpenses";
    case MonthlyExpenseCategoryChoice.RealEstateRent:
      return "hasNoRealEstateRentalExpenses";
    case MonthlyExpenseCategoryChoice.RealEstateTaxes:
      return "hasNoRealEstateTaxExpenses";
    case MonthlyExpenseCategoryChoice.RealEstateUpkeep:
      return "hasNoRealEstateUpkeepExpenses";
    case MonthlyExpenseCategoryChoice.RealEstateHoaDues:
      return "hasNoRealEstateHoaExpenses";
    case MonthlyExpenseCategoryChoice.TelephonyInternet:
      return "hasNoTelephonyInternetExpenses";
    case MonthlyExpenseCategoryChoice.TelephonyLandline:
      return "hasNoTelephonyLandlineExpenses";
    case MonthlyExpenseCategoryChoice.TelephonyMobilePhone:
      return "hasNoTelephonyMobileExpenses";
    case MonthlyExpenseCategoryChoice.TelephonyTelevision:
      return "hasNoTelephonyTelevisionExpenses";
    case MonthlyExpenseCategoryChoice.UtilityElectricHeat:
      return "hasNoUtilityElectricAndHeatingExpenses";
    case MonthlyExpenseCategoryChoice.UtilityWater:
      return "hasNoUtilityWaterExpenses";
    case MonthlyExpenseCategoryChoice.UtilitySewer:
      return "hasNoUtilitySewerExpenses";
    case MonthlyExpenseCategoryChoice.UtilityTrash:
      return "hasNoUtilityTrashExpenses";
    case MonthlyExpenseCategoryChoice.HouseholdClothing:
      return "hasNoHouseholdClothingExpenses";
    case MonthlyExpenseCategoryChoice.HouseholdFood:
      return "hasNoHouseholdFoodExpenses";
    case MonthlyExpenseCategoryChoice.HouseholdPersonalCare:
      return "hasNoHouseholdPersonalCareExpenses";
    case MonthlyExpenseCategoryChoice.HouseholdMedicalExpenses:
      return "hasNoHouseholdMedicalExpenses";
    case MonthlyExpenseCategoryChoice.TransportationGeneral:
      return "hasNoTransportationGeneralExpenses";
    case MonthlyExpenseCategoryChoice.Recreation:
      return "hasNoRecreationExpenses";
    case MonthlyExpenseCategoryChoice.Charity:
      return "hasNoCharityExpenses";
    case MonthlyExpenseCategoryChoice.HouseholdMisc:
      return "hasNoHouseholdMiscExpenses";
    case MonthlyExpenseCategoryChoice.Alimony:
      return "hasNoAlimonyExpenses";
    default:
      throw new Error(`unexpected expense category ${category}`);
  }
};

export function useUpsertMonthlyExpense<
  Schema extends { monthlyExpense: any } = UpsertMonthlyExpense,
  Variables = UpsertMonthlyExpenseVariables
>(query = UPSERT_MONTHLY_EXPENSE, category: MonthlyExpenseCategoryChoice) {
  return useUpsertModify<
    Schema,
    Variables,
    MonthlyExpenseInput,
    QueryMonthlyExpensesArgs
  >(query, "monthlyExpense", "monthlyExpenses", (cache) => {
    updateFilingProfileCache(
      cache,
      getCategoryHasNoBooleanName(category),
      false
    );
  });
}

export function useDeleteMonthlyExpense() {
  return useDeleteEvict<DeleteMonthlyExpense>(
    DELETE_MONTHLY_EXPENSE,
    "deleteMonthlyExpense",
    "MonthlyExpense"
  );
}
