import { useMutation, useQuery } from "@apollo/client";
import cn from "classnames";
import { ButtonRow } from "components/ButtonRow";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { OtherPersonSchema } from "components/FormSections/OtherPerson";
import { HiddenInput } from "components/HiddenInput";
import { Loading } from "components/Loading";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { formatErrorsFromSchema, formatName } from "utils/formatters";
import { array, boolean, object } from "yup";
import DELETE_COSIGNER from "./DeleteCosigner.gql";
import GET_COSIGNABLE from "./GetCosignable.gql";
import MARK_NO_COSIGNERS from "./MarkNoCosigners.gql";

export const CosignersSchema = object({
  cosigners: array(OtherPersonSchema)
    .when("hasNoCosigners", {
      is: true,
      then: array().min(0),
      otherwise: array().min(
        1,
        "You haven’t indicated that this debt has no cosigners or added any cosigners."
      ),
    })
    .defined(),
  hasNoCosigners: boolean().required(),
}).required();

interface CosignersWizardStepProps extends WizardRouteChildProps {
  relatedId: string;
  relatedTypename: string;
  cosignerName?: string;
}

export default function CosignersWizardStep({
  relatedId,
  relatedTypename,
  navProps,
  cosignerName = "cosigner",
}: CosignersWizardStepProps) {
  const history = useHistory();
  const [deleteCosigner] = useMutation<DeleteCosigner, DeleteCosignerVariables>(
    DELETE_COSIGNER
  );
  const { data, loading } = useQuery<GetCosignable, GetCosignableVariables>(
    GET_COSIGNABLE,
    {
      variables: { id: relatedId, typename: relatedTypename },
    }
  );
  const [markNoCosigners, { loading: markingNoCosigners }] = useMutation<
    MarkNoCosigners,
    MarkNoCosignersVariables
  >(MARK_NO_COSIGNERS, {
    variables: { id: relatedId, typename: relatedTypename },
  });

  if (loading) return <Loading />;

  const { cosigners, hasNoCosigners } = data.cosignable;
  return (
    <div>
      <HiddenInput name="cosigners" value={cosigners} />
      <HiddenInput name="hasNoCosigners" value={hasNoCosigners} />
      {!cosigners.length ? (
        hasNoCosigners ? (
          <>
            <p>
              You have marked this debt as not having any {cosignerName}s. If
              you did this in error, you may add {cosignerName}s below.
            </p>
            <Link to="create_cosigner" className="secondary btn">
              <FaPlus />
              <span>Add a {cosignerName}</span>
            </Link>
          </>
        ) : (
          <>
            <p className="required">Did you cosign this debt with anyone?</p>
            <ButtonRow
              left={
                <button
                  type="button"
                  className={cn("btn block", { loading: markingNoCosigners })}
                  onClick={() => markNoCosigners()}
                >
                  No
                </button>
              }
              right={
                <Link to="create_cosigner" className="block btn">
                  Yes
                </Link>
              }
            />
          </>
        )
      ) : (
        <>
          <p>
            If you cosigned with multiple people, add them below. Otherwise,
            you’re ready to continue.
          </p>
          <StatelessList
            createURL="create_cosigner"
            emptyText={`You haven’t added any ${cosignerName}s yet.`}
            items={cosigners}
            deleteItem={({ id }) =>
              deleteCosigner({
                variables: {
                  cosignerId: id,
                  relatedId,
                  relatedTypename,
                },
              })
            }
            addButtonText={`Add a ${cosignerName}`}
            renderItem={(cosigner) => (
              <div>
                {formatErrorsFromSchema(OtherPersonSchema, cosigner)}
                {formatName(cosigner)}
              </div>
            )}
            onEdit={(id) => history.push(`edit_cosigner/${id}`)}
          />
        </>
      )}

      <WizardNavigation
        {...navProps}
        canContinue={CosignersSchema.isValidSync(data.cosignable)}
      />
    </div>
  );
}
