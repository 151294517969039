import { useUpsertSeizure } from "api/graphql/Seizure";
import {
  Creditor,
  CreditorSchema,
} from "components/FormModules/Legal/Seizures/SeizureWizard/Creditor";
import {
  Item,
  ItemSchema,
} from "components/FormModules/Legal/Seizures/SeizureWizard/Item";
import { Wizard } from "components/Wizard";
import React from "react";
import { Switch } from "react-router-dom";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  /** The URL to switch to after the initial save. Created object ID will be appended */
  editURL?: string;
}

export const SeizureSchema = ItemSchema.concat(CreditorSchema);

export function SeizureWizard({
  backURL,
  nextURL,
  initialValue,
  title,
  editURL = "edit",
}: Props) {
  const [upsertSeizure] = useUpsertSeizure();

  return (
    <>
      <Switch>
        <Wizard
          title={title}
          backURL={backURL}
          nextURL={nextURL}
          initialValue={initialValue}
          vertical
          steps={[
            {
              path: "/item",
              title: "Seized Asset",
              component: Item,
              schema: ItemSchema,
            },
            {
              path: "/creditor",
              title: "Creditor",
              component: Creditor,
              schema: CreditorSchema,
              async onSave(formState) {
                const { data } = await upsertSeizure(formState);
                return data.seizure;
              },
            },
          ]}
        />
      </Switch>
    </>
  );
}
