import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaEnvelope } from "react-icons/fa";
import MailingAddress from "./MailingAddress";
import MailingAddressSummary from "./Summary";

const MailingAddressSubmodule: SubModule = {
  path: "mailing",
  title: "Mailing Address",
  icon: FaEnvelope,
  component: MailingAddress,
  summary: MailingAddressSummary,
  statusName: "mailingAddress",
};

export default MailingAddressSubmodule;
