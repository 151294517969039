import { useGetHouseholdContributor } from "api/graphql/HouseholdContributors";
import { Loading } from "components/Loading";
import { find, set } from "lodash";
import { Redirect } from "react-router-dom";
import { unnestIds } from "utils/ids";
import { HouseholdContributorWizard } from "./Wizard";

export function EditView({ id, listURL }: { id: UUID; listURL: string }) {
  const { loading, data } = useGetHouseholdContributor(id);
  if (loading) return <Loading />;

  const householdContributorToEdit = data.householdContributor;

  if (!householdContributorToEdit) {
    return <Redirect to={listURL} />;
  }

  const initialValue = {
    ...unnestIds(householdContributorToEdit),
  };

  // so the "create address" form is blank.
  // we've already captured the mailing address id
  set(initialValue, "relatedPerson.mailingAddress", null);

  return (
    <HouseholdContributorWizard
      initialValue={initialValue}
      backURL={listURL}
      nextURL={listURL}
    />
  );
}
