import {
  ascending,
  extent,
  range as d3Range,
  ScaleContinuousNumeric,
  scaleQuantize,
} from "d3";
import { uniq } from "lodash";
import * as React from "react";

export function useTicks({
  min,
  max,
  step = 1,
  controlledTicks,
  otherValuesToInclude = [],
  range = d3Range,
}: {
  min?: number;
  max?: number;
  step?: number;
  controlledTicks?: number[];
  otherValuesToInclude?: number[];
  range?: (min: number, max: number, step: number) => number[];
}) {
  const ticks = React.useMemo(() => {
    const arr = uniq([
      ...(min !== undefined && !controlledTicks ? [min] : []),
      ...(max !== undefined && !controlledTicks ? [max] : []),
      ...(controlledTicks || range(min || 0, max || 0, step || 0)),
      ...otherValuesToInclude,
    ]);
    arr.sort(ascending);
    return arr;
  }, [min, max, step, controlledTicks, otherValuesToInclude]);

  const alignDataValueToTick = React.useCallback(
    (value: number, scale?: ScaleContinuousNumeric<number, number>) =>
      scaleQuantize().domain(extent(ticks)).range(ticks)(
        scale ? scale.invert(value) : value
      ),
    [ticks]
  );

  return { ticks, alignDataValueToTick };
}
