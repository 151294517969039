import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import { object, mixed } from "yup";
import { ControlledFileInput } from "components/FileInput";
import { useFormContext } from "react-hook-form";
import { FaCheckCircle } from "react-icons/fa";
import { last } from "lodash";

export const DisbursementSchema = object({
  uploadFile: mixed<File | UploadedFile>().required(
    "Please upload documentation about this disbursement."
  ),
}).required();

export type DisbursementFormState = ReturnType<
  typeof DisbursementSchema.validateSync
>;

export default function Disbursement({
  isEdit,
  navProps,
}: WizardRouteChildProps) {
  const form = useFormContext<DisbursementFormState>();
  const uploadFile = form.watch("uploadFile");
  return (
    <>
      <ControlledFileInput
        name="uploadFile"
        fileName={last(uploadFile?.name.split("/")) || undefined}
        downloadUrl={uploadFile?.url || undefined}
        required="Please upload documentation."
        Icon={(uploadFile || form.watch("uploadFile")) && FaCheckCircle}
        helpText={last(uploadFile?.name.split("/"))}
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
