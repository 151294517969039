import { useQuery } from "@apollo/client";
import { useGetAddress } from "api/graphql/Address";
import { AddressForm, AddressSchema } from "components/FormSections/Address";
import GetOrCreate from "components/GetOrCreate";
import { HiddenInput } from "components/HiddenInput";
import { Loading } from "components/Loading";
import { isNil, uniqBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa";
import { Link, Redirect, useHistory } from "react-router-dom";
import { formatCamelCaseToSnake, formatGraphAddress } from "utils/formatters";
import { useNested, useQueryParams } from "utils/hooks";
import { updateQueryParams } from "utils/urls";
import { object, string } from "yup";
import GET_OR_CREATE_ADDRESS_DEFAULT_OPTIONS from "./GetOrCreateAddressDefaultOptions.gql";

export const getSchema = (
  name = "address",
  idName = `${name}Id`,
  required = "This address is required."
) => {
  return object({
    [idName]: string()
      .nullable()
      .when(name, {
        is: isNil,
        then: string().required(required),
      }),
    [name]: AddressSchema.nullable().default(null),
  });
};

interface EditFormProps {
  id: UUID;
  backURL: string;
  name: string;
  idName: string;
  objectName: string;
  autoFocus?: boolean;
}

function EditForm({ id, backURL, name, idName, objectName }: EditFormProps) {
  const form = useFormContext();
  const { data, error } = useGetAddress({
    variables: { id },
    fetchPolicy: "cache-and-network",
  });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded || !data) return;

    setLoaded(true);
    form.setValue(name, data.address);
    form.setValue(idName, data.address.id);
  }, [data, loaded, name, idName, form]);

  if (error) return <Redirect to={backURL} />;
  if (!data) return <Loading />;

  return (
    <>
      <AddressForm graphQL name={name} />
      <HiddenInput name={idName} />
      <Link to={backURL} className="flat primary btn">
        <FaChevronLeft />
        <span>Select existing {objectName}</span>
      </Link>
    </>
  );
}

interface CreateFormProps {
  name: string;
  idName: string;
  backURL: string;
  objectName: string;
  hasOptions: boolean;
  autoFocus?: boolean;
}
function CreateForm({
  name,
  idName,
  objectName,
  backURL,
  hasOptions,
  autoFocus,
}: CreateFormProps) {
  const form = useFormContext();
  useEffect(() => {
    form.setValue(name, {
      line1: "",
      line2: "",
      city: "",
      state: { abbr: "" },
      zipCode: "",
    });
    form.setValue(idName, null);
  }, []);

  return (
    <>
      <AddressForm autoFocus={autoFocus} graphQL name={name} />
      {hasOptions && (
        <Link to={backURL} className="flat primary btn">
          <FaChevronLeft />
          <span>Select existing {objectName}</span>
        </Link>
      )}
    </>
  );
}

function useGetAddresses(options?: Address[]) {
  const { data } = useQuery<GetOrCreateAddressDefaultOptions>(
    GET_OR_CREATE_ADDRESS_DEFAULT_OPTIONS,
    { skip: !!options }
  );

  return useMemo(() => {
    if (options) return options;
    if (data) {
      return uniqBy(
        [
          ...data.mailingAddresses,
          ...data.residentialAddresses.map((ra) => ra.address),
        ],
        "id"
      );
    }
    return undefined;
  }, [options, data]);
}

export interface GetOrCreateAddressProps {
  name?: string;
  idName?: string;
  objectName?: string;
  options?: Address[];
  required?: boolean | string;
  autoFocus?: boolean;
}

export default function GetOrCreateAddress({
  name = "address",
  idName = `${name}.id`,
  objectName = "address",
  options,
  required,
  autoFocus,
}: GetOrCreateAddressProps) {
  const params = useQueryParams();
  const { url } = useNested();
  const history = useHistory();
  const form = useFormContext();

  const CREATE = `create-${formatCamelCaseToSnake(name, { separator: "-" })}`;
  const EDIT = `edit-${formatCamelCaseToSnake(name, { separator: "-" })}`;
  const SELECT_URL = updateQueryParams(url("."), {
    ...params,
    [CREATE]: undefined,
    [EDIT]: undefined,
  });

  const CREATE_URL = updateQueryParams(url("."), {
    ...params,
    [CREATE]: "true",
    [EDIT]: undefined,
  });

  const getEditURL = useCallback(
    (id: UUID) =>
      updateQueryParams(url("."), {
        ...params,
        [CREATE]: undefined,
        [EDIT]: id,
      }),
    [CREATE, EDIT, url, params]
  );

  const onEdit = useCallback(
    async (id) => {
      history.push(getEditURL(id));
    },
    [history, getEditURL]
  );

  const addresses = useGetAddresses(options);
  const gocOptions = useMemo(() => {
    if (!addresses) return [];
    return addresses.map((address: Address) => ({
      label: formatGraphAddress(address),
      value: address.id,
    }));
  }, [addresses]);

  if (!addresses) return <Loading />;

  if (params[CREATE]) {
    return (
      <CreateForm
        name={name}
        idName={idName}
        objectName={objectName}
        backURL={SELECT_URL}
        hasOptions={!!addresses.length}
        autoFocus={autoFocus}
      />
    );
  } else if (params[EDIT]) {
    const editId = params[EDIT];
    if (!addresses.map((a) => a.id).includes(editId)) {
      return <Redirect to={SELECT_URL} />;
    }

    return (
      <EditForm
        id={editId}
        backURL={SELECT_URL}
        name={name}
        idName={idName}
        objectName={objectName}
        autoFocus={autoFocus}
      />
    );
  } else {
    if (!addresses.length) {
      return <Redirect to={CREATE_URL} />;
    }
    return (
      <GetOrCreate
        name={idName}
        options={gocOptions}
        objectName={objectName}
        createURL={CREATE_URL}
        onEdit={onEdit}
        required={required === true ? "This field is required." : required}
      />
    );
  }
}
