import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, { HasNoSummary } from "components/ModuleSummary";
import HOUSEHOLD_CONTRIBUTORS_MODULE from "./HouseholdContributorsModule.gql";

export default function HouseholdContributorsSummary(
  props: SubModuleSummaryProps
) {
  const { data } = useQuery<HouseholdContributorsModule>(
    HOUSEHOLD_CONTRIBUTORS_MODULE,
    {
      returnPartialData: true,
    }
  );

  return (
    <ModuleSummary {...props} progress={data?.progress.householdContributors}>
      {data?.filingProfile?.hasNoHouseholdContributors ? (
        <HasNoSummary>
          There are no other people that help pay household expenses.
        </HasNoSummary>
      ) : (
        <></>
      )}
    </ModuleSummary>
  );
}
