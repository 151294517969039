import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaSignature } from "react-icons/fa";
import { PreviousNames } from "./PreviousNames";
import PreviousNamesSummary from "./Summary";

const PreviousNamesSubmodule: SubModule = {
  path: "previous_names",
  icon: FaSignature,
  title: "Previous Names",
  component: PreviousNames,
  summary: PreviousNamesSummary,
  statusName: "previousNames",
};

export default PreviousNamesSubmodule;
