import { BooleanInput } from "components/BooleanInput";
import { DecimalInput } from "components/NumberInput";
import { TextInput } from "components/TextInput";
import { WantToKeepLearnMore } from "components/WantToKeepLearnMore";
import { WizardRouteChildProps } from "components/Wizard";
import * as React from "react";
import { DecimalSchema } from "utils/validators/yup";
import { mixed, object, string } from "yup";

export const PrepaymentDetailsSchema = object({
  serviceProviderName: string().required(
    "Please enter the service provider name."
  ),
  unusedPrepaymentAmount: DecimalSchema.required(
    "Please enter the unused pre-payment amount."
  ).test(
    "min",
    "Must be greater than zero.",
    (value: string) => value && Number(value.replace(/,/g, "")) > 0
  ),
  wantToKeep: mixed<boolean | null>().required("This field is required."),
});

export function PrepaymentDetails(props: WizardRouteChildProps) {
  return (
    <>
      <TextInput
        name="serviceProviderName"
        label="Service Provider Name"
        autoFocus={!props.isEdit}
      />
      <DecimalInput
        name="unusedPrepaymentAmount"
        label="Unused portion of the deposit or pre-payment owed to you"
        addOnBefore="$"
        includeThousandsSeparator
        allowDecimal
      />
      <BooleanInput
        name="wantToKeep"
        label="Do you want to keep this after your bankruptcy?"
        learnMore={<WantToKeepLearnMore />}
      />
    </>
  );
}
