import { useQuery } from "@apollo/client";
import GET_REAL_ESTATE_DOCUMENT_RECORD from "./GetRealEstatePropertyDocumentRecord.gql";

export function useGetRealEstateDocumentRecord(
  lookupAddress: RealEstatePropertyLookupInput
) {
  return useQuery<
    GetRealEstatePropertyDocumentRecord,
    GetRealEstatePropertyDocumentRecordVariables
  >(GET_REAL_ESTATE_DOCUMENT_RECORD, {
    variables: { lookupAddress },
  });
}
