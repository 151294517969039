import { useFormContext } from "react-hook-form";

/**
 * Return the submitting state of a react hook form. Make sure your onSubmit handler
 * returns a promise if you wish to await the success/failure of the handler.
 *
 * Will always return false if the component using the hook is not inside of a react
 * hook form.
 */
export function useFormSubmitting() {
  const ctx = useFormContext();
  if (!ctx) return false;
  return ctx.formState.isSubmitting;
}
