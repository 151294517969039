import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaChild } from "react-icons/fa";
import { ChildSupport } from "./ChildSupport";
import ChildSupportSummary from "./Summary";

const ChildSupportSubmodule: SubModule = {
  path: "child-support",
  icon: FaChild,
  title: "Child Support",
  component: ChildSupport,
  summary: ChildSupportSummary,
  statusName: "childSupport",
};

export default ChildSupportSubmodule;
