import cn from "classnames";
import * as React from "react";
import { breakpoints, useBreakpoint, useFixedBottom } from "utils/hooks";
import styles from "./styles.module.scss";

export interface NavProps {
  children: React.ReactElement[];
  style?: React.CSSProperties;
  className?: string;
}

export function Nav({ children, style, className, ...etc }: NavProps) {
  const isMobile = useBreakpoint(breakpoints.mobile);
  const container = React.useRef(null);
  const { shift, reset } = useFixedBottom();

  React.useEffect(() => {
    if (isMobile) {
      const spaceNeeded = `${container.current?.clientHeight}px`;
      shift(spaceNeeded);
    } else {
      reset();
    }
    return () => {
      reset();
    };
  }, [isMobile, reset, shift]);

  return (
    <div className={cn(styles.ProgressNav, className)}>
      <div style={style} className={styles.container} ref={container} {...etc}>
        {(React.Children.toArray(children) as React.ReactElement[])
          .filter((c) => !c.props.hidden)
          .map((child, index) => React.cloneElement(child, { index }))}
      </div>
    </div>
  );
}
