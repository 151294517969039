const toNumber = (value: string | number) =>
  typeof value === "number" ? value : parseFloat(value);

export const parseFancyNumber = (value: string) =>
  parseFloat(value.replace(/,/g, ""));

export const fancyNumber = (v: string) =>
  v === "" ||
  /^-?\d{0,3}(,?\d{3})*\.?\d*$/.test(v) ||
  "Please enter a valid number.";

export const fancyMin = (min: string | number) => (v: string) =>
  v === "" ||
  parseFancyNumber(v) >= toNumber(min) ||
  `This value can’t be less than ${toNumber(min).toLocaleString()}.`;

export const fancyMax = (max: string | number) => (v: string) =>
  v === "" ||
  parseFancyNumber(v) <= toNumber(max) ||
  `This value can’t be greater than ${toNumber(max).toLocaleString()}.`;

export function partialEqual(left: any, right: any, schema: any): boolean {
  let equal = true;
  for (const [keyExpression, equalCheck] of Object.entries(schema)) {
    const isOptional = keyExpression.endsWith("?");
    const key = isOptional ? keyExpression.slice(0, -1) : keyExpression;

    const leftValue = left && left[key];
    const rightValue = right && right[key];

    if (typeof equalCheck !== "function") {
      equal = equal && partialEqual(leftValue, rightValue, equalCheck);
      continue;
    }

    const bothValuesMissing =
      leftValue === undefined && rightValue === undefined;

    if (bothValuesMissing && isOptional) {
      continue;
    }

    equal = equal && equalCheck(leftValue, rightValue);
  }
  return equal;
}
