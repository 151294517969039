import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDeleteEvict, useUpsertModify } from "api/graphql/utils";
import { FarmAssetCategory } from "globalTypes";
import DELETE_FARM_ASSET from "./DeleteFarmAsset.gql";
import GET_FARM_ASSET from "./GetFarmAsset.gql";
import LIST_FARM_ASSETS from "./ListFarmAssets.gql";
import UPSERT_FARM_ASSET from "./UpsertFarmAsset.gql";

export function useListFarmAssets(
  options?: QueryHookOptions<ListFarmAssets, ListFarmAssetsVariables>
) {
  return useQuery<ListFarmAssets>(LIST_FARM_ASSETS, options);
}

export function useGetFarmAsset(id: string) {
  return useQuery<GetFarmAsset, GetFarmAssetVariables>(GET_FARM_ASSET, {
    variables: { id },
  });
}

export const getCategoryHasNoBooleanName = (category: FarmAssetCategory) => {
  switch (category) {
    case FarmAssetCategory.FarmAnimals:
      return "hasNoFarmAnimals";
    case FarmAssetCategory.MarketableCrops:
      return "hasNoMarketableCrops";
    case FarmAssetCategory.FarmSupplies:
      return "hasNoFarmSupplies";
    case FarmAssetCategory.FarmEquipment:
      return "hasNoFarmEquipment";
    case FarmAssetCategory.CommercialFishingSupplies:
      return "hasNoCommercialFishingSupplies";
    case FarmAssetCategory.CommercialFishingEquipment:
      return "hasNoCommercialFishingEquipment";
  }
};

export function useUpsertFarmAsset<
  Schema extends { farmAsset: Partial<FullFarmAsset> } = UpsertFarmAsset,
  Variables = UpsertFarmAssetVariables
>(query = UPSERT_FARM_ASSET) {
  return useUpsertModify<Schema, Variables, FarmAssetInput, QueryFarmAssetArgs>(
    query,
    "farmAsset",
    "farmAssets",
    (cache, data) => {
      const filingProfileBoolean = getCategoryHasNoBooleanName(
        data.farmAsset.category
      );
      updateFilingProfileCache(cache, filingProfileBoolean, false);
    }
  );
}

export function useDeleteFarmAsset() {
  return useDeleteEvict<DeleteFarmAsset>(
    DELETE_FARM_ASSET,
    "deleteFarmAsset",
    "FarmAsset"
  );
}
