import { useUpsertPreviousName } from "api/graphql/PreviousName";
import {
  PreviousName,
  PreviousNameSchema,
} from "components/FormModules/Legal/PreviousNames/PreviousNameWizard/PreviousName";
import { Wizard } from "components/Wizard";
import React from "react";
import { Switch } from "react-router-dom";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
}

export const PreviousNameFullSchema = PreviousNameSchema;

export function PreviousNameWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: Props) {
  const [upsertPreviousName] = useUpsertPreviousName();

  return (
    <>
      <Switch>
        <Wizard
          title={title}
          backURL={backURL}
          nextURL={nextURL}
          initialValue={initialValue}
          steps={[
            {
              path: "/previous_name",
              title: "Previous Name",
              component: PreviousName,
              schema: PreviousNameSchema,
              async onSave(formState) {
                const { data } = await upsertPreviousName(formState);
                return data.previousName;
              },
            },
          ]}
        />
      </Switch>
    </>
  );
}
