import { useUpsertRetirementAccountLoan } from "api/graphql/RetirementAccountLoan";
import CreateFinancialAccountView from "components/FormModules/Assets/FinancialAccounts/CreateView";
import EditFinancialAccountView from "components/FormModules/Assets/FinancialAccounts/EditView";
import { CollectorForm } from "components/FormSections/Collector";
import { Wizard } from "components/Wizard";
import { CollectorsSchema } from "components/WizardSteps/Collectors";
import React, { useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import Collectors from "./Collectors";
import Details, { DetailsSchema } from "./Details";
import Lender, { LenderSchema } from "./Lender";
import RetirementAccount, {
  RetirementAccountLoanRetirementAccountSchema,
} from "./RetirementAccount";
import RETIREMENT_ACCOUNT_LOAN_WIZARD_RETIREMENT_ACCOUNT_LOAN_UPSERT from "./RetirementAccountLoanWizardRetirementAccountLoanUpsert.gql";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
}

export const RetirementAccountLoanSchema = RetirementAccountLoanRetirementAccountSchema.concat(
  LenderSchema
)
  .concat(DetailsSchema)
  .concat(CollectorsSchema);

export function RetirementAccountLoanWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: Props) {
  const { path, url } = useNested();
  const [upsertRetirementAccountLoan] = useUpsertRetirementAccountLoan<
    RetirementAccountLoanWizardRetirementAccountLoanUpsert,
    RetirementAccountLoanWizardRetirementAccountLoanUpsertVariables
  >(RETIREMENT_ACCOUNT_LOAN_WIZARD_RETIREMENT_ACCOUNT_LOAN_UPSERT);
  const history = useHistory();
  const [retirementAccountLoanId, setRetirementAccountLoanId] = useState(
    initialValue?.id
  );

  const collectorsURL = "/collectors";
  const retirementAccountURL = "/retirement_account";

  return (
    <Switch>
      <Route
        path={path("/retirement_account/create")}
        render={() => (
          <CreateFinancialAccountView
            listURL={url(retirementAccountURL)}
            accountType="RETIREMENT"
          />
        )}
      />
      <Route
        path={path("/retirement_account/edit/:editId")}
        render={(routeProps) => {
          const id = routeProps?.match?.params?.editId;
          return (
            <EditFinancialAccountView
              listURL={url(retirementAccountURL)}
              id={id}
            />
          );
        }}
      />

      <Route
        path={path("/create_collector")}
        render={() => (
          <CollectorForm
            title="Add a Retirement Account Loan Debt Collector"
            returnURL={url(collectorsURL)}
            relatedId={retirementAccountLoanId}
            relatedTypename="RetirementAccountLoan"
          />
        )}
      />
      <Route
        path={path("/edit_collector/:collectorId")}
        render={({ match }) => (
          <CollectorForm
            title="Updating Retirement Account Loan Debt Collector"
            returnURL={url(collectorsURL)}
            relatedId={retirementAccountLoanId}
            relatedTypename="RetirementAccountLoan"
            id={match.params.collectorId}
          />
        )}
      />

      <Wizard
        title={title}
        backURL={backURL}
        nextURL={nextURL}
        initialValue={initialValue}
        vertical
        steps={[
          {
            path: retirementAccountURL,
            title: "Retirement Account",
            component: RetirementAccount,
            schema: RetirementAccountLoanRetirementAccountSchema,
          },
          {
            path: "/lender",
            title: "Lender",
            component: Lender,
            schema: LenderSchema,
          },
          {
            path: "/details",
            title: "Retirement Account Loan Details",
            component: Details,
            schema: DetailsSchema,
            async onSave(formState) {
              const { data } = await upsertRetirementAccountLoan(formState);
              const { id } = data.retirementAccountLoan;
              setRetirementAccountLoanId(id);
              if (retirementAccountLoanId) {
                history.push(url(collectorsURL));
              } else {
                history.push(`../edit/${id}${collectorsURL}`);
              }
              return data.retirementAccountLoan;
            },
          },
          {
            path: collectorsURL,
            title: "Collectors",
            component: Collectors,
            schema: CollectorsSchema,
          },
        ]}
      />
    </Switch>
  );
}
