import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaUserShield } from "react-icons/fa";
import Insurance from "./Insurance";
import InsuranceSummary from "./Summary";

const InsuranceSubmodule: SubModule = {
  path: "insurance",
  title: "Insurance",
  icon: FaUserShield,
  component: Insurance,
  summary: InsuranceSummary,
  statusName: "insurancePolicies",
};

export default InsuranceSubmodule;
