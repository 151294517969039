import { CategoryDetail } from "components/FormModules/Assets/HouseholdItems/CategorySection/CategoryDetail";
import { HouseholdItemCategoryChoice } from "globalTypes";
import * as React from "react";
import { FaCat } from "react-icons/fa";

const Examples = () => (
  <div>
    <p>Examples include:</p>
    <ul>
      <li>Dogs</li>
      <li>Cats</li>
      <li>Birds</li>
      <li>Horses</li>
      <li>All other non-farm animals</li>
    </ul>
  </div>
);

const NonFarmAnimals: CategoryDetail = {
  category: HouseholdItemCategoryChoice.NonFarmAnimals,
  displayName: "Pets",
  displayLowercaseName: "pets",
  hasNoProfileBooleanName: "hasNoHouseholdNonFarmAnimalsItems",
  singleInterrogativeText: "Tell us about this animal.",
  noAssetDeclarativeText:
    "You have indicated that neither you nor any member of your household owns pets.",
  booleanInterrogativeText: (
    <>
      <p className="required">
        <strong>Do you or any members of your household own any pets?</strong>
      </p>
      <Examples />
    </>
  ),
  declarativeText: (
    <>
      <p className="required">
        <strong>
          Please describe all pets you and your family members own.
        </strong>
      </p>
      <Examples />
    </>
  ),
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add animals below.",
  urlPathName: "pets",
  displayMenuName: "Pets",
  menuIcon: FaCat,
};

export default NonFarmAnimals;
