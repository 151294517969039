import { useMutation, useQuery } from "@apollo/client";
import { Form } from "components/Form";
import GetOrCreate from "components/GetOrCreate";
import { HiddenInput } from "components/HiddenInput";
import { Loading } from "components/Loading";
import { PhoneNumberInput } from "components/PhoneNumberInput";
import PrivacyInput from "components/PrivacyInput";
import { TextInput } from "components/TextInput";
import { Title } from "components/Title";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { formatGraphAddress } from "utils/formatters";
import { useNested } from "utils/hooks";
import { object, string } from "yup";
import { AddressForm, AddressSchema } from "../Address";
import COLLECTOR_FORM_INITIAL_VALUE from "./CollectorFormInitialValue.gql";
import COLLECTOR_MAILING_ADDRESS_OPTIONS from "./CollectorMailingAddressOptions.gql";
import UPSERT_COLLECTOR from "./UpsertCollector.gql";

export const CollectorSchema = object({
  name: string().required(
    "Please enter the name of this debt collector or agency."
  ),
  accountNumber: string().required("Please enter the account number."),
  phoneNumber: string()
    .nullable()
    .matches(
      /^(\d{3}-\d{3}-\d{4})$/,
      "Please enter a phone number in the format 512-555-1234."
    ),
  mailingAddress: AddressSchema.nullable(),
  mailingAddressId: string().when("mailingAddress", {
    is: (v) => !v,
    then: string().required(
      "You must select an existing address or provide a new address."
    ),
    otherwise: string().optional().nullable(),
  }),
});

type CollectorFormState = ReturnType<typeof CollectorSchema.validateSync>;

interface FormProps {
  id?: string;
  returnURL?: string;
  relatedId: string;
  relatedTypename: string;
  title: string;
}

export function CollectorForm({
  id,
  returnURL,
  relatedId,
  relatedTypename,
  title,
}: FormProps) {
  const { url, path } = useNested();
  const history = useHistory();

  const initialValue = useQuery<
    CollectorFormInitialValue,
    CollectorFormInitialValueVariables
  >(COLLECTOR_FORM_INITIAL_VALUE, {
    variables: { collectorID: id },
    skip: !id,
  });

  const [upsertCollector] = useMutation<
    UpsertCollector,
    UpsertCollectorVariables
  >(UPSERT_COLLECTOR);

  const mailingOptions = useQuery<CollectorMailingAddressOptions>(
    COLLECTOR_MAILING_ADDRESS_OPTIONS,
    { fetchPolicy: "cache-and-network" }
  );

  if (initialValue.loading) return <Loading />;

  return (
    <Form<CollectorFormState>
      initialValue={initialValue.data?.collectionAgency}
      onSubmit={async (input) => {
        await upsertCollector({
          variables: {
            relatedId,
            relatedTypename,
            input,
          },
        });
        history.push(returnURL);
      }}
      schema={CollectorSchema}
    >
      <Title>{title}</Title>
      {!!initialValue.data && <HiddenInput name="id" />}
      <TextInput
        name="name"
        label="Collection agency name"
        width="300px"
        autoFocus={!id}
      />
      {/* @ts-ignore Switch out text-mask to something up to date */}
      <PrivacyInput name="accountNumber" label="Account number" width="200px" />
      <PhoneNumberInput rules={{ required: true }} />
      <hr />
      <label className="label">Collector’s mailing address</label>
      <Switch>
        <Route path={path("/create_address")}>
          <AddressForm graphQL name="mailingAddress" />
          <Link to={url("/")} className="flat primary btn">
            <FaChevronLeft />
            <span>Select existing mailing address</span>
          </Link>
        </Route>
        <Route>
          {mailingOptions.loading ? (
            <Loading />
          ) : (
            <>
              <HiddenInput name="mailingAddress" value={null} />
              <GetOrCreate
                name="mailingAddressId"
                objectName="mailing address"
                createURL={url("/create_address")}
                initialValue={
                  initialValue.data?.collectionAgency.mailingAddress.id
                }
                options={mailingOptions.data.mailingAddresses.map((a) => ({
                  value: a.id,
                  label: formatGraphAddress(a),
                }))}
              />
            </>
          )}
        </Route>
      </Switch>
      <WizardNavigation isComplete backURL={returnURL} />
    </Form>
  );
}
