import { WizardNavigation } from "components/WizardNavigation";
import { Form } from "components/Form";
import { TextArea } from "components/TextArea";
import { object, string } from "yup";
import { useGetGigIncome, useUpsertGigIncome } from "api/graphql/GigIncome";
import { useHistory } from "react-router-dom";
import { Loading } from "components/Loading";

const GigIncomeSchema = object({
  description: string(),
}).required();
const defaultValues = {
  id: null,
  description: null,
};
export function EditView({
  id,
  listURL,
  ...props
}: {
  id: UUID;
  listURL: string;
}) {
  const history = useHistory();
  const [upsertGigIncome] = useUpsertGigIncome();
  const { data, loading } = useGetGigIncome(id);

  if (loading) return <Loading />;

  return (
    <Form
      schema={GigIncomeSchema}
      onSubmit={async (formState) => {
        await upsertGigIncome({ ...formState, id });
        history.push(listURL);
      }}
      initialValue={{ ...defaultValues, ...data?.gigIncome }}
    >
      <>
        <TextArea
          name="description"
          required
          label="Describe your gig work income"
          placeholder="For example: Doordash, delivered food 3 nights a week. Drove for Uber 20-30 hours a week."
          rows={5}
        />
        <WizardNavigation backURL={listURL} isComplete={true} />
      </>
    </Form>
  );
}
