import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaBalanceScale } from "react-icons/fa";
import TaxDebtsSummary from "./Summary";
import { TaxDebts } from "./TaxDebts";

const TaxDebtsSubmodule: SubModule = {
  path: "tax_debts",
  title: "Tax Debts",
  icon: FaBalanceScale,
  component: TaxDebts,
  summary: TaxDebtsSummary,
  statusName: "taxDebts",
};

export default TaxDebtsSubmodule;
