import { useQuery } from "@apollo/client";
import { useUpdateFamilyProfile } from "api/graphql/FamilyProfile";
import { useListMailingAddresses } from "api/graphql/MailingAddress";
import cn from "classnames";
import { usePauseAutoScroll } from "components/AutoScrollToTop";
import { BirthdayInput } from "components/BirthdayInput";
import { ButtonRow } from "components/ButtonRow";
import { Card } from "components/Card";
import { Form } from "components/Form";
import { GraphAddressFormState } from "components/FormSections/Address";
import GetOrCreateAddress from "components/GetOrCreate/GetOrCreateAddress";
import { HiddenInput } from "components/HiddenInput";
import { InputRow } from "components/InputRow";
import { Loading } from "components/Loading";
import { MonthInput } from "components/MonthInput";
import { BooleanInput } from "components/BooleanInput";
import { RadioGroupInput } from "components/RadioGroupInput";
import { TextInput } from "components/TextInput";
import { IDInput } from "components/IDInput";
import { isAfter, startOfToday } from "date-fns";
import { capitalize } from "lodash";
import React from "react";
import { FaCalendar, FaCheck } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { required } from "utils/defaultMessages";
import { useCheckMounted } from "utils/hooks";
import { unnestIds } from "utils/ids";
import SPOUSE_MODULE from "./SpouseModule.gql";

export const getPartnerLabelDisplayName = (
  maritalStatus: FamilyProfileMaritalChoice
) => {
  switch (maritalStatus) {
    case "UNMARRIED":
      return "significant other";
    case "DIVORCED":
      return "former spouse";
    case "MARRIED":
      return "spouse";
    case "SEPARATED":
      return "spouse";
    case "WIDOWED":
      return "departed spouse";
    default:
      return "significant other";
  }
};
const getAskSpouseDetails = (maritalStatus: FamilyProfileMaritalChoice) => {
  return ["DIVORCED", "MARRIED", "SEPARATED"].includes(maritalStatus);
};

interface SpouseFormProps {
  id?: string;
  prev?: string;
  next?: string;
}

type SpouseFormState = {
  maritalStatus: FamilyProfileMaritalChoice;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  birthday: ISODate;
  mailingAddressId?: string;
  mailingAddress?: Partial<GraphAddressFormState> & { id: UUID };
  ssnItin?: string;
};

export function SpouseForm({ id, prev, next }: SpouseFormProps) {
  usePauseAutoScroll();
  const [saving, setSaving] = React.useState(false);
  const checkMounted = useCheckMounted();
  const history = useHistory();

  const { data, loading } = useQuery<SpouseModule>(SPOUSE_MODULE);
  const mailingOptions = useListMailingAddresses();
  const updateFamilyProfile = useUpdateFamilyProfile();

  const hideDisplayFormValues = [null, "UNMARRIED"];
  const [displaySpouseForm, setDisplaySpouseForm] = React.useState(false);
  const [askSpouseDetails, setAskSpouseDetails] = React.useState(false);
  const [displayDivorceDateQuery, setDisplayDivorceDateQuery] =
    React.useState(false);
  const [partnerLabelDisplayName, setPartnerLabelDisplayName] =
    React.useState<string>(
      getPartnerLabelDisplayName(data?.familyProfile?.maritalStatus)
    );

  React.useEffect(() => {
    setDisplaySpouseForm(
      !hideDisplayFormValues.includes(data?.familyProfile?.maritalStatus)
    );
  }, [data]);

  React.useEffect(() => {
    setDisplayDivorceDateQuery(
      data?.familyProfile?.maritalStatus === "DIVORCED"
    );
  }, [data]);

  React.useEffect(() => {
    setAskSpouseDetails(
      getAskSpouseDetails(data?.familyProfile?.maritalStatus)
    );
  }, [data]);

  React.useEffect(() => {
    mailingOptions.refetch();
  }, []);

  const onSubmit = React.useCallback(
    async (formState) => {
      const input = unnestIds({
        ...formState,
        hasNoFamilyMembers: data.familyProfile.hasNoFamilyMembers,
        familyMembers: data.familyProfile.familyMembers,
      });
      // Hack to get the spouse ID back into the form state (not sure why it's broken)
      input.spouseId = data?.familyProfile?.spouse?.id;
      if (formState.maritalStatus === "UNMARRIED") {
        input.spouse = null;
        input.spouseId = null;
      }
      if (formState.maritalStatus !== "DIVORCED") {
        input.divorceDate = null;
      }

      setSaving(true);
      try {
        await updateFamilyProfile(input);
        if (next) history.push(next);
      } catch (e) {
        console.error(e);
      } finally {
        if (checkMounted()) {
          setSaving(false);
        }
      }
    },
    [updateFamilyProfile, data]
  );

  if (loading) return <Loading />;

  return (
    <Card>
      <Form<SpouseFormState>
        initialValue={unnestIds(data?.familyProfile)}
        onSubmit={onSubmit}
      >
        <RadioGroupInput<string>
          name="maritalStatus"
          label="How would you describe your relationship status?"
          options={[
            {
              value: "UNMARRIED",
              label: "Single / Unmarried",
            },
            {
              value: "MARRIED",
              label: "Married",
            },
            {
              value: "SEPARATED",
              label: "Separated",
            },
            {
              value: "DIVORCED",
              label: "Divorced",
            },
            {
              value: "WIDOWED",
              label: "Widowed",
            },
          ]}
          onChange={(value) => {
            const isUnmarried = !hideDisplayFormValues.includes(
              value as FamilyProfileMaritalChoice
            );
            setDisplaySpouseForm(isUnmarried);
            setPartnerLabelDisplayName(
              getPartnerLabelDisplayName(value as FamilyProfileMaritalChoice)
            );
            const isDivorced =
              (value as FamilyProfileMaritalChoice) === "DIVORCED";
            setDisplayDivorceDateQuery(isDivorced);
            setAskSpouseDetails(
              getAskSpouseDetails(value as FamilyProfileMaritalChoice)
            );
          }}
          required
        />

        {displayDivorceDateQuery && (
          <>
            <MonthInput
              name="divorceDate"
              rules={{
                required: "Enter the month and year of your divorce.",
              }}
              disabledMonths={(date) =>
                isAfter(date, startOfToday()) &&
                "Divorce month must be this month or before."
              }
              label="Divorce month"
              addOnAfter={<FaCalendar />}
            />
          </>
        )}
        <hr />

        {!!data?.familyProfile?.spouse && <HiddenInput name="spouse.id" />}

        {displaySpouseForm && (
          <div>
            <InputRow
              label={`${capitalize(partnerLabelDisplayName)}’s legal name`}
            >
              <TextInput
                name="spouse.firstName"
                placeholder="First"
                aria-label="First name"
                autoFocus={!id}
                maxLength={255}
                rules={{
                  required: `Please enter your ${partnerLabelDisplayName}’s first name.`,
                }}
              />
              <TextInput
                name="spouse.middleName"
                placeholder="Middle"
                aria-label="Middle name"
                maxLength={255}
              />
              <TextInput
                name="spouse.lastName"
                placeholder="Last"
                aria-label="Last name"
                maxLength={255}
                rules={{
                  required: `Please enter your ${partnerLabelDisplayName}’s last name.`,
                }}
              />
              <TextInput
                name="spouse.suffix"
                placeholder="Suffix"
                aria-label="Name suffix"
                width="100px"
                maxLength={12}
              />
            </InputRow>
            <BirthdayInput
              name="spouse.birthday"
              label="Birth date"
              rules={{ required }}
              addOnAfter={<FaCalendar />}
            />
            <label className="label">{`What is your ${partnerLabelDisplayName}’s mailing address?`}</label>
            <GetOrCreateAddress
              objectName="mailing address"
              name="spouse.mailingAddress"
              idName="spouse.mailingAddressId"
              required
            />
            {askSpouseDetails && (
              <>
                <div className="mt-4">
                  <IDInput
                    name="spouse.ssnItin"
                    isSaved={!!data?.familyProfile?.spouse?.hasSsnItin}
                    label="Spouse's social security number"
                    showLearnMore={false}
                  />
                </div>
                <div className="mt-4">
                  <BooleanInput
                    name="spouseFiling"
                    label="Will your partner/spouse join you in the bankruptcy filing?"
                  />
                </div>
              </>
            )}
            <hr />
          </div>
        )}

        <ButtonRow
          left={
            <button
              className="btn"
              type="button"
              onClick={() => history.push(prev)}
            >
              Back
            </button>
          }
          right={
            <button className={cn("btn primary", { loading: saving })}>
              <span>Save</span>
              <FaCheck />
            </button>
          }
        />
      </Form>
    </Card>
  );
}
