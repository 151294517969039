import { useUpsertLawsuit } from "api/graphql/Lawsuit";
import {
  CaseDetails,
  CaseDetailsSchema,
} from "components/FormModules/Legal/Lawsuits/LawsuitWizard/CaseDetails";
import {
  FinancialDetails,
  FinancialDetailsSchema,
} from "components/FormModules/Legal/Lawsuits/LawsuitWizard/FinancialDetails";
import { Wizard } from "components/Wizard";
import { omit } from "lodash";
import React from "react";
import { Switch } from "react-router-dom";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
}

// export const LawsuitSchema = ItemSchema.concat(CreditorSchema);
export const LawsuitSchema = CaseDetailsSchema.concat(FinancialDetailsSchema);

export function LawsuitWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: Props) {
  const [upsertLawsuit] = useUpsertLawsuit();

  return (
    <>
      <Switch>
        <Wizard
          title={title}
          backURL={backURL}
          nextURL={nextURL}
          initialValue={initialValue}
          vertical
          steps={[
            {
              path: "/case-details",
              title: "Case Details",
              component: CaseDetails,
              schema: CaseDetailsSchema,
            },
            {
              path: "/financial-details",
              title: "Financial Details",
              component: FinancialDetails,
              schema: FinancialDetailsSchema,
              async onSave(formState) {
                const input = {
                  ...omit(
                    formState,
                    "_payment",
                    "amountPaid",
                    "amountRecieved",
                    "amountOwed",
                    "paymentDate"
                  ),
                  paymentDate: null as Decimal,
                  amountPaid: null as Decimal,
                  amountOwed: null as Decimal,
                  amountReceived: null as Decimal,
                };
                switch (formState._payment) {
                  case "pay":
                    input.amountPaid = formState.amountPaid;
                    input.amountOwed = formState.amountOwed;
                    input.paymentDate = formState.paymentDate;
                    input.amountReceived = null;
                    break;
                  case "receive":
                    input.amountPaid = null;
                    input.paymentDate = formState.paymentDate;
                    input.amountReceived = formState.amountReceived;
                }
                const { data } = await upsertLawsuit(input);
                return data.lawsuit;
              },
            },
          ]}
        />
      </Switch>
    </>
  );
}
