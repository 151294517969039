import React, { ReactNode } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import css from "./FieldWarning.module.css";

export default function FieldWarning({ children }: { children: ReactNode }) {
  return (
    <div className={css.container}>
      <FaExclamationCircle className={css.icon} />
      <span>{children}</span>
    </div>
  );
}
