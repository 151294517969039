import { useEditorBuffer } from "components/EditorState/hooks";
import { WizardRouteChildProps } from "components/Wizard";
import CoownersWizardStep, {
  RegisterOnSaveType,
} from "components/WizardSteps/Coowners";
import React from "react";

export default function Coowners(
  props: WizardRouteChildProps & { registerOnSave: RegisterOnSaveType }
) {
  const [{ buffer }] = useEditorBuffer();
  const vehicleID = buffer.id;

  return (
    <CoownersWizardStep
      relatedTypename="VehicleTitle"
      relatedId={vehicleID}
      displayName="vehicle"
      {...props}
    />
  );
}
