import { CategoryDetail } from "components/FormModules/Assets/HouseholdItems/CategorySection/CategoryDetail";
import { HouseholdItemCategoryChoice } from "globalTypes";
import * as React from "react";
import { GiBigDiamondRing } from "react-icons/gi";

const Examples = () => (
  <div>
    <p>Examples include:</p>
    <ul>
      <li>Everyday jewelry</li>
      <li>Costume jewelry</li>
      <li>Engagement rings</li>
      <li>Wedding rings</li>
      <li>Heirloom jewelry</li>
      <li>Watches</li>
      <li>Gems</li>
      <li>Gold</li>
      <li>Silver</li>
    </ul>
  </div>
);

const JewelryDetail: CategoryDetail = {
  category: HouseholdItemCategoryChoice.Jewelry,
  displayName: "Jewelry",
  displayLowercaseName: "jewelry",
  hasNoProfileBooleanName: "hasNoHouseholdJewelryItems",
  singleInterrogativeText: "Tell us about this piece of jewelry.",
  noAssetDeclarativeText:
    "You have indicated that neither you nor any member of your household owns any jewelry.",
  booleanInterrogativeText: (
    <>
      <p className="required">
        <strong>
          Do you or any members of your household own any jewelry?
        </strong>
      </p>
      <Examples />
    </>
  ),
  declarativeText: (
    <>
      <p className="required">
        <strong>
          Please itemize all jewelry you and your family members own.
        </strong>
      </p>
      <Examples />
    </>
  ),
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add jewelry below.",
  urlPathName: "jewelry",
  displayMenuName: "Jewelry",
  menuIcon: GiBigDiamondRing,
};

export default JewelryDetail;
