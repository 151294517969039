import SectionSummary from "components/FormModuleSubMenu/SectionSummary";
import { SectionIndexProps } from "components/FormModuleSubMenu/SubmenuModule";
import React from "react";
import householdIllustration from "./household.svg";
import YOUR_HOUSEHOLD_INDEX from "./YourHouseholdIndex.gql";

export default function YourHouseholdSummary(props: SectionIndexProps) {
  return (
    <SectionSummary<YourHouseholdIndex>
      {...props}
      query={YOUR_HOUSEHOLD_INDEX}
      image={
        <img
          src={householdIllustration}
          alt="A family eating a meal together"
          width={406}
          height={306}
        />
      }
      heroCopy={
        <p>
          Your lawyer will need to know about you, your family, and some of your
          personal history.
        </p>
      }
      completedCopy={
        <p>
          Great job! If all the info below is accurate and complete,
          you&rsquo;ve finished filling out your personal information.
        </p>
      }
    />
  );
}
