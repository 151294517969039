import { DateInput as MantineDateInput } from "@mantine/dates";
import { useController } from "react-hook-form";

export function DateInput({ name, label = "", ...otherProps }) {
  const {
    field: { ...inputProps },
  } = useController({
    name,
    defaultValue: "",
  });

  const mergedProps = {
    ...inputProps,
    ...otherProps,
  };

  return (
    <MantineDateInput
      id={name}
      label={label}
      preserveTime={false}
      {...mergedProps}
    />
  );
}
