import React from "react";
import { TaxReturnWizard } from "./Wizard";

interface Props {
  listURL: string;
  editURL?: string;
}

export function CreateView({ listURL, editURL = `../edit/` }: Props) {
  return (
    <TaxReturnWizard
      title="Add Tax Return"
      backURL={listURL}
      nextURL={listURL}
      editURL={editURL}
    />
  );
}
