import cn from "classnames";
import * as React from "react";
import css from "./styles.module.css";

export type Actions = {
  [stepNumber: string]: (...args: any) => any;
};

export interface ProgressIndicatorProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  total: number;
  completed: number;
  actions?: Actions;
  active?: number;
}

export function ProgressIndicator({
  total,
  completed,
  actions,
  active = completed,
  ...props
}: ProgressIndicatorProps) {
  return (
    <div {...props} className={cn(props.className, css.indicator)}>
      {Array.from({ length: total }, (v, i) => {
        const isCompleted = i + 1 <= completed;
        const isActive = i + 1 == active;
        const action = actions?.[i + 1];
        return (
          <div
            key={i}
            className={cn(css.dot, {
              [css.completed]: isCompleted,
              [css.actionable]: !!action,
              [css.active]: isActive,
            })}
            onClick={typeof action === "function" ? () => action() : undefined}
          />
        );
      })}
    </div>
  );
}
