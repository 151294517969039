import { TextInput as MantineTextInput } from "@mantine/core";
import { useController } from "react-hook-form";

export function TextInput({ name, label = "", ...otherProps }) {
  const {
    field: { ...inputProps },
  } = useController({
    name,
    defaultValue: "",
  });

  const mergedProps = {
    ...inputProps,
    ...otherProps,
  };

  return <MantineTextInput id={name} label={label} {...mergedProps} />;
}
