import { useEffect, useRef } from "react";

/**
 * Functions which performs a click outside event listener
 * @param {*} handler The function call when an outside click is detected
 */
export default function useOnClickOutside({ handler = null }) {
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && ref.current.contains(event.target)) {
      return;
    }

    // eslint-disable-next-line no-unused-expressions
    handler && handler();
  };

  const handleKeydown = (event) => {
    if (event.key === "Escape") {
      // eslint-disable-next-line no-unused-expressions
      handler && handler();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleKeydown);
    };
  });

  return [ref];
}
