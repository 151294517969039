import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaHome } from "react-icons/fa";
import { getPastDateIntervalFromDuration, TimeUnit } from "utils/dates";
import Residence from "./Residence";
import ResidentialAddressesSummary from "./Summary";

export const residencyRequirements = getPastDateIntervalFromDuration({
  length: 8,
  unit: TimeUnit.Year,
});

const ResidentialAddressSubmodule: SubModule = {
  path: "residence",
  title: "Residential History",
  icon: FaHome,
  component: Residence,
  summary: ResidentialAddressesSummary,
  statusName: "residency",
  props: { requiredFrom: residencyRequirements.start },
};

export default ResidentialAddressSubmodule;
