import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import EDUCATION_MODULE from "./EducationModule.gql";

export default function EducationSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<EducationModule>(EDUCATION_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress?.education}>
      {data?.filingProfile?.hasNoEducationalStudies ? (
        <HasNoSummary>
          You haven&rsquo;t pursued any paid post-secondary education.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            maxValueLength={2}
            label="paid program"
            pluralizeLabel
            value={data?.educationalStudies?.length}
          />
          <NumericSummary
            maxValueLength={2}
            label="graduated"
            value={data?.educationalStudies?.filter((s) => s.graduated).length}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
