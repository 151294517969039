import { FormGroup } from "components/FormGroup";
import { InputCommonProps } from "components/TextInput";
import * as React from "react";
import { useAutoFocus, useFormField } from "utils/hooks";

export interface TextAreaProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "width">,
    InputCommonProps {}

/**
 * Just like an HTML textarea element, but with optional built-in label and error feedback.
 *
 * If you don't need labels or errors, just use a regular `<textarea />`
 */
export function TextArea({
  name,
  rules,
  width,
  label = "",
  className = "",
  id,
  helpText,
  learnMore,
  autoFocus = false,
  ...otherProps
}: TextAreaProps) {
  const { error, Label, inputProps } = useFormField({
    name,
    label,
    rules,
  });

  return (
    <FormGroup
      id={id}
      className={className}
      style={{ width }}
      label={label && <Label />}
      learnMore={learnMore}
      helpText={helpText}
      error={error}
    >
      <textarea {...inputProps} {...otherProps} {...useAutoFocus(autoFocus)} />
    </FormGroup>
  );
}
