import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_RETIREMENT_ACCOUNT_LOANS from "./DeleteRetirementAccountLoan.gql";
import GET_RETIREMENT_ACCOUNT_LOAN from "./GetRetirementAccountLoan.gql";
import LIST_RETIREMENT_ACCOUNT_LOANS from "./ListRetirementAccountLoan.gql";
import UPSERT_RETIREMENT_ACCOUNT_LOANS from "./UpsertRetirementAccountLoan.gql";

const LIST_RETIREMENT_ACCOUNT_LOANS_IDS = gql`
  query ListRetirementAccountLoanIds {
    retirementAccountLoans {
      id
    }
  }
`;

export function useListRetirementAccountLoan(
  options?: QueryHookOptions<ListRetirementAccountLoan>
) {
  return useQuery<ListRetirementAccountLoan>(
    LIST_RETIREMENT_ACCOUNT_LOANS,
    options
  );
}

export function useGetRetirementAccountLoan(id: string) {
  return useQuery<GetRetirementAccountLoan, GetRetirementAccountLoanVariables>(
    GET_RETIREMENT_ACCOUNT_LOAN,
    {
      variables: { id },
    }
  );
}

export function useUpsertRetirementAccountLoan<
  Schema extends { retirementAccountLoan: any } = UpsertRetirementAccountLoan,
  Variables = UpsertRetirementAccountLoanVariables
>(query = UPSERT_RETIREMENT_ACCOUNT_LOANS) {
  return useUpsert<
    Schema,
    Variables,
    ListRetirementAccountLoanIds,
    RetirementAccountLoanInput
  >(
    query,
    LIST_RETIREMENT_ACCOUNT_LOANS_IDS,
    "retirementAccountLoan",
    "retirementAccountLoans",
    (cache) =>
      updateFilingProfileCache(cache, "hasNoRetirementAccountLoans", false)
  );
}

export function useDeleteRetirementAccountLoan() {
  return useDelete<DeleteRetirementAccountLoan, ListRetirementAccountLoanIds>(
    DELETE_RETIREMENT_ACCOUNT_LOANS,
    LIST_RETIREMENT_ACCOUNT_LOANS_IDS,
    "deleteRetirementAccountLoan",
    "retirementAccountLoans"
  );
}
