import {
  investmentTypesRequiringDescription,
  investmentTypesRequiringOwnership,
} from "components/FormModules/Assets/FinancialInvestments/utils";
import { HiddenInput } from "components/HiddenInput";
import { LearnMore } from "components/LearnMore";
import { DecimalInput } from "components/NumberInput";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import { FinancialInvestmentTypeChoice } from "globalTypes";
import React from "react";
import { formatFinancialInvestmentType } from "utils/formatters";
import { DecimalSchema } from "utils/validators/yup";
import { mixed, object, string } from "yup";

export const InvestmentSchema = object({
  entityName: string().required("This field is required."),
  currentValue: DecimalSchema.required("This field is required.").test(
    "min",
    "Must be greater than zero.",
    (value: string) => value && Number(value.replace(/,/g, "")) > 0
  ),
  description: string().when("investmentType", {
    is: (value) => investmentTypesRequiringDescription.includes(value),
    then: string().required("This field is required."),
    otherwise: string().nullable(),
  }),
  investmentType: mixed<FinancialInvestmentTypeChoice>()
    .required("This field is required.")
    .oneOf(Object.values(FinancialInvestmentTypeChoice)),
  entityOwnedPercentage: DecimalSchema.when("investmentType", {
    is: (value) => investmentTypesRequiringOwnership.includes(value),
    then: string()
      .required("This field is required.")
      .matches(
        /\d{0,3}\.?\d{1,2}/,
        "Provide a valid percentage between .0001 and 100.00, such as .01 or 87.5."
      )
      .test(
        "min",
        "Must be greater than zero.",
        (value: string) => value && Number(value.replace(/,/g, "")) > 0
      )
      .test(
        "max",
        "You cannot own more than 100% of an investment.",
        (value: string) => value && Number(value.replace(/,/g, "")) <= 100
      ),
    otherwise: string().nullable(),
  }),
}).required();

interface InvestmentStepProps extends WizardRouteChildProps {
  investmentType: FinancialInvestmentTypeChoice;
}

export default function Investment({
  isEdit,
  navProps,
  investmentType,
}: InvestmentStepProps) {
  const showDescription =
    investmentTypesRequiringDescription.includes(investmentType);
  const descriptionPlaceholder =
    investmentType === FinancialInvestmentTypeChoice.Other
      ? "Tell us about this investment."
      : `Tell us about this ${formatFinancialInvestmentType(
          investmentType
        ).toLowerCase()}..`;

  const showEntityOwnedPercentage =
    investmentTypesRequiringOwnership.includes(investmentType);

  let investmentLabel = "Investment Name";
  switch (investmentType) {
    case FinancialInvestmentTypeChoice.Cash:
      investmentLabel = "Description of cash";
      break;
    case FinancialInvestmentTypeChoice.Prepayment:
      investmentLabel = "Name of company or utility";
      break;
  }

  let valueLabel = "Current value";
  switch (investmentType) {
    case FinancialInvestmentTypeChoice.Cash:
      valueLabel = "Value";
      break;
    case FinancialInvestmentTypeChoice.Prepayment:
      valueLabel = "Expected returned payment";
      break;
  }

  return (
    <>
      <div className="form-row">
        <TextInput
          name="entityName"
          label={investmentLabel}
          width="200px"
          autoFocus={!isEdit}
        />
        <DecimalInput
          name="currentValue"
          label={valueLabel}
          addOnBefore="$"
          includeThousandsSeparator
          allowDecimal
          learnMore={
            <LearnMore>
              <h4>What if I don’t know the value of my investment?</h4>
              <p>
                Contact the administrator of the asset and ask for the most
                recent valuation.
              </p>

              <h4>
                What value should I put in when I own a fraction of an asset?
              </h4>
              <p>
                Always use the value of the assets you own, not the total value
                of the asset. For instance, if you own 20% of a barbecue company
                worth $40,000.00, you would value this at $8,000.
              </p>
            </LearnMore>
          }
        />
      </div>
      {showDescription && (
        <div className="form-row">
          <TextArea
            name="description"
            label="Description"
            placeholder={descriptionPlaceholder}
            rules={{ required: true }}
          />
        </div>
      )}
      {showEntityOwnedPercentage && (
        <div className="form-row">
          <DecimalInput
            name="entityOwnedPercentage"
            label="What percentage of this entity do you and your household members own?"
            addOnAfter="%"
            allowDecimal
            placeholder="100.00"
            width="175px"
            rules={{ required: true }}
          />
        </div>
      )}

      <HiddenInput name="investmentType" value={investmentType} />
      <WizardNavigation {...navProps} />
    </>
  );
}
