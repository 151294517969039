import { BigIcon } from "components/Big";
import {
  ProgressIndicator,
  StepHeading,
  StepProps,
} from "components/Onboarding/AboutTheProcess";
import * as React from "react";
import { FaDolly } from "react-icons/fa";

export function Discharge({ total, completed, actions, ...props }: StepProps) {
  return (
    <div {...props}>
      <BigIcon>
        <FaDolly />
      </BigIcon>
      <StepHeading>Discharge</StepHeading>
      <ProgressIndicator
        total={total}
        completed={completed}
        actions={actions}
      />
      <p>
        For a Chapter 7 bankruptcy, your debts are discharged at the end of your
        bankruptcy case. For a Chapter 13 bankruptcy, your debts are discharged
        after you have completed your three- to five-year repayment plan.
      </p>
    </div>
  );
}
