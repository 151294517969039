import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaCar } from "react-icons/fa";
import OwnedVehiclesSummary from "./Summary";
import { Vehicles } from "./Vehicles";

const VehicleSubmodule: SubModule = {
  path: "vehicles",
  icon: FaCar,
  title: "Vehicles",
  component: Vehicles,
  summary: OwnedVehiclesSummary,
  statusName: "ownedVehicles",
};

export default VehicleSubmodule;
