import cn from "classnames";
import { Card } from "components/Card";
import { AboutMyFirm } from "components/PortalModules/AboutMyFirm";
import LawFirmUsersGrid from "components/PortalModules/LawFirmUsersGrid";
import ReferredUsersGrid from "components/PortalModules/ReferredUsersGrid";
import { ReferredUsersStats } from "components/PortalModules/ReferredUsersStats";
import React from "react";
import { Helmet } from "react-helmet";
import css from "./LawyerPortal.module.css";

export function LawyerPortal() {
  return (
    <>
      <Helmet>
        <title>Intake Portal</title>
      </Helmet>

      <ReferredUsersStats />
      <Card
        className={cn("card", css.mb2)}
        title={<h3>Referred Intake Users</h3>}
      >
        <ReferredUsersGrid />
      </Card>
      <AboutMyFirm />
      <Card
        className={cn("card", css.mt2)}
        title={<h3>My Firm&rsquo;s Users</h3>}
      >
        <LawFirmUsersGrid />
      </Card>
    </>
  );
}
