import StudentLoans from "components/FormModules/StudentLoans";
import SubMenuModule from "components/FormModuleSubMenu";
import React, { useEffect, useState } from "react";
import { FaFileInvoiceDollar } from "react-icons/fa";
import CreditCardDebts from "./CreditCardDebts";
import CreditReports from "./CreditReports";
import MedicalDebts from "./MedicalDebts";
import Mortgages from "./Mortgages";
import OtherDebts from "./OtherDebts";
import RetirementAccountLoans from "./RetirementAccountLoans";
import LiabilitiesSummary from "./Summary";
import TaxDebts from "./TaxDebts";
import VehicleLeases from "./VehicleLeases";
import VehicleLoans from "./VehicleLoans";
import { useFilingProfileBoolean } from "api/graphql/FilingProfile";
import { Loading } from "components/Loading";

export type LiabilitiesProps = {
  next?: string;
  prev?: string;
  subMenuContainerID?: string;
};

const liabilitiesModules = [
  Mortgages,
  CreditCardDebts,
  MedicalDebts,
  StudentLoans,
  TaxDebts,
  VehicleLoans,
  VehicleLeases,
  RetirementAccountLoans,
  OtherDebts,
  CreditReports,
];

export const liabilitiesStatusNames = liabilitiesModules.map(
  (m) => m.statusName
);

export function Liabilities({ prev, subMenuContainerID }: LiabilitiesProps) {
  const [modules, setModules] = useState(liabilitiesModules);
  const { data: enableCreditReport, loading } =
    useFilingProfileBoolean("enableCreditReport");

  useEffect(() => {
    if (enableCreditReport === false) {
      const newModules = liabilitiesModules.filter(
        (module) => module !== CreditReports
      );
      setModules(newModules);
    }
  }, [enableCreditReport]);

  if (loading) return <Loading />;

  return (
    <SubMenuModule
      subMenuContainerID={subMenuContainerID}
      dashboardURL={prev}
      title="Your Liabilities"
      Index={LiabilitiesSummary}
      subModules={modules}
    />
  );
}

Liabilities.Icon = FaFileInvoiceDollar;
