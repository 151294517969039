import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import VEHICLE_LOANS_MODULE from "./VehicleLoansModule.gql";

export default function VehicleLoansSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<VehicleLoansModule>(VEHICLE_LOANS_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.vehicleLoans}>
      {data?.filingProfile?.hasNoVehicleLoans ? (
        <HasNoSummary>You don&rsquo;t have any vehicle loans.</HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="vehicle loan"
            maxValueLength={2}
            value={data?.vehicleLoans?.length}
            pluralizeLabel
          />
          <NumericSummary
            label="total balance"
            valuePrefix="$"
            maxValueLength={5}
            value={fancyNumberSum(data?.vehicleLoans, "outstandingAmount")}
          />
          <NumericSummary
            label="monthly payments"
            valuePrefix="$"
            maxValueLength={4}
            value={fancyNumberSum(data?.vehicleLoans, "monthlyPayment")}
          />
          <NumericSummary
            label="overdue"
            valuePrefix="$"
            maxValueLength={4}
            value={fancyNumberSum(data?.vehicleLoans, "delinquentAmount")}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
