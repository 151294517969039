import { BooleanInput } from "components/BooleanInput";
import { DecimalInput } from "components/NumberInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { DecimalSchema } from "utils/validators/yup";
import { boolean, object } from "yup";

export const DetailsSchema = object({
  outstandingAmount: DecimalSchema.required(
    "Please enter the outstanding amount you owe for this debt."
  ),
  isPaidThroughPlan: boolean().required("This field is required."),
}).required();

export default function Details({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <DecimalInput
          name="outstandingAmount"
          label="Outstanding amount"
          addOnBefore="$"
          width="150px"
          autoFocus={!isEdit}
          includeThousandsSeparator
          allowDecimal
        />
      </div>

      <div className="form-row">
        <BooleanInput
          name="isPaidThroughPlan"
          label="Do you use a payment plan to repay this debt?"
        />
      </div>

      <WizardNavigation {...navProps} />
    </>
  );
}
