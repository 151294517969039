import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaIdCard } from "react-icons/fa";
import TaxpayerIdDocumentsSummary from "./Summary";
import { TaxpayerId } from "./TaxpayerId";

const TaxpayerIdSubmodule: SubModule = {
  path: "taxpayer_id",
  title: "Taxpayer ID / Social Security Card",
  icon: FaIdCard,
  component: TaxpayerId,
  summary: TaxpayerIdDocumentsSummary,
  statusName: "taxpayerId" as const,
};

export default TaxpayerIdSubmodule;
