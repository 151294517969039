import { useState } from "react";
import { Modal, Button, Group, Collapse, Loader } from "@mantine/core";

export default function SubmissionConfirmationModal({
  opened,
  onConfirm,
  onCancel,
  onClose,
  loading,
}) {
  const [learnMoreCollapsed, setLearnMoreCollapsed] = useState(true);
  function toggleCollapse() {
    setLearnMoreCollapsed(!learnMoreCollapsed);
  }

  return (
    <Modal
      centered
      opened={opened}
      transition={"fade"}
      title={<>{loading ? "Please wait..." : <h3>Please note</h3>}</>}
      onClose={onClose}
    >
      <>
        {loading ? (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <div>
            <p>
              You will be billed for this client if you proceed with submission.
              Are you sure you want to proceed?
            </p>
            <a onClick={toggleCollapse} className="hover:cursor-pointer">
              Learn more
            </a>
            <Collapse in={!learnMoreCollapsed}>
              <h4>What happens when I force submission?</h4>
              <p>
                This action forces the submission of your client’s intake
                directly to you: the document bundle will be ready to download
                and you will be charged for this Intake at the close of this
                calendar month, regardless of how complete it is. Your client
                will no longer be able to actively work on it.
              </p>
              <p>
                As a reminder: Your charge for an Intake (regardless of
                completion level) with a credit report is $99. An Intake without
                a credit report is $62.
              </p>
              <p>
                If you have to reverse this action, you will need to reach out
                to <a href="mailto:support@lexria.com">support@lexria.com</a> to
                open the Intake back up to your client. They will not be able to
                edit their Intake as long as it has been submitted to you.
              </p>
            </Collapse>
          </div>
        )}
        <Group position="right" className="mt-12">
          <Button
            onClick={onConfirm}
            variant="filled"
            color="red"
            disabled={loading}
          >
            Proceed with submission
          </Button>
          <Button onClick={onCancel} variant="outline" disabled={loading}>
            Cancel this request
          </Button>
        </Group>
      </>
    </Modal>
  );
}
