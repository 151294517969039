import {
  useAssignAssetSaleBuyer,
  useDeleteAssetSaleBuyer,
  useGetAssetSale,
  useUpsertAssetSaleBuyer,
} from "api/graphql/AssetSale";
import { Card } from "components/Card";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { useEditorBuffer } from "components/EditorState/hooks";
import { OtherPersonSchema } from "components/FormSections/OtherPerson";
import {
  OtherPersonDataValidationType,
  OtherPersonWithMissingDataValidationsForm,
} from "components/FormSections/OtherPersonWithMissingDataValidation";
import { HiddenInput } from "components/HiddenInput";
import { Loading } from "components/Loading";
import { Modal } from "components/Modal";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import { OtherPersonInput } from "globalTypes";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router";
import { Route } from "react-router-dom";
import { formatErrorsFromSchema, formatName } from "utils/formatters";
import { useNested } from "utils/hooks";
import { array, object } from "yup";

export const BuyersSchema = object({
  buyers: array().min(
    1,
    "Please list all buyers (or recipients) of this asset."
  ),
}).required();

export function Buyers({ isEdit, navProps }: WizardRouteChildProps) {
  const history = useHistory();
  const [deleteBuyer] = useDeleteAssetSaleBuyer();
  const [assignAssetSaleBuyer] = useAssignAssetSaleBuyer();
  const [upsertAssetSaleBuyer] = useUpsertAssetSaleBuyer();

  const [{ buffer }] = useEditorBuffer();
  const relatedId = buffer.id;
  const { url, path } = useNested();
  const form = useFormContext();

  const validations = React.useMemo(
    () => [OtherPersonDataValidationType.MailingAddress],
    []
  );

  const getOnSubmit = React.useCallback(
    (id) => (state: any) => {
      if (!id && "id" in state) {
        return assignAssetSaleBuyer({
          otherPersonId: state.id,
          relatedId,
        });
      } else {
        return upsertAssetSaleBuyer({
          variables: {
            input: state as OtherPersonInput,
            relatedId,
          },
        });
      }
    },
    []
  );

  const { data, loading } = useGetAssetSale(relatedId);

  if (!data) return <Loading />;

  const buyers = data.assetSale.buyers;

  return (
    <>
      <Route path={path("/add_buyer")}>
        <Modal isOpen>
          <Card>
            <OtherPersonWithMissingDataValidationsForm
              validations={validations}
              id={null}
              candidates={data?.assetSale?.eligibleBuyers}
              relatedId={relatedId}
              relatedTypename={"AssetSale"}
              relatedDisplayName="buyer"
              title={"Add a buyer"}
              backURL={url("/")}
              nextURL={url("/")}
              onSubmit={getOnSubmit(null)}
              saveText="Add buyer"
              onFinalizeMissingDataCollection={assignAssetSaleBuyer}
            />
          </Card>
        </Modal>
      </Route>
      <Route
        path={path("/edit_buyer/:buyerId")}
        render={({ match }) => {
          return (
            <Modal isOpen>
              <Card>
                <OtherPersonWithMissingDataValidationsForm
                  validations={validations}
                  id={match?.params?.buyerId}
                  candidates={data?.assetSale?.eligibleBuyers}
                  relatedId={relatedId}
                  relatedTypename={"AssetSale"}
                  relatedDisplayName="buyer"
                  title={"Editing buyer"}
                  backURL={url("/")}
                  nextURL={url("/")}
                  onSubmit={getOnSubmit(match?.params?.buyerId)}
                  saveText="Update buyer"
                  onFinalizeMissingDataCollection={assignAssetSaleBuyer}
                />
              </Card>
            </Modal>
          );
        }}
      />

      <p>
        List all of the buyers (or recipients if the item was transferred)
        below.
      </p>
      <StatelessList
        createURL={url("/add_buyer")}
        emptyText="You haven’t added anyone yet."
        emptyCTA="Add buyers"
        items={buyers}
        deleteItem={({ id }) =>
          deleteBuyer({
            variables: {
              buyerId: id,
              relatedId,
            },
          })
        }
        addButtonText={`Add a buyer`}
        renderItem={(buyer) => (
          <div>
            {formatErrorsFromSchema(OtherPersonSchema, buyer)}
            {formatName(buyer)}
          </div>
        )}
        onEdit={(id) => history.push(url(`/edit_buyer/${id}`))}
      />
      <HiddenInput name="buyers" value={buyers} />
      {!!form?.errors?.buyers && (
        <div className="errors">{form.errors.buyers.message}</div>
      )}
      <WizardNavigation {...navProps} />
    </>
  );
}
