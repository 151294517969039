import { useUpsertHouseholdItem } from "api/graphql/HouseholdItem";
import { Wizard } from "components/Wizard";
import {
  CoownersWizardStepProps,
  useDelegatedOnSave,
} from "components/WizardSteps/Coowners";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import Coowners, { CoOwnersSchema } from "./Coowners";
import Item, { ItemSchema } from "./Item";

interface Props {
  category: HouseholdItemCategoryChoice;
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  /** The URL to switch to after the initial save. Created object ID will be appended */
  editURL?: string;
  subtitle?: React.ReactElement;
}

export const HouseholdItemSchema = ItemSchema.concat(CoOwnersSchema);

export function HouseholdItemWizard({
  category,
  backURL,
  nextURL,
  initialValue,
  title,
  editURL = "edit",
  subtitle,
}: Props) {
  const { url } = useNested();
  const [upsertHouseholdItem] = useUpsertHouseholdItem(undefined, category);
  const history = useHistory();
  const [assetId, setAssetId] = useState(initialValue?.id);
  const [registerOnSaveCoowners, onSaveCoowners] = useDelegatedOnSave();

  return (
    <Wizard
      title={title}
      subtitle={subtitle}
      backURL={backURL}
      nextURL={nextURL}
      initialValue={initialValue}
      vertical
      steps={[
        {
          path: "/item_description",
          title: "Item Description",
          component: Item,
          schema: ItemSchema,
          async onSave(formState) {
            const { data } = await upsertHouseholdItem(
              {
                ...formState,
                category,
              },
              undefined,
              ({ categoryFilter }) =>
                categoryFilter === category || !categoryFilter
            );
            const { id } = data.householdItem;
            setAssetId(id);
            setTimeout(() => {
              if (assetId) {
                history.push(url("/coowners"));
              } else {
                history.push(`../${editURL}/${id}/coowners`);
              }
            }, 0);
            return data.householdItem;
          },
        },
        {
          path: "/coowners",
          title: "Co-owners",
          component: React.useCallback(
            (props: CoownersWizardStepProps) => (
              <Coowners {...props} registerOnSave={registerOnSaveCoowners} />
            ),
            [registerOnSaveCoowners]
          ),
          schema: CoOwnersSchema,
          includeSubpaths: true,
          onSave: onSaveCoowners,
        },
      ]}
    />
  );
}
