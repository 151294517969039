import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FinancialInvestments } from "./FinancialInvestments";
import FinancialInvestmentsSummary from "./Summary";

const FinancialInvestmentsSubmodule: SubModule = {
  path: "financial_investments",
  icon: FaFileInvoiceDollar,
  title: "Financial Investments",
  component: FinancialInvestments,
  summary: FinancialInvestmentsSummary,
  statusName: "financialInvestments",
};

export default FinancialInvestmentsSubmodule;
