import * as React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

export interface HasMarkedNoCoownersProps {
  displayName: string;
  registerOnSave?: (register: () => Promise<any>) => void;
}

export function HasMarkedNoCoowners({
  displayName,
  registerOnSave,
}: HasMarkedNoCoownersProps) {
  const ADD_URL = "all/add_co_owner";

  React.useEffect(() => {
    registerOnSave?.(async () => {
      return;
    });
  }, []);

  return (
    <>
      <p>
        {`You have indicated that you are the only owner of this ${displayName}. If you did this in error, you may add co-owners below.`}
      </p>
      <Link to={ADD_URL} className="secondary btn">
        <FaPlus />
        <span>Add a co-owner</span>
      </Link>
    </>
  );
}
