import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_STUDIES from "./DeleteEducationalStudy.gql";
import GET_STUDIES from "./GetEducationalStudy.gql";
import LIST_STUDIES from "./ListEducationalStudies.gql";
import UPSERT_STUDIES from "./UpsertEducationalStudy.gql";

const LIST_STUDIES_IDS = gql`
  query ListEducationalStudiesIds {
    educationalStudies {
      id
    }
  }
`;

export function useListEducationalStudies(
  options?: QueryHookOptions<ListEducationalStudies>
) {
  return useQuery<ListEducationalStudies>(LIST_STUDIES, options);
}

export function useGetEducationalStudy(id: string) {
  return useQuery<GetEducationalStudy, GetEducationalStudyVariables>(
    GET_STUDIES,
    {
      variables: { id },
    }
  );
}

export function useUpsertEducationalStudy() {
  return useUpsert<
    UpsertEducationalStudy,
    UpsertEducationalStudyVariables,
    ListEducationalStudiesIds,
    EducationalStudyInput
  >(
    UPSERT_STUDIES,
    LIST_STUDIES_IDS,
    "educationalStudy",
    "educationalStudies",
    (cache) => updateFilingProfileCache(cache, "hasNoEducationalStudies", false)
  );
}

export function useDeleteEducationalStudy() {
  return useDelete<DeleteEducationalStudy, ListEducationalStudiesIds>(
    DELETE_STUDIES,
    LIST_STUDIES_IDS,
    "deleteEducationalStudy",
    "educationalStudies"
  );
}
