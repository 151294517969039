import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaPiggyBank } from "react-icons/fa";
import { FinancialAccounts } from "./FinancialAccounts";
import FinancialAccountsSummary from "./Summary";

const FinancialAccountsSubmodule: SubModule = {
  path: "financial_accounts",
  icon: FaPiggyBank,
  title: "Financial Accounts",
  component: FinancialAccounts,
  summary: FinancialAccountsSummary,
  statusName: "financialAccounts",
};

export default FinancialAccountsSubmodule;
