import { StatelessEditCreateList } from "components/EditCreateList";
import { FormModuleProps } from "components/FormFlow";
import React from "react";
import { CreateView } from "./CreateView";
import { EditView } from "./EditView";
import { ListView } from "./ListView";

export type BankruptcyCasesProps = FormModuleProps<any> & {
  allowNoPreviousBankruptcyCases?: boolean;
};

export function BankruptcyCases({
  next = "/",
  prev = "/",
  allowNoPreviousBankruptcyCases = true,
}: BankruptcyCasesProps) {
  return (
    <StatelessEditCreateList
      prev={prev}
      next={next}
      ListView={(props) => (
        <ListView
          {...props}
          allowNoPreviousBankruptcyCases={allowNoPreviousBankruptcyCases}
        />
      )}
      EditView={EditView}
      CreateView={CreateView}
    />
  );
}
