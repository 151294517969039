import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { updateFilingProfileCache } from "../FilingProfile";
import { useDelete, useUpsert } from "../utils";
import DELETE_VEHICLE_TITLE from "./DeleteVehicleTitle.gql";
import GET_VEHICLE_TITLE from "./GetVehicleTitle.gql";
import LIST_VEHICLE_TITLE from "./ListVehicleTitle.gql";
import UPSERT_VEHICLE_TITLE from "./UpsertVehicleTitle.gql";

const LIST_VEHICLE_TITLE_IDS = gql`
  query ListVehicleTitleIds {
    ownedVehicles {
      id
    }
  }
`;

export function useListVehicleTitle() {
  return useQuery<ListVehicleTitle>(LIST_VEHICLE_TITLE);
}

export function useGetVehicleTitle(id: string) {
  return useQuery<GetVehicleTitle, GetVehicleTitleVariables>(
    GET_VEHICLE_TITLE,
    {
      variables: { id },
    }
  );
}

export function useUpsertVehicleTitle() {
  return useUpsert<
    UpsertVehicleTitle,
    UpsertVehicleTitleVariables,
    ListVehicleTitleIds,
    OwnedVehicleInput
  >(
    UPSERT_VEHICLE_TITLE,
    LIST_VEHICLE_TITLE_IDS,
    "ownedVehicle",
    "ownedVehicles",
    (cache) => updateFilingProfileCache(cache, "hasNoVehicles", false)
  );
}

export function useDeleteVehicleTitle() {
  return useDelete<DeleteVehicleTitle, ListVehicleTitleIds>(
    DELETE_VEHICLE_TITLE,
    LIST_VEHICLE_TITLE_IDS,
    "deleteOwnedVehicle",
    "ownedVehicles"
  );
}
