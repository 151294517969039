import { CategoryDetail } from "components/FormModules/Assets/HouseholdItems/CategorySection/CategoryDetail";
import { HouseholdItemCategoryChoice } from "globalTypes";
import * as React from "react";
import { MdPhoneIphone } from "react-icons/md";

const Examples = () => (
  <div>
    <p>Examples include:</p>
    <ul>
      <li>Televisions and radios</li>
      <li>Audio, video, stereo, and digital equipment</li>
      <li>Computers, printers, scanners</li>
      <li>Music collections</li>
      <li>
        Electronic devices including cell phones, cameras, media players, games
      </li>
    </ul>
  </div>
);

const ElectronicsDetail: CategoryDetail = {
  category: HouseholdItemCategoryChoice.Electronics,
  displayName: "Electronics",
  displayLowercaseName: "electronics",
  hasNoProfileBooleanName: "hasNoHouseholdElectronicsItems",
  singleInterrogativeText: "Tell us about this electronic equipment.",
  noAssetDeclarativeText:
    "You have indicated that neither you nor any member of your household has any electronics.",
  booleanInterrogativeText: (
    <>
      <p className="required">
        <strong>
          Do you or any members of your household have any electronics?
        </strong>
      </p>
      <Examples />
    </>
  ),
  declarativeText: (
    <>
      <p className="required">
        <strong>
          Please itemize all electronics you and your family members own.
        </strong>
      </p>
      <Examples />
    </>
  ),
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add electronics below.",
  urlPathName: "electronics",
  displayMenuName: "Electronics",
  menuIcon: MdPhoneIphone,
};

export default ElectronicsDetail;
