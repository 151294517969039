import { QueryHookOptions, useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  onCreditCardDebtAdded,
  onCreditCardDebtRemoved,
} from "../CreditCardDebt";
import {
  onFinancialAccountAdded,
  onFinancialAccountRemoved,
} from "../FinancialAccount";
import { createCacheListUpdaters } from "../utils";
import { onVehicleLoanAdded, onVehicleLoanRemoved } from "../VehicleLoan";
import GET_ITEM from "./GetMxMember.gql";
import IMPORT_MEMBER from "./ImportMxMember.gql";
import LIST_ITEMS from "./ListMxMembers.gql";
import REMOVE_MEMBER from "./RemoveMxMember.gql";

const LIST_ITEM_IDS = gql`
  query ListMxMemberIds {
    mxMembers {
      id
    }
  }
`;

export const [onMxMemberAdded, onMxMemberRemoved] = createCacheListUpdaters<
  ImportMxMember["importMxMember"]
>(LIST_ITEM_IDS, "mxMembers");

export function useListMxMembers(options?: QueryHookOptions<ListMxMembers>) {
  return useQuery<ListMxMembers>(LIST_ITEMS, options);
}

export function useGetMxMember(id: string) {
  return useQuery<GetMxMember, GetMxMemberVariables>(GET_ITEM, {
    variables: { id },
  });
}

export function useImportMxMember() {
  return useMutation<ImportMxMember, ImportMxMemberVariables>(IMPORT_MEMBER, {
    update(cache, { data }) {
      const m = data.importMxMember;
      onMxMemberAdded(cache, m);

      m.financialAccounts.forEach((a) => {
        onFinancialAccountAdded(cache, a);
      });
      m.creditCardDebts.forEach((a) => {
        onCreditCardDebtAdded(cache, a);
      });
      m.vehicleLoans.forEach((a) => {
        onVehicleLoanAdded(cache, a);
      });
    },
  });
}

export function useRemoveMxMember() {
  return useMutation<RemoveMxMember, RemoveMxMemberVariables>(REMOVE_MEMBER, {
    update(cache, { data }) {
      const m = data.removeMxMember;
      onMxMemberRemoved(cache, m);

      m.financialAccounts.forEach((a) => {
        onFinancialAccountRemoved(cache, a);
      });
      m.creditCardDebts.forEach((a) => {
        onCreditCardDebtRemoved(cache, a);
      });
      m.vehicleLoans.forEach((a) => {
        onVehicleLoanRemoved(cache, a);
      });
    },
  });
}
