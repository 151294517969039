import * as React from "react";
import { resolveRelative, updateQueryParams } from "utils/urls";
import { useNestedPath } from "./useNestedPath";
import { useNestedURL } from "./useNestedURL";
import { useQueryParams } from "./useQueryParams";

export function useNested() {
  const rootPath = useNestedPath();
  const rootURL = useNestedURL();
  const queryParams = useQueryParams();
  return React.useMemo(
    () => ({
      rootPath,
      path: (subPath: string) =>
        resolveRelative(
          `${rootPath}/${subPath.startsWith("/") ? subPath.slice(1) : subPath}`
        ),
      rootURL,
      url: (
        subURL: string,
        { preserveSearch = false, preserveHash = false } = {}
      ) => {
        // resolve relative paths, because react router is dumb and
        // only uses exact string matches in links
        let url = resolveRelative(
          `${rootURL}/${subURL.startsWith("/") ? subURL.slice(1) : subURL}`
        );

        if (preserveSearch) {
          url = updateQueryParams(url, queryParams);
        }
        if (preserveHash) {
          url += location.hash;
        }
        return url;
      },
    }),
    [queryParams, rootPath, rootURL]
  );
}
