import { AnimatedSwitch } from "components/AnimatedSwitch";
import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { useNested } from "utils/hooks";
import { ErrorView } from "./ErrorView";
import { OverviewView } from "./OverviewView";
import { SuccessView } from "./SuccessView";
import { UploadView } from "./UploadView";

interface WizardProps {
  abortURL: string;
  nextURL: string;
  previousLoansCount?: number;
}

export function Wizard({ abortURL, nextURL, previousLoansCount }: WizardProps) {
  const { url, path } = useNested();
  const [wizardState, setWizardState] = React.useState(undefined);
  const onSuccess = React.useCallback(() => {
    setWizardState("SUCCESS");
  }, []);
  const onError = React.useCallback(() => {
    setWizardState("ERROR");
  }, []);
  const success = wizardState === "SUCCESS";
  const error = wizardState === "ERROR";

  return (
    <AnimatedSwitch animateRedirects={false}>
      <Route
        path={path("/overview")}
        render={() => (
          <OverviewView
            abortURL={abortURL}
            nextURL={url("/upload")}
            previousLoansCount={previousLoansCount}
          />
        )}
      />
      <Route
        path={path("/upload")}
        render={() => (
          <UploadView
            abortURL={abortURL}
            backURL={url("/overview")}
            successURL={url("/success")}
            errorURL={url("/error")}
          />
        )}
      />
      <Route
        path={path("/success")}
        render={() => {
          if (!success) {
            onSuccess();
          }
          return <Redirect to={url("/done")} />;
        }}
      />
      <Route
        path={path("/error")}
        render={() => {
          if (!error) {
            onError();
          }
          return <Redirect to={url("/done")} />;
        }}
      />
      <Route
        path={path("/done")}
        render={() => {
          if (success) {
            return <SuccessView nextURL={nextURL} />;
          } else if (error) {
            return <ErrorView abortURL={abortURL} backURL={url("/upload")} />;
          } else {
            return <Redirect to={url("/")} />;
          }
        }}
      />
      <Route exact path={path("/")}>
        <Redirect to={url("/overview")} />
      </Route>
      <Route>
        <Redirect to={url("/")} />
      </Route>
    </AnimatedSwitch>
  );
}
