import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDeleteEvict, useUpsertModify } from "api/graphql/utils";
import DELETE_BUSINESS from "./DeleteOwnedBusiness.gql";
import GET_BUSINESS from "./GetOwnedBusiness.gql";
import LIST_BUSINESSES from "./ListOwnedBusinesses.gql";
import UPSERT_BUSINESS from "./UpsertOwnedBusiness.gql";

export function useListOwnedBusinesses(
  options?: QueryHookOptions<ListOwnedBusinesses>
) {
  return useQuery<ListOwnedBusinesses>(LIST_BUSINESSES, options);
}

export function useGetOwnedBusiness(id: string) {
  return useQuery<GetOwnedBusiness, GetOwnedBusinessVariables>(GET_BUSINESS, {
    variables: { id },
  });
}

export function useUpsertOwnedBusiness<
  Schema extends { ownedBusiness: any } = UpsertOwnedBusiness,
  Variables = UpsertOwnedBusinessVariables
>(query = UPSERT_BUSINESS) {
  return useUpsertModify<Schema, Variables, OwnedBusinessInput>(
    query,
    "ownedBusiness",
    "ownedBusinesses",
    (cache) => updateFilingProfileCache(cache, "hasNoBusinesses", false)
  );
}

export function useDeleteOwnedBusiness() {
  return useDeleteEvict<DeleteOwnedBusiness>(
    DELETE_BUSINESS,
    "deleteOwnedBusiness",
    "OwnedBusiness"
  );
}
