import { FileEndpoint } from "./ApiEndpoint";

export interface EducationalStudiesAPIInput {
  school: string;
  degree: string;
  first_year?: number;
  last_year?: number;
  graduated?: boolean;
  years_attended: string[] | number[];
}

export interface MyStudentDataAPIOutput {
  suggested_data: {
    loans: object[];
    educational_studies: EducationalStudiesAPIInput[];
  };
}

export class MyStudentDataAPI extends FileEndpoint<MyStudentDataAPIOutput> {}
