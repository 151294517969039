import { Adder, fsum } from "d3-array";
import {
  differenceBy,
  find,
  findIndex,
  forEachRight,
  initial,
  last,
  set,
} from "lodash";
import {
  fuzzyEquals,
  fuzzyFindIndex,
  fuzzyGT,
  fuzzyMax,
  fuzzyMin,
  roundToTwoDecimalPlaces,
} from "utils/math";
import { RequiredKeys } from "utils/types";
import { TableItem } from ".";
import {
  computeSnappingPoints,
  defaultSnappingPointDefinitions,
  SnappingPoint,
} from "../Slider/snapping";
import { formatDisplayValue, unformatDisplayValue } from "./utils";

/**
 * Return a new, updated display value if the old value un-parses to a different
 * value than `value`. This utility allows for the partial input and editing of
 * values (i.e. $10.0 is the "same" display value as "$10.00", and 3.0000000000001
 * formats to the "same" display value as 3.0).
 * @param oldDisplayValue
 * @param value
 */
export const updateDisplayValue = (oldDisplayValue: string, value: number) => {
  if (oldDisplayValue === "") return "";

  if (fuzzyEquals(unformatDisplayValue(oldDisplayValue), value)) {
    return oldDisplayValue;
  }

  return formatDisplayValue(value);
};

/**
 * Re-compute the `max` and `displayValues` for an array of `TableItems`.
 * This adjuster makes sure that the sum of all the values will always add up
 * to `total`, by computing the `max` values of each table item are never greater
 * than the running total of items that come before it.
 *
 * For certain items, we might want to make sure that they are always a certain size
 * ("inelastic") and so the adjuster will squish the bounds of resizable (elastic) items to
 * make room.
 */
export const boundsAdjuster = <Details>(total: number) => (
  items: TableItem<Details>[],
  itemsWithInelasticValues?: TableItem<Details>[]
) => {
  const _inelasticItems =
    !itemsWithInelasticValues ||
    fuzzyGT(
      fsum(itemsWithInelasticValues, (i) => i.value),
      total
    )
      ? []
      : itemsWithInelasticValues;

  const recur = (recurItems: TableItem<Details>[]) =>
    boundsAdjuster<Details>(total)(recurItems, _inelasticItems);

  const isItemElastic = (item: TableItem<Details>) =>
    !find(_inelasticItems, (f) => f.id === item.id);

  let adjusted: TableItem<Details>[] = [];
  let remaining = total;
  for (const item of items) {
    const min = 0;
    const max = roundToTwoDecimalPlaces(
      isItemElastic(item) ? remaining : fuzzyMax(item.value, remaining)
    );
    const value = roundToTwoDecimalPlaces(
      isItemElastic(item) ? fuzzyMin(item.value, remaining) : item.value
    );
    const displayValue = updateDisplayValue(item.displayValue, value);

    if (value > remaining) {
      let backfillAmount = value - remaining;
      remaining += backfillAmount;

      forEachRight(adjusted, (itemToAdjust) => {
        if (!isItemElastic(itemToAdjust)) return;
        if (fuzzyEquals(backfillAmount, 0)) return;

        const adjustment = fuzzyMin(backfillAmount, itemToAdjust.value);
        itemToAdjust.value = roundToTwoDecimalPlaces(
          itemToAdjust.value - adjustment
        );
        itemToAdjust.displayValue = updateDisplayValue(
          itemToAdjust.displayValue,
          itemToAdjust.value
        );
        backfillAmount -= adjustment;
      });

      adjusted = recur(adjusted);
    }

    adjusted.push({ ...item, min, max, value, displayValue });
    remaining -= value;
  }

  return adjusted;
};

/**
 * This adjuster makes sure that TableItems that were at their max in
 * a previous state are still snapped to their newly changed max value
 * in the new state.
 */
export const stickyMaxAdjuster = <Details>(
  total: number,
  modifyChangedValues = false
) => (newItems: TableItem<Details>[], oldItems: TableItem<Details>[]) => {
  const adjusted = [] as TableItem<Details>[];

  const max = new Adder();
  max.add(total);

  for (const newItem of newItems) {
    const oldItem = find(oldItems, (old) => old.id === newItem.id);
    if (!oldItem) continue;

    const allowValueToChange =
      fuzzyEquals(newItem.value, oldItem.value) || modifyChangedValues;
    const maxIsSticky = fuzzyEquals(oldItem.value, oldItem.max);

    const value =
      allowValueToChange && maxIsSticky
        ? roundToTwoDecimalPlaces(max.valueOf())
        : newItem.value;

    const displayValue = updateDisplayValue(newItem.displayValue, value);

    adjusted.push({
      ...newItem,
      max: roundToTwoDecimalPlaces(max.valueOf()),
      value,
      displayValue,
    });
    max.add(-value);
  }

  return adjusted;
};

/**
 * This adjuster increases the last item in a group of table items to sum
 * to a given total. Needed because of the unevenly sized groups that result
 * in dividing and rounding groups to the nearest penny (10/3 -> [3.33, 3.33, 3.34])
 */
export const increaseLastItemToTotalAdjuster = <Details>(total: number) => (
  items: TableItem<Details>[]
) => {
  const runningTotal = fsum(items, (item) => item.value);
  const delta = total - runningTotal;
  if (fuzzyGT(delta, 0)) {
    const lastItem = last(items);
    const value = roundToTwoDecimalPlaces(lastItem.value + delta);
    const displayValue = formatDisplayValue(value);

    return [...initial(items), { ...lastItem, value, displayValue }];
  } else {
    return items;
  }
};

/**
 * Sets the value of all TableItems to total/n rounded to the nearest penny,
 * with a fudge factor to make sure that the items sum adds up to the total.
 */
export const evenDistributionAdjuster = <Details>(total: number) => (
  items: TableItem<Details>[]
) => {
  if (!items.length) return items;

  const adjustBounds = boundsAdjuster(total);
  const value = roundToTwoDecimalPlaces(total / items.length);
  const displayValue = formatDisplayValue(value);
  const adjusted = adjustBounds(
    items.map((item) => ({ ...item, value, displayValue }))
  );

  // make sure the last item is snapped to the max value
  const fudge = roundToTwoDecimalPlaces(total - fsum(adjusted, (a) => a.value));
  const finalItem = last(adjusted);
  const fudgeFinalValue = roundToTwoDecimalPlaces(finalItem.value + fudge);
  const fudgedFinalItem = {
    ...finalItem,
    value: fudgeFinalValue,
    displayValue: updateDisplayValue(finalItem.displayValue, fudgeFinalValue),
  };

  return [...initial(adjusted), fudgedFinalItem] as TableItem<Details>[];
};

/**
 * Checks to see if TableItems were evenly distributed using the same
 * rules that the `evenDistributionAdjuster` uses.
 */
export const evenDistributionChecker = <Details>(total: number) => (
  items: TableItem<Details>[]
) => {
  if (!items.length) return true;

  const evenValue = roundToTwoDecimalPlaces(total / items.length);
  const allButLastSnapped = initial(items).reduce(
    (acc, item) => acc && fuzzyEquals(item.value, evenValue),
    true
  );

  return (
    allButLastSnapped &&
    fuzzyEquals(
      fsum(items, (item) => item.value),
      total
    )
  );
};

/**
 * Scale the value of TableItems by a factor of newTotal/oldTotal.
 *
 * If `newTotal` is 0, all items are zero. If `oldTotal` is 0, the
 * first item is set to `newTotal`, and all other items are set to 0.
 */
export const rescaleItemValues = <Details>({
  items,
  oldTotal,
  newTotal,
}: {
  items: TableItem<Details>[];
  newTotal: number;
  oldTotal: number;
}) => {
  const adjusted = [] as TableItem<Details>[];
  const scalingFromZero = fuzzyEquals(oldTotal, 0);
  const scale = fuzzyEquals(newTotal, 0)
    ? 0
    : scalingFromZero
    ? Infinity
    : newTotal / oldTotal;

  const currentMax = new Adder();
  currentMax.add(newTotal);

  for (const [i, item] of items.entries()) {
    const max = fuzzyEquals(scale, 0)
      ? 0
      : roundToTwoDecimalPlaces(currentMax.valueOf());
    const value =
      scalingFromZero && i === 0
        ? max
        : roundToTwoDecimalPlaces(item.value * scale || 0);

    adjusted.push({
      ...item,
      value,
      max,
      displayValue: updateDisplayValue(item.displayValue, value),
    });
    currentMax.add(-value);
  }

  return adjusted;
};

/**
 * Re-snap items in the new state that were previously snapped in the old state.
 * This is the magic sauce to get around the lack of precision encountered by just
 * scaling alone.
 *
 * The adjuster is dumb and will only work if the new and old snapping points are in the
 * same order and have the same length.
 */
export const reSnapItems = <Details>(
  newState: TableState<Details>,
  oldState: TableState<Details>
) => {
  if (newState.items.length !== oldState.items.length) return newState;
  if (oldState.total.value === 0) return newState;

  const adjusted = [] as TableItem<Details>[];
  const oldSnappingPointNumbers = oldState.snappingPoints.map(
    (snap) => snap.value
  );
  const newSnappingPointNumbers = newState.snappingPoints.map(
    (snap) => snap.value
  );

  const currentMax = new Adder();
  currentMax.add(newState.total.value);

  for (const [i, newItem] of newState.items.entries()) {
    const oldItem = oldState.items[i];
    const previousSnappingPointIndex = fuzzyFindIndex(
      oldSnappingPointNumbers,
      oldItem.value
    );
    const wasPreviouslySnapped = previousSnappingPointIndex !== -1;
    const value = wasPreviouslySnapped
      ? newSnappingPointNumbers[previousSnappingPointIndex]
      : newItem.value;

    adjusted.push({
      ...newItem,
      value,
      displayValue: updateDisplayValue(newItem.displayValue, value),
      max: roundToTwoDecimalPlaces(currentMax.valueOf()),
    });

    currentMax.add(-value);
  }

  return {
    ...newState,
    items: stickyMaxAdjuster<Details>(newState.total.value, true)(
      adjusted,
      oldState.items
    ),
  };
};

export type TableState<Details = {}> = {
  total: { value: number; displayValue: string };
  items: TableItem<Details>[];
  snappingPoints: SnappingPoint[];
  dirty: boolean;
};

const setDirty = <Details>(state: TableState<Details>, isDirty = true) => ({
  ...state,
  dirty: isDirty,
});

export type Action<Details = {}> =
  | { type: "UpdateItem"; updatedItem: TableItem<Details> }
  | { type: "ReplaceAllItems"; items: TableItem<Details>[] }
  | { type: "DeleteItem"; id: UUID }
  | ({ type: "AddItem"; id: UUID; details: Details } & Partial<
      Omit<TableItem<Details>, "id">
    >)
  | { type: "UpdateTotal"; value: number; displayValue: string }
  | { type: "UpdateDirty"; dirty: boolean };

/**
 * Sub-reducer for TableItems, broken out for readability and compositionality.
 */
const getItemsReducer = <Details>(total: number) => (
  state: TableItem<Details>[],
  action: Action<Details>
): TableItem<Details>[] => {
  const adjustBounds = boundsAdjuster<Details>(total);
  const evenlyDistribute = evenDistributionAdjuster<Details>(total);
  const isEvenlyDistributed = evenDistributionChecker<Details>(total);
  const adjustStickyMax = stickyMaxAdjuster<Details>(total);
  const fillEmpty = increaseLastItemToTotalAdjuster<Details>(total);

  const { type, ...rest } = action;
  switch (action.type) {
    case "UpdateItem":
      return adjustStickyMax(
        adjustBounds(
          set(
            [...state],
            findIndex(state, (item) => item.id === action.updatedItem.id),
            action.updatedItem
          ),
          [action.updatedItem]
        ),
        state
      );
    case "DeleteItem":
      if (isEvenlyDistributed(state)) {
        return evenlyDistribute(state.filter((item) => item.id !== action.id));
      } else {
        return adjustStickyMax(
          adjustBounds(state.filter((item) => item.id !== action.id)),
          state
        );
      }
    case "ReplaceAllItems":
      // so any existing order doesn't change between replacements
      // i.e. after save
      return adjustBounds([
        ...(() => {
          const ordered: TableItem<Details>[] = [];
          for (const oldItem of state) {
            const newItem = find(action.items, (i) => i.id === oldItem.id);
            if (newItem) ordered.push(newItem);
          }
          return ordered;
        })(),
        ...differenceBy(action.items, state, "id"),
      ]);
    case "AddItem":
      /* eslint-disable-next-line no-case-declarations */
      const newItem = {
        value: 0,
        min: 0,
        max: total,
        displayValue: formatDisplayValue(0),
        id: action.id,
        details: action.details,
        ...rest,
      };
      if (isEvenlyDistributed(state)) {
        return evenlyDistribute([...state, newItem]);
      } else {
        return fillEmpty(adjustBounds([...state, newItem]));
      }
    default:
      return state;
  }
};

/**
 * Main table reducer. Dynamically re-computes snapping definitions to allow
 * for precision scaling and snapping.
 */
export const getTableReducer = <Details = {}>(
  snappingPointDefinitions = defaultSnappingPointDefinitions
) => (
  state: TableState<Details>,
  action: Action<Details>
): TableState<Details> => {
  switch (action.type) {
    case "ReplaceAllItems":
    case "AddItem":
    case "DeleteItem":
    case "UpdateItem":
      return setDirty({
        ...state,
        items: getItemsReducer<Details>(state.total.value)(state.items, action),
      });
    case "UpdateTotal":
      return setDirty(
        reSnapItems<Details>(
          {
            ...state,
            total: {
              value: action.value,
              displayValue: action.displayValue,
            },
            items:
              action.value === state.total.value
                ? state.items
                : rescaleItemValues({
                    items: state.items,
                    newTotal: action.value,
                    oldTotal: state.total.value,
                  }),
            snappingPoints:
              action.value === state.total.value
                ? state.snappingPoints
                : computeSnappingPoints({ min: 0, max: action.value }),
          },
          state
        )
      );
    case "UpdateDirty":
      return setDirty(state, action.dirty);
    default:
      return state;
  }
};

export interface GetInitialTableStateArgs<Details> {
  items?: RequiredKeys<TableItem<Details>, "id" | "value" | "details">[];
  total?: number;
  dirty?: boolean;
}

export const getTableInitialState = <Details>(
  { items, total, dirty }: GetInitialTableStateArgs<Details> = {
    items: [],
    total: 0,
    dirty: false,
  }
) => ({
  total: {
    value: total || 0,
    displayValue: formatDisplayValue(total || 0),
  },
  items: !items
    ? []
    : boundsAdjuster<Details>(total || 0)(
        items.map((item) => ({
          ...item,
          displayValue: item.displayValue
            ? item.displayValue
            : formatDisplayValue(item.value),
          details: item.details,
          min: 0,
          max: total || 0,
        }))
      ),
  snappingPoints: computeSnappingPoints({ min: 0, max: total || 0 }),
  dirty,
});
