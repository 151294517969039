import { useEditorBuffer } from "components/EditorState/hooks";
import { NumberInput } from "components/NumberInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { Controller } from "react-hook-form";
import { array, boolean, number, object, ref } from "yup";
import { YearsAttendedInput } from "../YearsAttendedInput";

export const YearsAttendedSchema = object({
  firstYear: number()
    .required("Please enter the first year you attended.")
    .min(1920, "Please select a year on or after 1920.")
    .max(new Date().getFullYear(), "First year must be this year or earlier."),
  lastYear: number()
    .min(1920, "Please select a year on or after 1920.")
    .max(new Date().getFullYear(), "Last year must be this year or earlier.")
    .min(ref("firstYear"), "Last year must be later than first year.")
    .required("Please enter the last year you attended."),
  yearsAttended: array(number()).required("This field is required."),
  tookOff: boolean()
    .when("yearsAttended", {
      is: (x) => !x,
      then: boolean().required("This question is required"),
      otherwise: boolean().optional(),
    })
    .strip(true),
}).required();

export function YearsAttended({
  isEdit,
  form,
  navProps,
}: WizardRouteChildProps) {
  const [{ buffer }] = useEditorBuffer();

  return (
    <>
      <p>When did you attend {buffer.school}?</p>
      <div className="form-row">
        <NumberInput
          name="firstYear"
          label="First year"
          placeholder="e.g. 2013"
          inputMode="numeric"
          width="150px"
          autoFocus={!isEdit}
          integerLimit={4}
          onChange={() =>
            form.formState.isSubmitted && form.trigger("lastYear")
          }
        />
        <NumberInput
          name="lastYear"
          label="Last year"
          placeholder="e.g. 2018"
          inputMode="numeric"
          width="150px"
          integerLimit={4}
        />
      </div>
      <Controller
        name="yearsAttended"
        defaultValue={buffer.yearsAttended}
        as={
          <YearsAttendedInput
            firstYear={form.watch("firstYear")}
            lastYear={form.watch("lastYear")}
          />
        }
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
