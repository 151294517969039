import { FarmAssetCategory } from "globalTypes";
import React from "react";
import { GiWheat } from "react-icons/gi";
import { CategoryDetail } from "../CategorySection/CategoryDetail";

const MarketableCropsDetail: CategoryDetail = {
  category: FarmAssetCategory.MarketableCrops,
  displayName: "Crops",
  displayLowercaseName: "crops",
  hasNoProfileBooleanName: "hasNoMarketableCrops",
  singleInterrogativeText:
    "Tell us about a single class of marketable crops you own, such as a harvest of corn or a plot of wheat.",
  noAssetDeclarativeText:
    "You have indicated that you don’t have any growing or harvested marketable crops.",
  booleanInterrogativeText: (
    <p className="required">
      <strong>
        Do you have any marketable crops, whether growing or harvested?
      </strong>
    </p>
  ),
  declarativeText: (
    <p>Please list all harvested or growing marketable crops you own.</p>
  ),
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add crops below.",
  urlPathName: "crops",
  menuIcon: GiWheat,
};

export default MarketableCropsDetail;
