import { useEditorBuffer } from "components/EditorState/hooks";
import { WizardRouteChildProps } from "components/Wizard";
import CosignersWizardStep from "components/WizardSteps/Cosigners";
import * as React from "react";

export function Cosigner(props: WizardRouteChildProps) {
  const [{ buffer }] = useEditorBuffer();
  const loanId = buffer.id;

  return (
    <CosignersWizardStep
      relatedTypename="StudentLoan"
      relatedId={loanId}
      {...props}
    />
  );
}
