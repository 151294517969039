import { Card } from "components/Card";
import * as React from "react";
import { Helmet } from "react-helmet";
import css from "../styles.module.css";

export function SettlementAssessment() {
  return (
    <>
      <Helmet>
        <title>Expect to hear from us soon</title>
      </Helmet>

      <div className={css.container}>
        <Card className={css.infoCard}>
          <h3>Getting started</h3>
          <p>
            It looks like there are some ways we may be able to help you. You
            should expect to hear from our customer success team within the next
            three business days.
          </p>
        </Card>
      </div>
    </>
  );
}
