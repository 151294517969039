import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, { SummaryStatsRow } from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import GOVERNMENT_DISBURSEMENTS_MODULE from "./GovernmentDisbursementsModule.gql";

export default function GovernmentDisbursementsSummary(
  props: SubModuleSummaryProps
) {
  const { data } = useQuery<GovernmentDisbursementsModule>(
    GOVERNMENT_DISBURSEMENTS_MODULE,
    {
      returnPartialData: true,
    }
  );

  return (
    <ModuleSummary {...props} progress={data?.progress.governmentDisbursements}>
      <SummaryStatsRow>
        <NumericSummary
          label="disbursement"
          maxValueLength={2}
          value={data?.governmentDisbursements?.length}
          pluralizeLabel
        />
      </SummaryStatsRow>
    </ModuleSummary>
  );
}
