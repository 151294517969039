import { StatelessEditCreateList } from "components/EditCreateList";
import { FormModuleProps } from "components/FormFlow";
import React from "react";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

export default function OwnedBusinesses({ prev, next }: FormModuleProps<any>) {
  return (
    <StatelessEditCreateList
      prev={next}
      next={prev}
      ListView={ListView}
      EditView={EditView}
      CreateView={CreateView}
    />
  );
}
