import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import { EnvironmentalCleanupNoticeInput } from "globalTypes";
import gql from "graphql-tag";
import DELETE_SEIZURE from "./DeleteEnvironmentalCleanupNotice.gql";
import GET_SEIZURE from "./GetEnvironmentalCleanupNotice.gql";
import LIST_SEIZURE from "./ListEnvironmentalCleanupNotice.gql";
import UPSERT_SEIZURE from "./UpsertEnvironmentalCleanupNotice.gql";

const LIST_SEIZURE_IDS = gql`
  query ListEnvironmentalCleanupNoticeIds {
    environmentalCleanupNotices {
      id
    }
  }
`;

export function useListEnvironmentalCleanupNotice(
  options?: QueryHookOptions<ListEnvironmentalCleanupNotice>
) {
  return useQuery<ListEnvironmentalCleanupNotice>(LIST_SEIZURE, options);
}

export function useGetEnvironmentalCleanupNotice(id: string) {
  return useQuery<
    GetEnvironmentalCleanupNotice,
    GetEnvironmentalCleanupNoticeVariables
  >(GET_SEIZURE, {
    variables: { id },
  });
}

export function useUpsertEnvironmentalCleanupNotice<
  Schema extends {
    environmentalCleanupNotice: any;
  } = UpsertEnvironmentalCleanupNotice,
  Variables = UpsertEnvironmentalCleanupNoticeVariables
>(query = UPSERT_SEIZURE) {
  return useUpsert<
    Schema,
    Variables,
    ListEnvironmentalCleanupNoticeIds,
    EnvironmentalCleanupNoticeInput
  >(
    query,
    LIST_SEIZURE_IDS,
    "environmentalCleanupNotice",
    "environmentalCleanupNotices",
    (cache) =>
      updateFilingProfileCache(cache, "hasNoEnvironmentalCleanupNotices", false)
  );
}

export function useDeleteEnvironmentalCleanupNotice() {
  return useDelete<
    DeleteEnvironmentalCleanupNotice,
    ListEnvironmentalCleanupNoticeIds
  >(
    DELETE_SEIZURE,
    LIST_SEIZURE_IDS,
    "deleteEnvironmentalCleanupNotice",
    "environmentalCleanupNotices"
  );
}
