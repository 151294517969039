import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaMobile, FaHome } from "react-icons/fa";
import { HouseholdContributors } from "./HouseholdContributors";
import HouseholdContributorsSummary from "./Summary";

const HouseholdContributorsSubmodule: SubModule = {
  path: "/household_contributors",
  icon: FaHome,
  title: "Expense Contributors",
  component: HouseholdContributors,
  summary: HouseholdContributorsSummary,
  statusName: "householdContributors",
};

export default HouseholdContributorsSubmodule;
