// Taken from https://tobiasahlin.com/spinkit/
import cn from "classnames";
import css from "./Spinner.module.scss";

export function Spinner() {
  return (
    <div className={cn("flex space-x-1", css.loader)}>
      <div className="w-1 h-1 bg-gray-400 rounded-full animate-pulse"></div>
      <div className="w-1 h-1 bg-gray-400 rounded-full animate-pulse"></div>
      <div className="w-1 h-1 bg-gray-400 rounded-full animate-pulse"></div>
    </div>
  );
}
