import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDeleteEvict, useUpsertModify } from "api/graphql/utils";
import { InsurancePolicyType } from "globalTypes";
import DELETE_INSURANCE_POLICY from "./DeleteInsurancePolicy.gql";
import GET_INSURANCE_POLICY from "./GetInsurancePolicy.gql";
import LIST_INSURANCE_POLICIES from "./ListInsurancePolicies.gql";
import UPSERT_INSURANCE_POLICY from "./UpsertInsurancePolicy.gql";

export function useListInsurancePolicies(
  options?: QueryHookOptions<ListInsurancePolicies>
) {
  return useQuery<ListInsurancePolicies>(LIST_INSURANCE_POLICIES, options);
}

export function useGetInsurancePolicy(id: string) {
  return useQuery<GetInsurancePolicy, GetInsurancePolicyVariables>(
    GET_INSURANCE_POLICY,
    {
      variables: { id },
    }
  );
}

export const getCategoryHasNoBooleanName = (category: InsurancePolicyType) => {
  switch (category) {
    case InsurancePolicyType.Health:
      return "hasNoHouseholdInsuranceHealthcareExpenses";
    case InsurancePolicyType.Dental:
      return "hasNoHouseholdInsuranceDentalExpenses";
    case InsurancePolicyType.Vision:
      return "hasNoHouseholdInsuranceVisionExpenses";
    case InsurancePolicyType.DisabilityLong:
      return "hasNoHouseholdInsuranceLongTermDisabilityExpenses";
    case InsurancePolicyType.DisabilityShort:
      return "hasNoHouseholdInsuranceShortTermDisabilityExpenses";
    case InsurancePolicyType.WholeLife:
      return "hasNoHouseholdInsuranceWholeExpenses";
    case InsurancePolicyType.TermLife:
      return "hasNoHouseholdInsuranceTermExpenses";
    case InsurancePolicyType.Auto:
      return "hasNoTransportationInsuranceExpenses";
    case InsurancePolicyType.RealEstate:
      return "hasNoRealEstateInsuranceExpenses";
    default:
      throw new Error(`unexpected insurance type ${category}`);
  }
};

export function useUpsertInsurancePolicy<
  Schema extends { insurancePolicy: any } = UpsertInsurancePolicy,
  Variables = UpsertInsurancePolicyVariables
>(query = UPSERT_INSURANCE_POLICY) {
  return useUpsertModify<
    Schema,
    Variables,
    InsurancePolicyInput,
    QueryInsurancePoliciesArgs
  >(query, "insurancePolicy", "insurancePolicies", (cache, data) => {
    updateFilingProfileCache(
      cache,
      getCategoryHasNoBooleanName(data.insurancePolicy.type),
      false
    );
  });
}

export function useDeleteInsurancePolicy() {
  return useDeleteEvict<DeleteInsurancePolicy>(
    DELETE_INSURANCE_POLICY,
    "deleteInsurancePolicy",
    "InsurancePolicy"
  );
}
