import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import RETIREMENT_MODULE from "./RetirementAccountLoansModule.gql";

export default function RetirementAccountLoansSummary(
  props: SubModuleSummaryProps
) {
  const { data } = useQuery<RetirementAccountLoansModule>(RETIREMENT_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress?.retirementAccountLoans}>
      {data?.filingProfile?.hasNoRetirementAccountLoans ? (
        <HasNoSummary>
          You don&rsquo;t have any retirement account loans.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="401(k) loan"
            maxValueLength={2}
            value={data?.retirementAccountLoans?.length}
            pluralizeLabel
          />
          <NumericSummary
            label="total balance"
            valuePrefix="$"
            maxValueLength={5}
            value={fancyNumberSum(
              data?.retirementAccountLoans,
              "outstandingAmount"
            )}
          />
          <NumericSummary
            label="monthly payments"
            valuePrefix="$"
            maxValueLength={4}
            value={fancyNumberSum(
              data?.retirementAccountLoans,
              "monthlyPayment"
            )}
          />
          <NumericSummary
            label="overdue"
            valuePrefix="$"
            maxValueLength={4}
            value={fancyNumberSum(
              data?.retirementAccountLoans,
              "delinquentAmount"
            )}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
