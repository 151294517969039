import { MaskedInput } from "components/MaskedInput";
import { TextInput, TextInputCommonProps } from "components/TextInput";
import { isFunction } from "lodash";
import * as React from "react";

interface Props extends TextInputCommonProps {
  required?: boolean;
}

export function PhoneNumberInput({
  name = "phoneNumber",
  label = "Phone number",
  required = false,
  placeholder = "555-555-0100",
  rules,
  width = "8em",
  ...etc
}: Props) {
  const customValidate = isFunction(rules?.validate)
    ? { custom: rules.validate }
    : rules?.validate || {};

  const rulesWithRequired = {
    required,
    ...rules,
    validate: {
      ...customValidate,
      format: (value: string) =>
        !value ||
        /^\d\d\d-?\d\d\d-?\d\d\d\d$/.test(value) ||
        "Please enter a phone number in the format of 555-555-0100.",
    },
  };

  return (
    <TextInput
      label={label}
      rules={rulesWithRequired}
      name={name}
      width={width}
      {...etc}
      inputElement={
        <MaskedInput
          type="tel"
          placeholder={placeholder}
          aria-label={label}
          mask={[
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
        />
      }
    />
  );
}
