import { ButtonRow } from "components/ButtonRow";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import LearnMore from "./CreditReportsLearnMore";
import { SignupButton } from "./signup";

export interface Props {
  login: string;
}

export default function IndexView(props: Props) {
  const history = useHistory();

  return (
    <>
      <p>
        To build a complete and accurate picture of your financial situation we
        will need to pull your credit report. <LearnMore />
      </p>
      <p>
        You’ll need to sign up for a free account on our credit reporting site.
        Once you’ve signed up come back, and enter your credentials here to
        finish the import.
      </p>

      <ButtonRow
        right={
          <SignupButton
            onClick={() => {
              setTimeout(() => history.push(props.login), 1500);
            }}
          />
        }
        left={
          <Link to={props.login} className="btn flat">
            I’ve already signed up
          </Link>
        }
      />
    </>
  );
}
