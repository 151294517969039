import cn from "classnames";
import { Card } from "components/Card";
import { Modal } from "components/Modal";
import { useAwaitModalClose } from "components/Modal/hooks";
import * as React from "react";
import { createPortal } from "react-dom";
import { FaTimes } from "react-icons/fa";
import { ModalCloseCtx } from "./contexts";
import s from "./LearnMore.module.css";

const PORTAL_ID = "_help-text-display";

interface LearnMoreProps {
  /** The expanded help text */
  children: React.ReactNode;
  /** The content of the trigger link. Also sets the card title unless overriden. */
  linkText?: React.ReactNode;
  /** The title displayed in the LearnMore card. Defaults to props.linkText */
  title?: React.ReactNode;
}

/** Show a link which displays expanded help text when clicked */
export function LearnMore({
  linkText = "Learn more",
  title,
  children,
}: LearnMoreProps) {
  const [isOpen, setOpen] = React.useState(false);
  const openLink = React.useRef();
  const listenerCallback = React.useRef<(e: MouseEvent) => void>();
  const portals = document.querySelectorAll(`#${PORTAL_ID}`);
  if (portals.length > 1) {
    // eslint-disable-next-line no-console
    console.warn(
      "More than one help text display location detected. Only the first one will be used."
    );
  }
  const portal = portals[0];

  const close = () => {
    setOpen(false);
    document.removeEventListener("click", listenerCallback.current);
  };

  const open = () => {
    setOpen(true);
    listenerCallback.current = (e) => {
      const target = e.target as HTMLElement;
      if (
        target !== openLink.current &&
        target?.className?.includes?.(s.HelpLink)
      ) {
        close();
      }
    };
    document.addEventListener("click", listenerCallback.current);
  };

  const closeCompleted = useAwaitModalClose();
  const dismiss = async () => {
    close();
    await closeCompleted();
  };

  const content = (
    <Card
      className={s.card}
      title={
        <div className={s.cardTitle}>
          <h3>{title || linkText}</h3>
          <button type="button" className="btn flat icon" onClick={close}>
            <FaTimes />
          </button>
        </div>
      }
    >
      <div className={s.cardContent}>
        {React.Children.map(children, (child: React.ReactElement) =>
          React.cloneElement(child, child?.props)
        )}
      </div>
    </Card>
  );

  return (
    <>
      <a onClick={open} className={s.HelpLink} ref={openLink}>
        {linkText}
      </a>
      {portal && isOpen ? (
        createPortal(content, portal)
      ) : (
        <Modal isOpen={isOpen} onRequestClose={close}>
          <ModalCloseCtx.Provider value={dismiss}>
            {content}
          </ModalCloseCtx.Provider>
        </Modal>
      )}
    </>
  );
}

export function LearnMorePortal({
  className,
  ...props
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      {...props}
      id={PORTAL_ID}
      className={cn(s.LearnMorePortal, className)}
    />
  );
}
