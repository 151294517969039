import { useListRoot } from "components/EditCreateList/hooks";
import { FinancialAccountWizard } from "components/FormModules/Assets/FinancialAccounts/FinancialAccountWizard";
import { financialAccountTypes } from "components/FormModules/Assets/FinancialAccounts/utils";
import { OptionCard, OptionsList } from "components/OptionCard";
import { Title } from "components/Title";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { formatFinancialAccountType } from "utils/formatters";
import { useNested } from "utils/hooks";

interface Props {
  listURL: string;
  accountType: FinancialAccountTypeChoice;
}

export default function CreateView(props: Props) {
  const { url, rootPath, path } = useNested();
  const { url: rootURL } = useListRoot();

  const redirectURL = props.accountType
    ? url(
        formatFinancialAccountType(props.accountType)
          .toLowerCase()
          .replace(/\s/g, "_")
      )
    : null;

  return (
    <>
      {redirectURL && <Redirect to={redirectURL} />}
      <Switch>
        <Route
          exact
          path={rootPath}
          render={() => (
            <>
              <Title>Add Financial Account</Title>
              <p>What type of account is this?</p>
              <OptionsList>
                {financialAccountTypes.map(({ value, icon }) => (
                  <OptionCard
                    key={value}
                    Icon={icon}
                    href={url(
                      formatFinancialAccountType(value)
                        .toLowerCase()
                        .replace(" ", "_"),
                      { preserveSearch: true }
                    )}
                  >
                    {formatFinancialAccountType(value)}
                  </OptionCard>
                ))}
              </OptionsList>
            </>
          )}
        />

        {financialAccountTypes.map(({ value, icon }) => (
          <Route
            key={value}
            path={path(
              formatFinancialAccountType(value).toLowerCase().replace(" ", "_")
            )}
            render={() => (
              <FinancialAccountWizard
                title={`Add ${formatFinancialAccountType(value)} Account`}
                accountType={value}
                backURL={props.listURL}
                nextURL={props.listURL}
              />
            )}
          />
        ))}
        <Redirect to={rootURL} />
      </Switch>
    </>
  );
}
