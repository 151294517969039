import { LearnMore } from "components/LearnMore";
import * as React from "react";

export function WantToKeepLearnMore() {
  return (
    <LearnMore>
      <h4>What does it mean to keep my property or service?</h4>
      <p>
        Many – but not all – assets can be protected in bankruptcy. This means
        that you may be able to retain the property after bankruptcy. Depending
        on your circumstances, the Bankruptcy Court may require you to make
        monthly payments under Chapter 13 of the Bankruptcy Code to retain your
        property. Your lawyer will review with you what can and cannot be
        protected.
      </p>
    </LearnMore>
  );
}
