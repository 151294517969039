import { AddressForm, AddressSchema } from "components/FormSections/Address";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { object } from "yup";

export const StatementDetailsSchema = object({
  lenderAddress: AddressSchema.nullable(),
});

export default function StatementDetails({ navProps }: WizardRouteChildProps) {
  return (
    <>
      <p>
        What is the address of the lender or loan servicer who sent this
        statement?
      </p>
      <AddressForm name="lenderAddress" graphQL />

      <WizardNavigation {...navProps} isComplete={false} />
    </>
  );
}
