import { AddressForm, AddressSchema } from "components/FormSections/Address";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { object, string } from "yup";

export const CreditorSchema = object({
  creditorName: string().required(
    "Please provide the name of the creditor that performed the seizure."
  ),
  creditorAddress: AddressSchema.required(),
}).required();

export function Creditor({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <TextInput
          name="creditorName"
          label="Creditor Name"
          maxLength={128}
          width="200px"
          autoFocus
        />
      </div>
      <p>What is the creditors contact address?</p>
      <AddressForm name="creditorAddress" graphQL />
      <WizardNavigation {...navProps} />
    </>
  );
}
