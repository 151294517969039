import { BooleanInput } from "components/BooleanInput";
import { DateInput } from "components/DateInput";
import { DecimalInput } from "components/NumberInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { DateSchema, DecimalSchema } from "utils/validators/yup";
import { boolean, object } from "yup";

export const DetailsSchema = object({
  stillUse: boolean().required("This field is required."),
  outstandingAmount: DecimalSchema.required(
    "Please enter the outstanding amount for this debt."
  ),
  delinquentAmount: DecimalSchema.required(
    "Please enter the delinquent amount if any, otherwise enter “0”."
  ),
  monthlyPayment: DecimalSchema.required(
    "Please enter your monthly payment for this debt."
  ),
  lastPaymentDate: DateSchema.optional()
    .nullable()
    .dateNotFuture("Last payment date can’t be in the future."),
}).required();

export default function Details({ navProps, isEdit }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <BooleanInput
          name="stillUse"
          label="Do you still use this provider?"
          autoFocus={!isEdit}
        />
      </div>

      <div className="form-row">
        <DecimalInput
          name="monthlyPayment"
          label="Monthly payment"
          addOnBefore="$"
          width="150px"
          includeThousandsSeparator
          allowDecimal
        />
        <DecimalInput
          name="outstandingAmount"
          label="Outstanding amount"
          addOnBefore="$"
          width="150px"
          includeThousandsSeparator
          allowDecimal
        />
      </div>

      <div className="form-row">
        <DecimalInput
          name="delinquentAmount"
          label="Delinquent amount"
          addOnBefore="$"
          width="150px"
          includeThousandsSeparator
          allowDecimal
        />
        <DateInput
          name="lastPaymentDate"
          label="Last payment date"
          width="150px"
        />
      </div>

      <WizardNavigation {...navProps} />
    </>
  );
}
