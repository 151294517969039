import { useQuery } from "@apollo/client";
import CategoriesList from "components/CategoriesList";
import { FormModuleProps } from "components/FormFlow";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import { sortBy, sumBy } from "lodash";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { breakpoints, useBreakpoint, useNested } from "utils/hooks";
import { parseFancyNumber } from "utils/validators";
import { CategorySection } from "./CategorySection";
import INSURANCE_MODULE from "./InsuranceModule.gql";
import { InsurancePolicySchema } from "./InsurancePolicyWizard";
import * as types from "./types";

const sections = [
  types.autoCategoryDetail,
  types.realEstateCategoryDetail,
  types.healthCategoryDetail,
  types.dentalCategoryDetail,
  types.visionCategoryDetail,
  types.shortTermDisabilityCategoryDetail,
  types.longTermDisabilityCategoryDetail,
  types.termLifeCategoryDetail,
  types.wholeLifeCategoryDetail,
];

export default function Insurance({ prev, next }: FormModuleProps<undefined>) {
  const isMobile = useBreakpoint(breakpoints.mobile);
  const { path, url } = useNested();
  const { data } = useQuery<InsuranceModule>(INSURANCE_MODULE);

  if (!data) return <Loading />;

  const currencyFormatter = new Intl.NumberFormat("en", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <Switch>
        {sections.map((categoryDetail, index) => {
          return (
            <Route
              key={index}
              path={path(`${categoryDetail.urlPathName}`)}
              render={() => (
                <CategorySection
                  key={index}
                  next={url("/")}
                  prev={url("/")}
                  categoryDetail={categoryDetail}
                />
              )}
            />
          );
        })}

        <Route
          exact
          path={path("/")}
          render={() => (
            <div>
              <Title>Insurance Policies</Title>
              <p>
                When filing for bankruptcy, your lawyer will need to know about
                all insurance policies you and your household are covered under.{" "}
                {isMobile ? "Tap " : "Click "} each of the following sections to
                tell us about your current insurance policies.
              </p>

              <CategoriesList
                doneURL={next}
                categories={sortBy(sections, (c) => {
                  // Sort incomplete categories to the top
                  const items = data.insurancePolicies.filter(
                    (policy) => policy.type === c.category
                  );
                  const done =
                    !!items.length ||
                    data.filingProfile[c.hasNoProfileBooleanName];
                  return done ? 1 : 0;
                }).map((c) => {
                  const items = data.insurancePolicies.filter(
                    (policy) => policy.type === c.category
                  );
                  const totalCost = currencyFormatter.format(
                    sumBy(items, (policy) =>
                      Number(parseFancyNumber(policy.premium))
                    )
                  );

                  return {
                    name: c.displayName,
                    Icon: c.menuIcon,
                    href: url(c.urlPathName),
                    hasNone: data.filingProfile[c.hasNoProfileBooleanName],
                    items,
                    itemName: "policy",
                    schema: InsurancePolicySchema,
                    itemsSummary: `${totalCost}/month`,
                  };
                })}
              />
            </div>
          )}
        />
      </Switch>
    </>
  );
}
