import { useCreateLawFirmUser } from "api/graphql/LawFirmUser";
import { Form } from "components/Form";
import { TextInput } from "components/TextInput";
import { WizardNavigation } from "components/WizardNavigation";
import React, { useState } from "react";
import { ErrorTranslationBackends } from "utils/hooks";
import { object, string } from "yup";

export const LawFirmUserSchema = object({
  name: string().required("Please enter the user's full name."),
  email: string()
    .email("Enter a valid email address.")
    .required("Please enter the user's email address."),
}).required();

export type LawFirmUserFormState = ReturnType<
  typeof LawFirmUserSchema.validateSync
>;

interface FormProps {
  onCreate: (newUser: LawFirmUser) => any;
  onClose: () => any;
}

export default function LawFirmUserForm({ onClose, onCreate }: FormProps) {
  const createLawFirmUser = useCreateLawFirmUser();
  const [errors, setErrors] = useState([]);

  return (
    <Form<LawFirmUserFormState>
      schema={LawFirmUserSchema}
      onSubmit={async (data) => {
        await createLawFirmUser(data)
          .then((result) => {
            onClose();
            onCreate(result.data.createLawFirmUser);
          })
          .catch((error) => {
            const nonFieldErrors = error?.graphQLErrors
              .filter((gqlError: any) => {
                return !!gqlError?.state?.non_field_errors;
              })
              .map((gqlError: any) => {
                return gqlError.state.non_field_errors;
              });
            setErrors(nonFieldErrors);
          });
      }}
      errorTranslator={ErrorTranslationBackends.GraphQL}
    >
      {!!errors.length && (
        <div className={"errors"}>
          {errors.map((text, index) => {
            return (
              <p key={index} className={"errors"}>
                {text}
              </p>
            );
          })}
        </div>
      )}
      <div className="form-row">
        <TextInput name="name" label="Full name" />
      </div>
      <div className="form-row">
        <TextInput name="email" label="Email address" />
      </div>
      <WizardNavigation
        saveText={"Create user"}
        isComplete={true}
        onAbort={onClose}
        abortText={"Never mind"}
      />
    </Form>
  );
}
