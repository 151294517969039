import { useGetLawFirmStats } from "api/graphql/LawFirmStats";
import { StatCard } from "components/StatCard";
import React from "react";
import css from "./styles.module.css";

export function ReferredUsersStats() {
  const { data, loading } = useGetLawFirmStats();
  if (loading) return null;

  return (
    <div className={css.ReferralStats}>
      <StatCard
        title="Total users"
        value={data.lawFirmStats.totalUsersToday}
        previousStats={[
          {
            title: "Last month end",
            value: data.lawFirmStats.totalUsersPastMonth,
          },
          {
            title: "3 months end",
            value: data.lawFirmStats.totalUsersPastThreeMonths,
          },
        ]}
      />
      <StatCard
        title="New this month"
        value={data.lawFirmStats.newUsersCurrentMonth}
        previousStats={[
          {
            title: "Previous month",
            value: data.lawFirmStats.newUsersPastMonth,
          },
          {
            title: "Previous 3 months",
            value: data.lawFirmStats.newUsersPastThreeMonths,
          },
        ]}
      />
      <StatCard
        title="Completed this month"
        value={data.lawFirmStats.completedUsersCurrentMonth}
        previousStats={[
          {
            title: "Previous month",
            value: data.lawFirmStats.completedUsersPastMonth,
          },
          {
            title: "Previous 3 months",
            value: data.lawFirmStats.completedUsersPastThreeMonths,
          },
        ]}
      />
    </div>
  );
}
