import { useQuery } from "@apollo/client";
import { useDeleteVehicleTitle } from "api/graphql/VehicleTitles";
import { ButtonRow } from "components/ButtonRow";
import { ListItem } from "components/ListItem";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import { format, parseISO } from "date-fns";
import React from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import {
  formatErrorsFromSchema,
  formatName,
  formatOxfordComma,
  formatTrimmedFancyNumber,
} from "utils/formatters";
import { useNested } from "utils/hooks";
import VEHICLES_MODULE from "./VehiclesModule.gql";
import { VehicleTitleSchema } from "./VehicleWizard";

interface Props {
  onEdit: (id: string) => any;
  createURL: string;
  nextURL: string;
}

export function ListView({ onEdit, createURL, nextURL }: Props) {
  const { data, loading } = useQuery<VehiclesModule>(VEHICLES_MODULE);
  const [onDelete] = useDeleteVehicleTitle();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { ownedVehicles: items } = data;

  return (
    <>
      <Title>Your Vehicles</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              nextURL={nextURL}
              createURL={createURL}
              filingProfileBoolean="hasNoVehicles"
              addText="Add a vehicle"
              question={
                <p className="required">
                  <strong>Do you own any vehicles?</strong>
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you don’t own any vehicles. If that’s
                  correct, you’re ready to continue to the next section.
                  Otherwise, you may add vehicles below.
                </p>
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Tell us about all the vehicles you own. This includes cars,
                trucks, motorcycles, tractors, etc.
              </p>
              <ButtonRow
                style={{ marginBottom: "8px" }}
                left={
                  <Link to={createURL} className="btn secondary">
                    <FaPlus />
                    <span>Add a vehicle</span>
                  </Link>
                }
                right={
                  <Link to={nextURL} className="btn primary">
                    <span>Done</span>
                    <FaCheck />
                  </Link>
                }
              />
              {items.map((vt) => {
                const loans = data.vehicleLoans.filter(
                  (l) => l.vehicle?.id === vt.id
                );
                return (
                  <ListItem
                    key={vt.id}
                    onEdit={() => onEdit(vt.id)}
                    onDelete={() => onDelete(vt.id)}
                    objectName="vehicle"
                    relatedItems={{
                      loan: loans.map(
                        (l) => `Loan ${l.accountNumber} from ${l.lenderName}`
                      ),
                    }}
                  >
                    <div>
                      {formatErrorsFromSchema(VehicleTitleSchema, vt)}
                      {vt.vehicle.year} {vt.vehicle.make} {vt.vehicle.model}{" "}
                      <br />
                      {vt.purchaseDate && (
                        <div className="supporting-text">
                          Purchased{" "}
                          {format(parseISO(vt.purchaseDate), "MMMM dd, yyyy")}
                        </div>
                      )}
                      {vt.vehicle.mileage && (
                        <div className="supporting-text">
                          {formatTrimmedFancyNumber(vt.vehicle.mileage)} miles
                        </div>
                      )}
                      {vt.currentValue && (
                        <div className="supporting-text">
                          Worth ${vt.currentValue}
                        </div>
                      )}
                      {!!vt.coowners.length && (
                        <div className="supporting-text">
                          Co-owned with{" "}
                          {formatOxfordComma(
                            ...vt.coowners.map((coowner) =>
                              formatName(coowner.person)
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </ListItem>
                );
              })}
            </>
          )}
        />
      </Switch>
    </>
  );
}
