import {
  useGetConsultationPayment,
  useUpsertConsultationPayment,
} from "api/graphql/Consultation";
import { DateInput } from "components/DateInput";
import { Form } from "components/Form";
import { HiddenInput } from "components/HiddenInput";
import { Loading } from "components/Loading";
import { DecimalInput } from "components/NumberInput";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { useHistory } from "react-router";
import { DateSchema, DecimalSchema } from "utils/validators/yup";
import { object } from "yup";

export const ConsultationPaymentSchema = object({
  paymentDate: DateSchema.required("Please enter the payment date."),
  amount: DecimalSchema.required("Please enter the payment amount."),
}).required();

interface ConsultationPaymentFormProps {
  consultationId: UUID;
  paymentId?: UUID;
  nextURL?: string;
  backURL?: string;
  abortURL?: string;
  saveText?: string;
}

export function ConsultationPaymentForm({
  consultationId,
  paymentId,
  nextURL,
  backURL,
  abortURL,
  saveText,
}: ConsultationPaymentFormProps) {
  const { data, loading } = useGetConsultationPayment(paymentId, {
    skip: !paymentId,
  });
  const upsertConsultationPayment = useUpsertConsultationPayment();
  const history = useHistory();

  if (loading) return <Loading />;

  return (
    <Form
      schema={ConsultationPaymentSchema}
      onSubmit={async (data) => {
        await upsertConsultationPayment(consultationId, data as any);
        history.push(nextURL);
      }}
      initialValue={data?.consultationPayment}
    >
      <div className="form-row">
        <HiddenInput name="id" />
        <DateInput name="paymentDate" label="Payment date" />
        <DecimalInput
          name="amount"
          addOnBefore="$"
          label="Payment amount"
          allowDecimal
          includeThousandsSeparator
        />
      </div>
      <WizardNavigation
        backURL={backURL}
        abortURL={abortURL}
        saveText={saveText}
        isComplete
      />
    </Form>
  );
}
