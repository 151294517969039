import { useQuery } from "@apollo/client";
import { useDeleteFinancialInvestment } from "api/graphql/FinancialInvestment";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { Description } from "components/FormModules/Assets/FinancialInvestments/Description";
import FINANCIAL_INVESTMENTS_MODULE from "components/FormModules/Assets/FinancialInvestments/FinancialInvestmentModule.gql";
import { FinancialInvestmentSchema } from "components/FormModules/Assets/FinancialInvestments/FinancialInvestmentWizard";
import { financialInvestmentTypeDetails } from "components/FormModules/Assets/FinancialInvestments/utils";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema, formatName } from "utils/formatters";
import { useNested } from "utils/hooks";

interface Props {
  createURL: string;
  nextURL: string;
  prevURL: string;
  onEdit: (id: string) => any;
}

export default function ListView(props: Props) {
  const { data } = useQuery<FinancialInvestmentModule>(
    FINANCIAL_INVESTMENTS_MODULE
  );
  const [onDelete] = useDeleteFinancialInvestment();
  const { path, url } = useNested();

  if (!data) return <Loading />;
  const { financialInvestments: items } = data;

  return (
    <>
      <Title>Your Financial Investments</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              nextURL={props.nextURL}
              createURL={props.createURL}
              filingProfileBoolean="hasNoFinancialInvestments"
              addText="Add an investment"
              question={
                <Description
                  introText={
                    "Do you or any members of your household have any financial investments?"
                  }
                />
              }
              confirmation={
                <Description
                  introText={
                    "You have indicated that you and all members of your household have no financial investments."
                  }
                  footerText={
                    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add investments below."
                  }
                />
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <Description introText="Tell us about all the financial investments you and other members of your household own." />
              <StatelessList
                {...props}
                emptyText="You haven’t added any financial investments yet."
                addButtonText="Add an investment"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(investment) => (
                  <div>
                    {formatErrorsFromSchema(
                      FinancialInvestmentSchema,
                      investment
                    )}
                    <strong>
                      {
                        financialInvestmentTypeDetails[
                          investment.investmentType
                        ].displayName
                      }
                      : {investment.entityName}
                    </strong>
                    <div className="supporting-text">
                      Current value: ${investment.currentValue}
                    </div>

                    {!!investment.coowners?.length && (
                      <div className="supporting-text">
                        Co-owned with{" "}
                        {investment.coowners
                          .map((coowner) => formatName(coowner.person))
                          .join(", ")}
                      </div>
                    )}
                  </div>
                )}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
