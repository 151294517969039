export const formatAddressToLookupAddress = (
  address: Required<MailingAddresses["mailingAddresses"]>[number]
): RealEstatePropertyLookupInput => {
  return {
    line1: address.line1,
    city: address.city,
    state: address.state.abbr,
    zipCode: address.zipCode,
  };
};
