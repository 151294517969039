import { AddressForm, AddressSchema } from "components/FormSections/Address";
import PrivacyInput from "components/PrivacyInput";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { object, string } from "yup";

export const LenderSchema = object({
  lenderName: string().required("Please enter the name of the lending entity."),
  accountNumber: string().required(
    "Please enter the account number for this loan."
  ),
  billingAddress: AddressSchema.required(),
}).required();

export default function Lender({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <TextInput
          name="lenderName"
          label="Lender name"
          width="200px"
          autoFocus={!isEdit}
        />
        {/* @ts-ignore Switch out text-mask to something up to date */}
        <PrivacyInput
          name="accountNumber"
          label="Loan or account number"
          width="200px"
        />
      </div>

      <p>
        What is this lender&rsquo;s mailing address? This will allow us to
        contact the lender, if necessary.
      </p>
      <AddressForm graphQL name="billingAddress" />

      <WizardNavigation {...navProps} />
    </>
  );
}
