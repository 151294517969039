import { useQuery } from "@apollo/client";
import { LearnMore } from "components/LearnMore";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React, { useCallback } from "react";
import { FaUniversity } from "react-icons/fa";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import AddStatementWizard, {
  lastStepURL,
  StudentLoanStatement,
} from "./AddStatementWizard";
import ListView from "./ListView";
import { MyStudentDataUploader } from "./MyStudentDataUploader";
import NSLDSView from "./NSLDSView";
import StatementView from "./StatementView";
import STUDENT_LOANS_MODULE from "./StudentLoansModule.gql";

export type SaveError = any;
export type StudentLoansProps = {
  next?: string;
};

const StudenLoansLearnMore = () => {
  return (
    <LearnMore>
      <h4>Are my student loans dischargeable through bankruptcy?</h4>
      <p>
        It is possible to discharge student loans in bankruptcy, but most loans
        require a special lawsuit called an adversary proceeding to prove that
        repaying those student loans will cause an undue hardship.
      </p>
      <h4>What exactly is undue hardship? </h4>
      <p>
        It can vary from case to case, but many jurisdictions use criteria
        called the Brunner Test to determine if your loan debt is causing you
        undue hardship. The test looks at whether
      </p>
      <ol>
        <li>
          repaying your loans will prevent you and your dependents from having a
          minimal standard of living
        </li>
        <li>
          your financial situation unlikely to improve or change for the rest of
          the repayment period on your loans
        </li>
        <li>you’ve made good faith efforts to repay your loans</li>
      </ol>
      <h4>Can my lawyer help me discharge my student loans?</h4>
      <p>That is a conversation to have with your bankruptcy attorney.</p>
      <h4>
        Can my loans be discharged without filing an adversary proceeding?
      </h4>
      <p>
        There are a few special cases where this may be possible. Collecting
        this information now will make it easier to analyze for these special
        cases.
      </p>
      <h4>How else can I deal with my student loans?</h4>
      <p>Depending on your circumstances, there may be a few options:</p>
      <ul>
        <li>Refinancing / consolidating loans</li>
        <li>Income-driven repayment</li>
        <li>Total and permanent disability discharge</li>
        <li>Closed school discharge</li>
        <li>Borrower defense discharge</li>
      </ul>
    </LearnMore>
  );
};

export function StudentLoans({ next }: StudentLoansProps) {
  const { path, url } = useNested();
  const { data } = useQuery<StudentLoansModule>(STUDENT_LOANS_MODULE);
  const history = useHistory();
  const [newStatement, setNewStatement] = React.useState<
    StudentLoanStatement | undefined
  >(undefined);

  const summaryURL = "/summary";
  const myStudentDataURL = "/my-student-data";
  const addStatementURL = "/add-statement";
  const editStatementURL = "/statement";
  const nsldsIndexURL = "/nslds";

  const onCreateLoan = useCallback(
    (loan: { statementFileHash: string }) => {
      setNewStatement(undefined);
      history.push(
        location.pathname.replace(
          "statement/new",
          `statement/${loan.statementFileHash}`
        )
      );
    },
    [history]
  );

  if (!data) return <Loading />;

  const { studentLoans, filingProfile } = data || {};
  const hasNsldsLoans =
    studentLoans.filter((l) => l.fromNsldsImport).length > 0 &&
    !filingProfile.hasNoNsldsLoans;

  return (
    <Switch>
      <Route
        path={path(`${summaryURL}/query`)}
        render={() => (
          <>
            <Title>Your Student Loans</Title>
            <QuestionView
              filingProfileBoolean="hasNoStudentLoans"
              createURL={url(summaryURL)}
              nextURL={next}
              addText="Add student loans"
              question={
                <>
                  <p>
                    To build a complete and accurate picture of your financial
                    situation your lawyer will need to know about any student
                    loans you may have. <StudenLoansLearnMore />
                  </p>

                  <p className="required">
                    <strong>
                      Do you have any public or private student loans?
                    </strong>
                  </p>

                  <p>
                    Examples of <strong>public student loans</strong> include,
                    but are not limited to:
                  </p>
                  <ul>
                    <li>Stafford loans</li>
                    <li>Perkins loans</li>
                    <li>Direct Plus and Parent Plus loans</li>
                    <li>
                      Any loan issued to you by the Department of Education
                    </li>
                  </ul>

                  <p>
                    Examples of <strong>private student loans</strong> include
                    but are not limited to:
                  </p>
                  <ul>
                    <li>Loans from a bank or other financial institution</li>
                    <li>Loans from your school</li>
                    <li>
                      All other student loans not issued by the Federal
                      Government
                    </li>
                  </ul>
                </>
              }
              confirmation={
                <p>
                  You have indicated that you do not have any public or private
                  student loans. If that’s correct, you’re ready to continue to
                  the next section. Otherwise, you may add loans below.
                </p>
              }
            />
          </>
        )}
      />
      <Route path={path(summaryURL)} exact>
        <ListView
          doneURL={next}
          addLoansURL={url(addStatementURL)}
          importLoansURL={url(myStudentDataURL)}
          nsldsIndexURL={url(nsldsIndexURL)}
        />
      </Route>
      <Route
        path={path(myStudentDataURL)}
        render={() => {
          return (
            <MyStudentDataUploader
              previousLoansCount={
                studentLoans.filter((l) => l.fromNsldsImport).length
              }
              prev={hasNsldsLoans ? url(nsldsIndexURL) : url(summaryURL)}
              next={url(nsldsIndexURL)}
            />
          );
        }}
      />

      <Route path={path(addStatementURL)}>
        <AddStatementWizard
          abortURL={url(summaryURL)}
          initialValue={newStatement}
          onSubmit={(formValues) => {
            setNewStatement(formValues);
            history.push(url(editStatementURL) + "/new");
            return formValues;
          }}
        />
      </Route>

      <Route
        path={`${path(editStatementURL)}/:statementHash`}
        render={({ match }) => (
          <StatementView
            doneURL={url(summaryURL)}
            prevURL={url(addStatementURL) + lastStepURL}
            statementHash={match.params.statementHash}
            statement={newStatement}
            onCreate={onCreateLoan}
          />
        )}
      />

      <Route path={path(nsldsIndexURL)}>
        <NSLDSView
          reImportURL={url(myStudentDataURL)}
          backURL={url(summaryURL)}
        />
      </Route>
      {!studentLoans.length && <Redirect to={url(`${summaryURL}/query`)} />}
      <Route key="catchall">
        <Redirect to={url(summaryURL)} />
      </Route>
    </Switch>
  );
}

StudentLoans.Icon = FaUniversity;
