import * as React from "react";

/**
 * Hook for sequencing state changes and effects.
 *
 * Currently, there's way to be notified after `setState` or `dispatch` events
 * have completed (a la, `this.setState`'s callback argument in class-based components),
 * see:
 *
 * - https://github.com/facebook/react/issues/14174
 * - https://github.com/facebook/react/issues/15344
 * - https://github.com/facebook/react/issues/15240
 *
 * This hook essentially allows you to schedule a callback to be run *after* the state updates
 * have been committed, rather than in the current render cycle, where the changes aren't reflected
 * yet.
 *
 * I.e. you can't do:
 *
 * dispatch({ type: 'updatez', value: 'foo' });
 * cb(state.updatedValue); // state.updatedValue is the unchanged value here :(
 *
 * Rather:
 *
 * doCb = useUpdateCallback(() => cb(state.updatedValue));
 *
 * dispatch({ type: 'updatez', value: 'foo' });
 * doCb(); // cb will have access to the updated state value when it runs
 *
 * This sucks, since it's brittle (i.e. is the state change you're seeing
 * the state change you actually wanted? or is the scheduler doing something
 * tricky), and also destroys the semantics of function calls (GROSS).
 *
 * If possible, you should try to restructure your code to not wait for
 * state changes. But, sometimes it's unavlidable, and until the React team figures
 * out what the RightWayTM is, this is a stop-gap that that works for simple cases.
 */
export function useUpdateCallback<Args extends [], ReturnValue>(
  callback: (...a: Args) => ReturnValue
) {
  const runCallback = React.useRef(false);
  const callbackArgs = React.useRef<Args | null>(null);
  const returnValue = React.useRef<ReturnValue | null>(null);

  React.useEffect(() => {
    if (runCallback.current) {
      if (!callbackArgs.current) return;
      const args = callbackArgs.current;
      runCallback.current = false;
      callbackArgs.current = null;
      returnValue.current = callback(...args);
    }
  });

  const trigger = React.useCallback((...args: Args) => {
    callbackArgs.current = args;
    runCallback.current = true;
    return new Promise<ReturnValue>((res) => {
      const runCheck = () =>
        setTimeout(() => {
          if (runCallback.current) runCheck();
          res(returnValue.current);
          returnValue.current = null;
        }, 0);
      runCheck();
    });
  }, []);

  return trigger;
}
