import { Caption, Container } from "components/Big";
import * as React from "react";
import { formatNumberForDisplay } from "utils/formatters";
import css from "./styles.module.css";

export interface BigNumberProps {
  caption?: React.ReactNode;
  children?: string | number;
  format?: (value: string | number) => string;
}

export function BigNumber({
  caption,
  children,
  format = formatNumberForDisplay,
}: BigNumberProps) {
  return (
    <Container>
      <div className={css.number}>{format(children)}</div>
      {!!caption && <Caption>{caption}</Caption>}
    </Container>
  );
}
