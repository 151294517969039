import { AddressForm, AddressSchema } from "components/FormSections/Address";
import PrivacyInput from "components/PrivacyInput";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { object, string } from "yup";

export const LenderSchema = object({
  providerName: string().required(
    "Please enter the name of the medical provider."
  ),
  accountNumber: string().required(
    "Please enter the account number for this debt."
  ),
  billingAddress: AddressSchema.required(
    "Please enter the mailing address for this provider."
  ),
}).required();

export default function Lender({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <TextInput
          name="providerName"
          label="Medical provider name"
          width="200px"
          autoFocus={!isEdit}
        />
        {/* @ts-ignore Switch out text-mask to something up to date */}
        <PrivacyInput
          name="accountNumber"
          label="Account number"
          width="200px"
        />
      </div>

      <p>
        What is this medical provider&rsquo;s mailing address? This will allow
        us to contact them, if necessary.
      </p>
      <AddressForm graphQL name="billingAddress" />

      <WizardNavigation {...navProps} />
    </>
  );
}
