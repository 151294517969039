import { useMutation, useQuery } from "@apollo/client";
import cn from "classnames";
import { ButtonRow } from "components/ButtonRow";
import CategoriesList from "components/CategoriesList";
import { FormModuleProps } from "components/FormFlow";
import { FarmAssetSchema } from "components/FormModules/Assets/FarmAssets/FarmAssetForm";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import { omit, sortBy, sumBy } from "lodash";
import React from "react";
import { FaArrowRight, FaPlus } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { breakpoints, useBreakpoint, useNested } from "utils/hooks";
import { parseFancyNumber } from "utils/validators";
import sectionCategories from "./categories";
import { CategorySection } from "./CategorySection";
import FARM_ASSETS_MODULE from "./FarmAssetsModule.gql";
import MARK_NO_FARM_ASSETS from "./MarkNoFarmAssets.gql";

export default function FarmAssets({ next }: FormModuleProps<any>) {
  const isMobile = useBreakpoint(breakpoints.mobile);
  const { path, url } = useNested();
  const { data } = useQuery<FarmAssetsModule, FarmAssetsModuleVariables>(
    FARM_ASSETS_MODULE
  );
  const [markNone, { loading: markingNone }] = useMutation<
    MarkNoFarmAssets,
    MarkNoFarmAssetsVariables
  >(MARK_NO_FARM_ASSETS);

  if (!data) return <Loading />;

  const currencyFormatter = new Intl.NumberFormat("en", {
    style: "currency",
    currency: "USD",
  });

  const profileValues = Object.values(
    omit(data.filingProfile, "__typename", "id")
  );

  const profileAllFalse = profileValues.every((hasNone) => !hasNone);

  const hasNone = profileValues.every((hasNone) => !!hasNone);

  const notStarted = profileAllFalse && data.farmAssets.length === 0;

  return (
    <Switch>
      {sectionCategories.map((categoryDetail) => {
        return (
          <Route
            key={categoryDetail.urlPathName}
            path={path(`${categoryDetail.urlPathName}`)}
            render={() => (
              <CategorySection
                prev={url("/")}
                next={url("/")}
                categoryDetail={categoryDetail}
              />
            )}
          />
        );
      })}

      <Route
        exact
        path={path("/")}
        render={() => (
          <Redirect
            to={notStarted || hasNone ? url("/query") : url("/categories")}
          />
        )}
      />

      <Route
        path={path("/query")}
        render={() => (
          <div>
            <Title>Farming Assets</Title>

            {hasNone ? (
              <>
                <p>
                  You have indicated that you don&rsquo;t own any farming or
                  commercial fishing animals, crops, equipment, or supplies. If
                  that&rsquo;s true, you can move on to the next section.
                  Otherwise, you can add assets below.
                </p>
                <ButtonRow
                  left={
                    <Link to={url("/categories")} className="secondary btn">
                      <FaPlus />
                      <span>Add assets</span>
                    </Link>
                  }
                  right={
                    <Link to={next} className="primary btn">
                      <span>Continue</span>
                      <FaArrowRight />
                    </Link>
                  }
                />
              </>
            ) : (
              <>
                <p className="required">
                  <strong>
                    Do you own any farming or commercial fishing assets? This
                    type of asset includes:
                  </strong>
                </p>
                <ul>
                  <li>Farm animals</li>
                  <li>Marketable crops</li>
                  <li>Farming or commercial fishing equipment & machinery</li>
                  <li>
                    Farming or commercial fishing supplies, chemicals, and feed
                  </li>
                </ul>
                <ButtonRow
                  left={
                    <button
                      className={cn("btn block", { loading: markingNone })}
                      onClick={() => markNone()}
                    >
                      No
                    </button>
                  }
                  right={
                    <Link to={url("/categories")} className="block btn">
                      Yes
                    </Link>
                  }
                />
              </>
            )}
          </div>
        )}
      />

      <Route
        path={path("/categories")}
        render={() => (
          <div>
            <Title>Farming Assets</Title>

            <p>
              When filing for bankruptcy, your lawyer will need to know about
              any assets you own for farming or commercial fishing.{" "}
              {isMobile ? "Tap " : "Click "} each of the following sections to
              tell us about that type of farming asset.
            </p>
            <CategoriesList
              doneURL={next}
              categories={sortBy(sectionCategories, (c) => {
                // Sort incomplete categories to the top
                const items = data.farmAssets.filter(
                  (asset) => asset.category === c.category
                );
                const done =
                  !!items.length ||
                  data.filingProfile[c.hasNoProfileBooleanName];
                return done ? 1 : 0;
              }).map((c) => {
                const items = data.farmAssets.filter(
                  (asset) => asset.category === c.category
                );
                const totalCost = currencyFormatter.format(
                  sumBy(items, (expense) =>
                    Number(parseFancyNumber(expense.currentValue))
                  )
                );

                return {
                  name: c.displayName,
                  href: url(c.urlPathName),
                  Icon: c.menuIcon,
                  hasNone: data.filingProfile[c.hasNoProfileBooleanName],
                  items,
                  itemName: "asset",
                  schema: FarmAssetSchema,
                  itemsSummary: `worth ${totalCost}`,
                };
              })}
            />
          </div>
        )}
      />
    </Switch>
  );
}
