import { useApolloClient, useMutation } from "@apollo/client";
import CREATE_OTHER_PERSON from "api/graphql/OtherPerson/CreateOtherPerson.gql";
import GET_OTHER_PERSON from "api/graphql/OtherPerson/GetOtherPerson.gql";
import { Card } from "components/Card";
import { CoownerForm, OnSubmitDataType } from "components/FormSections/Coowner";
import { Modal } from "components/Modal";
import * as React from "react";
import { Route, useHistory } from "react-router-dom";
import { formatIndefinite } from "utils/formatters";
import { useCheckMounted } from "utils/hooks";
import { unnestIds } from "utils/ids";
import { Details } from "../CoownersBreakdown";
import css from "./styles.module.scss";

export interface AddCoownerModalProps {
  onAddCoowner: ({ id, details }: { id: UUID; details: Details }) => void;
  coownerFilter?: ({ id }: { id: UUID }) => boolean;
  modalPath: string;
  nextURL: string;
  abortURL: string;
  displayName: string;
  relatedTypename: string;
  relatedId: UUID;
}

export function AddCoownerModal({
  onAddCoowner,
  coownerFilter,
  modalPath,
  nextURL,
  abortURL,
  displayName,
  relatedTypename,
  relatedId,
}: AddCoownerModalProps) {
  const history = useHistory();
  const client = useApolloClient();
  const checkMounted = useCheckMounted();

  const [upsertOtherPerson] = useMutation<
    CreateOtherPerson,
    CreateOtherPersonVariables
  >(CREATE_OTHER_PERSON);

  const onSubmit = React.useCallback(
    async (data: OnSubmitDataType) => {
      if ("id" in data) {
        const res = await client.query<GetOtherPerson, GetOtherPersonVariables>(
          {
            query: GET_OTHER_PERSON,
            variables: { id: data.id },
          }
        );
        const person = res?.data?.otherPerson;
        if (!person || !checkMounted()) return;

        onAddCoowner({
          id: person.id,
          details: {
            ...person,
            personType: "OtherPerson",
          },
        });
      } else {
        const res = await upsertOtherPerson({
          variables: {
            input: { ...unnestIds(data) },
          },
        });
        const person = res?.data?.otherPerson;
        if (!person || !checkMounted()) return;

        onAddCoowner({
          id: person.id,
          details: {
            ...person,
            personType: "OtherPerson",
          },
        });
      }
      history.push(nextURL);
    },
    [onAddCoowner, client, upsertOtherPerson, history, checkMounted, nextURL]
  );

  return (
    <Route
      path={modalPath}
      render={React.useCallback(() => {
        return (
          <Modal isOpen>
            <Card className={css["modal-body"]}>
              <h3>Add {formatIndefinite(displayName)} co-owner</h3>
              <CoownerForm
                relatedId={relatedId}
                relatedTypename={relatedTypename}
                onSubmit={onSubmit}
                onAbort={async () => {
                  history.push(abortURL);
                }}
                candidateFilter={coownerFilter}
                saveText={`Add to ${displayName}`}
              />
            </Card>
          </Modal>
        );
      }, [
        coownerFilter,
        onSubmit,
        abortURL,
        history,
        displayName,
        relatedId,
        relatedTypename,
      ])}
    />
  );
}
