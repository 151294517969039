import cn from "classnames";
import { BooleanInput } from "components/BooleanInput";
import { LearnMore } from "components/LearnMore";
import { isEqual } from "lodash";
import * as React from "react";
import { required } from "utils/defaultMessages";
import css from "./YearsAttendedInput.module.css";

interface YearsAttendedInputProps {
  value?: number[];
  onChange?: (newValue: number[]) => any;
  firstYear?: string | number;
  lastYear?: string | number;
}

const toInt = (value: string | number) => {
  if (typeof value === "string") {
    return parseInt(value);
  }
  return value;
};

export function YearsAttendedInput({
  value = [],
  firstYear: unsafeFirst,
  onChange,
  lastYear: unsafeLast,
}: YearsAttendedInputProps) {
  const firstYear = toInt(unsafeFirst);
  const lastYear = toInt(unsafeLast);
  const [additionalYears, setAdditionalYears] = React.useState<number[]>(
    value.slice(1, value.length - 1)
  );
  const [tookOff, setTookOff] = React.useState(
    firstYear && lastYear && value.length
      ? value.length < lastYear - firstYear + 1
      : undefined
  );
  const fullYearList = React.useMemo(
    () =>
      Array.from(
        { length: lastYear - firstYear + 1 },
        (_, idx) => firstYear + idx
      ),
    [firstYear, lastYear]
  );

  // onChange
  React.useEffect(() => {
    let newValue;
    if (firstYear && lastYear && lastYear >= firstYear) {
      if (tookOff === true) {
        newValue = [
          firstYear,
          ...additionalYears.filter((y) => y > firstYear && y < lastYear),
          lastYear,
        ];
      } else if (tookOff === false) {
        newValue = fullYearList;
      }
    }

    if (!isEqual(newValue, value)) {
      onChange?.(newValue);
    }
  }, [
    additionalYears,
    firstYear,
    fullYearList,
    lastYear,
    onChange,
    tookOff,
    value,
  ]);

  const onTookOffChanged = (tookOff: boolean) => {
    setTookOff(tookOff);
  };

  const onYearSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const numberValue = parseInt(value);
    let newYears;
    if (checked) {
      newYears = [...additionalYears, numberValue];
    } else {
      newYears = additionalYears.filter((year) => year !== numberValue);
    }
    setAdditionalYears(newYears);
  };

  const showYearSelect =
    !!firstYear &&
    !!lastYear &&
    tookOff &&
    lastYear >= firstYear &&
    lastYear - firstYear < 20;

  return (
    <>
      <BooleanInput
        label="Did you take any years off?"
        name="tookOff"
        required={required}
        value={tookOff}
        defaultValue={tookOff}
        onChange={onTookOffChanged}
      />

      {showYearSelect && (
        <>
          <p>
            We&rsquo;ve selected the first and last years for you. Please select
            all the other calendar years during which you attended this program.{" "}
            <LearnMore linkText="What do we mean by calendar years attended?">
              <p>
                We mean the actual years that you were in school. So, if you
                took classes in the following terms, you would select 2015,
                2016, 2017, 2019, 2020 and leave 2018 unselected:
                <ul>
                  <li>Fall 2015</li>
                  <li>Spring 2016</li>
                  <li>Fall 2016</li>
                  <li>Fall 2017</li>
                  <li>Spring 2019</li>
                  <li>Spring 2020</li>
                  <li>Fall 2020</li>
                </ul>
              </p>
            </LearnMore>
          </p>
          <div className={css.yearsContainer}>
            {fullYearList.map((year) => {
              const required = year === firstYear || year === lastYear;
              const active = additionalYears.includes(year) || required;
              return (
                <label
                  key={year}
                  className={cn("label", { [css.active]: active })}
                >
                  {!required && (
                    <input
                      value={year}
                      type="checkbox"
                      defaultChecked={active}
                      onChange={onYearSelect}
                    />
                  )}
                  <div
                    className={cn(css.yearToggle, { [css.readonly]: required })}
                  >
                    {year}
                  </div>
                </label>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}
