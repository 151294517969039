import { useEditorBuffer } from "components/EditorState/hooks";
import { Excerpt } from "components/Excerpt";
import {
  Amount,
  Description,
  LineNumber,
  TaxReturnLine,
} from "components/Excerpt/TaxLine";
import { HiddenInput } from "components/HiddenInput";
import { LearnMore } from "components/LearnMore";
import { DecimalInput } from "components/NumberInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { DecimalSchema } from "utils/validators/yup";
import { object } from "yup";

export const IncomeSchema = object({
  totalIncome: DecimalSchema.when("_type", {
    is: "FEDERAL_INCOME",
    then: DecimalSchema.required("Please enter your total income."),
    otherwise: DecimalSchema.nullable(),
  }),
  adjustedGrossIncome: DecimalSchema.when("_type", {
    is: "FEDERAL_INCOME",
    then: DecimalSchema.required("Please enter your adjusted gross income."),
    otherwise: DecimalSchema.nullable(),
  }),
});

export type IncomeFormState = {
  totalIncome?: Decimal;
  adjustedGrossIncome?: Decimal;
};

export default function Income({ navProps }: WizardRouteChildProps) {
  const [{ buffer }] = useEditorBuffer();
  const taxReturnType: TaxReturnTypeChoice = buffer._type;
  const taxReturnYear = parseInt(buffer.returnYear);

  return (
    <>
      <HiddenInput name="_type" value={taxReturnType} />
      <h2>{taxReturnYear} Income</h2>
      {taxReturnType !== "FEDERAL_INCOME" && (
        <>
          <p>
            We don’t need to ask about income for this kind of tax return, you
            can click <strong>next</strong> to continue.
          </p>
          <HiddenInput name="totalIncome" value={null} />
          <HiddenInput name="adjustedGrossIncome" value={null} />
        </>
      )}
      {taxReturnType === "FEDERAL_INCOME" && (
        <div className="form-row">
          <DecimalInput
            label="Total (unadjusted) income"
            name="totalIncome"
            addOnBefore="$"
            allowDecimal
            allowNegative
            includeThousandsSeparator
            rules={{ required: true }}
            learnMore={
              <LearnMore>
                <h4>How do I find my total income?</h4>
                <p>
                  Your total total income is listed as a line on
                  <strong> form 1040</strong> of your tax return. See below for
                  the specific location in a particular year.
                </p>

                <details open={taxReturnYear === 2021}>
                  <summary>2021</summary>
                  <strong>Total income</strong> is listed on
                  <strong> line 9 </strong>
                  of form <strong>1040</strong>
                  <Excerpt>
                    <TaxReturnLine>
                      <LineNumber>9</LineNumber>
                      <Description>
                        Add lines 1, 2b, 3b, 4b, 5b, 6b, 7, and 8. This is your
                        <strong> total income</strong>
                      </Description>
                      <Amount lineNumber="9" />
                    </TaxReturnLine>
                  </Excerpt>
                </details>
                <details open={taxReturnYear === 2020}>
                  <summary>2020</summary>
                  <strong>Total income</strong> is listed on
                  <strong> line 9 </strong>
                  of form <strong>1040</strong>
                  <Excerpt>
                    <TaxReturnLine>
                      <LineNumber>9</LineNumber>
                      <Description>
                        Add lines 1, 2b, 3b, 4b, 5b, 6b, 7, and 8. This is your
                        <strong> total income</strong>
                      </Description>
                      <Amount lineNumber="9" />
                    </TaxReturnLine>
                  </Excerpt>
                </details>
                <details open={taxReturnYear === 2019}>
                  <summary>2019</summary>
                  <strong>Total income</strong> is listed on
                  <strong> line 7b </strong>
                  of form <strong>1040</strong>
                  <Excerpt>
                    <TaxReturnLine>
                      <LineNumber>b</LineNumber>
                      <Description>
                        Add lines 1, 2b, 3b, 4b, 4d, 5b, 6, and 7a. This is
                        your&nbsp;<strong>total income</strong>
                      </Description>
                      <Amount lineNumber="7b" />
                    </TaxReturnLine>
                  </Excerpt>
                </details>
              </LearnMore>
            }
          />
          <DecimalInput
            label="Adjusted gross income"
            name="adjustedGrossIncome"
            addOnBefore="$"
            allowDecimal
            allowNegative
            includeThousandsSeparator
            rules={{ required: true }}
            learnMore={
              <LearnMore>
                <h4>How do I find my adjusted gross income?</h4>
                <p>
                  Your adjusted gross income is listed as a line on
                  <strong> form 1040</strong> of your tax return. See below for
                  the specific location in a particular year.
                </p>
                <details open={taxReturnYear === 2021}>
                  <summary>2021</summary>
                  <strong>Adjusted gross income</strong> is listed on
                  <strong> line 11 </strong>
                  of form <strong>1040</strong>
                  <Excerpt>
                    <TaxReturnLine>
                      <LineNumber>11</LineNumber>
                      <Description>
                        Subtract line 10c from line 9. This is your
                        <strong> adjusted gross income</strong>
                      </Description>
                      <Amount lineNumber="11" />
                    </TaxReturnLine>
                  </Excerpt>
                </details>
                <details open={taxReturnYear === 2020}>
                  <summary>2020</summary>
                  <strong>Adjusted gross income</strong> is listed on
                  <strong> line 11 </strong>
                  of form <strong>1040</strong>
                  <Excerpt>
                    <TaxReturnLine>
                      <LineNumber>11</LineNumber>
                      <Description>
                        Subtract line 10c from line 9. This is your
                        <strong> adjusted gross income</strong>
                      </Description>
                      <Amount lineNumber="11" />
                    </TaxReturnLine>
                  </Excerpt>
                </details>
                <details open={taxReturnYear === 2019}>
                  <summary>2019</summary>
                  <strong>Adjusted gross income</strong> is listed on
                  <strong> line 8b </strong>
                  of form <strong>1040</strong>
                  <Excerpt>
                    <TaxReturnLine>
                      <LineNumber>b</LineNumber>
                      <Description>
                        Subtract line 8a from line 7b. This is your
                        <strong> adjusted gross income</strong>
                      </Description>
                      <Amount lineNumber="8b" />
                    </TaxReturnLine>
                  </Excerpt>
                </details>
              </LearnMore>
            }
          />
        </div>
      )}
      <WizardNavigation {...navProps} />
    </>
  );
}
