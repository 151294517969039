import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_DEBT from "./DeleteMedicalDebt.gql";
import GET_DEBT from "./GetMedicalDebt.gql";
import LIST_DEBT from "./ListMedicalDebt.gql";
import UPSERT_DEBT from "./UpsertMedicalDebt.gql";

const LIST_DEBT_IDS = gql`
  query ListMedicalDebtIds {
    medicalDebts {
      id
    }
  }
`;

export function useListMedicalDebt(
  options?: QueryHookOptions<ListMedicalDebt>
) {
  return useQuery<ListMedicalDebt>(LIST_DEBT, options);
}

export function useGetMedicalDebt(id: string) {
  return useQuery<GetMedicalDebt, GetMedicalDebtVariables>(GET_DEBT, {
    variables: { id },
  });
}

export function useUpsertMedicalDebt<
  Schema extends { medicalDebt: any } = UpsertMedicalDebt,
  Variables = UpsertMedicalDebtVariables
>(query = UPSERT_DEBT) {
  return useUpsert<Schema, Variables, ListMedicalDebtIds, MedicalDebtInput>(
    query,
    LIST_DEBT_IDS,
    "medicalDebt",
    "medicalDebts",
    (cache) => updateFilingProfileCache(cache, "hasNoMedicalDebts", false)
  );
}

export function useDeleteMedicalDebt() {
  return useDelete<DeleteMedicalDebt, ListMedicalDebtIds>(
    DELETE_DEBT,
    LIST_DEBT_IDS,
    "deleteMedicalDebt",
    "medicalDebts"
  );
}
