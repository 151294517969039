import { useQuery } from "@apollo/client";
import { useDeleteAssetLoss } from "api/graphql/AssetLoss";
import { ButtonRow } from "components/ButtonRow";
import { ListItem } from "components/ListItem";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import { format, parseISO } from "date-fns";
import React from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema } from "utils/formatters";
import { useNested } from "utils/hooks";
import SEIZURES_MODULE from "./AssetLossesModule.gql";
import { AssetLossSchema } from "./AssetLossWizard";

interface Props {
  onEdit: (id: string) => any;
  createURL: string;
  nextURL: string;
}

export function ListView({ onEdit, createURL, nextURL }: Props) {
  const { data, loading } = useQuery<AssetLossesModule>(SEIZURES_MODULE);
  const [onDelete] = useDeleteAssetLoss();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { assetLosses: items } = data;

  return (
    <>
      <Title>Your Lost Assets</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              nextURL={nextURL}
              createURL={createURL}
              filingProfileBoolean="hasNoAssetLosses"
              addText="Add a lost asset"
              question={
                <>
                  <strong>
                    <p className="required">
                      Have you lost any assets you owned in the last 12 months?
                    </p>
                  </strong>
                  <p>Types of losses include but are not limited to:</p>
                  <ul>
                    <li>Fires</li>
                    <li>Floods</li>
                    <li>Thefts</li>
                    <li>Gambling</li>
                  </ul>
                </>
              }
              confirmation={
                <p>
                  You have indicated that you haven’t lost any assets in the
                  last 12 months. If that’s correct, you’re ready to continue to
                  the next section. Otherwise, you may add seized assets below.
                </p>
              }
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Tell us about all the assets you have lost in the last 12
                months. Types of losses include but are not limited to:
              </p>
              <ul>
                <li>fires</li>
                <li>floods</li>
                <li>thefts</li>
                <li>gambling</li>
              </ul>
              <ButtonRow
                style={{ marginBottom: "8px" }}
                left={
                  <Link to={createURL} className="btn secondary">
                    <FaPlus />
                    <span>Add a lost asset</span>
                  </Link>
                }
                right={
                  <Link to={nextURL} className="btn primary">
                    <span>Done</span>
                    <FaCheck />
                  </Link>
                }
              />
              {items.map((loss) => {
                return (
                  <ListItem
                    key={loss.id}
                    onEdit={() => onEdit(loss.id)}
                    onDelete={() => onDelete(loss.id)}
                    objectName="lossLoss"
                  >
                    <div>
                      {formatErrorsFromSchema(AssetLossSchema, loss)}
                      {loss.assetDescription}{" "}
                      {loss.lossDate &&
                        `lost on ${format(
                          parseISO(loss.lossDate),
                          "MM/dd/yyyy"
                        )} `}
                      {loss.lossDescription && ` by ${loss.lossDescription}`}
                    </div>
                  </ListItem>
                );
              })}
            </>
          )}
        />
      </Switch>
    </>
  );
}
