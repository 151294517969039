import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import VEHICLE_LEASES_MODULE from "./VehicleLeasesModule.gql";

export default function VehicleLeasesSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<VehicleLeasesModule>(VEHICLE_LEASES_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.vehicleLeases}>
      {data?.filingProfile?.hasNoVehicleLeases ? (
        <HasNoSummary>You don&rsquo;t have any vehicle leases.</HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="lease"
            maxValueLength={2}
            value={data?.vehicleLeases?.length}
            pluralizeLabel
          />
          <NumericSummary
            label="total balance"
            valuePrefix="$"
            maxValueLength={5}
            value={fancyNumberSum(data?.vehicleLeases, "payoffAmount")}
          />
          <NumericSummary
            label="monthly payments"
            valuePrefix="$"
            maxValueLength={4}
            value={fancyNumberSum(data?.vehicleLeases, "monthlyPayment")}
          />
          <NumericSummary
            label="overdue"
            valuePrefix="$"
            maxValueLength={4}
            value={fancyNumberSum(data?.vehicleLeases, "delinquentAmount")}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
