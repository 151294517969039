import { useListStudentLoan } from "api/graphql/StudentLoan";
import { ButtonRow } from "components/ButtonRow";
import { Card } from "components/Card";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import React from "react";
import { FaCheck, FaDownload } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import LoanListItem from "../LoanListItem";

interface Props {
  backURL: string;
  reImportURL?: string;
  onEdit: (id: string) => void;
}

export default function ListView({ backURL, reImportURL, onEdit }: Props) {
  const { data, loading } = useListStudentLoan();

  if (loading) return <Loading />;
  const { studentLoans } = data;
  const nsldsLoans = studentLoans.filter((l) => l.fromNsldsImport);

  if (nsldsLoans.length === 0) return <Redirect to={backURL} />;

  return (
    <div>
      <Title>Your Imported Public Loans</Title>

      <p>
        These are the loans we imported from your My Student Data file. Please
        check the loans below for accuracy or to add cosigners.
      </p>

      <ButtonRow
        left={
          reImportURL && (
            <Link to={reImportURL} className="btn">
              <FaDownload />
              <span>Re-import</span>
            </Link>
          )
        }
        right={
          <Link to={backURL} className="primary btn">
            <span>Done</span>
            <FaCheck />
          </Link>
        }
      />

      <Card>
        {nsldsLoans.map((l) => (
          <LoanListItem key={l.id} loan={l} onEdit={() => onEdit(l.id)} />
        ))}
      </Card>
    </div>
  );
}
