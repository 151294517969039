import { AddressForm, AddressSchema } from "components/FormSections/Address";
import { Select } from "components/Select";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import { ConsultationTypeChoice } from "globalTypes";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { formatConsultationType } from "utils/formatters";
import { object, string } from "yup";

export const ConsultantSchema = object({
  consultantName: string().required("Please provide a name."),
  consultantAddress: AddressSchema,
  consultantAddressId: string(),
  consultationType: string().oneOf<ConsultationTypeChoice>(
    Object.values(ConsultationTypeChoice),
    "Please select an option from the list."
  ),
}).required();

export function Consultant({ isEdit, navProps }: WizardRouteChildProps) {
  const form = useFormContext();
  const isLegal =
    form.watch("consultationType") ===
    ConsultationTypeChoice.LegalBankruptcyAdvice;
  return (
    <>
      <div className="form-row">
        <Select
          name="consultationType"
          options={Object.values(ConsultationTypeChoice).map((type) => ({
            value: type,
            label: formatConsultationType(type),
          }))}
          label="What kind of consultation is this?"
          autoFocus={!isEdit}
          rules={{ required: true }}
        />
        <TextInput
          name="consultantName"
          label={isLegal ? "Name of lawyer or firm" : "Name of consultant"}
          maxLength={128}
          width="200px"
          autoFocus
        />
      </div>
      <label className="label">
        Address of {isLegal ? "lawyer or firm" : "consultant"}
      </label>
      <AddressForm name="consultantAddress" graphQL />
      <WizardNavigation {...navProps} />
    </>
  );
}
