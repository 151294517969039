import { QueryHookOptions, useQuery } from "@apollo/client";
import { useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import GET_CREDIT_COUNSELING from "./GetCreditCounseling.gql";
import LIST_CREDIT_COUNSELING from "./ListCreditCounseling.gql";
import UPSERT_CREDIT_COUNSELING from "./UpsertCreditCounseling.gql";

// Not sure how to handle this correctly when there is no list (OneToOne relation)...
const LIST_CREDIT_COUNSELING_IDS = gql`
  query ListCreditCounselingIds {
    creditCounselings {
      id
    }
  }
`;

export function useListCreditCounseling(
  options?: QueryHookOptions<ListCreditCounseling>
) {
  return useQuery<ListCreditCounseling>(LIST_CREDIT_COUNSELING, options);
}

export function useGetCreditCounseling() {
  return useQuery<GetCreditCounseling>(GET_CREDIT_COUNSELING);
}

export function useUpsertCreditCounseling<
  Schema extends { creditCounseling: any } = UpsertCreditCounseling,
  Variables = UpsertCreditCounselingVariables
>(query = UPSERT_CREDIT_COUNSELING) {
  return useUpsert<
    Schema,
    Variables,
    ListCreditCounselingIds,
    CreditCounselingInput
  >(
    query,
    LIST_CREDIT_COUNSELING_IDS,
    "creditCounseling",
    "creditCounselings",
    (cache, data) => {
      const { id, creditCounseling } = data.creditCounseling;
      cache.modify({
        id: cache.identify({ __typename: "FilingPerson", id }),
        fields: {
          creditCounseling: () => creditCounseling,
        },
      });
    }
  );
}
