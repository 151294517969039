import DollarsInput from "components/DollarsInput";
import FieldWarning from "components/FieldWarning";
import { LearnMore } from "components/LearnMore";
import { MonthInput } from "components/MonthInput";
import { Select } from "components/Select";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import { EmploymentRecordPayFrequencyChoice } from "globalTypes";
import React from "react";
import { parseFancyNumber } from "utils/validators";
import { DateSchema, DecimalSchema } from "utils/validators/yup";
import { object, ref, string } from "yup";

export const HistorySchema = object({
  startMonth: DateSchema.dateNotFuture(
    "Start month must be this month or before."
  ).required("Please enter the month you started working for this company."),
  monthlySalary: DecimalSchema.required(
    "Please enter the amount you make per month."
  ),
  payFrequency: string()
    .oneOf(
      Object.values(EmploymentRecordPayFrequencyChoice),
      "Please select your pay frequency."
    )
    .required(),
  endMonth: DateSchema.dateNotFuture(
    "Ending month of your employment can’t be in the future."
  )
    .dateMin(
      ref("startMonth"),
      "The ending month of employment can’t be before start month."
    )
    .nullable(),
  lastSixMonthsGrossAmount: DecimalSchema.required("This field is required.")
    .matches(/^[0-9,]{0,12}\.?\d{1,2}$/, "Provide a value.")
    .test(
      "min",
      "Must be greater than or equal to zero.",
      (value: string) => value && Number(value.replace(/,/g, "")) >= 0
    )
    .test(
      "max",
      "If this value is accurate, please contact our support team.",
      (value: string) => value && Number(value.replace(/,/g, "")) <= 99999999
    ),
}).required();

export type HistoryFormState = ReturnType<typeof HistorySchema.validateSync>;

export function History({ isEdit, navProps, form }: WizardRouteChildProps) {
  const monthlySalary: string | null = form.watch("monthlySalary");
  return (
    <>
      <div className="form-row">
        <MonthInput name="startMonth" label="Start month" width="200px" />

        <MonthInput
          name="endMonth"
          label="End month"
          width="200px"
          helpText="Leave this blank if you currently work here."
        />
      </div>

      <div className="form-row">
        <DollarsInput
          name="monthlySalary"
          label="Gross monthly salary"
          width="200px"
          min="0"
          helpText={
            monthlySalary &&
            parseFancyNumber(monthlySalary) > 8000 && (
              <FieldWarning>
                This seems a bit high. Be sure to enter your gross salary{" "}
                <em>per month</em>.
              </FieldWarning>
            )
          }
          learnMore={
            <LearnMore>
              <h4>Should I include tips?</h4>
              <p>Yes.</p>

              <h4>What if income from this job changes from month to month?</h4>
              <p>
                Calculate the total income for the previous 12 months and divide
                it by 12. This will give you the average annual monthly income.
              </p>

              <h4>What does gross monthly salary mean?</h4>
              <p>
                Gross monthly salary (or income) is the amount of money you earn
                before anything is taken out for taxes or other deductions (such
                as health insurance, retirement contributions).
              </p>
            </LearnMore>
          }
        />
        <Select
          name="payFrequency"
          label="How often does this job pay wages?"
          width="200px"
          options={Object.entries(EmploymentRecordPayFrequencyChoice).map(
            ([k, v]) => ({
              value: v,
              label: v.replace(/_/g, " ").toLowerCase(),
            })
          )}
        />
      </div>

      <div className="form-row">
        <DollarsInput
          name="lastSixMonthsGrossAmount"
          label="Gross income in last 6 mos."
          min="0"
          learnMore={
            <LearnMore>
              <h4>Should I include tips?</h4>
              <p>Most definitely.</p>

              <h4>What does gross income in the last six months mean?</h4>
              <p>
                Add up the gross income from the last six months for this job.
                Gross income is the total income prior to withholdings for
                taxes, health insurance, retirement savings, or anything
                similar.
              </p>
            </LearnMore>
          }
        />
      </div>
      <WizardNavigation {...navProps} />
    </>
  );
}
