import { useGetConsultation } from "api/graphql/Consultation";
import { Loading } from "components/Loading";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { ConsultationWizard } from "./ConsultationWizard";

export function EditView({
  id,
  listURL,
  ...props
}: {
  id: UUID;
  listURL: string;
}) {
  const { data, loading } = useGetConsultation(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.consultation) {
    return <Redirect to={listURL} />;
  }

  return (
    <ConsultationWizard
      title={"Updating Consultation"}
      backURL={listURL}
      nextURL={listURL}
      initialValue={data.consultation}
    />
  );
}
