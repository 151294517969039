import React, { ReactNode } from "react";
import css from "./ImageHero.module.scss";

interface Props {
  /** Image element, with at least src, alt, width, and height props */
  image: ReactNode;
  /** Copy to be displayed beside the image */
  children: ReactNode;
}

export default function ImageHero({ image, children }: Props) {
  return (
    <div className={css.container}>
      <div className={css.imageWrapper}>{image}</div>
      <div className={css.contentWrapper}>{children}</div>
    </div>
  );
}
