import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaSitemap } from "react-icons/fa";
import OwnedBusinesses from "./OwnedBusinesses";
import OwnedBusinessesSummary from "./Summary";

const BusinessesSubmodule: SubModule = {
  path: "businesses",
  title: "Owned Businesses",
  icon: FaSitemap,
  component: OwnedBusinesses,
  summary: OwnedBusinessesSummary,
  statusName: "ownedBusinesses",
};

export default BusinessesSubmodule;
