import { useUpsertMedicalDebt } from "api/graphql/MedicalDebt";
import { CollectorForm } from "components/FormSections/Collector";
import { CosignerForm } from "components/FormSections/Cosigner";
import { Wizard } from "components/Wizard";
import { CollectorsSchema } from "components/WizardSteps/Collectors";
import { CosignersSchema } from "components/WizardSteps/Cosigners";
import React, { useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import Collectors from "./Collectors";
import Cosigners from "./Cosigners";
import Details, { DetailsSchema } from "./Details";
import Lender, { LenderSchema } from "./Lender";
import MEDICAL_DEBT_WIZARD_MEDICAL_DEBT_UPSERT from "./MedicalDebtWizardMedicalDebtUpsert.gql";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
}

export const MedicalDebtSchema = LenderSchema.concat(DetailsSchema)
  .concat(CosignersSchema)
  .concat(CollectorsSchema);

export function MedicalDebtWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: Props) {
  const { path, url } = useNested();
  const [upsertMedicalDebt] = useUpsertMedicalDebt<
    MedicalDebtWizardMedicalDebtUpsert,
    MedicalDebtWizardMedicalDebtUpsertVariables
  >(MEDICAL_DEBT_WIZARD_MEDICAL_DEBT_UPSERT);
  const history = useHistory();
  const [medicalDebtId, setMedicalDebtId] = useState(initialValue?.id);

  const cosignersURL = "/cosigners";
  const collectorsURL = "/collectors";

  return (
    <Switch>
      <Route
        path={path("/create_cosigner")}
        render={() => (
          <CosignerForm
            title="Add a Medical Debt Cosigner"
            returnURL={url(cosignersURL)}
            relatedTypename="MedicalDebt"
            relatedId={medicalDebtId}
          />
        )}
      />
      <Route
        path={path("/edit_cosigner/:cosignerId")}
        render={({ match }) => (
          <CosignerForm
            title="Updating Medical Debt Cosigner"
            returnURL={url(cosignersURL)}
            relatedId={medicalDebtId}
            relatedTypename="MedicalDebt"
            id={match.params.cosignerId}
          />
        )}
      />

      <Route
        path={path("/create_collector")}
        render={() => (
          <CollectorForm
            title="Add a Medical Debt Collector"
            returnURL={url(collectorsURL)}
            relatedId={medicalDebtId}
            relatedTypename="MedicalDebt"
          />
        )}
      />
      <Route
        path={path("/edit_collector/:collectorId")}
        render={({ match }) => (
          <CollectorForm
            title="Updating Medical Debt Collector"
            returnURL={url(collectorsURL)}
            relatedId={medicalDebtId}
            relatedTypename="MedicalDebt"
            id={match.params.collectorId}
          />
        )}
      />

      <Wizard
        title={title}
        backURL={backURL}
        nextURL={nextURL}
        initialValue={initialValue}
        vertical
        steps={[
          {
            path: "/lender",
            title: "Lender",
            component: Lender,
            schema: LenderSchema,
          },
          {
            path: "/details",
            title: "Debt details",
            component: Details,
            schema: DetailsSchema,
            async onSave(formState) {
              const { data } = await upsertMedicalDebt(formState);
              const { id } = data.medicalDebt;
              setMedicalDebtId(id);
              if (medicalDebtId) {
                history.push(url(cosignersURL));
              } else {
                history.push(`../edit/${id}${cosignersURL}`);
              }
              return data.medicalDebt;
            },
          },
          {
            path: cosignersURL,
            title: "Cosigners",
            component: Cosigners,
            schema: CosignersSchema,
          },
          {
            path: collectorsURL,
            title: "Collectors",
            component: Collectors,
            schema: CollectorsSchema,
          },
        ]}
      />
    </Switch>
  );
}
