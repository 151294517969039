import { useGetGovernmentDisbursement } from "api/graphql/GovernmentDisbursement";
import { GovernmentDisbursementWizard } from "components/FormModules/Income/GovernmentDisbursements/GovernmentDisbursementWizard";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import { DisbursementsCategoryDetail } from "./CategoryDetail";

interface Props {
  id: string;
  listURL: string;
  categoryDetail: DisbursementsCategoryDetail;
}

export default function EditView(props: Props) {
  const { data, loading } = useGetGovernmentDisbursement(props.id);

  if (loading) {
    return <Loading />;
  } else if (!data?.governmentDisbursement) {
    return <Redirect to={props.listURL} />;
  }

  return (
    <GovernmentDisbursementWizard
      category={props.categoryDetail.category}
      title={`Updating ${props.categoryDetail.displayName}`}
      subtitle={props.categoryDetail.singleInterrogativeText}
      backURL={props.listURL}
      nextURL={props.listURL}
      initialValue={data.governmentDisbursement}
    />
  );
}
