import { Endpoint, FileEndpoint } from "./ApiEndpoint";
import { MyStudentDataAPI } from "./MyStudentData";

const BASE_URL = "/api/v1/filing/";

/**
 * Endpoint for managing filing People.
 *
 * A filing person is the person filing the loan forgiveness case.
 * Although there can be multiple People associated with a user account,
 * there is generally only one filing person (the rest would be cofilers).
 */
class FilingPersonAPI extends Endpoint<never, never> {
  constructor() {
    super(`${BASE_URL}person/`);
  }

  myStudentData = new MyStudentDataAPI(
    `${BASE_URL}nslds_my_student_loan/txt_uploads/`
  );

  publicLoanSummaries = new FileEndpoint(
    `${BASE_URL}nslds_loan_summaries/pdf_uploads/`
  );

  privateLoanSummaries = new FileEndpoint(
    `${BASE_URL}private_student_loan_summaries/pdf_uploads/`
  );
}

export default new FilingPersonAPI();
