import { FarmAssetCategory } from "globalTypes";
import React from "react";
import { GiGrain } from "react-icons/gi";
import { CategoryDetail } from "../CategorySection/CategoryDetail";

const FarmSuppliesDetail: CategoryDetail = {
  category: FarmAssetCategory.FarmSupplies,
  displayName: "Farming Supplies",
  displayLowercaseName: "farming supplies",
  hasNoProfileBooleanName: "hasNoFarmSupplies",
  singleInterrogativeText: "Tell us about a single type of farming supplies.",
  noAssetDeclarativeText:
    "You have indicated that you don’t own any farming supplies.",
  booleanInterrogativeText: (
    <p className="required">
      <strong>
        Do you own any farming supplies, such as fertilizer, chemical
        treatments, or feed?
      </strong>
    </p>
  ),
  declarativeText: (
    <p>Please itemize all farming supplies, chemicals, and feed you own.</p>
  ),
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add supplies below.",
  urlPathName: "farm_supplies",
  menuIcon: GiGrain,
};

export default FarmSuppliesDetail;
