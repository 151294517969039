import { Select } from "components/Select";
import { TextInput } from "components/TextInput";
import React from "react";
import { states } from "utils/constants";
import { required } from "utils/defaultMessages";
import { useFormContext } from "react-hook-form";
import AutocompleteAddress from "components/autocomplete/Address";

interface AddressFormProps {
  name?: string;
  autoFocus?: boolean;
  graphQL?: boolean;
}

export default function AddressForm({
  autoFocus = false,
  name = "address",
  graphQL = false,
}: AddressFormProps) {
  const form = useFormContext();

  const line1Name = `${name}.${graphQL ? "line1" : "line_1"}`;
  const line2Name = `${name}.${graphQL ? "line2" : "line_2"}`;
  const cityName = `${name}.city`;
  const stateName = `${name}.state${graphQL ? ".abbr" : ""}`;
  const zipCodeName = `${name}.${graphQL ? "zipCode" : "zip_code"}`;

  return (
    <>
      <div className="form-row">
        <TextInput
          name={line1Name}
          placeholder="Street address"
          label="Line 1"
          width="300px"
          rules={{ required }}
          maxLength={128}
          autoFocus={autoFocus}
          autoComplete="off"
        />

        <TextInput
          name={line2Name}
          placeholder="Suite / Floor / Apt #"
          label="Line 2"
          width="170px"
          maxLength={128}
          autoComplete="address-line2"
        />

        <TextInput
          name={cityName}
          label="City"
          width="200px"
          rules={{ required }}
          maxLength={128}
          autoComplete="address-level2"
        />

        <Select
          name={stateName}
          label="State"
          width="170px"
          rules={{ required }}
          options={states.map(({ name, abbr }) => ({
            value: abbr,
            label: name,
          }))}
          autoComplete="address-level1"
        />

        <TextInput
          name={zipCodeName}
          label="ZIP code"
          width="100px"
          rules={{
            required,
            pattern: {
              value: /\d\d\d\d\d/,
              message: "Please enter a valid 5-digit ZIP code.",
            },
          }}
          inputMode="numeric"
          autoComplete="postal-code"
          maxLength={5}
        />
      </div>

      <AutocompleteAddress
        inputSelector={`[name="${line1Name}"]`}
        onPlaceSelected={(place) => {
          form.setValue(line1Name, place.line1);
          form.setValue(cityName, place.city);
          form.setValue(stateName, place.state);
          form.setValue(zipCodeName, place.zipCode);

          (document.querySelector(`[name="${line2Name}"]`) as
            | HTMLElement
            | undefined)?.focus();
        }}
      />
    </>
  );
}
