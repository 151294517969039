import { Card } from "components/Card";
import Pill from "components/Pill";
import React, { ReactNode } from "react";
import { FaCheck } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { Link } from "react-router-dom";
import {
  formatErrorsFromSchema,
  formatPlural,
  formatQuantity,
} from "utils/formatters";
import { breakpoints, useBreakpoint } from "utils/hooks";
import { array, ObjectSchema } from "yup";
import css from "./CategoriesList.module.scss";

interface Category {
  href: string;
  Icon: IconType;
  name: string;
  items: any[];
  hasNone: boolean;
  itemName: string;
  schema?: ObjectSchema;
  itemsSummary?: ReactNode;
}

interface Props {
  categories: Category[];
  doneURL?: string;
}

export default function CategoriesList({ categories, doneURL }: Props) {
  const isMobile = useBreakpoint(breakpoints.mobile);
  return (
    <Card className={css.categoryTable}>
      {doneURL && (
        <div className={css.footer} style={{ top: isMobile ? "50px" : 0 }}>
          <Link to={doneURL}>
            <a className="block primary btn">
              <span>Done</span>
              <FaCheck />
            </a>
          </Link>
        </div>
      )}

      {categories.map(
        ({
          href,
          Icon,
          name,
          hasNone,
          schema,
          items,
          itemName,
          itemsSummary,
        }) => {
          return (
            <Link key={href} to={href} className={css.categoryType}>
              <div className={css.categoryType__prefix}>
                {schema && formatErrorsFromSchema(array(schema), items)}
              </div>

              <div className={css.categoryType__body}>
                <Icon
                  className={css.categoryType__icon}
                  size="40px"
                  color="var(--primary-light)"
                />

                <div className={css.categoryType__label}>{name}</div>

                <div className={css.categoryType__details}>
                  <div className={css.categoryType__pills}>
                    {hasNone ? (
                      <>
                        <Pill status="success">{`No ${formatPlural(
                          0,
                          itemName
                        )}`}</Pill>
                      </>
                    ) : items.length ? (
                      <>
                        <Pill status="info">
                          {formatQuantity(items.length, ` ${itemName}`)}
                        </Pill>
                        <div className={css.categoryType__itemsDetail}>
                          {itemsSummary}
                        </div>
                      </>
                    ) : (
                      <Pill hollow>Not started</Pill>
                    )}
                  </div>
                </div>
              </div>
            </Link>
          );
        }
      )}
    </Card>
  );
}
