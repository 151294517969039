import * as React from "react";
import { BufferType, MetaType } from "./reducers";

export const Buffer = React.createContext<BufferType<any>>([
  { buffer: {}, committed: {} },
  () => null,
]);
export const Meta = React.createContext<MetaType>([
  { working: false, dirty: false },
  () => null,
]);
export const Save = React.createContext<() => Promise<void> | undefined>(() =>
  Promise.resolve()
);
