import { LearnMore } from "components/LearnMore";
import { DecimalInput } from "components/NumberInput";
import { TextArea } from "components/TextArea";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { DecimalSchema } from "utils/validators/yup";
import { object, string } from "yup";

export const ExpenseSchema = object({
  amount: DecimalSchema.required("This field is required."),
  description: string().required("This field is required."),
}).required();

export type ExpenseFormState = ReturnType<typeof ExpenseSchema.validateSync>;

export default function Expense({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <DecimalInput
        name="amount"
        label="Monthly Amount"
        addOnBefore="$"
        autoFocus={!isEdit}
        includeThousandsSeparator
        allowDecimal
        learnMore={
          <LearnMore>
            <h4>What if this amount changes from month to month?</h4>
            <p>
              In this case, calculate the average amount you spent over the last
              six months. You can do this by adding together the total amount
              spent on this expense type over the last six months, then dividing
              by six.
            </p>
            <h4>
              What if I have a shared bill that covers multiple categories, like
              home phone, TV, and internet?
            </h4>
            <p>
              Do your best to split costs across each section. For instance, if
              you have a bill that has $35 for TV, $20 for your home phone, $30
              for your internet, $4 in home phone fees, and $17 in taxes and
              general fees, you would want to make the home phone section $24,
              the internet section $30, and the TV section $52. Add the general
              fees to the largest section or wherever makes the most sense to
              you.
            </p>
            <h4>What if this expense was a one-time payment?</h4>
            <p>
              Put down the amount paid, and note that it is not a recurring
              expense in the expense descriptions.
            </p>
          </LearnMore>
        }
      />
      <div className="form-row">
        <TextArea
          name="description"
          label="Description"
          placeholder="Tell us about why this amount is a necessary expense."
        />
      </div>
      <WizardNavigation {...navProps} />
    </>
  );
}
