import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import Cookies from "js-cookie";
import cacheConfig from "./cacheConfig";

const link = ApolloLink.from([
  onError(({ graphQLErrors }) => {
    graphQLErrors?.forEach((e) => {
      if (e.message === "Not authenticated") {
        window.location.href = `/core/authentication/login?next=${window.location.pathname}&reason=re-auth`;
      } else {
        console.error(e);
      }
    });
  }),
  createUploadLink({
    uri: "/graphql/",
    headers: { "X-CSRFToken": Cookies.get("csrftoken") },
  }),
]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(cacheConfig),
});
