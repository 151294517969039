import { BigIcon } from "components/Big";
import {
  ProgressIndicator,
  StepHeading,
  StepProps,
} from "components/Onboarding/AboutTheProcess";
import * as React from "react";
import { AiFillDollarCircle, AiOutlineFileSearch } from "react-icons/ai";
import { FaClipboardCheck, FaGavel, FaHandsHelping } from "react-icons/fa";
import { GoCalendar } from "react-icons/go";
import { Milestone, useMilestoneConnector } from "../../../Milestone";

export function WhatsNext({ total, completed, actions, ...props }: StepProps) {
  const [
    assessmentConnector,
    startAssessmentConnector,
  ] = useMilestoneConnector();
  const [
    legalReviewConnector,
    startLegalReviewConnector,
  ] = useMilestoneConnector();

  return (
    <div {...props}>
      <BigIcon>
        <GoCalendar />
      </BigIcon>
      <StepHeading>What’s next for me?</StepHeading>
      <ProgressIndicator
        total={total}
        completed={completed}
        actions={actions}
      />

      <div style={{ marginTop: "50px" }}>
        <Milestone
          icon={<FaClipboardCheck />}
          header={<h3>Gather your data</h3>}
          connector={assessmentConnector}
          forceVisible
        >
          <p>
            We will need to ask you some questions about your family, income,
            education, property, and debts.
          </p>
          <p>
            You should be able to complete the first section telling us more
            about your personal information in less than ten minutes. We
            encourage you to get started now and make progress every day towards
            completing the assessment.
          </p>
        </Milestone>
        <Milestone
          icon={<AiOutlineFileSearch />}
          header={<h3>Legal review</h3>}
          connector={legalReviewConnector}
          onVisible={startAssessmentConnector}
        >
          <p>
            Your completed assessment will be reviewed by your lawyer to ensure
            that it is complete, accurate, and that they are able to help you.
          </p>
        </Milestone>
        <Milestone
          icon={<FaGavel />}
          header={<h3>File bankruptcy case</h3>}
          onVisible={startLegalReviewConnector}
        >
          <p>
            You may need to meet with your lawyer and complete payments after
            this - requirements here vary from firm to firm. The final step is
            for your lawyer to file a new bankruptcy case. Your lawyer will keep
            you up to date on the bankruptcy case&rsquo;s progress.
          </p>
        </Milestone>
      </div>
    </div>
  );
}
