import {
  useDeleteConsultationPayment,
  useGetConsultation,
} from "api/graphql/Consultation";
import { ListViewProps } from "components/EditCreateList/stateless";
import { useEditorBuffer } from "components/EditorState/hooks";
import { ListItem } from "components/ListItem";
import { Loading } from "components/Loading";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

export function ListView(props: ListViewProps) {
  const { createURL, nextURL, onEdit, prevURL } = props;
  const [{ buffer }] = useEditorBuffer();
  const { data } = useGetConsultation(buffer.id);
  const onDelete = useDeleteConsultationPayment();

  if (!data) return <Loading />;
  const items = data.consultation.payments;

  return (
    <>
      <p>
        Tell us about all payments you made to{" "}
        {data.consultation.consultantName}.
      </p>
      {items.map((payment) => {
        return (
          <ListItem
            key={payment.id}
            onEdit={() => onEdit(payment.id)}
            onDelete={() => onDelete(payment.id)}
            objectName="consultation"
          >
            <div>
              ${payment.amount} on {payment.paymentDate}
            </div>
          </ListItem>
        );
      })}
      {!!items.length && (
        <p>
          <strong>Total: ${data.consultation.totalPaid}</strong>
        </p>
      )}
      <Link to={createURL} className="btn secondary">
        <FaPlus />
        <span>Add a payment</span>
      </Link>
    </>
  );
}
