import { FormGroup } from "components/FormGroup";
import { MaskedInput } from "components/MaskedInput";
import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { MaskedInputProps } from "react-text-mask";
import { useFormField } from "utils/hooks";
import { EXCLUDE_FROM_FULLSTORY_CLASSNAME } from "utils/privacy";
import cn from "classnames";
import { Controller, RegisterOptions } from "react-hook-form";
import { mergeRefs } from "utils/mergeRefs";

export interface PrivacyInputProps extends Omit<MaskedInputProps, "onChange"> {
  name?: string;
  label?: string;
  placeholder?: string;
  rules?: RegisterOptions;
  width?: string;
  learnMore?: ReactNode;
  onChange?: (value: string, e: React.ChangeEvent<HTMLInputElement>) => any;
}

export default function PrivacyInput({
  name,
  width,
  label,
  placeholder,
  onChange,
  style,
  learnMore,
  rules,
  ...etc
}: PrivacyInputProps) {
  const [currentValue, setValue] = useState("");
  const [focused, setFocused] = useState(false);
  const liveInput = useRef<HTMLInputElement>();
  const maskID = `${name}-mask`;

  const { form, Label, inputProps, error } = useFormField({
    name,
    rules,
    label,
    register: false,
  });

  const formValue = form ? form.watch(name) : null;
  React.useEffect(() => {
    setValue(formValue || "");
  }, [formValue]);

  const maskActive = !focused;

  if (currentValue && form && form.getValues()[name] === "") {
    setValue("");
  }

  const commonProps = {
    type: "text",
    placeholder,
    style,
  };

  useEffect(() => {
    if (focused) {
      liveInput.current?.focus();
    }
  }, [focused]);

  const onFocus = () => {
    setFocused(true);
    liveInput.current?.focus();
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    const newValue = form ? form.getValues()[name] : e.target.value;
    setValue(newValue || "");
  };

  const liveInputProps = {
    ...etc,
    ...commonProps,
    ...inputProps,
    style: { ...commonProps.style, display: maskActive ? "none" : "block" },
    className: cn(inputProps.className, EXCLUDE_FROM_FULLSTORY_CLASSNAME),
    ref: liveInput,
    onBlur,
    value: currentValue,
    "data-testid": "live-input",
  };

  return (
    <FormGroup
      style={{ width }}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setValue(value);
        onChange?.(value, e);
      }}
      label={<Label htmlFor={maskActive ? maskID : inputProps.id} />}
      error={error}
      learnMore={learnMore}
    >
      {form ? (
        <Controller
          name={name}
          onBlur={onBlur}
          onChange={([e]: [React.FormEvent<HTMLInputElement>]) =>
            (e.target as HTMLInputElement).value
          }
          render={({ onChange, onBlur, value, name, ref }) => (
            <MaskedInput
              {...liveInputProps}
              onChange={onChange}
              onBlur={(e) => {
                onBlur();
                liveInputProps?.onBlur(e);
              }}
              value={value}
              name={name}
              ref={mergeRefs(ref, liveInputProps.ref)}
            />
          )}
          rules={rules}
        />
      ) : (
        <MaskedInput {...liveInputProps} />
      )}

      {maskActive && (
        <input
          {...commonProps}
          id={maskID}
          onFocus={onFocus}
          readOnly
          value={currentValue.replace(/./g, "●")}
          className={cn({ "has-error": error })}
        />
      )}
    </FormGroup>
  );
}
