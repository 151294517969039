import SectionSummary from "components/FormModuleSubMenu/SectionSummary";
import { SectionIndexProps } from "components/FormModuleSubMenu/SubmenuModule";
import React from "react";
import expensesIllustration from "./expenses.svg";
import EXPENSES_INDEX from "./ExpensesIndex.gql";

export default function ExpensesSummary(props: SectionIndexProps) {
  return (
    <SectionSummary<ExpensesIndex>
      {...props}
      query={EXPENSES_INDEX}
      image={
        <img
          src={expensesIllustration}
          alt="A tiny person walking up to a point of sale with a credit card"
          width={406}
          height={306}
        />
      }
      heroCopy={
        <p>
          Your lawyer needs a comprehensive picture of your recurring expenses.
          They will also need to know about any major recent one-time expenses.
        </p>
      }
      completedCopy={
        <p>
          Great work! If all the information below is accurate and complete,
          you&rsquo;re done entering your expenses.
        </p>
      }
    />
  );
}
