import DollarsInput from "components/DollarsInput";
import { TextArea } from "components/TextArea";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";

export default function Miscellaneous({ navProps }: WizardRouteChildProps) {
  return (
    <>
      <p>In the average month, how much does this company spend on:</p>

      <DollarsInput name="monthlyInsurance" label="Insurance" autoFocus />

      <DollarsInput
        name="monthlyDebtPayments"
        label="Pre-petition debt payments to secured creditors"
      />
      <TextArea
        name="businessDebtsDescription"
        label="Describe these debts, if any"
        rules={{ required: true }}
      />

      <DollarsInput
        name="monthlyOtherExpenses"
        label="Business expenses not covered in the other fields"
      />
      <TextArea
        name="otherExpensesDescription"
        label="Specify these other expenses, if any"
      />

      <WizardNavigation {...navProps} />
    </>
  );
}
