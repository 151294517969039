import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_STORAGE_CONTAINER from "./DeleteStorageContainer.gql";
import GET_STORAGE_CONTAINER from "./GetStorageContainer.gql";
import LIST_STORAGE_CONTAINER from "./ListStorageContainer.gql";
import UPSERT_STORAGE_CONTAINER from "./UpsertStorageContainer.gql";

const LIST_STORAGE_CONTAINER_IDS = gql`
  query ListStorageContainerIds {
    storageContainers {
      id
    }
  }
`;

export function useListStorageContainer(
  options?: QueryHookOptions<ListStorageContainer>
) {
  return useQuery<ListStorageContainer>(LIST_STORAGE_CONTAINER, options);
}

export function useGetStorageContainer(id: string) {
  return useQuery<GetStorageContainer, GetStorageContainerVariables>(
    GET_STORAGE_CONTAINER,
    {
      variables: { id },
    }
  );
}

export function useUpsertStorageContainer<
  Schema extends { storageContainer: any } = UpsertStorageContainer,
  Variables = UpsertStorageContainerVariables
>(query = UPSERT_STORAGE_CONTAINER) {
  return useUpsert<
    Schema,
    Variables,
    ListStorageContainerIds,
    StorageContainerInput
  >(
    query,
    LIST_STORAGE_CONTAINER_IDS,
    "storageContainer",
    "storageContainers",
    (cache) => updateFilingProfileCache(cache, "hasNoStorage", false)
  );
}

export function useDeleteStorageContainer() {
  return useDelete<DeleteStorageContainer, ListStorageContainerIds>(
    DELETE_STORAGE_CONTAINER,
    LIST_STORAGE_CONTAINER_IDS,
    "deleteStorageContainer",
    "storageContainers"
  );
}
