import { useQuery } from "@apollo/client";
import { useDeleteSeizure } from "api/graphql/Seizure";
import { ButtonRow } from "components/ButtonRow";
import { SeizureSchema } from "components/FormModules/Legal/Seizures/SeizureWizard";
import { ListItem } from "components/ListItem";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema } from "utils/formatters";
import { useNested } from "utils/hooks";
import SEIZURES_MODULE from "./SeizuresModule.gql";

interface Props {
  onEdit: (id: string) => any;
  createURL: string;
  nextURL: string;
}

export function ListView({ onEdit, createURL, nextURL }: Props) {
  const { data, loading } = useQuery<SeizuresModule>(SEIZURES_MODULE);
  const [onDelete] = useDeleteSeizure();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { seizures: items } = data;

  return (
    <>
      <Title>Your Seized Assets</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoSeizures"
              createURL={createURL}
              nextURL={nextURL}
              addText="Add a seized asset"
              question={
                <>
                  <p className="required">
                    <strong>
                      Have creditors seized (or intend to seize) any assets you
                      own?
                    </strong>
                  </p>
                  <p>Seized assets can include but are not limited to:</p>
                  <ul>
                    <li>Money in a bank account</li>
                    <li>Garnished wages</li>
                    <li>Real estate (including foreclosure and eviction)</li>
                    <li>Vehicles</li>
                    <li>Furniture</li>
                  </ul>
                </>
              }
              confirmation={
                <p>
                  You have indicated that creditors have not seized (and do not
                  intend to seize) any assets you own. If that’s correct, you’re
                  ready to continue to the next section. Otherwise, you may add
                  seized assets below.
                </p>
              }
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Tell us about all assets that have been seized by a creditor or
                are in the process of being seized within the next 90 days.
                Seized assets can include but are not limited to:
              </p>
              <ul>
                <li>Money in a bank account</li>
                <li>Garnished wages</li>
                <li>Real estate (including foreclosure and eviction)</li>
                <li>Vehicles</li>
                <li>Furniture</li>
              </ul>
              <ButtonRow
                style={{ marginBottom: "8px" }}
                left={
                  <Link to={createURL} className="btn secondary">
                    <FaPlus />
                    <span>Add a seized asset</span>
                  </Link>
                }
                right={
                  <Link to={nextURL} className="btn primary">
                    <span>Done</span>
                    <FaCheck />
                  </Link>
                }
              />
              {items.map((asset) => {
                return (
                  <ListItem
                    key={asset.id}
                    onEdit={() => onEdit(asset.id)}
                    onDelete={() => onDelete(asset.id)}
                    objectName="seizure"
                  >
                    <div>
                      {formatErrorsFromSchema(SeizureSchema, asset)}
                      {asset.itemDescription} seized{" "}
                      {asset.itemTakenDate && `on ${asset.itemTakenDate} `}by{" "}
                      {asset.creditorName} worth ${asset.itemValue}
                    </div>
                  </ListItem>
                );
              })}
            </>
          )}
        />
      </Switch>
    </>
  );
}
