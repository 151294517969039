import { useQuery } from "@apollo/client";
import GetOrCreate from "components/GetOrCreate";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import gql from "graphql-tag";
import React from "react";
import { object, string } from "yup";

export const RetirementAccountLoanRetirementAccountSchema = object({
  retirementAccount: object({
    id: string().required("This step is required."),
  }).required(),
}).required();

export const GET_RETIREMENT_ACCOUNT_CANDIDATES = gql`
  query GetRetirementAccountCandidates {
    financialAccounts {
      id
      institutionName
      accountNumber
      accountType
    }
  }
`;

export default function Vehicle({ navProps }: WizardRouteChildProps) {
  const { data, loading } = useQuery<GetRetirementAccountCandidates>(
    GET_RETIREMENT_ACCOUNT_CANDIDATES
  );

  return (
    <>
      <p>Which retirement account is this loan for?</p>
      <GetOrCreate
        name="retirementAccount.id"
        createURL="retirement_account/create"
        objectName="retirement account"
        loading={loading}
        options={data?.financialAccounts
          ?.filter(({ accountType }) => accountType === "RETIREMENT")
          .map((account) => ({
            value: account.id,
            label: `Retirement account with ${account.institutionName} (ending in ${account.accountNumber})`,
          }))}
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
