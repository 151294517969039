import { LearnMore } from "components/LearnMore";
import React from "react";

export default function IncomeLearnMore() {
  return (
    <LearnMore>
      <h4>Why do you need to know about my household income?</h4>
      <p>
        The bankruptcy process includes a means test, which involves examining
        your income over the last six months.
      </p>
    </LearnMore>
  );
}
