import {
  useFilingProfileBoolean,
  useFilingProfileBooleanMutation,
} from "api/graphql/FilingProfile";
import cn from "classnames";
import { ButtonRow } from "components/ButtonRow";
import { Loading } from "components/Loading";
import React, { ReactNode } from "react";
import { FaArrowRight, FaPlus } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";

interface Props {
  createURL: string;
  nextURL: string;
  filingProfileBoolean: MutableProfileField;
  addText: string;
  question: ReactNode;
  confirmation: ReactNode;
}

export default function QuestionView({
  createURL,
  nextURL,
  addText,
  filingProfileBoolean,
  question,
  confirmation,
}: Props) {
  const history = useHistory();

  const { data: hasNone, loading } =
    useFilingProfileBoolean(filingProfileBoolean);

  const [markNone, { loading: markingNone }] =
    useFilingProfileBooleanMutation(filingProfileBoolean);
  const onMarkNone = async () => {
    await markNone(true);
    history.push(nextURL);
  };

  if (loading) return <Loading />;

  return (
    <>
      {hasNone ? confirmation : question}
      <ButtonRow
        style={{ marginBottom: "8px" }}
        left={
          hasNone ? (
            <Link to={createURL} className="secondary btn">
              <FaPlus />
              <span>{addText}</span>
            </Link>
          ) : (
            <button
              onClick={onMarkNone}
              className={cn("btn block", { loading: markingNone })}
            >
              No
            </button>
          )
        }
        right={
          hasNone ? (
            <Link to={nextURL} className="primary btn">
              <span>Continue</span>
              <FaArrowRight />
            </Link>
          ) : (
            <Link to={createURL} className="block primary btn">
              Yes
            </Link>
          )
        }
      />
    </>
  );
}
