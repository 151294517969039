import { BigIcon } from "components/Big";
import {
  ProgressIndicator,
  StepHeading,
  StepProps,
} from "components/Onboarding/AboutTheProcess";
import * as React from "react";
import { FaMoneyBillAlt } from "react-icons/fa";

export function Intro({ total, completed, actions, ...props }: StepProps) {
  return (
    <div {...props}>
      <BigIcon>
        <FaMoneyBillAlt />
      </BigIcon>
      <StepHeading>How bankruptcy works</StepHeading>
      <ProgressIndicator
        total={total}
        completed={completed}
        actions={actions}
      />
      <p>
        Bankruptcy exists to help honest people eliminate their debts and get a
        fresh start in life.
      </p>
      <p>
        Each year, more than half a million people file for bankruptcy,
        discharge their debts, and get their fresh start. By going through
        bankruptcy, these people are able to eliminate almost all of their debts
        and save many of their most valuable possessions.
      </p>
      <p>
        In the next few minutes, we’ll walk you through how the process works
        and how it can help you.
      </p>
    </div>
  );
}
