import { useQuery } from "@apollo/client";
import { CreditReportStatus } from "api/graphql/__generated__";
import { GuardedRoute } from "components/GuardedRoute";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import gql from "graphql-tag";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useNested } from "utils/hooks";
import ErrorView from "./ErrorView";
import IndexView from "./IndexView";
import LoginView from "./LoginView";
import PendingView from "./PendingView";
import SuccessView from "./SuccessView";

export interface Props {
  prev?: string;
  next?: string;
}

const PULL_CREDIT_REPORT_STATUS = gql`
  query PullCreditReportStatus {
    pullCreditReportStatus
  }
`;

export function CreditReports(props: Props) {
  const { path, url } = useNested();

  const { data } = useQuery<PullCreditReportStatus>(PULL_CREDIT_REPORT_STATUS, {
    fetchPolicy: "cache-and-network",
  });

  if (!data) return <Loading />;

  const pullStatus = data.pullCreditReportStatus;
  const { Issued, Pending, Error, Invalid } = CreditReportStatus;

  return (
    <>
      <Title>Credit Report</Title>
      <Switch>
        <GuardedRoute
          guard={pullStatus === Issued}
          path={path("/success")}
          render={() => <SuccessView {...props} />}
          renderFallback={() => <Redirect to={url("/")} />}
        />
        <GuardedRoute
          guard={pullStatus === Pending}
          path={path("/pending")}
          render={() => <PendingView {...props} />}
          renderFallback={() => <Redirect to={url("/")} />}
        />
        <GuardedRoute
          guard={pullStatus === Error}
          path={path("/error")}
          render={() => <ErrorView invalid={false} login={url("/login")} />}
          renderFallback={() => <Redirect to={url("/")} />}
        />
        <GuardedRoute
          guard={pullStatus === Invalid}
          path={path("/invalid")}
          render={() => <ErrorView invalid={true} login={url("/login")} />}
          renderFallback={() => <Redirect to={url("/")} />}
        />
        <GuardedRoute
          guard={pullStatus !== Pending && pullStatus !== Issued}
          path={path("/login")}
          render={() => <LoginView {...props} index={url("/")} />}
          renderFallback={() => <Redirect to={url("/")} />}
        />
        {pullStatus && (
          <Redirect
            to={
              pullStatus === Error
                ? url("/error")
                : pullStatus === Invalid
                ? url("/invalid")
                : pullStatus === Pending
                ? url("/pending")
                : pullStatus === Issued
                ? url("/success")
                : null
            }
          />
        )}
        <Route
          path={path("/")}
          render={() => <IndexView login={url("/login")} />}
        />
      </Switch>
    </>
  );
}
