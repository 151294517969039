import { isEqual } from "lodash";

type FileDescriptor = { file_name: string } | { name: string };

export const checkFilesEqual = (xs: FileDescriptor[], ys: FileDescriptor[]) => {
  const rename = (arr: FileDescriptor[]) =>
    arr
      .map((item) => {
        if ("file_name" in item) {
          return { name: item.file_name };
        } else {
          return { name: item.name };
        }
      })
      .sort();
  return isEqual(rename(xs), rename(ys));
};

/**
 * TODO: write up an api endpoint for this
 */
export const PUBLIC_LOAN_CATEGORIES = [
  { value: "DIRECT_SUBSIDIZED", label: "Direct/FFEL/Stafford Subsidized" },
  { value: "DIRECT_UNSUBSIDIZED", label: "Direct/FFEL/Stafford Unsubsidized" },
  { value: "PERKINS", label: "Perkins" },
  { value: "DIRECT_PLUS", label: "Direct Plus" },
  { value: "PARENT_PLUS", label: "Parent Plus" },
  {
    value: "DIRECT_CONSOLIDATION",
    label: "Direct/FFEL/Stafford Consolidation",
  },
  { value: "INCOME_CONTINGENT", label: "Income Contingent" },
];
