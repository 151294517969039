import cn from "classnames";
import { Card } from "components/Card";
import * as React from "react";
import { Link, LinkProps } from "react-router-dom";
import css from "./LinkCard.module.css";

interface Props extends Omit<LinkProps, "to"> {
  to: string;
}

export function LinkCard({ children, className, to, ...linkProps }: Props) {
  const isExternal = /^(mailto:|https?:\/\/)/.test(to as string);

  return isExternal ? (
    <a {...linkProps} href={to} className={cn(css.link, className)}>
      <Card>{children}</Card>
    </a>
  ) : (
    <Link {...linkProps} to={to} className={cn(css.link, className)}>
      <Card>{children}</Card>
    </Link>
  );
}
