import * as React from "react";
import { formatMoneyForDisplay } from "utils/formatters";
import { BigNumber } from "./BigNumber";
import { BigNumberProps } from "./BigNumber/BigNumber";

export type BigMoneyProps = BigNumberProps;

export function BigMoney({
  format = formatMoneyForDisplay,
  ...props
}: BigMoneyProps) {
  return <BigNumber format={format} {...props} />;
}
