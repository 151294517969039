import { useQuery } from "@apollo/client";
import {
  useUpsertMiscDocument,
  useDeleteMiscDocument,
} from "api/graphql/FilingPerson";
import cn from "classnames";
import { Form } from "components/Form";
import React from "react";
import { useForm } from "react-hook-form";
import { array, object, mixed } from "yup";
import LIST_MISC_DOCUMENTS from "./MiscDocuments.gql";
import { FileList } from "components/FileList";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import { ButtonRow } from "components/ButtonRow";
import { FaCheck } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import fileDownload from "js-file-download";
import { last } from "lodash";

const FormSchema = object({
  miscDocuments: array(mixed<File | UploadedFile>()).min(0),
});

interface FormProps {
  next?: string;
  prev?: string;
}

export function MiscDocuments({ prev, next }: FormProps) {
  const { data, loading } = useQuery<ListMiscDocuments>(LIST_MISC_DOCUMENTS);
  const [upsertMiscDocument] = useUpsertMiscDocument();
  const [deleteMiscDocument] = useDeleteMiscDocument();
  const [documentError, setDocumentError] = React.useState(null);
  const [working, setWorking] = React.useState(false);

  const history = useHistory();

  const form = useForm({
    defaultValues: { miscDocuments: data?.filingPerson?.miscDocuments || [] },
  });

  const miscDocuments = form.watch("miscDocuments", []);

  React.useEffect(() => {
    form.reset({ miscDocuments: data?.filingPerson?.miscDocuments });
  }, [data]);

  async function addDocument(files: File[]) {
    setDocumentError(null);
    setWorking(true);
    for (const file of files) {
      await upsertMiscDocument({ miscDoc: file })
        .then((result) => {
          form.setValue("miscDocuments", [
            ...miscDocuments,
            result.data.upsertMiscDocument,
          ]);
        })
        .catch((error: any) => {
          if (error.message === "Already exists") {
            setDocumentError(`You've already uploaded ${file.name}.`);
          } else {
            setDocumentError(
              `An error occurred uploading ${file.name}.  Please try again.  If this issue persists, please contact
            support@lexria.com.`
            );
          }
        });
    }
    setWorking(false);
  }

  async function removeDocument(fileName: string, index: number) {
    setDocumentError(null);
    setWorking(true);
    const document = miscDocuments[index];
    await deleteMiscDocument({ id: document.id }).then((result) => {
      form.setValue("miscDocuments", [
        ...miscDocuments.slice(0, index),
        ...miscDocuments.slice(index + 1),
      ]);
    });
    setWorking(false);
  }

  async function downloadDocument(uploadedFile: UploadedFile, index: number) {
    const resp = await fetch(uploadedFile.url);
    const blob = await resp.blob();
    const name = last(uploadedFile.name.split("/"));
    fileDownload(blob, name);
  }

  if (loading) return <Loading />;

  return (
    <>
      <Title>Additional Documents</Title>
      <p>
        Use this section to upload any additional documents not required
        elsewhere – typically your attorney will tell you exactly what you need
        to include here.
      </p>
      <Form useForm={form} schema={FormSchema}>
        <FileList
          multiple={false}
          name="miscDocuments"
          files={miscDocuments}
          onDropAccepted={addDocument}
          onDelete={removeDocument}
          onDownload={downloadDocument}
          error={documentError}
        />
      </Form>
      <ButtonRow
        left={
          <button
            className={cn("btn primary", { loading: working })}
            disabled={working}
            onClick={() => {
              history.push(next);
            }}
          >
            <span>Done</span>
            <FaCheck />
          </button>
        }
      />
      <p>
        Wait until your uploaded documents appear before selecting
        &apos;Done&apos; to save and continue.
      </p>
    </>
  );
}
