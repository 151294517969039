import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useDeleteEmploymentRecord } from "api/graphql/EmploymentRecord";
import { ListViewProps } from "components/EditCreateList/stateless";
import { StatelessList } from "components/EditCreateList/StatelessList";
import ApiEmployerEditConfirmationModal from "./EmploymentWizard/ApiEmployerEditConfirmationModal";
import IncomeLearnMore from "components/FormModules/Income/IncomeLearnMore";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import { format, parseISO } from "date-fns";
import { FaExclamationCircle } from "react-icons/fa";
import { Redirect, Route, Switch } from "react-router-dom";
import { getPastDateIntervalFromDuration, TimeUnit } from "utils/dates";
import { useNested } from "utils/hooks";
import css from "../styles.module.css";
import EMPLOYMENT_RECORDS_MODULE from "./EmploymentRecordsModule.gql";

export const dateInterval = getPastDateIntervalFromDuration({
  length: 6,
  unit: TimeUnit.Month,
});

export function renderEmploymentRecord(
  employmentRecord: ArrayItemType<EmploymentRecordsModule["employmentRecords"]>
) {
  const paychecksNeeded = 1;

  return (
    <>
      <div>
        <strong>
          {employmentRecord.jobTitle} at {employmentRecord.companyName}
        </strong>
        {!employmentRecord?.dataProvider ? (
          <>
            <div>
              Started{" "}
              {employmentRecord.startMonth
                ? format(parseISO(employmentRecord.startMonth), "MMMM yyyy")
                : "unknown"}
              {!!employmentRecord.endMonth &&
                `, ended ${format(
                  parseISO(employmentRecord.endMonth),
                  "MMMM yyyy"
                )}`}
            </div>
            {paychecksNeeded &&
              employmentRecord.documents.length < paychecksNeeded && (
                <div className={css.warning}>Paychecks not added</div>
              )}
          </>
        ) : employmentRecord?.dataProvider?.status == "complete" ? (
          <div className="supporting-text">
            You have successfully connected this employer. We have retrieved
            your employment information from your payroll provider.
          </div>
        ) : (
          <div className="supporting-text">
            You have successfully connected this employer. We are working to
            retrieve your employment information from your payroll provider.
          </div>
        )}
      </div>
    </>
  );
}

export default function ListView(props: ListViewProps) {
  const { data, loading } = useQuery<EmploymentRecordsModule>(
    EMPLOYMENT_RECORDS_MODULE
  );
  const [onDelete] = useDeleteEmploymentRecord();
  const { path, url } = useNested();
  const [showApiEmployerModal, setShowApiEmployerModal] = useState(false);
  const [employmentRecord, setEmploymentRecord] =
    useState<ArrayItemType<EmploymentRecordsModule["employmentRecords"]>>();

  if (loading) return <Loading />;
  const { employmentRecords } = data;
  const items = employmentRecords.filter((er) => !er.ownedBusiness);

  const editHandler = (id: string) => {
    const er = items.find((er) => er.id === id);
    setEmploymentRecord(er);
    if (er?.dataProvider) {
      setShowApiEmployerModal(true);
    } else {
      return props.onEdit(id);
    }
  };

  return (
    <>
      <Title>Your Employment Income</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoEmploymentIncome"
              createURL={props.createURL}
              nextURL={props.nextURL}
              addText="Add a job"
              question={
                <>
                  <p>
                    Your lawyer will need a complete picture of all the current
                    and past sources of employment-related income for you and
                    all members of your household over the last seven months.
                    This includes income from a spouse, dependents, and live-in
                    partners.
                  </p>
                  <p>
                    You’ll also need to provide documents verifying each income
                    source. Employment related income includes income from
                    working at a business you don&rsquo;t personally own.{" "}
                    <IncomeLearnMore />
                  </p>
                  <p className="required">
                    <strong>
                      Do you or any members of your household get income from a
                      job at a business you don&rsquo;t personally own?
                    </strong>
                  </p>
                </>
              }
              confirmation={
                <>
                  <p>
                    You have indicated that over the last seven months neither
                    you nor any members of your household have received any
                    income from a job with a business you don&rsquo;t personally
                    own.
                  </p>
                  <p>
                    If that’s correct, you’re ready to continue to the next
                    section. If your household does have income from any of
                    these sources, you may add it below.
                  </p>
                </>
              }
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <ApiEmployerEditConfirmationModal
                isOpen={showApiEmployerModal}
                onClose={() => {
                  setShowApiEmployerModal(false);
                }}
                onContinue={() => {
                  // setShowApiEmployerModal(false);
                  // props.onEdit(employmentRecord?.id);
                }}
                employmentRecord={employmentRecord}
              />

              <p>
                Please continue adding jobs until the list below includes all
                sources of employment income for you and your household
                (including live-in partners) over the last 6 months. Employment
                related income includes income from working at a business you
                don&rsquo;t personally own. <IncomeLearnMore />
              </p>
              <StatelessList
                {...props}
                emptyText="You haven’t added any employment income sources."
                addButtonText="Add a job"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(er) => renderEmploymentRecord(er)}
                onEdit={(id) => editHandler(id)}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
