import { useQuery } from "@apollo/client";
import { useDeleteTaxDebt } from "api/graphql/TaxDebt";
import { ListViewProps } from "components/EditCreateList/stateless";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import { lowerCase } from "lodash";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  formatErrorsFromSchema,
  formatName,
  formatQuantity,
  formatTaxReturnName,
} from "utils/formatters";
import { useNested } from "utils/hooks";
import TAX_DEBTS_MODULE from "./TaxDebtsModule.gql";
import { TaxDebtSchema } from "./TaxDebtWizard";

export default function ListView(props: ListViewProps) {
  const { data, loading } = useQuery<TaxDebtsModule>(TAX_DEBTS_MODULE);
  const [onDelete] = useDeleteTaxDebt();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { taxDebts: items } = data;

  return (
    <>
      <Title>Your Tax Debts</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoTaxDebts"
              createURL={props.createURL}
              nextURL={props.nextURL}
              addText="Add a tax debt"
              question={
                <p className="required">
                  <strong>Do you have any tax debts?</strong>
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you don&rsquo;t have any debts
                  relating to unpaid taxes. If that&rsquo;s correct,
                  you&rsquo;re ready to continue to the next section. Otherwise,
                  you may add tax debts below.
                </p>
              }
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                In order to file your bankruptcy, we need to know about all your
                any debts you may have relating to your taxes, including any you
                have are a co-debtor or cosigner for.
              </p>
              <StatelessList
                {...props}
                emptyText="You haven’t added any tax debts yet."
                addButtonText="Add a tax debt"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(d) => (
                  <div>
                    {formatErrorsFromSchema(TaxDebtSchema, d)}
                    <strong>
                      Tax Debt {d.accountNumber} from {d.entityName} for your{" "}
                      {lowerCase(formatTaxReturnName(d.taxReturn))}
                    </strong>
                    {!!d.cosigners.length && (
                      <div className="supporting-text">
                        Cosigned with {d.cosigners.map(formatName).join(", ")}
                      </div>
                    )}
                    {!!d.collectors.length && (
                      <div className="supporting-text">
                        {formatQuantity(d.collectors.length, " debt collector")}
                      </div>
                    )}
                  </div>
                )}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
