import cn from "classnames";
import * as React from "react";
import css from "./FormGroup.module.css";

interface FormGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  helpText?: React.ReactNode;
  learnMore?: React.ReactNode;
  label?: React.ReactNode;
  children: React.ReactNode;
  error?: React.ReactNode;
}

function FormGroup(
  {
    children,
    label,
    learnMore,
    helpText,
    error,
    className,
    ...etc
  }: FormGroupProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <div {...etc} className={cn(css.formGroup, className)} ref={ref}>
      {(label || learnMore) && (
        <div className="label-row">
          {label}
          {learnMore}
        </div>
      )}

      {children}

      {error && <div className={cn("errors", css.error)}>{error}</div>}
      {helpText && <div className={css.helpText}>{helpText}</div>}
    </div>
  );
}

const forwarded = React.forwardRef(FormGroup);

export { forwarded as FormGroup };
