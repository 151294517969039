import { HiddenInput } from "components/HiddenInput";
import PrivacyInput from "components/PrivacyInput";
import { Select } from "components/Select";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import { LoanCategory, LoanType } from "globalTypes";
import * as React from "react";
import { useQueryParams } from "utils/hooks";
import { object, string } from "yup";
import { StudentLoanStatementSchema } from "../AddStatementWizard";
import { PUBLIC_LOAN_CATEGORIES } from "./utils";

export const LenderSchema = object({
  _type: string()
    .oneOf(Object.values(LoanType), "Please select the type of this loan.")
    .required(),
  lenderName: string().required("Please enter the name of this lender."),
  accountNumber: string().when("_type", {
    is: LoanType.Private,
    then: string().required("Please enter the account number for this loan."),
    otherwise: string().optional().nullable(),
  }),
  category: string()
    .oneOf(
      Object.values(LoanCategory),
      "Please select what category this loan falls into."
    )
    .required(),
})
  .concat(StudentLoanStatementSchema)
  .required();

export function Lender({ form, isEdit, navProps }: WizardRouteChildProps) {
  const { loanType } = useQueryParams();
  const isPublicLoan =
    form.watch("_type") === "PUBLIC" || loanType === "public";

  return (
    <>
      {isPublicLoan ? (
        <HiddenInput name="_type" value="PUBLIC" />
      ) : (
        <>
          <HiddenInput name="_type" value="PRIVATE" />
          <HiddenInput name="category" value="STUDENT" />
        </>
      )}

      <div className="form-row">
        <TextInput
          name="lenderName"
          label="Lender’s name"
          maxLength={255}
          width="200px"
          autoFocus={!isEdit}
          defaultValue={isPublicLoan ? "US Department of Education" : ""}
        />
        {/* @ts-ignore Switch out text-mask to something up to date */}
        <PrivacyInput
          name="accountNumber"
          label="Account number"
          width="150px"
          rules={{ required: !isPublicLoan }}
        />
      </div>

      {isPublicLoan ? (
        <div className="form-row">
          <Select
            name="category"
            options={PUBLIC_LOAN_CATEGORIES}
            label="Loan type"
          />
        </div>
      ) : (
        <input type="hidden" name="category" ref={form.register} />
      )}

      <HiddenInput name="lenderAddress" />
      <HiddenInput name="statementFile" />
      <HiddenInput name="fromNsldsImport" />

      <WizardNavigation {...navProps} />
    </>
  );
}
