import React from "react";
import { MedicalDebtWizard } from "./MedicalDebtWizard";

export default function CreateView({ listURL }: { listURL: string }) {
  return (
    <MedicalDebtWizard
      title="Add Medical Debt"
      backURL={listURL}
      nextURL={listURL}
    />
  );
}
