import * as React from "react";
import {
  FaCalendarAlt,
  FaCopyright,
  FaDoorClosed,
  FaDoorOpen,
  FaHamburger,
  FaHistory,
  FaHourglassHalf,
  FaLandmark,
  FaMoneyBill,
  FaMoneyCheckAlt,
  FaPercentage,
  FaPiggyBank,
} from "react-icons/fa";
import { IconBaseProps } from "react-icons/lib/cjs";
import { FinancialInvestmentTypeChoice } from "globalTypes";

export const investmentTypesRequiringDescription: FinancialInvestmentTypeChoice[] =
  [
    FinancialInvestmentTypeChoice.Annuity,
    FinancialInvestmentTypeChoice.CertificateOfDeposit,
    FinancialInvestmentTypeChoice.LicenseOrFranchise,
    FinancialInvestmentTypeChoice.IntellectualProperty,
    FinancialInvestmentTypeChoice.Royalty,
    FinancialInvestmentTypeChoice.Trust,
    FinancialInvestmentTypeChoice.Other,
  ];

export const investmentTypesRequiringOwnership: FinancialInvestmentTypeChoice[] =
  [FinancialInvestmentTypeChoice.StockPrivate];

type FinancialInvestmentDetail = {
  type: FinancialInvestmentTypeChoice;
  displayName: string;
  icon: React.FC<IconBaseProps>;
  helpText: React.ReactNode;
  descriptionInstructionText?: React.ReactNode;
  postInstructionText?: React.ReactNode;
};

export const financialInvestmentTypeDetails: {
  [key in FinancialInvestmentTypeChoice]: FinancialInvestmentDetail;
} = {
  [FinancialInvestmentTypeChoice.Annuity]: {
    type: FinancialInvestmentTypeChoice.Annuity,
    displayName: "Annuity",
    icon: FaCalendarAlt,
    helpText:
      "An annuity is a contract for a periodic payment to you or a member of your household for life or a defined period.",
    descriptionInstructionText: (
      <strong>
        Tell us about a single annuity you or a household member owns or has a
        legal interest in.
      </strong>
    ),
  },
  [FinancialInvestmentTypeChoice.Bond]: {
    type: FinancialInvestmentTypeChoice.Bond,
    displayName: "Bond",
    icon: FaHistory,
    helpText:
      "A bond is an investment that is repaid at a fixed interest rate.  Governments and corporations issue the most common types of bonds.",
  },
  [FinancialInvestmentTypeChoice.Cash]: {
    type: FinancialInvestmentTypeChoice.Cash,
    displayName: "Cash",
    icon: FaMoneyBill,
    helpText:
      "Cash is money you have in your wallet, in your home, in a safe deposit box, and on hand when you file your petition.",
    descriptionInstructionText: (
      <strong>
        Tell us about all the cash you and members of your household currently
        have on hand.
      </strong>
    ),
  },
  [FinancialInvestmentTypeChoice.CertificateOfDeposit]: {
    type: FinancialInvestmentTypeChoice.CertificateOfDeposit,
    displayName: "Certificate of Deposit",
    icon: FaLandmark,
    helpText:
      "A certificate of deposit (CD) is a time deposit, a financial product commonly sold by banks, that has a fix-term (often one, three, or six months, or one to five years) and usually, a fixed interest rate.",
  },
  [FinancialInvestmentTypeChoice.IntellectualProperty]: {
    type: FinancialInvestmentTypeChoice.IntellectualProperty,
    displayName: "Intellectual property",
    icon: FaCopyright,
    helpText:
      "Intellectual property is a creation that cannot be physically touched.  Examples include copyrights, trademarks, patents, and trade secrets.",
  },
  [FinancialInvestmentTypeChoice.InvestmentFund]: {
    type: FinancialInvestmentTypeChoice.InvestmentFund,
    displayName: "Investment fund",
    icon: FaHistory,
    helpText:
      "An investment fund shares a common pool of resources to purchase assets.  Common investment fund types include but are not limited to mutual funds, bond funds, index funds, and hedge funds.",
  },
  [FinancialInvestmentTypeChoice.LicenseOrFranchise]: {
    type: FinancialInvestmentTypeChoice.LicenseOrFranchise,
    displayName: "Franchise or business license",
    icon: FaHamburger,
    helpText:
      "Franchises and licenses allow you to conduct specific kinds of business.  This can include franchise rights, building permits, liquor licenses, and other license categories.",
  },
  [FinancialInvestmentTypeChoice.Other]: {
    type: FinancialInvestmentTypeChoice.Other,
    displayName: "Other investment",
    icon: FaPiggyBank,
    helpText:
      "Other investments should cover any investment you are unable to classify.",
  },
  [FinancialInvestmentTypeChoice.Royalty]: {
    type: FinancialInvestmentTypeChoice.Royalty,
    displayName: "Royalty",
    icon: FaPercentage,
    helpText:
      "A royalty is a payment made to an asset owner for the right to ongoing use of that asset.",
  },
  [FinancialInvestmentTypeChoice.Prepayment]: {
    type: FinancialInvestmentTypeChoice.Prepayment,
    displayName: "Security deposit or prepayment",
    icon: FaHourglassHalf,
    helpText: (
      <>
        Security deposits and prepayments are the share of unused deposits you
        or your household have made so that you may continue service or use from
        a company. Examples include:
        <ul>
          <li>agreements with landlords, </li>
          <li>prepaid rent, </li>
          <li>public utilities (electric, gas, water), </li>
          <li>telecommunications companies, </li>
          <li>similar arrangements with other companies</li>
        </ul>
      </>
    ),
    descriptionInstructionText: (
      <strong>
        Tell us about a single security deposit or prepayment you or a member of
        your household has made.
      </strong>
    ),
  },
  [FinancialInvestmentTypeChoice.StockPrivate]: {
    type: FinancialInvestmentTypeChoice.StockPrivate,
    displayName: "Private stock",
    icon: FaDoorClosed,
    helpText:
      "Private stock represents owning part of a business that is not publicly traded.",
    descriptionInstructionText: (
      <>
        <strong>
          Tell us about a single private stock you or a household member owns or
          holds interest in.
        </strong>{" "}
        <p>
          If you already added all of your brokerage accounts that hold all of
          your public stock in the &rsquo;Financial Accounts&lsquo; category,
          you do not need to complete this step.
        </p>
      </>
    ),
  },
  [FinancialInvestmentTypeChoice.StockPublic]: {
    type: FinancialInvestmentTypeChoice.StockPublic,
    displayName: "Public stock",
    icon: FaDoorOpen,
    helpText:
      "Public stock represents owning part of a business that is publicly traded.",
    descriptionInstructionText: (
      <>
        <strong>
          Tell us about a single public stock you or a household member owns or
          holds interest in.
        </strong>{" "}
        <p>
          If you already added all of your brokerage accounts that hold all of
          your private stock in the &rsquo;Financial Accounts&lsquo; category,
          you do not need to complete this step.
        </p>
      </>
    ),
  },
  [FinancialInvestmentTypeChoice.Trust]: {
    type: FinancialInvestmentTypeChoice.Trust,
    displayName: "Trust",
    icon: FaLandmark,
    helpText:
      "A trust is an arrangement that allows a third party to hold assets on behalf of you or a member of your household.  Trusts can also encompass future rights to assets, such as property.",
  },
  [FinancialInvestmentTypeChoice.InstrumentNegotiable]: {
    type: FinancialInvestmentTypeChoice.InstrumentNegotiable,
    displayName: "Undeposited payment",
    icon: FaMoneyCheckAlt,
    helpText:
      "An undeposited payment is a commitment from someone to pay you or a household member, such as a check or money order.",
  },
};
