import { Loading } from "components/Loading";
import { useRenderToMobileMenu } from "components/MobileMenu";
import { WelcomeContentProps } from "components/Onboarding/Reopen/Welcome";
import React, { FC } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Link, NavLink, Redirect, Route, Switch } from "react-router-dom";
import { useNested, useQueryParams } from "utils/hooks";
import { Dashboard as DefaultDashboard } from "./Dashboard";
import { DashboardProps } from "./Dashboard/Dashboard";
import s from "./FormFlow.module.scss";

export interface FormModuleProps<OutputSchema> {
  initialValue: OutputSchema;
  next?: string;
  prev?: string;
}

export interface FormModule<OutputSchema>
  extends React.FC<FormModuleProps<OutputSchema>> {
  getInitialValue?: () => Promise<OutputSchema>;
  Icon?: React.FC<{ size?: string; color?: string }>;
}

type ValueGetter<T> = () => T | Promise<T> | undefined | Promise<undefined>;

interface Step<T = any> {
  /** Displayed in navigation link and a header above the step */
  title: string;
  /**
   * The component to render for the step.
   *
   * It will be passed the following props when it's rendered:
   * - `initialValues`: the value returned by getInitialValues
   * - `next`: the path that should be navigated to after completing the step.
   */
  component: FormModule<T>;
  /**
   * A (possibly asynchronous) function that should return the initialValues of the step.
   *
   * By default, the stepper will try to read a `getInitialValue` property on the component. If that is
   * also undefined, it will simply pass `undefined` as the initial value.
   */
  getInitialValue?: ValueGetter<T>;
  /** An object of additional props to pass to the step component. */
  props?: any;
  statusNames?: (keyof Omit<GetStatus["progress"], "__typename">)[];
}

export interface FormFlowProps {
  onSubmit?: () => Promise<void>;
  steps: Step[];
  renderAdditionalRoutes?: () => React.ReactNode;
  Dashboard?: FC<DashboardProps>;
  AboutTheProcess: FC<{ completedURL: string }>;
  Welcome: FC<WelcomeContentProps>;
  showWelcome?: boolean;
}

interface LoaderProps<T> extends FormModuleProps<T> {
  load?: () => Promise<T>;
  Component: (props: FormModuleProps<T>) => JSX.Element;
}

function Loader({ load, Component, ...etc }: LoaderProps<any>) {
  // The data that has been fetched
  const [initialValue, setInitial] = React.useState();
  // Whether data has been fetched at least once
  const [loaded, setLoaded] = React.useState(!load);
  // Whether data is currently being fetched
  const isLoading = React.useRef(false);
  const { refresh, hard } = useQueryParams();

  React.useEffect(() => {
    if (!load) return;
    const performLoad = async () => {
      isLoading.current = true;
      if (hard) {
        setLoaded(false);
      }
      const initial = await load();
      setInitial(initial);
      setLoaded(true);
      isLoading.current = false;
    };

    if (!isLoading.current && (!loaded || refresh)) {
      performLoad();
    }
  }, [load, loaded, refresh]);

  if (!loaded) return <Loading color="var(--primary)" />;
  return <Component initialValue={initialValue} {...etc} />;
}

export function FormFlow({
  steps,
  onSubmit,
  Dashboard = DefaultDashboard,
  AboutTheProcess,
  Welcome,
  showWelcome = false,
  renderAdditionalRoutes = null,
}: FormFlowProps) {
  const { path, url } = useNested();
  const renderToMobileMenu = useRenderToMobileMenu();
  const getStepId = (step: Step) => step.title.toLowerCase().replace(" ", "-");

  return (
    <div className={s.container}>
      {renderToMobileMenu(
        <Route path={path("/section/:any")}>
          <nav className={s.moduleNavigation}>
            <Link to={url("/")} className={s.dashboardLink}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaChevronLeft />
                <span>Dashboard</span>
              </div>
            </Link>

            {steps.map((step) => (
              <NavLink
                to={url(`/section/${getStepId(step)}`)}
                className={s.sectionLink}
                activeClassName={s.active}
                key={getStepId(step)}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {step.component.Icon && <step.component.Icon />}
                  <span>{step.title}</span>
                </div>
                <div id={`${getStepId(step)}-nav-link`} />
              </NavLink>
            ))}
          </nav>
        </Route>
      )}
      <Switch>
        {steps.map((step) => {
          const next = url("/");
          const prev = url("/");
          const load = step.getInitialValue || step.component.getInitialValue;

          return (
            <Route
              path={path(`/section/${getStepId(step)}`)}
              key={getStepId(step)}
            >
              <Loader
                {...step.props}
                Component={step.component}
                load={load}
                next={next}
                prev={prev}
              />
            </Route>
          );
        })}
        <Route
          exact
          path={path("/")}
          render={React.useCallback(() => {
            return (
              <Redirect to={url(showWelcome ? "/welcome" : "/dashboard")} />
            );
          }, [url, showWelcome])}
        />

        <Route
          path={path("/welcome")}
          render={() => (
            <Welcome
              aboutTheProcessURL={url("/about-the-process")}
              completedURL={url("/dashboard")}
            />
          )}
        />

        <Route
          path={path("/about-the-process")}
          render={() => <AboutTheProcess completedURL={url("/dashboard")} />}
        />

        <Route
          exact
          path={path("/dashboard")}
          render={React.useCallback(() => {
            return (
              <Dashboard
                onSubmit={onSubmit}
                modules={steps.map((s) => ({
                  ...s,
                  icon: s.component.Icon,
                  href: url(`/section/${getStepId(s)}`),
                }))}
              />
            );
          }, [onSubmit, url, steps])}
        />
        {renderAdditionalRoutes?.()}
        <Route>
          <Redirect to={url("/")} />
        </Route>
      </Switch>
    </div>
  );
}
