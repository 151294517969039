import { Card } from "components/Card";
import { EmailLink } from "components/ContactUs/ContactTypes";
import { OptionsList } from "components/OptionCard";
import React from "react";
import { Helmet } from "react-helmet";
import css from "../styles.module.css";

export function NewBankruptcyDeclined() {
  return (
    <>
      <Helmet>
        <title>Debt relief through bankruptcy</title>
      </Helmet>

      <div className={css.container}>
        <Card className={css.infoCard}>
          <h3>Welcome back</h3>
          <p>
            Previously, you indicated that you did not want to move forward with
            a new bankruptcy filing that would allow you to discharge your
            debts. If you have changed your mind, please contact us.
          </p>
          <OptionsList>
            <EmailLink />
          </OptionsList>
        </Card>
      </div>
    </>
  );
}
