import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaMoneyCheck } from "react-icons/fa";
import { RetirementAccountLoans } from "./RetirementAccountLoans";
import RetirementAccountLoansSummary from "./Summary";

const RetirementAccountLoansSubmodule: SubModule = {
  path: "retirement_account_loans",
  title: "401(k) Loans",
  icon: FaMoneyCheck,
  component: RetirementAccountLoans,
  summary: RetirementAccountLoansSummary,
  statusName: "retirementAccountLoans",
};

export default RetirementAccountLoansSubmodule;
