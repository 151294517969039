import { RadioGroup } from "components/RadioInput";
import { find, isNull, isUndefined } from "lodash";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useFormField } from "utils/hooks";

export type RadioGroupOption<ValueType> = {
  label: React.ReactNode;
  value: ValueType;
  annotationBefore?: React.ReactNode;
  annotationAfter?: React.ReactNode;
};

export interface RadioGroupInputProps<ValueType = unknown> {
  name: string;
  label?: React.ReactNode;
  required?: boolean | string;
  value?: ValueType;
  onChange?: (newValue?: ValueType | undefined) => void;
  defaultValue?: ValueType;
  helpText?: React.ReactNode;
  autoFocus?: boolean;
  options: RadioGroupOption<ValueType>[];
  learnMore?: React.ReactNode;
}

/** Like a RadioGroup, but you can use any type for the values, not just strings! */
function ControlledRadioGroupInput<ValueType>({
  name,
  value,
  onChange,
  label,
  helpText,
  options,
  autoFocus,
  required = false,
  learnMore,
}: RadioGroupInputProps<ValueType>) {
  const handleChange = React.useCallback(
    (stringValue) => {
      if (!onChange) return;

      if (typeof stringValue === "undefined") {
        onChange(undefined);
      } else {
        const selectedOption = find(
          options,
          ({ value }) => JSON.stringify(value) === stringValue
        );
        onChange(selectedOption.value);
      }
    },
    [onChange, options]
  );

  return (
    <RadioGroup
      name={name}
      label={label}
      onChange={handleChange}
      rules={{ required }}
      helpText={helpText}
      learnMore={learnMore}
    >
      {options.reduce(
        (
          acc,
          { label, value: optionValue, annotationBefore, annotationAfter },
          index
        ) => {
          if (annotationBefore) {
            acc.push(
              <React.Fragment key={`${JSON.stringify(optionValue)}__before`}>
                {annotationBefore}
              </React.Fragment>
            );
          }
          const optionID = `${name}-${index}`;
          acc.push(
            <label
              className="label"
              key={JSON.stringify(optionValue)}
              htmlFor={optionID}
            >
              <input
                id={optionID}
                type="radio"
                name={name}
                value={JSON.stringify(optionValue)}
                defaultChecked={value === optionValue}
                autoFocus={autoFocus && index === 0}
              />
              {label}
            </label>
          );
          if (annotationAfter) {
            acc.push(
              <React.Fragment key={`${JSON.stringify(optionValue)}__after`}>
                {annotationAfter}
              </React.Fragment>
            );
          }
          return acc;
        },
        []
      )}
    </RadioGroup>
  );
}

function RHFRadioGroupInput<ValueType>({
  name,
  required,
  onChange,
  defaultValue,
  options,
  ...inputProps
}: RadioGroupInputProps<ValueType>) {
  const { initialValue } = useFormField({ name });

  return (
    <Controller
      name={name}
      rules={{
        validate: {
          required: (val) => {
            const undefinedOption = find(options, (o) => isUndefined(o.value));
            const nullOption = find(options, (o) => isNull(o.value));
            const isEmpty =
              (isUndefined(val) && !undefinedOption) ||
              (isNull(val) && !nullOption);
            if (typeof required === "string") {
              return !isEmpty || required;
            } else if (required) {
              return !isEmpty || "This field is required.";
            }
          },
        },
      }}
      defaultValue={defaultValue}
      render={({ onChange: rhfOnChange, value }) => (
        <ControlledRadioGroupInput
          {...inputProps}
          name={name}
          required={required}
          defaultValue={initialValue}
          value={value}
          options={options}
          onChange={(value) => {
            onChange?.(value);
            rhfOnChange(value);
          }}
        />
      )}
    />
  );
}

export function RadioGroupInput<T = unknown>(props: RadioGroupInputProps<T>) {
  const Component = useFormContext()
    ? RHFRadioGroupInput
    : ControlledRadioGroupInput;
  return <Component {...props} />;
}
