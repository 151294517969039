import { useQuery } from "@apollo/client";
import { residencyRequirements } from "components/FormModules/Addresses/ResidentialAddress/index";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, { SummaryStatsRow } from "components/ModuleSummary";
import LinesSummary from "components/ModuleSummary/LinesSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import { format, parseISO } from "date-fns";
import { find } from "lodash";
import React, { useMemo } from "react";
import { getDateInterval } from "utils/dates";
import {
  formatDateIntervalDuration,
  formatGraphAddress,
} from "utils/formatters";
import RESIDENTIAL_ADDRESSES_MODULE from "./ResidentialAddressesModule.gql";

export default function ResidentialAddressesSummary(
  props: SubModuleSummaryProps
) {
  const { data } = useQuery<ResidentialAddressesModule>(
    RESIDENTIAL_ADDRESSES_MODULE,
    {
      returnPartialData: true,
    }
  );

  const previousTimePeriod = formatDateIntervalDuration(
    getDateInterval(residencyRequirements.start)
  );

  const addresses = data?.residentialAddresses;

  const currentAddress = useMemo(() => {
    if (!addresses) return undefined;
    return find(addresses, (a) => !a.residentTo) || null;
  }, [addresses]);

  return (
    <ModuleSummary {...props} progress={data?.progress?.residency}>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <LinesSummary
          requiredData={currentAddress}
          maxLines={3}
          maxWidth="20em"
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            flexGrow: 0.5,
          }}
        >
          <strong>{formatGraphAddress(currentAddress?.address)}</strong>
          <div>current address</div>
        </LinesSummary>

        <SummaryStatsRow style={{ flexGrow: 1 }}>
          <NumericSummary
            maxValueLength={10}
            label="moved in"
            value={
              currentAddress &&
              format(parseISO(currentAddress.residentFrom), "MM/dd/yyyy")
            }
          />
          <NumericSummary
            maxValueLength={1}
            label="prior address"
            supportingText={`last ${previousTimePeriod}`}
            pluralizeLabel
            value={
              data?.residentialAddresses
                ? data.residentialAddresses.filter(
                    (a) => a.id !== currentAddress?.id
                  ).length
                : undefined
            }
          />
        </SummaryStatsRow>
      </div>
    </ModuleSummary>
  );
}
