import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaComments } from "react-icons/fa";
import { Consultations } from "./Consultations";
import ConsultationsSummary from "./Summary";

const ConsultationsSubmodule: SubModule = {
  path: "/consultations",
  icon: FaComments,
  title: "Consultations",
  component: Consultations,
  summary: ConsultationsSummary,
  statusName: "consultations",
};

export default ConsultationsSubmodule;
