import * as React from "react";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { NavLink } from "react-router-dom";
import css from "./styles.module.css";

export interface SubMenuItemProps {
  url: string;
  children: React.ReactNode;
}

export function SubMenuItem({ url, children }: SubMenuItemProps) {
  return (
    <NavLink to={url} className={css.link} activeClassName={css.active}>
      {children}
    </NavLink>
  );
}

export interface SubMenuProps {
  containerID?: string;
  containerElement?: HTMLElement;
  children: React.ReactNode;
}

export function SubMenu({
  containerID,
  containerElement,
  children,
}: SubMenuProps) {
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    function updateContainer() {
      const container =
        containerElement || document.getElementById(containerID);
      if (container) {
        setContainer(container);
      } else {
        setTimeout(updateContainer, 300);
      }
    }

    updateContainer();
  }, [containerID, containerElement]);

  if (!container) return null;

  const menu = <div className={css.container}>{children}</div>;

  return createPortal(menu, container);
}
