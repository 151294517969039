type UpdateParams = { [paramName: string]: string | number | undefined };

/**
 * Update query string parameters in a url
 * @param urlToUpdate string of the url to update, can be absolute or relative
 * @param params object of parameter/values to add/update to the url. Set a value to `undefined` to remove from the url
 */
export function updateQueryParams(urlToUpdate: string, params: UpdateParams) {
  let url;
  let isAbsoluteURL = true;

  try {
    url = new URL(urlToUpdate);
  } catch (e) {
    url = new URL(urlToUpdate, window.location.origin);
    isAbsoluteURL = false;
  }

  for (const [paramName, paramValue] of Object.entries(params)) {
    if (paramValue === undefined) {
      url.searchParams.delete(paramName);
    } else {
      url.searchParams.set(paramName, `${paramValue}`);
    }
  }

  if (isAbsoluteURL) {
    return url.toString();
  }

  const constructedURL = url.toString().replace(window.location.origin, "");
  if (urlToUpdate.startsWith("/")) {
    if (constructedURL.startsWith("/")) {
      return constructedURL;
    } else {
      return `/${constructedURL}`;
    }
  } else {
    if (constructedURL.startsWith("/")) {
      return constructedURL.slice(1);
    } else {
      return constructedURL;
    }
  }
}

/**
 * Computes resolved URLs or paths from relative ones
 *
 * Ex: resolveRelative("/foo/bar/baz/../../qux/../bix/") -> "/foo/bix/"
 * @param urlToResolve Relative URL or path to resolve
 * @param rootURL The base url (defaults to "/")
 */
export function resolveRelative(urlToResolve: string, rootURL = "/") {
  const base = new URL(rootURL, window.location.origin);
  const resolved = new URL(urlToResolve, base.href);
  return resolved.href.replace(window.location.origin, "");
}
