import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { OverviewIcon } from "./Icons";

interface OverviewViewProps {
  abortURL: string;
  nextURL: string;
  previousLoansCount?: number;
}

export function OverviewView({
  abortURL,
  nextURL,
  previousLoansCount = 0,
}: OverviewViewProps) {
  return (
    <div>
      <OverviewIcon style={{ marginBottom: "0.5em" }} />
      <p>
        Lexria can record your public loans from the data the Department of
        Education has on file. We’ll walk you through how to download your My
        Student Data file and upload it to your account.
      </p>
      {!!previousLoansCount && (
        <p style={{ marginTop: "1em" }}>
          <strong>
            If you continue, the upload process will replace{" "}
            {previousLoansCount} of your loans previously imported from a My
            Student Data file. Any changes you made to those loans will be lost.
          </strong>
        </p>
      )}
      <WizardNavigation backURL={abortURL} nextURL={nextURL} />
    </div>
  );
}
