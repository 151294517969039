import React from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router";
import { Form } from "components/Form";
import { Title } from "components/Title";
import { TextInput } from "components/TextInput";
import { DateInput } from "components/DateInput";
import { BooleanInput } from "components/BooleanInput";
import { WizardNavigation } from "components/WizardNavigation";
import {
  useGetCreditCounseling,
  useUpsertCreditCounseling,
} from "api/graphql/CreditCounseling";

import { Loading } from "components/Loading";
import { DateSchema } from "utils/validators/yup";
import { object, boolean, string } from "yup";

export interface Props {
  prev: string;
  next: string;
}

const dateCompletedMessage = "Completed date can’t be in the future.";
export const CreditCounselingSchema = object({
  completed: boolean().required(),
  dateCompleted: DateSchema.dateNotFuture(dateCompletedMessage).when(
    "completed",
    {
      is: true,
      then: DateSchema.dateNotFuture(dateCompletedMessage).required(
        "Please enter the date you completed your credit counseling."
      ),
      otherwise: DateSchema.dateNotFuture(dateCompletedMessage).nullable(),
    }
  ),
  certificateNumber: string(),
  providerName: string(),
}).required();
const defaultValues = {
  completed: null,
  dateCompleted: null,
  certificateNumber: null,
  providerName: null,
};

export function CreditCounseling({ prev, next }: Props) {
  const history = useHistory();
  const [upsertCreditCounseling] = useUpsertCreditCounseling();
  const { data, loading } = useGetCreditCounseling();
  if (loading) {
    return <Loading />;
  }

  return (
    <Form
      schema={CreditCounselingSchema}
      onSubmit={async (formState) => {
        const { data } = await upsertCreditCounseling(formState);
        if (next) {
          history.push(next);
        }
      }}
      initialValue={
        data?.creditCounseling ? data.creditCounseling : defaultValues
      }
    >
      <CreditCounselingForm />
      <WizardNavigation backURL={prev} isComplete={true} />
    </Form>
  );
}

function CreditCounselingForm() {
  const { watch } = useFormContext();
  const watchCompleted = watch("completed", false);

  return (
    <>
      <Title>Credit Counseling</Title>
      <BooleanInput
        name="completed"
        required
        label="Have you completed credit counseling?"
      />

      {watchCompleted && (
        <>
          <DateInput
            name="dateCompleted"
            label="Date Completed"
            width="175px"
          />
          <div className="form-row">
            <TextInput
              name="certificateNumber"
              label="Certificate Number"
              maxLength={255}
            />
            <TextInput
              name="providerName"
              label="Provider Name"
              maxLength={255}
            />
          </div>
        </>
      )}
    </>
  );
}
