import { useState } from "react";

import { useQuery } from "@apollo/client";
import ConnectionStatusWidget from "components/ConnectionStatusWidget";
import LOAD_REFERRED_USER_CONNECTIONS from "./LoadReferredUserConnections.gql";
import ConnectionStatusModal from "components/PortalModules/ConnectionStatusModal";

export default function ReferredUserConnectionStatuses({ userId }) {
  const { data, loading } = useQuery(LOAD_REFERRED_USER_CONNECTIONS, {
    variables: {
      id: userId,
      cachePolicy: "cache-and-network",
    },
  });
  const [showConnectionStatusModal, setShowConnectionStatusModal] =
    useState(false);

  return (
    <>
      <div
        className="hover:cursor-pointer"
        onClick={() => {
          setShowConnectionStatusModal(true);
        }}
      >
        <ConnectionStatusWidget
          loading={loading}
          statuses={data?.referredUser?.connectionStatuses}
        />
      </div>
      {!loading && (
        <ConnectionStatusModal
          opened={showConnectionStatusModal}
          onClose={() => {
            setShowConnectionStatusModal(false);
          }}
          connections={data?.referredUser?.connectionStatuses}
        />
      )}
    </>
  );
}
