import React from "react";
import { VehicleLoanWizard } from "./VehicleLoanWizard";

export default function CreateView({ listURL }: { listURL: string }) {
  return (
    <VehicleLoanWizard
      title="Add Vehicle Loan"
      backURL={listURL}
      nextURL={listURL}
    />
  );
}
