import { InMemoryCache, useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import gql from "graphql-tag";
import { useCallback } from "react";
import UPDATE_FILING_PROFILE_BOOLEAN from "./UpdateFilingProfileBoolean.gql";
import UPDATE_ENABLE_CREDIT_REPORT from "./UpdateEnableCreditReport.gql";
import UPDATE_FILING_PROFILE_DATA from "./UpdateFilingProfileData.gql";

type Cache = Pick<InMemoryCache, "modify" | "identify">;

export function updateFilingProfileCache(
  cache: Cache,
  field: MutableProfileField,
  value: boolean
) {
  cache.modify({
    id: cache.identify({ __typename: "FilingProfile" }),
    fields: {
      [field]: () => value,
    },
  });
}

export function useFilingProfileBoolean(field: MutableProfileField) {
  const { data } = useQuery(
    gql`
      query GetFilingProfileBooleanTwo {
        filingProfile {
          ${field}
        }
      }
    `
  );
  return {
    data: data?.filingProfile[field],
    loading: !data,
  };
}

export function useUpdateEnableCreditReportMutation() {
  const [updateEnableCreditReport] = useMutation(UPDATE_ENABLE_CREDIT_REPORT);
  const callback = React.useCallback(
    (variables: UpdateEnableCreditReportVariables) => {
      return updateEnableCreditReport({ variables });
    },
    [updateEnableCreditReport]
  );
  return React.useMemo(() => [callback], [callback]);
}

export function useUpdateFilingProfileDataMutation() {
  const [updateFilingProfileData] = useMutation(UPDATE_FILING_PROFILE_DATA);

  const callback = React.useCallback(
    (variables: UpdateFilingProfileDataVariables) => {
      return updateFilingProfileData({
        variables,
        update(cache, { data }) {
          cache.modify({
            id: cache.identify({ __typename: "FilingProfile" }),
            fields: {
              ...data.updateFilingProfileData,
            },
          });
        },
      });
    },
    [updateFilingProfileData]
  );
  return React.useMemo(() => [callback], [callback]);
}

/** Used to update a single boolean field on the user's FilingProfile */
export function useFilingProfileBooleanMutation(field: MutableProfileField) {
  const [performMutation, etc] = useMutation<
    UpdateFilingProfileBoolean,
    UpdateFilingProfileBooleanVariables
  >(UPDATE_FILING_PROFILE_BOOLEAN);

  const callback = useCallback(
    (value: boolean) =>
      performMutation({
        variables: { field, value },
        update(cache, { data }) {
          updateFilingProfileCache(
            cache,
            field,
            data.updateFilingProfileBoolean
          );
        },
      }),
    [field, performMutation]
  );

  return [callback, etc] as [typeof callback, typeof etc];
}
