import DocumentsUpload from "components/FormSections/DocumentsUpload";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { array, boolean, mixed, object } from "yup";

export const DocumentationSchema = object({
  hasNoSupportingDocumentation: boolean().required(),
  documents: array(mixed<File | string>()).when(
    "hasNoSupportingDocumentation",
    {
      is: true,
      then: array().min(0),
      otherwise: array().min(
        1,
        "You haven’t provided any documentation of this policy."
      ),
    }
  ),
}).required();

export default function Documentation({
  isEdit,
  navProps,
}: WizardRouteChildProps) {
  return (
    <>
      <DocumentsUpload
        relatedTypename={"InsurancePolicy"}
        displayText={
          <p>
            Upload your most recent example of a bill or invoice relating to
            this policy.
          </p>
        }
        hasNoDocumentsLabelText={
          "Do you have any documentation of this policy, such as a recent bill or invoice?"
        }
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
