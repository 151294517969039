import * as React from "react";
import { ListAction, ListItems } from "./reducer";

export const State = React.createContext<
  [ListItems<any>, React.Dispatch<ListAction<any>>]
>(undefined);
export const Root = React.createContext<{
  url: string;
  path: string;
  prevURL: string;
  nextURL: string;
}>(undefined);
