import { StatelessEditCreateList } from "components/EditCreateList";
import { DisbursementsCategoryDetail } from "./CategoryDetail";
import React from "react";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

export interface Props {
  prev: string;
  next: string;
  categoryDetail: DisbursementsCategoryDetail;
}

export function CategorySection({ prev, next, categoryDetail }: Props) {
  return (
    <StatelessEditCreateList
      prev={prev}
      next={next}
      CreateView={React.useCallback(
        (props) => (
          <CreateView {...props} categoryDetail={categoryDetail} />
        ),
        [categoryDetail]
      )}
      ListView={React.useCallback(
        (props) => (
          <ListView {...props} categoryDetail={categoryDetail} />
        ),
        [categoryDetail]
      )}
      EditView={React.useCallback(
        (props) => (
          <EditView {...props} categoryDetail={categoryDetail} />
        ),
        [categoryDetail]
      )}
    />
  );
}
