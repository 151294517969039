import cn from "classnames";
import * as React from "react";
import css from "./styles.module.css";

interface CaptionProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export function Caption({
  children,
  style = {},
  className = "",
}: CaptionProps) {
  return (
    <div className={cn(css.caption, className)} style={style}>
      {children}
    </div>
  );
}
