import { useGetRealEstateDocumentRecord } from "api/graphql/RealEstatePropertyDocumentRecord";
import { useEditorBuffer } from "components/EditorState/hooks";
import { formatAddressToLookupAddress } from "components/FormModules/Assets/RealEstate/utils";
import { Loading } from "components/Loading";
import { WizardRouteChildProps } from "components/Wizard";
import CoownersWizardStep, {
  RegisterOnSaveType,
} from "components/WizardSteps/Coowners";
import React from "react";

export default function Coowners(
  props: WizardRouteChildProps & { registerOnSave: RegisterOnSaveType }
) {
  const [{ buffer }] = useEditorBuffer();
  const propertyId = buffer.id;
  const formattedAddress = formatAddressToLookupAddress(buffer.address);
  const {
    loading: documentationLoadingStatus,
    data: documentationData,
  } = useGetRealEstateDocumentRecord(formattedAddress);
  if (documentationLoadingStatus) return <Loading />;
  const docRec = documentationData?.realEstatePropertyDocumentRecord;

  return (
    <CoownersWizardStep
      relatedTypename="RealEstateProperty"
      relatedId={propertyId}
      displayName="property"
      knownOwners={
        !docRec?.propertyHasNoCoowners ? docRec?.propertyOwnerList : null
      }
      {...props}
    />
  );
}
