import { useGetVehicleLoan } from "api/graphql/VehicleLoan";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import { VehicleLoanWizard } from "./VehicleLoanWizard";

interface Props {
  id: string;
  listURL: string;
}

export default function EditView({ id, listURL }: Props) {
  const { data, loading } = useGetVehicleLoan(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.vehicleLoan) {
    return <Redirect to={listURL} />;
  }

  return (
    <VehicleLoanWizard
      title="Updating Vehicle Loan"
      backURL={listURL}
      nextURL={listURL}
      initialValue={data.vehicleLoan}
    />
  );
}
