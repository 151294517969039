import { useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import ASSIGN_COFILER from "./AssignCofiler.gql";
import GET_COFILER_CANDIDATES from "./GetCofilerCandidates.gql";
import UPSERT_COFILER from "./UpsertCofiler.gql";

export function useAssignCofiler() {
  const [assignCofiler] = useMutation<AssignCofiler, AssignCofilerVariables>(
    ASSIGN_COFILER
  );

  const callback = React.useCallback(
    (variables: AssignCofilerVariables) => {
      return assignCofiler({ variables });
    },
    [assignCofiler]
  );

  return React.useMemo(() => [callback], [callback]);
}

export function useGetCofilerCandidates(
  variables: GetCofilerCandidatesVariables
) {
  return useQuery<GetCofilerCandidates, GetCofilerCandidatesVariables>(
    GET_COFILER_CANDIDATES,
    {
      fetchPolicy: "network-only",
      variables,
    }
  );
}

export function useUpsertCofiler() {
  return useMutation<UpsertCofiler, UpsertCofilerVariables>(UPSERT_COFILER);
}
