import { useQuery } from "@apollo/client";
import { useDeleteOtherIncome } from "api/graphql/OtherIncome";
import cn from "classnames";
import { ListViewProps } from "components/EditCreateList/stateless";
import { StatelessList } from "components/EditCreateList/StatelessList";
import OtherIncomeLearnMore from "components/FormModules/Income/Other/OtherIncomeLearnMore";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import { OtherIncomeCategoryChoice } from "globalTypes";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { formatQuantity } from "utils/formatters";
import { useNested } from "utils/hooks";
import css from "../styles.module.css";
import OTHER_INCOMES_MODULE from "./OtherIncomesModule.gql";
import { getTypeByValue, otherIncomeFrequencies } from "./utils";

function renderOtherIncome(
  income: ArrayItemType<OtherIncomesModule["otherIncomes"]>,
  documentsNeeded = 1
) {
  const { icon: Icon, label } = getTypeByValue(income.category);

  return (
    <>
      <h5 className="cardLabel">
        <Icon size="14px" /> <span>{label}</span>
      </h5>
      <strong>${income.amount}</strong>
      <div>
        {income.category === OtherIncomeCategoryChoice.MoneyOwed
          ? "Owed"
          : "Paid"}{" "}
        {otherIncomeFrequencies[income.frequency].toLowerCase()}
      </div>
      <div
        className={cn({
          [css.warning]: income.documents.length < documentsNeeded,
        })}
      >
        {income.documents.length >= documentsNeeded
          ? formatQuantity(income.documents.length, " document")
          : "Missing documentation"}
      </div>
      {income.description && (
        <div className="supporting-text">{income.description}</div>
      )}
    </>
  );
}

export default function ListView(props: ListViewProps) {
  const { data, loading } = useQuery<OtherIncomesModule>(OTHER_INCOMES_MODULE);
  const [onDelete] = useDeleteOtherIncome();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { otherIncomes: items } = data;

  return (
    <>
      <Title>Your Other Income</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              nextURL={props.nextURL}
              createURL={props.createURL}
              filingProfileBoolean="hasNoOtherIncome"
              addText="Add income"
              question={
                <>
                  <p>
                    Your lawyer will need a complete picture of all the current
                    and past sources of non-employment related income for you
                    and all members of your household over the last six months.
                    This includes income for a spouse, dependents and live-in
                    partners. You’ll also need to provide documents verifying
                    each income source.
                  </p>
                  <h4>Why do you need to know about my household income?</h4>
                  <p>
                    The bankruptcy process includes a means test, which involves
                    examining your income over the last six months.
                  </p>
                  <h4>What is non-employment income?</h4>
                  <p>
                    Non-employment income is money that you make from a source
                    other than your job, a business you own, or self employment.
                    Sources include:
                  </p>
                  <ul>
                    <li>Unemployment</li>
                    <li>Disability</li>
                    <li>Child Support</li>
                    <li>Alimony</li>
                    <li>Food stamps</li>
                    <li>Pension</li>
                    <li>Real Estate</li>
                    <li>Capital Gains</li>
                    <li>Annuities</li>
                    <li>Unpaid wages or money that’s owed to you</li>
                  </ul>

                  <p className="required">
                    <strong>
                      Does your household have any income from sources other
                      than a job, self-employment, or owning a business?
                    </strong>
                  </p>
                </>
              }
              confirmation={
                <>
                  <p>
                    You have indicated that over the last six months neither you
                    nor any members of your household have received any income
                    from sources other than a job, self-employment, or owning a
                    business. <OtherIncomeLearnMore />
                  </p>
                  <p>
                    If that’s correct, you’re ready to continue to the next
                    section. If you do have income from any of these sources,
                    you may add them below.
                  </p>
                </>
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Continue adding non-employment income sources until all of the
                non-employment income for you and your household (including
                live-in partners) from the last six months is shown below.{" "}
              </p>
              <h4>Why do you need to know about my household income?</h4>
              <p>
                The bankruptcy process includes a means test, which involves
                examining your income over the last six months.
              </p>
              <h4>What is non-employment income?</h4>
              <p>
                Non-employment income is money that you make from a source other
                than your job, a business you own, or self employment. Sources
                include:
              </p>
              <ul>
                <li>Unemployment</li>
                <li>Disability</li>
                <li>Child Support</li>
                <li>Alimony</li>
                <li>Food stamps</li>
                <li>Pension</li>
                <li>Real Estate</li>
                <li>Capital Gains</li>
                <li>Annuities</li>
                <li>Unpaid wages or money that’s owed to you</li>
              </ul>
              <StatelessList
                {...props}
                emptyText="You haven’t added any other income sources."
                addButtonText="Add income"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(oi) => renderOtherIncome(oi)}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
