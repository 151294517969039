import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaClipboardList } from "react-icons/fa";
import { CreditReports } from "./CreditReports";
import CreditReportsSummary from "./Summary";

const CreditReportsSubmodule: SubModule = {
  path: "credit_report",
  title: "Credit Report",
  icon: FaClipboardList,
  component: CreditReports,
  summary: CreditReportsSummary,
  statusName: "creditReports",
};

export default CreditReportsSubmodule;
