import {
  StatusType,
  SubModule,
} from "components/FormModuleSubMenu/SubmenuModule";
import { ModuleStatus } from "globalTypes";
import { sortBy } from "lodash";
import React from "react";
import { useNested } from "utils/hooks";
import css from "./styles.module.css";

interface ModuleSummariesProps<T> {
  status: T;
  subModules: SubModule[];
}

export default function ModuleSummaries<T extends StatusType>({
  status,
  subModules,
}: ModuleSummariesProps<T>) {
  const { url } = useNested();
  const moduleSorts = {
    [ModuleStatus.InProgress]: 0,
    [ModuleStatus.NotStarted]: 1,
    [ModuleStatus.Complete]: 2,
  };
  const sortedSubmodules = sortBy(
    subModules,
    (m) => moduleSorts[status[m.statusName].status]
  );

  return (
    <div className={css.subModules}>
      {sortedSubmodules.map((m) => (
        <m.summary key={m.path} {...m} href={url(m.path)} />
      ))}
    </div>
  );
}
