import * as React from "react";

const style = getComputedStyle(document.documentElement);

// We provide default breakpoints here since jest doesn't load up styles.
const small = style.getPropertyValue("--breakpoint-sm") || "500px";
const large = style.getPropertyValue("--breakpoint-lg") || "1200px";

export const breakpoints = {
  mobile: `(max-width: ${small})`,
  tablet: `(min-width: calc(${small} + 1px)) and (max-width: calc(${large} - 1px))`,
  desktop: `(min-width: ${large})`,
};

export function useBreakpoint(cssMediaQuery: string) {
  const mql = React.useRef(window.matchMedia(cssMediaQuery));
  const [breakpointMatches, setBreakpointMatches] = React.useState(
    mql.current.matches
  );

  React.useEffect(() => {
    const listener = (e: MediaQueryListEvent) => {
      setBreakpointMatches(e.matches);
    };

    // addListener is deprecated, but is (of course)
    // the version that works with Safari!
    mql.current.addListener(listener);
    return () => mql.current.removeListener(listener);
  }, []);

  return breakpointMatches;
}
