import { Card } from "components/Card";
import { Modal } from "components/Modal";
import LawFirmUserForm from "components/PortalModules/LawFirmUsersGrid/LawFirmUserForm";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";

interface ModalProps {
  isOpen: boolean;
  onClose: () => any;
  onCreate: (newUser: LawFirmUser) => any;
}

export default function CreateLawFirmUserModal({
  isOpen,
  onClose,
  onCreate,
}: ModalProps) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Card>
        <div>
          <h3>New User</h3>
        </div>
        <LawFirmUserForm onClose={onClose} onCreate={onCreate} />
      </Card>
    </Modal>
  );
}

interface ButtonProps {
  onCreate: (newUser: LawFirmUser) => any;
}

export function CreateLawFirmUserButton({ onCreate }: ButtonProps) {
  const [isOpen, setOpen] = useState(false);

  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  return (
    <>
      <CreateLawFirmUserModal
        isOpen={isOpen}
        onClose={closeModal}
        onCreate={onCreate}
      />
      <button
        type="button"
        className={"btn primary action"}
        onClick={async () => {
          openModal();
        }}
        title="Delete"
      >
        <FaPlus />
        <span>Create user</span>
      </button>
    </>
  );
}
