import { FaClipboardList } from "react-icons/fa";
import { Seizures } from "./Seizures";
import SeizuresSummary from "./Summary";
import type { SubModule } from "components/FormModuleSubMenu/SubmenuModule";

const SeizuresSubmodule: SubModule = {
  path: "seizures",
  icon: FaClipboardList,
  title: "Seized Assets",
  component: Seizures,
  summary: SeizuresSummary,
  statusName: "seizures",
};

export default SeizuresSubmodule;
