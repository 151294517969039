import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary from "components/ModuleSummary";
import LinesSummary from "components/ModuleSummary/LinesSummary";
import React from "react";
import GET_TAXPAYER_ID from "./GetTaxpayerId.gql";

export default function TaxpayerIdDocumentsSummary(
  props: SubModuleSummaryProps
) {
  const { data } = useQuery<GetTaxpayerIdDocument>(GET_TAXPAYER_ID, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress?.taxpayerId}>
      <LinesSummary
        requiredData={data?.filingPerson}
        maxLines={1}
        maxWidth="15em"
        style={{ textAlign: "center" }}
      >
        {data?.progress?.taxpayerId?.status === "COMPLETE"
          ? "You have provided your taxpayer ID."
          : "You have not provided your taxpayer ID."}
      </LinesSummary>
    </ModuleSummary>
  );
}
