import { isPlainObject } from "lodash";
import * as React from "react";
import { UseFormMethods } from "react-hook-form";

const dotPaths = (nested: any): { name: string; message: any }[] => {
  const dotted = [];
  for (const [name, message] of Object.entries(nested)) {
    if (!isPlainObject(message)) {
      dotted.push({ name, message });
    } else {
      for (const subpath of dotPaths(message)) {
        dotted.push({
          name: `${name}.${subpath.name}`,
          message: subpath.message,
        });
      }
    }
  }
  return dotted;
};

const snakeToCamel = (str: string) =>
  str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace("-", "").replace("_", "")
  );

const drfErrorTranslation = (form: UseFormMethods) => (reason: any) => {
  const djangoErrors = reason && reason.response && reason.response.data;
  if (djangoErrors) {
    dotPaths(djangoErrors).forEach(({ name, message }) =>
      form.setError(name, message)
    );
  }
};

const gqlErrorTranslation = (form: UseFormMethods) => (reason: any) => {
  const gqlErrors = reason && reason.graphQLErrors;
  if (!gqlErrors) {
    return;
  }
  for (const error of gqlErrors) {
    if (!error.state) {
      // the error has no user-defined error message
      console.error(error);
      form.setError("nonFieldErrors", {
        message: "An error occurred",
        type: "manual",
      });
      continue;
    }
    for (const [name, errorValues] of Object.entries<{ message: string }[]>(
      error.state
    )) {
      // If the errorvalues are objects, extract the messages to an array first
      let errorValuesArray: string[] = [];
      if (errorValues[0] && typeof errorValues[0] === "object") {
        errorValuesArray = errorValues.map((errorValue) => errorValue.message);
      } else {
        errorValuesArray = errorValues;
      }
      form.setError(snakeToCamel(name), {
        type: "manual",
        message: errorValuesArray.join(" "),
      });
    }
  }
};

export enum ErrorTranslationBackends {
  DRF,
  GraphQL,
}

export type ErrorTranslator = (e: any) => void;

export function useBackendErrorTranslation(
  form: UseFormMethods<any>,
  backend = ErrorTranslationBackends.GraphQL
) {
  let translator: any;
  if (backend === ErrorTranslationBackends.DRF) {
    translator = drfErrorTranslation;
  } else if (backend === ErrorTranslationBackends.GraphQL) {
    translator = gqlErrorTranslation;
  } else {
    throw new Error("Unknown error translation backend");
  }
  return React.useMemo<ErrorTranslator>(
    () => translator(form),
    [form, translator]
  );
}
