import { useQuery } from "@apollo/client";
import { BigMoney, BigNumber, BigState } from "components/Big";
import { Loading } from "components/Loading";
import React from "react";
import { formatStateNameFromAbbr } from "utils/formatters";
import LOAD_SUMMARY_STATS from "./LoadSummaryStats.gql";
import css from "./styles.module.css";

function shouldDisplayStat(input: string | number) {
  return !!input && !/^[0]*\.?[0]{0,2}$/.test(input.toString());
}

export function SummaryStats() {
  const { data } = useQuery<LoadSummaryStats>(LOAD_SUMMARY_STATS);

  if (!data) return <Loading color="var(--gray-1)" />;
  const { summary } = data.filingPerson;
  const shouldDisplayStats = [
    shouldDisplayStat(summary.totalOtherDebt),
    shouldDisplayStat(summary.householdSize),
    !!summary.usState,
  ].includes(true);

  return (
    <>
      {shouldDisplayStats && (
        <div className={css.SummaryStats}>
          <BigMoney caption="general debt">{summary.totalOtherDebt}</BigMoney>
          <BigNumber caption="people in your household">
            {summary.householdSize}
          </BigNumber>
          {!!summary.usState && (
            <BigState
              caption={`resident of ${formatStateNameFromAbbr(
                summary.usState
              )}`}
            >
              {summary.usState}
            </BigState>
          )}
        </div>
      )}
    </>
  );
}
