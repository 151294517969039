import { useListRoot } from "components/EditCreateList/hooks";
import * as React from "react";
import { PreviousNameWizard } from "./PreviousNameWizard";

export function CreateView({ listURL }: { listURL: string }) {
  const root = useListRoot();
  const [nextURL, setNextURL] = React.useState(`${root.url}/summary`);
  return (
    <PreviousNameWizard
      title="Add a previous name"
      backURL={`${root.url}/summary`}
      nextURL={nextURL}
    />
  );
}
