import { MaskedInput } from "components/MaskedInput";
import { TextInput, TextInputProps } from "components/TextInput";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { fancyMax, fancyMin, fancyNumber } from "utils/validators";

export interface NumberInputCommonProps
  extends Omit<TextInputProps, "onChange" | "ref"> {
  /** What to display before the amount. */
  prefix?: string;
  /** What to display after the amount. */
  suffix?: string;
  /** Limit to the length of the integer number. */
  integerLimit?: number;
  /** Whether to allow the user to enter a fractional amount. Defaults to `false`. */
  allowDecimal?: boolean;
  /** Limit to the number of decimal places. Defaults to `2`. */
  decimalLimit?: number;
  /** Whether to add thousands separators. Defaults to `false`. */
  includeThousandsSeparator?: boolean;
  /** Whether to allow input of negative values. Defaults to `false`. */
  allowNegative?: boolean;

  onChange?: (newValue: any) => any;
  value?: any;
}

export function ControlledNumberInput({
  prefix = "",
  suffix = "",
  integerLimit,
  allowDecimal = false,
  decimalLimit = 2,
  allowNegative = false,
  includeThousandsSeparator = false,
  onChange,
  value,
  name,
  register = true,
  ...etc
}: NumberInputCommonProps) {
  return (
    <TextInput
      {...etc}
      name={name}
      register={register}
      inputElement={
        <MaskedInput
          onChange={(e) => onChange?.(e.target.value)}
          inputMode="decimal"
          type="text"
          key={name}
          value={value}
          mask={createNumberMask({
            prefix,
            suffix,
            integerLimit,
            allowDecimal,
            decimalLimit,
            allowNegative,
            includeThousandsSeparator,
          })}
        />
      }
    />
  );
}

export interface DecimalInputProps
  extends Omit<NumberInputCommonProps, "onChange" | "value"> {
  onChange?: (newValue: string) => any;
  value?: string;
}

/**
 * An input for collecting numeric values as strings.
 *
 * Supports numbers with comma separators and decimals (depending on mask props), as well as
 * performing min and max validation with these values.
 */
export function DecimalInput({
  rules = {},
  min,
  max,
  ...props
}: DecimalInputProps) {
  const rulesWithValidate = {
    ...rules,
    validate: {
      fancyNumber,
      min: (value: string) =>
        typeof min === "undefined" ? true : fancyMin(min)(value),
      max: (value: string) =>
        typeof max === "undefined" ? true : fancyMax(max)(value),
      ...(rules.validate || {}),
    },
  };

  return <ControlledNumberInput {...props} rules={rulesWithValidate} />;
}

interface NumberInputProps
  extends Omit<NumberInputCommonProps, "onChange" | "value"> {
  onChange?: (newValue: number) => any;
  value?: number;
}

/**
 * An input for collecting numeric values as Numbers
 *
 * This input is like DecimalInput, except it parses the entered value and
 * returns it as a Number.
 */
export function NumberInput({
  rules = {},
  min,
  max,
  name,
  onChange: outsideOnChange,
  value,
  ...props
}: NumberInputProps) {
  const form = useFormContext();

  const numberInputOnChange = (cb: (newValue: number | null) => any) => {
    return (value: string) => {
      const numVal = parseFloat(value.replace(/,/g, ""));
      const newValue = Number.isNaN(numVal) ? null : numVal;
      cb(newValue);
    };
  };

  const getInput = (outsideProps: any) => (
    <ControlledNumberInput
      {...props}
      name={name}
      key={name}
      rules={rules}
      register={false}
      value={value?.toString() || ""}
      onChange={numberInputOnChange((newVal) => {
        outsideOnChange?.(newVal);
      })}
      {...outsideProps}
    />
  );

  return form ? (
    <Controller
      name={name}
      rules={rules}
      render={({ onChange, value }) =>
        getInput({
          onChange: numberInputOnChange((newVal) => {
            outsideOnChange?.(newVal);
            onChange(newVal);
          }),
          value,
        })
      }
    />
  ) : (
    getInput({})
  );
}
