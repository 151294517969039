import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary from "components/ModuleSummary";
import LinesSummary from "components/ModuleSummary/LinesSummary";
import { format, parseISO } from "date-fns";
import React from "react";
import { formatName } from "utils/formatters";
import ABOUT_YOU_MODULE from "./AboutYouFormInitialValue.gql";

export default function AboutYouSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<AboutYouFormInitialValue>(ABOUT_YOU_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.aboutYou}>
      <LinesSummary requiredData={data?.filingPerson?.birthday} maxLines={1}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>{data?.filingPerson && formatName(data.filingPerson)}</p>
          <p>
            {data?.filingPerson?.birthday &&
              `Born ${format(
                parseISO(data.filingPerson.birthday),
                "MM/dd/yyyy"
              )}`}
          </p>
          <p>{data?.filingPerson?.phoneNumber}</p>
        </div>
      </LinesSummary>
    </ModuleSummary>
  );
}
