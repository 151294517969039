import { useQuery } from "@apollo/client";
import ModuleSummaries from "components/FormModuleSubMenu/ModuleSummaries";
import {
  StatusType,
  SubModule,
} from "components/FormModuleSubMenu/SubmenuModule";
import ImageHero from "components/ImageHero";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import { ModuleStatus } from "globalTypes";
import { DocumentNode } from "graphql";
import { omit } from "lodash";
import React, { ReactNode } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

interface SectionSummaryProps {
  image: ReactNode;
  title: string;
  query: DocumentNode;
  doneURL: string;
  subModules: SubModule[];
  heroCopy: ReactNode;
  completedCopy: ReactNode;
}

interface ProgressQuery {
  progress: StatusType;
}

export default function SectionSummary<Query extends ProgressQuery>({
  image,
  title,
  query,
  doneURL,
  subModules,
  heroCopy,
  completedCopy,
}: SectionSummaryProps) {
  const { data } = useQuery<Query>(query, {
    fetchPolicy: "cache-and-network",
  });

  if (!data) return <Loading />;

  const allCompleted =
    Object.values(omit(data.progress, "__typename")).filter(
      (p) => p.status !== ModuleStatus.Complete
    ).length === 0;

  return (
    <>
      <ImageHero image={image}>
        <Title>{title}</Title>
        {allCompleted ? (
          <>
            {completedCopy}
            <Link to={doneURL} className="primary btn">
              <span>Continue</span>
              <FaArrowRight />
            </Link>
          </>
        ) : (
          heroCopy
        )}
      </ImageHero>

      <ModuleSummaries subModules={subModules} status={data.progress} />
    </>
  );
}
