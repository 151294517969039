import { LearnMore } from "components/LearnMore";
import React from "react";

export default function CreditReportsLearnMore() {
  return (
    <LearnMore>
      <div>
        <h4>
          Do I have to retrieve my credit report to use Lexria’s bankruptcy
          intake platform?
        </h4>
        <p>
          Yes. Providing the credit report will give accurate, up to date data
          for the bankruptcy filing.
        </p>

        <h4>Do I need to pay for my report?</h4>
        <p>No. Lexria’s fees are entirely handled by your attorney.</p>

        <h4>Can I get my own credit report through annualcreditreport.com?</h4>
        <p>
          You can always retrieve a free credit report through
          annualcreditreport.com. Lexria does not allow submissions of these
          free reports for bankruptcy intake.
        </p>

        <h4>
          Can I still get a free report through annualcreditreport.com after
          using Lexria?
        </h4>
        <p>
          Our retrieval of your report will not affect your ability to retrieve
          a free report if you are eligible to get one.
        </p>

        <h4>
          Why do I have to create a new login, etc. when I already did all this
          with on app.lexria.com?
        </h4>
        <p>
          Lexria is working with our credit reporting partner to setup single
          sign on. Until that is complete, you’ll need to maintain a separate
          login for both systems.
        </p>
      </div>
    </LearnMore>
  );
}
