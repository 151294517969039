import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, { SummaryStatsRow } from "components/ModuleSummary";
import LinesSummary from "components/ModuleSummary/LinesSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import { InsurancePolicyType } from "globalTypes";
import { startCase, toLower } from "lodash";
import React, { useMemo } from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import { formatOxfordComma } from "utils/formatters";
import INSURANCE_MODULE from "./InsuranceModule.gql";

export default function InsuranceSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<InsuranceModule>(INSURANCE_MODULE, {
    returnPartialData: true,
  });

  const [covered, notCovered] = useMemo(() => {
    if (!data?.insurancePolicies) return [[], []];
    const records: Partial<Record<InsurancePolicyType, boolean>> = {};
    data?.insurancePolicies?.forEach((p) => {
      records[p.type] = true;
    });
    return [
      Object.keys(records),
      Object.values(InsurancePolicyType).filter((t) => !records[t]),
    ];
  }, [data?.insurancePolicies]);

  const formatTypesList = (types: string[]) =>
    formatOxfordComma(...types.map((t) => startCase(toLower(t)))).replace(
      /_/g,
      " "
    );

  return (
    <ModuleSummary {...props} progress={data?.progress.insurancePolicies}>
      <LinesSummary
        requiredData={data?.insurancePolicies}
        maxLines={3}
        style={{ marginBottom: "16px" }}
      >
        <div>
          <strong>Covered:</strong> {formatTypesList(covered)}
        </div>
        <div>
          <strong>Not covered:</strong> {formatTypesList(notCovered)}
        </div>
      </LinesSummary>

      <SummaryStatsRow>
        <NumericSummary
          label="policy"
          maxValueLength={2}
          value={data?.insurancePolicies?.length}
          pluralizeLabel
        />
        <NumericSummary
          label="per month"
          valuePrefix="$"
          maxValueLength={5}
          value={fancyNumberSum(data?.insurancePolicies, "premium")}
        />
      </SummaryStatsRow>
    </ModuleSummary>
  );
}
