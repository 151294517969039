import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_OTHER_INCOME from "./DeleteOtherIncome.gql";
import GET_OTHER_INCOME from "./GetOtherIncome.gql";
import LIST_OTHER_INCOME from "./ListOtherIncome.gql";
import UPSERT_OTHER_INCOME from "./UpsertOtherIncome.gql";

const LIST_OTHER_INCOME_IDS = gql`
  query ListOtherIncomeIds {
    otherIncomes {
      id
    }
  }
`;

export function useListOtherIncome(
  options?: QueryHookOptions<ListOtherIncome>
) {
  return useQuery<ListOtherIncome>(LIST_OTHER_INCOME, options);
}

export function useGetOtherIncome(id: string) {
  return useQuery<GetOtherIncome, GetOtherIncomeVariables>(GET_OTHER_INCOME, {
    variables: { id },
  });
}

export function useUpsertOtherIncome<
  Schema extends { otherIncome: any } = UpsertOtherIncome,
  Variables = UpsertOtherIncomeVariables
>(query = UPSERT_OTHER_INCOME) {
  return useUpsert<Schema, Variables, ListOtherIncomeIds, OtherIncomeInput>(
    query,
    LIST_OTHER_INCOME_IDS,
    "otherIncome",
    "otherIncomes",
    (cache) => updateFilingProfileCache(cache, "hasNoOtherIncome", false)
  );
}

export function useDeleteOtherIncome() {
  return useDelete<DeleteOtherIncome, ListOtherIncomeIds>(
    DELETE_OTHER_INCOME,
    LIST_OTHER_INCOME_IDS,
    "deleteOtherIncome",
    "otherIncomes"
  );
}
