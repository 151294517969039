import { MonthlyExpenseCategoryChoice } from "globalTypes";
import { GiBigDiamondRing } from "react-icons/gi";
import { ExpensesCategoryDetail } from "../CategorySection/CategoryDetail";

export const alimonyCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.Alimony,
  displayName: "Alimony",
  displayLowercaseName: "alimony",
  hasNoProfileBooleanName: "hasNoAlimonyExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you or members of your household spent on alimony payments to a single individual over the last six months?" +
    " Create a new expense for each alimony recipient, and include their name " +
    "and contact information in the expense description.",
  urlPathName: "alimony",
  menuIcon: GiBigDiamondRing,
  description: null,
};
