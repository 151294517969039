import { QueryHookOptions, useQuery } from "@apollo/client";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_TAX_RETURNS from "./DeleteTaxReturn.gql";
import GET_TAX_RETURNS from "./GetTaxReturn.gql";
import LIST_TAX_RETURNS from "./ListTaxReturn.gql";
import UPSERT_TAX_RETURNS from "./UpsertTaxReturn.gql";

const LIST_TAX_RETURNS_IDS = gql`
  query ListTaxReturnIds {
    taxReturns {
      id
    }
  }
`;

export function useListTaxReturn(options?: QueryHookOptions<ListTaxReturn>) {
  return useQuery<ListTaxReturn>(LIST_TAX_RETURNS, options);
}

export function useGetTaxReturn(id: string) {
  return useQuery<GetTaxReturn, GetTaxReturnVariables>(GET_TAX_RETURNS, {
    variables: { id },
  });
}

export function useUpsertTaxReturn<
  Schema extends { taxReturn: any } = UpsertTaxReturn,
  Variables = UpsertTaxReturnVariables
>(query = UPSERT_TAX_RETURNS) {
  return useUpsert<Schema, Variables, ListTaxReturnIds, TaxReturnInput>(
    query,
    LIST_TAX_RETURNS_IDS,
    "taxReturn",
    "taxReturns"
  );
}

export function useDeleteTaxReturn() {
  return useDelete<DeleteTaxReturn, ListTaxReturnIds>(
    DELETE_TAX_RETURNS,
    LIST_TAX_RETURNS_IDS,
    "deleteTaxReturn",
    "taxReturns"
  );
}
