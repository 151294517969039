import FarmAnimalsDetail from "components/FormModules/Assets/FarmAssets/categories/FarmAnimals";
import FarmEquipmentDetail from "components/FormModules/Assets/FarmAssets/categories/FarmEquipment";
import FarmSuppliesDetail from "components/FormModules/Assets/FarmAssets/categories/FarmSupplies";
import CommercialFishingEquipmentDetail from "components/FormModules/Assets/FarmAssets/categories/FishingEquipment";
import CommercialFishingSuppliesDetail from "components/FormModules/Assets/FarmAssets/categories/FishingSupplies";
import MarketableCropsDetail from "components/FormModules/Assets/FarmAssets/categories/MarketableCrops";

/* Note that the order here is very important,
   as this order is used to build the menu.
 */
export default [
  FarmAnimalsDetail,
  MarketableCropsDetail,
  FarmEquipmentDetail,
  CommercialFishingEquipmentDetail,
  FarmSuppliesDetail,
  CommercialFishingSuppliesDetail,
];
