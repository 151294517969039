import { useQuery } from "@apollo/client";
import { ButtonRow } from "components/ButtonRow";
import { Card } from "components/Card";
import { LearnMore } from "components/LearnMore";
import { LinkCard } from "components/LinkCard";
import { Title } from "components/Title";
import { last, sum, uniq } from "lodash";
import React, { useMemo } from "react";
import {
  FaCheck,
  FaDownload,
  FaFileAlt,
  FaMapMarkerAlt,
  FaPlus,
  FaFileDownload,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  formatErrorsFromSchema,
  formatGraphAddress,
  formatMoneyForDisplay,
  formatOxfordComma,
  formatPlural,
  formatQuantity,
} from "utils/formatters";
import { useNested } from "utils/hooks";
import { parseFancyNumber } from "utils/validators";
import { array } from "yup";
import css from "./StudentLoans.module.scss";
import STUDENT_LOANS_MODULE from "./StudentLoansModule.gql";
import { StudentLoanSchema } from "./StudentLoanWizard";
import fileDownload from "js-file-download";

interface LoanGroupSummaryProps {
  loans: StudentLoansModule["studentLoans"];
  excludeValidation?: boolean;
}

export function LoanGroupSummary({
  loans,
  excludeValidation = false,
}: LoanGroupSummaryProps) {
  const lenders = uniq(loans.map((l) => l.lenderName));
  return (
    <div>
      {!excludeValidation &&
        formatErrorsFromSchema(array(StudentLoanSchema), loans)}
      <div>
        {lenders.length < 4
          ? `${formatPlural(loans.length, "Loan")} from
             ${formatOxfordComma(...lenders)}`
          : `Loans from ${lenders.length} lenders`}
      </div>
      <div>
        {formatMoneyForDisplay(
          sum(loans.map((l) => parseFancyNumber(l.monthlyPayment) || 0))
        )}{" "}
        total monthly payment
      </div>
      <div>
        {formatMoneyForDisplay(
          sum(
            loans.map(
              (l) =>
                (parseFancyNumber(l.outstandingPrincipal) || 0) +
                (parseFancyNumber(l.outstandingInterest) || 0)
            )
          )
        )}{" "}
        total outstanding amount
      </div>
    </div>
  );
}

interface StatementLoanGroupSummaryProps extends LoanGroupSummaryProps {
  statementFile: UploadedFile;
}

export function StatementLoanGroupSummary({
  loans,
  statementFile,
  ...etc
}: StatementLoanGroupSummaryProps) {
  async function downloadDocument(uploadedFile: UploadedFile) {
    const response = await fetch(uploadedFile.url);
    const blob = await response.blob();
    const name = last(uploadedFile.name.split("/"));
    fileDownload(blob, name);
  }

  return (
    <>
      <LoanGroupSummary loans={loans} {...etc} />
      <div className={css.statementSummaryRow}>
        <div>
          <FaMapMarkerAlt />
          <div>{formatGraphAddress(loans[0].lenderAddress)}</div>
        </div>
        <div
          className={css.statementFileRow}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            downloadDocument(statementFile);
          }}
        >
          <FaFileDownload />
          {last(statementFile.name.split("/"))}
        </div>
      </div>
    </>
  );
}

interface Props {
  doneURL: string;
  addLoansURL: string;
  importLoansURL: string;
  nsldsIndexURL: string;
}

export default function ListView({
  doneURL,
  addLoansURL,
  importLoansURL,
  nsldsIndexURL,
}: Props) {
  const { data } = useQuery<StudentLoansModule>(STUDENT_LOANS_MODULE);
  const { url } = useNested();
  const { studentLoans } = data;

  const loanGroups = useMemo(() => {
    const groups: { [hash: string]: StudentLoansModule["studentLoans"] } = {};

    studentLoans
      .filter((l) => !!l.statementFile)
      .forEach((l) => {
        groups[l.statementFileHash] = [
          ...(groups[l.statementFileHash] || []),
          l,
        ];
      });
    return groups;
  }, [studentLoans]);

  const nsldsLoans = useMemo(
    () => studentLoans.filter((l) => l.fromNsldsImport),
    [studentLoans]
  );

  const labelIconSize = "15px";
  const nsldsHeader = (
    <>
      <h5 className={css.statementCard__label}>
        <FaDownload size={labelIconSize} />
        <span>MyStudentData Import</span>
      </h5>
      <h3>{formatQuantity(nsldsLoans.length, " imported public loan")}</h3>
    </>
  );

  return (
    <div>
      <Title>Your student loans</Title>

      <p>
        Please add all of your public and private student student loans,
        including loans you have paid off. We’ll group your loans based on the
        statements you receive to make things easier.{" "}
        <LearnMore linkText="Learn more about how to find all of your loans.">
          <h4>How do I find my private student loans?</h4>
          <p>
            These is no central registry for private student loans. The best
            place to find this information is from your credit report. By
            federal law, everyone is permitted to obtain a copy of their credit
            report from all three major credit bureaus every year. To do this:
            <ol>
              <li>
                Go to{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://annualcreditreport.com"
                >
                  annualcreditreport.com
                </a>
              </li>
              <li>
                Navigate to and complete the form to obtain a credit report from
                any of the three major credit bureaus.
              </li>
              <li>
                Look for loans with the &ldquo;Loan Type&rdquo; field set to
                &ldquo;student loan&rdquo; or &ldquo;educational loan&rdquo; .
              </li>
              <li>
                Private loans should not contain lender names that look like
                &ldquo;US Department of Education&rdquo;, &ldquo;US Dept of
                Ed&rdquo;, or similar.
              </li>
              <li>
                For each of these loans, add a loan in the private loans table.
              </li>
            </ol>
            You can find more detailed instructions in our{" "}
            <a href="https://www.lexria.com/knowledge-base/how-do-i-get-my-private-student-loans-from-my-credit-report">
              knowledge base article
            </a>{" "}
            on obtaining this data.
          </p>
        </LearnMore>
      </p>

      <ButtonRow
        left={
          <Link to={addLoansURL} className="secondary btn">
            <FaPlus />
            <span>Add student loans</span>
          </Link>
        }
        right={
          <Link to={doneURL} className="primary btn">
            <span>Done</span>
            <FaCheck />
          </Link>
        }
      />

      {Object.entries(loanGroups).map(([hash, loans]) => (
        <LinkCard
          key={hash}
          to={url(`../statement/${hash}`)}
          className={css.statementCard}
        >
          <h5 className={css.statementCard__label}>
            <FaFileAlt size={labelIconSize} />
            <span>Statement</span>
          </h5>
          <h3>
            {formatQuantity(
              loans.length,
              ` ${loans[0]._type.toLowerCase()} student loan`
            )}
          </h3>

          <StatementLoanGroupSummary
            loans={loans}
            statementFile={loans[0].statementFile}
          />
        </LinkCard>
      ))}

      {!nsldsLoans.length ? (
        <Card className={css.statementCard}>
          {nsldsHeader}
          <Link className="btn" to={importLoansURL}>
            Import public loans &rarr;
          </Link>
          <p>
            If you have public loans, Lexria can import your information from
            the Department of Education.{" "}
          </p>
          <p className="supporting-text">
            Public student loans are issued to you by the Department of
            Education. Examples include Stafford, Perkins, Direct Plus, and
            Parent Plus loans.
          </p>
        </Card>
      ) : (
        <LinkCard to={nsldsIndexURL} className={css.statementCard}>
          {nsldsHeader}

          <LoanGroupSummary loans={nsldsLoans} />
        </LinkCard>
      )}
    </div>
  );
}
