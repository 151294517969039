import { useDeleteFarmAsset, useListFarmAssets } from "api/graphql/FarmAsset";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { FarmAssetSchema } from "components/FormModules/Assets/FarmAssets/FarmAssetForm";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema } from "utils/formatters";
import { useNested } from "utils/hooks";
import { CategoryDetail } from "./CategoryDetail";
import { Description } from "./Description";

interface Props {
  createURL: string;
  nextURL: string;
  prevURL: string;
  onEdit: (id: string) => any;
  categoryDetail: CategoryDetail;
}

export default function ListView(props: Props) {
  const {
    category,
    declarativeText,
    displayName,
    displayLowercaseName,
    hasNoProfileBooleanName,
    nextSectionText,
    noAssetDeclarativeText,
    booleanInterrogativeText,
  } = props.categoryDetail;

  const { data } = useListFarmAssets({
    variables: { categoryFilter: category },
  });
  const [onDelete] = useDeleteFarmAsset();
  const { path, url } = useNested();

  if (!data) return <Loading />;
  const { farmAssets: items } = data;

  return (
    <>
      <Title>Your {displayName}</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean={hasNoProfileBooleanName}
              createURL={props.createURL}
              nextURL={props.nextURL}
              addText="Add an item"
              question={<Description introText={booleanInterrogativeText} />}
              confirmation={
                <Description
                  introText={noAssetDeclarativeText}
                  footerText={nextSectionText}
                />
              }
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <Description introText={declarativeText} />

              <StatelessList
                {...props}
                emptyText="You haven’t added any items yet."
                addButtonText={`Add ${displayLowercaseName}`}
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(asset) => (
                  <div>
                    {formatErrorsFromSchema(FarmAssetSchema, asset)}
                    <strong>
                      {asset.assetDescription} (${asset.currentValue})
                    </strong>
                  </div>
                )}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
