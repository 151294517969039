import useOutsideClick from "@rooks/use-outside-click";
import cn from "classnames";
import { RootOverlay } from "components/DateInput/RootOverlay";
import { Loading } from "components/Loading";
import { find, get } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FaCaretDown, FaPencilAlt, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import css from "./GetOrCreate.module.css";

interface GetOrCreateProps<T> {
  /** The url which will display the create object form */
  createURL?: string;
  /** A function to be called with the current value when an object is to be edited */
  onEdit?: (value: T) => any;
  /** Lowercase noun of the type of object being selected, e.g. "person" or "vehicle */
  objectName: string;
  options?: { value: T; label: React.ReactNode }[];
  value?: T;
  onChange?: (newValue: T) => any;
  /** Whether options are loading */
  loading?: boolean;
}

function GetOrCreate<T extends string | number>({
  options = [],
  createURL,
  objectName,
  value,
  loading,
  onChange,
  onEdit,
}: GetOrCreateProps<T>) {
  const [isOpen, setOpen] = useState(options.length < 4 && !value);
  const [isSelect, setIsSelect] = useState(!isOpen);
  const dropdownParent = useRef<HTMLDivElement | undefined>();
  const selectedOption = useMemo(
    () => find(options, (o) => o.value === value),
    [value, options]
  );

  useEffect(() => {
    if (!loading) {
      const open = options.length < 4 && !value;
      setOpen(open);
      setIsSelect(!open);
    }
  }, [loading]);

  useOutsideClick(dropdownParent, () => {
    if (isSelect) setOpen(false);
  });

  const onClose = () => {
    setOpen(false);
    document.getElementById("selected-option-button")?.focus();
  };

  const onSelectOption = (value: T) => {
    onClose();
    onChange(value);
    setIsSelect(true);
  };

  const createButton = createURL && (
    <Link
      to={createURL}
      className={cn(css.createLink, "btn primary")}
      data-testid={`getOrCreateAddButton`}
    >
      <FaPlus />
      <span>Add {objectName}</span>
    </Link>
  );

  const optionsList = (
    <>
      <div
        className={css.optionsList}
        onKeyDown={(e) => {
          const target = e.target as HTMLButtonElement;
          if (e.key === "ArrowDown") {
            e.preventDefault();
            (target.nextElementSibling as HTMLButtonElement | null)?.focus();
          }
          if (e.key === "ArrowUp") {
            e.preventDefault();
            (
              target.previousElementSibling as HTMLButtonElement | null
            )?.focus();
          }
          if (e.key === "Escape" && isSelect) {
            e.preventDefault();
            onClose();
          }
        }}
      >
        {options.map(({ label, value }, i) => (
          <button
            type="button"
            key={value}
            className={cn("btn unstyled", css.option)}
            onClick={() => onSelectOption(value)}
            data-testid={`getOrCreateOptionId:${value}`}
            autoFocus={
              isSelect &&
              (value === selectedOption?.value || (!selectedOption && i === 0))
            }
          >
            {selectedOption?.value === value && "✔ "}
            {label}
          </button>
        ))}
        {createButton}
      </div>
    </>
  );

  return loading ? (
    <Loading size="S" />
  ) : (
    <div
      className={cn(css.container, { [css.isOpen]: isOpen })}
      ref={dropdownParent}
    >
      <div style={{ position: "relative", flexGrow: 1 }}>
        {isOpen &&
          (isSelect ? (
            <RootOverlay
              attachTo={dropdownParent.current}
              placement="bottom-start"
              modifiers={[
                { name: "preventOverflow", options: { altAxis: true } },
                {
                  name: "offset",
                  options: {
                    offset: [0, -dropdownParent.current?.clientHeight],
                  },
                },
                { name: "flip", enabled: false },
              ]}
              style={{ backgroundColor: "transparent", boxShadow: "none" }}
            >
              <div
                className={cn(css.dropdown, { [css.isOpen]: isOpen })}
                style={{
                  overflow: "hidden",
                  width: `${dropdownParent.current?.clientWidth}px`,
                }}
              >
                {optionsList}
              </div>
            </RootOverlay>
          ) : (
            optionsList
          ))}
        {isSelect && (
          <button
            className={cn("btn unstyled", css.option, css.selected)}
            id="selected-option-button"
            type="button"
            onClick={() => setOpen(true)}
            style={{
              borderTopLeftRadius: "32px !important",
              borderBottomLeftRadius: "32px !important",
            }}
          >
            <span
              style={{
                flexGrow: 1,
                color: selectedOption ? "inherit" : "var(--gray-3)",
              }}
            >
              {selectedOption?.label ||
                `Select ${
                  "aeiou".includes(objectName[0]) ? "an" : "a"
                } ${objectName}`}
            </span>
            <FaCaretDown />
          </button>
        )}
      </div>

      {!isOpen && onEdit && !!selectedOption && (
        <button
          className="btn"
          onClick={() => onEdit(selectedOption.value)}
          style={{ boxShadow: "none" }}
          title={`Edit this ${objectName}`}
          type="button"
        >
          <FaPencilAlt />
        </button>
      )}
      {!isOpen && createButton}
    </div>
  );
}

interface ControlledGetOrCreateProps<T>
  extends Omit<GetOrCreateProps<T>, "value" | "onChange"> {
  initialValue?: T;
  required?: string | boolean;
  name: string;
}

export default function ControlledGetOrCreate<T extends string | number>({
  name,
  required,
  initialValue,
  ...etc
}: ControlledGetOrCreateProps<T>) {
  const form = useFormContext();
  const error = get(form.errors, name) as any;
  return (
    <>
      <Controller
        name={name}
        rules={{ required }}
        defaultValue={initialValue}
        render={({ value, onChange }) => (
          <GetOrCreate {...etc} value={value} onChange={onChange} />
        )}
      />
      {error && <div className="errors">{error.message}</div>}
    </>
  );
}
