import { createPopper, Placement, StrictModifiers } from "@popperjs/core";
import cn from "classnames";
import * as React from "react";
import ReactDOM from "react-dom";
import { useCheckMounted } from "utils/hooks";
import { mergeRefs } from "utils/mergeRefs";
import { v4 } from "uuid";
import styles from "./DateInput.module.scss";

interface RootOverlayProps extends React.HTMLProps<HTMLDivElement> {
  attachTo: HTMLElement | string;
  children: React.ReactNode;
  placement?: Placement;
  modifiers?: StrictModifiers[];
}

function RootOverlay(
  {
    children,
    attachTo,
    className,
    placement = "bottom-start",
    modifiers = [],
    ...props
  }: RootOverlayProps,
  ref: React.Ref<any>
) {
  const containerID = React.useMemo(v4, []);
  const overlay = React.useRef<HTMLDivElement>();
  const isMounted = useCheckMounted();
  let container = document.getElementById(containerID);
  if (!container) {
    container = document.createElement("div");
    container.className = styles.container;
    container.id = containerID;
    document.body.append(container);
  }

  React.useEffect(() => {
    container.classList.add("ModalForeground");
    const el =
      typeof attachTo === "string"
        ? document.querySelector(attachTo)
        : attachTo;
    createPopper(el, overlay.current, { placement, modifiers });
    return () => {
      container.classList.remove("ModalForeground");
      if (!isMounted()) {
        container.remove();
      }
    };
  }, [attachTo, overlay, placement, modifiers]);

  return ReactDOM.createPortal(
    <div
      {...props}
      className={cn(className, styles.overlay)}
      ref={mergeRefs(ref, overlay)}
    >
      {children}
    </div>,
    container
  );
}

const forwarded = React.forwardRef(RootOverlay);
export { forwarded as RootOverlay };
