import classNames from "classnames";
import * as React from "react";
import css from "./styles.module.css";

interface PropTypes {
  children: string;
  status?: "success" | "error" | "warning" | "info";
  hollow?: boolean;
}

export default function Pill({
  status,
  children,
  hollow = false,
}: PropTypes): JSX.Element {
  return (
    <div
      className={classNames(css.root, {
        [css.success]: status === "success",
        [css.error]: status === "error",
        [css.warning]: status === "warning",
        [css.info]: status === "info",
        [css.hollow]: hollow,
      })}
    >
      <div className={css.content}>
        <span>{children}</span>
      </div>
    </div>
  );
}
