import * as React from "react";

export interface DescriptionProps {
  introText?: string;
  footerText?: string;
}

export function Description({ introText, footerText }: DescriptionProps) {
  return (
    <div>
      {!!introText && (
        <p className="required">
          <strong>{introText}</strong>
        </p>
      )}
      <p>Financial investments include but are not limited to:</p>
      <ul>
        <li>Investment funds</li>
        <li>Stocks</li>
        <li>Bonds</li>
        <li>Annuities</li>
        <li>Certificates of deposit</li>
        <li>Trusts and future interests</li>
        <li>Profit interests</li>
        <li>Intellectual property</li>
        <li>Royalties</li>
        <li>Business licenses and franchises</li>
        <li>Cash</li>
      </ul>
      {!!footerText && (
        <p>
          <span>{footerText}</span>
        </p>
      )}
    </div>
  );
}
