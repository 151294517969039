import { LearnMore } from "components/LearnMore";
import * as React from "react";

export interface DescriptionProps {
  introText?: string;
  footerText?: string;
}

export function Description({ introText, footerText }: DescriptionProps) {
  return (
    <div>
      {!!introText && (
        <p className="required">
          <strong>{introText}</strong>
        </p>
      )}
      <p>Financial accounts include but are not limited to:</p>
      <ul>
        <li>Checking accounts</li>
        <li>Savings accounts</li>
        <li>Brokerage accounts</li>
        <li>Retirement accounts</li>
        <li>Health savings accounts (HSAs)</li>
        <li>Mortgage escrow accounts</li>
        <li>Education savings accounts (e.g. 529 plans)</li>
      </ul>
      <p>
        {!!footerText && <span>{footerText} </span>}
        <LearnMore>
          <h4>Can I protect my retirement accounts in bankruptcy?</h4>
          <p>Yes, most retirement accounts can be protected in bankruptcy.</p>

          <h4>Will my education savings plan be protected in a bankruptcy?</h4>
          <p>
            Yes, education savings through 529 plans for immediate family can
            generally be protected in bankruptcy.
          </p>

          <h4>
            Should I include other financial savings like certificates of
            deposit or stock holdings?
          </h4>
          <p>
            No, you should list those in the &rsquo;Financial Investments&lsquo;
            category. You can access that category by clicking or tapping
            &rsquo;Financial Investments &lsquo; in the navigation menu.
            Remember, however, to list brokerage accounts in this section.
          </p>
        </LearnMore>
      </p>
    </div>
  );
}
