import { useQuery } from "@apollo/client";
import { BooleanInput } from "components/BooleanInput";
import GetOrCreateAddress from "components/GetOrCreate/GetOrCreateAddress";
import { Loading } from "components/Loading";
import { uniqBy } from "lodash";
import React from "react";
import { useFormContext } from "react-hook-form";
import { formatGraphAddress } from "utils/formatters";
import MAILING_ADDRESSES_MODULE from "./MailingAddressesModule.gql";

export default function MailingAddressForm() {
  const form = useFormContext();
  const mailingSame = form.watch("mailingAndCurrentResidenceAddressesSame");
  const { data } = useQuery<MailingAddressesModule>(MAILING_ADDRESSES_MODULE, {
    fetchPolicy: "cache-and-network",
  });

  if (!data) return <Loading />;

  const currentResidence = data.residentialAddresses.filter(
    (ra) => ra.residentTo === null
  )[0];
  const hasCurrentResidence = !!currentResidence;

  const addressOptions = uniqBy(
    [
      ...data.mailingAddresses,
      ...data.residentialAddresses.map((ra) => ra.address),
    ],
    "id"
  ).filter((address) => address.id !== currentResidence?.address?.id);

  return (
    <>
      {hasCurrentResidence && (
        <BooleanInput
          label={
            <>
              Is your mailing address the same as your current residential
              address ({formatGraphAddress(currentResidence.address, true)})?
            </>
          }
          name="mailingAndCurrentResidenceAddressesSame"
          required="Mailing address is required."
          helpText={
            mailingSame === undefined
              ? "If you are not sure, or don’t know what the difference is, you should answer no."
              : undefined
          }
        />
      )}
      {(!hasCurrentResidence || mailingSame === false) && (
        <>
          <p>What is your current mailing address?</p>
          <GetOrCreateAddress
            name="mailingAddress"
            idName="mailingAddressId"
            options={addressOptions}
            required="Mailing address is required."
          />
        </>
      )}
    </>
  );
}
