import { useQuery } from "@apollo/client";
import { useDeleteEducationalStudy } from "api/graphql/EducationalStudy";
import { ButtonRow } from "components/ButtonRow";
import { EducationSchema } from "components/FormModules/Education/EducationWizard";
import { ListItem } from "components/ListItem";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema } from "utils/formatters";
import { useNested } from "utils/hooks";
import EDUCATION_MODULE from "./EducationModule.gql";
import { ListHeaderDescription } from "./ListHeaderDescription";

interface Props {
  onEdit: (id: string) => any;
  createURL: string;
  nextURL: string;
}

export function ListView({ onEdit, createURL, nextURL }: Props) {
  const { data } = useQuery<EducationModule>(EDUCATION_MODULE, {
    fetchPolicy: "cache-and-network",
  });
  const [onDelete] = useDeleteEducationalStudy();
  const { path, url } = useNested();

  if (!data) return <Loading />;
  const { educationalStudies: items } = data;

  return (
    <>
      <Title>Your educational history</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoEducationalStudies"
              createURL={createURL}
              nextURL={nextURL}
              addText="Add a study program"
              question={
                <>
                  <p className="required">
                    <strong>
                      Have you attended any paid educational programs you have
                      started after high school, including programs you studied
                      for but did not complete?
                    </strong>
                  </p>
                  <p>Examples include but are not limited to:</p>
                  <ul>
                    <li>University</li>
                    <li>Community college</li>
                    <li>Trade school</li>
                  </ul>
                </>
              }
              confirmation={
                <p>
                  You have indicated that you do not have any paid educational
                  studies you started after high school, including programs you
                  studied for but did not complete. If that’s correct, you’re
                  ready to continue to the next section. Otherwise, you may add
                  programs below.
                </p>
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}
        <Route
          path={path("/")}
          render={() => (
            <>
              <ListHeaderDescription />
              <ButtonRow
                style={{ marginBottom: "8px" }}
                left={
                  <Link to={createURL} className="btn secondary">
                    <FaPlus />
                    <span>Add a program</span>
                  </Link>
                }
                right={
                  <Link to={nextURL} className="btn primary">
                    <span>Done</span>
                    <FaCheck />
                  </Link>
                }
              />
              {items.map((es) => {
                return (
                  <ListItem
                    key={es.id}
                    onEdit={() => onEdit(es.id)}
                    onDelete={() => onDelete(es.id)}
                    objectName="educationalStudy"
                  >
                    <div>
                      {formatErrorsFromSchema(EducationSchema, es)}
                      <strong>
                        {es.degree} at {es.school}
                      </strong>
                      <br />
                      <div className="supporting-text">
                        Enrolled {es.graduated ? "in" : "from"} {es.firstYear}
                        {es.graduated ? ", graduated in " : " to "}
                        {es.lastYear}
                      </div>
                    </div>
                  </ListItem>
                );
              })}
            </>
          )}
        />
      </Switch>
    </>
  );
}
