import { QueryHookOptions, useQuery } from "@apollo/client";
import { useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import GET_CHILD_SUPPORT from "./GetChildSupport.gql";
import LIST_CHILD_SUPPORT from "./ListChildSupport.gql";
import UPSERT_CHILD_SUPPORT from "./UpsertChildSupport.gql";

// Not sure how to handle this correctly when there is no list (OneToOne relation)...
const LIST_CHILD_SUPPORT_IDS = gql`
  query ListChildSupportIds {
    childSupports {
      id
    }
  }
`;

export function useListChildSupport(
  options?: QueryHookOptions<ListChildSupport>
) {
  return useQuery<ListChildSupport>(LIST_CHILD_SUPPORT, {
    ...options,
    fetchPolicy: "network-only",
  });
}

export function useGetChildSupport() {
  return useQuery<GetChildSupport>(GET_CHILD_SUPPORT, {
    fetchPolicy: "network-only",
  });
}

export function useUpsertChildSupport<
  Schema extends { childSupport: any } = UpsertChildSupport,
  Variables = UpsertChildSupportVariables
>(query = UPSERT_CHILD_SUPPORT) {
  return useUpsert<Schema, Variables, ListChildSupportIds, ChildSupportInput>(
    query,
    LIST_CHILD_SUPPORT_IDS,
    "childSupport",
    "childSupports",
    (cache, data) => {
      const { id, childSupport } = data.childSupport;
      cache.modify({
        id: cache.identify({ __typename: "FilingPerson", id }),
        fields: {
          childSupport: () => childSupport,
        },
      });
    }
  );
}
