import { DateInput } from "components/DateInput";
import { MonthInput } from "components/MonthInput";
import { DecimalInput } from "components/NumberInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { DateSchema, DecimalSchema } from "utils/validators/yup";
import { object } from "yup";

export const DetailsSchema = object({
  outstandingAmount: DecimalSchema.required(
    "Please enter your outstanding balance for this card."
  ),
  delinquentAmount: DecimalSchema.required(
    "Please enter the delinquent amount if any, otherwise enter “0”."
  ),
  monthlyPayment: DecimalSchema.optional().nullable(),
  lastPaymentDate: DateSchema.dateNotFuture(
    "Last payment date can’t be in the future."
  )
    .optional()
    .nullable(),
  accountCreationMonth: DateSchema.dateNotFuture(
    "Account creation month must be this month or before."
  ).required("Please enter the month you opened this account."),
  lastSixMonthsDebt: DecimalSchema.required(
    "Please enter the amount transacted through this card in the last six months."
  ),
  lastNinetyDayCashAdvance: DecimalSchema.required(
    "Please enter the total cash advances taken out on this card in the last 90 days."
  ),
}).required();

export default function Details({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <MonthInput
          name="accountCreationMonth"
          label="Account opened month"
          width="200px"
          autoFocus={!isEdit}
        />
        <DateInput name="lastPaymentDate" label="Last payment date" />
      </div>

      <div className="form-row">
        <DecimalInput
          name="outstandingAmount"
          label="Outstanding balance"
          addOnBefore="$"
          width="150px"
          includeThousandsSeparator
          allowDecimal
        />
      </div>

      <div className="form-row bottom">
        <DecimalInput
          name="lastSixMonthsDebt"
          label="Amount borrowed in last six months"
          addOnBefore="$"
          width="150px"
          includeThousandsSeparator
          allowDecimal
        />
        <DecimalInput
          name="lastNinetyDayCashAdvance"
          label="Total cash advances in the last 90 days"
          addOnBefore="$"
          width="150px"
          includeThousandsSeparator
          allowDecimal
        />
      </div>

      <div className="form-row bottom">
        <DecimalInput
          name="delinquentAmount"
          label="Delinquent amount"
          addOnBefore="$"
          includeThousandsSeparator
          allowDecimal
          helpText="The past-due amount. If you’re up to date on payments, enter 0."
        />

        <DecimalInput
          name="monthlyPayment"
          label="Minimum monthly payment"
          addOnBefore="$"
          includeThousandsSeparator
          allowDecimal
          helpText="Leave this blank if this credit card has no minimum payment, or it changes from month to month."
        />
      </div>

      <WizardNavigation {...navProps} />
    </>
  );
}
