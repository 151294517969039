import { useMutation } from "@apollo/client";
import { useFilingProfileBooleanMutation } from "api/graphql/FilingProfile";
import LIST_STUDENT_LOANS from "api/graphql/StudentLoan/ListStudentLoans.gql";
import { updateQuery } from "api/graphql/utils";
import cn from "classnames";
import { ButtonRow } from "components/ButtonRow";
import { LoanType } from "globalTypes";
import { camelCase, fromPairs, isObject, omit, toPairs } from "lodash";
import React from "react";
import { FaArrowLeft, FaCheck } from "react-icons/fa";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { formatQuantity } from "utils/formatters";
import { FileUploader } from "../FileUploader";
import REPLACE_NSLDS_LOANS from "./ReplaceNsldsLoans.gql";

const objectToCamelCase = (obj: Record<string, any>): Record<string, any> =>
  fromPairs(
    toPairs(obj).map(([k, v]) => [
      camelCase(k),
      isObject(v) ? objectToCamelCase(v) : v,
    ])
  );

interface UploadViewProps {
  abortURL: string;
  backURL: string;
  successURL: string;
  errorURL: string;
}

export function UploadView({
  abortURL,
  backURL,
  successURL,
  errorURL,
}: UploadViewProps) {
  const [markNoNSLDS, { loading }] =
    useFilingProfileBooleanMutation("hasNoNsldsLoans");
  const [suggestedLoans, setSuggestedLoans] = React.useState<any[]>(null);
  const [replaceNsldsLoans, { loading: replacing }] = useMutation<
    ReplaceNsldsLoans,
    ReplaceNsldsLoansVariables
  >(REPLACE_NSLDS_LOANS, {
    update(cache, { data }) {
      updateQuery<ListStudentLoans>(cache, LIST_STUDENT_LOANS, () => ({
        studentLoans: data.replaceNsldsLoans,
      }));
    },
  });
  const history = useHistory();

  const createSuggestedLoans = async () => {
    try {
      // Yes, this is some atrocious data moulding. The MyStudentData
      // views should probably be re-written in graphql, but I couldn't
      // justify that effort at the moment.
      await replaceNsldsLoans({
        variables: {
          input: suggestedLoans.map(
            (l) =>
              omit(
                {
                  ...objectToCamelCase(l),
                  lenderAddress: l.lender_address && {
                    line1: l.lender_address.line_1,
                    line2: l.lender_address.line_2,
                    city: l.lender_address.city,
                    state: { abbr: l.lender_address.state },
                    zipCode: l.lender_address.zip_code,
                  },
                  _type: LoanType.Public,
                  category: l.category?.replace(/-/g, "_"),
                  hasNoCosigners: true,
                  fromNsldsImport: true,
                },
                "type"
              ) as unknown as StudentLoanInput
          ),
        },
      });
      history.push(successURL);
    } catch (e) {
      console.error(e);
      history.push(errorURL);
    }
  };

  const markNoLoans = async () => {
    await markNoNSLDS(true);
    history.push(abortURL);
  };

  return (
    <div>
      <h4 style={{ marginTop: 0 }}>Instructions</h4>

      <ol>
        <li>
          Log in to the Department of Educations’s{" "}
          <a
            href="https://studentaid.gov/fsa-id/sign-in/landing"
            target="_blank"
            rel="noopener noreferrer"
          >
            My Student Data export page
          </a>
          .{" "}
          <b>
            NOTE: You must log in using a laptop or desktop computer. The
            Department of Education does not currently support downloading this
            file on a mobile device.
          </b>
        </li>

        <li>
          Accept the disclaimers when prompted and click{" "}
          <strong>confirm</strong> to download your My Student Data File
        </li>

        <li>Upload the MyStudentData.txt file you just downloaded below:</li>
      </ol>

      <FileUploader onSuggestionsReceived={setSuggestedLoans} />

      <div style={{ marginTop: "24px" }}>
        {!suggestedLoans ? (
          <p>
            If you don&rsquo;t see your public loans in the Department of
            Education system or otherwise can&rsquo;t access your My Student
            Data export, just click the button below and we&rsquo;ll add your
            public loans manually.
          </p>
        ) : (
          <p>
            Remember, your My Student Data File has sensitive identifying
            information, so be sure to completely delete and remove the file
            after upload if you’re using a shared or public computer.
          </p>
        )}
      </div>

      <ButtonRow
        style={{ marginBottom: 0 }}
        left={
          <Link to={backURL} className="btn">
            <FaArrowLeft />
            <span>Back</span>
          </Link>
        }
        right={
          <button
            type="button"
            className={cn("btn", {
              primary: suggestedLoans,
              secondary: !suggestedLoans,
              loading: loading || replacing,
            })}
            onClick={suggestedLoans ? createSuggestedLoans : markNoLoans}
          >
            <span>
              {suggestedLoans
                ? `Save ${formatQuantity(suggestedLoans.length, " loan")}`
                : "I don't have importable loans"}
            </span>
            {suggestedLoans && <FaCheck />}
          </button>
        }
      />
    </div>
  );
}
