import { useQuery } from "@apollo/client";
import cn from "classnames";
import gql from "graphql-tag";
import React, { useEffect, useRef } from "react";
import { FaArrowRight } from "react-icons/fa";

const GET_PULL_CREDIT_REPORT_URL = gql`
  query GetPullCreditReportSignupURL {
    getPullCreditReportSignupURL
  }
`;

export function SignupButton({
  text = "Get started",
  icon = <FaArrowRight />,
  onClick,
}: {
  text?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
}) {
  const { data } = useQuery<GetPullCreditReportSignupUrl>(
    GET_PULL_CREDIT_REPORT_URL
  );
  const element = useRef(null);

  useEffect(() => {
    if (!element.current) return;

    const el = element.current;
    el.addEventListener("click", onClick);
    return () => el.removeEventListener("click", onClick);
  }, [onClick]);

  return (
    <a
      href={data?.getPullCreditReportSignupURL || "#"}
      className={cn("btn", "primary", {
        disabled: !data?.getPullCreditReportSignupURL,
        loading: !data?.getPullCreditReportSignupURL,
      })}
      target="_blank"
      rel="noreferrer"
      ref={element}
    >
      <span>{text}</span>
      {icon}
    </a>
  );
}

export function SignupLink({ children }: { children: React.ReactNode }) {
  const { data } = useQuery<GetPullCreditReportSignupUrl>(
    GET_PULL_CREDIT_REPORT_URL
  );

  return (
    <a
      href={data?.getPullCreditReportSignupURL || "#"}
      className={cn({ disabled: !data?.getPullCreditReportSignupURL })}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
}
