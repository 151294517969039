import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import CONSULTATIONS_MODULE from "./ConsultationsModule.gql";

export default function ConsultationsSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<ConsultationsModule>(CONSULTATIONS_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.consultations}>
      {data?.filingProfile?.hasNoBankruptcyConsultations ? (
        <HasNoSummary>
          You haven&rsquo;t consulted with any law firms or companies for
          bankruptcy advice or services.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="consultation"
            maxValueLength={1}
            value={data?.consultations?.length}
            pluralizeLabel
          />

          <NumericSummary
            label="paid"
            maxValueLength={5}
            valuePrefix="$"
            value={fancyNumberSum(
              data?.consultations?.reduce((p, c) => [...p, ...c.payments], []),
              "amount"
            )}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
