import { LearnMore } from "components/LearnMore";
import React from "react";

export function OtherIncomeExamples() {
  return (
    <ul>
      <li>Unemployment</li>
      <li>Disability</li>
      <li>Child Support</li>
      <li>Alimony</li>
      <li>Food stamps</li>
      <li>Pension</li>
      <li>Real Estate</li>
      <li>Capital Gains</li>
      <li>Annuities</li>
      <li>Unpaid wages or money that’s owed to you</li>
    </ul>
  );
}

export default function OtherIncomeLearnMore() {
  return (
    <LearnMore>
      <h4>Why do you need to know about my household income?</h4>
      <p>
        The bankruptcy process includes a means test, which involves examining
        your income over the last six months.
      </p>
      <h4>What is non-employment income?</h4>
      <p>
        Non-employment income is money that you make from a source other than
        your job, a business you own, or self employment. Sources include:
      </p>
      <OtherIncomeExamples />
    </LearnMore>
  );
}
