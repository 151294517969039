import { AddressForm, AddressSchema } from "components/FormSections/Address";
import PrivacyInput from "components/PrivacyInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { object, string } from "yup";

export const LenderSchema = object({
  accountNumber: string().required(
    "Please enter the account number for this loan."
  ),
  billingAddress: AddressSchema.required(),
}).required();

export default function Lender({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        {/* @ts-ignore Switch out text-mask to something up to date */}
        <PrivacyInput
          autoFocus={!isEdit}
          name="accountNumber"
          label="Loan number or account number"
          width="200px"
        />
      </div>

      <p>
        What is the mailing address for the loan department at the account
        institution? This will allow us to contact them about your loan if
        necessary.
      </p>
      <AddressForm graphQL name="billingAddress" />

      <WizardNavigation {...navProps} />
    </>
  );
}
