import { useQuery } from "@apollo/client";
import { useDeleteMedicalDebt } from "api/graphql/MedicalDebt";
import { ListViewProps } from "components/EditCreateList/stateless";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  formatErrorsFromSchema,
  formatName,
  formatOxfordComma,
  formatQuantity,
} from "utils/formatters";
import { useNested } from "utils/hooks";
import MEDICAL_DEBTS_MODULE from "./MedicalDebtsModule.gql";
import { MedicalDebtSchema } from "./MedicalDebtWizard";

interface Props {
  onEdit: (id: string) => any;
  createURL: string;
  nextURL: string;
}

export default function ListView(props: ListViewProps) {
  const { data, loading } = useQuery<MedicalDebtsModule>(MEDICAL_DEBTS_MODULE);
  const [onDelete] = useDeleteMedicalDebt();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { medicalDebts: items } = data;

  return (
    <>
      <Title>Your Medical Debts</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoMedicalDebts"
              createURL={props.createURL}
              nextURL={props.nextURL}
              addText="Add a medical debt"
              question={
                <p className="required">
                  <strong>
                    Do you have any debts related to medical bills?
                  </strong>
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you don&rsquo;t have any medical
                  debts. If that&rsquo;s correct, you&rsquo;re ready to continue
                  to the next section. Otherwise, you may add debts below.
                </p>
              }
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                In order to file your bankruptcy, we need to know about all your
                debts. Please provide us with information about any debts
                relating to medical bills. This can include but is not limited
                to doctor&rsquo;s visits, hospital stays, rehabilitation, and
                prescription drugs.
              </p>
              <StatelessList
                {...props}
                emptyText="You haven’t added any medical debts yet."
                addButtonText="Add a debt"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(d) => (
                  <div>
                    {formatErrorsFromSchema(MedicalDebtSchema, d)}
                    <strong>
                      Medical debt {d.accountNumber} from {d.providerName}
                    </strong>
                    {!!d.cosigners.length && (
                      <div className="supporting-text">
                        Cosigned with{" "}
                        {formatOxfordComma(...d.cosigners.map(formatName))}
                      </div>
                    )}
                    {!!d.collectors.length && (
                      <div className="supporting-text">
                        {formatQuantity(d.collectors.length, " debt collector")}
                      </div>
                    )}
                  </div>
                )}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
