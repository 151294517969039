import { useQuery } from "@apollo/client";
import { useDeleteOtherDebt } from "api/graphql/OtherDebt";
import { ListViewProps } from "components/EditCreateList/stateless";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { OtherDebtSchema } from "components/FormModules/Liabilities/OtherDebts/OtherDebtWizard";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  formatErrorsFromSchema,
  formatName,
  formatQuantity,
} from "utils/formatters";
import { useNested } from "utils/hooks";
import OTHER_DEBTS_MODULE from "./OtherDebtsModule.gql";

export default function ListView(props: ListViewProps) {
  const { data, loading } = useQuery<OtherDebtsModule>(OTHER_DEBTS_MODULE);
  const [onDelete] = useDeleteOtherDebt();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { otherDebts: items } = data;

  return (
    <>
      <Title>Your Other Debts</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoOtherDebts"
              createURL={props.createURL}
              nextURL={props.nextURL}
              addText="Add a debt"
              question={
                <p className="required">
                  <strong>
                    Do you have any debts that are not in any of the other
                    categories?
                  </strong>
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you don&rsquo;t have any unclassified
                  or other debts. If that&rsquo;s correct, you&rsquo;re ready to
                  continue to the next section. Otherwise, you may add debts
                  below.
                </p>
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                In order to file your bankruptcy, we need to know about all your
                debts. You can provide information about most common debt types
                in other sections. All other debts should be tracked here.
              </p>
              <StatelessList
                {...props}
                emptyText="You haven’t added any unclassified debts yet."
                addButtonText="Add a debt"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(d) => (
                  <div>
                    {formatErrorsFromSchema(OtherDebtSchema, d)}
                    <strong>
                      Unclassified debt {d.accountNumber} from {d.entityName}
                    </strong>
                    {!!d.cosigners.length && (
                      <div className="supporting-text">
                        Cosigned with {d.cosigners.map(formatName).join(", ")}
                      </div>
                    )}
                    {!!d.collectors.length && (
                      <div className="supporting-text">
                        {formatQuantity(d.collectors.length, " debt collector")}
                      </div>
                    )}
                  </div>
                )}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
