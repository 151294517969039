import AboutTheProcess from "components/Onboarding/AboutTheProcess";
import * as React from "react";
import * as steps from "./steps";

export default function NewFilingAboutTheProcess({
  completedURL,
}: {
  completedURL: string;
}) {
  return (
    <AboutTheProcess
      completedURL={completedURL}
      steps={[
        { component: steps.Intro, path: "/intro", title: "Overview" },
        {
          component: steps.Bankruptcy,
          path: "/understanding-bankruptcy",
          title: "Understanding bankruptcy",
        },
        {
          component: steps.GatheringInformation,
          path: "/gathering-information",
          title: "Gathering information",
        },
        { component: steps.Court, path: "/court", title: "Court" },
        {
          component: steps.Discharge,
          path: "/discharge",
          title: "Discharge",
        },

        {
          component: steps.WhatsNext,
          path: "/whats-next",
          title: "What’s next",
        },
      ]}
    />
  );
}
