import { StorageContainerStorageTypeChoice } from "globalTypes";

type Detail = {
  displayName: string;
};

export const storageContainerDetails: {
  [key in StorageContainerStorageTypeChoice]: Detail;
} = {
  [StorageContainerStorageTypeChoice.StorageUnit]: {
    displayName: "Storage unit or locker",
  },
  [StorageContainerStorageTypeChoice.DepositBox]: {
    displayName: "Safety Deposit Box",
  },
  [StorageContainerStorageTypeChoice.Other]: {
    displayName: "Other",
  },
};
