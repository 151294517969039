import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary from "components/ModuleSummary";
import React from "react";
import TAX_RETURNS_MODULE from "./TaxReturnsModule.gql";

export default function TaxReturnsSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<TaxReturnsModule>(TAX_RETURNS_MODULE, {
    returnPartialData: true,
  });

  return <ModuleSummary {...props} progress={data?.progress.taxReturns} />;
}
