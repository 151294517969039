import { BigIcon } from "components/Big";
import {
  ProgressIndicator,
  StepHeading,
  StepProps,
} from "components/Onboarding/AboutTheProcess";
import * as React from "react";
import { FaClipboardList } from "react-icons/fa";

export function GatheringInformation({
  total,
  completed,
  actions,
  ...props
}: StepProps) {
  return (
    <div {...props}>
      <BigIcon>
        <FaClipboardList />
      </BigIcon>
      <StepHeading>Gathering information</StepHeading>
      <ProgressIndicator
        total={total}
        completed={completed}
        actions={actions}
      />
      <p>
        A successful bankruptcy case begins with gathering all the information
        necessary to file for bankruptcy.
      </p>
      <p>
        The bankruptcy courts require that you provide an accurate list of all
        income sources, all valuables and household goods you possess, all debts
        you owe, and more.
      </p>
      <p>
        Gathering this information can be hard, so Lexria has made it as easy as
        filing your taxes. We use simple, easy to understand forms, and also
        provide helpful integrations you can choose to use that can pull data
        from your employer, bank, and lenders.
      </p>
      <p>
        <strong>
          It is important to remember that bankruptcy is a legal process - you
          will need to provide accurate information to the best of your ability.
        </strong>{" "}
        Reliable and accurate information allows your lawyer to make your
        bankruptcy experience as easy as possible.
      </p>
    </div>
  );
}
