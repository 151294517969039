import { BigIcon } from "components/Big";
import { AnimationControls, motion, useAnimation } from "framer-motion";
import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { breakpoints, useBreakpoint } from "utils/hooks";
import css from "./styles.module.scss";

export interface MilestoneProps {
  icon: React.ReactNode;
  children?: React.ReactNode;
  header?: React.ReactNode;
  onVisible?: () => any;
  connector?: AnimationControls;
  forceVisible?: boolean;
}

export function Milestone({
  icon,
  children,
  header,
  onVisible,
  connector,
  forceVisible = false,
}: MilestoneProps) {
  const isMobile = useBreakpoint(breakpoints.mobile);
  const [isVisible, setIsVisible] = React.useState(forceVisible ? true : false);
  const handleVisibilityChange = React.useCallback((visible) => {
    if (visible && !isVisible) {
      setIsVisible(true);
      onVisible?.();
    }
  }, []);

  const introAnimation = useAnimation();

  React.useEffect(() => {
    if (isVisible) {
      introAnimation.start("visible");
    }
  }, [isVisible, introAnimation]);

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility={isMobile}
      active={!forceVisible}
    >
      <motion.div
        className={css.Milestone}
        style={{ visibility: isVisible ? "initial" : "hidden" }}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={introAnimation}
      >
        <div className={css.Milestone__Icon}>
          <motion.div
            className={css.connector}
            initial="connectorHidden"
            animate={connector}
            variants={{
              connectorHidden: { maxHeight: 0 },
              connectorVisible: { maxHeight: "100%" },
            }}
          />
          <svg
            viewBox="0 0 36 36"
            style={{ position: "absolute", top: 0, left: 0 }}
          >
            <motion.path
              d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
              fill="#fff"
              stroke="var(--primary)"
              variants={{
                hidden: {
                  pathLength: 0,
                  strokeWidth: isMobile ? 1.3 : 0.7, // for some reason this has to be set here, looks like a bug
                },
                visible: {
                  pathLength: 1,
                  transition: {
                    type: "tween",
                    duration: 0.3,
                  },
                },
              }}
              initial="hidden"
              animate={introAnimation}
            />
          </svg>
          <BigIcon className={css.icon}>{icon}</BigIcon>
        </div>
        <div className={css.Milestone__Content}>
          {header && (
            <motion.div
              variants={{
                hidden: { marginTop: 30 },
                visible: { marginTop: 0 },
              }}
              initial="hidden"
              animate={introAnimation}
            >
              {header}
            </motion.div>
          )}
          {children && (
            <motion.div
              variants={{
                hidden: { opacity: 0 },
                visible: {
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                  },
                },
              }}
              initial="hidden"
              animate={introAnimation}
            >
              {children}
            </motion.div>
          )}
        </div>
      </motion.div>
    </VisibilitySensor>
  );
}

export function useMilestoneConnector(): [
  AnimationControls,
  () => Promise<any>
] {
  const animation = useAnimation();
  const startAnimation = React.useCallback(
    () => animation.start("connectorVisible"),
    [animation]
  );
  return React.useMemo(
    () => [animation, startAnimation],
    [animation, startAnimation]
  );
}
