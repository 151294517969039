import {
  OtherIncomeCategoryChoice,
  OtherIncomeFrequencyChoice,
} from "globalTypes";
import { find } from "lodash";
import {
  FaBaby,
  FaCalendarAlt,
  FaChartLine,
  FaDollarSign,
  FaHandsHelping,
  FaHome,
  FaPiggyBank,
  FaPizzaSlice,
  FaUserClock,
  FaWheelchair,
} from "react-icons/fa";
import { GiBigDiamondRing } from "react-icons/gi";
import { IconBaseProps } from "react-icons/lib/cjs";

type IncomeType = {
  label: string;
  value: OtherIncomeCategoryChoice;
  icon: React.FC<IconBaseProps>;
};

export const incomeTypes: IncomeType[] = [
  {
    label: "Unemployment",
    value: OtherIncomeCategoryChoice.Unemployment,
    icon: FaHandsHelping,
  },
  {
    label: "Child Support",
    value: OtherIncomeCategoryChoice.ChildSupport,
    icon: FaBaby,
  },
  {
    label: "Food Stamps",
    value: OtherIncomeCategoryChoice.FoodStamps,
    icon: FaPizzaSlice,
  },
  {
    label: "Alimony",
    value: OtherIncomeCategoryChoice.Alimony,
    icon: GiBigDiamondRing,
  },
  {
    label: "Capital Gains",
    value: OtherIncomeCategoryChoice.CapitalGains,
    icon: FaChartLine,
  },
  {
    label: "Real Estate",
    value: OtherIncomeCategoryChoice.RealEstate,
    icon: FaHome,
  },
  {
    label: "Pension or Retirement",
    value: OtherIncomeCategoryChoice.Pension,
    icon: FaPiggyBank,
  },
  {
    label: "Disability or Worker’s Comp",
    value: OtherIncomeCategoryChoice.Disability,
    icon: FaWheelchair,
  },
  {
    label: "Insurance Payouts or Annuity",
    value: OtherIncomeCategoryChoice.Annuity,
    icon: FaCalendarAlt,
  },
  {
    label: "Money Owed to You",
    value: OtherIncomeCategoryChoice.MoneyOwed,
    icon: FaUserClock,
  },
  {
    label: "Other",
    value: OtherIncomeCategoryChoice.Other,
    icon: FaDollarSign,
  },
];

export function getTypeByValue(searchValue: string) {
  return find(incomeTypes, ({ value }) => value === searchValue);
}

export const otherIncomeFrequencies: {
  [K in OtherIncomeFrequencyChoice]: string;
} = {
  ONE_TIME: "One-time",
  WEEKLY: "Weekly",
  BIWEEKLY: "Biweekly",
  MONTHLY: "Monthly",
  BI_ANNUALLY: "Bi-annually",
  ANNUALLY: "Annually",
};
