import { BooleanInput } from "components/BooleanInput";
import { Wizard } from "components/Wizard";
import { format } from "date-fns";
import React, { useCallback } from "react";
import { FaArrowRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { getDateInterval } from "utils/dates";
import { formatGraphAddress } from "utils/formatters";
import { formatDateIntervalDuration } from "utils/formatters/dates";
import { formatNumberToWords } from "utils/formatters/numbers";
import { updateQueryParams } from "utils/urls";
import { boolean, object } from "yup";

export interface Props {
  mailingAddress: Address;
  requiredFrom: Date;
  backURL: string;
  createURL: string;
}

export default function MailingAddressQuestionWizard({
  mailingAddress,
  requiredFrom,
  backURL,
  createURL,
}: Props) {
  const history = useHistory();
  const previousTimePeriod = formatDateIntervalDuration(
    getDateInterval(requiredFrom),
    { formatNumber: formatNumberToWords }
  );

  return (
    <>
      <Wizard
        nextURL={updateQueryParams(createURL, {
          addressId: mailingAddress.id,
          residentFrom: format(requiredFrom, "yyyy-MM-dd"),
        })}
        backURL={backURL}
        abortURL={null}
        saveText="Next"
        saveIcon={<FaArrowRight />}
        hideNavigation
        steps={[
          {
            title: "Current Residence",
            component: useCallback(
              function ResidenceAndMailingSame() {
                return (
                  <>
                    <BooleanInput
                      label={
                        <>
                          Do you currently live at{" "}
                          <strong>
                            {formatGraphAddress(mailingAddress, true)}
                          </strong>
                          ?
                        </>
                      }
                      name="livesAtMailingAddress"
                    />
                  </>
                );
              },
              [mailingAddress]
            ),
            path: "/residence-and-mailing-same",
            schema: object({
              livesAtMailingAddress: boolean().required(
                "This information is required."
              ),
            }).required(),
            onSave: async (data) => {
              if (!data.livesAtMailingAddress) {
                history.push(
                  updateQueryParams(createURL, { "create-address": "true" })
                );
                return;
              }
              return data;
            },
            navigateAfterSave: true,
          },
          {
            title: "How long have you lived here?",
            component: useCallback(
              function ResidenceDuration() {
                return (
                  <>
                    <BooleanInput
                      label={
                        <>
                          Have you lived at{" "}
                          {formatGraphAddress(mailingAddress, true)} for{" "}
                          <strong>{previousTimePeriod} or longer</strong>?
                        </>
                      }
                      name="hasLivedForDuration"
                    />
                  </>
                );
              },
              [mailingAddress, previousTimePeriod]
            ),
            path: "/residence-duration",
            schema: object({
              hasLivedForDuration: boolean().required(
                "This information is required."
              ),
            }).required(),
            onSave: async (data) => {
              if (!data.hasLivedForDuration) {
                history.push(
                  updateQueryParams(createURL, { addressId: mailingAddress.id })
                );
                return;
              }
              return data;
            },
          },
        ]}
      />
    </>
  );
}
