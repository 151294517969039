import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import FINANCIAL_ACCOUNT_MODULE from "./FinancialAccountModule.gql";

export default function FinancialAccountsSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<FinancialAccountModule>(FINANCIAL_ACCOUNT_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.financialAccounts}>
      {data?.filingProfile?.hasNoFinancialAccounts ? (
        <HasNoSummary>
          Your household doesn&rsquo;t have any financial accounts.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="account"
            maxValueLength={2}
            value={data?.financialAccounts?.length}
            pluralizeLabel
          />
          <NumericSummary
            label="total balance"
            valuePrefix="$"
            maxValueLength={7}
            value={fancyNumberSum(data?.financialAccounts, "currentValue")}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
