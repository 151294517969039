import MailingAddressSubmodule from "components/FormModules/Addresses/MailingAddress";
import ResidentialAddressSubmodule from "components/FormModules/Addresses/ResidentialAddress";
import Education from "components/FormModules/Education";
import SubMenuModule from "components/FormModuleSubMenu";
import React from "react";
import { FaUsers } from "react-icons/fa";
import AboutYou from "./AboutYou";
import OtherFamilyMembers from "./OtherFamilyMembers";
import Spouse from "./Spouse";
import YourHouseholdSummary from "./Summary";
import ChildSupport from "./ChildSupport";

interface YourHouseholdProps {
  next?: string;
  prev?: string;
  subMenuContainerID?: string;
}

const yourHouseholdModules = [
  AboutYou,
  Spouse,
  ResidentialAddressSubmodule,
  MailingAddressSubmodule,
  OtherFamilyMembers,
  Education,
  ChildSupport,
];
export const yourHouseholdStatusNames = yourHouseholdModules.map(
  (m) => m.statusName
);

export function YourHousehold({
  subMenuContainerID,
  prev,
}: YourHouseholdProps) {
  return (
    <SubMenuModule
      subMenuContainerID={subMenuContainerID}
      dashboardURL={prev}
      title="About You"
      Index={YourHouseholdSummary}
      subModules={yourHouseholdModules}
    />
  );
}

YourHousehold.Icon = FaUsers;
