import { AddressForm, AddressSchema } from "components/FormSections/Address";
import { Select } from "components/Select";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import { StorageContainerStorageTypeChoice } from "globalTypes";
import * as React from "react";
import { mixed, object, string } from "yup";
import { storageContainerDetails } from "../utils";

export const DetailsSchema = object({
  storageType: mixed<StorageContainerStorageTypeChoice>()
    .required("Please select a storage type.")
    .oneOf(
      Object.values(StorageContainerStorageTypeChoice),
      "Select a valid storage type."
    ),
  entityName: string().required(
    "Please enter the name of the business you are storing things with."
  ),
  entityAddress: AddressSchema.required(),
  thirdPartyIdentifier: string().required(
    "Enter the identifier of the storage unit."
  ),
});

export function Details({ navProps, isEdit }: WizardRouteChildProps) {
  const options = React.useMemo(
    () =>
      Object.entries(storageContainerDetails).map(([key, value]) => ({
        value: key,
        label: value.displayName,
      })),
    []
  );

  return (
    <>
      <Select
        name="storageType"
        options={options}
        label="What type of storage is this?"
        autoFocus={!isEdit}
      />
      <TextInput name="entityName" label="Business name" />
      <TextInput
        name="thirdPartyIdentifier"
        label="Unit name, number, or other identifier"
      />
      <AddressForm name="entityAddress" graphQL />
      <WizardNavigation {...navProps} />
    </>
  );
}
