import { useUpsertEnvironmentalCleanupNotice } from "api/graphql/EnvironmentalCleanupNotice";
import { Wizard } from "components/Wizard";
import React from "react";
import { Switch } from "react-router-dom";
import { Notice, NoticeSchema } from "./Notice";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
}

export const EnvironmentalCleanupNoticeSchema = NoticeSchema;

export function EnvironmentalCleanupNoticeWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: Props) {
  const [upsertEnvironmentalCleanupNotice] =
    useUpsertEnvironmentalCleanupNotice();

  return (
    <>
      <Switch>
        <Wizard
          title={title}
          backURL={backURL}
          nextURL={nextURL}
          initialValue={initialValue}
          vertical
          steps={[
            {
              path: "/notice",
              title: "Notice",
              component: Notice,
              schema: NoticeSchema,
              async onSave(formState) {
                const { data } = await upsertEnvironmentalCleanupNotice(
                  formState
                );
                return data.environmentalCleanupNotice;
              },
            },
          ]}
        />
      </Switch>
    </>
  );
}
