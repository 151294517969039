import { useUpsertBankruptcyCase } from "api/graphql/BankruptcyCase";
import { CofilerForm } from "components/FormSections/Cofiler";
import { Wizard, WizardProps } from "components/Wizard";
import * as React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import { CaseDetails, CaseDetailsSchema } from "./CaseDetails";
import { AddCofiler, AddCofilerSchema } from "./Cofiler";
import { Timeline, TimelineSchema } from "./Timeline";

export const BankruptcyCaseSchema = CaseDetailsSchema.concat(
  TimelineSchema
).concat(AddCofilerSchema);

export function BankruptcyCaseWizard(
  props: Omit<
    WizardProps<ReturnType<typeof BankruptcyCaseSchema.validateSync>>,
    "steps" | "onSave"
  >
) {
  const history = useHistory();
  const [upsertBankruptcyCase] = useUpsertBankruptcyCase();
  const { url, path } = useNested();
  const [bankruptcyCaseId, setBankruptcyCaseId] = React.useState(
    (props.initialValue as any)?.id
  );
  const cofilersPath = "/cofilers";

  return (
    <Switch>
      <Route
        path={path("/create_cofiler")}
        render={() => (
          <CofilerForm
            title="Add a Bankruptcy Case Co-filer"
            returnURL={url(cofilersPath)}
            relatedId={bankruptcyCaseId}
            relatedTypename="BankruptcyCase"
          />
        )}
      />
      <Route
        path={path("/edit_cofiler/:cofilerId")}
        render={({ match }) => (
          <CofilerForm
            title="Updating Bankruptcy Case Co-filer"
            returnURL={url(cofilersPath)}
            relatedId={bankruptcyCaseId}
            relatedTypename="BankruptcyCase"
            id={match.params.cofilerId}
          />
        )}
      />
      <Wizard
        {...props}
        vertical
        steps={[
          {
            path: "/case-details",
            title: "Case details",
            component: CaseDetails,
            schema: CaseDetailsSchema,
          },
          {
            path: "/timeline",
            title: "Timeline",
            component: Timeline,
            schema: TimelineSchema,
            async onSave(formState) {
              const updatedCase = {
                ...formState,
                humanReviewed: true,
                inRepayment:
                  formState.chapter === 7 ? false : formState.inRepayment,
              };

              const { data } = await upsertBankruptcyCase(updatedCase);

              if (!data) {
                return formState;
              }

              if (props.isEdit) {
                history.push(url(cofilersPath));
              } else {
                setBankruptcyCaseId(data.bankruptcyCase.id);
                history.push(`../edit/${data.bankruptcyCase.id}/cofilers`);
              }
              return data.bankruptcyCase;
            },
          },
          {
            path: cofilersPath,
            title: "Co-filers",
            component: AddCofiler,
            schema: AddCofilerSchema,
          },
        ]}
      />
    </Switch>
  );
}
