import { useUpsertOtherIncome } from "api/graphql/OtherIncome";
import { Wizard } from "components/Wizard";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import { About, AboutSchema } from "./About";
import { Documents, DocumentsSchema } from "./Documents";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  editURL?: string;
}

export const OtherIncomeSchema = AboutSchema.concat(DocumentsSchema);

export function OtherIncomeWizard({
  backURL,
  nextURL,
  initialValue,
  title,
  editURL = "edit",
}: Props) {
  const { url } = useNested();
  const [upsertOtherIncome] = useUpsertOtherIncome();
  const history = useHistory();
  const [otherIncomeId, setOtherIncomeId] = useState(initialValue?.id);

  return (
    <Wizard
      title={title}
      backURL={backURL}
      nextURL={nextURL}
      vertical
      initialValue={initialValue && OtherIncomeSchema.cast(initialValue)}
      steps={[
        {
          path: "/about",
          title: "About",
          component: About,
          schema: AboutSchema,
          async onSave(formState) {
            const { data } = await upsertOtherIncome({
              ...formState,
            });
            const { id } = data.otherIncome;
            setOtherIncomeId(id);
            if (otherIncomeId) {
              history.push(url("/documents"));
            } else {
              history.push(`../../${editURL}/${id}/documents`);
            }
            return data.otherIncome;
          },
        },
        {
          path: "/documents",
          title: "Documentation",
          component: Documents,
          schema: DocumentsSchema,
        },
      ]}
    />
  );
}
