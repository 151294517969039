import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, { HasNoSummary } from "components/ModuleSummary";
import GIG_INCOME_MODULE from "./GigIncomeModule.gql";

export default function GigIncomeSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<GigIncomeModule>(GIG_INCOME_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.gigIncome}>
      {data?.filingProfile?.hasNoGigIncome ? (
        <HasNoSummary>
          Your household hasn&rsquo;t received any gig work income in the last 6
          months.
        </HasNoSummary>
      ) : (
        <></>
      )}
    </ModuleSummary>
  );
}
