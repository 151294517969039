import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import CLEANUP_MODULE from "./EnvironmentalCleanupNoticesModule.gql";

export default function CleanupSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<EnvironmentalCleanupNoticesModule>(CLEANUP_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary
      {...props}
      progress={data?.progress.environmentalCleanupNotices}
    >
      {data?.filingProfile?.hasNoEnvironmentalCleanupNotices ? (
        <HasNoSummary>
          You&rsquo;ve never sent or received any environmental cleanup notices.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="notice"
            maxValueLength={1}
            value={data?.environmentalCleanupNotices?.length}
            pluralizeLabel
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
