import { useUpsertTaxReturn } from "api/graphql/TaxReturn";
import { OtherPersonForm } from "components/FormSections/OtherPerson";
import { Wizard } from "components/Wizard";
import { omit } from "lodash";
import { Route, Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import { parseFancyNumber } from "utils/validators";
import Cofiling, { CofilingSchema } from "./Cofiling";
import Income, { IncomeFormState, IncomeSchema } from "./Income";
import MoneyOwed, { MoneyOwedFormState, MoneyOwedSchema } from "./MoneyOwed";
import TAX_RETURN_OTHER_FILER_UPSERT from "./TaxReturnOtherFilerUpsert.gql";
import Upload, { UploadFormState, UploadSchema } from "./Upload";

export function taxReturnResponseToWizardState(
  taxReturn: Omit<ArrayItemType<TaxReturnsModule["taxReturns"]>, "taxDebt">
) {
  const { otherFiler, totalTaxesDue } = taxReturn;
  const parsedTaxesDue =
    totalTaxesDue &&
    parseFancyNumber(totalTaxesDue.toString().replace(/,/g, ""));

  const returnObj = {
    ...omit(taxReturn, "uploadFileHash"),
    returnAmount: Math.abs(parsedTaxesDue),
    returnMultiplier: totalTaxesDue
      ? parsedTaxesDue >= 0
        ? 1
        : -1
      : (undefined as -1 | 1 | undefined),
    otherFilerId: otherFiler?.id,
  };
  return returnObj;
}

export function taxReturnWizardStateToRequest(
  formValues: UploadFormState & IncomeFormState & MoneyOwedFormState
) {
  const { returnMultiplier, returnAmount } = formValues;

  return {
    ...omit(
      formValues,
      "returnMultiplier",
      "returnAmount",
      "uploadFile",
      "uploadFileHash",
      "eligibleOtherFilers"
    ),
    returnFiled: true,
    totalTaxesDue: `${returnMultiplier * returnAmount}`,
  };
}

interface Props {
  backURL: string;
  nextURL: string;
  /** The URL to switch to after the initial save. Created object ID will be appended */
  editURL?: string;
  title?: string;
  initialValue?: any;
}

export const TaxReturnSchema = UploadSchema.concat(MoneyOwedSchema)
  .concat(IncomeSchema)
  .concat(CofilingSchema)
  .transform(taxReturnResponseToWizardState);

export function TaxReturnWizard({
  backURL,
  nextURL,
  editURL,
  initialValue,
  title,
}: Props) {
  const history = useHistory();
  const { path, url } = useNested();
  const [upsertReturn] = useUpsertTaxReturn<
    TaxReturnOtherFilerUpsert,
    TaxReturnOtherFilerUpsertVariables
  >(TAX_RETURN_OTHER_FILER_UPSERT);

  return (
    <Switch>
      <Route path={path("/create_other_filer")}>
        <OtherPersonForm
          title="Add Tax Co-Filer"
          onSubmit={(otherPerson) => {
            if ("id" in otherPerson) {
              return upsertReturn({
                ...taxReturnWizardStateToRequest(initialValue),
                isCofiled: true,
                otherFilerId: otherPerson.id,
              });
            } else {
              return upsertReturn({
                ...taxReturnWizardStateToRequest(initialValue),
                isCofiled: true,
                otherFiler: otherPerson,
              });
            }
          }}
          nextURL={url("/filing_people")}
          backURL={url("/filing_people")}
        />
      </Route>

      <Wizard
        vertical
        title={title}
        backURL={backURL}
        nextURL={nextURL}
        onSave={async (formState) => {
          const { data } = await upsertReturn(
            taxReturnWizardStateToRequest(formState)
          );
          history.push(nextURL);
          return taxReturnResponseToWizardState(data.taxReturn);
        }}
        initialValue={initialValue}
        steps={[
          {
            path: "/upload_return",
            title: "Upload return",
            component: Upload,
            schema: UploadSchema,
          },
          {
            path: "/income",
            title: "Income",
            component: Income,
            schema: IncomeSchema,
          },
          {
            path: "/money_owed",
            title: "Tax debts",
            component: MoneyOwed,
            schema: MoneyOwedSchema,
            async onSave(formValues) {
              const { uploadFile } = formValues;
              const otherVars =
                uploadFile instanceof File ? { uploadFile } : {};
              const submission = taxReturnWizardStateToRequest(formValues);
              const { data } = await upsertReturn(submission, otherVars);
              history.push(
                initialValue
                  ? "filing_people"
                  : `${editURL || ""}${data.taxReturn.id}/filing_people`
              );
              return taxReturnResponseToWizardState(data.taxReturn);
            },
          },
          {
            path: "/filing_people",
            title: "Filing people",
            component: Cofiling,
            schema: CofilingSchema,
          },
        ]}
      />
    </Switch>
  );
}
