import cn from "classnames";
import * as React from "react";
import css from "./style.module.scss";

export function TaxReturnLine({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn(css.taxLine, className)} {...props}>
      {children}
    </div>
  );
}

export function LineNumber({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn(css.lineNumber, className)} {...props}>
      {children}
    </div>
  );
}

export function Description({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn(css.lineDescription, className)} {...props}>
      <span className={css.dotMask}>{children}</span>
      <div className={css.arrow}>
        <span className={css.dotMask}>▶</span>
      </div>
    </div>
  );
}

interface AmountProps extends React.HTMLAttributes<HTMLDivElement> {
  withCents?: boolean;
  cents?: number;
  dollars?: number;
  lineNumber: string;
}

export function Amount({
  children,
  className,
  lineNumber,
  dollars,
  cents,
  withCents = false,
  ...props
}: AmountProps) {
  return (
    <div className={cn(css.lineAmount, className)} {...props}>
      <div className={css.line}>{lineNumber}</div>
      <div className={css.dollars}>{dollars}</div>
      {withCents && <div className={css.cents}>{cents}</div>}
      {children}
    </div>
  );
}
