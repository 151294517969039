import { useApolloClient } from "@apollo/client";
import GET_OTHER_PERSON from "api/graphql/OtherPerson/GetOtherPerson.gql";
import { motion } from "framer-motion";
import * as React from "react";
import { UseFormMethods } from "react-hook-form";
import { useLatestResult } from "utils/hooks";

interface UseGatherMissingOtherPersonDataArgs {
  form: UseFormMethods<any>;
  testHasRequiredData?: (otherPerson?: FullOtherPerson) => Promise<boolean>;
  getMissingDataUpdateURL?: ({ id }: { id: UUID }) => string;
}

export function useGatherMissingOtherPersonData({
  form,
  testHasRequiredData,
  getMissingDataUpdateURL,
}: UseGatherMissingOtherPersonDataArgs) {
  const [enqueue] = useLatestResult();
  const id = form.watch("id");

  const [saving, setSaving] = React.useState(false);
  const [needsMoreData, setNeedsMoreData] = React.useState(false);
  const [nextURL, setNextURL] = React.useState(undefined);
  const [canContinue, setCanContinue] = React.useState(true);
  const client = useApolloClient();

  React.useEffect(() => {
    if (!id || !testHasRequiredData || !getMissingDataUpdateURL) return;

    const task = client
      .query<GetOtherPerson, GetOtherPersonVariables>({
        query: GET_OTHER_PERSON,
        variables: { id },
      })
      .then((result) => testHasRequiredData(result.data?.otherPerson));

    enqueue(task, (isComplete) => {
      if (!isComplete) {
        setNeedsMoreData(true);
        setNextURL(getMissingDataUpdateURL({ id }));
      } else {
        setNeedsMoreData(false);
        setNextURL(undefined);
      }

      setCanContinue(true);
      setSaving(false);
    });
  }, [id, testHasRequiredData, getMissingDataUpdateURL]);

  const wizardNavigationProps = React.useMemo(
    () => ({
      isComplete: !needsMoreData,
      canContinue,
      saving,
      nextURL,
      nextIsSubmit: nextURL === undefined, // this isn't strictly needed, just for our old friend jsdom
    }),
    [needsMoreData, canContinue, saving, nextURL]
  );

  const messaging = (
    <motion.div
      variants={{
        moreData: { opacity: 1, marginTop: 20, height: "auto" },
        noMoreData: {
          opacity: 0,
          marginTop: 0,
          height: 0,
          transition: { duration: 0.1 },
        },
      }}
      animate={needsMoreData ? "moreData" : "noMoreData"}
      initial="noMoreData"
    >
      <strong>There’s a bit more we need to know about this person.</strong>{" "}
      We’ll guide you through entering the information we need.
    </motion.div>
  );

  return React.useMemo(
    () =>
      [wizardNavigationProps, messaging] as [
        typeof wizardNavigationProps,
        typeof messaging
      ],
    [wizardNavigationProps, messaging]
  );
}
