import { useFilingProfileBooleanMutation } from "api/graphql/FilingProfile";
import { useGetSession } from "api/graphql/Session";
import cn from "classnames";
import { BigIcon } from "components/Big";
import { Card } from "components/Card";
import { Loading } from "components/Loading";
import { HTMLMotionProps, motion } from "framer-motion";
import * as React from "react";
import { FaAward } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import css from "./styles.module.scss";

export interface WelcomeContentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  completedURL: string;
  aboutTheProcessURL: string;
}

export default function NewFilingWelcome({
  completedURL,
  aboutTheProcessURL,
  ...props
}: WelcomeContentProps) {
  const history = useHistory();
  const { data: sessionData } = useGetSession();

  const [markWelcomeCompleted] = useFilingProfileBooleanMutation(
    "hasCompletedNewFilingWelcome"
  );

  const onComplete = (nextURL: string) => {
    markWelcomeCompleted(true);
    history.push(nextURL);
  };

  if (!sessionData) return <Loading />;

  const { firstName } = sessionData.session.user;

  return (
    <Card {...props} className={cn(props.className, css.Welcome)}>
      <BigIcon<HTMLMotionProps<"div">>
        Wrapper={motion.div}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring", stiffness: 350, damping: 18 }}
      >
        <FaAward />
      </BigIcon>
      <h1>Welcome{!!firstName && `, ${firstName}`}!</h1>
      <p>
        <strong>
          We’re here to help you on your journey towards financial freedom
        </strong>
        .
      </p>
      <p>
        First, we need to know some more about your family, income, education,
        property, and debts. Completing our questionnaire will take a few hours
        and is comparable to filing your taxes.
      </p>
      <p>
        Don’t worry, you don’t need to finish everything at once. We’ll guide
        you through all the steps. You can get started now by providing the
        information you have available.
      </p>
      <button
        className="btn primary"
        onClick={() => onComplete(aboutTheProcessURL)}
      >
        Learn about bankruptcy
      </button>
      <button className="btn flat" onClick={() => onComplete(completedURL)}>
        Get started
      </button>
    </Card>
  );
}
