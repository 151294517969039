import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary from "components/ModuleSummary";
import LinesSummary from "components/ModuleSummary/LinesSummary";
import LIST_MISC_DOCUMENTS from "./MiscDocuments.gql";

export default function MiscDocumentsSummary(props: SubModuleSummaryProps) {
  const { data, loading } = useQuery<ListMiscDocuments>(LIST_MISC_DOCUMENTS, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress?.miscDocuments}>
      <LinesSummary
        requiredData={data?.filingPerson}
        maxLines={1}
        maxWidth="15em"
        style={{ textAlign: "center" }}
      >
        {`You have uploaded ${data?.filingPerson?.miscDocuments?.length || 0}
        additional documents`}
      </LinesSummary>
    </ModuleSummary>
  );
}
