import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useCheckMounted } from "utils/hooks";

function ControlledHiddenInput({
  controlledValue,
  onChange,
  name,
}: {
  onChange: (value: any) => any;
  controlledValue: any;
  name: string;
}): null {
  const checkMounted = useCheckMounted();
  const form = useFormContext();
  const formValue = form.watch(name);

  useEffect(() => {
    if (
      controlledValue === undefined ||
      controlledValue === formValue ||
      !checkMounted()
    )
      return;

    // setTimeout is necessary because for some reason react-hook-form
    // will clobber the onChange with the defaultValue unless it's delayed.
    const timeout = setTimeout(() => {
      if (!checkMounted()) return;

      onChange(controlledValue);
    }, 0);
    return () => clearTimeout(timeout);
  }, [controlledValue, formValue]);

  return null;
}

/** A way to set form values without any UI.
 *
 * When used with react-hook-form, any type of value can be used.
 * Otherwise, only strings can be used for the value.
 */
export function HiddenInput(
  props: Omit<React.InputHTMLAttributes<HTMLInputElement>, "value" | "name"> & {
    value?: any;
    name: string;
  }
) {
  const form = useFormContext();
  const { value, name } = props;

  return form ? (
    <Controller
      name={name}
      render={({ onChange }) => (
        <ControlledHiddenInput
          name={name}
          controlledValue={value}
          onChange={onChange}
        />
      )}
    />
  ) : (
    <input type="hidden" {...props} />
  );
}
