import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router";
import { Form } from "components/Form";
import { Title } from "components/Title";
import { TextArea } from "components/TextArea";
import { BooleanInput } from "components/BooleanInput";
import { WizardNavigation } from "components/WizardNavigation";
import {
  useGetChildSupport,
  useUpsertChildSupport,
} from "api/graphql/ChildSupport";

import { Loading } from "components/Loading";
import { object, string, boolean } from "yup";

export interface Props {
  prev: string;
  next: string;
}

export const ChildSupportSchema = object({
  pays: boolean().required(),
  description: string().when("pays", {
    is: true,
    then: string().required(
      "Please describe your child support payments as noted above."
    ),
    otherwise: string().nullable(),
  }),
}).required();

const defaultValues = {
  pays: null,
  description: null,
};

export function ChildSupport({ prev, next }: Props) {
  const history = useHistory();
  const [upsertChildSupport] = useUpsertChildSupport();
  const { data, loading } = useGetChildSupport();
  if (loading) {
    return <Loading />;
  }

  return (
    <Form
      schema={ChildSupportSchema}
      onSubmit={async (formState) => {
        const { data } = await upsertChildSupport(formState);
        if (next) {
          history.push(next);
        }
      }}
      initialValue={data?.childSupport ? data.childSupport : defaultValues}
    >
      <ChildSupportForm />
      <WizardNavigation backURL={prev} isComplete={true} />
    </Form>
  );
}

function ChildSupportForm() {
  const { watch } = useFormContext();
  const watchPays = watch("pays", false);
  return (
    <>
      <Title>Child Support</Title>
      <BooleanInput name="pays" label="Do you pay child support?" />
      {watchPays && (
        <div className="mt-8">
          <div className="font-semibold text-md">
            <div>
              For each person to whom you pay child support, please list the
              following:
            </div>
            <ul>
              <li>Full name</li>
              <li>
                Last known address of the adult the child currently lives with
              </li>
              <li>Whether the child support is court-ordered</li>
            </ul>
          </div>
          <TextArea name="description" label="Description" rows={6} />
        </div>
      )}
    </>
  );
}
