import { useGetStudentLoan } from "api/graphql/StudentLoan";
import { Loading } from "components/Loading";
import * as React from "react";
import { Redirect } from "react-router-dom";
import StudentLoanWizard from "../StudentLoanWizard";

interface Props {
  id: string;
  listURL: string;
}

export default function EditView({ id, listURL }: Props) {
  const { data, loading } = useGetStudentLoan(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.studentLoan) {
    return <Redirect to={listURL} />;
  }

  const { studentLoan } = data;

  return (
    <StudentLoanWizard
      title={`Updating Imported Loan`}
      backURL={listURL}
      nextURL={listURL}
      initialValue={studentLoan}
    />
  );
}
