const supportsCSSVariables = CSS?.supports?.("color", "var(--fake-var");

export default {
  /** Will return the corresponding CSS variable if supported, otherwise undefined.
   *
   * Used to silence non-animatable warnings from framer-motion
   */
  color(color: string) {
    if (supportsCSSVariables) return `var(--${color})`;
    return undefined;
  },
};
