import { useGetMonthlyExpense } from "api/graphql/MonthlyExpense";
import { MonthlyExpenseWizard } from "components/FormModules/Expenses/HouseholdExpenses/MonthlyExpenseWizard";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import { ExpensesCategoryDetail } from "./CategoryDetail";

interface Props {
  id: string;
  listURL: string;
  categoryDetail: ExpensesCategoryDetail;
}

export default function EditView(props: Props) {
  const { data, loading } = useGetMonthlyExpense(props.id);

  if (loading) {
    return <Loading />;
  } else if (!data?.monthlyExpense) {
    return <Redirect to={props.listURL} />;
  }

  return (
    <MonthlyExpenseWizard
      category={props.categoryDetail.category}
      title={`Updating ${props.categoryDetail.displayName} Expense`}
      subtitle={props.categoryDetail.singleInterrogativeText}
      backURL={props.listURL}
      nextURL={props.listURL}
      initialValue={data.monthlyExpense}
    />
  );
}
