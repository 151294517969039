import { object, string } from "yup";

export const AddressSchema = object({
  line1: string().required("Line 1 is required."),
  line2: string().optional().nullable(),
  city: string().required("City is required."),
  state: object({
    abbr: string().required("State is required."),
  }).required(),
  zipCode: string()
    .matches(/^(\d\d\d\d\d)?$/, "Please enter a valid 5-digit ZIP code.")
    .required("ZIP code is required."),
});

export const RestAddressSchema = object({
  line_1: string().required("Line 1 is required."),
  line_2: string().optional().nullable(),
  city: string().required("City is required."),
  state: string().required("State is required."),
  zip_code: string()
    .matches(/^(\d\d\d\d\d)?$/, "Please enter a valid 5-digit ZIP code.")
    .required("ZIP code is required."),
});

export type AddressFormState = ReturnType<
  typeof RestAddressSchema.validateSync
>;

export type GraphAddressFormState = ReturnType<
  typeof AddressSchema.validateSync
>;
