import { ApolloProvider } from "@apollo/client";
import { client } from "api/graphql/client";
import { ErrorBoundary } from "components/ErrorBoundary";
import ReactDom from "react-dom";
import { MantineProvider } from "@mantine/core";
import "utils/validators/yup"; // Ensure that custom yup methods are always available
import App from "./containers/App";
import "./css/bundles/react.scss";
import jqueryStuff from "./jquery_gulag";
import initNotifications from "./notifications";

window.addEventListener("DOMContentLoaded", () => {
  jqueryStuff();
});

const root = document.getElementById("react-root");
if (root) {
  ReactDom.render(
    <MantineProvider>
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </ErrorBoundary>
    </MantineProvider>,
    root
  );
}

initNotifications();
