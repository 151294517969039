import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_ASSET_SALE from "./DeleteAssetSale.gql";
import GET_ASSET_SALE from "./GetAssetSale.gql";
import LIST_ASSET_SALE from "./ListAssetSale.gql";
import UPSERT_ASSET_SALE from "./UpsertAssetSale.gql";

export * from "./Buyer";

const LIST_ASSET_SALE_IDS = gql`
  query ListAssetSaleIds {
    assetSales {
      id
    }
  }
`;

export function useListAssetSale(options?: QueryHookOptions<ListAssetSale>) {
  return useQuery<ListAssetSale>(LIST_ASSET_SALE, options);
}

export function useGetAssetSale(id: string) {
  return useQuery<GetAssetSale, GetAssetSaleVariables>(GET_ASSET_SALE, {
    variables: { id },
  });
}

export function useUpsertAssetSale<
  Schema extends { assetSale: any } = UpsertAssetSale,
  Variables = UpsertAssetSaleVariables
>(query = UPSERT_ASSET_SALE) {
  return useUpsert<Schema, Variables, ListAssetSaleIds, AssetSaleInput>(
    query,
    LIST_ASSET_SALE_IDS,
    "assetSale",
    "assetSales",
    (cache) => updateFilingProfileCache(cache, "hasNoAssetSales", false)
  );
}

export function useDeleteAssetSale() {
  return useDelete<DeleteAssetSale, ListAssetSaleIds>(
    DELETE_ASSET_SALE,
    LIST_ASSET_SALE_IDS,
    "deleteAssetSale",
    "assetSales"
  );
}
