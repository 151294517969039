import { useQuery } from "@apollo/client";
import { ButtonRow } from "components/ButtonRow";
import { Card } from "components/Card";
import { ListViewProps } from "components/EditCreateList/stateless";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import { startOfToday, subYears } from "date-fns";
import { TaxReturnTypeChoice } from "globalTypes";
import { find, uniq } from "lodash";
import React from "react";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import ReturnBlock from "./ReturnBlock";
import css from "./TaxReturns.module.css";
import TAX_RETURNS_MODULE from "./TaxReturnsModule.gql";

export function ListView({ createURL, onEdit, nextURL }: ListViewProps) {
  const { data, loading } = useQuery<TaxReturnsModule>(TAX_RETURNS_MODULE);

  const requiredYears = [
    subYears(startOfToday(), 1).getFullYear(),
    subYears(startOfToday(), 2).getFullYear(),
  ];

  const optionalYears = [subYears(startOfToday(), 3).getFullYear()];

  if (loading) return <Loading />;
  const { taxReturns } = data;

  const returnYears = uniq(
    taxReturns
      .map((r) => r.returnYear)
      .filter(
        (year) =>
          !(requiredYears.includes(year) || optionalYears.includes(year))
      )
  )
    .sort()
    .reverse();

  return (
    <div>
      <Title>Tax returns</Title>

      <p>
        In order to file your bankruptcy, we need to know a bit about your
        recent tax returns, as well as any tax years for which you owe
        outstanding tax debts.
      </p>
      <p>
        Please complete the applicable sections below for the federal, state,
        and local level. If you didn&rsquo;t file taxes for a given year or
        don&rsquo;t have to file state or local taxes, simply press
        &ldquo;Didn&rsquo;t file&rdquo;.
      </p>
      <p>
        <span className="font-bold">NOTE:</span> You can submit either your tax
        returns or any tax transcripts you may have received from the IRS.{" "}
      </p>

      <ButtonRow
        right={
          <Link to={nextURL} className="primary btn">
            <span>Done</span>
            <FaCheck />
          </Link>
        }
      />

      {requiredYears.map((requiredYear) => (
        <Card className={css.returnRow} key={`required${requiredYear}`}>
          <h2>{requiredYear}</h2>
          {[
            TaxReturnTypeChoice.FederalIncome,
            TaxReturnTypeChoice.StateIncome,
            TaxReturnTypeChoice.LocalIncome,
          ].map((type) => (
            <ReturnBlock
              key={type}
              type={type}
              year={requiredYear}
              createURL={createURL}
              onEdit={onEdit}
              taxReturn={find(
                taxReturns,
                ({ returnYear, _type }) =>
                  returnYear === requiredYear && _type === type
              )}
            />
          ))}
        </Card>
      ))}

      <p className="mt-12 mb-8">
        In some cases, your attorney may require additional tax returns beyond
        those for the prior 2 years. If asked to do so, please include those
        here.
      </p>

      {optionalYears.map((year) => (
        <Card className={css.returnRow} key={`required${year}`}>
          <h2>{year}</h2>
          {[
            TaxReturnTypeChoice.FederalIncome,
            TaxReturnTypeChoice.StateIncome,
            TaxReturnTypeChoice.LocalIncome,
          ].map((type) => (
            <ReturnBlock
              key={type}
              type={type}
              year={year}
              createURL={createURL}
              onEdit={onEdit}
              taxReturn={find(
                taxReturns,
                ({ returnYear, _type }) => returnYear === year && _type === type
              )}
            />
          ))}
        </Card>
      ))}

      {returnYears.map((year) => (
        <Card className={css.returnRow} key={year}>
          <h2>{year}</h2>
          {taxReturns
            .filter((r) => r.returnYear === year)
            .map((r) => (
              <ReturnBlock
                key={r.id}
                type={r._type}
                year={year}
                createURL={createURL}
                onEdit={onEdit}
                taxReturn={r}
              />
            ))}
        </Card>
      ))}
    </div>
  );
}
