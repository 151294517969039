import { useUpsertConsultation } from "api/graphql/Consultation";
import { Wizard } from "components/Wizard";
import React from "react";
import { Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import { Consultant, ConsultantSchema } from "./Consultant";
import { Payments, PaymentsSchema } from "./Payments";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  /** The URL to switch to after the initial save. Created object ID will be appended */
  editURL?: string;
}

export const ConsultationSchema = ConsultantSchema.concat(PaymentsSchema);

export function ConsultationWizard({
  backURL,
  nextURL,
  initialValue,
  title,
  editURL = "edit",
}: Props) {
  const [upsertConsultation] = useUpsertConsultation();
  const history = useHistory();
  const [consultationId, setConsultationId] = React.useState(
    initialValue?.id || null
  );
  const { url } = useNested();

  return (
    <>
      <Switch>
        <Wizard
          title={title}
          backURL={backURL}
          nextURL={nextURL}
          initialValue={initialValue}
          vertical
          steps={[
            {
              path: "/consultant",
              title: "Details",
              component: Consultant,
              schema: ConsultantSchema,
              async onSave(formState) {
                const { data } = await upsertConsultation(formState);
                setConsultationId(data.consultation.id);
                setTimeout(() => {
                  if (consultationId) {
                    history.push(url("/payments"));
                  } else {
                    history.push(
                      `../${editURL}/${data.consultation.id}/payments`
                    );
                  }
                }, 0);
                return data.consultation;
              },
            },
            {
              path: "/payments",
              title: "Payments",
              component: Payments,
              schema: PaymentsSchema,
              includeSubpaths: true,
            },
          ]}
        />
      </Switch>
    </>
  );
}
