import { ControlledFileInput } from "components/FileInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import { last } from "lodash";
import { FaCheckCircle } from "react-icons/fa";
import { mixed, object } from "yup";

export const UploadSchema = object({
  uploadMortgage: mixed<File | UploadedFile>().nullable(),
  uploadDeed: mixed<File | UploadedFile>().nullable(),
  uploadPropertyTax: mixed<File | UploadedFile>().nullable(),
}).required();

export default function Upload({ form, navProps }: WizardRouteChildProps) {
  const uploadDeed = form.watch("uploadDeed");
  const uploadMortgage = form.watch("uploadMortgage");
  const uploadPropertyTax = form.watch("uploadPropertyTax");
  return (
    <>
      <h3>Mortgage</h3>
      <p>Please upload a copy of the recorded mortgage for this property.</p>
      <ControlledFileInput
        name="uploadMortgage"
        fileName={last(uploadMortgage?.name.split("/")) || undefined}
        downloadUrl={uploadMortgage?.url || undefined}
        multiple={false}
        Icon={uploadMortgage && FaCheckCircle}
        helpText={last(uploadMortgage?.name.split("/")) || undefined}
      />
      <h3 className="mt-12">Deed</h3>
      <p>Please upload a copy of the recorded deed for this property.</p>
      <ControlledFileInput
        name="uploadDeed"
        fileName={last(uploadDeed?.name.split("/")) || undefined}
        downloadUrl={uploadDeed?.url || undefined}
        multiple={false}
        Icon={uploadDeed && FaCheckCircle}
        helpText={last(uploadDeed?.name.split("/")) || undefined}
      />
      <h3 className="mt-12">Property Tax Bill</h3>
      <p>
        Please upload a copy of the most recent property tax bill for this
        property.
      </p>
      <ControlledFileInput
        name="uploadPropertyTax"
        fileName={last(uploadPropertyTax?.name.split("/")) || undefined}
        downloadUrl={uploadPropertyTax?.url || undefined}
        multiple={false}
        Icon={uploadPropertyTax && FaCheckCircle}
        helpText={last(uploadPropertyTax?.name.split("/")) || undefined}
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
