import * as React from "react";
import { useHistory } from "react-router-dom";
import { Root, State } from "./context";
import { Indexable } from "./reducer";

export function useSetListItemMeta() {
  const [state, dispatch] = React.useContext(State);
  return React.useCallback((item: Indexable, key: string, value: any) => {
    dispatch({ type: "SET_ITEM_META", item, key, value });
  }, []);
}

export function useListRoot() {
  return React.useContext(Root);
}

export function useListView<T extends { id: UUID }>(
  onDelete?: (item: T) => Promise<any>
) {
  const [state, dispatch] = React.useContext(State);
  const history = useHistory();
  const root = useListRoot();
  const deleteItem = async (item: T) => {
    dispatch({
      type: "SET_ITEM_META",
      key: "deleting",
      value: true,
      item,
    });

    try {
      await onDelete(item);
      dispatch({ type: "DELETE_ITEM", item });
    } catch (e) {
      console.error(e);
      dispatch({
        type: "SET_ITEM_META",
        item,
        key: "deleting",
        value: false,
      });
    }
  };

  const onAdd = (item: T) => dispatch({ type: "ADD_ITEM", item });
  const onUpdate = (item: T) => dispatch({ type: "UPDATE_ITEM", item });

  return {
    items: state.index.map((id) => state.items[id]),
    createURL: `${root.url}/create`,
    nextURL: `${root.url}/next`,
    onEdit(id: UUID) {
      history.push(`${root.url}/edit/${id}`);
    },
    onDelete(item: T) {
      return deleteItem(item);
    },
    onAdd,
    onUpdate,
  };
}
