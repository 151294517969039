import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import PREPAYMENTS_MODULE from "./ServicePrepaymentsModule.gql";

export default function PrepaymentsSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<ServicePrepaymentsModule>(PREPAYMENTS_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.servicePrepayments}>
      {data?.filingProfile?.hasNoServicePrepayments ? (
        <HasNoSummary>
          You don&rsquo;t have any gift cards, pre-paid credit cards, or service
          pre-payments.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="prepayment"
            maxValueLength={2}
            value={data?.servicePrepayments?.length}
            pluralizeLabel
          />
          <NumericSummary
            label="total prepaid"
            valuePrefix="$"
            maxValueLength={4}
            value={fancyNumberSum(
              data?.servicePrepayments,
              "unusedPrepaymentAmount"
            )}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
