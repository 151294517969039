import { useState, useEffect } from "react";
import scriptjs from "scriptjs";
import css from "./ArgyleLink.module.scss";

export default function ArgyleLink({
  isOpen,
  onSuccess,
  onError,
  onClose,
  employerShortId,
  employerLinkToken,
}) {
  const [loaded, setLoaded] = useState(false);
  const [linkInstance, setLinkInstance] = useState(null);

  useEffect(() => {
    if (loaded) {
      return;
    }

    scriptjs("https://plugin.argyle.com/argyle.web.v5.js", "argyle-js", () => {
      setLoaded(true);
    });
  }, [loaded]);

  useEffect(() => {
    if (
      !linkInstance &&
      window.Argyle &&
      employerShortId &&
      employerLinkToken
    ) {
      const link = window.Argyle.create({
        sandbox: process.env.ARGYLE_SANDBOX === "true",
        flowId: process.env.ARGYLE_UI_CUSTOMIZATION_ID,
        userToken: employerLinkToken,
        items: [employerShortId],
        onAccountCreated: ({ accountId, userId, linkItemId }) => {
          console.log("Account created", accountId, userId, linkItemId);
        },
        onAccountConnected: ({ accountId, userId, linkItemId }) => {
          onSuccess({ accountId, userId, linkItemId });
        },
        onAccountUpdated: ({ accountId, userId, linkItemId }) => {
          console.log("Account updated", accountId, userId, linkItemId);
        },
        onAccountRemoved: ({ accountId, userId, linkItemId }) => {
          console.log("Account removed", accountId, userId, linkItemId);
        },
        onUserCreated: async ({ userId, userToken }) => {
          console.log("User created", userId, userToken);
        },
        onClose: () => {
          onClose();
        },
        onTokenExpired: (updateToken) => {},
      });
      setLinkInstance(link);
    }
    if (linkInstance && isOpen) {
      linkInstance.open();
    }
  }, [
    loaded,
    linkInstance,
    isOpen,
    employerShortId,
    employerLinkToken,
    onSuccess,
    onError,
    onClose,
  ]);

  return <div />;
}
