import { LinkCard } from "components/LinkCard";
import React, { ReactNode } from "react";
import { IconType } from "react-icons/lib";
import { onlyText } from "utils/react";
import css from "./OptionCard.module.css";

interface Props {
  Icon: IconType;
  children: ReactNode;
  href?: string;
  helpText?: ReactNode;
  target?: "_blank" | "_parent" | "_top";
  onClick?: (e: React.MouseEvent) => void;
}

export function OptionCard({
  Icon,
  children,
  href,
  target,
  helpText,
  onClick,
}: Props) {
  return (
    <LinkCard
      to={href}
      className={css.optionCard}
      title={helpText && onlyText(helpText)}
      target={target}
      rel={target && "noopener noreferrer"}
      onClick={onClick}
    >
      <Icon
        className={css.optionCard__icon}
        size="60px"
        color="var(--primary-light)"
      />
      <div className={css.optionCard__label}>{children}</div>
    </LinkCard>
  );
}

export function OptionsList({
  children,
  minWidth = 170,
}: {
  children: ReactNode;
  minWidth?: number;
}) {
  return (
    <div
      className={css.optionsList}
      style={
        {
          "--minWidth": `${minWidth}px`,
        } as any
      }
    >
      {children}
    </div>
  );
}
