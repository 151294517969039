import React, { FC, ReactNode } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import { Root } from "./context";

export interface ListViewProps {
  createURL: string;
  nextURL: string;
  prevURL: string;
  onEdit: (id: string) => any;
}

export interface EditViewProps {
  listURL: string;
  id: string;
}

export interface CreateViewProps {
  listURL: string;
}

interface Props {
  prev: string;
  next: string;
  ListView: FC<ListViewProps>;
  EditView: FC<EditViewProps>;
  CreateView: FC<CreateViewProps>;
  children?: ReactNode;
}

export function StatelessEditCreateList({
  prev,
  next,
  ListView,
  EditView,
  CreateView,
  children,
}: Props) {
  const { rootURL, rootPath, url, path } = useNested();
  const history = useHistory();
  const listURL = url("/summary", { preserveHash: true });

  return (
    <Root.Provider
      value={{ url: rootURL, path: rootPath, prevURL: prev, nextURL: next }}
    >
      <Switch>
        {children}
        <Route
          path={path("/summary")}
          render={() => (
            <ListView
              createURL={url("/create")}
              nextURL={next}
              prevURL={prev}
              onEdit={(id) => history.push(url(`/edit/${id}/`))}
            />
          )}
        />
        <Route
          path={path("/create")}
          render={() => <CreateView listURL={listURL} />}
        />
        <Route
          path={path("/edit/:editId")}
          render={(routeProps) => {
            const id = routeProps?.match?.params?.editId;
            return <EditView listURL={listURL} id={id} />;
          }}
        />
        <Route
          exact
          path={path("/")}
          render={() => <Redirect to={listURL} />}
        />
        {prev && (
          <Route path={path("/prev")}>
            <Redirect to={prev} />
          </Route>
        )}
        {next && (
          <Route path={path("/next")}>
            <Redirect to={next} />
          </Route>
        )}
        <Route>
          <Redirect to={url("/", { preserveHash: true })} />
        </Route>
      </Switch>
    </Root.Provider>
  );
}
