import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary from "components/ModuleSummary";
import LinesSummary from "components/ModuleSummary/LinesSummary";
import React from "react";
import GET_PHOTO_ID from "./GetPhotoId.gql";

export default function PhotoIdDocumentSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<GetPhotoIdDocument>(GET_PHOTO_ID, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress?.pictureId}>
      <LinesSummary
        requiredData={data?.filingPerson}
        maxLines={1}
        maxWidth="15em"
        style={{ textAlign: "center" }}
      >
        {data?.progress?.pictureId?.status === "COMPLETE"
          ? "You have provided your photo ID."
          : "You have not provided your photo ID."}
      </LinesSummary>
    </ModuleSummary>
  );
}
