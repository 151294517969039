import { CategoryDetail } from "components/FormModules/Assets/HouseholdItems/CategorySection/CategoryDetail";
import { HouseholdItemCategoryChoice } from "globalTypes";
import * as React from "react";
import { GiCannon } from "react-icons/gi";

const Examples = () => (
  <div>
    <p>Examples include:</p>
    <ul>
      <li>Pistols</li>
      <li>Rifles</li>
      <li>Shotguns</li>
      <li>Ammunition</li>
      <li>Other related equipment</li>
    </ul>
  </div>
);

const FirearmsDetail: CategoryDetail = {
  category: HouseholdItemCategoryChoice.Firearms,
  displayName: "Firearms",
  displayLowercaseName: "firearms",
  hasNoProfileBooleanName: "hasNoHouseholdFirearmsItems",
  singleInterrogativeText:
    "Tell us about this firearm or firearm-related equipment.",
  noAssetDeclarativeText:
    "You have indicated that neither you nor any member of your household has any firearms.",
  booleanInterrogativeText: (
    <>
      <p className="required">
        <strong>
          Do you or any members of your household own any firearms?
        </strong>
      </p>
      <Examples />
    </>
  ),
  declarativeText: (
    <>
      <p className="required">
        <strong>
          Please itemize all firearms you and your family members own.
        </strong>
      </p>
      <Examples />
    </>
  ),
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add firearms below.",
  urlPathName: "firearms",
  displayMenuName: "Firearms",
  menuIcon: GiCannon,
};

export default FirearmsDetail;
