import { useQuery } from "@apollo/client";
import { useDeleteBankruptcyCase } from "api/graphql/BankruptcyCase";
import { ListViewProps } from "components/EditCreateList/stateless";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { LearnMore } from "components/LearnMore";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema, formatQuantity } from "utils/formatters";
import { useNested } from "utils/hooks";
import BANKRUPTCY_CASES_MODULE from "./BankruptcyCasesModule.gql";
import { BankruptcyCaseSchema } from "./BankruptcyCaseWizard";

function BankruptcyHeaderHelpText() {
  return (
    <LearnMore>
      <h4>What is a consumer bankruptcy?</h4>
      <p>Chapter 7 or chapter 13 filings are consumer bankruptcies.</p>
      <h4>I do not have my bankruptcy paperwork.</h4>
      <p>
        If you have already submitted your About You section information, we
        will attempt to automatically retrieve this information.
      </p>
      <h4>All the automatically retrieved data is wrong.</h4>
      <p>
        We looked these values up based on your social security number. Delete
        these cases, then review and update this in the About You section.
      </p>
    </LearnMore>
  );
}

interface Props extends ListViewProps {
  allowNoPreviousBankruptcyCases: boolean;
}

export function ListView({ allowNoPreviousBankruptcyCases, ...props }: Props) {
  const { data, loading } = useQuery<BankruptcyCasesModule>(
    BANKRUPTCY_CASES_MODULE
  );
  const [onDelete] = useDeleteBankruptcyCase();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { bankruptcyCases: items } = data;
  const reviewItems = items.filter((item) => !item.humanReviewed);

  return (
    <>
      <Title>Prior Bankruptcies</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              {...props}
              filingProfileBoolean="hasNoPreviousBankruptcies"
              addText="Add a bankruptcy case"
              question={
                <p className="required">
                  <strong>
                    Have you filed any bankruptcy cases in the last 8 years?
                  </strong>
                </p>
              }
              confirmation={
                <p>
                  You indicated that you haven’t filed any previous bankruptcy
                  cases in the last 8 years. If that’s correct, you’re ready to
                  continue to the next section. If you have filed any bankruptcy
                  cases previously, you may add them below.
                </p>
              }
            />
          )}
        />
        {!items.length && allowNoPreviousBankruptcyCases && (
          <Redirect to={url("/query")} />
        )}
        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                We need a complete list of all consumer bankruptcies you have
                filed. <BankruptcyHeaderHelpText />
              </p>
              {!!reviewItems.length && (
                <>
                  <p>
                    Based on the personal information you have provided, we have
                    identified{" "}
                    {formatQuantity(reviewItems.length, " bankruptcy case")}{" "}
                    from public records that appear to be related to you.
                  </p>
                  <p>
                    Review all cases marked as “Review required” below and
                    revise any incorrect information. Click save on the last
                    step to confirm that a case has been reviewed and corrected.
                    If the information for a particular case is incorrect or
                    looks unfamiliar, delete that case. If any cases are
                    missing, you will need to add them.
                  </p>
                </>
              )}
              <StatelessList
                {...props}
                emptyText="You haven’t added any cases yet."
                addButtonText="Add a case"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(item) => (
                  <div>
                    {formatErrorsFromSchema(BankruptcyCaseSchema, item)}

                    {!item.humanReviewed && (
                      <Link to={url(`../edit/${item.id}`)} className="warning">
                        <div
                          className="warning"
                          style={{ marginBottom: "5px" }}
                        >
                          <FaExclamationCircle
                            className="secondary"
                            style={{ verticalAlign: "middle" }}
                          />
                          <span style={{ marginLeft: "8px" }}>
                            Review Required
                          </span>
                        </div>
                      </Link>
                    )}
                    <strong>
                      Chapter {item.chapter} in {item.bankruptcyCourt.name}{" "}
                      {!item.closureDate && "(Open)"}
                    </strong>
                    <div>
                      Filed {format(parseISO(item.filingDate), "MM/dd/yyyy")}
                      {item.closureDate &&
                        `, closed ${
                          item.closureDate &&
                          format(parseISO(item.closureDate), "MM/dd/yyyy")
                        }`}
                    </div>
                    <div className="supporting-text">
                      {item.chapter === 13 &&
                        (item.inRepayment
                          ? "In repayment"
                          : "Repayment completed")}
                    </div>
                    {item.cofilers.length ? (
                      <div className="supporting-text">
                        Co-filed with{" "}
                        {item.cofilers.map((cofiler, i) => (
                          <React.Fragment key={`${item.id}__${cofiler.id}`}>
                            {i !== 0 && ", "}
                            {cofiler.firstName} {cofiler.middleName}{" "}
                            {cofiler.lastName}
                          </React.Fragment>
                        ))}
                      </div>
                    ) : item.hasNoCofilers ? (
                      <div className="supporting-text">No cofilers</div>
                    ) : (
                      <div className="supporting-text">
                        You haven’t told us if you cofiled with anyone
                      </div>
                    )}
                  </div>
                )}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
