import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaCreditCard } from "react-icons/fa";
import { CreditCardDebts } from "./CreditCardDebts";
import CreditCardsSummary from "./Summary";

const CreditCardDebtsSubmodule: SubModule = {
  path: "credit_card_debts",
  title: "Credit Cards",
  icon: FaCreditCard,
  component: CreditCardDebts,
  summary: CreditCardsSummary,
  statusName: "creditCardDebts",
};

export default CreditCardDebtsSubmodule;
