import React from "react";
import { Modal, Badge } from "@mantine/core";

import styled from "@emotion/styled";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
`;
const GridItemLabel = styled.div`
  font-weight: 600;
`;

function Connection({ connection }) {
  return (
    <>
      <div>{connection.name}</div>
      <div>
        <Badge color={connection.status === "connected" ? "green" : "red"}>
          {connection.status}
        </Badge>
      </div>
    </>
  );
}

export default function ConnectionStatusModal({
  opened,
  onClose,
  connections,
}) {
  return (
    <Modal
      opened={opened}
      transition={"fade"}
      onClose={onClose}
      size="xl"
      centered
    >
      <>
        <div>
          <div>
            <h2>Connections</h2>
            <div className="relative w-full h-full">
              <div className="mt-8">
                <h3>Employer Accounts</h3>
                <div className="mt-4">
                  {connections.employment.length === 0 ? (
                    <div>No connected employer accounts</div>
                  ) : (
                    <>
                      <Grid>
                        <GridItemLabel>Employer</GridItemLabel>
                        <GridItemLabel>Status</GridItemLabel>
                        {connections.employment.map((connection, i) => (
                          <Connection key={i} connection={connection} />
                        ))}
                      </Grid>
                    </>
                  )}
                </div>
              </div>
              <div className="mt-12">
                <h3>Financial Accounts</h3>
                <div className="mt-4">
                  {connections.financial.length === 0 ? (
                    <div>No connected financial accounts</div>
                  ) : (
                    <>
                      <Grid>
                        <GridItemLabel>Institution</GridItemLabel>
                        <GridItemLabel>Status</GridItemLabel>
                        {connections.financial.map((connection, i) => (
                          <Connection key={i} connection={connection} />
                        ))}
                      </Grid>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
}
