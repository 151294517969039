import { BigIcon } from "components/Big";
import {
  ProgressIndicator,
  StepHeading,
  StepProps,
} from "components/Onboarding/AboutTheProcess";
import * as React from "react";
import { FaBalanceScale } from "react-icons/fa";

export function Court({ total, completed, actions, ...props }: StepProps) {
  return (
    <div {...props}>
      <BigIcon>
        <FaBalanceScale />
      </BigIcon>
      <StepHeading>Court</StepHeading>
      <ProgressIndicator
        total={total}
        completed={completed}
        actions={actions}
      />
      <p>
        After gathering your information, your lawyer will work with you on the
        next steps to file your bankruptcy case. Eventually, the case will be
        filed with the courts and the bankruptcy process will begin. Filing the
        case automatically stops most debt collection actions against you. This
        prevents the people and companies you owe money to from starting or
        continuing lawsuits, garnishing your wages, or contacting you to make
        payments. You are only protected from creditors that are properly
        notified, so it is always imperative that you provide a comprehensive
        and accurate list of people you owe money to.
      </p>
      <p>
        In most Chapter 7 cases, you will not have to appear in court.
        Typically, you will only meet with a court agent called the trustee and
        your creditors. For a Chapter 13 case, you and your lawyer typically
        will be required to present your payment plan to a bankruptcy judge for
        review in court.
      </p>
    </div>
  );
}
