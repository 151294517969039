import { AddressForm, AddressSchema } from "components/FormSections/Address";
import PrivacyInput from "components/PrivacyInput";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { object, string } from "yup";

export const LenderSchema = object({
  leaseholderName: string().required(
    "Please enter the name of the person or group who leased you this vehicle."
  ),
  accountNumber: string().required(
    "Please enter the account number for this lease."
  ),
  billingAddress: AddressSchema.required(),
}).required();

export default function Lender({ navProps, isEdit }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <TextInput
          name="leaseholderName"
          label="Lessor name"
          width="200px"
          autoFocus={!isEdit}
        />
        {/* @ts-ignore Switch out text-mask to something up to date */}
        <PrivacyInput
          name="accountNumber"
          label="Lease or account number"
          width="200px"
        />
      </div>

      <p>
        What is this lessor&rsquo;s mailing address? This will allow us to
        contact the lessor, if necessary.
      </p>
      <AddressForm graphQL name="billingAddress" />

      <WizardNavigation {...navProps} />
    </>
  );
}
