import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaEnvelopeOpenText } from "react-icons/fa";
import TaxReturnsSummary from "./Summary";
import { TaxReturns } from "./TaxReturns";

const TaxReturnsSubmodule: SubModule = {
  path: "tax_returns",
  icon: FaEnvelopeOpenText,
  title: "Tax Returns",
  component: TaxReturns,
  summary: TaxReturnsSummary,
  statusName: "taxReturns",
};

export default TaxReturnsSubmodule;
