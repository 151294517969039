import { HiddenInput } from "components/HiddenInput";
import { Select } from "components/Select";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { ServicePrepaymentTypeChoice } from "globalTypes";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { object, string } from "yup";
import { servicePrepaymentDetails } from "../utils";

export const PrepaymentTypeSchema = object({
  prepaymentType: string().required("Please select a pre-payment type."),
  description: string().when("prepaymentType", {
    is: (value) => value === ServicePrepaymentTypeChoice.Other,
    then: string().required("A description is required."),
    otherwise: string().nullable(),
  }),
});

export function PrepaymentType(props: WizardRouteChildProps) {
  const options = React.useMemo(
    () =>
      Object.entries(servicePrepaymentDetails).map(([key, value]) => ({
        value: key,
        label: value.displayName,
      })),
    []
  );
  const form = useFormContext();
  const prepaymentType = form.watch("prepaymentType");
  const isOther = prepaymentType === ServicePrepaymentTypeChoice.Other;

  return (
    <>
      <Select
        name="prepaymentType"
        options={options}
        label="What is this security deposit or pre-payment for?"
      />
      {isOther ? (
        <TextInput
          name="description"
          label="Please provide a brief description"
          autoFocus={!props.isEdit && isOther}
          rules={{ required: true }}
        />
      ) : (
        <HiddenInput name="description" value="" />
      )}
    </>
  );
}
