import { BooleanInput } from "components/BooleanInput";
import { DateInput } from "components/DateInput";
import { LearnMore } from "components/LearnMore";
import { DecimalInput } from "components/NumberInput";
import { TextArea } from "components/TextArea";
import { WantToKeepLearnMore } from "components/WantToKeepLearnMore";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { useFormContext } from "react-hook-form";
import { DateSchema, DecimalSchema } from "utils/validators/yup";
import { mixed, object, string } from "yup";

export const DetailsSchema = object({
  description: string().required("Please enter a description of this debt."),
  outstandingAmount: DecimalSchema.required(
    "Please enter the outstanding amount owed for this debt."
  ),
  delinquentAmount: DecimalSchema.required(
    "Please enter the delinquent balance if any, otherwise enter “0”."
  ),
  monthlyPayment: DecimalSchema.required(
    "Please enter your monthly payment for this debt."
  ),
  lastPaymentDate: DateSchema.dateNotFuture(
    "Last payment date can’t be in the future."
  ).nullable(),
  secured: mixed<boolean | null>().required("This field is required."),
  securedDescription: string().when("secured", {
    is: true,
    then: string().required("Please describe what this debt is secured by"),
    otherwise: string().optional().nullable(),
  }),
  wantToKeep: mixed<boolean | null>().when("secured", {
    is: true,
    then: mixed<boolean | null>().required("This field is required."),
    otherwise: mixed<boolean | null>().oneOf([true, false, null]),
  }),
}).required();

export default function Details({ isEdit, navProps }: WizardRouteChildProps) {
  const form = useFormContext();
  return (
    <>
      <div className="form-row">
        <TextArea
          name="description"
          label="Description"
          autoFocus={!isEdit}
          learnMore={
            <LearnMore title="Description">
              <p>
                As this loan does not fit any other classification like medical
                debts or credit card debts, please describe what this loan is
                for.
              </p>
            </LearnMore>
          }
        />
      </div>

      <div className="form-row">
        <DecimalInput
          name="monthlyPayment"
          label="Monthly payment"
          addOnBefore="$"
          width="150px"
          includeThousandsSeparator
          allowDecimal
        />
        <DecimalInput
          name="outstandingAmount"
          label="Outstanding amount"
          addOnBefore="$"
          width="150px"
          includeThousandsSeparator
          allowDecimal
        />
      </div>

      <div className="form-row">
        <DecimalInput
          name="delinquentAmount"
          label="Delinquent amount"
          addOnBefore="$"
          width="150px"
          includeThousandsSeparator
          allowDecimal
        />
        <DateInput
          name="lastPaymentDate"
          label="Last payment date"
          width="150px"
        />
      </div>

      <div className="form-row">
        <BooleanInput
          name="secured"
          label="Is this debt secured by any property or other assets you own or hold interest in?"
          learnMore={
            <LearnMore>
              <h4>What is a secured debt?</h4>
              <p>
                A debt that is backed by property or assets you own is called
                secured. This means that if you fail to repay the debt, you will
                need to forfeit that property. Mortgages and vehicle loans are
                examples of secured debts.
              </p>
            </LearnMore>
          }
          onChange={(value) => {
            if (!value) {
              form.setValue("securedDescription", null);
              form.setValue("wantToKeep", false);
            }
          }}
        />
      </div>
      <div
        className="form-row"
        style={{
          display: form.watch("secured") ? "flex" : "none",
        }}
      >
        <TextArea
          name="securedDescription"
          label="Secured Asset(s) Description"
          placeholder="Tell us what assets secure this debt."
          rules={{ required: true }}
        />
      </div>
      <div
        className="form-row"
        style={{
          display: form.watch("secured") ? "flex" : "none",
        }}
      >
        <BooleanInput
          name="wantToKeep"
          label="Do you want to keep the secured assets after your bankruptcy?"
          learnMore={<WantToKeepLearnMore />}
        />
      </div>

      <WizardNavigation {...navProps} />
    </>
  );
}
