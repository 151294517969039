import { useMutation } from "@apollo/client";
import cn from "classnames";
import { usePauseAutoScroll } from "components/AutoScrollToTop";
import {
  BreakdownTable,
  useBreakdownTableState,
} from "components/BreakdownTable";
import { formatDisplayValue } from "components/BreakdownTable/utils";
import { ButtonRow } from "components/ButtonRow";
import { useEditorBuffer } from "components/EditorState/hooks";
import { hsl, interpolateDiscrete, scaleLinear, schemeSet2 } from "d3";
import { motion } from "framer-motion";
import { find, initial } from "lodash";
import * as React from "react";
import { FaPencilAlt, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { formatName } from "utils/formatters";
import { hashString } from "utils/hash";
import { breakpoints, useBreakpoint, useNested } from "utils/hooks";
import { fuzzyEquals } from "utils/math";
import { parseFancyNumber } from "utils/validators";
import { AddCoownerModal } from "../AddCoownerModal";
import { EditCoownerModal } from "../EditCoownerModal";
import MARK_NO_COOWNERS from "../graphql/MarkNoCoowners.gql";
import SET_COOWNERSHIP_DETAILS from "../graphql/SetCoownershipDetails.gql";
import css from "./styles.module.scss";

export interface CoownersBreakdownProps {
  displayName: string;
  knownOwners?: string[];
  relatedId: UUID;
  relatedTypename: string;
  currentValue: Decimal;
  ownedValue: Decimal;
  filingPerson: {
    id: UUID;
    firstName: string;
    middleName?: string;
    lastName: string;
    suffix?: string;
  };
  coowners: {
    person: {
      id: UUID;
      firstName: string;
      middleName?: string;
      lastName: string;
      suffix?: string;
    };
    details?: {
      coOwnedValue: Decimal;
    };
  }[];
  registerOnSave?: (register: () => Promise<any>) => void;
  noCoownersURL: string;
}

export interface Details {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  personType: "FilingPerson" | "OtherPerson";
}

function useUpdateBufferField(fieldName: string) {
  const [{ buffer }] = useEditorBuffer() || [{}];
  const update = React.useCallback(
    (value: any) => {
      if (!buffer) return;
      buffer[fieldName] = value;
    },
    [buffer]
  );
  return React.useMemo(() => [update], [update]);
}

function useMarkNoCoowners({
  relatedId,
  relatedTypename,
}: {
  relatedId: UUID;
  relatedTypename: string;
}) {
  return useMutation<MarkNoCoowners, MarkNoCoownersVariables>(
    MARK_NO_COOWNERS,
    {
      variables: { id: relatedId, typename: relatedTypename },
    }
  );
}

function useSetCoownershipDetails() {
  return useMutation<SetCoownershipDetails, SetCoownershipDetailsVariables>(
    SET_COOWNERSHIP_DETAILS
  );
}

function useForegroungColorScale(filingPersonId?: UUID) {
  return React.useCallback(
    (id: string, i?: number) => {
      if (filingPersonId === id) {
        return getComputedStyle(document.documentElement).getPropertyValue(
          "--primary"
        );
      }

      const schemeWithoutGray = initial([...schemeSet2]);
      const color = interpolateDiscrete(schemeWithoutGray);
      const scale = scaleLinear().domain([0, 2 ** 32]);
      return color(scale(hashString(id || "")));
    },
    [filingPersonId]
  );
}

function useBackgroundColorScale(filingPersonId?: UUID) {
  const foregroundScale = useForegroungColorScale(filingPersonId);
  return React.useCallback(
    (id: string, i?: number) => {
      const color = hsl(foregroundScale(id)).brighter(0.3);
      color.opacity = 0.3;
      return color.formatRgb();
    },
    [filingPersonId, foregroundScale]
  );
}

export function CoownersBreakdown({
  displayName,
  knownOwners,
  relatedId,
  relatedTypename,
  currentValue,
  ownedValue,
  coowners,
  filingPerson,
  registerOnSave,
  noCoownersURL,
}: CoownersBreakdownProps) {
  usePauseAutoScroll();
  const { url, path } = useNested();
  const history = useHistory();
  const isMobile = useBreakpoint(breakpoints.mobile);

  const [markNoCoowners, { loading: markingNoCoowners }] = useMarkNoCoowners({
    relatedId,
    relatedTypename,
  });

  const [save, { loading: saving }] = useSetCoownershipDetails();
  const [updateBufferCurrentValue] = useUpdateBufferField("currentValue");

  const [errors, setErrors] = React.useState<string>(null);

  const colorScale = useForegroungColorScale(filingPerson.id);
  const backgroundColorScale = useBackgroundColorScale(filingPerson.id);

  const {
    table,
    deleteItem,
    changeItem,
    addItem,
    changeTotal,
    setDirty,
    setItems,
  } = useBreakdownTableState<Details>({
    total: parseFancyNumber(currentValue),
    items: [
      {
        id: filingPerson.id,
        value: parseFancyNumber(ownedValue),
        details: {
          ...filingPerson,
          personType: "FilingPerson",
        },
      },
      ...coowners.map((coowner) => ({
        id: coowner.person.id,
        value: parseFancyNumber(coowner.details?.coOwnedValue),
        details: {
          ...coowner.person,
          personType: "OtherPerson" as const,
        },
      })),
    ],
  });

  const saveCallback = React.useCallback(async () => {
    const errorMessage = "There was a problem saving. Please try again.";
    try {
      setErrors(null);
      const result = await save({
        variables: {
          relatedTypename,
          relatedId,
          currentValue: `${table.total.value}`,
          ownedValue: `${
            table.items.filter(
              (item) => item.details.personType === "FilingPerson"
            )?.[0]?.value
          }`,
          coowners: table.items
            .filter((item) => item.details.personType === "OtherPerson")
            .map((coowner) => ({
              person: coowner.id,
              details: {
                coOwnedValue: `${coowner.value}`,
              },
            })),
        },
      });

      if (result.errors) {
        setErrors(errorMessage);
        return;
      }

      setDirty(false);
      updateBufferCurrentValue(result.data.setCoownershipDetails.currentValue);
    } catch (e) {
      console.error(e);
      setErrors(errorMessage);
      throw e;
    }
  }, [save, setErrors, setDirty, updateBufferCurrentValue]);

  React.useEffect(() => {
    registerOnSave?.(saveCallback);
  }, [saveCallback]);

  const removeAlreadySelectedCoowners = React.useCallback(
    (candidate: { id: UUID }) =>
      !find(table.items, (item) => item.id === candidate.id),
    [table.items]
  );

  return (
    <>
      <p>
        {`If you share ownership of this ${displayName} with multiple people, add them below. Otherwise, you’re ready to continue.`}
      </p>
      {knownOwners && (
        <>
          <p>{`Our search of ${displayName} records shows these people are listed as ${displayName} owners:`}</p>
          <ul>
            {knownOwners.map((value, index) => {
              return <li key={index}>{value}</li>;
            })}
          </ul>
        </>
      )}
      <br />
      <div className={cn(css.wrapper, { [css.unsaved]: table.dirty })}>
        <div className={cn(css.notice, { [css.unsaved]: table.dirty })}>
          <span className={css.inner}>
            {saving
              ? "Saving your changes"
              : "Your changes haven’t been saved yet"}
          </span>
        </div>
        <BreakdownTable<Details>
          total={table.total}
          items={table.items}
          snappingPoints={table.snappingPoints}
          disabled={saving || markingNoCoowners}
          getColorForItem={(item) => colorScale(item.id)}
          getBackgroundColorForItem={(item) => backgroundColorScale(item.id)}
          onChangeItem={(updatedItem) => {
            changeItem(updatedItem);
          }}
          onChangeTotal={({ value, displayValue }) => {
            changeTotal({ value, displayValue });
          }}
          renderTotalHeading={() => `Total value of this ${displayName}`}
          renderItemHeading={() => "Owner"}
          renderItemValueHeading={() => "$ amount this person owns"}
          renderItemEntry={(item) => (
            <>
              <div>{`${formatName(item.details)}${
                item.id === filingPerson.id ? " (you)" : ""
              }`}</div>
              {item.details.personType === "OtherPerson" && (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      history.push(url(`/edit_co_owner/${item.id}`));
                    }}
                    title="Edit"
                    className="btn flat"
                    style={{
                      padding: "10px",
                    }}
                  >
                    <FaPencilAlt color="var(--gray-3)" />
                  </button>
                  <button
                    type="button"
                    className="btn flat"
                    style={{ padding: "10px" }}
                    onClick={() => {
                      deleteItem(item);
                    }}
                  >
                    <FaTrashAlt color="var(--gray-3)" />
                  </button>
                </>
              )}
            </>
          )}
          renderTableWarnings={(total, itemTotals) => {
            const unallocated = total - itemTotals;
            if (!fuzzyEquals(unallocated, 0)) {
              return `There’s ${formatDisplayValue(
                unallocated
              )} of this ${displayName} that hasn’t been assigned to anyone.`;
            }
          }}
          renderItemWarnings={(item) => {
            if (fuzzyEquals(item.max, 0)) {
              return "To give this person ownership reduce the other owners’ amounts using the sliders or the $ amount inputs.";
            }
          }}
        />
        <motion.div positionTransition>
          <ButtonRow
            className={css["control-buttons"]}
            right={
              <button
                type="button"
                onClick={() => {
                  history.push(url("/add_co_owner"));
                }}
                className={cn("btn secondary", {
                  disabled: saving || markingNoCoowners,
                })}
              >
                <FaPlus />
                <span>{isMobile ? "Co-owner" : "Add a co-owner"}</span>
              </button>
            }
            left={
              <button
                type="button"
                onClick={async () => {
                  await markNoCoowners();
                  setItems({
                    items: [
                      {
                        ...find(
                          table.items,
                          (item) => item.id === filingPerson.id
                        ),
                        value: table.total.value,
                      },
                    ],
                  });
                  history.push(noCoownersURL);
                }}
                className={cn("btn", {
                  loading: markingNoCoowners,
                  disabled: saving,
                })}
              >
                I’m the sole owner
              </button>
            }
          />
        </motion.div>
      </div>
      {errors && <div className={cn("errors", css.errors)}>{errors}</div>}
      <AddCoownerModal
        coownerFilter={removeAlreadySelectedCoowners}
        onAddCoowner={addItem}
        modalPath={path("/add_co_owner")}
        nextURL={url("/")}
        abortURL={url("/")}
        displayName={displayName}
        relatedTypename={relatedTypename}
        relatedId={relatedId}
      />
      <EditCoownerModal
        displayName={displayName}
        relatedTypename={relatedTypename}
        relatedId={relatedId}
        onEditCoowner={({ id, details, value }) => {
          const oldItem = table.items.filter((item) => item.id === id)[0];
          if (!oldItem) return;

          changeItem({
            ...oldItem,
            id,
            details: details === undefined ? oldItem.details : details,
            value: value === undefined ? oldItem.value : value,
          });
        }}
        modalPath={path("/edit_co_owner/:coownerId")}
        getIdFromPathMatch={(match) => match?.params?.coownerId}
        nextURL={url("/")}
      />
    </>
  );
}
