import cn from "classnames";
import * as React from "react";
import ReactModal from "react-modal";
import styles from "./Modal.module.scss";

const appElement = document.getElementById("main-container");
ReactModal.setAppElement(appElement || document.body);

interface ModalProps extends ReactModal.Props {
  children: React.ReactNode;
}

export function Modal(props: ModalProps) {
  return (
    <ReactModal
      {...props}
      closeTimeoutMS={100}
      className={cn(props.className, styles.content)}
      overlayClassName={cn(props.overlayClassName, styles.overlay)}
    />
  );
}
