import { useUpsertFinancialInvestment } from "api/graphql/FinancialInvestment";
import Coowners from "components/FormModules/Assets/FinancialInvestments/FinancialInvestmentWizard/Coowners";
import { financialInvestmentTypeDetails } from "components/FormModules/Assets/FinancialInvestments/utils";
import { Wizard, WizardRouteChildProps } from "components/Wizard";
import {
  CoOwnersSchema,
  CoownersWizardStepProps,
  useDelegatedOnSave,
} from "components/WizardSteps/Coowners";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import Investment, { InvestmentSchema } from "./Investment";

interface Props {
  investmentType: FinancialInvestmentTypeChoice;
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  /** The URL to switch to after the initial save. Created object ID will be appended */
  editURL?: string;
  subtitle?: React.ReactElement;
}

export const FinancialInvestmentSchema = InvestmentSchema.concat(
  CoOwnersSchema
);

export function FinancialInvestmentWizard({
  investmentType,
  backURL,
  nextURL,
  initialValue,
  title,
  editURL = "edit",
}: Props) {
  const { path, url } = useNested();
  const [upsertFinancialInvestment] = useUpsertFinancialInvestment(undefined);
  const history = useHistory();
  const [financialInvestmentId, setFinancialInvestmentId] = useState(
    initialValue?.id
  );
  const [registerOnSaveCoowners, onSaveCoowners] = useDelegatedOnSave();
  const coownersURL = "/coowners";
  const detail = financialInvestmentTypeDetails[investmentType];

  const InvestmentWithProps = (props: WizardRouteChildProps) => (
    <Investment {...props} investmentType={investmentType} />
  );

  return (
    <Wizard
      title={title}
      subtitle={
        <p>
          {detail.helpText}{" "}
          {detail.descriptionInstructionText || (
            <strong>
              Tell us about a single {detail.displayName.toLowerCase()} you or a
              household member owns or holds interest in.
            </strong>
          )}
        </p>
      }
      backURL={backURL}
      nextURL={nextURL}
      initialValue={initialValue}
      vertical
      steps={[
        {
          path: "/investment",
          title: "Investment",
          component: InvestmentWithProps,
          schema: InvestmentSchema,
          async onSave(formState) {
            formState.investmentType = investmentType;
            const { data } = await upsertFinancialInvestment(formState);
            const { id } = data.financialInvestment;
            setFinancialInvestmentId(id);
            if (financialInvestmentId) {
              history.push(url(coownersURL));
            } else {
              history.push(`../../${editURL}/${id}${coownersURL}`);
            }
            return data.financialInvestment;
          },
        },
        {
          path: coownersURL,
          title: "Co-owners",
          component: React.useCallback(
            (props: CoownersWizardStepProps) => (
              <Coowners {...props} registerOnSave={registerOnSaveCoowners} />
            ),
            [registerOnSaveCoowners]
          ),
          schema: CoOwnersSchema,
          includeSubpaths: true,
          onSave: onSaveCoowners,
        },
      ]}
    />
  );
}
