import { ButtonRow } from "components/ButtonRow";
import { ListItem } from "components/ListItem";
import * as React from "react";
import { FaArrowLeft, FaArrowRight, FaCheck, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ListViewProps } from "./stateless";

export interface ListProps<T = any> extends Partial<ListViewProps> {
  showAddButton?: boolean;
  addButtonText?: React.ReactNode;
  emptyText?: React.ReactNode;
  emptyCTA?: string;
  deleteItem: (item: T) => Promise<any>;
  renderItem?: (item: T) => React.ReactNode;
  cancelButton?: React.ReactNode;
  items: T[];
}

export function StatelessList<T extends { id: UUID }>({
  showAddButton = true,
  addButtonText = "Add another",
  prevURL,
  cancelButton = prevURL && (
    <Link to={prevURL} className="btn">
      <FaArrowLeft />
      <span>Back</span>
    </Link>
  ),
  emptyText,
  emptyCTA = "Get started",
  deleteItem,
  renderItem,
  items,
  createURL,
  nextURL,
  onEdit,
}: ListProps<T>) {
  const isEmpty = !items.length;

  return (
    <>
      {isEmpty && <p style={{ color: "var(--gray-3)" }}>{emptyText}</p>}
      <ButtonRow
        style={{ marginBottom: "8px" }}
        left={
          !isEmpty ? (
            showAddButton ? (
              <Link to={createURL} className="btn secondary">
                <FaPlus />
                <span>{addButtonText}</span>
              </Link>
            ) : null
          ) : (
            cancelButton || null
          )
        }
        right={
          isEmpty ? (
            <Link to={createURL} className="btn primary">
              <span>{emptyCTA}</span> <FaArrowRight />
            </Link>
          ) : (
            nextURL && (
              <Link to={nextURL} className="btn primary">
                <span>Done</span> <FaCheck />
              </Link>
            )
          )
        }
      />
      {items.map((item) => {
        return (
          <ListItem
            key={item.id}
            onDelete={() => deleteItem(item)}
            onEdit={
              onEdit &&
              (() => {
                onEdit(item.id);
              })
            }
          >
            {renderItem(item)}
          </ListItem>
        );
      })}
    </>
  );
}
