import { useGetFarmAsset } from "api/graphql/FarmAsset";
import FarmAssetForm from "components/FormModules/Assets/FarmAssets/FarmAssetForm";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import { CategoryDetail } from "./CategoryDetail";
import { Description } from "./Description";

interface Props {
  id: string;
  listURL: string;
  categoryDetail: CategoryDetail;
}

export default function EditView(props: Props) {
  const { data } = useGetFarmAsset(props.id);

  if (!data) {
    return <Loading />;
  } else if (!data?.farmAsset) {
    return <Redirect to={props.listURL} />;
  }

  return (
    <FarmAssetForm
      category={props.categoryDetail.category}
      title={`Updating ${props.categoryDetail.displayName}`}
      subtitle={
        <Description introText={props.categoryDetail.singleInterrogativeText} />
      }
      backURL={props.listURL}
      nextURL={props.listURL}
      initialValue={data.farmAsset}
    />
  );
}
