import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaBed } from "react-icons/fa";
import { HouseholdItems } from "./HouseholdItems";
import HouseholdItemsSummary from "./Summary";

const HouseholdItemsSubmodule: SubModule = {
  path: "household_and_personal_items",
  icon: FaBed,
  title: "Household Items",
  component: HouseholdItems,
  summary: HouseholdItemsSummary,
  statusName: "householdItems",
};

export default HouseholdItemsSubmodule;
