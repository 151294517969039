import { useQuery } from "@apollo/client";
import { useEditorBuffer } from "components/EditorState/hooks";
import GetOrCreate from "components/GetOrCreate";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import gql from "graphql-tag";
import React from "react";
import { formatTaxReturnName } from "utils/formatters";
import { object, string } from "yup";

export const TaxReturnSchema = object({
  taxReturn: object({
    id: string().required("This step is required."),
  }).required(),
}).required();

export const GET_TAX_RETURN_CANDIDATES = gql`
  query GetTaxReturnCandidates($taxDebtID: UUID) {
    taxDebtReturnCandidates(taxDebtId: $taxDebtID) {
      id
      returnFiled
      returnYear
      _type
    }
  }
`;

export default function TaxReturn({ navProps }: WizardRouteChildProps) {
  const [{ committed }] = useEditorBuffer();
  const taxDebtID = committed?.id;

  const { data, loading } = useQuery<
    GetTaxReturnCandidates,
    GetTaxReturnCandidatesVariables
  >(GET_TAX_RETURN_CANDIDATES, {
    fetchPolicy: "cache-and-network",
    variables: { taxDebtID },
  });

  return (
    <>
      <p>Which tax return is this debt for?</p>
      <GetOrCreate
        name="taxReturn.id"
        createURL="create_tax_return?money_owed=true"
        objectName="tax return"
        loading={loading}
        options={data?.taxDebtReturnCandidates?.map((item) => ({
          value: item.id,
          label: formatTaxReturnName(item),
        }))}
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
