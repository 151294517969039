import { useGetSession } from "api/graphql/Session";
import { Dashboard, useDashboard } from "components/FormFlow/Dashboard";
import { DashboardProps } from "components/FormFlow/Dashboard/Dashboard";
import { SummaryStats } from "components/SummaryStats";
import React from "react";
import { Link } from "react-router-dom";

export function NewFilingDashboardContents() {
  const { started, done } = useDashboard();
  const { data: sessionData } = useGetSession();

  const firstName = sessionData?.session?.user?.firstName;
  let copy: string;
  if (done) {
    copy = `
        You are now ready to submit! You may want to go back and review
        your information, as you won't be able to change it after submitting.
      `;
  } else if (started) {
    copy = `
        Welcome back${
          firstName ? `, ${firstName}` : ""
        }! We saved all your info and we’re
        ready to continue right where you left off.
      `;
  } else {
    copy = `
        To move forward discharging your debt via bankruptcy we
        need to gather more information. We need you to complete all of the
        sections — but you can do them in any order.  Make sure all the data
        you provide is accurate.
      `;
  }

  return (
    <>
      <h1 id="dashboard-title">Tell us more about you</h1>
      <SummaryStats />
      <Link to={"./about-the-process?intro=false"}>
        How does bankruptcy work?
      </Link>
      <p style={{ marginBottom: "30px" }}>{copy}</p>
    </>
  );
}

export function NewFilingDashboard(props: DashboardProps) {
  return (
    <Dashboard {...props}>
      <NewFilingDashboardContents />
    </Dashboard>
  );
}
