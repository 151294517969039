import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import ASSET_SALES_MODULE from "./AssetSalesModule.gql";

export default function AssetSalesSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<AssetSalesModule>(ASSET_SALES_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.assetSales}>
      {data?.filingProfile?.hasNoAssetSales ? (
        <HasNoSummary>
          You haven&rsquo;t sold or transferred any assets in the last two
          years.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="sold asset"
            maxValueLength={2}
            value={data?.assetSales?.length}
            pluralizeLabel
          />
          <NumericSummary
            label="total value sold"
            valuePrefix="$"
            maxValueLength={4}
            value={fancyNumberSum(data?.assetSales, "saleAmount")}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
