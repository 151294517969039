import { useGetCoownerCandidates } from "api/graphql/Coowner";
import {
  OtherPersonDataValidationType,
  OtherPersonWithMissingDataValidationsForm,
} from "components/FormSections/OtherPersonWithMissingDataValidation";
import { Loading } from "components/Loading";
import * as React from "react";
import { Candidate, OtherPersonFormState } from "../OtherPerson";

export type OnSubmitDataType = OtherPersonFormState;

export type CoownerFormProps = {
  returnURL?: string;
  onAbort?: () => void;
  onSubmit?: (data: OnSubmitDataType) => void;
  relatedId: string;
  relatedTypename: string;
  id?: string;
  title?: string;
  candidateFilter?: (candidate: Candidate) => boolean;
  saveText?: string;
};

export function CoownerForm({
  returnURL,
  onAbort,
  onSubmit: controlledOnSubmit,
  id,
  relatedId,
  relatedTypename,
  title,
  candidateFilter,
  saveText,
}: CoownerFormProps) {
  const { data, loading, error } = useGetCoownerCandidates({
    id: relatedId,
    typename: relatedTypename,
  });

  const validations = React.useMemo(
    () => [OtherPersonDataValidationType.MailingAddress],
    []
  );

  const onSubmit = React.useCallback(
    async (state: OnSubmitDataType) => {
      if (controlledOnSubmit) {
        controlledOnSubmit(state);
      }
      return state;
    },
    [controlledOnSubmit]
  );

  const onFinalizeMissingDataCollection = React.useCallback(
    async ({ otherPersonId }: { otherPersonId: UUID }) => {
      await onSubmit({ id: otherPersonId });
    },
    [onSubmit]
  );

  if (loading) return <Loading size="M" />;

  return (
    <OtherPersonWithMissingDataValidationsForm
      validations={validations}
      id={id}
      candidates={data?.coownable?.eligibleCoowners?.map(
        (coowner) => coowner.person
      )}
      relatedId={relatedId}
      relatedTypename={relatedTypename}
      relatedDisplayName="co-owner"
      title={title}
      backURL={returnURL}
      nextURL={returnURL}
      onAbort={onAbort}
      onSubmit={onSubmit}
      onFinalizeMissingDataCollection={onFinalizeMissingDataCollection}
      candidateFilter={candidateFilter}
      saveText={saveText}
    />
  );
}
