import {
  useListResidentialAddresses,
  useUpsertResidentialAddress,
} from "api/graphql/ResidentialAddress";
import { Card } from "components/Card";
import { CreateViewProps } from "components/EditCreateList/stateless";
import { Form } from "components/Form";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import React from "react";
import { useHistory } from "react-router";
import { useQueryParams } from "utils/hooks";
import ResidenceForm from "./ResidenceForm";
import { cleanApiInput, sortAddresses } from "./utils";

export default function CreateView({ listURL }: CreateViewProps) {
  const [upsertResidentialAddress] = useUpsertResidentialAddress();
  const history = useHistory();
  const params = useQueryParams();
  const { index: indexString } = useQueryParams();
  const { data } = useListResidentialAddresses();

  if (!data) return <Loading />;

  const { residentialAddresses: items } = data;

  const sortedAddresses = sortAddresses(items);
  const index = indexString ? parseInt(indexString) : 0;

  const prev = sortedAddresses[index - 1];
  const next = sortedAddresses[index];

  const initialValue = {} as any;
  if (params.addressId) {
    initialValue.addressId = params.addressId;
  }
  if (params.residentFrom) {
    initialValue.residentFrom = params.residentFrom;
  }

  return (
    <>
      <Title>Add a residence</Title>
      <Card>
        <Form
          onSubmit={async (
            values: ResidentialAddressInput,
            event,
            translateErrors
          ) => {
            try {
              await upsertResidentialAddress(cleanApiInput(values));
              history.push(listURL);
            } catch (e) {
              translateErrors(e);
            }
          }}
          initialValue={initialValue}
        >
          <ResidenceForm
            prevResidentTo={prev?.residentTo}
            nextResidentFrom={next?.residentFrom}
            abortURL={listURL}
            autoFocus
          />
        </Form>
      </Card>
    </>
  );
}
