import { useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import ASSIGN_COOWNER from "./AssignCoowner.gql";
import GET_COOWNER_CANDIDATES from "./GetCoownerCandidates.gql";
import UPSERT_COOWNER from "./UpsertCoowner.gql";

export function useAssignCoowner() {
  const [assignCoowner] = useMutation<AssignCoowner, AssignCoownerVariables>(
    ASSIGN_COOWNER
  );

  const callback = React.useCallback(
    (variables: AssignCoownerVariables) =>
      assignCoowner({
        variables,
      }),
    [assignCoowner]
  );

  return React.useMemo(() => [callback], [callback]);
}

export function useGetCoownerCandidates(
  variables: GetCoownerCandidatesVariables
) {
  return useQuery<GetCoownerCandidates, GetCoownerCandidatesVariables>(
    GET_COOWNER_CANDIDATES,
    {
      fetchPolicy: "network-only",
      variables,
    }
  );
}

export function useUpsertCoowner() {
  const [upsertCoowner] = useMutation<UpsertCoowner, UpsertCoownerVariables>(
    UPSERT_COOWNER
  );
  const callback = React.useCallback(
    (variables: UpsertCoownerVariables) =>
      upsertCoowner({
        variables,
      }),
    [upsertCoowner]
  );

  return React.useMemo(() => [callback], [callback]);
}
