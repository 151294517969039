import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaWallet } from "react-icons/fa";
import HouseholdExpenses from "./HouseholdExpenses";
import HouseholdExpensesSummary from "./Summary";

const HouseholdExpensesSubmodule: SubModule = {
  path: "household",
  title: "Household",
  icon: FaWallet,
  component: HouseholdExpenses,
  summary: HouseholdExpensesSummary,
  statusName: "monthlyExpenses",
};

export default HouseholdExpensesSubmodule;
