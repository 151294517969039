import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaTree } from "react-icons/fa";
import { EnvironmentalCleanupNotices } from "./EnvironmentalCleanupNotices";
import CleanupSummary from "./Summary";

const CleanupSubmodule: SubModule = {
  path: "/cleanup_notices",
  icon: FaTree,
  title: "Environmental Cleanup",
  component: EnvironmentalCleanupNotices,
  summary: CleanupSummary,
  statusName: "environmentalCleanupNotices" as const,
};

export default CleanupSubmodule;
