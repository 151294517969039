import React from "react";
import { VehicleLeaseWizard } from "./VehicleLeaseWizard";

export default function CreateView({ listURL }: { listURL: string }) {
  return (
    <VehicleLeaseWizard
      title="Add Vehicle Lease"
      backURL={listURL}
      nextURL={listURL}
    />
  );
}
