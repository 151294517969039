import { useUpsertServicePrepayment } from "api/graphql/ServicePrepayment";
import { Wizard } from "components/Wizard";
import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import {
  PrepaymentDetails,
  PrepaymentDetailsSchema,
} from "./PrepaymentDetails";
import { PrepaymentType, PrepaymentTypeSchema } from "./PrepaymentType";
import { ServicePrepaymentTypeChoice } from "globalTypes";

export const ServicePrepaymentSchema = PrepaymentTypeSchema.concat(
  PrepaymentDetailsSchema
);

interface ServicePrepaymentWizardProps {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  /** The URL to switch to after the initial save. Created object ID will be appended */
  editURL?: string;
  subtitle?: ReactElement;
}

export function ServicePrepaymentWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: ServicePrepaymentWizardProps) {
  const [upsertFinancialInvestment] = useUpsertServicePrepayment();
  const history = useHistory();

  return (
    <Wizard
      title={title}
      subtitle={
        <p>
          <strong>
            Tell us about a single security deposit or pre-payment for a utility
            or service owed to you or a member of your household.
          </strong>
        </p>
      }
      backURL={backURL}
      nextURL={nextURL}
      initialValue={initialValue}
      onSave={async (formState) => {
        const cleaned = {
          ...formState,
          description:
            formState.prepaymentType === ServicePrepaymentTypeChoice.Other
              ? formState.description
              : null,
        };
        await upsertFinancialInvestment(cleaned as ServicePrepaymentInput);
        history.push(nextURL);
        return formState;
      }}
      steps={[
        {
          path: "/type",
          title: "Pre-payment type",
          component: PrepaymentType,
          schema: PrepaymentTypeSchema,
          partialNestedUpdate: true,
        },
        {
          path: "/details",
          title: "Pre-payment details",
          component: PrepaymentDetails,
          schema: PrepaymentDetailsSchema,
          partialNestedUpdate: true,
        },
      ]}
    />
  );
}
