import DocumentsUpload from "components/FormSections/DocumentsUpload";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { array, mixed, number, object } from "yup";

export const StatementsSchema = object({
  importedTransactionsLength: number().optional().nullable(),
  documents: array(mixed<File | string>()).when("importedTransactionsLength", {
    is: (x) => typeof x === "number" && x > 0,
    then: array().optional().nullable(),
    otherwise: array().min(
      1,
      "Please upload documentation of your transactions for the last 12 months."
    ),
  }),
});

export default function Statements({ navProps }: WizardRouteChildProps) {
  return (
    <>
      <DocumentsUpload
        relatedTypename="FinancialAccount"
        displayText={
          <p className="required">
            Upload statements or other documents which show transactions for
            this account over the last 12 months.
          </p>
        }
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
