import {
  useApproveApplicant,
  useRejectApplicant,
  useForceSubmission,
  useReopenIntake,
} from "api/graphql/ReferredUser";
import { useUpdateEnableCreditReportMutation } from "api/graphql/FilingProfile";
import { useUpdateUsersArchivedStatus } from "api/graphql/ReferredUser";
import Axios from "axios";
import { Radio, Button, Select } from "@mantine/core";
import DataGrid from "components/DataGrid";
import { KebabMenuButton, KebabMenuItem } from "components/KebabMenu";
import { Loading } from "components/Loading";
import { LearnMore } from "components/LearnMore";
import {
  FormattedReferredUser,
  getFormattedReferredUser,
} from "components/PortalModules/ReferredUsersGrid/utils";
import React, { useState } from "react";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaQuestionCircle,
  FaSpinner,
} from "react-icons/fa";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import fileDownload from "js-file-download";

import { useListReferredUsers } from "api/graphql/ReferredUser";
import ReferredUserProgressIndicator from "./ReferredUserProgressIndicator";
import ReferredUserConnectionStatuses from "./ReferredUserConnections";
import SubmissionConfirmationModal from "components/PortalModules/ConfirmationModal";
import UserInfoModal from "components/PortalModules/UserInfoModal";

import css from "./ReferredUsersGrid.module.scss";

export default function ReferredUsersGrid() {
  const [users, setUsers] = useState([]);
  const [archivedUsers, setArchivedUsers] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [applicantDisabled, setApplicantDisabled] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelected, setClearSelected] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [reportIsLoading, setReportIsLoading] = useState(false);

  // User info modal bits
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);

  // Force submission bits
  const [forceSubmission, { loading: forceSubmissionLoading }] =
    useForceSubmission();
  const [reopenIntake, { loading: reopenIntakeLoading }] = useReopenIntake();

  const [showSubmissionConfirmationModal, setShowSubmissionConfirmationModal] =
    useState(false);
  const [forceSubmissionUserId, setForceSubmissionUserId] = useState(null);

  const [approveApplicant] = useApproveApplicant();
  const [rejectApplicant] = useRejectApplicant();
  const { data } = useListReferredUsers();
  const [updateEnableCreditReport] = useUpdateEnableCreditReportMutation();
  const [updateArchivedStatus] = useUpdateUsersArchivedStatus();

  function clearSelectedRows() {
    setClearSelected(!clearSelected);
  }

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
      },
    },
  };

  React.useEffect(() => {
    if (data) {
      setUsers(
        data.referredUsers
          .map((user: ReferredUser) => getFormattedReferredUser(user))
          .filter((user) => !user.isArchived)
      );
      setArchivedUsers(
        data.referredUsers
          .map((user: ReferredUser) => getFormattedReferredUser(user))
          .filter((user) => user.isArchived)
      );
    }
  }, [data]);

  const handleSelectedRowsChange = (rows: FormattedReferredUser[]) => {
    setSelectedRows(rows);
  };

  const handleRowClicked = (row: FormattedReferredUser) => {
    setSelectedUser(row);
    setShowUserInfoModal(true);
  };

  if (!data) return <Loading />;

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      grow: 3,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      grow: 4,
    },
    {
      name: "Status",
      selector: "completionStatus",
      grow: 3,
      sortable: true,
    },
    {
      name: "Progress",
      grow: 1,
      cell(user: FormattedReferredUser) {
        return <ReferredUserProgressIndicator userId={user.id} />;
      },
    },
    {
      name: "Connections",
      grow: 1,
      cell(user: FormattedReferredUser) {
        return <ReferredUserConnectionStatuses userId={user.id} />;
      },
    },
    {
      name: "Registration Date",
      grow: 2,
      sortable: true,
      sortFunction: (a: FormattedReferredUser, b: FormattedReferredUser) => {
        return a.registeredAt >= b.registeredAt ? 1 : -1;
      },
      selector: "registeredAt",
      cell(user: FormattedReferredUser) {
        return user.registeredAt.toLocaleDateString();
      },
    },
    {
      name: "Last Login",
      selector: "lastLoginAt",
      grow: 2,
      sortable: true,
      sortFunction: (a: FormattedReferredUser, b: FormattedReferredUser) => {
        return a.lastLoginAt >= b.lastLoginAt ? 1 : -1;
      },
      cell(user: FormattedReferredUser) {
        return user.lastLoginAt?.toLocaleDateString();
      },
    },
    {
      name: "Documents due",
      selector: "documentsDueDate",
      grow: 2,
      sortable: true,
      sortFunction: (a: FormattedReferredUser, b: FormattedReferredUser) => {
        return a.documentsDueDate >= b.documentsDueDate ? 1 : -1;
      },
      cell(user: FormattedReferredUser) {
        return user.documentsDueDate?.toLocaleDateString();
      },
    },
    {
      name: "Law firm contact",
      selector: "lawFirmContact",
      grow: 2,
      sortable: true,
      sortFunction: (a: FormattedReferredUser, b: FormattedReferredUser) => {
        return a.lawFirmContact >= b.lawFirmContact ? 1 : -1;
      },
    },
    // {
    //   name: "Credit Report Enabled",
    //   grow: 1,
    //   cell(user: FormattedReferredUser) {
    //     return (
    //       <div>
    //         {user.enableCreditReport === true ? (
    //           <FaCheckCircle title="Enabled" className={css.verifiedIcon} />
    //         ) : user.enableCreditReport === false ? (
    //           <FaTimesCircle title="Disabled" className={css.falseIcon} />
    //         ) : (
    //           <FaQuestionCircle
    //             title="Disabled"
    //             className={css.undefinedIcon}
    //           />
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      name: "Actions",
      grow: 2,
      cell(user: FormattedReferredUser) {
        const menuItems = [];
        // Hijack
        if (user.completionStatusRaw !== "ABANDONED") {
          menuItems.push(
            <KebabMenuItem
              onClick={async () => {
                const resp = await Axios.post(
                  `/masquerade/email/${user.email}/`
                );
                if (resp && resp.status === 200) {
                  window.location = resp.request.responseURL;
                }
              }}
            >
              View as {user.name}
            </KebabMenuItem>
          );
        }
        // Download bundle
        if (user.uploadsBundleUrl) {
          menuItems.push(
            <a
              key={"download-bundle"}
              href={`${user.uploadsBundleUrl}`}
              download={`${user?.name}_${
                user.uploadsBundleGeneratedAt
                  ? user.uploadsBundleGeneratedAt.toLocaleString()
                  : ""
              }.zip`.replace(/[,\s]+/g, "_")}
              className={css.menuLink}
            >
              <KebabMenuItem>Download document bundle</KebabMenuItem>
            </a>
          );
        }
        // Force submission
        if (["IN_PROGRESS"].includes(user.completionStatusRaw)) {
          menuItems.push(
            <KebabMenuItem
              onClick={async () => {
                // If the user has already submitted, don't show the warning
                if (user.earliestSubmittedAt) {
                  if (!forceSubmissionLoading) {
                    await forceSubmission({ userId: user.id });
                  }
                } else {
                  setForceSubmissionUserId(user.id);
                  setShowSubmissionConfirmationModal(true);
                }
              }}
            >
              Force submission
            </KebabMenuItem>
          );
        }
        // Reopen intake
        if (
          ["LEXRIA_REVIEWING", "COMPLETED"].includes(user.completionStatusRaw)
        ) {
          menuItems.push(
            <KebabMenuItem
              onClick={async () => {
                if (!reopenIntakeLoading) {
                  await reopenIntake({ userId: user.id });
                }
              }}
            >
              Reopen intake
            </KebabMenuItem>
          );
        }
        // Approve or reject
        if (user.completionStatusRaw === "QUALIFICATION_REVIEW") {
          menuItems.push(
            <KebabMenuItem
              onClick={async () => {
                if (!applicantDisabled) {
                  setApplicantDisabled(true);
                  await approveApplicant({ userId: user.id }).catch(() =>
                    setApplicantDisabled(false)
                  );
                  setApplicantDisabled(false);
                }
              }}
            >
              Approve client
            </KebabMenuItem>,
            <KebabMenuItem
              onClick={async () => {
                if (!applicantDisabled) {
                  setApplicantDisabled(true);
                  await rejectApplicant({ userId: user.id }).catch(() =>
                    setApplicantDisabled(false)
                  );
                  setApplicantDisabled(false);
                }
              }}
            >
              Deny applicant
            </KebabMenuItem>
          );
        }
        // Enable/disable credit report
        // if (user.enableCreditReport === true) {
        //   menuItems.push(
        //     <KebabMenuItem
        //       onClick={async () => {
        //         await updateEnableCreditReport({
        //           userId: user.id,
        //           value: false,
        //         });
        //       }}
        //     >
        //       Disable credit report
        //     </KebabMenuItem>
        //   );
        // }
        // if (user.enableCreditReport === false) {
        //   menuItems.push(
        //     <KebabMenuItem
        //       onClick={async () => {
        //         await updateEnableCreditReport({
        //           userId: user.id,
        //           value: true,
        //         });
        //       }}
        //     >
        //       Enable credit report
        //     </KebabMenuItem>
        //   );
        // }
        return <KebabMenuButton menuItems={menuItems} />;
      },
    },
  ];

  const SubheaderElement = (
    <div className="flex justify-start mr-auto">
      <div className="flex gap-2">
        <Select
          allowDeselect
          placeholder="Actions"
          data={[
            showArchived
              ? { label: "Unarchive users", value: "unarchive" }
              : { label: "Archive users", value: "archive" },
          ]}
          value={selectedAction}
          onChange={(value) => {
            setSelectedAction(value);
          }}
        />
        <Button
          onClick={async () => {
            if (selectedAction === "archive") {
              await updateArchivedStatus({
                userIds: selectedRows.map((row) => row.id),
                archived: true,
              });
            } else if (selectedAction === "unarchive") {
              await updateArchivedStatus({
                userIds: selectedRows.map((row) => row.id),
                archived: false,
              });
            }
            clearSelectedRows();
            setSelectedAction(null);
          }}
          disabled={selectedRows.length === 0}
        >
          Go
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <DataGrid
        data={showArchived ? archivedUsers : users}
        columns={columns}
        searchPlaceholderText={"🔍 Search referred users..."}
        customStyles={customStyles}
        onRowsSelected={handleSelectedRowsChange}
        handleRowClicked={handleRowClicked}
        clearSelectedRows={clearSelected}
        subheaderElement={SubheaderElement}
        filterRecord={(record, searchText) => {
          return (
            record.name.toLowerCase().includes(searchText.toLowerCase()) ||
            record.email.toLowerCase().includes(searchText.toLowerCase()) ||
            record.completionStatus
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
        }}
      />
      <div className="flex justify-between">
        <div>
          <Radio.Group
            orientation="vertical"
            spacing={0}
            defaultValue="active"
            onChange={(value) => {
              if (value === "active") {
                setShowArchived(false);
              } else {
                setShowArchived(true);
              }
              clearSelectedRows();
              setSelectedAction(null);
            }}
          >
            <Radio value="active" label="Active Users" />
            <Radio value="archived" label="Archived Users" />
          </Radio.Group>
          <LearnMore
            linkText={<span className="text-base">Learn more</span>}
            title="Learn more"
          >
            <h4>What happens when I archive users?</h4>
            <p>
              When you archive a user, you simply remove them from your Referred
              Intake Users view. You have the ability to do this to clean up
              your Dashboard by only showing the Active Users you prefer to see
              (this can be very helpful as your list of Users grows).
            </p>
            <p>
              Please note that Lexria is not deleting any archived users, so you
              always have the chance to unarchive them if need be. Archiving and
              unarchiving does not change the state of the Intake – it only
              changes what Users appear as Active in your Dashboard.
            </p>
          </LearnMore>
        </div>
        <div className="flex items-center">
          <Button
            variant="outline"
            leftIcon={
              reportIsLoading ? (
                <FaSpinner className="w-5 h-5 animate-spin" />
              ) : (
                <HiOutlineDocumentDownload
                  title="Download"
                  className="w-5 h-5"
                />
              )
            }
            disabled={reportIsLoading}
            onClick={async () => {
              setReportIsLoading(true);
              const resp = await Axios({
                url: "/law_firm/client_report/",
                method: "GET",
                responseType: "blob",
              });
              const contentDisposition = resp.headers["content-disposition"];
              const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
              const filename = match[1];
              setReportIsLoading(false);
              fileDownload(resp.data, filename);
            }}
          >
            Download report
          </Button>
        </div>
      </div>
      <SubmissionConfirmationModal
        opened={showSubmissionConfirmationModal}
        onConfirm={async () => {
          // NOTE: I'm not sure why typescript has a problem with this
          await forceSubmission({ userId: forceSubmissionUserId });
          setShowSubmissionConfirmationModal(false);
          setForceSubmissionUserId(null);
        }}
        onCancel={() => {
          setShowSubmissionConfirmationModal(false);
          setForceSubmissionUserId(null);
        }}
        onClose={() => {
          setShowSubmissionConfirmationModal(false);
          setForceSubmissionUserId(null);
        }}
        loading={forceSubmissionLoading}
      />
      <UserInfoModal
        opened={showUserInfoModal}
        user={selectedUser || {}}
        onClose={() => {
          setShowUserInfoModal(false);
          setSelectedUser(null);
        }}
        onConfirm={() => {
          setShowUserInfoModal(false);
          setSelectedUser(null);
        }}
        onCancel={() => {
          setShowUserInfoModal(false);
          setSelectedUser(null);
        }}
      />
    </>
  );
}
