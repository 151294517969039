import { BigIcon } from "components/Big";
import {
  ProgressIndicator,
  StepHeading,
  StepProps,
} from "components/Onboarding/AboutTheProcess";
import * as React from "react";
import { FaLeanpub } from "react-icons/fa";

export function Bankruptcy({ total, completed, actions, ...props }: StepProps) {
  return (
    <div {...props}>
      <BigIcon>
        <FaLeanpub />
      </BigIcon>
      <StepHeading>Understanding bankruptcy</StepHeading>
      <ProgressIndicator
        total={total}
        completed={completed}
        actions={actions}
      />
      <p>
        Before starting a bankruptcy case, you will have to gather information
        that will tell the courts about your income, debts, and assets.
        Depending on your financial circumstances and needs, you can file for
        bankruptcy under the Chapter 7 or Chapter 13 rules. Your attorney will
        best understand applicable local and federal law and be able to work
        with you to protect the assets that matter most to you.
      </p>
      <hr />
      <h5>Chapter 7</h5>
      <p>
        Chapter 7 bankruptcies are generally fast and simple. If your income is
        below the median for your family size in your state, you will be able to
        file under Chapter 7 rules. Here, the bankruptcy court takes over the
        unprotected property and goods you own, sells them, and pays your
        creditors with this money. Your debts are then discharged.
      </p>
      <br />
      <h5>Chapter 13</h5>
      <p>
        Chapter 13 bankruptcies are more complex — they require a plan from you
        to repay all or part of their debts over a three to five year period.
        When you complete the payment plan, your debts are then discharged.
        Chapter 13 bankruptcies are used when income exceeds the Chapter 7
        maximum, to protect high value assets that could not be protected in a
        Chapter 7 bankruptcy, to save a house from foreclosure, to save a car
        from repossession, or to protect cosigners of consumer debts.
      </p>
    </div>
  );
}
