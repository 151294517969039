import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary from "components/ModuleSummary";
import LinesSummary from "components/ModuleSummary/LinesSummary";
import { FamilyProfileMaritalChoice } from "globalTypes";
import React from "react";
import { formatName } from "utils/formatters";
import SPOUSE_MODULE from "./SpouseModule.gql";

export default function SpouseSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<SpouseModule>(SPOUSE_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress?.spouse}>
      <LinesSummary
        requiredData={data?.familyProfile?.maritalStatus}
        maxLines={1}
        maxWidth="15em"
        style={{ textAlign: "center" }}
      >
        {data?.familyProfile?.maritalStatus ===
        FamilyProfileMaritalChoice.Unmarried ? (
          "You are unmarried"
        ) : (
          <span>
            {data?.familyProfile?.maritalStatus ===
              FamilyProfileMaritalChoice.Married && "Married to"}
            {data?.familyProfile?.maritalStatus ===
              FamilyProfileMaritalChoice.Divorced && "Divorced from"}
            {data?.familyProfile?.maritalStatus ===
              FamilyProfileMaritalChoice.Widowed && "Widowed from"}
            {data?.familyProfile?.maritalStatus ===
              FamilyProfileMaritalChoice.Separated && "Separated from"}{" "}
            {data?.familyProfile?.spouse &&
              formatName(data.familyProfile.spouse)}
          </span>
        )}
        .
      </LinesSummary>
    </ModuleSummary>
  );
}
