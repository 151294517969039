import SectionSummary from "components/FormModuleSubMenu/SectionSummary";
import { SectionIndexProps } from "components/FormModuleSubMenu/SubmenuModule";
import React from "react";
import assetsIllustration from "./assets.svg";
import ASSETS_INDEX from "./AssetsIndex.gql";

export default function AssetsSummary(props: SectionIndexProps) {
  return (
    <SectionSummary<AssetsIndex>
      {...props}
      query={ASSETS_INDEX}
      image={
        <img
          src={assetsIllustration}
          alt="A person sitting on a wallet, holding a coin"
          width={406}
          height={306}
        />
      }
      heroCopy={
        <p>
          To assist you, your lawyer will need to know about any assets you own.
          We&rsquo;ll walk you through different types of assets, like real
          estate, vehicles, and personal/household items so you can build an
          accurate picture for them.
        </p>
      }
      completedCopy={
        <p>
          It looks like we have all the information your lawyer needs to know
          about your assets. If the information below is accurate and complete,
          you&rsquo;re all done with this section!
        </p>
      }
    />
  );
}
