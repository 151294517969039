import { useMutation } from "@apollo/client";
import CREATE_OTHER_PERSON from "api/graphql/OtherPerson/CreateOtherPerson.gql";
import { Card } from "components/Card";
import { CoownerForm } from "components/FormSections/Coowner";
import { Modal } from "components/Modal";
import * as React from "react";
import { Route, useHistory } from "react-router-dom";
import { unnestIds } from "utils/ids";
import { Details } from "../CoownersBreakdown";
import css from "./styles.module.css";

export interface EditCoownerModalProps {
  relatedTypename: string;
  relatedId: UUID;
  displayName: string;
  onEditCoowner: ({
    id,
    details,
    value,
  }: {
    id: UUID;
    details?: Details;
    value?: number;
  }) => void;
  modalPath: string;
  getIdFromPathMatch: (match?: any) => UUID;
  nextURL: string;
}

export function EditCoownerModal({
  relatedTypename,
  relatedId,
  displayName,
  onEditCoowner,
  modalPath,
  getIdFromPathMatch,
  nextURL,
}: EditCoownerModalProps) {
  const history = useHistory();
  const [upsertOtherPerson] = useMutation<
    CreateOtherPerson,
    CreateOtherPersonVariables
  >(CREATE_OTHER_PERSON);

  return (
    <Route
      path={modalPath}
      render={({ match }) => (
        <Modal isOpen>
          <Card className={css["modal-body"]}>
            <h3>Updating {displayName} co-owner</h3>
            <CoownerForm
              relatedId={relatedId}
              relatedTypename={relatedTypename}
              id={getIdFromPathMatch(match)}
              onAbort={() => history.push(nextURL)}
              onSubmit={async (input) => {
                const res = await upsertOtherPerson({
                  variables: {
                    input: unnestIds(input),
                  },
                });
                onEditCoowner({
                  id: res.data.otherPerson.id,
                  details: {
                    personType: "OtherPerson",
                    ...res.data.otherPerson,
                  },
                });
                history.push(nextURL);
              }}
              saveText={`Update co-owner`}
            />
          </Card>
        </Modal>
      )}
    />
  );
}
