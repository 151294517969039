import { useQuery } from "@apollo/client";
import { BooleanInput } from "components/BooleanInput";
import { useEditorBuffer } from "components/EditorState/hooks";
import GetOrCreate from "components/GetOrCreate";
import { HiddenInput } from "components/HiddenInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import gql from "graphql-tag";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";
import { formatName } from "utils/formatters";
import { boolean, object, string } from "yup";

export const CofilingSchema = object({
  isCofiled: boolean().nullable().required("This field is required."),
  isPrimaryFiler: boolean().nullable().required("This field is required."),
  otherFilerId: string().when("isCofiled", {
    is: true,
    then: string().required("Please select a co-filer."),
    otherwise: string().optional().nullable(),
  }),
}).required();

type CofilingFormState = ReturnType<typeof CofilingSchema.validateSync>;

const GET_ELIGIBLE_COFILERS = gql`
  query GetEligibleTaxReturnCofilers($returnID: UUID!) {
    taxReturn(id: $returnID) {
      id
      eligibleOtherFilers {
        id
        firstName
        middleName
        lastName
        suffix
        birthday
      }
    }
  }
`;

export default function Cofiling({ navProps }: WizardRouteChildProps) {
  const form = useFormContext<CofilingFormState>();
  const { params } = useRouteMatch<{ editId?: string }>();
  const { data, loading } = useQuery<
    GetEligibleTaxReturnCofilers,
    GetEligibleTaxReturnCofilersVariables
  >(GET_ELIGIBLE_COFILERS, {
    variables: { returnID: params.editId },
    fetchPolicy: "cache-and-network",
  });
  const [{ committed }] = useEditorBuffer();

  return (
    <>
      <BooleanInput
        name="isCofiled"
        label="Did you co-file this return with someone?"
      />

      {form.watch("isCofiled") ? (
        <>
          <BooleanInput
            name="isPrimaryFiler"
            label="Were you the primary filer?"
          />
          <p className="required">Who did you file this return with?</p>
          <GetOrCreate
            createURL="create_other_filer"
            name="otherFilerId"
            loading={loading}
            initialValue={committed?.otherFiler?.id}
            objectName="co-filer"
            options={data?.taxReturn?.eligibleOtherFilers.map((p) => ({
              label: formatName(p),
              value: p.id,
            }))}
          />
        </>
      ) : (
        <>
          <HiddenInput name="isPrimaryFiler" value={true} />
          <HiddenInput name="otherFiler" value={null} />
        </>
      )}
      <WizardNavigation {...navProps} />
    </>
  );
}
