import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { StudentLoans } from "./StudentLoans";
import StudentLoansSummary from "./Summary";

const StudentLoansSubmodule: SubModule = {
  path: "student_loans",
  title: "Student Loans",
  icon: StudentLoans.Icon,
  component: StudentLoans,
  summary: StudentLoansSummary,
  statusName: "studentLoans",
};

export default StudentLoansSubmodule;
