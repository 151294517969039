import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import LAWSUITS_MODULE from "./LawsuitsModule.gql";

export default function LawsuitsSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<LawsuitsModule>(LAWSUITS_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.lawsuits}>
      {data?.filingProfile?.hasNoLawsuits ? (
        <HasNoSummary>
          Your haven&rsquo;t been involved in any lawsuits in the last 12
          months.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="lawsuit"
            maxValueLength={1}
            value={data?.lawsuits?.length}
            pluralizeLabel
          />

          <NumericSummary
            label="received"
            maxValueLength={5}
            valuePrefix="$"
            value={fancyNumberSum(data?.lawsuits, "amountReceived")}
          />

          <NumericSummary
            label="forfeited"
            maxValueLength={5}
            valuePrefix="$"
            value={fancyNumberSum(data?.lawsuits, "amountPaid", "amountOwed")}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
