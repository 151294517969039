import { useDeleteStudentLoan } from "api/graphql/StudentLoan";
import { ButtonRow } from "components/ButtonRow";
import { Card } from "components/Card";
import { ListViewProps } from "components/EditCreateList/stateless";
import { Title } from "components/Title";
import { last } from "lodash";
import * as React from "react";
import { FaArrowLeft, FaArrowRight, FaCheck, FaPlus } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { formatQuantity } from "utils/formatters";
import { StudentLoanStatement } from "../AddStatementWizard";
import { StatementLoanGroupSummary } from "../ListView";
import LoanListItem from "../LoanListItem";
import css from "../StudentLoans.module.scss";

interface SummaryProps extends ListViewProps {
  items: StudentLoansModule["studentLoans"];
  statement?: StudentLoanStatement;
}

export default function ListView({
  items,
  statement,
  nextURL,
  prevURL,
  onEdit,
  createURL,
}: SummaryProps) {
  const [deleteStudentLoan] = useDeleteStudentLoan();
  const history = useHistory();

  return (
    <>
      <Title>Student Loan Statement</Title>

      {items.length ? (
        <p>
          Continue adding loans until there is an entry for each loan on this
          statement.
        </p>
      ) : (
        <>
          <p>
            We need to collect a little more detailed information about each of
            the loans on this statement.
          </p>
          <p>
            <strong>Note:</strong> This statement will not be saved until you
            add at least one loan to it.
          </p>
        </>
      )}

      {items.length ? (
        <ButtonRow
          left={
            <Link to={createURL} className="secondary btn">
              <FaPlus />
              <span>Add loan info</span>
            </Link>
          }
          right={
            <Link to={nextURL} className="primary btn">
              <span>Done</span>
              <FaCheck />
            </Link>
          }
        />
      ) : (
        <ButtonRow
          left={
            <Link to={prevURL} className="btn">
              <FaArrowLeft />
              <span>Back</span>
            </Link>
          }
          right={
            <Link to={createURL} className="primary btn">
              <span>Add first loan</span>
              <FaArrowRight />
            </Link>
          }
        />
      )}

      {!!items.length && (
        <Card>
          <h3>
            {formatQuantity(
              items.length,
              ` ${items[0]._type.toLowerCase()} student loan`
            )}
          </h3>

          <StatementLoanGroupSummary
            loans={items}
            excludeValidation
            statementFile={
              items.length
                ? items[0]?.statementFile
                : (statement.statementFile as File)
            }
          />

          <hr className={css.statementSeparator} />

          {items.map((l) => (
            <LoanListItem
              id={`loan-${l.id}`}
              key={l.id}
              loan={l}
              onEdit={() => onEdit(l.id)}
              onDelete={async () => {
                await deleteStudentLoan(l.id);
                if (items.length === 1) history.push(nextURL);
              }}
            />
          ))}
        </Card>
      )}
    </>
  );
}
