import {
  FaFunnelDollar,
  FaHeartbeat,
  FaHome,
  FaMoneyCheck,
  FaPiggyBank,
  FaReceipt,
  FaUniversity,
  FaWallet,
} from "react-icons/fa";
import { IconBaseProps } from "react-icons/lib/cjs";
import { FinancialAccountTypeChoice } from "globalTypes";

type FinancialAccountType = {
  value: FinancialAccountTypeChoice;
  icon: React.FC<IconBaseProps>;
};

export const financialAccountTypes: FinancialAccountType[] = [
  {
    value: FinancialAccountTypeChoice.Checking,
    icon: FaMoneyCheck,
  },
  {
    value: FinancialAccountTypeChoice.Savings,
    icon: FaPiggyBank,
  },
  {
    value: FinancialAccountTypeChoice.Retirement,
    icon: FaFunnelDollar,
  },
  {
    value: FinancialAccountTypeChoice.InvestmentOther,
    icon: FaReceipt,
  },
  {
    value: FinancialAccountTypeChoice.InvestmentEducational,
    icon: FaUniversity,
  },
  {
    value: FinancialAccountTypeChoice.MortgageEscrow,
    icon: FaHome,
  },
  {
    value: FinancialAccountTypeChoice.Hsa,
    icon: FaHeartbeat,
  },
  {
    value: FinancialAccountTypeChoice.Other,
    icon: FaWallet,
  },
];
