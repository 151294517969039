import DollarsInput from "components/DollarsInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";

export default function Taxes({ navProps }: WizardRouteChildProps) {
  return (
    <>
      <p>In the average month, how much does this company spend on:</p>

      <div className="form-row">
        <DollarsInput
          name="monthlyPayrollTaxes"
          label="Payroll taxes"
          autoFocus
        />
        <DollarsInput
          name="monthlyUnemploymentTaxes"
          label="Unemployment taxes"
        />
      </div>

      <DollarsInput name="monthlyOtherTaxes" label="Other taxes" />

      <WizardNavigation {...navProps} />
    </>
  );
}
