import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_LAWSUIT from "./DeleteLawsuit.gql";
import GET_LAWSUIT from "./GetLawsuit.gql";
import LIST_LAWSUIT from "./ListLawsuit.gql";
import UPSERT_LAWSUIT from "./UpsertLawsuit.gql";

const LIST_LAWSUIT_IDS = gql`
  query ListLawsuitIds {
    lawsuits {
      id
    }
  }
`;

export function useListLawsuit(options?: QueryHookOptions<ListLawsuit>) {
  return useQuery<ListLawsuit>(LIST_LAWSUIT, options);
}

export function useGetLawsuit(id: string) {
  return useQuery<GetLawsuit, GetLawsuitVariables>(GET_LAWSUIT, {
    variables: { id },
  });
}

export function useUpsertLawsuit<
  Schema extends { lawsuit: any } = UpsertLawsuit,
  Variables = UpsertLawsuitVariables
>(query = UPSERT_LAWSUIT) {
  return useUpsert<Schema, Variables, ListLawsuitIds, LawsuitInput>(
    query,
    LIST_LAWSUIT_IDS,
    "lawsuit",
    "lawsuits",
    (cache) => updateFilingProfileCache(cache, "hasNoLawsuits", false)
  );
}

export function useDeleteLawsuit() {
  return useDelete<DeleteLawsuit, ListLawsuitIds>(
    DELETE_LAWSUIT,
    LIST_LAWSUIT_IDS,
    "deleteLawsuit",
    "lawsuits"
  );
}
