import numbro from "numbro";

export const formatFancyNumber = (n: number | string, decimalPlaces = 2) =>
  numbro(n).format({
    thousandSeparated: true,
    mantissa: decimalPlaces,
    trimMantissa: false,
  });

export const formatTrimmedFancyNumber = (n: number | string) =>
  numbro(n).format({
    thousandSeparated: true,
    mantissa: 2,
    trimMantissa: true,
  });

export const formatMoneyForDisplay = (
  amount: string | number,
  defaultNaN = "?"
) => {
  if (numbro.unformat(amount as string) !== undefined) {
    return numbro(amount).formatCurrency({ mantissa: 0, average: true });
  }
  return defaultNaN;
};

export const formatNumberForDisplay = (
  value: string | number,
  defaultNaN = "?"
) => {
  if (numbro.unformat(value as string) !== undefined) {
    return numbro(value).format({ mantissa: 0, average: true });
  }
  return defaultNaN;
};

export const formatNumberToWords = (
  value: string | number,
  direction = Math.floor,
  defaultNaN = "?"
): string => {
  const parsed = numbro.unformat(value as string);
  if (parsed === undefined || isNaN(parsed)) {
    console.error(`Invalid number ${value}`);
    return defaultNaN;
  } else if (parsed > 100 || parsed < 0) {
    console.error("Only numbers 0 to 100 can be formatted to words");
    return defaultNaN;
  }

  const integerValue = direction(parsed);

  switch (integerValue) {
    case 0:
      return "zero";
    case 1:
      return "one";
    case 2:
      return "two";
    case 3:
      return "three";
    case 4:
      return "four";
    case 5:
      return "five";
    case 6:
      return "six";
    case 7:
      return "seven";
    case 8:
      return "eight";
    case 9:
      return "nine";
    case 10:
      return "ten";
    case 11:
      return "eleven";
    case 12:
      return "twelve";
    case 13:
      return "thirteen";
    case 14:
      return "fourteen";
    case 15:
      return "fifteen";
    case 16:
      return "sixteen";
    case 17:
      return "seventeen";
    case 18:
      return "eighteen";
    case 19:
      return "nineteen";
    case 20:
      return "twenty";
    case 30:
      return "thirty";
    case 40:
      return "forty";
    case 50:
      return "fifty";
    case 60:
      return "sixty";
    case 70:
      return "seventy";
    case 80:
      return "eighty";
    case 90:
      return "ninety";
    default:
      return `${formatNumberToWords(
        10 * Math.floor(integerValue / 10)
      )} ${formatNumberToWords(integerValue % 10)}`;
  }
};
