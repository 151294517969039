import { useUpsertAssetSale } from "api/graphql/AssetSale";
import { Wizard } from "components/Wizard";
import React from "react";
import { useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import { Buyers, BuyersSchema } from "./Buyers";
import { Item, ItemSchema } from "./Item";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  /** The URL to switch to after the initial save. Created object ID will be appended */
  editURL?: string;
}

export const AssetSaleSchema = ItemSchema.concat(BuyersSchema);

export function AssetSaleWizard({
  backURL,
  nextURL,
  initialValue,
  title,
  editURL = "edit",
}: Props) {
  const [upsertAssetSale] = useUpsertAssetSale();
  const history = useHistory();
  const { url } = useNested();

  return (
    <Wizard
      title={title}
      backURL={backURL}
      nextURL={nextURL}
      initialValue={initialValue}
      vertical
      steps={[
        {
          path: "/item",
          title: "Asset Sold",
          component: Item,
          schema: ItemSchema,
          async onSave(formState) {
            const { data } = await upsertAssetSale(formState);
            setTimeout(() => {
              if (data.assetSale.id) {
                history.push(url("./buyers"));
              } else {
                history.push(url(`../${editURL}/${data.assetSale.id}/buyers`));
              }
            }, 0);
            return data.assetSale;
          },
        },
        {
          path: "/buyers",
          title: "Buyers/Recipients",
          component: Buyers,
          schema: BuyersSchema,
          includeSubpaths: true,
        },
      ]}
    />
  );
}
