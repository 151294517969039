import { useUpsertCreditCardDebt } from "api/graphql/CreditCardDebt";
import { CollectorForm } from "components/FormSections/Collector";
import { CosignerForm } from "components/FormSections/Cosigner";
import { Wizard } from "components/Wizard";
import { CollectorsSchema } from "components/WizardSteps/Collectors";
import { CosignersSchema } from "components/WizardSteps/Cosigners";
import React, { useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import Collectors from "./Collectors";
import Cosigners from "./Cosigners";
import CREDIT_CARD_DEBT_WIZARD_CREDIT_CARD_DEBT_UPSERT from "./CreditCardDebtWizardCreditCardDebtUpsert.gql";
import Details, { DetailsSchema } from "./Details";
import Lender, { LenderSchema } from "./Lender";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
}

export const CreditCardDebtSchema = LenderSchema.concat(DetailsSchema)
  .concat(CosignersSchema)
  .concat(CollectorsSchema);

export function CreditCardDebtWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: Props) {
  const { path, url } = useNested();
  const [upsertCreditCardDebt] = useUpsertCreditCardDebt<
    CreditCardDebtWizardCreditCardDebtUpsert,
    CreditCardDebtWizardCreditCardDebtUpsertVariables
  >(CREDIT_CARD_DEBT_WIZARD_CREDIT_CARD_DEBT_UPSERT);
  const history = useHistory();
  const [creditCardDebtId, setCreditCardDebtId] = useState(initialValue?.id);

  const cosignerURL = "/cosigners";
  const collectorURL = "/collectors";

  return (
    <Switch>
      <Route
        path={path("/create_cosigner")}
        render={() => (
          <CosignerForm
            title="Add a Credit Card Cosigner"
            returnURL={url(cosignerURL)}
            relatedId={creditCardDebtId}
            relatedTypename="CreditCardDebt"
          />
        )}
      />
      <Route
        path={path("/edit_cosigner/:cosignerId")}
        render={({ match }) => (
          <CosignerForm
            title="Updating Credit Card Cosigner"
            returnURL={url(cosignerURL)}
            relatedId={creditCardDebtId}
            relatedTypename="CreditCardDebt"
            id={match.params.cosignerId}
          />
        )}
      />

      <Route
        path={path("/create_collector")}
        render={() => (
          <CollectorForm
            title="Add a Credit Card Debt Collector"
            returnURL={url(collectorURL)}
            relatedId={creditCardDebtId}
            relatedTypename="CreditCardDebt"
          />
        )}
      />
      <Route
        path={path("/edit_collector/:collectorId")}
        render={({ match }) => (
          <CollectorForm
            title="Updating Credit Card Debt Collector"
            returnURL={url(collectorURL)}
            relatedId={creditCardDebtId}
            relatedTypename="CreditCardDebt"
            id={match.params.collectorId}
          />
        )}
      />

      <Wizard
        title={title}
        backURL={backURL}
        nextURL={nextURL}
        initialValue={initialValue}
        vertical
        steps={[
          {
            path: "/account",
            title: "Account",
            component: Lender,
            schema: LenderSchema,
          },
          {
            path: "/details",
            title: "Debt details",
            component: Details,
            schema: DetailsSchema,
            async onSave(formState) {
              const { data } = await upsertCreditCardDebt(formState);
              const { id } = data.creditCardDebt;
              setCreditCardDebtId(id);
              setTimeout(() => {
                if (creditCardDebtId) {
                  history.push(url("/cosigners"));
                } else {
                  history.push(`../edit/${id}/cosigners`);
                }
              }, 0);
              return data.creditCardDebt;
            },
          },
          {
            path: cosignerURL,
            title: "Cosigners",
            component: Cosigners,
            schema: CosignersSchema,
          },
          {
            path: collectorURL,
            title: "Collectors",
            component: Collectors,
            schema: CollectorsSchema,
          },
        ]}
      />
    </Switch>
  );
}
