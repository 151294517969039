import { useEditorBuffer } from "components/EditorState/hooks";
import DocumentsUpload from "components/FormSections/DocumentsUpload";
import { LearnMore } from "components/LearnMore";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { getPastDateIntervalFromDuration, TimeUnit } from "utils/dates";
import {
  formatDateIntervalDuration,
  formatNumberToWords,
  formatPlural,
} from "utils/formatters";
import { array, mixed, object } from "yup";

export const PayChecksSchema = object({
  documents: array(mixed<File | string>()).min(
    1,
    "You haven’t provided any documentation about this expense."
  ),
}).required();

export function PayChecks({ isEdit, navProps }: WizardRouteChildProps) {
  const [{ buffer }] = useEditorBuffer();
  const dateInterval = getPastDateIntervalFromDuration({
    length: 7,
    unit: TimeUnit.Month,
  });
  const paychecksNeeded = 1;
  const displayPaychecksNeeded =
    paychecksNeeded <= 10
      ? formatNumberToWords(paychecksNeeded)
      : `${paychecksNeeded}`;

  const requiredInterval = dateInterval
    ? formatDateIntervalDuration(dateInterval)
    : "last month";

  return (
    <>
      <DocumentsUpload
        relatedTypename={"EmploymentRecord"}
        displayText={
          <p>
            Upload your most recent pay stubs showing your income from this
            employer over the last {requiredInterval}. Please upload those
            documents in PDF format.&nbsp;
            <LearnMore>
              <h4>
                What if I or my family-member is self-employed and do not
                receive pay stubs?
              </h4>
              <p>
                Upload some other record of income covering at least the last{" "}
                {requiredInterval}.
              </p>

              <h4>
                I or my family member just started a new job, and don&rsquo;t
                have {paychecksNeeded === 1 ? "a" : displayPaychecksNeeded}{" "}
                {formatPlural(paychecksNeeded, "paycheck")} yet.
              </h4>
              <p>
                <a href="mailto:support@lexria.com">Email us </a> and we will
                work out a different way to verify your employment.
              </p>
            </LearnMore>
          </p>
        }
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
