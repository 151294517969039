import { useCallback, useEffect, useState } from "react";

/** Hook for detecting page visibility using the [Page Visibility API]() */
export default function usePageVisible() {
  const [isVisible, setVisible] = useState(true);

  let visibilityChange: string | undefined;
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    visibilityChange = "visibilitychange";
  } else if (typeof (document as any).msHidden !== "undefined") {
    visibilityChange = "msvisibilitychange";
  } else if (typeof (document as any).webkitHidden !== "undefined") {
    visibilityChange = "webkitvisibilitychange";
  }

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === "hidden") {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, []);

  useEffect(() => {
    if (
      typeof document.addEventListener === "undefined" ||
      visibilityChange === undefined
    ) {
      setVisible(true);
    } else {
      // Handle page visibility change
      document.addEventListener(
        visibilityChange,
        handleVisibilityChange,
        false
      );
      return () =>
        document.removeEventListener(
          visibilityChange,
          handleVisibilityChange,
          false
        );
    }
  }, [handleVisibilityChange, visibilityChange]);

  return isVisible;
}
