import { useGetVehicleLease } from "api/graphql/VehicleLease";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import { VehicleLeaseWizard } from "./VehicleLeaseWizard";

interface Props {
  id: string;
  listURL: string;
}

export default function EditView({ id, listURL }: Props) {
  const { data, loading } = useGetVehicleLease(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.vehicleLease) {
    return <Redirect to={listURL} />;
  }

  return (
    <VehicleLeaseWizard
      title="Updating Vehicle Lease"
      backURL={listURL}
      nextURL={listURL}
      initialValue={data.vehicleLease}
    />
  );
}
