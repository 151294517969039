import { useListRoot } from "components/EditCreateList/hooks";
import {} from "components/EditCreateList/stateless";
import * as React from "react";
import { AssetLossWizard } from "./AssetLossWizard";

export function CreateView({ listURL }: { listURL: string }) {
  const root = useListRoot();
  const [nextURL, setNextURL] = React.useState(`${root.url}/summary`);
  return (
    <AssetLossWizard
      title="Add a lost asset"
      backURL={`${root.url}/summary`}
      nextURL={nextURL}
    />
  );
}
