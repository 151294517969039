// Taken from Nextjs examples:
// https://github.com/vercel/next.js/tree/canary/examples/with-portals

import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export default function ClientOnlyPortal({ children, selector }) {
  const overlayRef = useRef();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    overlayRef.current = document.querySelector(selector);
    setMounted(true);
  }, [selector]);

  return mounted ? createPortal(children, overlayRef.current) : null;
}
