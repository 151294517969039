import { ButtonRow } from "components/ButtonRow";
import React from "react";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

export interface Props {
  prev?: string;
  next?: string;
}

export default function SuccessView(props: Props) {
  return (
    <>
      <p>
        Your credit report has been successfully imported. You’re all ready to
        continue on to the next section.
      </p>
      <ButtonRow
        right={
          props.next && (
            <Link to={props.next} className="btn primary">
              <span>Done</span>
              <FaCheck />
            </Link>
          )
        }
      />
    </>
  );
}
