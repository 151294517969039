import {
  useGetFinancialAccount,
  useUpsertFinancialAccount,
} from "api/graphql/FinancialAccount";
import Coowners from "components/FormModules/Assets/FinancialAccounts/FinancialAccountWizard/Coowners";
import { Loading } from "components/Loading";
import { Wizard, WizardRouteChildProps } from "components/Wizard";
import {
  CoOwnersSchema,
  CoownersWizardStepProps,
  useDelegatedOnSave,
} from "components/WizardSteps/Coowners";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useNested, useQueryParams } from "utils/hooks";
import { OptionalKeys } from "utils/types";
import Account, { AccountSchema } from "./Account";
import Deposits, { DepositsSchema } from "./Deposits";
import Institution, { InstitutionSchema } from "./Institution";
import Statements, { StatementsSchema } from "./Statements";
import Withdrawals, { WithdrawalsSchema } from "./Withdrawals";

interface Props {
  accountType: FinancialAccountTypeChoice;
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  /** The URL to switch to after the initial save. Created object ID will be appended */
  editURL?: string;
  subtitle?: React.ReactElement;
}

export const FinancialAccountSchema = AccountSchema.concat(DepositsSchema)
  .concat(WithdrawalsSchema)
  .concat(InstitutionSchema)
  .concat(CoOwnersSchema)
  .concat(StatementsSchema);

export function FinancialAccountWizard({
  accountType,
  backURL,
  nextURL,
  initialValue,
  title,
  editURL = "edit",
}: Props) {
  const { url } = useNested();
  const [upsertFinancialAccount] = useUpsertFinancialAccount(undefined);
  const history = useHistory();

  const { inherits } = useQueryParams();
  const { data } = useGetFinancialAccount(inherits, { skip: !inherits });

  const imported = !!initialValue?.mxAccountId;
  const importedTransactions = !!initialValue?.importedTransactionsLength;

  const [financialAccountId, setFinancialAccountId] = useState(
    initialValue?.id
  );
  const [registerOnSaveCoowners, onSaveCoowners] = useDelegatedOnSave();
  const CoownersWithOnSave = useCallback(
    (props: CoownersWizardStepProps) => (
      <Coowners {...props} registerOnSave={registerOnSaveCoowners} />
    ),
    [registerOnSaveCoowners]
  );
  const coownersURL = "/coowners";

  const InstitutionWithProps = (props: WizardRouteChildProps) => (
    <Institution {...props} accountType={accountType} />
  );

  if (inherits && !data) return <Loading />;
  const { institutionName, contactAddress } = data?.financialAccount || {};

  async function onSave(
    formState: OptionalKeys<
      FinancialAccountInput,
      "institutionName" | "contactAddress"
    >
  ) {
    formState.accountType = accountType;
    const { data } = await upsertFinancialAccount({
      institutionName,
      contactAddress,
      ...formState,
    });
    const { id } = data.financialAccount;
    setFinancialAccountId(id);

    // TODO: refactor wizard so this silly asynchronicity isn't required
    setTimeout(() => {
      if (financialAccountId) {
        history.push(url(coownersURL));
      } else {
        history.push(`../../${editURL}/${id}${coownersURL}`);
      }
    }, 0);
    return data.financialAccount;
  }

  return (
    <Wizard
      title={title}
      subtitle={
        <strong>
          Tell us about a single financial account{" "}
          {inherits && `at ${institutionName}`} owned by a household member.
        </strong>
      }
      backURL={backURL}
      nextURL={nextURL}
      initialValue={{ institutionName, contactAddress, ...initialValue }}
      vertical
      steps={[
        !inherits && {
          path: "/institution",
          title: "Institution",
          component: InstitutionWithProps,
          schema: InstitutionSchema,
        },
        {
          path: "/account",
          title: "Account",
          component: Account,
          schema: AccountSchema,
          onSave: imported ? onSave : undefined,
        },
        !importedTransactions && {
          path: "/deposits",
          title: "Deposits",
          component: Deposits,
          schema: DepositsSchema,
        },
        !importedTransactions && {
          path: "/withdrawals",
          title: "Withdrawals",
          component: Withdrawals,
          schema: WithdrawalsSchema,
          onSave,
        },
        {
          path: coownersURL,
          title: "Co-owners",
          component: CoownersWithOnSave,
          schema: CoOwnersSchema,
          includeSubpaths: true,
          onSave: onSaveCoowners,
          navigateAfterSave: !importedTransactions ? true : undefined,
        },
        !importedTransactions && {
          path: "/statements",
          title: "Statements",
          component: Statements,
          schema: StatementsSchema,
        },
      ]}
    />
  );
}
