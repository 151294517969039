import { ApolloQueryResult } from "@apollo/client";
import { queryAddress } from "api/graphql/Address";
import { useListMailingAddresses } from "api/graphql/MailingAddress";
import { useListResidentialAddresses } from "api/graphql/ResidentialAddress";
import { BooleanInput } from "components/BooleanInput";
import { DateInput } from "components/DateInput";
import { AddressForm } from "components/FormSections/Address";
import GetOrCreateAddress from "components/GetOrCreate/GetOrCreateAddress";
import { HiddenInput } from "components/HiddenInput";
import { Loading } from "components/Loading";
import { DecimalInput } from "components/NumberInput";
import { TextInput } from "components/TextInput";
import { WizardNavigation } from "components/WizardNavigation";
import {
  addMonths,
  format,
  isAfter,
  isBefore,
  isToday,
  min,
  parseISO,
  startOfToday,
  subMonths,
} from "date-fns";
import { uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { required } from "utils/defaultMessages";
import { useLatestResult } from "utils/hooks";

const COMMUNITY_PROPERTY_STATES = [
  "AZ",
  "CA",
  "ID",
  "LA",
  "NV",
  "NM",
  "PR",
  "TX",
  "WA",
  "WI",
];

interface Props {
  minDate?: Date;
  maxDate?: Date;
  prevResidentTo?: ISODate;
  nextResidentFrom?: ISODate;
  abortURL?: string;
  isEdit?: boolean;
  autoFocus?: boolean;
}

export default function ResidenceForm({
  abortURL,
  nextResidentFrom,
  prevResidentTo,
  isEdit,
}: Props) {
  const form = useFormContext();

  const { data: mailingData } = useListMailingAddresses();
  const { data: residenceData } = useListResidentialAddresses();
  const [addressStateAbbr, setAddressStateAbbr] = useState<string>(null);
  const addressId = form.watch("addressId");
  const address = form.watch("address");
  const [enqueue] = useLatestResult();

  useEffect(() => {
    if (!addressId && !addressStateAbbr) return;

    enqueue(
      addressId ? queryAddress(addressId) : Promise.resolve(null),
      (result?: ApolloQueryResult<GetAddress>) => {
        if (addressStateAbbr === result?.data?.address?.state?.abbr) {
          return;
        }

        setAddressStateAbbr(result?.data?.address?.state?.abbr || null);
      }
    );
  }, [addressId, addressStateAbbr, enqueue]);

  if (!mailingData || !residenceData) return <Loading />;
  const residentialAddressIds = residenceData.residentialAddresses.map(
    (ra) => ra.address.id
  );

  let addressOptions: Address[];
  if (!isEdit) {
    addressOptions = mailingData.mailingAddresses.filter(
      (a) => !residentialAddressIds.includes(a.id)
    );
  } else {
    addressOptions = uniqBy(
      [
        ...mailingData.mailingAddresses,
        ...residenceData.residentialAddresses.map((ra) => ra.address),
      ],
      "id"
    );
  }

  let maxDate = startOfToday();
  let minDate: Date | undefined;
  if (nextResidentFrom) {
    const maxOverlapEnd = addMonths(parseISO(nextResidentFrom), 1);
    maxDate = min([maxDate, maxOverlapEnd]);
  }
  if (prevResidentTo) {
    minDate = subMonths(parseISO(prevResidentTo), 1);
  }

  const globallyDisabled = {
    [`Please enter a date after ${
      minDate && format(minDate, "MM/dd/yyyy")
    }, a month before you moved out of your previous address.`]: {
      before: minDate,
    },
    [`Please enter a date before ${
      maxDate && format(maxDate, "MM/dd/yyyy")
    }, a month after you moved into your subsequent address.`]: {
      after: maxDate,
    },
  };

  return (
    <>
      <GetOrCreateAddress
        name="address"
        idName="addressId"
        objectName="address"
        options={addressOptions}
        required="Address is required."
        autoFocus
      />

      {/*  Pull the state for existing (but not edited) addresses to
           allow community property question to work */}
      {!!addressId && !address && (
        <HiddenInput name="_stateAbbr" value={addressStateAbbr} />
      )}

      <p style={{ marginTop: "24px" }}>When did you live at this address?</p>

      <div className="form-row">
        <DateInput
          name="residentFrom"
          label="From"
          defaultValue={prevResidentTo}
          rules={{ required }}
          onChange={() => {
            if (form.formState.isSubmitted) {
              form.trigger("residentTo");
            }
          }}
          disabledDays={{
            "Move-in should be before move-out.": (date) =>
              isAfter(date, parseISO(form.watch("residentTo"))),
            ...globallyDisabled,
          }}
        />
        <DateInput
          name="residentTo"
          label="To"
          defaultValue={nextResidentFrom}
          onChange={() => {
            if (form.formState.isSubmitted) {
              form.trigger("residentFrom");
            }
          }}
          disabledDays={{
            "Move-out should be after move-in.": (date) =>
              isBefore(date, parseISO(form.watch("residentFrom"))),
            ...globallyDisabled,
          }}
          helpText="Leave this blank if you live here now"
        />
      </div>

      {COMMUNITY_PROPERTY_STATES.includes(
        form.watch("address.state.abbr") || form.watch("_stateAbbr")
      ) ? (
        <BooleanInput
          name="livedWithSpouseCommunityProperty"
          required
          label="Did a spouse or legal equivalent live with you at this address?"
        />
      ) : (
        <HiddenInput name="livedWithSpouseCommunityProperty" value={false} />
      )}

      {!form.watch("residentTo") ||
      isToday(parseISO(form.watch("residentTo"))) ? (
        <BooleanInput
          name="isNotARenter"
          required
          label="Do you currently rent this address?"
          yesLabel="No"
          noLabel="Yes"
        />
      ) : (
        <HiddenInput name="isNotARenter" value={true} />
      )}

      {form.watch("isNotARenter") === false && (
        <>
          <TextInput
            name="landlordName"
            label="Landlord’s name"
            rules={{ required }}
          />
          <HiddenInput name="landlordAddressId" />
          <p>Landlord’s address</p>
          <AddressForm name="landlordAddress" graphQL />
          <BooleanInput
            name="landlordHasSentEvictionNotice"
            label="Has your landlord sent you an eviction notice?"
            required
          />
          <BooleanInput
            name="landlordHasReceivedEvictionJudgement"
            label="Has your landlord received an eviction judgement against you?"
            required
          />
          <DecimalInput
            name="delinquentRent"
            label="Are you behind on your rent? If so, how much?"
            addOnBefore="$"
            allowDecimal
            includeThousandsSeparator
          />
        </>
      )}

      <WizardNavigation isComplete abortURL={abortURL} />
    </>
  );
}
