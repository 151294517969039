import { useQuery } from "@apollo/client";
import { useDeleteMortgage } from "api/graphql/RealEstateMortgage";
import { ListViewProps } from "components/EditCreateList/stateless";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import { format, parseISO } from "date-fns";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  formatErrorsFromSchema,
  formatGraphAddress,
  formatName,
  formatOxfordComma,
  formatQuantity,
} from "utils/formatters";
import { useNested } from "utils/hooks";
import MORTGAGES_MODULE from "./MortgagesModule.gql";
import { MortgageSchema } from "./MortgageWizard";

export default function ListView(props: ListViewProps) {
  const { data, loading } = useQuery<MortgagesModule>(MORTGAGES_MODULE);
  const [onDelete] = useDeleteMortgage();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  if (!data) throw new Error("Real estate list didn't return data");
  const { realEstateMortgages: items } = data;

  return (
    <>
      <Title>Your Mortgages</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoRealEstateMortgages"
              createURL={props.createURL}
              nextURL={props.nextURL}
              addText="Add a mortgage"
              question={
                <p className="required">
                  <strong>Do you have any mortgages?</strong>
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you don&rsquo;t have any mortgages for
                  property you own. If that&rsquo;s correct, you&rsquo;re ready
                  to continue to the next section. Otherwise, you may add
                  mortgages below.
                </p>
              }
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                In order to file your bankruptcy, we need to know about all your
                outstanding mortgages, including any you have cosigned.
              </p>
              <StatelessList
                {...props}
                emptyText="You haven’t added any mortgages yet."
                addButtonText="Add a mortgage"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(mortgage) => {
                  const {
                    originationDate,
                    loanNumber,
                    lenderName,
                    property,
                    cosigners,
                    collectors,
                  } = mortgage;
                  return (
                    <div>
                      {formatErrorsFromSchema(MortgageSchema, mortgage)}

                      <strong>
                        Mortgage {loanNumber} from {lenderName}
                      </strong>
                      <br />
                      {formatGraphAddress(property.address)}

                      <div className="supporting-text">
                        Issued on{" "}
                        {format(parseISO(originationDate), "MM/dd/yyyy")}
                      </div>

                      {!!cosigners.length && (
                        <div className="supporting-text">
                          Cosigned with{" "}
                          {formatOxfordComma(...cosigners.map(formatName))}
                        </div>
                      )}

                      {!!collectors.length && (
                        <div className="supporting-text">
                          {formatQuantity(collectors.length, " debt collector")}
                        </div>
                      )}
                    </div>
                  );
                }}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
