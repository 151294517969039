import { MonthInput } from "components/MonthInput";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import * as React from "react";
import { DateSchema } from "utils/validators/yup";
import { object, string } from "yup";

export const PreviousNameSchema = object({
  legalName: string().required(
    "Please provide the full legal name, including the first name, middle name, last name, and suffix that you have used in the past."
  ),
  monthChanged: DateSchema.dateNotFuture(
    "Changed month can’t be in the future."
  ).required("Please enter the month and year you legally changed your name."),
}).required();

export function PreviousName({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <TextInput
          name="legalName"
          label="Full previous legal name"
          maxLength={255}
          width="200px"
          placeholder="Morgan Riley van Buren III"
        />
        <MonthInput name="monthChanged" label="Changed month" width="175px" />
      </div>
    </>
  );
}
