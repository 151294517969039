import { ButtonRow } from "components/ButtonRow";
import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { breakpoints, useBreakpoint } from "utils/hooks";
import PasswordResetLink from "./reset";

export interface Props {
  login: string;
  invalid: boolean;
}

export default function ErrorView(props: Props) {
  const isMobile = useBreakpoint(breakpoints.mobile);

  return (
    <>
      {props.invalid ? (
        <>
          <h4>Invalid credentials</h4>
          <p>
            Please provide the credentials you created when signing up for your
            credit report (
            <a
              href="https://credit.lexria.com"
              className="href"
              target="_blank"
              rel="noreferrer"
            >
              credit.lexria.com
            </a>
            ). These credentials are likely different from the credentials you
            used to login to <a href="https://app.lexria.com">app.lexria.com</a>{" "}
            today.
          </p>
          <p>
            If you aren’t sure what your{" "}
            <a
              href="https://credit.lexria.com/customer_login.asp"
              target="_blank"
              rel="noreferrer"
            >
              credit.lexria.com
            </a>{" "}
            password is and need to reset it,{" "}
            <PasswordResetLink>
              {isMobile ? "tap" : "click"} here
            </PasswordResetLink>
            .
          </p>
        </>
      ) : (
        <p>
          There was a problem importing your credit report. You can try the
          import again, or if this problem persists, please contact us.
        </p>
      )}
      <ButtonRow
        right={
          <Link className="btn primary" to="./login">
            <span>Try importing again</span>
            <FaArrowRight />
          </Link>
        }
      />
    </>
  );
}
