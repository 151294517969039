import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_VEHICLE_LEASES from "./DeleteVehicleLease.gql";
import GET_VEHICLE_LEASES from "./GetVehicleLease.gql";
import LIST_VEHICLE_LEASES from "./ListVehicleLease.gql";
import UPSERT_VEHICLE_LEASES from "./UpsertVehicleLease.gql";

const LIST_VEHICLE_LEASES_IDS = gql`
  query ListVehicleLeaseIds {
    vehicleLeases {
      id
    }
  }
`;

export function useListVehicleLease(
  options?: QueryHookOptions<ListVehicleLease>
) {
  return useQuery<ListVehicleLease>(LIST_VEHICLE_LEASES, options);
}

export function useGetVehicleLease(id: string) {
  return useQuery<GetVehicleLease, GetVehicleLeaseVariables>(
    GET_VEHICLE_LEASES,
    {
      variables: { id },
    }
  );
}

export function useUpsertVehicleLease<
  Schema extends { vehicleLease: any } = UpsertVehicleLease,
  Variables = UpsertVehicleLeaseVariables
>(query = UPSERT_VEHICLE_LEASES) {
  return useUpsert<Schema, Variables, ListVehicleLeaseIds, VehicleLeaseInput>(
    query,
    LIST_VEHICLE_LEASES_IDS,
    "vehicleLease",
    "vehicleLeases",
    (cache) => updateFilingProfileCache(cache, "hasNoVehicleLeases", false)
  );
}

export function useDeleteVehicleLease() {
  return useDelete<DeleteVehicleLease, ListVehicleLeaseIds>(
    DELETE_VEHICLE_LEASES,
    LIST_VEHICLE_LEASES_IDS,
    "deleteVehicleLease",
    "vehicleLeases"
  );
}
