import { useQuery } from "@apollo/client";
import CategoriesList from "components/CategoriesList";
import { FormModuleProps } from "components/FormFlow";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import { sortBy, sumBy } from "lodash";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { breakpoints, useBreakpoint, useNested } from "utils/hooks";
import { parseFancyNumber } from "utils/validators";
import * as categories from "./categories";
import { CategorySection } from "./CategorySection";
import EXPENSES_MODULE from "./ExpensesModule.gql";
import { MonthlyExpenseSchema } from "./MonthlyExpenseWizard";

const sections = [
  categories.childCareCategoryDetail,
  categories.childEducationCategoryDetail,
  categories.realEstateRentCategoryDetail,
  categories.realEstateTaxesCategoryDetail,
  categories.realEstateUpkeepCategoryDetail,
  categories.realEstateHoaCategoryDetail,
  categories.homeInternetCategoryDetail,
  categories.homePhoneCategoryDetail,
  categories.mobilePhoneCategoryDetail,
  categories.televisionCategoryDetail,
  categories.electricUtilitiesCategoryDetail,
  categories.waterUtilitiesCategoryDetail,
  categories.sewerUtilitiesCategoryDetail,
  categories.trashUtilitiesCategoryDetail,
  categories.clothingCategoryDetail,
  categories.foodCategoryDetail,
  categories.personalCareCategoryDetail,
  categories.healthCareCategoryDetail,
  categories.transportationCategoryDetail,
  categories.recreationCategoryDetail,
  categories.charityCategoryDetail,
  categories.alimonyCategoryDetail,
  categories.otherCategoryDetail,
];

export default function HouseholdExpenses({
  prev,
  next,
}: FormModuleProps<undefined>) {
  const isMobile = useBreakpoint(breakpoints.mobile);
  const { path, url } = useNested();
  const { data } = useQuery<ExpensesModule, ExpensesModuleVariables>(
    EXPENSES_MODULE
  );

  if (!data) return <Loading />;

  const currencyFormatter = new Intl.NumberFormat("en", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <Switch>
        {sections.map((categoryDetail, index) => {
          return (
            <Route
              key={index}
              path={path(`${categoryDetail.urlPathName}`)}
              render={() => (
                <CategorySection
                  key={index}
                  next={url("/")}
                  prev={url("/")}
                  categoryDetail={categoryDetail}
                />
              )}
            />
          );
        })}
        <Route
          exact
          path={path("/")}
          render={() => (
            <div>
              <Title>Household Expenses</Title>
              <p>
                When filing for bankruptcy, your lawyer will need to know about
                your last six months of household-related spending.{" "}
                {isMobile ? "Tap " : "Click "} each of the following sections to
                tell us about your spending for that type of expense.
              </p>
              <CategoriesList
                doneURL={next}
                categories={sortBy(sections, (c) => {
                  // Sort incomplete categories to the top
                  const items = data.monthlyExpenses.filter(
                    (expense) => expense.category === c.category
                  );
                  const done =
                    !!items.length ||
                    data.filingProfile[c.hasNoProfileBooleanName];
                  return done ? 1 : 0;
                }).map((c) => {
                  const items = data.monthlyExpenses.filter(
                    (expense) => expense.category === c.category
                  );
                  const totalCost = currencyFormatter.format(
                    sumBy(items, (expense) =>
                      Number(parseFancyNumber(expense.amount))
                    )
                  );

                  return {
                    name: c.displayName,
                    href: url(c.urlPathName),
                    Icon: c.menuIcon,
                    hasNone: data.filingProfile[c.hasNoProfileBooleanName],
                    items,
                    itemName: "expense",
                    schema: MonthlyExpenseSchema,
                    itemsSummary: `${totalCost}/month`,
                  };
                })}
              />
            </div>
          )}
        />
      </Switch>
    </>
  );
}
