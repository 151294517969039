import { useGetStorageContainer } from "api/graphql/StorageContainer";
import { Loading } from "components/Loading";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { StorageContainerWizard } from "./StorageContainerWizard";

interface Props {
  id: string;
  listURL: string;
  editURL?: string;
}

export function EditView({ id, listURL, editURL }: Props) {
  const { data, loading } = useGetStorageContainer(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.storageContainer) {
    return <Redirect to={listURL} />;
  }

  return (
    <StorageContainerWizard
      title={`Updating storage`}
      backURL={listURL}
      nextURL={listURL}
      editURL={editURL}
      initialValue={data.storageContainer}
    />
  );
}
