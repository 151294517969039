import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import {
  createCacheListUpdaters,
  useDelete,
  useUpsert,
} from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_DEBT from "./DeleteCreditCardDebt.gql";
import GET_DEBT from "./GetCreditCardDebt.gql";
import LIST_DEBT from "./ListCreditCardDebt.gql";
import UPSERT_DEBT from "./UpsertCreditCardDebt.gql";

const LIST_DEBT_IDS = gql`
  query ListCreditCardDebtIds {
    creditCardDebts {
      id
    }
  }
`;

export const [
  onCreditCardDebtAdded,
  onCreditCardDebtRemoved,
] = createCacheListUpdaters<UpsertCreditCardDebt["creditCardDebt"]>(
  LIST_DEBT_IDS,
  "creditCardDebts"
);

export function useListCreditCardDebt(
  options?: QueryHookOptions<ListCreditCardDebt>
) {
  return useQuery<ListCreditCardDebt>(LIST_DEBT, options);
}

export function useGetCreditCardDebt(id: string) {
  return useQuery<GetCreditCardDebt, GetCreditCardDebtVariables>(GET_DEBT, {
    variables: { id },
  });
}

export function useUpsertCreditCardDebt<
  Schema extends { creditCardDebt: any } = UpsertCreditCardDebt,
  Variables = UpsertCreditCardDebtVariables
>(query = UPSERT_DEBT) {
  return useUpsert<
    Schema,
    Variables,
    ListCreditCardDebtIds,
    CreditCardDebtInput
  >(query, LIST_DEBT_IDS, "creditCardDebt", "creditCardDebts", (cache) =>
    updateFilingProfileCache(cache, "hasNoCreditCardDebts", false)
  );
}

export function useDeleteCreditCardDebt() {
  return useDelete<DeleteCreditCardDebt, ListCreditCardDebtIds>(
    DELETE_DEBT,
    LIST_DEBT_IDS,
    "deleteCreditCardDebt",
    "creditCardDebts"
  );
}
