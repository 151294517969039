import { Card } from "components/Card";
import { Modal } from "components/Modal";
import ArgyleLink from "components/EmployerApi/Argyle/ArgyleLink";

interface Props {
  isOpen: boolean;
  onSuccess: () => any;
  onError: () => any;
  onClose: () => any;
  apiEmployer: any; // TODO: type this
  employerLinkToken?: string;
}

export default function EmployerApiModal({
  isOpen,
  onClose,
  onSuccess,
  onError,
  apiEmployer,
  employerLinkToken,
}: Props) {
  return (
    <>
      {apiEmployer?.provider === "argyle" && (
        <ArgyleLink
          isOpen={isOpen}
          onSuccess={onSuccess}
          onError={onError}
          onClose={onClose}
          employerShortId={apiEmployer.shortId}
          employerLinkToken={employerLinkToken}
        />
      )}
      {/* TODO: Pinwheel modal */}
    </>
  );
}
