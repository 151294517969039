import {
  useAssignCosigner,
  useGetCosignerCandidates,
  useUpsertCosigner,
} from "api/graphql/Cosigner";
import {
  OtherPersonDataValidationType,
  OtherPersonWithMissingDataValidationsForm,
} from "components/FormSections/OtherPersonWithMissingDataValidation";
import { Loading } from "components/Loading";
import * as React from "react";

export function CosignerForm({
  returnURL,
  id,
  relatedId,
  relatedTypename,
  title,
}: {
  returnURL: string;
  relatedId: string;
  relatedTypename: string;
  id?: string;
  title: string;
}) {
  const [assignCosigner] = useAssignCosigner();
  const [upsertCosigner] = useUpsertCosigner();
  const { data, loading } = useGetCosignerCandidates({
    id: relatedId,
    typename: relatedTypename,
  });

  const validations = React.useMemo(
    () => [OtherPersonDataValidationType.MailingAddress],
    []
  );

  const onSubmit = React.useCallback((state) => {
    if (!id && "id" in state) {
      return assignCosigner({
        otherPersonId: state.id,
        relatedId,
        relatedTypename,
      });
    } else {
      return upsertCosigner({
        variables: {
          input: state as OtherPersonInput,
          relatedId,
          relatedTypename,
        },
      });
    }
  }, []);

  if (!id && loading) return <Loading />;

  return (
    <OtherPersonWithMissingDataValidationsForm
      validations={validations}
      id={id}
      candidates={data?.cosignable?.eligibleCosigners}
      relatedId={relatedId}
      relatedTypename={relatedTypename}
      relatedDisplayName="co-signer"
      title={title}
      backURL={returnURL}
      nextURL={returnURL}
      onSubmit={onSubmit}
      onFinalizeMissingDataCollection={assignCosigner}
    />
  );
}
