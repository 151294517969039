import cn from "classnames";
import { format, isAfter, isBefore, parseISO, startOfToday } from "date-fns";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { ResidenceCard } from "./ResidenceCard";
import css from "./ResidenceListItem.module.css";

const fancyFormat = (date: Date) => format(date, "MMMM do, yyyy");

interface ResidenceListItemProps {
  items: ResidentialAddress[];
  requiredFrom: Date;
  item: ResidentialAddress;
  index: number;
  onDelete: () => Promise<any>;
  onCreate: (index: number) => void;
  onEdit: () => void;
}

export default function ResidenceListItem({
  items,
  index,
  onDelete,
  onEdit,
  onCreate,
  requiredFrom,
}: ResidenceListItemProps) {
  const item = items[index];
  const { residentFrom, residentTo, address } = item;
  const next = items[index + 1];
  const gapAfter = isBefore(
    parseISO(residentTo),
    next ? parseISO(next.residentFrom) : startOfToday()
  );

  const prepend = () => onCreate(index);
  const append = () => onCreate(index + 1);

  return (
    <div>
      {index === 0 && isAfter(parseISO(residentFrom), requiredFrom) && (
        <>
          <div className={css.timePoint}>{fancyFormat(requiredFrom)}</div>
          <button className="btn primary flat" onClick={prepend}>
            <FaPlus />
            <span>Add missing address</span>
          </button>
        </>
      )}

      <div className={css.timePoint}>{fancyFormat(parseISO(residentFrom))}</div>

      <ResidenceCard
        className={css.timeInterval}
        address={address}
        onDelete={onDelete}
        onEdit={onEdit}
      />

      {residentTo && gapAfter && (
        <>
          <div className={css.timePoint}>
            {fancyFormat(parseISO(residentTo))}
          </div>
          <button className="btn primary flat" onClick={append}>
            <FaPlus />
            <span>Add missing address</span>
          </button>
        </>
      )}
      {index === items.length - 1 && (
        <div className={cn(css.timePoint, css.end)}>Present</div>
      )}
    </div>
  );
}
