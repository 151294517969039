import { BooleanInput } from "components/BooleanInput";
import { useEditorBuffer } from "components/EditorState/hooks";
import { FileList } from "components/FileList";
import {
  useAddDocumentUpload,
  useMarkNoDocumentation,
  useRemoveDocumentUpload,
} from "components/FormSections/DocumentsUpload/hooks";
import { pick, last } from "lodash";
import React from "react";
import { useFormContext, UseFormMethods } from "react-hook-form";
import fileDownload from "js-file-download";

function useUpdateBufferField(fieldName: string) {
  const [{ buffer }] = useEditorBuffer() || [{}];
  const update = React.useCallback(
    (value: any) => {
      if (!buffer) return;
      buffer[fieldName] = value;
    },
    [buffer]
  );
  return React.useMemo(() => [update], [update]);
}

interface DocumentsUploadProps {
  fieldName?: string;
  relatedTypename: string;
  /** The copy to display above the documents and input */
  displayText?: React.ReactNode;
  hasNoDocumentsFieldName?: string;
  /** The copy to display above the has no documents question.
   * If provided, the has no documents question will be displayed */
  hasNoDocumentsLabelText?: string;
}

type DocumentState = {
  name: string;
  id: string;
  uploadFile: UploadedFile;
  uploadFileHash: string;
  type: string;
};

export default function DocumentsUpload({
  fieldName = "documents",
  relatedTypename,
  displayText = null,
  hasNoDocumentsFieldName = "hasNoSupportingDocumentation",
  hasNoDocumentsLabelText = null,
}: DocumentsUploadProps) {
  const [{ buffer }] = useEditorBuffer();
  const [updateBufferDocuments] = useUpdateBufferField("documents");
  const form: UseFormMethods | undefined = useFormContext();

  const [upload] = useAddDocumentUpload();
  const [remove] = useRemoveDocumentUpload();
  const [markNoDocuments] = useMarkNoDocumentation();

  const [documents, setDocuments] = React.useState<DocumentState[]>(
    buffer[fieldName].map((doc: any) => ({
      ...doc,
      type: "application/pdf",
    }))
  );
  const [documentError, setDocumentError] = React.useState(null);

  async function addDocument(files: File[], event: any) {
    setDocumentError(null);
    for (const file of files) {
      await upload({
        relatedId: buffer.id,
        relatedTypename: relatedTypename,
        uploadFile: file,
      })
        .then((result) => {
          const addedDocument = result.data.addDocument.documents.slice(-1)[0];
          const document = {
            ...pick(addedDocument, "id", "uploadFile", "uploadFileHash"),
            type: "application/pdf",
            name: last(addedDocument.uploadFile.name.split("/")),
          };
          setDocuments((documents) => {
            const documentsValue = [...documents, document];
            updateBufferDocuments(documentsValue);
            return documentsValue;
          });
        })
        .catch((error: any) => {
          if (error.message === "Already exists") {
            setDocumentError(`You've already uploaded ${file.name}.`);
          } else {
            setDocumentError(
              `An error occurred uploading ${file.name}.  Please try again.  If this issue persists, please contact support@lexria.com.`
            );
          }
        });
    }
  }

  function removeDocument(fileName: string, index: number) {
    setDocumentError(null);
    const document = documents[index];
    remove({
      relatedId: buffer.id,
      relatedTypename: relatedTypename,
      documentId: document.id,
    });
    const documentsValue = documents.filter(
      (doc: any) => doc.id !== document.id
    );
    setDocuments(documentsValue);
    updateBufferDocuments(documentsValue);
  }

  async function downloadDocument(uploadedFile: UploadedFile, index: number) {
    const resp = await fetch(uploadedFile.url);
    const blob = await resp.blob();
    const name = last(uploadedFile.name.split("/"));
    fileDownload(blob, name);
  }

  const hasNoSupportingDocumentationValue =
    !!form && form.watch(hasNoDocumentsFieldName);
  const setHasSupportingDocumentation = (
    hasNoSupportingDocumentation: boolean
  ) => {
    markNoDocuments({
      input: {
        id: buffer.id,
        typename: relatedTypename,
        hasNoSupportingDocumentation,
      },
    });
    if (hasNoSupportingDocumentation) {
      documents.forEach((document: any, index: number) => {
        removeDocument(document.name, index);
      });
    }
    setDocuments([]);
    updateBufferDocuments([]);
  };

  return (
    <>
      {!!hasNoDocumentsLabelText && (
        <div className="form-row">
          <BooleanInput
            name={hasNoDocumentsFieldName}
            label={hasNoDocumentsLabelText}
            autoFocus
            onChange={setHasSupportingDocumentation}
            defaultValue={hasNoSupportingDocumentationValue}
            yesLabel="No"
            noLabel="Yes"
          />
        </div>
      )}
      {(!hasNoDocumentsLabelText || !hasNoSupportingDocumentationValue) && (
        <div>
          {displayText}
          <FileList
            files={documents}
            name={fieldName}
            accept="application/pdf"
            onDropAccepted={addDocument}
            onDelete={removeDocument}
            onDownload={downloadDocument}
            error={documentError}
          />
        </div>
      )}
    </>
  );
}
