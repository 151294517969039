import FarmAssetsSubmodule from "components/FormModules/Assets/FarmAssets";
import StorageSubmodule from "components/FormModules/Assets/StorageContainers";
import AssetsSummary from "components/FormModules/Assets/Summary";
import SubMenuModule from "components/FormModuleSubMenu";
import React from "react";
import { FaHandHoldingUsd } from "react-icons/fa";
import AssetLosses from "./AssetLosses";
import AssetSales from "./AssetSales";
import BorrowedAssets from "./BorrowedAssets";
import FinancialAccounts from "./FinancialAccounts";
import FinancialInvestments from "./FinancialInvestments";
import HouseholdItems from "./HouseholdItems";
import RealEstate from "./RealEstate";
import ServicePrepayments from "./ServicePrepayments";
import Vehicles from "./Vehicles";

export type AssetsProps = {
  next?: string;
  prev?: string;
  subMenuContainerID?: string;
};

const assetsModules = [
  RealEstate,
  Vehicles,
  FinancialAccounts,
  FinancialInvestments,
  ServicePrepayments,
  HouseholdItems,
  AssetSales,
  AssetLosses,
  BorrowedAssets,
  StorageSubmodule,
  FarmAssetsSubmodule,
];
export const assetsStatusNames = assetsModules.map((m) => m.statusName);

export function Assets({ prev, subMenuContainerID }: AssetsProps) {
  return (
    <SubMenuModule
      subMenuContainerID={subMenuContainerID}
      dashboardURL={prev}
      title="Your Assets"
      Index={AssetsSummary}
      subModules={assetsModules}
    />
  );
}

Assets.Icon = FaHandHoldingUsd;
