import cn from "classnames";
import * as React from "react";
import { useForm } from "react-hook-form";
import { FaArrowLeft, FaArrowRight, FaCheck } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useFormSubmitting } from "utils/hooks";
import { ButtonRow } from "./ButtonRow";

export interface WizardNavigationProps {
  abortURL?: string;
  backURL?: string;
  nextURL?: string;
  isComplete?: boolean;
  canContinue?: boolean;
  canAbort?: boolean;
  canGoBack?: boolean;
  backIsSubmit?: boolean;
  nextIsSubmit?: boolean;
  saving?: boolean;
  onNext?: (stuff: any) => void;
  onBack?: (stuff: any) => void;
  onAbort?: (stuff: any) => void;
  nextText?: string;
  saveText?: string;
  saveIcon?: React.ReactElement;
  abortText?: string;
  backText?: string;
}

export function WizardNavigation({
  backURL,
  abortURL,
  nextURL,
  isComplete,
  canAbort = true,
  canContinue = true,
  canGoBack = true,
  backIsSubmit = false,
  nextIsSubmit = true,
  saving,
  onNext,
  onBack,
  onAbort,
  nextText = "Next",
  saveText = "Save",
  saveIcon,
  abortText = "Cancel",
  backText = "Back",
}: WizardNavigationProps) {
  const history = useHistory();
  const form = !!useForm();
  const formSubmitting = useFormSubmitting();

  // defer to an explicit saving prop when given, otherwise
  // fall back on the implicit form loading state
  const loading = typeof saving === "undefined" ? formSubmitting : saving;

  const defaultOn = React.useCallback(
    (url: string) => () => {
      if (url) {
        history.push(url);
      }
    },
    [history]
  );

  const nextButtonOnClick = onNext || defaultOn(nextURL);
  const backButtonOnClick = onBack || defaultOn(backURL);
  const abortButtonOnClick = onAbort || defaultOn(abortURL);

  return (
    <ButtonRow
      reverse
      style={{ marginBottom: 0, marginTop: "15px" }}
      left={
        (onBack || backURL) && (
          <button
            type={backIsSubmit ? "submit" : "button"}
            onClick={backButtonOnClick}
            className={cn("btn", { disabled: loading || !canGoBack })}
          >
            <FaArrowLeft />
            {backText && (
              <>
                {" "}
                <span>{backText}</span>
              </>
            )}
          </button>
        )
      }
      right={
        <>
          {(nextURL || onNext || form) && (
            <button
              type={nextIsSubmit ? "submit" : "button"}
              className={cn("btn", "primary", {
                loading,
                disabled: !canContinue || loading,
              })}
              disabled={!canContinue || loading}
              onClick={nextButtonOnClick}
            >
              {isComplete ? (
                <>
                  {saveText && (
                    <>
                      <span>{saveText}</span>{" "}
                    </>
                  )}
                  {saveIcon || <FaCheck />}
                </>
              ) : (
                <>
                  {nextText && (
                    <>
                      <span>{nextText}</span>{" "}
                    </>
                  )}
                  <FaArrowRight />
                </>
              )}
            </button>
          )}
          {(abortURL || onAbort) && (
            <button
              type="button"
              onClick={abortButtonOnClick}
              className={cn("btn flat", { disabled: loading || !canAbort })}
            >
              {abortText}
            </button>
          )}
        </>
      }
    />
  );
}
