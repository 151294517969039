import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary from "components/ModuleSummary";
import CHILD_SUPPORT_MODULE from "./ChildSupportModule.gql";

export default function ChildSupportSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<ChildSupportModule>(CHILD_SUPPORT_MODULE, {
    returnPartialData: true,
  });

  return <ModuleSummary {...props} progress={data?.progress?.childSupport} />;
}
