import { useGetRealEstate } from "api/graphql/RealEstateProperties";
import { Loading } from "components/Loading";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { RealEstateWizard } from "./RealEstateWizard";

interface Props {
  id: string;
  listURL: string;
  editURL?: string;
}

export function EditView({ id, listURL, editURL }: Props) {
  const { data, loading } = useGetRealEstate(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.realEstateProperty) {
    return <Redirect to={listURL} />;
  }

  return (
    <RealEstateWizard
      title="Updating Real Estate Property"
      backURL={listURL}
      nextURL={listURL}
      editURL={editURL}
      initialValue={data.realEstateProperty}
    />
  );
}
