import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDeleteEvict, useUpsertModify } from "api/graphql/utils";
import { HouseholdItemCategoryChoice } from "globalTypes";
import DELETE_HOUSEHOLD_ITEM from "./DeleteHouseholdItem.gql";
import GET_HOUSEHOLD_ITEM from "./GetHouseholdItem.gql";
import LIST_HOUSEHOLD_ITEM_BY_CATEGORY from "./ListHouseholdItemFilterByCategory.gql";
import UPSERT_HOUSEHOLD_ITEM from "./UpsertHouseholdItem.gql";

export function useListHouseholdItem(
  options?: QueryHookOptions<ListHouseholdItemFilterByCategory>
) {
  return useQuery<ListHouseholdItemFilterByCategory>(
    LIST_HOUSEHOLD_ITEM_BY_CATEGORY,
    options
  );
}

export function useGetHouseholdItem(id: string) {
  return useQuery<GetHouseholdItem, GetHouseholdItemVariables>(
    GET_HOUSEHOLD_ITEM,
    {
      variables: { id },
    }
  );
}

export const getCategoryHasNoBooleanName = (
  category: HouseholdItemCategoryChoice
) => {
  switch (category) {
    case HouseholdItemCategoryChoice.Clothing:
      return "hasNoHouseholdClothingItems";
    case HouseholdItemCategoryChoice.Collectibles:
      return "hasNoHouseholdCollectiblesItems";
    case HouseholdItemCategoryChoice.Electronics:
      return "hasNoHouseholdElectronicsItems";
    case HouseholdItemCategoryChoice.Firearms:
      return "hasNoHouseholdFirearmsItems";
    case HouseholdItemCategoryChoice.GoodsAndFurnishings:
      return "hasNoHouseholdGoodsAndFurnishingItems";
    case HouseholdItemCategoryChoice.Jewelry:
      return "hasNoHouseholdJewelryItems";
    case HouseholdItemCategoryChoice.NonFarmAnimals:
      return "hasNoHouseholdNonFarmAnimalsItems";
    case HouseholdItemCategoryChoice.SportHobbyEquipment:
      return "hasNoHouseholdSportHobbyEquipmentItems";
    case HouseholdItemCategoryChoice.Other:
      return "hasNoHouseholdOtherItems";
  }
};

export function useUpsertHouseholdItem<
  Schema extends { householdItem: any } = UpsertHouseholdItem,
  Variables = UpsertHouseholdItemVariables
>(query = UPSERT_HOUSEHOLD_ITEM, category: HouseholdItemCategoryChoice) {
  return useUpsertModify<
    Schema,
    Variables,
    HouseholdItemInput,
    QueryHouseholdItemsArgs
  >(query, "householdItem", "householdItems", (cache, data) => {
    const filingProfileBoolean = getCategoryHasNoBooleanName(category);
    updateFilingProfileCache(cache, filingProfileBoolean, false);
  });
}

export function useDeleteHouseholdItem() {
  return useDeleteEvict<DeleteHouseholdItem>(
    DELETE_HOUSEHOLD_ITEM,
    "deleteHouseholdItem",
    "HouseholdItem"
  );
}
