import IncomeLearnMore from "components/FormModules/Income/IncomeLearnMore";
import SectionSummary from "components/FormModuleSubMenu/SectionSummary";
import { SectionIndexProps } from "components/FormModuleSubMenu/SubmenuModule";
import React from "react";
import incomeIllustration from "./income.svg";
import INCOME_INDEX from "./IncomeIndex.gql";

export default function IncomeSummary(props: SectionIndexProps) {
  return (
    <SectionSummary<IncomeIndex>
      {...props}
      query={INCOME_INDEX}
      image={
        <img
          src={incomeIllustration}
          alt="A person sitting on a stack of money in front of a bar chart"
          width={406}
          height={306}
        />
      }
      heroCopy={
        <p>
          Your lawyer needs to know all income that you or any members of your
          household earned in the past six months. You also need to provide
          documents verifying each income source. Be sure to include all income
          sources—even one-time payments. <IncomeLearnMore />
        </p>
      }
      completedCopy={
        <p>
          It looks like we have all the information your lawyer needs to know
          about your household income. If the information below is accurate and
          complete, you&rsquo;re all done with this section!
        </p>
      }
    />
  );
}
