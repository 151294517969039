import { useMutation, useQuery } from "@apollo/client";
import cn from "classnames";
import { ButtonRow } from "components/ButtonRow";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { CollectorSchema } from "components/FormSections/Collector";
import { HiddenInput } from "components/HiddenInput";
import { Loading } from "components/Loading";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { formatErrorsFromSchema } from "utils/formatters";
import { array, boolean, object } from "yup";
import DELETE_COLLECTOR from "./DeleteCollector.gql";
import GET_COLLECTABLE from "./GetCollectable.gql";
import MARK_NO_COLLECTORS from "./MarkNoCollectors.gql";

export const CollectorsSchema = object({
  collectors: array()
    .of(CollectorSchema)
    .when("hasNoCollectors", {
      is: true,
      then: array().min(0),
      otherwise: array().min(
        1,
        "You haven’t indicated that this debt has no collectors or added any collectors."
      ),
    })
    .defined(),
  hasNoCollectors: boolean().required(),
}).required();

interface CollectorsWizardStepProps extends WizardRouteChildProps {
  relatedId: string;
  relatedTypename: string;
}

export default function CollectorsWizardStep({
  navProps,
  relatedId,
  relatedTypename,
}: CollectorsWizardStepProps) {
  const history = useHistory();
  const [deleteCollector] = useMutation<
    DeleteCollector,
    DeleteCollectorVariables
  >(DELETE_COLLECTOR);
  const { data, loading } = useQuery<GetCollectable, GetCollectableVariables>(
    GET_COLLECTABLE,
    {
      variables: { id: relatedId, typename: relatedTypename },
    }
  );
  const [markNoCollectors, { loading: markingNoCollectors }] = useMutation<
    MarkNoCollectors,
    MarkNoCollectorsVariables
  >(MARK_NO_COLLECTORS, {
    variables: { id: relatedId, typename: relatedTypename },
  });

  if (loading) return <Loading />;
  const { collectors, hasNoCollectors } = data.collectable;

  return (
    <div>
      <HiddenInput name="collectors" value={collectors} />
      <HiddenInput name="hasNoCollectors" value={hasNoCollectors} />
      {!collectors.length ? (
        hasNoCollectors ? (
          <>
            <p>
              You indicated that you have not been contacted by any debt
              collectors relating to this debt. If you did this in error, you
              may add collectors below.
            </p>
            <Link to="create_collector" className="secondary btn">
              <FaPlus />
              <span>Add a collector</span>
            </Link>
          </>
        ) : (
          <>
            <p className="required">
              Have you been contacted by any debt collectors for this specific
              debt?
            </p>
            <ButtonRow
              left={
                <button
                  type="button"
                  className={cn("btn block", { loading: markingNoCollectors })}
                  onClick={() => markNoCollectors()}
                >
                  No
                </button>
              }
              right={
                <Link to="create_collector" className="block btn">
                  Yes
                </Link>
              }
            />
          </>
        )
      ) : (
        <>
          <p>
            If you have been contacted by multiple collection agencies, please
            add them below. Otherwise, you’re done with this debt!
          </p>
          <StatelessList
            createURL="create_collector"
            emptyText="You haven’t added any debt collectors yet."
            items={collectors}
            deleteItem={({ id }) =>
              deleteCollector({
                variables: {
                  collectorID: id,
                  relatedId,
                  relatedTypename,
                },
              })
            }
            addButtonText="Add a collector"
            renderItem={(collector) => (
              <div>
                {formatErrorsFromSchema(CollectorSchema, collector)}
                Account {collector.accountNumber} with {collector.name}
              </div>
            )}
            onEdit={(id) => history.push(`edit_collector/${id}`)}
          />
        </>
      )}

      <WizardNavigation
        {...navProps}
        canContinue={CollectorsSchema.isValidSync(data.collectable)}
      />
    </div>
  );
}
