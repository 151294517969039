import {
  RadioGroupInput,
  RadioGroupInputProps,
} from "components/RadioGroupInput";
import * as React from "react";

interface BooleanInputProps extends Omit<RadioGroupInputProps, "options"> {
  yesLabel?: React.ReactNode;
  noLabel?: React.ReactNode;
}

export function BooleanInput({
  yesLabel = "Yes",
  noLabel = "No",
  ...etc
}: BooleanInputProps) {
  return (
    <RadioGroupInput
      {...etc}
      options={[
        { label: yesLabel, value: true },
        { label: noLabel, value: false },
      ]}
    />
  );
}
