import {
  useGetResidentialAddress,
  useListResidentialAddresses,
  useUpsertResidentialAddress,
} from "api/graphql/ResidentialAddress";
import { Card } from "components/Card";
import { EditViewProps } from "components/EditCreateList/stateless";
import { Form } from "components/Form";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import { findIndex } from "lodash";
import React from "react";
import { useHistory } from "react-router";
import { unnestIds } from "utils/ids";
import ResidenceForm from "./ResidenceForm";
import { cleanApiInput, sortAddresses } from "./utils";

export default function EditView({ id, listURL }: EditViewProps) {
  const history = useHistory();
  const { data: listData } = useListResidentialAddresses();
  const { data: itemData } = useGetResidentialAddress(id);
  const [upsertResidentialAddress] = useUpsertResidentialAddress();

  if (!listData || !itemData) return <Loading />;

  const items = listData.residentialAddresses;
  const initialValue = unnestIds(itemData.residentialAddress);

  const sortedAddresses = sortAddresses(items);
  const index = findIndex(sortedAddresses, (a) => a.id === id);
  const prev = sortedAddresses[index - 1];
  const next = sortedAddresses[index + 1];

  return (
    <>
      <Title>Updating residence</Title>
      <Card>
        <Form
          onSubmit={async (
            values: ResidentialAddressInput,
            event,
            translateErrors
          ) => {
            try {
              await upsertResidentialAddress({ id, ...cleanApiInput(values) });
              history.push(listURL);
            } catch (e) {
              translateErrors(e);
            }
          }}
          initialValue={initialValue}
        >
          <ResidenceForm
            prevResidentTo={prev?.residentTo}
            nextResidentFrom={next?.residentFrom}
            abortURL={listURL}
            isEdit
          />
        </Form>
      </Card>
    </>
  );
}
