import { parseISO } from "date-fns";

export interface FormattedUser {
  name: string;
  email: string;
  emailVerified: boolean;
  lastLoginAt: ISODateTime;
}

export function getFormattedUser(user: LawFirmUser): FormattedUser {
  return {
    name: user.name,
    email: user.email,
    emailVerified: user.emailVerified,
    lastLoginAt: user.lastLogin
      ? parseISO(user.lastLogin).toLocaleDateString()
      : null,
  };
}
