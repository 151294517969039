import { useQuery } from "@apollo/client";
import { useDeleteConsultation } from "api/graphql/Consultation";
import { ButtonRow } from "components/ButtonRow";
import { ListItem } from "components/ListItem";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import {
  formatConsultationType,
  formatErrorsFromSchema,
} from "utils/formatters";
import { useNested } from "utils/hooks";
import ENVIRONMENTAL_CLEANUP_NOTICES_MODULE from "./ConsultationsModule.gql";
import { ConsultationSchema } from "./ConsultationWizard";

interface Props {
  onEdit: (id: string) => any;
  createURL: string;
  nextURL: string;
}

export function ListView({ onEdit, createURL, nextURL }: Props) {
  const { data, loading } = useQuery<ConsultationsModule>(
    ENVIRONMENTAL_CLEANUP_NOTICES_MODULE
  );
  const [onDelete] = useDeleteConsultation();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { consultations: items } = data;

  return (
    <>
      <Title>Your Consultations</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              nextURL={nextURL}
              createURL={createURL}
              filingProfileBoolean="hasNoBankruptcyConsultations"
              addText="Add a consultation"
              question={
                <p className="required">
                  <strong>
                    Other than your current bankruptcy attorney, have you
                    contacted any law firms or debt settlement companies for
                    bankruptcy advice or bankruptcy-related services?
                  </strong>
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you have not consulted with any law
                  firms or debt settlement companies for bankruptcy advice or
                  services outside of the scope of the current bankruptcy filing
                  you are using Lexria Intake for. If that’s correct, you’re
                  ready to continue to the next section. Otherwise, you may add
                  consultations below.
                </p>
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Besides this current bankruptcy filing with your attorney, tell
                us about all of the law firms and debt settlement companies you
                have made payments to for bankruptcy advice or debt settlement
                services.
              </p>
              <ButtonRow
                style={{ marginBottom: "8px" }}
                left={
                  <Link to={createURL} className="btn secondary">
                    <FaPlus />
                    <span>Add a consultation</span>
                  </Link>
                }
                right={
                  <Link to={nextURL} className="btn primary">
                    <span>Done</span>
                    <FaCheck />
                  </Link>
                }
              />
              {items.map((consultation) => {
                return (
                  <ListItem
                    key={consultation.id}
                    onEdit={() => onEdit(consultation.id)}
                    onDelete={() => onDelete(consultation.id)}
                    objectName="consultation"
                  >
                    <div>
                      {formatErrorsFromSchema(ConsultationSchema, consultation)}
                      {formatConsultationType(
                        consultation.consultationType
                      )}{" "}
                      from {consultation.consultantName}
                    </div>
                  </ListItem>
                );
              })}
            </>
          )}
        />
      </Switch>
    </>
  );
}
