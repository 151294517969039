import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaSearchDollar } from "react-icons/fa";
import { AssetLosses } from "./AssetLosses";
import AssetLossesSummary from "./Summary";

const AssetLossesSubmodule: SubModule = {
  path: "lost_assets",
  icon: FaSearchDollar,
  title: "Lost Assets",
  component: AssetLosses,
  summary: AssetLossesSummary,
  statusName: "assetLosses",
};

export default AssetLossesSubmodule;
