import { useGetConsultationPayment } from "api/graphql/Consultation";
import { EditViewProps } from "components/EditCreateList/stateless";
import { useEditorBuffer } from "components/EditorState/hooks";
import { Loading } from "components/Loading";
import React from "react";
import { ConsultationPaymentForm } from "./ConsultationPaymentForm";

export function EditView({ id, listURL }: EditViewProps) {
  const [{ buffer }] = useEditorBuffer();
  const { data } = useGetConsultationPayment(id);

  if (!data) return <Loading />;

  return (
    <>
      <h3>Edit payment to {buffer.consultantName}</h3>
      <ConsultationPaymentForm
        paymentId={id}
        consultationId={buffer.id}
        nextURL={listURL}
        backURL={listURL}
        abortURL={listURL}
        saveText="Update payment"
      />
    </>
  );
}
