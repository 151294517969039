import { Link, Switch, Route, Redirect } from "react-router-dom";
import { Title } from "components/Title";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { useNested } from "utils/hooks";
import { useListGigIncomes, useDeleteGigIncome } from "api/graphql/GigIncome";

import { ButtonRow } from "components/ButtonRow";
import { FaCheck } from "react-icons/fa";
import { ListItem } from "components/ListItem";

export interface GigIncomeProps {
  onEdit: (id: string) => any;
  createURL: string;
  nextURL: string;
}

export function ListView({ onEdit, createURL, nextURL }: GigIncomeProps) {
  const { data, loading } = useListGigIncomes();
  const [onDelete] = useDeleteGigIncome();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { gigIncomes: items } = data;

  return (
    <>
      <Title>Gig Work Income</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              nextURL={nextURL}
              createURL={createURL}
              filingProfileBoolean="hasNoGigIncome"
              addText="Add gig work income"
              question={
                <p className="required">
                  <strong>
                    Do you have any income from gig work (such as driving for a
                    ride-sharing service or delivering food)?
                  </strong>
                </p>
              }
              confirmation={
                <p>
                  You indicated that you have no income from gig work. If that’s
                  correct, you’re ready to continue to the next section.
                  Otherwise, you can add your gig work income below.
                </p>
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}
        <Route
          path={path("/")}
          render={() => {
            return (
              <>
                <p>
                  List all gig work companies you've worked for in the last 6
                  months and the type of work you performed. For example:
                </p>
                <ul className="mt-0">
                  <li>Uber, drove for 20-30 hours a week</li>
                  <li>Doordash, delivered food 3 nights a week</li>
                </ul>
                <p>
                  Note: Your attorney's office may be in touch regarding
                  additional documentation needed for this type of work.
                </p>
                <ButtonRow
                  style={{ marginBottom: "8px" }}
                  right={
                    <Link to={nextURL} className="btn primary">
                      <span>Done</span>
                      <FaCheck />
                    </Link>
                  }
                />
                {items.map((gigIncome) => (
                  <ListItem
                    key={gigIncome.id}
                    onEdit={() => onEdit(gigIncome.id)}
                    onDelete={() => onDelete(gigIncome.id)}
                    objectName="gigIncome"
                  >
                    <div>Gig work: {gigIncome.description}</div>
                  </ListItem>
                ))}
              </>
            );
          }}
        />
      </Switch>
    </>
  );
}
