import { useQuery } from "@apollo/client";
import CategoriesList from "components/CategoriesList";
import { LearnMore } from "components/LearnMore";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import { sumBy } from "lodash";
import React from "react";
import { breakpoints, useBreakpoint, useNested } from "utils/hooks";
import { parseFancyNumber } from "utils/validators";
import HOUSEHOLD_ITEMS_MODULE from "./HouseholdItemsModule.gql";
import { HouseholdItemSchema } from "./HouseholdItemWizard";
import sectionCategories from "./ItemTypes";
import { LearnMoreCommon } from "./LearnMoreCommon";

export default function HouseholdItemCategories({
  doneURL,
}: {
  doneURL: string;
}) {
  const { url } = useNested();
  const isMobile = useBreakpoint(breakpoints.mobile);
  const { data } = useQuery<HouseholdItemsModule>(HOUSEHOLD_ITEMS_MODULE);

  if (!data) return <Loading />;

  const currencyFormatter = new Intl.NumberFormat("en", {
    style: "currency",
    currency: "USD",
  });

  return (
    <div>
      <Title>Your Household Items and Personal Property</Title>
      <p>
        Your lawyer will need to know about the property you would like to claim
        as exempt in your bankruptcy petition. Claiming property as exempt
        protects it from claims by your creditors. Each state has its own limit,
        so claim what is truly important to you and necessary for you to
        maintain your quality of life.{" "}
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
      <p>
        {isMobile ? "Tap " : "Click "} each of the following sections to tell us
        about a category of items you or household owns.
      </p>

      <CategoriesList
        doneURL={doneURL}
        categories={sectionCategories.map((c) => {
          const items = data.householdItems.filter(
            (item) => item.category === c.category
          );
          const itemsValue = currencyFormatter.format(
            sumBy(items, (item) => Number(parseFancyNumber(item.currentValue)))
          );

          return {
            name: c.displayMenuName,
            href: url(c.urlPathName),
            Icon: c.menuIcon,
            hasNone: data.filingProfile[c.hasNoProfileBooleanName],
            schema: HouseholdItemSchema,
            items,
            itemName: "item",
            itemsSummary: `${itemsValue} total`,
          };
        })}
      />
    </div>
  );
}
