import { useUpsertEmploymentRecord } from "api/graphql/EmploymentRecord";
import { Wizard } from "components/Wizard";
import { omit } from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import { EmployerAddress, EmployerAddressSchema } from "./EmployerAddress";
import { History, HistorySchema } from "./History";
import { Overview, OverviewSchema } from "./Overview";
import { PayChecks, PayChecksSchema } from "./PayChecks";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  subtitle?: string;
  initialValue?: any;
  editURL?: string;
}

export const EmploymentSchema = OverviewSchema.concat(EmployerAddressSchema)
  .concat(PayChecksSchema)
  .concat(HistorySchema);

export function EmploymentWizard({
  backURL,
  nextURL,
  initialValue,
  title,
  subtitle,
  editURL = "edit",
}: Props) {
  const { url } = useNested();
  const [upsertEmploymentRecord] = useUpsertEmploymentRecord();
  const history = useHistory();
  const [employmentRecordId, setEmploymentRecordId] = useState(
    initialValue?.id
  );

  return (
    <Wizard
      title={title}
      subtitle={subtitle}
      backURL={backURL}
      nextURL={nextURL}
      initialValue={initialValue}
      vertical
      steps={[
        {
          path: "/overview",
          title: "Overview",
          component: Overview,
          schema: OverviewSchema,
        },
        {
          path: "/history-income",
          title: "History and Income",
          component: History,
          schema: HistorySchema,
        },
        {
          path: "/employer-address",
          title: "Address",
          component: EmployerAddress,
          schema: EmployerAddressSchema,
          async onSave(formState) {
            // Remove the hack fields we added to the form.
            delete formState.employerLinkToken;
            delete formState.useEmployerApi;
            const { data } = await upsertEmploymentRecord({
              ...formState,
            });
            const { id } = data.employmentRecord;
            setEmploymentRecordId(id);
            setTimeout(() => {
              if (employmentRecordId) {
                history.push(url("/paychecks"));
              } else {
                history.push(`../${editURL}/${id}/paychecks`);
              }
            }, 0);
            return data.employmentRecord;
          },
        },
        {
          path: "/paychecks",
          title: "Paychecks",
          component: PayChecks,
          schema: PayChecksSchema,
        },
      ]}
    />
  );
}
