import { useUpsertMortgage } from "api/graphql/RealEstateMortgage";
import { CreateView as CreateRealEstatePropertyView } from "components/FormModules/Assets/RealEstate/CreateView";
import { EditView as EditRealEstateView } from "components/FormModules/Assets/RealEstate/EditView";
import { CollectorForm } from "components/FormSections/Collector";
import { CosignerForm } from "components/FormSections/Cosigner";
import { Wizard } from "components/Wizard";
import { CollectorsSchema } from "components/WizardSteps/Collectors";
import { CosignersSchema } from "components/WizardSteps/Cosigners";
import React, { useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import Collectors from "./Collectors";
import Cosigners from "./Cosigners";
import Details, { DetailsSchema } from "./Details";
import Lender, { LenderSchema } from "./Lender";
import Property, { PropertySchema } from "./Property";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
}

export const MortgageSchema = PropertySchema.concat(LenderSchema)
  .concat(DetailsSchema)
  .concat(CollectorsSchema)
  .concat(CosignersSchema);

export function MortgageWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: Props) {
  const { path, url } = useNested();
  const [upsertMortgage] = useUpsertMortgage();
  const history = useHistory();
  const [mortgageId, setMortgageId] = useState(initialValue?.id);

  const cosignersPath = "/cosigners";
  const collectorsPath = "/collectors";
  const propertyPath = "/property";
  const editPropertyPath = "edit_property";

  return (
    <Switch>
      <Route
        path={path("/create_property")}
        render={() => (
          <CreateRealEstatePropertyView
            editURL={editPropertyPath}
            listURL={url(propertyPath)}
          />
        )}
      />

      <Route
        path={path(`/${editPropertyPath}/:editId`)}
        render={(routeProps) => {
          const id = routeProps?.match?.params?.editId;
          return (
            <EditRealEstateView
              editURL={editPropertyPath}
              listURL={url(propertyPath)}
              id={id}
            />
          );
        }}
      />

      <Route
        path={path("/create_cosigner")}
        render={() => (
          <CosignerForm
            title="Add a Mortgage Cosigner"
            returnURL={url(cosignersPath)}
            relatedId={mortgageId}
            relatedTypename="RealEstateMortgage"
          />
        )}
      />
      <Route
        path={path("/edit_cosigner/:cosignerId")}
        render={({ match }) => (
          <CosignerForm
            title="Updating Mortgage Cosigner"
            returnURL={url(cosignersPath)}
            relatedId={mortgageId}
            relatedTypename="RealEstateMortgage"
            id={match.params.cosignerId}
          />
        )}
      />

      <Route
        path={path("/create_collector")}
        render={() => (
          <CollectorForm
            title="Add a Mortgage Debt Collector"
            returnURL={url(collectorsPath)}
            relatedId={mortgageId}
            relatedTypename="RealEstateMortgage"
          />
        )}
      />
      <Route
        path={path("/edit_collector/:collectorId")}
        render={({ match }) => (
          <CollectorForm
            title="Updating Mortgage Debt Collector"
            returnURL={url(collectorsPath)}
            relatedId={mortgageId}
            relatedTypename="RealEstateMortgage"
            id={match.params.collectorId}
          />
        )}
      />

      <>
        <Wizard
          title={title}
          backURL={backURL}
          nextURL={nextURL}
          initialValue={initialValue}
          vertical
          steps={[
            {
              path: propertyPath,
              title: "Property",
              component: Property,
              schema: PropertySchema,
            },
            {
              path: "/lender",
              title: "Lender",
              component: Lender,
              schema: LenderSchema,
            },
            {
              path: "/details",
              title: "Mortgage Details",
              component: Details,
              schema: DetailsSchema,
              onSave: async (formState) => {
                const { data } = await upsertMortgage(formState);
                if (!data) return formState;
                const { id } = data.realEstateMortgage;
                setMortgageId(id);
                if (mortgageId) {
                  history.push(url(cosignersPath));
                } else {
                  history.push(`../edit/${id}/cosigners`);
                }
                return data.realEstateMortgage;
              },
            },
            {
              path: cosignersPath,
              title: "Cosigners",
              component: Cosigners,
              schema: CosignersSchema,
            },
            {
              path: collectorsPath,
              title: "Collectors",
              component: Collectors,
              schema: CollectorsSchema,
            },
          ]}
        />
      </>
    </Switch>
  );
}
