import PrivacyInput, { PrivacyInputProps } from "components/PrivacyInput";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

export default function AccountMaskInput({
  name,
  label = "Account number (last 4)",
  defaultValue,
  maxLength = 4,
  width = "200px",
  ...etc
}: PrivacyInputProps) {
  const form = useFormContext();
  const [value, setValue] = useState(
    form?.getValues(name) || defaultValue || ""
  );
  return (
    <PrivacyInput
      {...etc}
      name={name}
      label={label}
      value={form ? undefined : value}
      onChange={setValue}
      maxLength={maxLength}
      width={width}
      onPaste={(e) => {
        e.preventDefault();

        let pastedText = undefined;
        const { clipboardData } = window as any;
        if (clipboardData?.getData) {
          // IE
          pastedText = clipboardData.getData("Text");
        } else if (e.clipboardData && e.clipboardData.getData) {
          pastedText = e.clipboardData.getData("text/plain");
        }

        const lastFour = pastedText.slice(-4);

        if (form) {
          form.setValue(name, lastFour);
        } else {
          setValue(lastFour);
        }
        (e.target as HTMLInputElement).value = lastFour;

        return false;
      }}
    />
  );
}
