import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import { omit } from "lodash";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import FARM_ASSETS_MODULE from "./FarmAssetsModule.gql";

export default function FarmAssetsSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<FarmAssetsModule>(FARM_ASSETS_MODULE, {
    returnPartialData: true,
  });

  const profileValues = Object.values(
    omit(data?.filingProfile, "__typename", "id")
  );

  const hasNone = profileValues.every((hasNone) => !!hasNone);

  return (
    <ModuleSummary {...props} progress={data?.progress.farmAssets}>
      {hasNone ? (
        <HasNoSummary>
          You don&rsquo;t own any farming or commercial fishing assets.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            value={data?.farmAssets?.length}
            maxValueLength={2}
            label="asset"
            pluralizeLabel
          />

          <NumericSummary
            valuePrefix="$"
            value={fancyNumberSum(data?.farmAssets, "currentValue")}
            maxValueLength={5}
            label="total value"
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
