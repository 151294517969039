import { useGetAssetLoss } from "api/graphql/AssetLoss";
import { Loading } from "components/Loading";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { AssetLossWizard } from "./AssetLossWizard";

export function EditView({
  id,
  listURL,
  ...props
}: {
  id: UUID;
  listURL: string;
}) {
  const { data, loading } = useGetAssetLoss(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.assetLoss) {
    return <Redirect to={listURL} />;
  }

  return (
    <AssetLossWizard
      title={"Updating Lost Asset"}
      backURL={listURL}
      nextURL={listURL}
      initialValue={data.assetLoss}
    />
  );
}
