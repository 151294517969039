import { useQuery } from "@apollo/client";
import { FormModuleLink } from "components/FormFlow/Dashboard";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import React from "react";
import CREDIT_REPORTS_MODULE from "./CreditReportsModule.gql";

export default function CreditReportSummary({
  href,
  icon,
  title,
}: SubModuleSummaryProps) {
  const { data } = useQuery<CreditReportsModule>(CREDIT_REPORTS_MODULE, {
    returnPartialData: true,
  });

  return (
    <FormModuleLink
      href={href}
      icon={icon}
      title={title}
      status={data?.progress.creditReports}
    />
  );
}
