import React from "react";

export default function PasswordResetLink({
  children,
  ...props
}: React.HTMLAttributes<HTMLAnchorElement>) {
  return (
    <a
      href="https://credit.lexria.com/forgot_password_request.asp"
      target="_blank"
      rel="noreferrer"
      tabIndex={-1}
      {...props}
    >
      {children || "reset your password"}
    </a>
  );
}
