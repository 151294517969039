/* eslint-disable @typescript-eslint/ban-types */
import { yupResolver } from "@hookform/resolvers/yup";
import { EditorState } from "components/EditorState";
import {
  useEditorBuffer,
  useEditorMeta,
  useEditorSave,
} from "components/EditorState/hooks";
import { Form, transformData } from "components/Form";
import { Title } from "components/Title";
import {
  WizardNavigation,
  WizardNavigationProps,
} from "components/WizardNavigation";
import { findIndex, isMatch, last, merge } from "lodash";
import * as React from "react";
import { DeepPartial, useForm, UseFormMethods } from "react-hook-form";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  ErrorTranslationBackends,
  useBackendErrorTranslation,
  useCheckMounted,
  useNested,
  useUpdateCallback,
} from "utils/hooks";
import { object, ObjectSchema, string } from "yup";
import HorizontalWizard from "./HorizontalWizard";
import VerticalWizard from "./VerticalWizard";

const useUpdateBuffer = () => {
  const [bufferState, bufferDispatch] = useEditorBuffer();
  const updatePromise = React.useRef(null);
  const resolver = React.useRef(null);
  const [values, setValues] = React.useState(null);
  const mergeRef = React.useRef(false);

  const hasbufferUpdateCompleted = React.useCallback(
    (buffer) => {
      return isMatch(buffer, bufferState);
    },
    [bufferState]
  );

  React.useEffect(() => {
    if (values === null) return;
    bufferDispatch({ type: "UPDATE_BUFFER", values, merge: mergeRef.current });
  }, [values]);

  React.useEffect(() => {
    if (resolver.current && hasbufferUpdateCompleted(bufferState)) {
      resolver.current(bufferState.buffer);
    }
  }, [bufferState]);

  return React.useCallback((values_: any, merge?: boolean) => {
    mergeRef.current = !!merge;
    updatePromise.current = new Promise((resolve) => {
      resolver.current = resolve;
    });
    setValues(values_);
    return updatePromise.current;
  }, []);
};

export interface WizardStepProps<
  MySchema extends object | null | undefined,
  OnSaveSchema,
  RouteChildProps = WizardRouteChildProps
> {
  /** The path of this step, relative to the wizard path. */
  path: string;
  includeSubpaths?: boolean;
  component: React.FC<RouteChildProps>;
  schema: ObjectSchema<MySchema>;
  /** The title that should be rendered for this component in wizard navigation. */
  title: string;
  /** Can be used to persist form data part-way through the wizard. */
  onSave?: (state: OnSaveSchema) => any;
  partialNestedUpdate?: boolean;
  navigateAfterSave?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function WizardStep<T extends object>(
  props: WizardStepProps<T, any>
): null {
  return null;
}

export interface WizardRouteChildProps<AccumulatedFormSchema = any> {
  form: UseFormMethods<AccumulatedFormSchema>;
  navProps: WizardNavigationProps;
  isEdit: boolean;
}

export interface WizardRenderProps {
  nav: React.ReactElement<WizardNavigationProps>;
  children: React.ReactElement<WizardStepProps<any, any>>[];
  lastEnabledStep: number;
  currentStep: number;
  hideNavigation: boolean;
  hideButtons: boolean;
  form: UseFormMethods;
  onNav: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    newURL: string
  ) => any;
  error?: string;
  isEdit: boolean;
}

interface WizardRouterNonChildProps {
  nextURL?: string;
  backURL: string;
  abortURL?: string;
  nextText?: string;
  saveText?: string;
  saveIcon?: React.ReactElement;
  abortText?: string;
  backText?: string;
  hideNavigation?: boolean;
  hideButtons?: boolean;
  vertical?: boolean;
  isEdit?: boolean;
  errorTranslationBackend?: ErrorTranslationBackends;
}

export function WizardRouter({
  children,
  nextURL,
  backURL,
  abortURL = backURL,
  nextText = "Next",
  saveText = "Save",
  saveIcon,
  abortText = "Cancel",
  backText = "Back",
  hideNavigation = false,
  vertical = false,
  isEdit = false,
  errorTranslationBackend,
  hideButtons = false,
}: WizardRouterNonChildProps & {
  children: React.ReactElement[];
}) {
  const { url, path } = useNested();
  const [state, dispatch] = useEditorBuffer();
  const [meta, dispatchMeta] = useEditorMeta();
  const location = useLocation();
  const save = useEditorSave();
  const history = useHistory();
  const checkMounted = useCheckMounted();

  /*
    because we need to be able to access any updated value
    of nextURL, not the closed over value inside of the
    doSave callback
  */
  const nextURLRef = React.useRef(nextURL);
  React.useEffect(() => {
    nextURLRef.current = nextURL;
  }, [nextURL]);

  const normalizedChildren = React.Children.toArray(
    children
  ) as React.ReactElement<WizardStepProps<any, any>>[];

  const getFirstIncomplete = React.useCallback(
    (state: any) => {
      for (const [index, child] of normalizedChildren.entries()) {
        if (!child.props.schema.isValidSync(state.buffer)) {
          return index;
        }
      }
      return normalizedChildren.length;
    },
    [normalizedChildren]
  );

  // The initial state should have already been submitted to validation,
  // so we can assume that the first incomplete step is the last enabled step
  const [lastEnabledStep, setLastEnabled] = React.useState(
    getFirstIncomplete(state)
  );
  const defaultStep =
    lastEnabledStep === normalizedChildren.length ? 0 : lastEnabledStep;

  const currentStep = React.useMemo(() => {
    const withoutSearch = location.pathname.split("?")[0];
    const withoutTrailingSlash = withoutSearch.replace(/\/$/, "");
    return findIndex(normalizedChildren, (c) => {
      if (c.props.includeSubpaths) {
        return withoutTrailingSlash.includes(c.props.path);
      } else {
        return withoutTrailingSlash.endsWith(c.props.path);
      }
    });
  }, [location.pathname, normalizedChildren]);

  const validationSchema =
    normalizedChildren[currentStep >= 0 ? currentStep : defaultStep].props
      .schema;

  const form = useForm({
    defaultValues: state.buffer,
    resolver: yupResolver(validationSchema),
  });
  const translateErrors = useBackendErrorTranslation(
    form,
    errorTranslationBackend
  );

  const nextChildPath = normalizedChildren[currentStep + 1]?.props?.path;
  const prevChildPath = normalizedChildren[currentStep - 1]?.props?.path;
  const queuedURL = React.useRef("");
  const [incompleteData, setIncompleteData] = React.useState({});

  const lastChild = last(normalizedChildren);
  const isComplete =
    !!normalizedChildren[currentStep]?.props.onSave ||
    (lastChild && location.pathname.includes(lastChild.props.path));

  const updateBuffer = useUpdateBuffer();

  const onSubmit = async (values: any, navigate = true) => {
    if (!checkMounted()) {
      /* testing nicety */ return;
    }

    const mergeValues =
      !!normalizedChildren[currentStep]?.props?.partialNestedUpdate;

    let updated: any;
    if (!mergeValues) {
      dispatch({ type: "UPDATE_BUFFER", values });
    } else {
      updated = await updateBuffer(values, mergeValues);
    }

    if (currentStep === lastEnabledStep) {
      // Now that data has been validated successfully, we can
      // enable the next step
      setLastEnabled(lastEnabledStep + 1);
    }

    // The form must be reset before navigation for inputs to pick up the
    // initial value, and after onSubmit finishes so isSubmitted == false

    setTimeout(() => {
      const formUpdate = mergeValues
        ? merge(incompleteData, updated)
        : { ...incompleteData, ...state.buffer };
      form.reset(formUpdate);
      if (navigate) history.push(queuedURL.current);
    }, 0);
  };

  const doSave = useUpdateCallback(async () => {
    try {
      const { onSave } = normalizedChildren[currentStep].props;

      if (onSave) {
        dispatchMeta({ type: "SET_WORKING" });
        const schemaSoFar = normalizedChildren
          .slice(0, currentStep + 1)
          .reduce<ObjectSchema>(
            (schema, child) => schema.concat(child.props.schema),
            object({ id: string() })
          );
        const newState = await onSave(
          // Strip out fields from initial value that aren't included yet
          schemaSoFar.validateSync(state.buffer, { stripUnknown: true })
        );
        await onSubmit(
          newState,
          !!normalizedChildren[currentStep].props.navigateAfterSave
        );

        if (!checkMounted()) {
          /* testing nicety */ return;
        }
        dispatchMeta({ type: "UNSET_WORKING" });
      }

      if (!nextChildPath) {
        if (save) {
          await save();
        }
        if (nextURLRef.current) history.push(nextURLRef.current);
      }
    } catch (e) {
      console.error(e);
      translateErrors(e);
      dispatchMeta({ type: "UNSET_WORKING" });
    }
  });

  /** Save progress in temporary state and navigate to another page. */
  const navigateWithoutSave = (newURL: string) => {
    setIncompleteData(form.getValues());
    form.reset({ ...incompleteData, ...state.buffer });
    history.push(newURL);
  };

  /** Sets the URL that will be navigated to when onSubmit is next called. */
  const queueURLForOnSubmit = (newURL: string) => {
    queuedURL.current = newURL;
  };

  const onBack = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const newURL = prevChildPath
      ? url(prevChildPath, { preserveSearch: true })
      : backURL;
    queueURLForOnSubmit(newURL);
    if (currentStep === lastEnabledStep || isComplete) {
      // Prevent onSubmit from being called
      e.preventDefault();
      navigateWithoutSave(newURL);
    }
    // onSubmit will then be called
  };
  const onNext = () => {
    if (!nextChildPath) return;
    queueURLForOnSubmit(url(nextChildPath, { preserveSearch: true }));
    // onSubmit will then be called
  };
  const onNav = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    newURL: string
  ) => {
    e.preventDefault();
    if (currentStep === lastEnabledStep) {
      navigateWithoutSave(newURL);
    } else {
      queueURLForOnSubmit(newURL);
      form.trigger();
      setTimeout(() => {
        if (!Object.keys(form.errors).length) {
          onSubmit(transformData(form.getValues()));
        }
      }, 0);
    }
  };

  const onSave = async (values: any) => {
    dispatch({ type: "UPDATE_BUFFER", values });
    doSave();
  };

  const nav = (
    <WizardNavigation
      onBack={onBack}
      onNext={onNext}
      abortURL={abortURL}
      isComplete={isComplete}
      saving={meta.working}
      backIsSubmit
      nextText={nextText}
      saveText={saveText}
      saveIcon={saveIcon}
      abortText={abortText}
      backText={backText}
    />
  );
  const RenderWizard = vertical ? VerticalWizard : HorizontalWizard;

  return (
    <Switch>
      <Route exact path={path("/")} key="default_path">
        <Redirect
          to={url(normalizedChildren[defaultStep].props.path, {
            preserveSearch: true,
          })}
        />
      </Route>
      <Form
        useForm={form}
        schema={validationSchema}
        onSubmit={
          isComplete ? onSave : (values, event) => onSubmit(values, !!event)
        }
      >
        <RenderWizard
          nav={nav}
          lastEnabledStep={lastEnabledStep}
          currentStep={currentStep}
          hideNavigation={hideNavigation}
          hideButtons={hideButtons}
          form={form}
          onNav={onNav}
          error={form.errors?.nonFieldErrors?.message || meta.errorMessage}
          isEdit={isEdit}
        >
          {normalizedChildren}
        </RenderWizard>
      </Form>
      <Route key="catchall">
        <Redirect to={url("/", { preserveSearch: true })} />
      </Route>
    </Switch>
  );
}

export interface WizardProps<FormSchema> extends WizardRouterNonChildProps {
  initialValue?: DeepPartial<FormSchema>;
  title?: string;
  subtitle?: React.ReactNode;
  onSave?: (
    data: FormSchema
  ) => DeepPartial<FormSchema> | Promise<DeepPartial<FormSchema>>;
}

/**
 * A batteries-included solution for wizard UI and state management.
 *
 * There are two versions of the Wizard, vertical and horizontal. The
 * horizontal form is better for short processes of around 2-4 steps.
 * The vertical form is a stepper which can accommodate many more steps.
 * The horizontal form includes navigation, the vertical form requires your
 * wizard pages to include navigation to allow for more complex scenarios.
 *
 * All state included in form submissions is added to the wizard's state buffer.
 * Routing is handled automatically by default, based on the ordering of wizard
 * steps and their paths. By default, the last step is considered a save, and
 * the onSave prop on the Wizard is passed the buffer to allow building a complete api request
 * before submission.
 *
 * By passing an onSave prop to a given step, you can persist at any stage in the
 * process. The value you return from the onSave function will be saved to the buffer.
 * When using a step onSave, you must call `history.push` yourself. This allows for
 * more complex custom routing.
 */
export function Wizard<
  S extends object,
  T extends object,
  U extends object,
  V extends object,
  W extends object,
  X extends object
>({
  steps,
  initialValue,
  title,
  nextURL,
  backURL,
  abortURL,
  onSave,
  vertical = false,
  subtitle,
  ...routerProps
}: WizardProps<S & T & U & V & W & X> & {
  steps: [
    WizardStepProps<S, S>,
    WizardStepProps<T, S & T>?,
    WizardStepProps<U, S & T & U>?,
    WizardStepProps<V, S & T & U & V>?,
    WizardStepProps<W, S & T & U & V & W>?,
    WizardStepProps<X, S & T & U & V & W & X>?
  ];
}) {
  return (
    <EditorState<S & T & U & V & W & X>
      initialValue={initialValue}
      onSave={({ buffer }) => {
        if (!onSave) {
          return Promise.resolve(buffer.buffer);
        }

        const result = onSave(buffer.buffer);
        if (result instanceof Promise) {
          return result;
        }
        return Promise.resolve(result);
      }}
    >
      {title && <Title>{title}</Title>}
      {subtitle}

      <WizardRouter
        nextURL={nextURL}
        backURL={backURL}
        abortURL={abortURL}
        vertical={vertical}
        isEdit={!!(initialValue as any)?.id}
        {...routerProps}
      >
        {steps
          .filter((x): x is WizardStepProps<any, any> => !!x)
          .map((s) => s && <WizardStep {...s} key={s.path} />)}
      </WizardRouter>
    </EditorState>
  );
}
