import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_PREVIOUS_NAME from "./DeletePreviousName.gql";
import GET_PREVIOUS_NAME from "./GetPreviousName.gql";
import LIST_PREVIOUS_NAME from "./ListPreviousName.gql";
import UPSERT_PREVIOUS_NAME from "./UpsertPreviousName.gql";

const LIST_PREVIOUS_NAME_IDS = gql`
  query ListPreviousNameIds {
    previousNames {
      id
    }
  }
`;

export function useListPreviousName(
  options?: QueryHookOptions<ListPreviousName>
) {
  return useQuery<ListPreviousName>(LIST_PREVIOUS_NAME, options);
}

export function useGetPreviousName(id: string) {
  return useQuery<GetPreviousName, GetPreviousNameVariables>(
    GET_PREVIOUS_NAME,
    {
      variables: { id },
    }
  );
}

export function useUpsertPreviousName<
  Schema extends { previousName: any } = UpsertPreviousName,
  Variables = UpsertPreviousNameVariables
>(query = UPSERT_PREVIOUS_NAME) {
  return useUpsert<Schema, Variables, ListPreviousNameIds, PreviousNameInput>(
    query,
    LIST_PREVIOUS_NAME_IDS,
    "previousName",
    "previousNames",
    (cache) => updateFilingProfileCache(cache, "hasNoPreviousNames", false)
  );
}

export function useDeletePreviousName() {
  return useDelete<DeletePreviousName, ListPreviousNameIds>(
    DELETE_PREVIOUS_NAME,
    LIST_PREVIOUS_NAME_IDS,
    "deletePreviousName",
    "previousNames"
  );
}
