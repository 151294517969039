import { useListRoot } from "components/EditCreateList/hooks";
import React, { useState } from "react";
import { SeizureWizard } from "./SeizureWizard";

export function CreateView({ listURL }: { listURL: string }) {
  const root = useListRoot();
  const [nextURL, setNextURL] = useState(`${root.url}/summary`);
  return (
    <SeizureWizard
      title="Add a seized asset"
      backURL={`${root.url}/summary`}
      nextURL={nextURL}
    />
  );
}
