import { Client } from "@shaggytools/nhtsa-api-wrapper";
import TypeAhead from "components/autocomplete/TypeAhead";
import { ascending } from "d3";
import { startCase, uniq } from "lodash";
import * as React from "react";
import { useCheckMounted } from "utils/hooks";

interface AutocompleteVehicleMakeProps {
  inputSelector: string;
  onSelectMake: (make: string) => void;
}

export function AutocompleteVehicleMake({
  inputSelector,
  onSelectMake,
}: AutocompleteVehicleMakeProps) {
  const [makes, setMakes] = React.useState<string[]>(null);
  const checkMounted = useCheckMounted();

  React.useEffect(() => {
    const loadMakes = async () => {
      try {
        const makes = uniq(
          (await Client.GetAllMakes()).Results.map((make) => {
            return startCase(make.Make_Name.toLowerCase());
          }).sort(ascending)
        );

        if (checkMounted()) setMakes(makes);
      } catch (e) {
        console.error(e);
      }
    };

    loadMakes();
  }, [checkMounted]);

  const getSuggestions = React.useCallback(
    async (query: string) => {
      if (makes === null || query.length < 2) return [];
      return makes.filter((make) =>
        make.toUpperCase().includes(query.toUpperCase())
      );
    },
    [makes]
  );

  return (
    <TypeAhead
      inputSelector={inputSelector}
      getSuggestions={getSuggestions}
      onSelect={onSelectMake}
    />
  );
}

interface AutocompleteVehicleModelProps {
  inputSelector: string;
  onSelectModel: (model: string) => void;
  make: string | null | undefined;
}

export function AutocompleteVehicleModel({
  inputSelector,
  onSelectModel,
  make,
}: AutocompleteVehicleModelProps) {
  const getSuggestions = React.useCallback(
    async (query: string) => {
      if (!make || query.length < 2) return [];

      try {
        const models = (
          await Client.GetModelsForMake(make.toUpperCase())
        ).Results.map((result) => result.Model_Name).sort(ascending);

        return models.filter((model) =>
          model.toUpperCase().includes(query.toUpperCase())
        );
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    [make]
  );

  return (
    <TypeAhead
      inputSelector={inputSelector}
      getSuggestions={getSuggestions}
      onSelect={onSelectModel}
    />
  );
}
