import { WizardNavigation } from "components/WizardNavigation";
import React from "react";

export interface Props {
  next?: string;
  prev?: string;
}

export default function PendingView(props: Props) {
  return (
    <>
      <p>
        We’re working on importing your credit report. Usually this only takes a
        few minutes. In the mean time, feel free to continue working on other
        sections.
      </p>
      <WizardNavigation
        isComplete
        saveText="Done"
        nextURL={props.next}
        backURL={props.prev}
        abortURL={undefined}
      />
    </>
  );
}
