import * as React from "react";
import { useCheckMounted } from "./useCheckMounted";

/**
 * Hook for adjusting the layout of the site to accommodate
 * position: fixed elements at the bottom of the screen.
 *
 * Call `shift` when the fixed element is displayed, passing the height
 * of the element as a css value string. Call it again whenever the height
 * of your element changes.
 *
 * Call `reset` when the fixed element is removed to get the layout
 * back to its original state.
 */
export const useFixedBottom = () => {
  const rootEl: HTMLElement =
    document.getElementById("main-container") || document.body;
  const [shifted, setShifted] = React.useState("");
  const isMounted = useCheckMounted();

  const shift = React.useCallback((spaceNeeded: string) => {
    rootEl.style.paddingBottom = spaceNeeded;

    if (isMounted()) {
      setShifted(spaceNeeded);
    }
  }, []);

  const reset = React.useCallback(() => {
    rootEl.style.paddingBottom = "0";

    if (isMounted()) {
      setShifted("");
    }
  }, []);

  return { reset, shift };
};
