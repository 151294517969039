import { BooleanInput } from "components/BooleanInput";
import { HiddenInput } from "components/HiddenInput";
import { LearnMore } from "components/LearnMore";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { useFormContext } from "react-hook-form";
import { boolean, object, string } from "yup";
import AutocompleteEducationalInstitution from "components/autocomplete/EducationalInstitution";

export const OverviewSchema = object({
  school: string().required("Please enter the name of this school."),
  degree: string().required(
    "Please enter the type of degree you were pursuing."
  ),
  graduated: boolean().required("This field is required."),
  educationalInstitution: object({
    id: string(),
  }).nullable(),
}).required();
export type OverviewFormState = ReturnType<typeof OverviewSchema.validateSync>;

export function Overview({ isEdit, navProps }: WizardRouteChildProps) {
  const form = useFormContext<OverviewFormState>();

  const updateEducationalInstitution = (event: Event) => {
    /* Handles nasty cases where humans select, then override, etc. */
    const school = form.getValues("school");
    const educationalInstitution: any = form.getValues(
      "educationalInstitution"
    );
    if (school !== educationalInstitution?.displayName) {
      form.setValue("educationalInstitution", null);
    }
    return navProps.onNext(event);
  };

  return (
    <>
      <div className="form-row">
        <TextInput
          name="school"
          label="School name"
          placeholder="e.g. University of Texas"
          maxLength={255}
          autoFocus={!isEdit}
        />
        <AutocompleteEducationalInstitution
          inputSelector={'[name="school"]'}
          onEducationalInstitutionSelected={(institution) => {
            form.setValue("school", institution.displayName);
            form.setValue("educationalInstitution", institution);
            (document.querySelector('[name="degree"]') as
              | HTMLElement
              | undefined)?.focus();
          }}
        />
        <HiddenInput name="educationalInstitution" />
        <TextInput
          name="degree"
          label="Degree pursued"
          placeholder="Certificate, B.A., B.S., M.S., Ph.D, etc."
          maxLength={64}
          learnMore={
            <LearnMore>
              <h4>What should I put in the degree field?</h4>
              <p>
                You should put in the type of degree sought, such as A.A., B.S.,
                M.A., Ph.D., etc.
              </p>

              <h4>I didn&rsquo;t attend a university, what should I put in?</h4>
              <p>
                If you attended a trade school that offers a certificate, you
                should say that. Otherwise, input &quot;Professional
                Studies&quot; in the field.
              </p>
            </LearnMore>
          }
        />
      </div>
      <BooleanInput name="graduated" label="Did you graduate?" />
      <WizardNavigation {...navProps} onNext={updateEducationalInstitution} />
    </>
  );
}
