import { useQuery } from "@apollo/client";
import ProgressWidget from "components/ProgressWidget";
import { useEffect, useState } from "react";
import LOAD_REFERRED_USER_PROGRESS from "./LoadReferredUserProgress.gql";

export default function ReferredUserProgressIndicator({ userId }) {
  const [progress, setProgress] = useState();
  const { data, loading } = useQuery(LOAD_REFERRED_USER_PROGRESS, {
    variables: {
      id: userId,
      cachePolicy: "cache-and-network",
    },
  });
  useEffect(() => {
    if (data) {
      setProgress(data.referredUser.totalProgress);
    }
  }, [data]);

  return <ProgressWidget loading={loading} progress={progress} />;
}
