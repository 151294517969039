import { InMemoryCache, useMutation } from "@apollo/client";
import { DocumentNode } from "graphql";
import { get } from "lodash";
import { useCallback, useMemo } from "react";
import stripTypename from "utils/tests/apollo/stripTypename";

export type CacheUpdater = Pick<
  InMemoryCache,
  | "writeQuery"
  | "readQuery"
  | "writeFragment"
  | "readFragment"
  | "modify"
  | "identify"
>;

export function updateQuery<QuerySchema, VariablesSchema = any>(
  cache: CacheUpdater,
  query: DocumentNode,
  getNewData: (state: Partial<QuerySchema>) => Partial<QuerySchema>,
  variables?: VariablesSchema
) {
  let state;
  try {
    state = cache.readQuery<QuerySchema>({ query, variables });
  } catch {
    state = {};
  }
  cache.writeQuery<Partial<QuerySchema>>({
    query: query,
    variables: variables,
    data: {
      ...state,
      ...getNewData(state),
    },
  });
}

export function createCacheListUpdaters<ItemShape extends { id: string }>(
  listQuery: DocumentNode,
  stateKey: string
) {
  return [
    (cache: CacheUpdater, item: Partial<ItemShape>) =>
      updateQuery(cache, listQuery, (state) => ({
        ...state,
        [stateKey]: [
          ...get(state, stateKey, []).filter((p: any) => p.id !== item.id),
          item,
        ],
      })),
    (cache: CacheUpdater, item: Partial<ItemShape>) =>
      updateQuery(cache, listQuery, (state) => ({
        ...state,
        [stateKey]: get(state, stateKey, []).filter(
          (p: any) => p.id !== item.id
        ),
      })),
  ];
}

export function useUpsert<
  Schema,
  Variables,
  ListSchema,
  InputSchema extends { id?: string }
>(
  mutation: DocumentNode,
  listQuery: DocumentNode,
  getProperty: keyof Schema,
  listProperty: keyof ListSchema,
  customUpdate?: (cache: CacheUpdater, data: Schema) => any,
  listQueryVariables?: any
) {
  const [mutate, context] = useMutation<Schema, Variables>(mutation);
  const onUpsert = useCallback(
    (input: InputSchema, variables?: Omit<Variables, "input">) =>
      mutate({
        variables: { input: stripTypename(input), ...variables } as any,
        update(cache, { data }) {
          const { id } = input;
          if (id) {
            // This is an edit
            // Apollo will take care of it
          } else {
            // This is a create
            updateQuery<ListSchema>(
              cache,
              listQuery,
              (state) => ({
                ...state,
                [listProperty]: [
                  ...(get(state, listProperty, []) as any),
                  get(data, getProperty, []),
                ],
              }),
              listQueryVariables
            );
          }
          customUpdate?.(cache, data);
        },
      }),
    [
      listQueryVariables,
      customUpdate,
      getProperty,
      listProperty,
      listQuery,
      mutate,
    ]
  );

  return useMemo(() => [onUpsert, context], [context, onUpsert]) as [
    typeof onUpsert,
    typeof context
  ];
}

export function useUpsertModify<
  Schema,
  Variables,
  InputSchema extends { id?: string },
  ListQueryArgs extends Record<string, any> = Record<string, never>
>(
  mutation: DocumentNode,
  getProperty: keyof Schema,
  listProperty: keyof Query,
  customUpdate?: (cache: CacheUpdater, data: Schema) => any
) {
  const [mutate, context] = useMutation<Schema, Variables>(mutation);
  const onUpsert = useCallback(
    (
      input: InputSchema,
      /** Variables besides `input` to include in the mutation */
      variables?: Omit<Variables, "input">,
      /** An optional function to restrict queries created objects will be added to by their arguments */
      filterQueryArgs: (args: ListQueryArgs) => boolean = () => true
    ) =>
      mutate({
        variables: { input: stripTypename(input), ...variables } as any,
        update(cache, { data }) {
          const { id } = input;
          if (id) {
            // This is an edit
            // Apollo will take care of it
          } else {
            // This is a create
            cache.modify({
              fields: {
                [listProperty]: (existing, { storeFieldName, toReference }) => {
                  const args = JSON.parse(
                    storeFieldName.match(/\{.*\}/)?.[0] || "{}"
                  );
                  if (!filterQueryArgs(args)) return existing;

                  const newObject = get(data, getProperty);
                  return [...existing, toReference(newObject, true)];
                },
              },
            });
          }
          customUpdate?.(cache, data);
        },
      }),
    [customUpdate, getProperty, listProperty, mutate]
  );

  return useMemo(() => [onUpsert, context], [context, onUpsert]) as [
    typeof onUpsert,
    typeof context
  ];
}

export function useDeleteEvict<Schema>(
  mutation: DocumentNode,
  deleteProperty: keyof Schema,
  typename: string,
  customUpdate?: (cache: CacheUpdater, id: string, data: Schema) => any
) {
  const [mutate, context] = useMutation<Schema, { id: string }>(mutation);

  const onDelete = useCallback(
    (id: string) => {
      return mutate({
        variables: { id },
        update(cache, { data }) {
          cache.evict({
            id: cache.identify({
              __typename: typename,
              id: get(data, deleteProperty).id,
            }),
          });
          cache.gc();
          customUpdate?.(cache, id, data);
        },
      });
    },
    [customUpdate, deleteProperty, mutate]
  );

  return useMemo(() => [onDelete, context], [context, onDelete]) as [
    typeof onDelete,
    typeof context
  ];
}

export function useDelete<Schema, ListSchema>(
  mutation: DocumentNode,
  listQuery: DocumentNode,
  deleteProperty: keyof Schema,
  listProperty: keyof ListSchema,
  customUpdate?: (cache: CacheUpdater, id: string, data: Schema) => any,
  listQueryVariables?: any
) {
  const [mutate, context] = useMutation<Schema, { id: string }>(mutation);

  const onDelete = useCallback(
    (id: string) => {
      return mutate({
        variables: { id },
        update(cache, { data }) {
          updateQuery<ListSchema>(
            cache,
            listQuery,
            (state: any) => ({
              ...state,
              [listProperty]: get(state, listProperty, []).filter(
                (p: any) => p.id !== get(data, deleteProperty).id
              ),
            }),
            listQueryVariables
          );
          customUpdate?.(cache, id, data);
        },
      });
    },
    [
      listQueryVariables,
      customUpdate,
      deleteProperty,
      listProperty,
      listQuery,
      mutate,
    ]
  );

  return useMemo(() => [onDelete, context], [context, onDelete]) as [
    typeof onDelete,
    typeof context
  ];
}
