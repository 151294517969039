import {
  addDays,
  isAfter,
  isBefore,
  isValid,
  parseISO,
  startOfToday,
  subDays,
} from "date-fns";
import { addMethod, Ref, string, StringSchema } from "yup";

export const YearSchema = string().matches(
  /^(\d\d\d\d)?$/,
  "Please enter a valid 4-digit year."
);

export const DecimalSchema = string().matches(
  /^-?(\d{1,3})?(,?\d{3})*(\.\d+)?$/,
  "Please enter a valid number."
);

export const DateSchema = string().test(
  "isValidDate",
  "Please enter a valid date",
  (value) => !value || isValid(parseISO(value))
);

function dateCompareTest(
  value: ISODate,
  comparator: Date | ISODate,
  compare: (date: Date, comparator: Date) => boolean
) {
  const parsedDate = parseISO(value);
  if (!isValid(parsedDate)) return true;
  const dateComparator =
    comparator instanceof Date ? comparator : parseISO(comparator);
  if (!isValid(dateComparator)) return true;
  return compare(parsedDate, dateComparator);
}

addMethod<StringSchema>(
  string,
  "dateBefore",
  function (date: Date | ISODate | Ref, message: string) {
    return this.test("isDateBefore", message, function (value) {
      return dateCompareTest(value, this.resolve(date), isBefore);
    });
  }
);

addMethod<StringSchema>(
  string,
  "dateAfter",
  function (date: Date | ISODate | Ref, message: string) {
    return this.test("isDateAfter", message, function (value) {
      return dateCompareTest(value, this.resolve(date), isAfter);
    });
  }
);

addMethod<StringSchema>(
  string,
  "dateMin",
  function (date: Date | ISODate | Ref, message: string) {
    return this.test("dateMin", message, function (value) {
      return dateCompareTest(value, this.resolve(date), (d, c) =>
        isAfter(d, subDays(c, 1))
      );
    });
  }
);

addMethod<StringSchema>(
  string,
  "dateMax",
  function (date: Date | ISODate | Ref, message: string) {
    return this.test("dateMax", message, function (value) {
      return dateCompareTest(value, this.resolve(date), (d, c) =>
        isBefore(d, addDays(c, 1))
      );
    });
  }
);

addMethod<StringSchema>(string, "dateNotFuture", function (message: string) {
  return this.dateMax(startOfToday(), message);
});

function numberCompareTest(
  value: string,
  comparator: number | string,
  compare: (value: number, comparator: number) => boolean
) {
  const parsedNum = parseFloat(value);
  if (isNaN(parsedNum)) return true;
  const numberComparator =
    typeof comparator === "number" ? comparator : parseFloat(comparator);
  if (isNaN(numberComparator)) return true;
  return compare(parsedNum, numberComparator);
}

addMethod<StringSchema>(
  string,
  "numMax",
  function (max: number | string | Ref, message: string) {
    return this.test("numMax", message, function (value) {
      return numberCompareTest(value, this.resolve(max), (d, c) => d <= c);
    });
  }
);

addMethod<StringSchema>(
  string,
  "numMin",
  function (min: number | string | Ref, message: string) {
    return this.test("numMin", message, function (value) {
      return numberCompareTest(value, this.resolve(min), (d, c) => d >= c);
    });
  }
);
