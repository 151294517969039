import { useQuery } from "@apollo/client";
import { useGetSession } from "api/graphql/Session";
import cn from "classnames";
import { Loading } from "components/Loading";
import ProgressWidget from "components/ProgressWidget";
import { motion } from "framer-motion";
import { ModuleStatus } from "globalTypes";
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from "react";
import { FormModuleLink } from ".";
import GET_STATUS from "./GetStatus.gql";
import styles from "./styles/Dashboard.module.css";
import DashboardWalkthrough from "./Walkthrough";

export interface DashboardModule {
  title: string;
  statusNames?: (keyof Omit<GetStatus["progress"], "__typename">)[];
  href: string;
  icon?: FC<any>;
}

const DashboardCtx = createContext<{
  started: boolean;
  done: boolean;
}>(null);

export const useDashboard = () => useContext(DashboardCtx);

export function DefaultDashboardContents() {
  const { started, done } = useDashboard();
  const { data: sessionData } = useGetSession();

  const firstName = sessionData?.session?.user?.firstName;
  let copy: string;
  if (done) {
    copy = `
      You are now ready to submit! You may want to go back and review
      your information, as you won't be able to change it after submitting.
    `;
  } else if (started) {
    copy = `
      Welcome back${
        firstName ? `, ${firstName}` : ""
      }! We saved all your info and we’re
      ready to continue right where you left off.
    `;
  } else {
    copy = `
      To move forward we need you to complete all of the
      sections — but you can do them in any order.
    `;
  }

  return (
    <>
      <h1 id="dashboard-title">Tell us more about you</h1>
      <p style={{ marginBottom: "30px" }}>{copy}</p>
    </>
  );
}

export interface DashboardProps {
  onSubmit?: () => Promise<any>;
  modules: DashboardModule[];
  children?: ReactNode;
}

export type NamedModuleStatus = { [name: string]: ModuleStatus };

function getSectionStatus(
  data: GetStatus["progress"],
  section: DashboardModule
) {
  if (!section.statusNames || section.statusNames.length === 0)
    return undefined;

  let status: ModuleStatus;
  const statuses = section.statusNames.map((name) => data[name]);
  const completed = statuses.filter((s) => s.status === ModuleStatus.Complete);
  const notStarted = statuses.filter(
    (s) => s.status === ModuleStatus.NotStarted
  );

  if (notStarted.length === statuses.length) status = ModuleStatus.NotStarted;
  else if (completed.length === statuses.length) status = ModuleStatus.Complete;
  else status = ModuleStatus.InProgress;

  return { status, messages: [] as string[] };
}

export function Dashboard({ onSubmit, modules, children }: DashboardProps) {
  const [working, setWorking] = useState(false);
  const [error, setError] = useState("");
  const {
    data,
    loading,
    error: queryError,
  } = useQuery<GetStatus>(GET_STATUS, {
    fetchPolicy: "cache-and-network",
  });
  if (!data) return <Loading />;
  const { filingProfile, filingPerson } = data;
  const status = data.progress;
  const runWalkthrough =
    loading || queryError
      ? false
      : !filingProfile.hasCompletedDashboardWalkthrough;

  const done =
    modules
      .map((m) => getSectionStatus(status, m))
      .filter((s) => s?.status !== ModuleStatus.Complete).length === 0;

  const started =
    modules
      .map((m) => getSectionStatus(status, m))
      .filter((s) => s?.status !== ModuleStatus.NotStarted).length > 0;

  const submit = async () => {
    if (done) {
      setWorking(true);
      setError("");
      try {
        await onSubmit?.();
      } catch (e) {
        console.error(e);
        setError(`
          There was a problem submitting your application. Try again in a few
          minutes, and if this problem persists, please contact us.
        `);
      }
      setWorking(false);
    }
  };

  const modulesWithStatus = (desiredStatus: ModuleStatus) =>
    modules
      .filter((m) => getSectionStatus(status, m)?.status === desiredStatus)
      .map((m) => (
        <FormModuleLink
          key={m.title}
          title={m.title}
          status={getSectionStatus(status, m)}
          href={m.href}
          icon={m.icon}
          id={`dashboard-module-link-${m.title
            .toLowerCase()
            .replace(/\s+/g, "-")}`}
        />
      ));

  const finishedModules = modulesWithStatus("COMPLETE");

  return (
    <motion.div
      className={styles.root}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ type: "tween", delay: 0.3 }}
      layoutTransition
    >
      <DashboardWalkthrough shouldRun={runWalkthrough} />

      <main className={styles.main}>
        <DashboardCtx.Provider value={{ started, done }}>
          {children || <DefaultDashboardContents />}
        </DashboardCtx.Provider>

        <div id="dashboard-modules-list">
          {started && !done && (
            <div>
              <div className="text-sm">
                You are {data.progress.totalProgress}% done
              </div>
              <ProgressWidget
                progress={data.progress.totalProgress}
                colorize={false}
              />
            </div>
          )}

          <h5 className="mt-8">Up Next</h5>

          {modulesWithStatus("IN_PROGRESS")}
          {modulesWithStatus("NOT_STARTED")}

          {!!error && (
            <div className="errors" style={{ margin: "15px 0px" }}>
              {error}
            </div>
          )}
          <button
            disabled={!done}
            onClick={submit}
            className={cn("btn primary block", { loading: working })}
          >
            Send Intake for Attorney Review
          </button>

          {finishedModules.length > 0 && (
            <h5 style={{ marginTop: "40px" }}>Finished</h5>
          )}

          {finishedModules}
        </div>
      </main>
    </motion.div>
  );
}
