import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, { SummaryStatsRow } from "components/ModuleSummary";
import LinesSummary from "components/ModuleSummary/LinesSummary";
import React from "react";
import { formatGraphAddress } from "utils/formatters";
import MAILING_ADDRESS_MODULE from "./MailingAddressesModule.gql";

export default function MailingAddressSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<MailingAddressesModule>(MAILING_ADDRESS_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress?.mailingAddress}>
      <SummaryStatsRow>
        <LinesSummary
          requiredData={data?.filingPerson?.mailingAddress}
          maxLines={2}
          maxWidth="20em"
          style={{ textAlign: "center" }}
        >
          {formatGraphAddress(data?.filingPerson?.mailingAddress)}
        </LinesSummary>
      </SummaryStatsRow>
    </ModuleSummary>
  );
}
