import * as React from "react";
import { useLocation } from "react-router-dom";

type SearchParams = { [paramName: string]: string };

export function extractSearchQueryParams(search?: string) {
  // URLSearchParams *does* have an iterator that returns a two-item list of strings
  // but for some reason TypeScript doesn't realize this.
  const params = new URLSearchParams(search) as any;
  const result = {} as SearchParams;
  for (const [key, value] of params) {
    result[key] = value;
  }
  return result;
}

export function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => extractSearchQueryParams(search), [search]);
}
