import { useGetPreviousName } from "api/graphql/PreviousName";
import { Loading } from "components/Loading";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { PreviousNameWizard } from "./PreviousNameWizard";

export function EditView({
  id,
  listURL,
  ...props
}: {
  id: UUID;
  listURL: string;
}) {
  const { data, loading } = useGetPreviousName(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.previousName) {
    return <Redirect to={listURL} />;
  }

  return (
    <PreviousNameWizard
      title={"Updating Previous Name"}
      backURL={listURL}
      nextURL={listURL}
      initialValue={data.previousName}
    />
  );
}
