import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { SuccessIcon } from "./Icons";

export interface SuccessViewProps {
  nextURL: string;
}

export function SuccessView({ nextURL }: SuccessViewProps) {
  return (
    <div>
      <SuccessIcon />
      <p>
        We’ve created your public student loans in your account. Please take a
        minute to review your imported data for accuracy.
      </p>
      <WizardNavigation nextURL={nextURL} />
    </div>
  );
}
