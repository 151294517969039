import {
  Children,
  isValidElement,
  ReactElement,
  ReactNode,
  ReactText,
} from "react";

const hasChildren = (
  element: ReactNode
): element is ReactElement<{ children: ReactNode[] }> =>
  isValidElement<{ children?: ReactNode[] }>(element) &&
  Boolean(element.props.children);

const childToString = (child?: ReactText | boolean | {} | null): string => {
  if (
    typeof child === "undefined" ||
    child === null ||
    typeof child === "boolean"
  ) {
    return "";
  }

  if (JSON.stringify(child) === "{}") {
    return "";
  }

  return (child as string | number).toString();
};

/**
 * Recursively extracts the text of a React.ReactNode
 *
 * Based on https://github.com/fernandopasik/react-children-utilities/blob/master/src/lib/onlyText.ts
 * but extracted here since there's no published es5 module, and I didn't
 * want to deal with node_modules transpilation.
 */
export const onlyText = (children: ReactNode): string => {
  if (!(children instanceof Array) && !isValidElement(children)) {
    return childToString(children);
  }

  let text = "";
  for (const child of Children.toArray(children)) {
    if (isValidElement(child) && hasChildren(child)) {
      text += onlyText(child.props.children);
    } else if (isValidElement(child) && !hasChildren(child)) {
      continue;
    } else {
      text += childToString(child);
    }
  }
  return text;
};
