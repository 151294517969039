import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaWallet } from "react-icons/fa";
import GovernmentDisbursements from "./GovernmentDisbursements";
import GovernmentDisbursementsSummary from "./Summary";

const GovernmentDisbursementsSubmodule: SubModule = {
  path: "disbursements",
  title: "Disbursements",
  icon: FaWallet,
  component: GovernmentDisbursements,
  summary: GovernmentDisbursementsSummary,
  statusName: "governmentDisbursements",
};

export default GovernmentDisbursementsSubmodule;
