import { useMutation } from "@apollo/client";
import cn from "classnames";
import { ButtonRow } from "components/ButtonRow";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { formatName } from "utils/formatters";
import { useNested } from "utils/hooks";
import { roundToTwoDecimalPlaces } from "utils/math";
import { parseFancyNumber } from "utils/validators";
import SET_COOWNERSHIP_DETAILS from "./graphql/SetCoownershipDetails.gql";

export interface AddSpouseProps {
  displayName: string;
  relatedId: UUID;
  relatedTypename: string;
  currentValue: Decimal;
  spouse: any;
  addCoownerURL: string;
  listURL: string;
}

export function AddSpouse({
  displayName,
  relatedId,
  relatedTypename,
  currentValue,
  spouse,
  addCoownerURL,
  listURL,
}: AddSpouseProps) {
  const { url } = useNested();
  const history = useHistory();

  const [
    setCoownershipDetails,
    { loading: settingCoownershipDetails },
  ] = useMutation<SetCoownershipDetails, SetCoownershipDetailsVariables>(
    SET_COOWNERSHIP_DETAILS
  );

  const cv = parseFancyNumber(currentValue);
  const half = roundToTwoDecimalPlaces(cv / 2);

  return (
    <>
      <p>
        Is {formatName(spouse)} a co-owner of this {displayName}?
      </p>
      <ButtonRow
        left={
          <Link
            to={addCoownerURL}
            className={cn("btn", "block", "secondary", {
              disabled: settingCoownershipDetails,
            })}
          >
            No
          </Link>
        }
        right={
          <button
            type="button"
            className={cn("block", "btn", "primary", {
              loading: settingCoownershipDetails,
            })}
            onClick={async () => {
              await setCoownershipDetails({
                variables: {
                  relatedId,
                  relatedTypename,
                  currentValue,
                  ownedValue: `${half}`,
                  coowners: [
                    { person: spouse.id, details: { coOwnedValue: `${half}` } },
                  ],
                },
              });
              history.push(listURL);
            }}
          >
            Yes
          </button>
        }
      />
    </>
  );
}
