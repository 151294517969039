export const formatDisplayValue = (value: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};

export const unformatDisplayValue = (displayValue: string) =>
  parseFloat(displayValue.replace(/,/g, "").replace(/\$/g, ""));
