import { useGetAssetSale } from "api/graphql/AssetSale";
import { Loading } from "components/Loading";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { AssetSaleWizard } from "./AssetSaleWizard";

export function EditView({
  id,
  listURL,
  ...props
}: {
  id: UUID;
  listURL: string;
}) {
  const { data, loading } = useGetAssetSale(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.assetSale) {
    return <Redirect to={listURL} />;
  }

  return (
    <AssetSaleWizard
      title={"Updating Sold Asset"}
      backURL={listURL}
      nextURL={listURL}
      initialValue={data.assetSale}
    />
  );
}
