import OwnedBusinesses from "components/FormModules/OwnedBusinesses";
import SubMenuModule from "components/FormModuleSubMenu";
import React from "react";
import { FaDollarSign } from "react-icons/fa";
import EmploymentRecords from "./Employment";
import OtherIncomes from "./Other";
import IncomeSummary from "./Summary";
import GigIncome from "./Gig";
import HouseholdContributors from "./HouseholdContributors";
import GovernmentDisbursements from "./GovernmentDisbursements";

const incomeModules = [
  EmploymentRecords,
  OwnedBusinesses,
  GigIncome,
  HouseholdContributors,
  GovernmentDisbursements,
  OtherIncomes,
];
export const incomeStatusNames = incomeModules.map((m) => m.statusName);

export type IncomeProps = {
  next?: string;
  prev?: string;
  subMenuContainerID?: string;
};

export function Income({ prev, subMenuContainerID }: IncomeProps) {
  return (
    <SubMenuModule
      subMenuContainerID={subMenuContainerID}
      dashboardURL={prev}
      title="Your Income"
      Index={IncomeSummary}
      subModules={incomeModules}
    />
  );
}

Income.Icon = FaDollarSign;
