import { useQuery } from "@apollo/client";
import { useDeleteEnvironmentalCleanupNotice } from "api/graphql/EnvironmentalCleanupNotice";
import { ButtonRow } from "components/ButtonRow";
import { ListItem } from "components/ListItem";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema, formatGraphAddress } from "utils/formatters";
import { useNested } from "utils/hooks";
import ENVIRONMENTAL_CLEANUP_NOTICES_MODULE from "./EnvironmentalCleanupNoticesModule.gql";
import { EnvironmentalCleanupNoticeSchema } from "./EnvironmentalCleanupNoticeWizard";

interface Props {
  onEdit: (id: string) => any;
  createURL: string;
  nextURL: string;
}

export function ListView({ onEdit, createURL, nextURL }: Props) {
  const { data, loading } = useQuery<EnvironmentalCleanupNoticesModule>(
    ENVIRONMENTAL_CLEANUP_NOTICES_MODULE
  );
  const [onDelete] = useDeleteEnvironmentalCleanupNotice();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { environmentalCleanupNotices: items } = data;

  return (
    <>
      <Title>Your Environmental Cleanup Notices</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              nextURL={nextURL}
              createURL={createURL}
              filingProfileBoolean="hasNoEnvironmentalCleanupNotices"
              addText="Add a notice"
              question={
                <p className="required">
                  <strong>
                    Have you ever been notified by or sent notification to any
                    government agency (local, state, federal) that you might be
                    liable for environmental cleanup or that hazardous materials
                    were released at any site that you own or use?
                  </strong>
                </p>
              }
              confirmation={
                <p>
                  You indicated that you have never been notified by or sent
                  notification to any government agency that you might be liable
                  for environmental cleanup or that hazardous materials were
                  released at any site that you own or use. If that’s correct,
                  you’re ready to continue to the next section. Otherwise, you
                  may add notices below.
                </p>
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Tell us about all notices you have ever received from or sent to
                a local, state, or federal government agency about cleanup you
                might be liable for or hazardous materials that were released at
                any site you own or use.
              </p>
              <ButtonRow
                style={{ marginBottom: "8px" }}
                left={
                  <Link to={createURL} className="btn secondary">
                    <FaPlus />
                    <span>Add a notice</span>
                  </Link>
                }
                right={
                  <Link to={nextURL} className="btn primary">
                    <span>Done</span>
                    <FaCheck />
                  </Link>
                }
              />
              {items.map((notice) => {
                return (
                  <ListItem
                    key={notice.id}
                    onEdit={() => onEdit(notice.id)}
                    onDelete={() => onDelete(notice.id)}
                    objectName="environmentalCleanupNotice"
                  >
                    <div>
                      {formatErrorsFromSchema(
                        EnvironmentalCleanupNoticeSchema,
                        notice
                      )}
                      Notice {notice.wasReceived ? "from" : "to"}{" "}
                      {notice.governmentalUnitName} on {notice.noticeDate}{" "}
                      regarding {formatGraphAddress(notice.siteAddress, true)}
                    </div>
                  </ListItem>
                );
              })}
            </>
          )}
        />
      </Switch>
    </>
  );
}
