import { useGetSession } from "api/graphql/Session";
import { FaExclamationTriangle } from "react-icons/fa";

export default function LawFirmNotice() {
  const { data } = useGetSession();
  const lawFirmName = data?.session?.user?.referralFirm?.displayName;
  const lawFirmShortName = data?.session?.user?.referralFirm?.shortName;
  const documentsDueDate = data?.session?.user?.documentsDueDate
    ? new Date(data.session.user.documentsDueDate).toLocaleDateString("en-US", {
        dateStyle: "long",
      })
    : null;
  return (
    <>
      {lawFirmName && (
        <div className="p-4 bg-yellow-100">
          <div>
            Your law firm is{" "}
            <span className="text-xl font-semibold">{lawFirmName}</span>
          </div>
          <div>
            If this is not correct, please contact us at{" "}
            <a href="mailto:support@lexria.com">support@lexria.com</a>
          </div>
          {documentsDueDate && (
            <div>
              Note: Your documents due date is{" "}
              <span className="text-xl font-semibold">{documentsDueDate}</span>
            </div>
          )}
          {lawFirmShortName == "MillerMillerLaw" && (
            <div className="mt-8">
              <div>
                <FaExclamationTriangle className="text-red-500 size-12" />
              </div>
              <div>
                Effective December 31, 2024, Miller & Miller clients will no
                longer have access to the Lexria software.
                <br /> Starting January 1, 2025, Miller & Miller will offer a
                new client portal.
                <br /> Please note that data from Lexria will not be transferred
                to the new platform.
                <br /> We encourage clients to complete and submit necessary
                information in Lexria before December 31 in order to avoid
                starting over in the new client portal.
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
