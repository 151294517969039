import { FarmAssetCategory } from "globalTypes";
import React from "react";
import { GiFishingBoat } from "react-icons/gi";
import { CategoryDetail } from "../CategorySection/CategoryDetail";

const CommercialFishingEquipmentDetail: CategoryDetail = {
  category: FarmAssetCategory.CommercialFishingEquipment,
  displayName: "Commercial Fishing Equipment",
  displayLowercaseName: "commercial fishing equipment",
  hasNoProfileBooleanName: "hasNoCommercialFishingEquipment",
  singleInterrogativeText:
    "Tell us about a single piece of commercial fishing equipment, such as a machine, implement, or tool.",
  noAssetDeclarativeText:
    "You have indicated that you don’t own any commercial fishing equipment.",
  booleanInterrogativeText: (
    <p className="required">
      <strong>
        Do you own any commercial fishing equipment, such as machinery,
        implements, or tools?
      </strong>
    </p>
  ),
  declarativeText: (
    <p>
      Please itemize all high-value commercial fishing equipment, machinery, and
      tools you own.
    </p>
  ),
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add equipment below.",
  urlPathName: "fishing_equipment",
  menuIcon: GiFishingBoat,
};

export default CommercialFishingEquipmentDetail;
