import cn from "classnames";
import { FormGroup } from "components/FormGroup";
import * as React from "react";
import { useFormField } from "utils/hooks";
import s from "./InputRow.module.css";

interface InputRowProps {
  children: React.ReactElement[];
  label?: string;
  className?: string;
  helpText?: React.ReactNode;
  learnMore?: React.ReactNode;
  style?: React.CSSProperties;
}

export function InputRow({
  children,
  label = "",
  className = "",
  helpText,
  learnMore,
  style,
}: InputRowProps) {
  const myErrors = children.map((child) => {
    const { name, rules } = child.props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { error } = useFormField({ name, rules, register: false });
    return error;
  });

  return (
    <FormGroup
      className={className}
      label={label && <label className="label">{label}</label>}
      helpText={helpText}
      learnMore={learnMore}
      style={style}
      error={
        myErrors.filter((x) => !!x).length > 0 &&
        myErrors.map((message: string, index) => (
          <div className="errors" key={index}>
            {message}
          </div>
        ))
      }
    >
      <div className={s.inputRow}>
        {children.map((child, index) => (
          <InputRowInput key={child.props.name || index} child={child} />
        ))}
      </div>
    </FormGroup>
  );
}

function InputRowInput({ child }: { child: React.ReactElement }) {
  const { name, rules } = child.props;
  const { error, required } = useFormField({ name, rules, register: false });
  return React.createElement(child.type, {
    ...child.props,
    className: cn({
      "has-error": !!error,
      [s.required]: required,
    }),
  });
}
