import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import { omit } from "lodash";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { required } from "utils/defaultMessages";
import { DateSchema } from "utils/validators/yup";
import { object, string } from "yup";
import { RelatedPersonForm } from "./RelatedPersonForm";

export const AboutSchema = object({
  relationship: string().required(
    "Please enter your relationship with this person."
  ),
  relatedPersonId: string().when("relatedPerson", {
    is: (x) => !x,
    then: string().required("This step is required."),
    otherwise: string().optional().nullable(),
  }),
  relatedPerson: object({
    firstName: string().required("Please enter this person’s first name."),
    middleName: string().nullable(),
    lastName: string().required("Please enter this person’s last name."),
    suffix: string().nullable(),
    birthday: DateSchema.dateNotFuture(
      "You don’t need to include unborn family members!"
    ).required("Please enter this person’s birth date."),
  }).nullable(),
}).required();

export default function About({ isEdit, navProps }: WizardRouteChildProps) {
  const form = useFormContext();
  return (
    <>
      <RelatedPersonForm editing={isEdit} />
      <hr />
      <TextInput
        name="relationship"
        placeholder="e.g. son/daughter"
        label={`What is ${
          form.watch("relatedPerson.firstName") || "this person"
        }’s relationship to you?`}
        rules={{ required }}
      />
      <WizardNavigation
        {...omit(navProps, ["backIsSubmit", "onBack"])}
        backURL={navProps.abortURL}
      />
    </>
  );
}
