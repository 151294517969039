import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_FINANCIAL_INVESTMENTS from "./DeleteFinancialInvestment.gql";
import GET_FINANCIAL_INVESTMENTS from "./GetFinancialInvestment.gql";
import LIST_FINANCIAL_INVESTMENTS from "./ListFinancialInvestment.gql";
import UPSERT_FINANCIAL_INVESTMENTS from "./UpsertFinancialInvestment.gql";

const LIST_FINANCIAL_INVESTMENTS_IDS = gql`
  query ListFinancialInvestmentIds {
    financialInvestments {
      id
    }
  }
`;

export function useListFinancialInvestment(
  options?: QueryHookOptions<ListFinancialInvestment>
) {
  return useQuery<ListFinancialInvestment>(LIST_FINANCIAL_INVESTMENTS, options);
}

export function useGetFinancialInvestment(id: string) {
  return useQuery<GetFinancialInvestment, GetFinancialInvestmentVariables>(
    GET_FINANCIAL_INVESTMENTS,
    {
      variables: { id },
    }
  );
}

export function useUpsertFinancialInvestment<
  Schema extends { financialInvestment: any } = UpsertFinancialInvestment,
  Variables = UpsertFinancialInvestmentVariables
>(query = UPSERT_FINANCIAL_INVESTMENTS) {
  return useUpsert<
    Schema,
    Variables,
    ListFinancialInvestmentIds,
    FinancialInvestmentInput
  >(
    query,
    LIST_FINANCIAL_INVESTMENTS_IDS,
    "financialInvestment",
    "financialInvestments",
    (cache) =>
      updateFilingProfileCache(cache, "hasNoFinancialInvestments", false)
  );
}

export function useDeleteFinancialInvestment() {
  return useDelete<DeleteFinancialInvestment, ListFinancialInvestmentIds>(
    DELETE_FINANCIAL_INVESTMENTS,
    LIST_FINANCIAL_INVESTMENTS_IDS,
    "deleteFinancialInvestment",
    "financialInvestments"
  );
}
