import * as React from "react";
import { fuzzyGT, fuzzyLT, FUZZY_EPSILON } from "utils/math";

export function useAdaptiveSliderBounds({
  value,
  min,
  max,
  onChange,
  EPSILON = FUZZY_EPSILON,
}: {
  value: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
  EPSILON?: number;
}) {
  React.useEffect(() => {
    if (fuzzyLT(value, min, EPSILON)) onChange(min);
    if (fuzzyGT(value, max, EPSILON)) onChange(max);
  }, [value, min, max, onChange]);
}
