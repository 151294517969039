import { useQuery } from "@apollo/client";
import {
  useSetMailingAddress,
  useSetMailingAddressToCurrentResidence,
  useUpsertMailingAddress,
} from "api/graphql/MailingAddress";
import { Card } from "components/Card";
import { Form } from "components/Form";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { useHistory } from "react-router-dom";
import { unnestIds } from "utils/ids";
import MAILING_ADDRESSES_MODULE from "./MailingAddressesModule.gql";
import MailingAddressForm from "./MailingAddressForm";

export interface MailingAddressProps {
  next?: string;
  prev?: string;
}

export default function MailingAddress({ prev, next }: MailingAddressProps) {
  const history = useHistory();
  const { data } = useQuery<MailingAddressesModule>(MAILING_ADDRESSES_MODULE, {
    fetchPolicy: "cache-and-network",
  });

  const [upsertMailingAddress] = useUpsertMailingAddress();
  const [
    setMailingAddressToCurrentResidence,
  ] = useSetMailingAddressToCurrentResidence();
  const [setMailingAddress] = useSetMailingAddress();

  if (!data) return <Loading />;

  const { filingPerson, residentialAddresses } = data;
  const currentResidence = residentialAddresses.filter(
    (ra) => ra.residentTo === null
  )[0];
  const mailingAddress = filingPerson.mailingAddress;
  const mailingAndCurrentResidenceAddressesSame =
    mailingAddress && currentResidence
      ? mailingAddress?.id === currentResidence?.address?.id
      : false;

  const initialValue = unnestIds({
    mailingAndCurrentResidenceAddressesSame: mailingAddress
      ? mailingAndCurrentResidenceAddressesSame
      : undefined,
    mailingAddress: mailingAndCurrentResidenceAddressesSame
      ? null
      : mailingAddress,
  });

  return (
    <>
      <Title>Mailing address</Title>
      <Card>
        <Form
          initialValue={initialValue}
          onSubmit={async (values, e, translateErrors) => {
            try {
              if (values.mailingAndCurrentResidenceAddressesSame) {
                await setMailingAddressToCurrentResidence();
              } else if (values.mailingAddressId && !values.mailingAddress) {
                await setMailingAddress({
                  variables: { id: values.mailingAddressId },
                });
              } else {
                await upsertMailingAddress({
                  ...values.mailingAddress,
                  id: values.mailingAddressId,
                });
              }
              history.push(next);
            } catch (e) {
              translateErrors(e);
            }
          }}
        >
          <MailingAddressForm />
          <WizardNavigation isComplete backURL={prev} />
        </Form>
      </Card>
    </>
  );
}
