import cn from "classnames";
import * as React from "react";
import { RegisterOptions } from "react-hook-form";
import { useFormField } from "utils/hooks";
import css from "./RadioInput.module.css";

interface RadioInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  value: string;
  label?: React.ReactNode;
  learnMore?: React.ReactNode;
  rules?: RegisterOptions;
  className?: string;
  id?: string;
}

export function RadioInput({
  name,
  value,
  rules,
  label = "",
  className = "",
  id,
  learnMore,
  ...otherProps
}: RadioInputProps) {
  const { inputProps } = useFormField({
    name,
    rules,
  });

  return (
    <label
      id={id}
      className={cn("label", className, { required: rules?.required })}
    >
      <input
        type="radio"
        value={value}
        {...otherProps}
        {...inputProps}
        className={inputProps.className}
      />
      {label} {learnMore}
    </label>
  );
}

interface RadioGroupProps {
  children: React.ReactElement[];
  name?: string;
  label?: React.ReactNode;
  rules?: RegisterOptions;
  className?: string;
  onChange?: (newValue: string) => void;
  value?: string;
  helpText?: React.ReactNode;
  learnMore?: React.ReactNode;
}

/** Eases writing of radio input questions. Requires string values */
export function RadioGroup({
  label,
  helpText,
  rules,
  name,
  value,
  children,
  className,
  onChange,
  learnMore,
}: RadioGroupProps) {
  const { error, required } = useFormField({
    name,
    rules,
  });
  return (
    <fieldset className={cn(className, css.radioGroup)}>
      {label && (
        <legend className={cn({ required })} style={{ marginBottom: "5px" }}>
          {label} {learnMore}
        </legend>
      )}

      {children.map((child) =>
        React.cloneElement(child, {
          name,
          rules,
          checked:
            typeof value === "undefined" ? value : value === child.props.value,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              onChange?.(e.target.value);
            }
            child.props.onChange?.(e);
          },
          key: child.props.value,
          className: cn(child.props.className, css.radioGroupInput, {
            "has-error": typeof error !== "undefined",
          }),
        })
      )}

      {error && <div className={cn("errors", css.error)}>{error}</div>}
      {helpText && <div className={css.helpText}>{helpText}</div>}
    </fieldset>
  );
}
