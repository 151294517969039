import cn from "classnames";
import { last } from "lodash";
import prettyBytes from "pretty-bytes";
import * as React from "react";
import { DropEvent, DropzoneOptions, useDropzone } from "react-dropzone";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { FaBomb, FaUpload, FaDownload } from "react-icons/fa";
import { IconType } from "react-icons/lib/cjs";
import { useCheckMounted } from "utils/hooks";
import mimeTypes from "utils/mimeTypes";
import styles from "./FileInput.module.css";
import fileDownload from "js-file-download";

// This is the max file size we can send to the backend. It's 15MB.
// This is stupid.
const MAX_SIZE = 15 * 1024 * 1024;

export { styles };

export interface FileInputProps extends DropzoneOptions {
  className?: string;
  id?: string;
  helpText?: React.ReactNode;
  fileName?: string;
  downloadUrl?: string;
  Icon?: IconType;
}

/** A styled file input. Accepts [react-dropzone options](https://react-dropzone.js.org/#src) as props */
export function FileInput({
  className,
  id,
  helpText,
  fileName,
  downloadUrl,
  Icon,
  ...options
}: FileInputProps) {
  const [error, uncheckedSetError] = React.useState<string>();
  const { accept, maxSize = Infinity, minSize = 0, multiple = true } = options;
  const checkMounted = useCheckMounted();

  const setError = React.useCallback((message: string) => {
    // convenience for testing, mainly
    if (!checkMounted()) return;
    uncheckedSetError(message);
  }, []);

  const ERROR_RESET_TIMEOUT = 5000; // ms

  React.useEffect(() => {
    let timeout = null as any;
    if (error) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        setError("");
      }, ERROR_RESET_TIMEOUT);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [error]);

  const onDrop = (
    acceptedFiles: File[],
    rejectedFiles: File[],
    event: DropEvent
  ) => {
    // Because React Dropzone will only accept/reject files based on MIME type,
    // we need to do any non-MIME rejections here.
    if (options.onDrop) {
      try {
        options.onDrop(acceptedFiles, rejectedFiles, event);
      } catch (e) {
        setError(e.message || e);
      }
    }
  };

  const getDisplayedAccept = () => {
    const types = Array.isArray(accept) ? accept : accept.split(",");
    const extensions = types.map((t) =>
      mimeTypes[t] ? `.${mimeTypes[t]}` : t
    );

    let displayedAccept = extensions.slice(0, extensions.length - 1).join(", ");
    if (extensions.length > 1) {
      displayedAccept += " or ";
    }
    displayedAccept += last(extensions);
    return displayedAccept;
  };

  // TODO: support showing a list of errors for multiple files when multiple == true
  const onDropRejected = (files: File[], event: DropEvent) => {
    if (!multiple && files.length > 1) {
      setError("Please select only a single file.");
      return;
    }

    files.forEach((file) => {
      if (accept && !accept.includes(file.type)) {
        setError(
          `Incorrect filetype. Please provide only ${getDisplayedAccept()} files.`
        );
      }

      if (file.size > maxSize || file.size > MAX_SIZE) {
        setError(
          `Woah, that's a big file! Uploaded files must be no larger than ${prettyBytes(
            Math.min(MAX_SIZE, maxSize)
          )}.`
        );
      }

      if (file.size < minSize) {
        setError(
          `Uploaded files must be at least ${prettyBytes(minSize)} in size.`
        );
      }
    });
    if (options.onDropRejected) {
      options.onDropRejected(files, event);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: MAX_SIZE, // set on the backend
    ...options,
    accept,
    minSize,
    multiple,
    onDrop,
    onDropRejected,
  });

  const DisplayedIcon = Icon || (error ? FaBomb : FaUpload);

  if (typeof helpText === "undefined") {
    helpText = (
      <div>
        Drag {!!accept && getDisplayedAccept() + " "}files here, or click to
        select
      </div>
    );
  }

  return (
    <div
      {...getRootProps({
        id,
        className: cn(className, styles.root, {
          [styles.error]: error,
          [styles.dragActive]: isDragActive,
        }),
      })}
    >
      <div className={styles.actions}>
        {!!fileName && !!downloadUrl && (
          <button
            type="button"
            className="btn icon primary"
            onClick={async (e) => {
              e.stopPropagation();
              const resp = await fetch(downloadUrl);
              const blob = await resp.blob();
              fileDownload(blob, fileName);
            }}
          >
            <FaDownload size="14px" />
          </button>
        )}
      </div>
      <DisplayedIcon className={styles.Icon} />
      <div className={styles.IconLabel}>{error || helpText}</div>
      <input {...getInputProps()} />
    </div>
  );
}

export function ControlledFileInput(
  props: FileInputProps & { name: string; required?: string }
) {
  const form = useFormContext();
  const error = form.errors[props.name];
  let errorDisplay = "";
  if (error) {
    if (Array.isArray(error)) {
      const errorsArray = error as (FieldError | Record<string, any>)[];
      errorDisplay = errorsArray.map((err) => `${err.message}`).join(". ");
    } else {
      errorDisplay = `${error.message}`;
    }
  }

  const normalizeFiles = (files: File[]) => {
    switch (files.length) {
      case 0:
        return null;
      case 1:
        return files[0];
      default:
        return files;
    }
  };

  return (
    <>
      <Controller
        name={props.name}
        rules={{
          required: props.required,
        }}
        render={({ onChange }) => (
          <FileInput
            {...props}
            className={cn({ "has-error": !!error })}
            onDropAccepted={(files) => onChange(normalizeFiles(files))}
          />
        )}
      />
      {error && (
        <div className="errors">
          {errorDisplay.length ? errorDisplay : "An unexpected error occurred."}
        </div>
      )}
    </>
  );
}
