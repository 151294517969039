import { CategoryDetail } from "components/FormModules/Assets/HouseholdItems/CategorySection/CategoryDetail";
import { HouseholdItemCategoryChoice } from "globalTypes";
import * as React from "react";
import { FaMugHot } from "react-icons/fa";

const Examples = () => (
  <div>
    <p>Examples include:</p>
    <ul>
      <li>Major appliances</li>
      <li>Furniture</li>
      <li>Linens</li>
      <li>China</li>
      <li>Kitchenware</li>
    </ul>
  </div>
);

const FurnishingsDetail: CategoryDetail = {
  category: HouseholdItemCategoryChoice.GoodsAndFurnishings,
  displayName: "Household Goods and Furnishings",
  displayLowercaseName: "household goods and furnishings",
  hasNoProfileBooleanName: "hasNoHouseholdGoodsAndFurnishingItems",
  singleInterrogativeText: "Tell us about this household good or furnishing.",
  noAssetDeclarativeText:
    "You have indicated that neither you nor any member of your household owns any household goods or furnishings.",
  booleanInterrogativeText: (
    <>
      <p className="required">
        <strong>
          Do you or any members of your household own any household goods or
          furnishings?
        </strong>
      </p>
      <Examples />
    </>
  ),
  declarativeText: (
    <>
      <p className="required">
        <strong>
          Please itemize all household goods and furnishings you and your family
          members own.
        </strong>
      </p>
      <Examples />
    </>
  ),
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add items below.",
  urlPathName: "goods_and_furnishings",
  displayMenuName: "Goods & furnishings",
  menuIcon: FaMugHot,
};

export default FurnishingsDetail;
