import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_BORROWED_ASSET from "./DeleteBorrowedAsset.gql";
import GET_BORROWED_ASSET from "./GetBorrowedAsset.gql";
import LIST_BORROWED_ASSETS from "./ListBorrowedAssets.gql";
import UPSERT_BORROWED_ASSETS from "./UpsertBorrowedAsset.gql";

const LIST_BORROWED_ASSET_IDS = gql`
  query ListBorrowedAssetIds {
    borrowedAssets {
      id
    }
  }
`;

export function useListBorrowedAssets(
  options?: QueryHookOptions<ListBorrowedAssets>
) {
  return useQuery<ListBorrowedAssets>(LIST_BORROWED_ASSETS, options);
}

export function useGetBorrowedAsset(id: string) {
  return useQuery<GetBorrowedAsset, GetBorrowedAssetVariables>(
    GET_BORROWED_ASSET,
    {
      variables: { id },
    }
  );
}

export function useUpsertBorrowedAsset<
  Schema extends { borrowedAsset: any } = UpsertBorrowedAsset,
  Variables = UpsertBorrowedAssetVariables
>(query = UPSERT_BORROWED_ASSETS) {
  return useUpsert<Schema, Variables, ListBorrowedAssetIds, BorrowedAssetInput>(
    query,
    LIST_BORROWED_ASSET_IDS,
    "borrowedAsset",
    "borrowedAssets",
    (cache) => updateFilingProfileCache(cache, "hasNoBorrowedAssets", false)
  );
}

export function useDeleteBorrowedAsset() {
  return useDelete<DeleteBorrowedAsset, ListBorrowedAssetIds>(
    DELETE_BORROWED_ASSET,
    LIST_BORROWED_ASSET_IDS,
    "deleteBorrowedAsset",
    "borrowedAssets"
  );
}
