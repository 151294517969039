import { LearnMore } from "components/LearnMore";
import { MonthlyExpenseCategoryChoice } from "globalTypes";
import React from "react";
import { FaCar } from "react-icons/fa";
import { ExpensesCategoryDetail } from "../CategorySection/CategoryDetail";
import { LearnMoreCommon } from "../LearnMoreCommon";

export const transportationCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.TransportationGeneral,
  displayName: "Transportation",
  displayLowercaseName: "transportation",
  hasNoProfileBooleanName: "hasNoTransportationGeneralExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on a single type of transportation expense (e.g. gas, bus fare, maintenance)  over the last six months?",
  urlPathName: "transportation",
  menuIcon: FaCar,
  description: (
    <>
      <p>
        You don&rsquo;t need to include payments for vehicle leases or loans, or
        auto insurance expenses. We&rsquo;ll ask for that info elsewhere.
      </p>

      <p>Transportation expenses include but are not limited to:</p>
      <ul>
        <li>Public transportation fees such as bus or train fares</li>
        <li>Operating costs for your vehicles such as:</li>
        <ul>
          <li>Fuel</li>
          <li>Repairs and maintenance</li>
          <li>License fees including inspection costs</li>
          <li>Parking fees</li>
          <li>Tolls</li>
        </ul>
      </ul>

      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};
