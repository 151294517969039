import { DecimalInput } from "components/NumberInput";
import { TextArea } from "components/TextArea";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { DecimalSchema } from "utils/validators/yup";
import { object, string } from "yup";

export const ItemsSchema = object({
  items: string().required("Please describe these items"),
  totalValue: DecimalSchema.numMin(0, "This must be greater than 0.").required(
    "Please enter the total value of the borrowed property"
  ),
}).required();

export default function Items({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <DecimalInput
        name="totalValue"
        label="How much are these items worth?"
        addOnBefore="$"
        includeThousandsSeparator
        allowDecimal
        autoFocus={!isEdit}
      />
      <TextArea
        name="items"
        label="Describe the items in your possession"
        maxLength={1024}
      />

      <WizardNavigation {...navProps} />
    </>
  );
}
