import * as React from "react";
import { ServicePrepaymentWizard } from "./ServicePrepaymentWizard";

export function CreateView({
  listURL,
  editURL,
}: {
  listURL: string;
  editURL?: string;
}) {
  return (
    <ServicePrepaymentWizard
      title="Add Prepaid Card, Security Deposit, or Pre-payment"
      backURL={listURL}
      nextURL={listURL}
      editURL={editURL}
    />
  );
}
