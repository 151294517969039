import cn from "classnames";
import { Spinner } from "components/Spinner";

export default function ProgressWidget({ loading, progress, colorize = true }) {
  if (loading) return <Spinner />;

  return (
    <div
      className="flex items-center w-full h-full"
      title={`${progress}% complete`}
    >
      <div className="w-full h-2 overflow-hidden bg-gray-300 rounded">
        <div
          style={{ width: `${progress}%` }}
          className={cn(
            "h-full rounded",
            colorize
              ? progress < 30
                ? "bg-red-600"
                : progress < 70
                ? "bg-amber-500"
                : "bg-green-600"
              : "bg-green-600"
          )}
        ></div>
      </div>
    </div>
  );
}
