import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaDollarSign } from "react-icons/fa";
import { OtherIncomes } from "./OtherIncome";
import OtherIncomeSummary from "./Summary";

const OtherIncomeSubmodule: SubModule = {
  path: "other",
  icon: FaDollarSign,
  title: "Other",
  component: OtherIncomes,
  summary: OtherIncomeSummary,
  statusName: "otherIncome",
};

export default OtherIncomeSubmodule;
