import DollarsInput from "components/DollarsInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";

export default function Expenditures({ navProps }: WizardRouteChildProps) {
  return (
    <>
      <p>In the average month, how much does this company spend on:</p>

      <div className="form-row">
        <DollarsInput
          name="monthlyInventoryPurchasesCost"
          label="Inventory & raw materials"
          autoFocus
        />
        <DollarsInput
          name="monthlyTravelEntertainment"
          label="Travel & Entertainment"
        />
      </div>

      <div className="form-row">
        <DollarsInput
          name="monthlyVehicleExpenses"
          label="Vehicle expenses"
          helpText="e.g. leases, rentals, and fleet costs"
        />
        <DollarsInput
          name="monthlyFarmSuppliesCost"
          label="Farming supplies"
          helpText="e.g. feed, fertilizer, seeds, and spray"
        />
      </div>

      <DollarsInput
        name="monthlyEquipmentRental"
        label="Equipment rental & leases"
      />

      <WizardNavigation {...navProps} />
    </>
  );
}
