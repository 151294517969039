import { DateInput } from "components/DateInput";
import { LearnMore } from "components/LearnMore";
import { Select } from "components/Select";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { states } from "utils/constants";
import { DateSchema, DecimalSchema } from "utils/validators/yup";
import { object, string } from "yup";

export const ItemSchema = object({
  itemDescription: string().required(
    "Please describe the item or asset that was seized."
  ),
  itemTakenDate: DateSchema.nullable(),
  itemValue: DecimalSchema.required("This field is required.").test(
    "min",
    "Must be greater than zero.",
    (value: string) => value && Number(value.replace(/,/g, "")) > 0
  ),
}).required();

export const CaseDetailsSchema = object({
  description: string().required("Please describe this lawsuit."),
  plaintiffName: string().required("Please enter the plaintiff’s name."),
  defendantName: string().required("Please enter the defendant’s name."),
  caseNumber: string().nullable(),
  caseDate: DateSchema.nullable(),
  filingState: object({
    abbr: string().required("Please select a state"),
  }).nullable(),
});

export function CaseDetails({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <TextArea name="description" label="Description" />
      <div className="form-row">
        <TextInput
          name="plaintiffName"
          label="Plaintiff’s name"
          learnMore={
            <LearnMore>
              <p>
                This is the name of the person or entity filing the lawsuit. If
                you are unsure about who the plaintiff is, enter
                &ldquo;unknown&rdquo;
              </p>
            </LearnMore>
          }
        />
        <TextInput
          name="defendantName"
          label="Defendant’s name"
          learnMore={
            <LearnMore>
              <p>
                This is the name of the person or entity who was sued. If you
                are unsure about who the defendant is, enter
                &ldquo;unknown&rdquo;
              </p>
            </LearnMore>
          }
        />
        <TextInput name="caseNumber" label="Case number" />
        <DateInput name="caseDate" label="Case date" />
        <Select
          name="filingState.abbr"
          label="State the lawsuit was filed in"
          options={states.map(({ name, abbr }) => ({
            value: abbr,
            label: name,
          }))}
        />
      </div>
      <WizardNavigation {...navProps} />
    </>
  );
}
