import { LearnMore } from "components/LearnMore";
import { MonthlyExpenseCategoryChoice } from "globalTypes";
import React from "react";
import { FaDonate } from "react-icons/fa";
import { ExpensesCategoryDetail } from "../CategorySection/CategoryDetail";
import { LearnMoreCommon } from "../LearnMoreCommon";

export const charityCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.Charity,
  displayName: "Charity",
  displayLowercaseName: "charity",
  hasNoProfileBooleanName: "hasNoCharityExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you gave to charity over the last six months? If you made a donation over $600 in the last 2 years, record it here as well. Be sure to include giving for all household members.",
  urlPathName: "charity",
  menuIcon: FaDonate,
  description: (
    <>
      <p>Charitable expenses include but are not limited to donations for:</p>
      <ul>
        <li>Non-profit organizations</li>
        <li>Religious institutions</li>
        <li>Any donations over $600 in the last two years</li>
      </ul>

      <p>
        <LearnMore>
          <h4>Charitable expenses are allowed in bankruptcy?</h4>
          <p>
            Yes, but there are strict rules guiding what is permissible or not.
            Your lawyer will review all expenses and provide feedback to you if
            an expense is not permissible.
          </p>

          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};
