import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import { OtherIncomeFrequencyChoice } from "globalTypes";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import { parseFancyNumber } from "utils/validators";
import OTHER_INCOME_MODULE from "./OtherIncomesModule.gql";

export default function OtherIncomeSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<OtherIncomesModule>(OTHER_INCOME_MODULE, {
    returnPartialData: true,
  });

  const monthlyIncome = data?.otherIncomes?.reduce((total, i) => {
    const multipliers = {
      [OtherIncomeFrequencyChoice.OneTime]: 0,
      [OtherIncomeFrequencyChoice.Weekly]: 1,
      [OtherIncomeFrequencyChoice.Biweekly]: 1,
      [OtherIncomeFrequencyChoice.Monthly]: 1,
      [OtherIncomeFrequencyChoice.BiAnnually]: 1,
      [OtherIncomeFrequencyChoice.Annually]: 1,
    };

    return parseFancyNumber(i.amount) * multipliers[i.frequency] + total;
  }, 0);

  return (
    <ModuleSummary {...props} progress={data?.progress.otherIncome}>
      {data?.filingProfile?.hasNoOtherIncome ? (
        <HasNoSummary>
          Your household hasn&rsquo;t received any income from other sources in
          the last 6 months.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="income source"
            maxValueLength={1}
            value={data?.otherIncomes?.length}
            pluralizeLabel
          />

          <NumericSummary
            label="in the last six months"
            maxValueLength={4}
            valuePrefix="$"
            value={monthlyIncome}
          />

          <NumericSummary
            label="one-time payments"
            maxValueLength={4}
            valuePrefix="$"
            value={fancyNumberSum(
              data?.otherIncomes?.filter((i) => i.frequency === "ONE_TIME"),
              "amount"
            )}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
