import { AddressForm, AddressSchema } from "components/FormSections/Address";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { object } from "yup";

export const PropertySchema = object({
  address: AddressSchema.required(),
}).required();

export default function Property({ navProps }: WizardRouteChildProps) {
  return (
    <>
      <p>Where is this property located?</p>
      <AddressForm name="address" graphQL />

      <WizardNavigation {...navProps} />
    </>
  );
}
