import { InsuranceCategoryDetail } from "../HouseholdExpenses/CategorySection/CategoryDetail";
import React from "react";
import {
  FaAddressCard,
  FaCalendarAlt,
  FaCarCrash,
  FaCrutch,
  FaGlasses,
  FaHouseDamage,
  FaNotesMedical,
  FaTooth,
  FaWheelchair,
} from "react-icons/fa";

export const dentalCategoryDetail: InsuranceCategoryDetail = {
  category: "DENTAL",
  displayName: "Dental Insurance",
  displayLowercaseName: "dental insurance",
  hasNoProfileBooleanName: "hasNoHouseholdInsuranceDentalExpenses",
  singleInterrogativeText:
    "In the last six months, how much did you spend each month on a single dental insurance provider? Be sure to include spending for all household members.",
  urlPathName: "dental_insurance",
  menuIcon: FaTooth,
  description: (
    <p>
      Dental insurance policies cover a variety of dental work and expenses.
    </p>
  ),
};

export const healthCategoryDetail: InsuranceCategoryDetail = {
  category: "HEALTH",
  displayName: "Health Insurance",
  displayLowercaseName: "health insurance",
  hasNoProfileBooleanName: "hasNoHouseholdInsuranceHealthcareExpenses",
  singleInterrogativeText:
    "In the last six months, how much did you spend each month on a single health insurance provider? Be sure to include spending for all household members.",
  urlPathName: "health_insurance",
  menuIcon: FaNotesMedical,
  description: (
    <p>
      Health insurance policies cover medical expenses such as doctor visits,
      medications, and medical procedures. Don&rsquo;t include dental or vision
      insurance, which are covered in other sections.
    </p>
  ),
};

export const longTermDisabilityCategoryDetail: InsuranceCategoryDetail = {
  category: "DISABILITY_LONG",
  displayName: "Long Term Disability Insurance",
  displayLowercaseName: "long term disability insurance",
  hasNoProfileBooleanName: "hasNoHouseholdInsuranceLongTermDisabilityExpenses",
  singleInterrogativeText:
    "In the last six months, how much did you spend each month on a single long term disability insurance provider? Be sure to include spending for all household members.",
  urlPathName: "long_term_disability",
  menuIcon: FaWheelchair,
  description: (
    <p>
      Long term disability insurance is an insurance product that replaces your
      income for an extended period of time in the event that you experience a
      medical condition that prevents you from working. The benefit period
      usually lasts for years.
    </p>
  ),
};

export const realEstateCategoryDetail: InsuranceCategoryDetail = {
  category: "REAL_ESTATE",
  displayName: "Real Estate Insurance",
  displayLowercaseName: "real estate insurance",
  hasNoProfileBooleanName: "hasNoRealEstateInsuranceExpenses",
  singleInterrogativeText:
    "In the last six months, how much did you spend each month on a single real estate insurance provider? Be sure to include spending for all household members.",
  urlPathName: "real_estate_insurance",
  menuIcon: FaHouseDamage,
  description: (
    <p>
      Real estate insurance policies protect you in the event of damage to real
      estate you own or occupy. These include homeowner&rsquo;s and
      renter&rsquo;s insurance.
    </p>
  ),
};

export const shortTermDisabilityCategoryDetail: InsuranceCategoryDetail = {
  category: "DISABILITY_SHORT",
  displayName: "Short Term Disability Insurance",
  displayLowercaseName: "short term disability insurance",
  hasNoProfileBooleanName: "hasNoHouseholdInsuranceShortTermDisabilityExpenses",
  singleInterrogativeText:
    "In the last six months, how much did you spend each month on a single short term disability insurance provider? Be sure to include spending for all household members.",
  urlPathName: "short_term_disability_insurance",
  menuIcon: FaCrutch,
  description: (
    <p>
      Short term disability insurance is an insurance product that replaces your
      income for a short period of time in the event that you experience a
      medical condition that prevents you from working. The benefit period
      usually lasts 3 to 6 months.
    </p>
  ),
};

export const termLifeCategoryDetail: InsuranceCategoryDetail = {
  category: "TERM_LIFE",
  displayName: "Term Life Insurance",
  displayLowercaseName: "term life insurance",
  hasNoProfileBooleanName: "hasNoHouseholdInsuranceTermExpenses",
  singleInterrogativeText:
    "In the last six months, how much did you spend each month on a single term life insurance provider? Be sure to include spending for all household members.",
  urlPathName: "term_life_insurance",
  menuIcon: FaCalendarAlt,
  description: (
    <p>
      Term life insurance is a contract between you and an insurance company
      that lasts for a specific period of time, such as 10 years, 20 years or
      until you reach age 65. In exchange for your premium payments, the insurer
      pays a death benefit to your beneficiaries only if the contracted person
      dies during the term of the contract.
    </p>
  ),
};

export const wholeLifeCategoryDetail: InsuranceCategoryDetail = {
  category: "WHOLE_LIFE",
  displayName: "Whole Life Insurance",
  displayLowercaseName: "whole life insurance",
  hasNoProfileBooleanName: "hasNoHouseholdInsuranceWholeExpenses",
  singleInterrogativeText:
    "In the last six months, how much did you spend each month on a single whole life insurance provider? Be sure to include spending for all household members.",
  urlPathName: "whole_life_insurance",
  menuIcon: FaAddressCard,
  description: (
    <p>
      Whole life insurance is a contract between you and an insurance company
      that lasts for your whole life. So long as the contract terms are met, the
      insurer will pay the death benefit of the policy when the contracted
      person dies.
    </p>
  ),
};

export const autoCategoryDetail: InsuranceCategoryDetail = {
  category: "AUTO",
  displayName: "Vehicle Insurance",
  displayLowercaseName: "vehicle insurance",
  hasNoProfileBooleanName: "hasNoTransportationInsuranceExpenses",
  singleInterrogativeText:
    "In the last six months, how much did you spend each month for a single vehicle insurance provider? Be sure to include spending for all household members.",
  urlPathName: "transportation_insurance",
  menuIcon: FaCarCrash,
  description: (
    <p>
      Vehicle insurance policies cover loss or damage of vehicles you use. This
      includes auto insurance and any other policies which cover damages to
      vehicles.
    </p>
  ),
};

export const visionCategoryDetail: InsuranceCategoryDetail = {
  category: "VISION",
  displayName: "Vision Insurance",
  displayLowercaseName: "vision insurance",
  hasNoProfileBooleanName: "hasNoHouseholdInsuranceVisionExpenses",
  singleInterrogativeText:
    "In the last six months, how much did you spend each month on a single vision insurance provider? Be sure to include spending for all household members.",
  urlPathName: "vision_insurance",
  menuIcon: FaGlasses,
  description: (
    <p>
      Vision insurance policies can cover ophthalmologist visits, prescription
      lenses, and other vision care expenses.
    </p>
  ),
};
