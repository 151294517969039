import { useGetBankruptcyCase } from "api/graphql/BankruptcyCase";
import { Loading } from "components/Loading";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { BankruptcyCaseWizard } from "./BankruptcyCaseWizard";

export function EditView({
  id,
  listURL,
  ...props
}: {
  id: UUID;
  listURL: string;
}) {
  const { data, loading } = useGetBankruptcyCase(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.bankruptcyCase) {
    return <Redirect to={listURL} />;
  }

  return (
    <BankruptcyCaseWizard
      title={`${
        data.bankruptcyCase.humanReviewed ? "Updating" : "Reviewing"
      } case`}
      backURL={listURL}
      nextURL={listURL}
      initialValue={data.bankruptcyCase}
      isEdit
    />
  );
}
