import { EmploymentSchema } from "components/FormModules/Income/Employment/EmploymentWizard";
import { parseFancyNumber } from "utils/validators";
import { DecimalSchema } from "utils/validators/yup";
import { array, boolean, object, string } from "yup";

const dollarAmountSchema = DecimalSchema.required(
  "This field is required."
).numMin(0, "This can’t be negative.");

export const BusinessSummarySchema = object({
  businessName: string().required("Please enter the name of this business."),
  lastTwelveMonthsGrossIncome: dollarAmountSchema,
  expectedGrossMonthlyIncome: dollarAmountSchema,
  percentOwnership: DecimalSchema.required("This field is required")
    .numMin(0, "Must be between 0 and 100")
    .numMax(100, "Must be between 0 and 100"),
}).required();

export const BusinessTaxesSchema = object({
  monthlyPayrollTaxes: dollarAmountSchema,
  monthlyUnemploymentTaxes: dollarAmountSchema,
  monthlyOtherTaxes: dollarAmountSchema,
}).required();

export const BusinessEmployeesSchema = object({
  monthlyEmployeePayroll: dollarAmountSchema,
  monthlyProfessionalFees: dollarAmountSchema,
  monthlyEmployeeBenefits: dollarAmountSchema,
  monthlyWorkersCompensation: dollarAmountSchema,
});

export const BusinessFacilitiesSchema = object({
  monthlyRent: dollarAmountSchema,
  monthlyUtilities: dollarAmountSchema,
  monthlyOfficeExpenses: dollarAmountSchema,
  monthlyRepairsMaintenance: dollarAmountSchema,
});

export const BusinessExpendituresSchema = object({
  monthlyInventoryPurchasesCost: dollarAmountSchema,
  monthlyFarmSuppliesCost: dollarAmountSchema,
  monthlyVehicleExpenses: dollarAmountSchema,
  monthlyTravelEntertainment: dollarAmountSchema,
  monthlyEquipmentRental: dollarAmountSchema,
});

export const BusinessMiscExpensesSchema = object({
  monthlyInsurance: dollarAmountSchema,
  monthlyDebtPayments: dollarAmountSchema,
  businessDebtsDescription: string().when("monthlyDebtPayments", {
    is: (p) => p && parseFancyNumber(p) > 0,
    then: string().required(
      "Please describe the secured debts held by this company."
    ),
    otherwise: string().optional().nullable(),
  }),
  monthlyOtherExpenses: dollarAmountSchema,
  otherExpensesDescription: string().when("monthlyOtherExpenses", {
    is: (p) => p && parseFancyNumber(p) > 0,
    then: string().required(
      "Please specify the nature of these other expenses."
    ),
    otherwise: string().optional().nullable(),
  }),
});

export const OwnedBusinessExpensesSchema = BusinessTaxesSchema.concat(
  BusinessEmployeesSchema
)
  .concat(BusinessFacilitiesSchema)
  .concat(BusinessExpendituresSchema)
  .concat(BusinessMiscExpensesSchema);

export const PersonalSalariesSchema = object({
  hasNoPersonalSalaries: boolean().required(),
  personalSalaries: array(EmploymentSchema).when("hasNoPersonalSalaries", {
    is: true,
    then: array(EmploymentSchema).min(0),
    otherwise: array(EmploymentSchema).min(1),
  }),
});

export const OwnedBusinessSchema = BusinessSummarySchema.concat(
  OwnedBusinessExpensesSchema
).concat(PersonalSalariesSchema);
