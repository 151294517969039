import * as React from "react";
import { RealEstateWizard } from "./RealEstateWizard";

export function CreateView({
  listURL,
  editURL,
}: {
  listURL: string;
  editURL?: string;
}) {
  return (
    <RealEstateWizard
      title="Add Real Estate Property"
      backURL={listURL}
      nextURL={listURL}
      editURL={editURL}
    />
  );
}
