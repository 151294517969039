import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaHandshake } from "react-icons/fa";
import BorrowedAssets from "./BorrowedAssets";
import BorrowedAssetsSummary from "./Summary";

const BorrowedAssetsSubmodule: SubModule = {
  path: "held_assets",
  icon: FaHandshake,
  title: "Held Assets",
  component: BorrowedAssets,
  summary: BorrowedAssetsSummary,
  statusName: "borrowedAssets",
};

export default BorrowedAssetsSubmodule;
