import { isNull } from "lodash";
import React, { ReactNode } from "react";
import ContentLoader from "react-content-loader";
import { formatPlural } from "utils/formatters";
import { parseFancyNumber } from "utils/validators";

interface Props {
  valuePrefix?: string;
  value?: number | string | ReactNode | null;
  maxValueLength: number;
  label: string;
  supportingText?: string;
  pluralizeLabel?: boolean;
}

export default function NumericSummary({
  value,
  valuePrefix,
  label,
  maxValueLength,
  pluralizeLabel = false,
  supportingText,
}: Props) {
  const labelWidthEm = label.length * 0.8;
  const supportingWidthEm = supportingText ? supportingText.length * 0.6 : 0;
  const valueWidthEm = (maxValueLength + (valuePrefix || "").length) * 1.5;
  const totalWidthEm = Math.max(labelWidthEm, valueWidthEm, supportingWidthEm);
  const valueWidthPercent = valueWidthEm / totalWidthEm;
  const labelWidthPercent = (labelWidthEm / totalWidthEm) * 100;
  const supportingWidthPercent = (supportingWidthEm / totalWidthEm) * 100;

  if (isNull(value)) return null;

  const loading = typeof value === "undefined";
  const labelStyle = {
    visibility: loading ? "hidden" : "visible",
    textAlign: "center",
  } as const;

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <span
          style={{
            fontSize: "var(--font-size-L)",
            fontWeight: "bold",
            visibility: loading ? "hidden" : "visible",
          }}
        >
          {valuePrefix}
          {/* Provide a fallback value to preserve height */}
          {value || 0}
        </span>

        <span style={labelStyle}>
          {pluralizeLabel
            ? formatPlural(parseFancyNumber(`${value || 0}`), label)
            : label}
        </span>

        {supportingText && (
          <span className="supporting-text" style={labelStyle}>
            {supportingText}
          </span>
        )}

        {loading && (
          <ContentLoader
            speed={2}
            style={{ position: "absolute", top: 0, left: 0 }}
            width="100%"
            height="100%"
            viewBox="0 0 100 75"
            preserveAspectRatio="none"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect
              x={((1 - valueWidthPercent) / 2) * 100}
              y={supportingText ? "5" : "8"}
              rx="3"
              ry="3"
              width={valueWidthPercent * 100}
              height={supportingText ? "25" : "32"}
            />
            <rect
              x={(100 - labelWidthPercent) / 2}
              y={supportingText ? "35" : "50"}
              rx="3"
              ry="3"
              width={labelWidthPercent}
              height={supportingText ? "16" : "20"}
            />
            <rect
              x={(100 - supportingWidthPercent) / 2}
              y="59"
              rx="3"
              ry="3"
              width={supportingWidthPercent}
              height="16"
            />
          </ContentLoader>
        )}
      </div>
    </div>
  );
}
