import { format } from "date-fns";
import { DateInterval, getDateIntervalDuration, TimeUnit } from "utils/dates";
import { formatPlural } from "./words";

/** Formats a Date the same way the API server does. */
export const serverDate = (d: Date) => format(d, "yyyy-MM-dd");

export interface FormatDateIntervalOptions {
  unit?: TimeUnit | "auto";
  includeNumber?: (v: number) => boolean;
  formatNumber?: (v: number) => string;
}

/**
 * Format a date interval as a duration. Takes a DateInterval (start + end date)
 * and returns the duration of the interval (i.e. "3 weeks").
 *
 * Units can be directly specified in the options by passing a utils.dates.TimeInterval,
 * or can be auto discovered using "auto".
 */
export const formatDateIntervalDuration = (
  interval: DateInterval,
  {
    unit = "auto",
    includeNumber = (v) => v !== 1,
    formatNumber = String,
  }: FormatDateIntervalOptions = {}
): string => {
  let discoveredUnit;
  if (unit === "auto") {
    for (const trialUnit of [
      TimeUnit.Year,
      TimeUnit.Month,
      TimeUnit.Week,
      TimeUnit.Day,
    ]) {
      discoveredUnit = trialUnit;
      if (getDateIntervalDuration(interval, { unit: trialUnit }).length >= 1) {
        break;
      }
    }
  } else {
    discoveredUnit = unit;
  }

  const { length } = getDateIntervalDuration(interval, {
    unit: discoveredUnit,
  });
  const numberDisplay = includeNumber(length) ? `${formatNumber(length)} ` : "";
  return numberDisplay + formatPlural(length, discoveredUnit);
};

export function formatDateAsWritten(input: Date) {
  return input.toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}
