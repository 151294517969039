import ClientOnlyPortal from "components/ClientOnlyPortal";
import isEmpty from "lodash/isEmpty";
import * as React from "react";
import { usePopper } from "react-popper";
import useOnClickOutside from "utils/hooks/useOnClickOutside";
import css from "./KebabMenu.module.scss";

function KebabButton({ onClick }) {
  return (
    <div
      className={css.kebabButton}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
      </svg>
    </div>
  );
}

export function KebabMenuItem({ onClick, children }) {
  return (
    <div
      className="block h-full px-6 py-2 text-sm text-left text-gray-900 cursor-pointer hover:bg-gray-100 whitespace-nowrap"
      onClick={onClick}
      role="menuitem"
      tabIndex={0}
    >
      {children}
    </div>
  );
}

export function KebabMenu({ menuItems, onSelect }) {
  return (
    <div className="py-2 text-sm bg-white border rounded shadow">
      {isEmpty(menuItems) ? (
        <span className="block h-full px-6 py-2 text-sm text-left text-gray-500 whitespace-nowrap">
          No available actions...
        </span>
      ) : (
        <>
          {menuItems.map((item, i) => (
            <span key={i} onClick={() => onSelect()}>
              {item}
            </span>
          ))}
        </>
      )}
    </div>
  );
}

export function KebabMenuButton({ menuItems }) {
  const [anchorElement, setAnchorElement] = React.useState();
  const [menuElement, setMenuElement] = React.useState();

  const [isOpen, setIsOpen] = React.useState(false);

  const [clickOutsideRef] = useOnClickOutside({
    handler: () => {
      setIsOpen(false);
    },
  });

  const { styles, attributes } = usePopper(anchorElement, menuElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
      {
        name: "flip",
      },
      { name: "preventOverflow" },
    ],
  });

  return (
    <>
      <div ref={setAnchorElement}>
        <KebabButton
          onClick={(e) => {
            setIsOpen(!isOpen);
          }}
        />
      </div>

      <ClientOnlyPortal selector="#overlay">
        {/* TODO: Use e.g. framer to animate since headlessui is annoying */}
        {isOpen && (
          <div ref={clickOutsideRef}>
            <div
              ref={setMenuElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <KebabMenu
                menuItems={menuItems}
                onSelect={() => setIsOpen(false)}
              />
            </div>
          </div>
        )}
      </ClientOnlyPortal>
    </>
  );
}
