import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaCar } from "react-icons/fa";
import VehicleLoansSummary from "./Summary";
import { VehicleLoans } from "./VehicleLoans";

const VehicleLoansSubmodule: SubModule = {
  path: "vehicle_loans",
  title: "Vehicle Loans",
  icon: FaCar,
  component: VehicleLoans,
  summary: VehicleLoansSummary,
  statusName: "vehicleLoans",
};

export default VehicleLoansSubmodule;
