import { useDeleteFinancialAccount } from "api/graphql/FinancialAccount";
import { Card } from "components/Card";
import { ListItem } from "components/ListItem";
import { format, parseISO } from "date-fns";
import { find } from "lodash";
import React, { ReactNode } from "react";
import {
  formatErrorsFromSchema,
  formatFinancialAccountType,
  formatName,
  formatOxfordComma,
  formatQuantity,
} from "utils/formatters";
import { FinancialAccountSchema } from "./FinancialAccountWizard";
import css from "./ListItems.module.scss";
import { financialAccountTypes } from "./utils";

export function FinancialAccountListItem({
  account,
  showFeedback = false,
}: {
  account: ArrayItemType<FinancialAccountModule["financialAccounts"]>;
  showFeedback?: boolean;
}) {
  const Icon = find(
    financialAccountTypes,
    (t) => t.value === account.accountType
  )?.icon;

  return (
    <div>
      {showFeedback && formatErrorsFromSchema(FinancialAccountSchema, account)}

      <h5 className="cardLabel">
        <Icon /> <span>{formatFinancialAccountType(account.accountType)}</span>
      </h5>

      <div style={{ display: "flex" }}>
        <strong>Account {account.accountNumber}</strong>
        &nbsp;
        {account.accountCreationMonth && (
          <span>
            opened {format(parseISO(account.accountCreationMonth), "MM/yyyy")}
          </span>
        )}
        {account.accountClosureMonth && (
          <div className="supporting-text">Closed account</div>
        )}
      </div>

      <div className="supporting-text">Balance: ${account.currentValue}</div>
      {account.otherDescription && (
        <div className="supporting-text">{account.otherDescription}</div>
      )}

      {!!account.coowners?.length && (
        <div className="supporting-text">
          Co-owned with{" "}
          {formatOxfordComma(
            ...account.coowners.map((coowner) => formatName(coowner.person))
          )}
        </div>
      )}
    </div>
  );
}

export function FinancialInstitutionListItem({
  accounts,
  name,
  headerButton,
  onEdit,
  logo,
  showFeedback = false,
}: {
  accounts: FinancialAccountModule["financialAccounts"];
  name: string;
  logo?: string;
  headerButton?: ReactNode;
  onEdit?: (id: string) => any;
  showFeedback?: boolean;
}) {
  const [onDelete] = useDeleteFinancialAccount();

  return (
    <Card className={css.plaidItem}>
      <div className={css.header}>
        <div className={css.header__content}>
          {logo && (
            <img
              src={logo}
              alt={`${name} logo`}
              className={css.institutionLogo}
            />
          )}
          <div>
            <h3 style={{ margin: 0 }} id={name}>
              {name}
            </h3>
            <div className="supporting-text">
              {formatQuantity(accounts.length, " account")}
            </div>
          </div>
        </div>
        {headerButton}
      </div>

      {accounts.map((a) => (
        <ListItem
          key={a.id}
          className={css.accountListItem}
          onDelete={() => onDelete(a.id)}
          onEdit={() => onEdit(a.id)}
        >
          <FinancialAccountListItem account={a} showFeedback={showFeedback} />
        </ListItem>
      ))}
    </Card>
  );
}
