import { StatelessEditCreateList } from "components/EditCreateList";
import React from "react";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";

export interface Props {
  prev: string;
  next: string;
}

export function FinancialInvestments({ prev, next }: Props) {
  return (
    <StatelessEditCreateList
      prev={prev}
      next={next}
      CreateView={CreateView}
      ListView={ListView}
      EditView={EditView}
    />
  );
}
