import { CategoryDetail } from "components/FormModules/Assets/HouseholdItems/CategorySection/CategoryDetail";
import { HouseholdItemCategoryChoice } from "globalTypes";
import * as React from "react";
import { FaTrophy } from "react-icons/fa";

const Examples = () => (
  <div>
    <p>Examples include:</p>
    <ul>
      <li>Antiques and figurines</li>
      <li>Paintings, prints, or other artwork</li>
      <li>Books, pictures, or other art objects</li>
      <li>Stamp, coin, or baseball card collections</li>
      <li>Other collections or memorabilia</li>
    </ul>
  </div>
);

const CollectiblesDetail: CategoryDetail = {
  category: HouseholdItemCategoryChoice.Collectibles,
  displayName: "Collectibles",
  displayLowercaseName: "collectibles",
  hasNoProfileBooleanName: "hasNoHouseholdCollectiblesItems",
  singleInterrogativeText: "Tell us about this collectible.",
  noAssetDeclarativeText:
    "You have indicated that neither you nor any member of your household owns any collectibles, or collectibles you would like to claim as exempt.",
  booleanInterrogativeText: (
    <>
      <p className="required">
        <strong>
          Do you or any members of your household have any collectibles?
        </strong>
      </p>
      <Examples />
    </>
  ),
  declarativeText: (
    <>
      <p className="required">
        <strong>
          Please itemize all collectibles you and your family members own, or
          any collectibles you would like to claim as exempt.
        </strong>
      </p>
      <Examples />
    </>
  ),
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add collectibles below.",
  urlPathName: "collectibles",
  displayMenuName: "Collectibles",
  menuIcon: FaTrophy,
};

export default CollectiblesDetail;
