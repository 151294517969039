import cn from "classnames";
import { LearnMorePortal } from "components/LearnMore";
import React, { ReactNode } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { breakpoints, useBreakpoint } from "utils/hooks";
import s from "../FormFlow/FormFlow.module.scss";

interface Props {
  children: ReactNode;
  backLinkText: string;
  backLinkHref: string;
}

export default function ModuleContainer({
  children,
  backLinkText,
  backLinkHref,
}: Props) {
  const isDesktop = useBreakpoint(breakpoints.desktop);

  return (
    <>
      <div className={s.step}>
        <Link
          to={backLinkHref}
          className={cn(s.dashboardLink, s.mobileDashboardLink)}
        >
          <FaChevronLeft />
          {backLinkText}
        </Link>

        {children}
      </div>
      {isDesktop && <LearnMorePortal className={s.learnMoreContainer} />}
    </>
  );
}
