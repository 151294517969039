import { FarmAssetCategory } from "globalTypes";
import React from "react";
import { GiCow } from "react-icons/gi";
import { CategoryDetail } from "../CategorySection/CategoryDetail";

const FarmAnimalsDetail: CategoryDetail = {
  category: FarmAssetCategory.FarmAnimals,
  displayName: "Farm Animals",
  displayLowercaseName: "farm animals",
  hasNoProfileBooleanName: "hasNoFarmAnimals",
  singleInterrogativeText:
    "Tell us about either a single valuable farm animal or a class of animals, such as a species.",
  noAssetDeclarativeText:
    "You have indicated that you don’t own any farm animals.",
  booleanInterrogativeText: (
    <>
      <p className="required">
        <strong>
          Do you own any animals for farming or ranching purposes?
        </strong>
      </p>
      <p>Examples include pigs, sheep, chickens, farm-raised fish, etc.</p>
    </>
  ),
  declarativeText: <strong>Please list all farm/ranch animals you own.</strong>,
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add farm animals below.",
  urlPathName: "farm_animals",
  menuIcon: GiCow,
};

export default FarmAnimalsDetail;
