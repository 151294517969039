import { QueryHookOptions, useMutation, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import React from "react";
import DELETE_CONSULTATION from "./DeleteConsultation.gql";
import DELETE_CONSULTATION_PAYMENT from "./DeleteConsultationPayment.gql";
import GET_CONSULTATION from "./GetConsultation.gql";
import GET_CONSULTATION_PAYMENT from "./GetConsultationPayment.gql";
import LIST_CONSULTATION from "./ListConsultation.gql";
import UPSERT_CONSULTATION from "./UpsertConsultation.gql";
import UPSERT_CONSULTATION_PAYMENT from "./UpsertConsultationPayment.gql";

const LIST_CONSULTATION_IDS = gql`
  query ListConsultationIds {
    consultations {
      id
    }
  }
`;

export function useListConsultation(
  options?: QueryHookOptions<ListConsultation>
) {
  return useQuery<ListConsultation>(LIST_CONSULTATION, options);
}

export function useGetConsultation(id: string) {
  return useQuery<GetConsultation, GetConsultationVariables>(GET_CONSULTATION, {
    variables: { id },
  });
}

export function useUpsertConsultation<
  Schema extends {
    consultation: any;
  } = UpsertConsultation,
  Variables = UpsertConsultationVariables
>(query = UPSERT_CONSULTATION) {
  return useUpsert<Schema, Variables, ListConsultationIds, ConsultationInput>(
    query,
    LIST_CONSULTATION_IDS,
    "consultation",
    "consultations",
    (cache) =>
      updateFilingProfileCache(cache, "hasNoBankruptcyConsultations", false)
  );
}

export function useDeleteConsultation() {
  return useDelete<DeleteConsultation, ListConsultationIds>(
    DELETE_CONSULTATION,
    LIST_CONSULTATION_IDS,
    "deleteConsultation",
    "consultations"
  );
}

export function useDeleteConsultationPayment() {
  const [deleteConsultationPayment] = useMutation<
    DeleteConsultationPayment,
    DeleteConsultationPaymentVariables
  >(DELETE_CONSULTATION_PAYMENT);
  return React.useCallback(
    (id: UUID) => {
      return deleteConsultationPayment({ variables: { id } });
    },
    [deleteConsultationPayment]
  );
}

export function useUpsertConsultationPayment() {
  const [upsertConsultationPayment] = useMutation<
    UpsertConsultationPayment,
    UpsertConsultationPaymentVariables
  >(UPSERT_CONSULTATION_PAYMENT);
  return React.useCallback(
    (consultationId: UUID, input: ConsultationPaymentInput) => {
      return upsertConsultationPayment({
        variables: { consultationId, input },
      });
    },
    [upsertConsultationPayment]
  );
}

export function useGetConsultationPayment(
  id: string,
  options?: QueryHookOptions<
    GetConsultationPayment,
    GetConsultationPaymentVariables
  >
) {
  return useQuery<GetConsultationPayment, GetConsultationPaymentVariables>(
    GET_CONSULTATION_PAYMENT,
    {
      variables: { id },
      ...(options || {}),
    }
  );
}
