import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaHome } from "react-icons/fa";
import { Mortgages } from "./Mortgages";
import MortgagesSummary from "./Summary";

const MortgagesSubmodule: SubModule = {
  path: "mortgages",
  title: "Mortgages",
  icon: FaHome,
  component: Mortgages,
  summary: MortgagesSummary,
  statusName: "mortgages",
};

export default MortgagesSubmodule;
