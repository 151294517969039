import SubMenuModule from "components/FormModuleSubMenu";
import React from "react";
import { FaMoneyCheckAlt } from "react-icons/fa";
import HouseholdExpenses from "./HouseholdExpenses";
import Insurance from "./Insurance";
import ExpensesSummary from "./Summary";

export interface ExpensesProps {
  prev?: string;
  next?: string;
  subMenuContainerID?: string;
}

const expensesModules = [HouseholdExpenses, Insurance];
export const expensesStatusNames = expensesModules.map((m) => m.statusName);

export default function Expenses({ subMenuContainerID, prev }: ExpensesProps) {
  return (
    <SubMenuModule
      subMenuContainerID={subMenuContainerID}
      dashboardURL={prev}
      title="Your Expenses"
      Index={ExpensesSummary}
      subModules={expensesModules}
    />
  );
}

Expenses.Icon = FaMoneyCheckAlt;
