import AccountMaskInput from "components/AccountMaskInput";
import { AddressForm, AddressSchema } from "components/FormSections/Address";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { object, string } from "yup";

export const LenderSchema = object({
  lenderName: string().required("Please enter the card issuer."),
  accountNumber: string()
    .required("Please enter the account number.")
    .min(4, "You must provide the last four characters of the account number.")
    .max(4, "You must provide the last four characters of the account number."),
  billingAddress: AddressSchema.required(),
}).required();

export default function Lender({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <TextInput
          name="lenderName"
          label="Card issuer"
          width="200px"
          autoFocus={!isEdit}
        />
        {/* @ts-ignore Switch out text-mask to something up to date */}
        <AccountMaskInput name="accountNumber" />
      </div>

      <p>
        What is this lender&rsquo;s mailing address? This will allow us to
        contact the lender, if necessary.
      </p>
      <AddressForm graphQL name="billingAddress" />

      <WizardNavigation {...navProps} />
    </>
  );
}
