import { GovernmentDisbursementCategoryChoice } from "globalTypes";
import React from "react";
import { FaDonate } from "react-icons/fa";
import { DisbursementsCategoryDetail } from "../CategorySection/CategoryDetail";

export const socialSecurityCategoryDetail: DisbursementsCategoryDetail = {
  category: GovernmentDisbursementCategoryChoice.SocialSecurity,
  displayName: "Social Security",
  displayLowercaseName: "social security",
  hasNoProfileBooleanName: "hasNoSocialSecurity",
  singleInterrogativeText:
    "Please upload a copy of your most recent award letter.",
  urlPathName: "social-security",
  menuIcon: FaDonate,
  description: (
    <>
      {/* <p>
        Have you received any Social Security benefits for you or members of
        your household?
      </p> */}
      {/* <div>
        If so,{" "}
        <span className="font-semibold">
          please upload a copy of your most recent award letter.
        </span>
      </div> */}
    </>
  ),
};
