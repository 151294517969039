import { useQuery } from "@apollo/client";
import { useDeleteGovernmentDisbursement } from "api/graphql/GovernmentDisbursement";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema, formatQuantity } from "utils/formatters";
import { useNested } from "utils/hooks";
import GOVERNMENT_DISBURSEMENTS_MODULE from "../GovernmentDisbursementsModule.gql";
import { GovernmentDisbursementSchema } from "../GovernmentDisbursementWizard";
import { DisbursementsCategoryDetail } from "./CategoryDetail";

interface Props {
  createURL: string;
  nextURL: string;
  prevURL: string;
  onEdit: (id: string) => any;
  categoryDetail: DisbursementsCategoryDetail;
}

export default function ListView(props: Props) {
  const { data } = useQuery<
    GovernmentDisbursementsModule,
    GovernmentDisbursementsModuleVariables
  >(GOVERNMENT_DISBURSEMENTS_MODULE, {
    variables: { category: props.categoryDetail.category },
  });
  const [onDelete] = useDeleteGovernmentDisbursement();
  const { path, url } = useNested();

  if (!data) return <Loading />;

  const { governmentDisbursements: items } = data;
  const {
    displayName,
    hasNoProfileBooleanName,
    displayLowercaseName,
    description,
  } = props.categoryDetail;

  return (
    <>
      <Title>Your {displayName} Benefits</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean={hasNoProfileBooleanName}
              createURL={props.createURL}
              nextURL={props.nextURL}
              addText="Add a disbursement"
              question={
                <>
                  <p className="required">
                    <strong>
                      Have you received any {displayLowercaseName} benefits for
                      you or members of your household?
                    </strong>
                  </p>
                  {description}
                </>
              }
              confirmation={
                <>
                  <p>
                    You indicated that you didn&rsquo;t receive any{" "}
                    {displayLowercaseName} benefits for your household.
                  </p>
                  <p>
                    If that&rsquo;s correct, you&rsquo;re ready to continue to
                    the next section.
                  </p>
                  {description}
                </>
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              {description}

              <StatelessList
                {...props}
                emptyText="You haven’t added any government disbursements yet."
                showAddButton={false}
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(disbursement) => (
                  <div>
                    {formatErrorsFromSchema(
                      GovernmentDisbursementSchema,
                      disbursement
                    )}
                    <strong>{props.categoryDetail.displayName}</strong>
                  </div>
                )}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
