import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaFileAlt } from "react-icons/fa";
import { MiscDocuments } from "./MiscDocuments";
import MiscDocumentsSummary from "./Summary";

const MiscDocumentsSubmodule: SubModule = {
  path: "additional-documents",
  title: "Additional Documents",
  icon: FaFileAlt,
  component: MiscDocuments,
  summary: MiscDocumentsSummary,
  statusName: "miscDocuments" as const,
};

export default MiscDocumentsSubmodule;
