import { find } from "lodash";
import * as React from "react";
import { states } from "utils/constants";

interface FormatAddressInput {
  line1: string;
  line2?: string;
  city: string;
  state: { abbr: string };
  zipCode: string;
}

export function formatGraphAddress(
  address?: FormatAddressInput,
  inline = false
) {
  if (!address) {
    return null;
  }
  return (
    <>
      {address.line1}
      {!!address.line2 && (
        <>
          {", "}
          {address.line2}
        </>
      )}
      {inline ? ", " : <br />}
      {address.city}, {address.state.abbr} {address.zipCode}
    </>
  );
}

export const formatStateNameFromAbbr = (abbr: string) =>
  find(states, (state) => state.abbr === abbr.toUpperCase())?.name || abbr;
