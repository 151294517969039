import DocumentsUpload from "components/FormSections/DocumentsUpload";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { array, boolean, mixed, object } from "yup";

export const DocumentationSchema = object({
  hasNoSupportingDocumentation: boolean().required(),
  documents: array(mixed<File | UploadedFile>()).when(
    "hasNoSupportingDocumentation",
    {
      is: true,
      then: array().min(0),
      otherwise: array().min(
        1,
        "You haven’t provided any documentation about this expense."
      ),
    }
  ),
}).required();

export default function Documentation({
  isEdit,
  navProps,
}: WizardRouteChildProps) {
  return (
    <>
      <DocumentsUpload
        relatedTypename="MonthlyExpense"
        displayText={
          <p>
            Upload your most recent bill or invoice for this expense. If this is
            an expense that changes monthly, upload the last six months of
            documents.
          </p>
        }
        hasNoDocumentsLabelText={
          "Do you have any documentation about this expense, such as a recent bill or invoice?"
        }
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
