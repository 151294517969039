import { useQuery } from "@apollo/client";
import { useDeleteStorageContainer } from "api/graphql/StorageContainer";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema } from "utils/formatters";
import { useNested } from "utils/hooks";
import STORAGE_CONTAINERS_MODULE from "./StorageContainersModule.gql";
import { StorageContainerSchema } from "./StorageContainerWizard";

interface ListViewProps {
  createURL: string;
  nextURL: string;
  prevURL: string;
  onEdit: (id: string) => any;
}

export function ListView(props: ListViewProps) {
  const { data } = useQuery<StorageContainersModule>(STORAGE_CONTAINERS_MODULE);
  const [onDelete] = useDeleteStorageContainer();
  const { path, url } = useNested();

  if (!data) return <Loading />;
  const { storageContainers: items } = data;

  return (
    <>
      <Title>Your Storage</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              nextURL={props.nextURL}
              createURL={props.createURL}
              filingProfileBoolean="hasNoStorage"
              addText="Add storage"
              question={
                <p className="required">
                  <strong>
                    Do you store anything of value with a third party such as a
                    storage unit or safety deposit box?
                  </strong>
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you don’t store anything of value with
                  a third party such as a storage unit or safety deposit box. If
                  that’s correct, you’re ready to continue to the next section.
                  If you do have anything of value stored with a third party,
                  you may add them below.
                </p>
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Please tell us about any third parties where you store things,
                such as safety deposit boxes and storage units.
              </p>
              <StatelessList
                {...props}
                emptyText="You haven’t added any storage."
                addButtonText="Add storage"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(s) => {
                  return (
                    <div>
                      {formatErrorsFromSchema(StorageContainerSchema, s)}
                      <strong>
                        Storage with {s.entityName}: {s.thirdPartyIdentifier}
                      </strong>
                    </div>
                  );
                }}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
