import cn from "classnames";
import React from "react";
import css from "./Card.module.css";

interface PreviousStatProps {
  title: string;
  value: string | number;
}

interface CardProps extends Omit<React.HTMLProps<HTMLDivElement>, "title"> {
  title: string;
  value: string | number;
  previousStats?: PreviousStatProps[];
}

export function StatCard({ title, value, previousStats, ...etc }: CardProps) {
  return (
    <div className={cn(css.Card, "card")} {...etc}>
      <h4>{title}</h4>
      <div className={css.value}>{value}</div>
      {previousStats && (
        <div className={css.previousContainer}>
          {previousStats.map((stat, index) => {
            return (
              <div key={index}>
                <h6>{stat.title}</h6>
                <div className={css.previousValue}>{stat.value}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
