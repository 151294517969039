import * as React from "react";
import { Helmet } from "react-helmet";

interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
}

/** An `<h1>` which sets the document title with react-helmet */
export function Title({ children, ...headingProps }: TitleProps) {
  return (
    <>
      <Helmet>
        <title>{children}</title>
      </Helmet>
      <h1 {...headingProps}>{children}</h1>
    </>
  );
}
