import cn from "classnames";
import * as React from "react";
import s from "./Card.module.css";

interface CardProps extends Omit<React.HTMLProps<HTMLDivElement>, "title"> {
  children: React.ReactNode;
  className?: string;
  title?: React.ReactNode;
}

export function Card({ children, className = "", title, ...etc }: CardProps) {
  return (
    <div className={cn(s.Card, "card", className)} {...etc}>
      <div className={cn(s.Card__title, { hidden: !title })}>{title}</div>
      {children}
    </div>
  );
}
