import { useGetOtherIncome } from "api/graphql/OtherIncome";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import { OtherIncomeWizard } from "./OtherIncomeWizard";

interface Props {
  id: string;
  listURL: string;
}

export function EditView({ id, listURL }: Props) {
  const { data, loading } = useGetOtherIncome(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.otherIncome) {
    return <Redirect to={listURL} />;
  }

  return (
    <OtherIncomeWizard
      title="Updating Income"
      backURL={listURL}
      nextURL={listURL}
      initialValue={data.otherIncome}
    />
  );
}
