import { Card } from "components/Card";
import { EmailLink } from "components/ContactUs/ContactTypes";
import { OptionsList } from "components/OptionCard";
import * as React from "react";
import { Helmet } from "react-helmet";
import { FaCertificate, FaClipboardCheck } from "react-icons/fa";
import css from "./ApplicationSubmitted.module.css";

export function ApplicationSubmitted() {
  return (
    <>
      <Helmet>
        <title>Debt relief through bankruptcy</title>
      </Helmet>

      <div className={css.container}>
        <Card style={{ paddingBottom: "50px" }}>
          <h3>Your Lexria Intake Questionnaire has been submitted</h3>
          <p>
            Thank you for taking the time to take control of your financial
            situation. Your Lexria Intake Questionnaire has been submitted for
            review by your attorney.
          </p>
          <p>
            If you need anything related to your filing at this time, please
            reach out directly to your attorney. If you still have updates or
            questions about your Intake, you can contact us below.
          </p>
          <OptionsList>
            <EmailLink />
          </OptionsList>
        </Card>
      </div>
    </>
  );
}
