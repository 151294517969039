import React from "react";
import { OtherDebtWizard } from "./OtherDebtWizard";

export default function CreateView({ listURL }: { listURL: string }) {
  return (
    <OtherDebtWizard
      title="Add Other Debt"
      backURL={listURL}
      nextURL={listURL}
    />
  );
}
