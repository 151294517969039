import React from "react";
import { InsuranceCategoryDetail } from "../../HouseholdExpenses/CategorySection/CategoryDetail";
import InsurancePolicyWizard from "../InsurancePolicyWizard";

interface Props {
  listURL: string;
  categoryDetail: InsuranceCategoryDetail;
}

export default function CreateView(props: Props) {
  return (
    <InsurancePolicyWizard
      title={`Add ${props.categoryDetail.displayName} Policy`}
      subtitle={props.categoryDetail.singleInterrogativeText}
      type={props.categoryDetail.category}
      backURL={props.listURL}
      nextURL={props.listURL}
    />
  );
}
