import { useGetTaxReturn } from "api/graphql/TaxReturn";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import { taxReturnResponseToWizardState, TaxReturnWizard } from "./Wizard";

interface Props {
  id: string;
  listURL: string;
}

export function EditView({ id, listURL }: Props) {
  const { data, loading } = useGetTaxReturn(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.taxReturn) {
    return <Redirect to={listURL} />;
  }

  return (
    <TaxReturnWizard
      title="Updating Tax Return"
      backURL={listURL}
      nextURL={listURL}
      initialValue={taxReturnResponseToWizardState(data.taxReturn)}
    />
  );
}
