import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaFileMedical } from "react-icons/fa";
import { MedicalDebts } from "./MedicalDebts";
import MedicalDebtsSummary from "./Summary";

const MedicalDebtsSubmodule: SubModule = {
  path: "medical_debts",
  title: "Medical Debts",
  icon: FaFileMedical,
  component: MedicalDebts,
  summary: MedicalDebtsSummary,
  statusName: "medicalDebts",
};

export default MedicalDebtsSubmodule;
