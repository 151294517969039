import { useGetFamilyProfile } from "api/graphql/FamilyProfile";
import { useListOtherPersons } from "api/graphql/OtherPerson";
import { DateInput } from "components/DateInput";
import GetOrCreate from "components/GetOrCreate";
import { HiddenInput } from "components/HiddenInput";
import { InputRow } from "components/InputRow";
import { Loading } from "components/Loading";
import { TextInput } from "components/TextInput";
import * as React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { formatName } from "utils/formatters";
import { useNested, useQueryParams } from "utils/hooks";
import { updateQueryParams } from "utils/urls";

function CoreRelatedPersonForm({ editing }: { editing: boolean }) {
  return (
    <>
      <InputRow label="Family member’s legal name">
        <TextInput
          name={"relatedPerson.firstName"}
          placeholder="First"
          aria-label="First name"
          autoFocus={!editing}
          maxLength={255}
        />
        <TextInput
          name={"relatedPerson.middleName"}
          placeholder="Middle"
          aria-label="Middle name"
          maxLength={255}
        />
        <TextInput
          name={"relatedPerson.lastName"}
          placeholder="Last"
          aria-label="Last name"
          maxLength={255}
        />
        <TextInput
          name={"relatedPerson.suffix"}
          placeholder="Suffix"
          aria-label="Name suffix"
          width="100px"
          maxLength={12}
        />
      </InputRow>
      <DateInput name={"relatedPerson.birthday"} label="Birth date" />
    </>
  );
}

function EditRelatedPerson() {
  return <CoreRelatedPersonForm editing />;
}

function CreateRelatedPerson() {
  const params = useQueryParams();
  const otherPersons = useListOtherPersons();
  const { url } = useNested();
  const history = useHistory();
  const [redirected, setRedirected] = React.useState(false);

  React.useEffect(() => {
    if (!redirected && !otherPersons.loading && !params.createPerson) {
      if (!otherPersons.data.otherPersons.length) {
        setRedirected(true);
        history.replace(
          updateQueryParams(url("."), { ...params, createPerson: "true" })
        );
      }
    }
  }, [params, otherPersons, redirected]);

  if (params.createPerson) {
    return (
      <>
        <HiddenInput name="relatedPersonId" value={null} />
        <CoreRelatedPersonForm editing={false} />
        {!!otherPersons.data?.otherPersons?.length && (
          <Link
            to={updateQueryParams(url("."), {
              ...params,
              createPerson: undefined,
            })}
            className="flat primary btn"
          >
            <FaChevronLeft />
            <span>Select existing person</span>
          </Link>
        )}
      </>
    );
  } else {
    return otherPersons.loading ? (
      <Loading />
    ) : (
      <>
        <HiddenInput name="relatedPerson" value={null} />
        <p>Select a person from your assessment</p>
        <GetOrCreate
          name="relatedPersonId"
          objectName="person"
          createURL={updateQueryParams(url("."), {
            ...params,
            createPerson: "true",
          })}
          required="This field is required."
          options={otherPersons.data.otherPersons.map((a) => ({
            value: a.id,
            label: formatName(a),
          }))}
        />
      </>
    );
  }
}

interface RelatedPersonFormProps {
  editing: boolean;
}

export function RelatedPersonForm({ editing }: RelatedPersonFormProps) {
  if (editing) {
    return <EditRelatedPerson />;
  } else {
    return <CreateRelatedPerson />;
  }
}
