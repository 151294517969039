import { BooleanInput } from "components/BooleanInput";
import VehicleForm, { VehicleSchema } from "components/FormSections/Vehicle";
import { WantToKeepLearnMore } from "components/WantToKeepLearnMore";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { mixed, object } from "yup";

export const LeasedVehicleSchema = object({
  vehicle: VehicleSchema.required(),
  wantToKeep: mixed<boolean | null>().required("This field is required."),
}).required();

export default function Vehicle({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <p>What type of vehicle is this?</p>
      <VehicleForm autoFocus={!isEdit} />
      <div className="form-row">
        <BooleanInput
          name="wantToKeep"
          label="Do you want to keep this property after your bankruptcy?"
          learnMore={<WantToKeepLearnMore />}
        />
      </div>

      <WizardNavigation {...navProps} />
    </>
  );
}
