import { useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import ASSIGN_COSIGNER from "./AssignCosigner.gql";
import GET_COSIGNER_CANDIDATES from "./GetCosignerCandidates.gql";
import UPSERT_COSIGNER from "./UpsertCosigner.gql";

export function useAssignCosigner() {
  const [assignCosigner] = useMutation<AssignCosigner, AssignCosignerVariables>(
    ASSIGN_COSIGNER
  );

  const callback = React.useCallback(
    (variables: AssignCosignerVariables) => {
      return assignCosigner({ variables });
    },
    [assignCosigner]
  );

  return React.useMemo(() => [callback], [callback]);
}

export function useGetCosignerCandidates(
  variables: GetCosignerCandidatesVariables
) {
  return useQuery<GetCosignerCandidates, GetCosignerCandidatesVariables>(
    GET_COSIGNER_CANDIDATES,
    {
      fetchPolicy: "network-only",
      variables,
    }
  );
}

export function useUpsertCosigner() {
  return useMutation<UpsertCosigner, UpsertCosignerVariables>(UPSERT_COSIGNER);
}
