import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import { find } from "lodash";
import React, { useMemo } from "react";
import BANKRUPTCY_CASES_MODULE from "./BankruptcyCasesModule.gql";

export default function BankruptcyCasesSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<BankruptcyCasesModule>(BANKRUPTCY_CASES_MODULE, {
    returnPartialData: true,
  });

  const chapters = useMemo(() => {
    if (!data?.bankruptcyCases) return undefined;
    const hasChapter7 = !!find(data.bankruptcyCases, (c) => c.chapter === 7);
    const hasChapter13 = !!find(data.bankruptcyCases, (c) => c.chapter === 13);
    let chapters = "";
    if (hasChapter7) chapters += "7";
    if (hasChapter7 && hasChapter13) chapters += " & ";
    if (hasChapter13) chapters += "13";
    return chapters;
  }, [data?.bankruptcyCases]);

  return (
    <ModuleSummary {...props} progress={data?.progress.bankruptcyCases}>
      {data?.filingProfile?.hasNoPreviousBankruptcies ? (
        <HasNoSummary>
          Your haven&rsquo;t filed any previous bankruptcies.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="bankruptcy case"
            maxValueLength={1}
            value={data?.bankruptcyCases?.length}
            pluralizeLabel
          />
          <NumericSummary label="chapter" maxValueLength={3} value={chapters} />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
