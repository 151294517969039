import {
  compareAsc,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
  startOfToday,
} from "date-fns";
import { last, omit, pickBy } from "lodash";

export const sortAddresses = (addresses: ResidentialAddress[]) =>
  [...addresses].sort((a, b) =>
    compareAsc(parseISO(a.residentFrom), parseISO(b.residentFrom))
  );

export const isComplete = (state: ResidentialAddress[], requiredFrom: Date) => {
  const sorted = sortAddresses(state);
  let valid = true;
  if (!sorted.length) return false;
  if (isAfter(parseISO(sorted[0].residentFrom), requiredFrom)) return false;
  if (last(sorted).residentTo) return false;
  sorted.forEach((current, i) => {
    const prev = sorted[i - 1];
    const next = sorted[i + 1];
    if (
      (prev &&
        isBefore(parseISO(prev.residentTo), parseISO(current.residentFrom))) ||
      (next &&
        current.residentTo &&
        isAfter(parseISO(next.residentFrom), parseISO(current.residentTo)))
    ) {
      valid = false;
    }
  });
  return valid;
};

export const cleanApiInput = (
  data: ResidentialAddressInput
): ResidentialAddressInput => {
  const cleaned = pickBy(
    data,
    (value, key) => !(key === "addressId" && !value)
  ) as ResidentialAddressInput;
  if (isSameDay(parseISO(cleaned.residentTo), startOfToday())) {
    cleaned.residentTo = null;
  }
  return {
    ...omit(cleaned, "_stateAbbr"),
    residentFrom: data.residentFrom,
  };
};
