import { Wizard } from "components/Wizard";
import React from "react";
import { DeepPartial } from "react-hook-form";
import PublicOrPrivate, { PublicOrPrivateSchema } from "./PublicOrPrivate";
import StatementDetails, { StatementDetailsSchema } from "./StatementDetails";
import StatementUpload, { StatementUploadSchema } from "./StatementUpload";
import { RequiredKeys } from "utils/types";

export const StudentLoanStatementSchema = StatementUploadSchema.concat(
  PublicOrPrivateSchema
)
  .concat(StatementDetailsSchema)
  .required();
export type StudentLoanStatement = Omit<
  RequiredKeys<
    ReturnType<typeof StudentLoanStatementSchema.validateSync>,
    "statementFile"
  >,
  "lenderAddress"
> & { lenderAddress: Address };

export const lastStepURL = "/lender-address";

interface Props {
  abortURL?: string;
  onSubmit: (
    formValues: StudentLoanStatement
  ) => DeepPartial<StudentLoanStatement>;
  initialValue?: StudentLoanStatement;
}

export default function AddStatementWizard({
  abortURL,
  onSubmit,
  initialValue,
}: Props) {
  return (
    <Wizard
      title="Add a student loan statement"
      onSave={onSubmit}
      backURL={abortURL}
      initialValue={initialValue}
      hideButtons
      steps={[
        {
          title: "Upload",
          path: "/upload",
          schema: StatementUploadSchema,
          component: StatementUpload,
        },
        {
          title: "Loan Type",
          path: "loan-type",
          schema: PublicOrPrivateSchema,
          component: PublicOrPrivate,
        },
        {
          title: "Lender Address",
          path: lastStepURL,
          schema: StatementDetailsSchema,
          component: StatementDetails,
        },
      ]}
    />
  );
}
