import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaPassport } from "react-icons/fa";
import { PhotoId } from "./PhotoId";
import PhotoIdDocumentSummary from "./Summary";

const PictureIdSubmodule: SubModule = {
  path: "photo_id",
  title: "Photo ID",
  icon: FaPassport,
  component: PhotoId,
  summary: PhotoIdDocumentSummary,
  statusName: "pictureId" as const,
};

export default PictureIdSubmodule;
