import cn from "classnames";
import * as React from "react";
import { FaFile, FaFilePdf, FaTrashAlt, FaFileDownload } from "react-icons/fa";
import { Card } from "../Card";
import styles from "./FileCard.module.css";

interface FileCardProps {
  name?: string;
  type?: string;
  file?: File;
  onDelete?: (event: React.MouseEvent) => any;
  onDownload?: (event: React.MouseEvent) => any;
  disabled?: boolean;
  className?: string;
}

const getFilePreview = (mimeType: string) => {
  const size = "60%";
  switch (mimeType) {
    case "application/pdf":
      return <FaFilePdf size={size} />;
    default:
      return <FaFile size={size} />;
  }
};

export function FileCard({
  name = "",
  type,
  file,
  onDelete,
  onDownload,
  disabled = false,
  className,
}: FileCardProps) {
  const showName = name || (file && file.name);
  const showType = type || (file && file.type);
  return (
    <Card className={cn(styles.root, className)}>
      <div className={styles.actions}>
        {onDownload && (
          <button
            type="button"
            className="btn icon primary"
            onClick={onDownload}
            disabled={disabled}
          >
            <FaFileDownload size="14px" />
          </button>
        )}
        {onDelete && (
          <button
            type="button"
            className="btn icon destructive"
            onClick={onDelete}
            disabled={disabled}
          >
            <FaTrashAlt size="14px" />
          </button>
        )}
      </div>
      <div className={styles.preview}>{getFilePreview(showType)}</div>
      <div className={cn("small", styles.label)} title={showName}>
        {showName}
      </div>
    </Card>
  );
}
