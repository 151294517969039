import SectionSummary from "components/FormModuleSubMenu/SectionSummary";
import { SectionIndexProps } from "components/FormModuleSubMenu/SubmenuModule";
import React from "react";
import legalIllustration from "./legal.svg";
import LEGAL_MATTERS_INDEX from "./LegalMattersIndex.gql";

export default function LegalSummary(props: SectionIndexProps) {
  return (
    <SectionSummary<LegalMattersIndex>
      {...props}
      query={LEGAL_MATTERS_INDEX}
      image={
        <img
          src={legalIllustration}
          alt="A lawyer holding a balance"
          width={406}
          height={306}
        />
      }
      heroCopy={
        <p>
          Your involvement in recent or ongoing legal actions may affect your
          bankruptcy case. Your attorney may also be able to recovery recently
          seized assets or wage garnishments.
        </p>
      }
      completedCopy={
        <p>
          Nicely done! It looks like we have everything we need to know
          regarding your legal history. If the information below is accurate and
          complete, you&rsquo;re all done with this section.
        </p>
      }
    />
  );
}
