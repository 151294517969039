import { useQuery } from "@apollo/client";
import { useDeletePreviousName } from "api/graphql/PreviousName";
import { ButtonRow } from "components/ButtonRow";
import { PreviousNameFullSchema } from "components/FormModules/Legal/PreviousNames/PreviousNameWizard";
import { ListItem } from "components/ListItem";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema } from "utils/formatters";
import { useNested } from "utils/hooks";
import PREVIOUS_NAMES_MODULE from "./PreviousNamesModule.gql";

interface Props {
  onEdit: (id: string) => any;
  createURL: string;
  nextURL: string;
}

export function ListView({ onEdit, createURL, nextURL }: Props) {
  const { data, loading } = useQuery<PreviousNamesModule>(
    PREVIOUS_NAMES_MODULE
  );
  const [onDelete] = useDeletePreviousName();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { previousNames: items } = data;

  return (
    <>
      <Title>Your Previous Names</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoPreviousNames"
              createURL={createURL}
              nextURL={nextURL}
              addText="Add a previous name"
              question={
                <p className="required">
                  <strong>
                    Have you ever used the legal system to change your legal
                    name. You may have done this after marriage, divorce, or for
                    other reasons.
                  </strong>
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you have never legally changed your
                  name. If that’s correct, you’re ready to continue to the next
                  section. Otherwise, you may add previous legal names below.
                </p>
              }
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Tell us about all previous legal names you have registered with
                local, state, or federal government. You may have changed your
                name due to marriage, divorce, or for other reasons.
              </p>
              <ButtonRow
                style={{ marginBottom: "8px" }}
                left={
                  <Link to={createURL} className="btn secondary">
                    <FaPlus />
                    <span>Add a previous name</span>
                  </Link>
                }
                right={
                  <Link to={nextURL} className="btn primary">
                    <span>Done</span>
                    <FaCheck />
                  </Link>
                }
              />
              {items.map((pn) => {
                return (
                  <ListItem
                    key={pn.id}
                    onEdit={() => onEdit(pn.id)}
                    onDelete={() => onDelete(pn.id)}
                    objectName="seizure"
                  >
                    <div>
                      {formatErrorsFromSchema(PreviousNameFullSchema, pn)}
                      {pn.legalName} used until{" "}
                      {pn.monthChanged && pn.monthChanged.split("-")[0]}
                    </div>
                  </ListItem>
                );
              })}
            </>
          )}
        />
      </Switch>
    </>
  );
}
