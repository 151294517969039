import { LearnMore } from "components/LearnMore";
import React, { ReactNode } from "react";
import { LearnMoreCommon } from "../LearnMoreCommon";

export interface DescriptionProps {
  introText: ReactNode;
  footerText?: ReactNode;
}

export function Description({ introText, footerText }: DescriptionProps) {
  return (
    <div>
      <div>{introText}</div>
      <div>
        {footerText}{" "}
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </div>
    </div>
  );
}
