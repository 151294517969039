import { findIndex } from "lodash";
import * as React from "react";
import { Switch, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import css from "./AnimatedSwitch.module.css";

interface AnimatedSwitchProps {
  children: React.ReactElement[];
  sequence?: string[];
  animateRedirects?: boolean;
}

export function AnimatedSwitch({
  children,
  sequence,
  animateRedirects = true,
}: AnimatedSwitchProps) {
  const location = useLocation();
  const [lastLocation, setLastLocation] = React.useState(location.pathname);
  const [direction, setDirection] = React.useState("right");
  const normalized = React.Children.toArray(children) as React.ReactElement[];

  if (location.pathname !== lastLocation) {
    const lastIndex = sequence
      ? sequence.indexOf(lastLocation)
      : findIndex(normalized, (c) => c.props.path === lastLocation);
    const currentIndex = sequence
      ? sequence.indexOf(location.pathname)
      : findIndex(normalized, (c) => c.props.path === location.pathname);

    let nextDirection;
    if (lastIndex === -1 && !animateRedirects) {
      nextDirection = "none";
    } else {
      nextDirection = currentIndex > lastIndex ? "left" : "right";
    }

    setDirection(nextDirection);
    setLastLocation(location.pathname);
  }

  return (
    <TransitionGroup
      className={css.container}
      childFactory={(child: React.ReactElement) =>
        React.cloneElement(child, { classNames: `slide-${direction}` })
      }
    >
      <CSSTransition
        key={lastLocation}
        classNames={`slide-${direction}`}
        timeout={200}
      >
        <Switch location={location}>{normalized}</Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}
