import { useGetLawFirm, useUpdateLawFirm } from "api/graphql/LawFirm";
import cn from "classnames";
import { ButtonRow } from "components/ButtonRow";
import { Card } from "components/Card";
import { Form } from "components/Form";
import { Loading } from "components/Loading";
import { TextInput } from "components/TextInput";
import { ReferralHelp } from "containers/LawyerPortal/ReferralHelp";
import React from "react";
import { FaPencilAlt } from "react-icons/fa";
import { useCheckMounted } from "utils/hooks";
import { object, string } from "yup";
import css from "./styles.module.css";

export const LawFirmSchema = object({
  name: string().required("Please enter the legal name of this law firm."),
  displayName: string().required(
    "Please enter the trade name you use for your law firm."
  ),
  shortName: string()
    .required("Please enter the token you would like to use for referrals.")
    .matches(
      /[a-z]{8,24}/,
      "You must only use lower case letters for this token.  The overall length must be between 8 & 24 characters."
    ),
}).required();
export type LawFirmFormState = ReturnType<typeof LawFirmSchema.validateSync>;

export function AboutMyFirm() {
  const [editing, setEditing] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const checkMounted = useCheckMounted();
  const title = "About your firm";

  const updateLawFirm = useUpdateLawFirm();
  const { data, loading } = useGetLawFirm();

  const onSubmit = React.useCallback(
    async (formState) => {
      setSaving(true);
      try {
        await updateLawFirm(formState);
        setEditing(false);
      } catch (e) {
        console.error(e);
      } finally {
        if (checkMounted()) {
          setSaving(false);
        }
      }
    },
    [updateLawFirm, data]
  );

  if (loading)
    return (
      <Card title={<h3>{title}</h3>}>
        <Loading />
      </Card>
    );
  const lawFirm = data?.lawFirm;
  const assessReferralLink = `https://${process.env.SITE_DOMAIN}/assess/gather/name/?referralToken=${lawFirm.shortName}`;
  const fastReferralLink = `https://${process.env.SITE_DOMAIN}/fast_registration/?referralToken=${lawFirm.shortName}`;

  return (
    <>
      <Card title={<h3>{title}</h3>}>
        {!editing ? (
          <>
            <div className={css.container}>
              <div className={css.dataContainer}>
                <p>
                  <strong>Legal business name:</strong> {lawFirm.name}
                </p>
                <p>
                  <strong>Trade name:</strong> {lawFirm.displayName}
                </p>
              </div>
              <div>
                <div>
                  <strong>Unknown customers referral link</strong>
                  <div>
                    <a href={`${assessReferralLink}`} target="blank">
                      {assessReferralLink}
                    </a>
                  </div>
                </div>
                <hr />
                <div>
                  <strong>Known customers referral link</strong>
                  <div>
                    <a href={`${fastReferralLink}`} target="blank">
                      {fastReferralLink}
                    </a>
                  </div>
                </div>
                <hr />
                <ReferralHelp />
              </div>
            </div>

            <button
              className={cn("btn primary", css.mt2)}
              onClick={() => setEditing(true)}
            >
              <span>Edit</span>
              <FaPencilAlt />
            </button>
          </>
        ) : (
          <>
            <Form<LawFirmFormState>
              initialValue={data.lawFirm}
              onSubmit={onSubmit}
            >
              <TextInput
                name="name"
                label="Legal firm name"
                maxLength={255}
                autoFocus={true}
              />
              <TextInput
                name="displayName"
                label="Trade name"
                maxLength={255}
              />
              <TextInput
                name="shortName"
                label="Referral Token"
                disabled={true}
              />

              <ButtonRow
                left={
                  <button
                    className="btn"
                    type="button"
                    onClick={() => setEditing(false)}
                  >
                    Cancel
                  </button>
                }
                right={
                  <button
                    className={cn("btn primary", { loading: saving })}
                    type="submit"
                  >
                    Save
                  </button>
                }
              />
            </Form>
          </>
        )}
      </Card>
    </>
  );
}
