import { QueryHookOptions, useMutation, useQuery } from "@apollo/client";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_MAILING_ADDRESS from "./DeleteMailingAddress.gql";
import GET_MAILING_ADDRESS from "./GetMailingAddress.gql";
import LIST_MAILING_ADDRESSES from "./ListMailingAddresses.gql";
import SET_MAILING_ADDRESS from "./SetMailingAddress.gql";
import SET_MAILING_ADDRESS_TO_CURRENT_RESIDENCE from "./SetMailingAddressToCurrentResidence.gql";
import UPSERT_MAILING_ADDRESS from "./UpsertMailingAddress.gql";

const LIST_MAILING_ADDRESS_IDS = gql`
  query ListMailingAddressIds {
    mailingAddresses {
      id
    }
  }
`;

const GET_FILING_PERSON_ID = gql`
  query GetFilingPersonId {
    filingPerson {
      id
    }
  }
`;

export function useListMailingAddresses(
  options?: QueryHookOptions<MailingAddresses, MailingAddressesVariables>
) {
  return useQuery<MailingAddresses>(
    LIST_MAILING_ADDRESSES,
    options || { fetchPolicy: "cache-and-network" }
  );
}

export function useGetMailingAddress(
  options?: QueryHookOptions<MailingAddress, MailingAddressVariables>
) {
  return useQuery<MailingAddress>(GET_MAILING_ADDRESS, options);
}

export function useUpsertMailingAddress<
  Schema extends { mailingAddress: any } = UpsertMailingAddress,
  Variables = UpsertMailingAddressVariables
>(query = UPSERT_MAILING_ADDRESS) {
  return useUpsert<Schema, Variables, ListMailingAddressIds, AddressInput>(
    query,
    LIST_MAILING_ADDRESS_IDS,
    "mailingAddress",
    "mailingAddresses",
    (cache, data) => {
      const id = cache.readQuery<GetFilingPersonId>({
        query: GET_FILING_PERSON_ID,
      })?.filingPerson?.id;

      cache.modify({
        id: cache.identify({ __typename: "FilingPerson", id }),
        fields: {
          mailingAddress: () => data.mailingAddress,
        },
      });
    }
  );
}

export function useDeleteMailingAddress() {
  return useDelete<DeleteMailingAddress, ListMailingAddressIds>(
    DELETE_MAILING_ADDRESS,
    LIST_MAILING_ADDRESS_IDS,
    "deleteMailingAddress",
    "mailingAddresses",
    (cache) => {
      const id = cache.readQuery<GetFilingPersonId>({
        query: GET_FILING_PERSON_ID,
      })?.filingPerson?.id;

      cache.modify({
        id: cache.identify({ __typename: "FilingPerson", id }),
        fields: {
          mailingAddress: () => null,
        },
      });
    }
  );
}

export function useSetMailingAddressToCurrentResidence() {
  return useMutation<
    SetMailingAddressToCurrentResidence,
    SetMailingAddressToCurrentResidenceVariables
  >(SET_MAILING_ADDRESS_TO_CURRENT_RESIDENCE, {
    update: (cache, result) => {
      const mailingAddress = result.data.setMailingAddressToCurrentResidence;
      const id = cache.readQuery<GetFilingPersonId>({
        query: GET_FILING_PERSON_ID,
      })?.filingPerson?.id;

      cache.modify({
        id: cache.identify({ __typename: "FilingPerson", id }),
        fields: {
          mailingAddress: () => mailingAddress,
        },
      });
    },
  });
}

export function useSetMailingAddress() {
  return useMutation<SetMailingAddress, SetMailingAddressVariables>(
    SET_MAILING_ADDRESS,
    {
      update: (cache, result) => {
        const mailingAddress = result.data.setMailingAddress;
        const id = cache.readQuery<GetFilingPersonId>({
          query: GET_FILING_PERSON_ID,
        })?.filingPerson?.id;
        cache.modify({
          id: cache.identify({ __typename: "FilingPerson", id }),
          fields: {
            mailingAddress: () => mailingAddress,
          },
        });
      },
    }
  );
}
