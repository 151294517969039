export const mergeRefs = <T>(...refs: Array<React.Ref<T>>) => (ref: T) => {
  refs
    .filter((x) => x)
    .forEach((resolvableRef) => {
      if (typeof resolvableRef === "function") {
        resolvableRef(ref);
      } else {
        (resolvableRef as any).current = ref;
      }
    });
};
