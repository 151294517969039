import { StatelessEditCreateList } from "components/EditCreateList/stateless";
import { Title } from "components/Title";
import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { useNested } from "utils/hooks";
import { CreateView } from "./CreateView";
import { EditView } from "./EditView";
import { ListView } from "./ListView";
import { QuestionView } from "./QuestionView";

export interface OtherFamilyMembersProps {
  prev?: string;
  next?: string;
}

export function OtherFamilyMembers({ prev, next }: OtherFamilyMembersProps) {
  const { path } = useNested();
  return (
    <>
      <Switch>
        <Route path={path("/query")}>
          <Title>Other Family Members</Title>
          <QuestionView nextURL={next} />
        </Route>
        <Route>
          <StatelessEditCreateList
            ListView={ListView}
            EditView={EditView}
            CreateView={CreateView}
            prev={prev}
            next={next}
          />
        </Route>
      </Switch>
    </>
  );
}
