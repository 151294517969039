import { useEditorBuffer } from "components/EditorState/hooks";
import { WizardRouteChildProps } from "components/Wizard";
import CoownersWizardStep, {
  RegisterOnSaveType,
} from "components/WizardSteps/Coowners";
import React from "react";
export { CoOwnersSchema } from "components/WizardSteps/Coowners";

export default function Coowners(
  props: WizardRouteChildProps & { registerOnSave: RegisterOnSaveType }
) {
  const [{ buffer }] = useEditorBuffer();
  const householdItemId = buffer.id;

  return (
    <CoownersWizardStep
      relatedTypename="HouseholdItem"
      relatedId={householdItemId}
      displayName="household or personal item"
      {...props}
    />
  );
}
