import { useQuery } from "@apollo/client";
import { useDeleteRealEstate } from "api/graphql/RealEstateProperties";
import { ButtonRow } from "components/ButtonRow";
import { ListItem } from "components/ListItem";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import { format, parseISO } from "date-fns";
import React from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import {
  formatErrorsFromSchema,
  formatGraphAddress,
  formatName,
} from "utils/formatters";
import { useNested } from "utils/hooks";
import REAL_ESTATE_MODULE from "./RealEstateModule.gql";
import { RealEstateSchema } from "./RealEstateWizard";

interface Props {
  onEdit: (id: string) => any;
  createURL: string;
  nextURL: string;
}

export function ListView({ createURL, nextURL, onEdit }: Props) {
  const { data, loading } = useQuery<RealEstateModule>(REAL_ESTATE_MODULE);
  const [onDelete] = useDeleteRealEstate();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const items = data.realEstateProperties;

  return (
    <>
      <Title>Real Estate Assets</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              nextURL={nextURL}
              createURL={createURL}
              filingProfileBoolean="hasNoRealEstate"
              addText="Add real estate"
              question={
                <p className="required">
                  <strong>Do you own any real estate?</strong>
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you don’t own any real estate. If
                  that’s correct, you’re ready to continue to the next section.
                  If you do have real estate assets, you may add them below.
                </p>
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <>
                <p>
                  Please include all real estate you own, including properties
                  you are still paying a mortgage on.
                </p>
                <ButtonRow
                  style={{ marginBottom: "8px" }}
                  left={
                    <Link to={createURL} className="btn secondary">
                      <FaPlus />
                      <span>Add real estate</span>
                    </Link>
                  }
                  right={
                    <Link to={nextURL} className="btn primary">
                      <span>Done</span>
                      <FaCheck />
                    </Link>
                  }
                />
              </>

              {items.map((rep) => {
                const mortgages = data.realEstateMortgages.filter(
                  (m) => m.property.id === rep.id
                );
                return (
                  <ListItem
                    key={rep.id}
                    onEdit={() => onEdit(rep.id)}
                    onDelete={() => onDelete(rep.id)}
                    objectName="property"
                    relatedItems={{
                      mortgage: mortgages.map(
                        (m) => `Loan ${m.loanNumber} from ${m.lenderName}`
                      ),
                    }}
                  >
                    {formatErrorsFromSchema(RealEstateSchema, rep)}

                    <div>{formatGraphAddress(rep.address)}</div>

                    <div className="supporting-text">
                      Purchased on{" "}
                      {format(parseISO(rep.purchaseDate), "MM/dd/yyyy")} for $
                      {rep.purchasePrice}
                    </div>

                    <div className="supporting-text">
                      Currently worth ${rep.currentValue}
                    </div>

                    {!!rep.coowners?.length && (
                      <div className="supporting-text">
                        Co-owned with{" "}
                        {rep.coowners
                          .map((coowner) => formatName(coowner.person))
                          .join(", ")}
                      </div>
                    )}
                  </ListItem>
                );
              })}
            </>
          )}
        />
      </Switch>
    </>
  );
}
