import { yupResolver } from "@hookform/resolvers/yup";
import { isArray, isPlainObject } from "lodash";
import React, {
  createContext,
  FormEvent,
  useCallback,
  useContext,
} from "react";
import {
  DeepPartial,
  FormProvider,
  UnpackNestedValue,
  useForm as useDefaultForm,
  UseFormMethods,
} from "react-hook-form";
import {
  ErrorTranslationBackends,
  useBackendErrorTranslation,
} from "utils/hooks";
import { ErrorTranslator } from "utils/hooks/useBackendErrorTranslation";
import { ObjectSchema } from "yup";

export const YupSchemaContext = createContext<ObjectSchema | undefined>(
  undefined
);

const SubmitFormContext = createContext<() => Promise<void>>(async () => {
  // pass
});

export const useSubmitForm = () => {
  return useContext(SubmitFormContext);
};

export interface FormProps<T extends Record<string, unknown>> {
  children: React.ReactNode;
  onSubmit: (
    data: T,
    event: React.FormEvent | null,
    translator: ErrorTranslator
  ) => any;
  useForm?: UseFormMethods<T>;
  initialValue?: UnpackNestedValue<DeepPartial<T>>;
  schema?: ObjectSchema<T>;
  errorTranslator?: ErrorTranslationBackends;
}

export const transformData = (data: any) => {
  const result: any = {};
  Object.entries(data).forEach(([key, value]) => {
    if (value && isPlainObject(value) && !isArray(value)) {
      result[key] = transformData(value);
    } else if (value === "") {
      // Enable setting fields back to None when empty
      result[key] = null;
    } else {
      result[key] = value;
    }
  });
  return result;
};

export function Form<T extends Record<string, unknown>>({
  onSubmit,
  useForm,
  children,
  initialValue,
  schema,
  errorTranslator = ErrorTranslationBackends.GraphQL,
}: FormProps<T>) {
  const methods =
    useForm ||
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDefaultForm({
      defaultValues: initialValue,
      resolver: schema && yupResolver(schema),
    });

  const translateErrors = useBackendErrorTranslation(methods, errorTranslator);

  const wrapOnSubmit = useCallback(
    (callback: (data: any, e: FormEvent, translator: ErrorTranslator) => any) =>
      (data: any, e: FormEvent) => {
        return callback(transformData(data), e, translateErrors);
      },
    [translateErrors]
  );

  const submitHandler = useCallback(
    (e?: FormEvent) => {
      if (onSubmit) {
        e?.stopPropagation();
        return methods.handleSubmit(wrapOnSubmit(onSubmit))(e);
      }
    },
    [methods, wrapOnSubmit, onSubmit]
  );

  return (
    <FormProvider {...methods}>
      <YupSchemaContext.Provider value={schema}>
        <SubmitFormContext.Provider value={submitHandler}>
          <form onSubmit={submitHandler}>{children}</form>
        </SubmitFormContext.Provider>
      </YupSchemaContext.Provider>
    </FormProvider>
  );
}
