import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaUsersCog } from "react-icons/fa";
import { Cofiler } from "./Cofiler";
import CofilerSummary from "./Summary";

const CofilerSubmodule: SubModule = {
  path: "/cofiler",
  icon: FaUsersCog,
  title: "Joint Filing",
  component: Cofiler,
  summary: CofilerSummary,
  statusName: "cofiler",
};

export default CofilerSubmodule;
