import React from "react";

export default function TelecommunicationsLearnMoreCommon() {
  return (
    <>
      <h4>
        What should I do if I have bundled services bill for TV, phone, and
        internet or similar?
      </h4>
      <p>
        Please split the bill across sections and upload the bill(s) into each
        section. Assign fees wherever possible to their section, and assign
        general fees to the largest bill. As an example, if you have a bill
        structured like the following:
      </p>
      <ul>
        <li>
          <strong>Cable TV</strong>: $45.00
        </li>
        <li>
          <strong>Home Internet</strong>: $40.00
        </li>
        <li>
          <strong>Home Phone</strong>: $20.00
        </li>
        <li>
          <strong>Local Phone Service Fee</strong>: $5.00
        </li>
        <li>
          <strong>General Fees</strong>: $3.00
        </li>
        <li>
          <strong>Taxes</strong>: $11.31
        </li>
      </ul>
      <p>
        Create a $59.31 expense for Television. It is the largest expense, so we
        add general fees and taxes to it. Create a Home Internet expense as
        $40.00. Then create a $25.00 expense for your home phone by adding the
        specific local phone service fee to the home phone.
      </p>
    </>
  );
}
