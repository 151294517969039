import { useState, useEffect } from "react";
import { useGetSession } from "api/graphql/Session";
import { isNull } from "lodash";

function Chatwoot() {
  const { data: sessionData } = useGetSession();
  const [chatwootLoaded, setChatwootLoaded] = useState(false);

  useEffect(() => {
    const userEmail = sessionData?.session?.user?.email;
    const userName = `${sessionData?.session?.user?.firstName} ${sessionData?.session?.user?.lastName}`;
    const registrationDate = new Date(
      Date.parse(sessionData?.session?.user?.registrationDate)
    );
    const isLawyer = !isNull(sessionData?.session?.user?.lawFirm);
    const lawFirmShortName = isLawyer
      ? sessionData?.session?.user?.lawFirm?.shortName
      : sessionData?.session?.user?.referralFirm?.shortName;
    if (sessionData && chatwootLoaded) {
      // Set the user identifying information
      window.$chatwoot.setUser(userEmail, {
        email: userEmail,
        name: userName,
      });
      // Set the user custom attributes
      window.$chatwoot.setCustomAttributes({
        // registrationDate: registrationDate.toDateString(),
        lawyer: isLawyer,
        lawFirm: lawFirmShortName,
      });
    }
  }, [sessionData, chatwootLoaded]);

  useEffect(() => {
    window.addEventListener("chatwoot:ready", function () {
      setChatwootLoaded(true);
    });
    (function (d, t) {
      const BASE_URL = "https://app.chatwoot.com";
      const g = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + "/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: process.env.CHATWOOT_PROVIDER_KEY,
          baseUrl: BASE_URL,
        });
      };
    })(document, "script");
  }, []);

  return null;
}

export default Chatwoot;
