import { useUpsertAssetLoss } from "api/graphql/AssetLoss";
import { Wizard } from "components/Wizard";
import React from "react";
import { Item, ItemSchema } from "./Item";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
}

export const AssetLossSchema = ItemSchema;

export function AssetLossWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: Props) {
  const [upsertAssetLoss] = useUpsertAssetLoss();

  return (
    <Wizard
      title={title}
      backURL={backURL}
      nextURL={nextURL}
      initialValue={initialValue}
      vertical
      steps={[
        {
          path: "/item",
          title: "Lost Asset",
          component: Item,
          schema: ItemSchema,
          async onSave(formState) {
            const { data } = await upsertAssetLoss(formState);
            return data.assetLoss;
          },
        },
      ]}
    />
  );
}
