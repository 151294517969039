import { useMutation } from "@apollo/client";
import * as React from "react";
import ADD_DOCUMENT_UPLOAD from "./AddDocumentUpload.gql";
import MARK_NO_DOCUMENTATION from "./MarkNoDocumentation.gql";
import REMOVE_DOCUMENT_UPLOAD from "./RemoveDocumentUpload.gql";

export function useAddDocumentUpload() {
  const [addDocument] = useMutation<
    AddDocumentUpload,
    AddDocumentUploadVariables
  >(ADD_DOCUMENT_UPLOAD, {
    update(cache, { data }) {
      const { documents, __typename, id } = data.addDocument;

      cache.modify({
        id: cache.identify({ __typename, id }),
        fields: {
          documents: () => documents,
        },
      });
    },
  });

  const callback = React.useCallback(
    (variables: AddDocumentUploadVariables) => {
      return addDocument({ variables });
    },
    [addDocument]
  );

  return React.useMemo(() => [callback], [callback]);
}

export function useRemoveDocumentUpload() {
  const [removeDocument] = useMutation<
    RemoveDocumentUpload,
    RemoveDocumentUploadVariables
  >(REMOVE_DOCUMENT_UPLOAD, {
    update(cache, { data }) {
      const { documents, __typename, id } = data.deleteDocument;

      cache.modify({
        id: cache.identify({ __typename, id }),
        fields: {
          documents: () => documents,
        },
      });
    },
  });

  const callback = React.useCallback(
    (variables: RemoveDocumentUploadVariables) => {
      return removeDocument({ variables });
    },
    [removeDocument]
  );

  return React.useMemo(() => [callback], [callback]);
}

export function useMarkNoDocumentation() {
  const [markNoDocumentation] = useMutation<
    MarkNoDocumentation,
    MarkNoDocumentationVariables
  >(MARK_NO_DOCUMENTATION, {
    update(cache, { data }) {
      const {
        documents,
        hasNoSupportingDocumentation,
        __typename,
        id,
      } = data.markNoDocumentation;

      cache.modify({
        id: cache.identify({ __typename, id }),
        fields: {
          hasNoSupportingDocumentation: () => hasNoSupportingDocumentation,
          documents: () => documents,
        },
      });
    },
  });

  const callback = React.useCallback(
    (variables: MarkNoDocumentationVariables) => {
      return markNoDocumentation({ variables });
    },
    [markNoDocumentation]
  );

  return React.useMemo(() => [callback], [callback]);
}
