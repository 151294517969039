import { useMutation, useQuery } from "@apollo/client";
import cn from "classnames";
import { ButtonRow } from "components/ButtonRow";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { OtherPersonSchema } from "components/FormSections/OtherPerson";
import { HiddenInput } from "components/HiddenInput";
import { Loading } from "components/Loading";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { formatErrorsFromSchema, formatName } from "utils/formatters";
import { array, bool, boolean, object } from "yup";
import DELETE_COFILER from "./DeleteCofiler.gql";
import GET_COFILABLE from "./GetCofilable.gql";
import MARK_NO_COFILERS from "./MarkNoCofilers.gql";

export const CofilingPersonSchema = OtherPersonSchema.concat(
  object({
    hasSsnItin: bool().oneOf([true]).required("Cofiler needs an SSN/ITIN"),
    mailingAddress: object().required("Cofiler needs a mailing address"),
  }).required()
);

export const CosignersSchema = object({
  cofilers: array(CofilingPersonSchema)
    .when("hasNoCofilers", {
      is: true,
      then: array().min(0),
      otherwise: array().min(
        1,
        "You haven’t told us if you cofiled with anyone."
      ),
    })
    .defined(),
  hasNoCofilers: boolean().required(),
}).required();

interface CofilersWizardStepProps extends WizardRouteChildProps {
  relatedId: string;
  relatedTypename: string;
  cofilerName?: string;
}

export default function CofilersWizardStep({
  relatedId,
  relatedTypename,
  navProps,
  cofilerName = "cofiler",
}: CofilersWizardStepProps) {
  const history = useHistory();
  const [deleteCofiler] = useMutation<DeleteCofiler, DeleteCofilerVariables>(
    DELETE_COFILER
  );
  const { data, loading } = useQuery<GetCofilable, GetCofilableVariables>(
    GET_COFILABLE,
    {
      variables: { id: relatedId, typename: relatedTypename },
    }
  );
  const [markNoCosigners, { loading: markingNoCofilers }] = useMutation<
    MarkNoCofilers,
    MarkNoCofilersVariables
  >(MARK_NO_COFILERS, {
    variables: { id: relatedId, typename: relatedTypename },
  });

  if (loading) return <Loading />;

  const { cofilers, hasNoCofilers } = data.cofilable;
  return (
    <div>
      <HiddenInput name="cofilers" value={cofilers} />
      <HiddenInput name="hasNoCofilers" value={hasNoCofilers} />
      {!cofilers.length ? (
        hasNoCofilers ? (
          <>
            <p>
              You told us that you didn’t cofile with anyone. If you did this in
              error, you may add {cofilerName}s below.
            </p>
            <Link to="create_cofiler" className="secondary btn">
              <FaPlus />
              <span>Add a {cofilerName}</span>
            </Link>
          </>
        ) : (
          <>
            <p>Did you cofile with anyone?</p>
            <ButtonRow
              left={
                <button
                  type="button"
                  className={cn("btn block", { loading: markingNoCofilers })}
                  onClick={() => markNoCosigners()}
                >
                  No
                </button>
              }
              right={
                <Link to="create_cofiler" className="block btn">
                  Yes
                </Link>
              }
            />
          </>
        )
      ) : (
        <>
          <p>
            If you cofiled with multiple people, add them below. Otherwise,
            you’re ready to continue.
          </p>
          <StatelessList
            createURL="create_cofiler"
            emptyText={`You haven’t added any ${cofilerName}s yet.`}
            items={cofilers}
            deleteItem={({ id }) =>
              deleteCofiler({
                variables: {
                  cofilerId: id,
                  relatedId,
                  relatedTypename,
                },
              })
            }
            addButtonText={`Add a ${cofilerName}`}
            renderItem={(cofiler) => (
              <div>
                {formatErrorsFromSchema(CofilingPersonSchema, cofiler)}
                {formatName(cofiler)}
              </div>
            )}
            onEdit={(id) => history.push(`edit_cofiler/${id}`)}
          />
        </>
      )}

      <WizardNavigation
        {...navProps}
        canContinue={CosignersSchema.isValidSync(data.cofilable)}
      />
    </div>
  );
}
