import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_CASE from "./DeleteBankruptcyCase.gql";
import GET_CASE from "./GetBankruptcyCase.gql";
import LIST_CASE from "./ListBankruptcyCase.gql";
import UPSERT_CASE from "./UpsertBankruptcyCase.gql";

const LIST_CASE_IDS = gql`
  query ListBankruptcyCaseIds {
    bankruptcyCases {
      id
    }
  }
`;

export function useListBankruptcyCase(
  options?: QueryHookOptions<ListBankruptcyCase>
) {
  return useQuery<ListBankruptcyCase>(LIST_CASE, options);
}

export function useGetBankruptcyCase(id: string) {
  return useQuery<GetBankruptcyCase, GetBankruptcyCaseVariables>(GET_CASE, {
    variables: { id },
  });
}

export function useUpsertBankruptcyCase<
  Schema extends { bankruptcyCase: any } = UpsertBankruptcyCase,
  Variables = UpsertBankruptcyCaseVariables
>(query = UPSERT_CASE) {
  return useUpsert<
    Schema,
    Variables,
    ListBankruptcyCaseIds,
    BankruptcyCaseInput
  >(query, LIST_CASE_IDS, "bankruptcyCase", "bankruptcyCases", (cache) =>
    updateFilingProfileCache(cache, "hasNoPreviousBankruptcies", false)
  );
}

export function useDeleteBankruptcyCase() {
  return useDelete<DeleteBankruptcyCase, ListBankruptcyCaseIds>(
    DELETE_CASE,
    LIST_CASE_IDS,
    "deleteBankruptcyCase",
    "bankruptcyCases"
  );
}
