import { QueryHookOptions, useQuery } from "@apollo/client";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_RESIDENTIAL_ADDRESS from "./DeleteResidentialAddress.gql";
import GET_RESIDENTIAL_ADDRESS from "./GetResidentialAddress.gql";
import LIST_RESIDENTIAL_ADDRESS from "./ListResidentialAddress.gql";
import UPSERT_RESIDENTIAL_ADDRESS from "./UpsertResidentialAddress.gql";

const LIST_RESIDENTIAL_ADDRESS_IDS = gql`
  query ListResidentialAddressIds {
    residentialAddresses {
      id
    }
  }
`;

export function useListResidentialAddresses(
  options?: QueryHookOptions<ListResidentialAddress>
) {
  return useQuery<ListResidentialAddress>(LIST_RESIDENTIAL_ADDRESS, options);
}

export function useGetResidentialAddress(id: string) {
  return useQuery<GetResidentialAddress, GetResidentialAddressVariables>(
    GET_RESIDENTIAL_ADDRESS,
    {
      variables: { id },
    }
  );
}

export function useUpsertResidentialAddress<
  Schema extends { residentialAddress: any } = UpsertResidentialAddress,
  Variables = UpsertResidentialAddressVariables
>(query = UPSERT_RESIDENTIAL_ADDRESS) {
  return useUpsert<
    Schema,
    Variables,
    ListResidentialAddressIds,
    ResidentialAddressInput
  >(
    query,
    LIST_RESIDENTIAL_ADDRESS_IDS,
    "residentialAddress",
    "residentialAddresses"
  );
}

export function useDeleteResidentialAddress() {
  return useDelete<DeleteResidentialAddress, ListResidentialAddressIds>(
    DELETE_RESIDENTIAL_ADDRESS,
    LIST_RESIDENTIAL_ADDRESS_IDS,
    "deleteResidentialAddress",
    "residentialAddresses"
  );
}
