import { FarmAssetCategory } from "globalTypes";
import React from "react";
import { GiFullMetalBucketHandle } from "react-icons/gi";
import { CategoryDetail } from "../CategorySection/CategoryDetail";

const CommercialFishingSuppliesDetail: CategoryDetail = {
  category: FarmAssetCategory.CommercialFishingSupplies,
  displayName: "Commercial Fishing Supplies",
  displayLowercaseName: "commercial fishing supplies",
  hasNoProfileBooleanName: "hasNoCommercialFishingSupplies",
  singleInterrogativeText:
    "Tell us about a single type of commercial fishing supplies.",
  noAssetDeclarativeText:
    "You have indicated that you don’t own any commercial fishing supplies.",
  booleanInterrogativeText: (
    <p className="required">
      <strong>
        Do you own any commercial fishing supplies, such as chemical treatments
        or feed?
      </strong>
    </p>
  ),
  declarativeText: (
    <p>
      Please itemize all commercial fishing supplies, chemicals, and feed you
      own.
    </p>
  ),
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add supplies below.",
  urlPathName: "fishing_supplies",
  menuIcon: GiFullMetalBucketHandle,
};

export default CommercialFishingSuppliesDetail;
