import BorrowedAssetWizard from "components/FormModules/Assets/BorrowedAssets/BorrowedAssetWizard";
import React from "react";

export function CreateView({ listURL }: { listURL: string }) {
  return (
    <BorrowedAssetWizard
      title="Add Borrowed Assets"
      backURL={listURL}
      nextURL={listURL}
    />
  );
}
