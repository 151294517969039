import { useGetOtherDebt } from "api/graphql/OtherDebt";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import { OtherDebtWizard } from "./OtherDebtWizard";

interface Props {
  id: string;
  listURL: string;
}

export default function EditView({ id, listURL }: Props) {
  const { data, loading } = useGetOtherDebt(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.otherDebt) {
    return <Redirect to={listURL} />;
  }

  return (
    <OtherDebtWizard
      title="Updating Other Debt"
      backURL={listURL}
      nextURL={listURL}
      initialValue={data.otherDebt}
    />
  );
}
