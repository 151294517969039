import cn from "classnames";
import ModuleContainer from "components/ModuleContainer";
import React, { FC } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { useNested } from "utils/hooks";
import s from "../FormFlow/FormFlow.module.scss";
import { SubMenu, SubMenuItem } from "./FormModuleSubMenu";
import css from "./styles.module.css";

type RequiredSubModuleProps = { prev?: string; next?: string };

export interface SubModuleSummaryProps {
  href: string;
  title: string;
  icon: IconType;
}

type ProgressKey = keyof Omit<GetStatus["progress"], "__typename">;
export type StatusType = Partial<Record<ProgressKey, FullProgress>>;

export interface SubModule<
  ComponentProps extends RequiredSubModuleProps = RequiredSubModuleProps
> {
  /** The path of this submodule, relative to the parent module */
  path: string;
  /** The icon to display in the sub-navigation menu */
  icon: IconType;
  /** The section name to display in the sub-navigation menu */
  title: string;
  /** The main component for the module, displayed when it's active */
  component: FC<ComponentProps>;
  /** A summary for the submodule to be rendered in the parent index */
  summary: FC<SubModuleSummaryProps>;
  statusName: ProgressKey;
  props?: Omit<ComponentProps, "prev" | "next">;
}

export interface SectionIndexProps {
  doneURL: string;
  subModules: SubModule[];
  title: string;
}

interface SubMenuModuleProps<T extends StatusType> {
  subMenuContainerID?: string;
  Index: FC<SectionIndexProps>;
  title: string;
  dashboardURL: string;

  /** The path to redirect to if none of the child modules are matched.
   *
   * Default without index: redirects to the first completed sub-module, or the first sub-module if none completed.
   * Default with index: redirects to the first completed sub-module, or the index if none completed.
   * With value: always redirects to the path provided.
   */
  defaultRedirect?: string;
  subModules: SubModule[];
}

/**
 * A top-level form module which is composed of smaller, child sub-modules.
 *
 * Implements a sub-menu in the main navigation and routing between child modules.
 */
export default function SubMenuModule<Status extends StatusType>({
  subModules,
  subMenuContainerID,
  dashboardURL,
  defaultRedirect,
  Index,
  title,
}: SubMenuModuleProps<Status>) {
  const { path, url } = useNested();

  return (
    <>
      <SubMenu containerID={subMenuContainerID}>
        {subModules.map((m) => (
          <SubMenuItem key={m.path} url={url(m.path)}>
            <m.icon />
            <span>{m.title}</span>
          </SubMenuItem>
        ))}
      </SubMenu>

      <Switch>
        {subModules.map((m, i) => (
          <Route
            key={m.path}
            path={path(m.path)}
            render={() => (
              <ModuleContainer backLinkHref={url("/")} backLinkText={title}>
                <m.component {...m.props} prev={url("/")} next={url("/")} />
              </ModuleContainer>
            )}
          />
        ))}

        <Route
          exact
          path={path("/")}
          render={() => (
            <div className={css.index}>
              <Link
                to={dashboardURL}
                className={cn(s.dashboardLink, s.mobileDashboardLink)}
              >
                <FaChevronLeft />
                Dashboard
              </Link>
              <Index
                doneURL={dashboardURL}
                subModules={subModules}
                title={title}
              />
            </div>
          )}
        />

        {/* Default redirect */}
        <Redirect to={url(defaultRedirect || "/")} />
      </Switch>
    </>
  );
}
