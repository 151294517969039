import { useGetFinancialInvestment } from "api/graphql/FinancialInvestment";
import { FinancialInvestmentWizard } from "components/FormModules/Assets/FinancialInvestments/FinancialInvestmentWizard";
import { financialInvestmentTypeDetails } from "components/FormModules/Assets/FinancialInvestments/utils";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import { formatTitleCaseString } from "utils/formatters";

interface Props {
  id: string;
  listURL: string;
}

export default function EditView(props: Props) {
  const { data, loading } = useGetFinancialInvestment(props.id);

  if (loading) {
    return <Loading />;
  } else if (!data?.financialInvestment) {
    return <Redirect to={props.listURL} />;
  }

  const investmentType = data.financialInvestment.investmentType;

  return (
    <FinancialInvestmentWizard
      investmentType={investmentType}
      title={`Updating ${formatTitleCaseString(
        financialInvestmentTypeDetails[investmentType].displayName
      )}`}
      backURL={props.listURL}
      nextURL={props.listURL}
      initialValue={data.financialInvestment}
    />
  );
}
