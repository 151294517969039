import { useGetFinancialAccount } from "api/graphql/FinancialAccount";
import { FinancialAccountWizard } from "components/FormModules/Assets/FinancialAccounts/FinancialAccountWizard";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import { formatFinancialAccountType } from "utils/formatters";

interface Props {
  id: string;
  listURL: string;
}

export default function EditView(props: Props) {
  const { data, loading } = useGetFinancialAccount(props.id);

  if (loading) {
    return <Loading />;
  } else if (!data?.financialAccount) {
    return <Redirect to={props.listURL} />;
  }

  return (
    <FinancialAccountWizard
      accountType={data.financialAccount.accountType}
      title={`Updating ${formatFinancialAccountType(
        data.financialAccount.accountType
      )} Account`}
      backURL={props.listURL}
      nextURL={props.listURL}
      initialValue={data.financialAccount}
    />
  );
}
