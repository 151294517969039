import { useQuery } from "@apollo/client";
import { useDeleteRetirementAccountLoan } from "api/graphql/RetirementAccountLoan";
import { ListViewProps } from "components/EditCreateList/stateless";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema, formatQuantity } from "utils/formatters";
import { useNested } from "utils/hooks";
import RETIREMENT_ACCOUNT_LOANS_MODULE from "./RetirementAccountLoansModule.gql";
import { RetirementAccountLoanSchema } from "./RetirementAccountLoanWizard";

export default function ListView(props: ListViewProps) {
  const { data, loading } = useQuery<RetirementAccountLoansModule>(
    RETIREMENT_ACCOUNT_LOANS_MODULE
  );
  const [onDelete] = useDeleteRetirementAccountLoan();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { retirementAccountLoans: items } = data;

  return (
    <>
      <Title>Your Retirement Account Loans</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoRetirementAccountLoans"
              createURL={props.createURL}
              nextURL={props.nextURL}
              addText="Add a loan"
              question={
                <p className="required">
                  <strong>
                    Do you have any loans on your 401(k) or other retirement
                    accounts?
                  </strong>
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you don&rsquo;t have any loans for any
                  of your 401(k)/retirement accounts. If that&rsquo;s correct,
                  you&rsquo;re ready to continue to the next section. Otherwise,
                  you may add loans below.
                </p>
              }
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                In order to file your bankruptcy, we need to know about all your
                outstanding loans on 401(k) and other retirement accounts.
              </p>
              <StatelessList
                {...props}
                emptyText="You haven’t added any retirement account loans yet."
                addButtonText="Add a retirement account loan"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(l) => (
                  <div>
                    {formatErrorsFromSchema(RetirementAccountLoanSchema, l)}
                    <strong>
                      Loan on account #{l.accountNumber} from {l.lenderName}
                    </strong>
                    {!!l.collectors.length && (
                      <div className="supporting-text">
                        {formatQuantity(l.collectors.length, " debt collector")}
                      </div>
                    )}
                  </div>
                )}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
