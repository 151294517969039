import { useQuery } from "@apollo/client";
import { useDeleteVehicleLease } from "api/graphql/VehicleLease";
import { ListViewProps } from "components/EditCreateList/stateless";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  formatErrorsFromSchema,
  formatName,
  formatOxfordComma,
  formatQuantity,
  formatVehicleName,
} from "utils/formatters";
import { useNested } from "utils/hooks";
import VEHICLE_LEASES_MODULE from "./VehicleLeasesModule.gql";
import { VehicleLeaseSchema } from "./VehicleLeaseWizard";

export default function ListView(props: ListViewProps) {
  const { data, loading } = useQuery<VehicleLeasesModule>(
    VEHICLE_LEASES_MODULE
  );
  const [onDelete] = useDeleteVehicleLease();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { vehicleLeases: items } = data;

  return (
    <>
      <Title>Your Vehicle Leases</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoVehicleLeases"
              createURL={props.createURL}
              nextURL={props.nextURL}
              addText="Add a vehicle lease"
              question={
                <p className="required">
                  <strong>Do you have any vehicle leases?</strong>
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you don&rsquo;t have any leased
                  vehicles. If that&rsquo;s correct, you&rsquo;re ready to
                  continue to the next section. Otherwise, you may add leases
                  below.
                </p>
              }
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                In order to file your bankruptcy, we need to know about all your
                outstanding leases for your vehicles, including any you have
                cosigned.
              </p>
              <StatelessList
                {...props}
                emptyText="You haven’t added any vehicle leases yet."
                addButtonText="Add a vehicle lease"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(l) => (
                  <div>
                    {formatErrorsFromSchema(VehicleLeaseSchema, l)}
                    <strong>
                      Vehicle lease {l.accountNumber} from {l.leaseholderName}{" "}
                      for your {formatVehicleName(l.vehicle)}
                    </strong>
                    {!!l.cosigners.length && (
                      <div className="supporting-text">
                        Cosigned with{" "}
                        {formatOxfordComma(...l.cosigners.map(formatName))}
                      </div>
                    )}
                    {!!l.collectors.length && (
                      <div className="supporting-text">
                        {formatQuantity(l.collectors.length, " debt collector")}
                      </div>
                    )}
                  </div>
                )}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
