import sha1 from "js-sha1";

/**
 * Returns the sha1 hex digest of a file
 * @param file - a file object
 */
export async function hashFile(file: File) {
  const reader = new FileReader();
  const blob = await new Promise<ArrayBuffer>((resolve) => {
    reader.onload = (e) => resolve(e.target.result as ArrayBuffer);
    reader.readAsArrayBuffer(file);
  });
  return sha1(blob);
}

/**
 * Simple hash of a string. Always returns a value between 0 and 2^32.
 */
export const hashString = (str: string) => {
  let hash = 5381,
    i = str.length;

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0;
};
