import { CreateViewProps } from "components/EditCreateList/stateless";
import { useEditorBuffer } from "components/EditorState/hooks";
import React from "react";
import { ConsultationPaymentForm } from "./ConsultationPaymentForm";

export function CreateView({ listURL }: CreateViewProps) {
  const [{ buffer }] = useEditorBuffer();

  return (
    <>
      <h3>Add a payment to {buffer.consultantName}</h3>
      <ConsultationPaymentForm
        consultationId={buffer.id}
        nextURL={listURL}
        backURL={listURL}
        abortURL={listURL}
        saveText="Add payment"
      />
    </>
  );
}
