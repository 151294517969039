import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary from "components/ModuleSummary";
import React from "react";
import CREDIT_COUNSELING_MODULE from "./CreditCounselingModule.gql";

export default function CreditCounselingSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<CreditCounselingModule>(CREDIT_COUNSELING_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress?.creditCounseling} />
  );
}
