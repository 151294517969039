import { useUpsertOwnedBusiness } from "api/graphql/OwnedBusiness";
import DollarsInput from "components/DollarsInput";
import { Form } from "components/Form";
import { BusinessSummarySchema } from "components/FormModules/OwnedBusinesses/validations";
import { DecimalInput } from "components/NumberInput";
import { TextInput } from "components/TextInput";
import { WizardNavigation } from "components/WizardNavigation";
import { omit } from "lodash";
import React from "react";

export default function OwnedBusinessForm({
  backURL,
  onDone,
  initialValue,
}: {
  backURL?: string;
  onDone: (business: UpsertOwnedBusiness["ownedBusiness"]) => any;
  initialValue?: Partial<UpsertOwnedBusiness["ownedBusiness"]>;
}) {
  const [upsert] = useUpsertOwnedBusiness();

  return (
    <Form
      onSubmit={async (formValues) => {
        const { data } = await upsert({
          ...omit(initialValue, "personalSalaries"),
          ...formValues,
        });
        onDone(data.ownedBusiness);
      }}
      schema={BusinessSummarySchema}
      initialValue={initialValue}
    >
      <TextInput
        name="businessName"
        label="Business name"
        helpText="If the business has no official name, enter “self-employment”."
        autoFocus={!initialValue}
      />

      <DecimalInput
        name="percentOwnership"
        label="How much of the business do you own?"
        helpText="If self-employed or the sole owner, enter “100%”."
        addOnAfter="%"
        integerLimit={3}
        allowDecimal
        decimalLimit={4}
      />

      <div className="form-row">
        <DollarsInput
          label="Revenue in the last 12 months"
          name="lastTwelveMonthsGrossIncome"
        />
        <DollarsInput
          label="Expected future monthly revenue"
          name="expectedGrossMonthlyIncome"
        />
      </div>

      <WizardNavigation backURL={backURL} isComplete />
    </Form>
  );
}
