import { useQuery } from "@apollo/client";
import { useDeleteMonthlyExpense } from "api/graphql/MonthlyExpense";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema, formatQuantity } from "utils/formatters";
import { useNested } from "utils/hooks";
import EXPENSES_MODULE from "../ExpensesModule.gql";
import { MonthlyExpenseSchema } from "../MonthlyExpenseWizard";
import { ExpensesCategoryDetail } from "./CategoryDetail";

interface Props {
  createURL: string;
  nextURL: string;
  prevURL: string;
  onEdit: (id: string) => any;
  categoryDetail: ExpensesCategoryDetail;
}

export default function ListView(props: Props) {
  const { data } = useQuery<ExpensesModule, ExpensesModuleVariables>(
    EXPENSES_MODULE,
    {
      variables: { category: props.categoryDetail.category },
    }
  );
  const [onDelete] = useDeleteMonthlyExpense();
  const { path, url } = useNested();

  if (!data) return <Loading />;

  const { monthlyExpenses: items } = data;
  const {
    displayName,
    hasNoProfileBooleanName,
    displayLowercaseName,
    description,
  } = props.categoryDetail;

  return (
    <>
      <Title>Your {displayName} Expenses</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean={hasNoProfileBooleanName}
              createURL={props.createURL}
              nextURL={props.nextURL}
              addText="Add an expense"
              question={
                <>
                  <p className="required">
                    <strong>
                      Have you had any {displayLowercaseName} expenses for you
                      or members of your household in the last six months?
                    </strong>
                  </p>
                  {description}
                </>
              }
              confirmation={
                <>
                  <p>
                    You indicated that you didn&rsquo;t have any{" "}
                    {displayLowercaseName} expenses for your household in the
                    last six months.
                  </p>
                  <p>
                    If that&rsquo;s correct, you&rsquo;re ready to continue to
                    the next section. Otherwise, you may add expenses below.
                  </p>
                  {description}
                </>
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Tell us about all of your spending on {displayLowercaseName}{" "}
                expenses over the last six months. Be sure to include spending
                for all household members.
              </p>
              {description}

              <StatelessList
                {...props}
                emptyText="You haven’t added any expenses yet."
                addButtonText="Add an expense"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(expense) => (
                  <div>
                    {formatErrorsFromSchema(MonthlyExpenseSchema, expense)}
                    <strong>
                      Monthly {props.categoryDetail.displayLowercaseName}{" "}
                      expense of ${expense.amount}
                    </strong>
                    <div className="supporting-text">{expense.description}</div>
                    {!expense.hasNoSupportingDocumentation && (
                      <div className="supporting-text">
                        {formatQuantity(expense.documents.length, " document")}
                      </div>
                    )}
                  </div>
                )}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
