import { BooleanInput } from "components/BooleanInput";
import { DateInput } from "components/DateInput";
import { AddressForm, AddressSchema } from "components/FormSections/Address";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { DateSchema } from "utils/validators/yup";
import { boolean, object, string } from "yup";

export const NoticeSchema = object({
  wasReceived: boolean().required(
    "Please indicate if you sent or received this notice."
  ),
  governmentalUnitName: string().required(
    "Please provide the name of the agency."
  ),
  noticeDate: DateSchema.required(
    "Please provide the date the notice was issued or sent."
  ),
  siteAddress: AddressSchema.required(),
  lawName: string().nullable(),
}).required();

export function Notice({ isEdit, navProps }: WizardRouteChildProps) {
  const form = useFormContext();
  const wasReceived = form.watch("wasReceived");

  return (
    <>
      <div className="form-row">
        <BooleanInput
          name="wasReceived"
          label="Did you recieve or send this notice?"
          yesLabel="I received this notice"
          noLabel="I sent this notice"
          autoFocus={!isEdit}
        />
      </div>
      <div className="bottom form-row">
        <TextInput
          name="governmentalUnitName"
          label={`${
            wasReceived === false ? "Receiving" : "Issuing"
          } agency or department name`}
          maxLength={128}
          width="200px"
        />
        <DateInput
          name="noticeDate"
          label={`When was this notice ${
            wasReceived === false ? "sent" : "issued"
          }?`}
        />
        <TextInput
          name="lawName"
          label="Name of environmental law the notice relates to (if you know it)"
        />
      </div>
      <p>Where is this site located?</p>
      <AddressForm name="siteAddress" graphQL />
      <WizardNavigation {...navProps} />
    </>
  );
}
