import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_DEBT from "./DeleteTaxDebt.gql";
import GET_DEBT from "./GetTaxDebt.gql";
import LIST_DEBT from "./ListTaxDebt.gql";
import UPSERT_DEBT from "./UpsertTaxDebt.gql";

const LIST_DEBT_IDS = gql`
  query ListTaxDebtIds {
    taxDebts {
      id
    }
  }
`;

export function useListTaxDebt(options?: QueryHookOptions<ListTaxDebt>) {
  return useQuery<ListTaxDebt>(LIST_DEBT, options);
}

export function useGetTaxDebt(id: string) {
  return useQuery<GetTaxDebt, GetTaxDebtVariables>(GET_DEBT, {
    variables: { id },
  });
}

export function useUpsertTaxDebt<
  Schema extends { taxDebt: any } = UpsertTaxDebt,
  Variables = UpsertTaxDebtVariables
>(query = UPSERT_DEBT) {
  return useUpsert<Schema, Variables, ListTaxDebtIds, TaxDebtInput>(
    query,
    LIST_DEBT_IDS,
    "taxDebt",
    "taxDebts",
    (cache) => updateFilingProfileCache(cache, "hasNoTaxDebts", false)
  );
}

export function useDeleteTaxDebt() {
  return useDelete<DeleteTaxDebt, ListTaxDebtIds>(
    DELETE_DEBT,
    LIST_DEBT_IDS,
    "deleteTaxDebt",
    "taxDebts"
  );
}
