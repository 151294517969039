import { BooleanInput } from "components/BooleanInput";
import { DateInput } from "components/DateInput";
import VehicleForm, { VehicleSchema } from "components/FormSections/Vehicle";
import { LearnMore } from "components/LearnMore";
import { DecimalInput } from "components/NumberInput";
import { WantToKeepLearnMore } from "components/WantToKeepLearnMore";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { DateSchema, DecimalSchema } from "utils/validators/yup";
import { mixed, object } from "yup";

export const OwnedVehicleSchema = object({
  vehicle: VehicleSchema.required(),
  purchaseDate: DateSchema.dateNotFuture(
    "Purchase date can’t be in the future"
  ).required("Please enter the date you purchased this vehicle."),
  currentValue: DecimalSchema.required(
    "Please enter the amount this vehicle is worth, e.g. the Blue Book value."
  ),
  wantToKeep: mixed<boolean | null>().required("This field is required."),
}).required();

export default function Vehicle({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <p>What type of vehicle is this?</p>
      <VehicleForm autoFocus={!isEdit} />

      <div className="form-row">
        <DateInput
          name="purchaseDate"
          label="When did you purchase it?"
          inputProps={{
            "data-testid": "purchase_date",
          }}
        />

        <DecimalInput
          name="currentValue"
          label="Current Value"
          width="180px"
          data-testid="current_value"
          addOnBefore="$"
          includeThousandsSeparator
          allowDecimal
          learnMore={
            <LearnMore>
              <h4>How should I determine what my vehicle is worth?</h4>
              <p>
                The standard method for valuing a vehicle in bankruptcy is the
                retail replacement value. The retail replacement value is what
                you would pay for a similar vehicle in a similar condition at
                the date you file your bankruptcy case.
              </p>
              <p>
                The simplest way to obtain this valuation is to visit&nbsp;
                <a href="https://www.kbb.com/">Kelley Blue Book</a> and see how
                much it would cost to buy your vehicle of the same make, model,
                year, and condition.
              </p>
              <h4>What if my vehicle is not listed on Kelley Blue Book?</h4>
              <p>
                You can review for sale offerings of similar vehicles in your
                newspaper or in other ads and come up with a comparable price
                based on the make, model, year, and condition.
              </p>
            </LearnMore>
          }
        />
      </div>
      <div className="form-row">
        <BooleanInput
          name="wantToKeep"
          label="Do you want to keep this vehicle after your bankruptcy?"
          learnMore={<WantToKeepLearnMore />}
        />
      </div>

      <WizardNavigation {...navProps} />
    </>
  );
}
