import { DateInput } from "components/DateInput";
import { LearnMore } from "components/LearnMore";
import { DecimalInput } from "components/NumberInput";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { DateSchema, DecimalSchema } from "utils/validators/yup";
import { object, string } from "yup";

export const ItemSchema = object({
  itemDescription: string().required(
    "Please describe the item or asset that was sold."
  ),
  saleDate: DateSchema.required(
    "Please indicate what month this item or asset was sold."
  ),
  saleDescription: string().nullable(),
  saleAmount: DecimalSchema.required("This field is required.").test(
    "min",
    "Must be greater than zero.",
    (value: string) => value && Number(value.replace(/,/g, "")) > 0
  ),
}).required();

export function Item({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <p>Describe the asset or item that you sold or transferred.</p>
      <div className="form-row">
        <TextInput
          name="itemDescription"
          label="Description"
          placeholder={"Describe the item that was sold."}
          autoFocus
        />
      </div>
      <div className="form-row">
        <DecimalInput
          name="saleAmount"
          label="Sale amount"
          addOnBefore="$"
          includeThousandsSeparator
          allowDecimal
          learnMore={
            <LearnMore>
              <div>
                <p>
                  <strong>
                    What if the item was transferred and not sold?
                  </strong>
                </p>
                <p>Put down the value of the item transferred.</p>
              </div>
            </LearnMore>
          }
        />
        <DateInput
          name="saleDate"
          label="Date of sale"
          width="175px"
          learnMore={
            <LearnMore>
              <div>
                <p>
                  <strong>
                    What if the item was transferred and not sold?
                  </strong>
                </p>
                <p>Put down the date the item was transferred.</p>
              </div>
            </LearnMore>
          }
        />
      </div>
      <div className="form-row">
        <TextArea
          name="saleDescription"
          label="What was done with the money from the sale?"
        />
        <p className="small">
          Leave this blank if the item was transferred and not sold.
        </p>
      </div>
      <WizardNavigation {...navProps} />
    </>
  );
}
