import { useListRoot } from "components/EditCreateList/hooks";
import { OptionCard, OptionsList } from "components/OptionCard";
import { Title } from "components/Title";
import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { useNested } from "utils/hooks";
import { updateQueryParams } from "utils/urls";
import { OtherIncomeWizard } from "./OtherIncomeWizard";
import { incomeTypes } from "./utils";

export function CreateView({ listURL }: { listURL: string }) {
  const { url, rootPath, path } = useNested();
  const { url: rootURL } = useListRoot();

  return (
    <Switch>
      <Route
        exact
        path={rootPath}
        render={() => (
          <>
            <Title>Add Income</Title>
            <p>What type of income is this?</p>
            <OptionsList>
              {incomeTypes.map(({ label, value, icon }) => (
                <OptionCard
                  key={value}
                  Icon={icon}
                  href={updateQueryParams(url("new"), {
                    type: value,
                  })}
                >
                  {label}
                </OptionCard>
              ))}
            </OptionsList>
          </>
        )}
      />
      <Route
        path={path("new")}
        render={() => (
          <OtherIncomeWizard
            title="Add income"
            backURL={listURL}
            nextURL={listURL}
          />
        )}
      />
    </Switch>
  );
}
