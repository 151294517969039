import { OptionCard } from "components/OptionCard";
import { FaComments, FaEnvelope, FaUserClock } from "react-icons/fa";

export const EMAIL_LINK = "mailto:support@lexria.com";

export function EmailLink() {
  return (
    <OptionCard Icon={FaEnvelope} href={EMAIL_LINK}>
      Email us
    </OptionCard>
  );
}
