import { startCase, toLower } from "lodash";
import { CamelToSnake } from "utils/types";

function getDefaultPlural(singular: string) {
  if (singular.endsWith("y")) {
    return `${singular.replace(/y$/, "ies")}`;
  }
  if (singular.endsWith("x")) {
    return `${singular}es`;
  }
  if (singular.endsWith("s")) {
    return `${singular}es`;
  }
  return `${singular}s`;
}

export const formatPlural = (
  count: number,
  noun: string,
  plural = getDefaultPlural(noun)
) => (count === 1 ? noun : plural);

export const formatQuantity = (count: number, noun: string, plural?: string) =>
  `${count}${formatPlural(count, noun, plural)}`;

export const formatIndefinite = (noun: string) =>
  "aeiou".includes((noun[0] || "").toLowerCase()) ? `an ${noun}` : `a ${noun}`;

/** Formats a list of strings in English, using the [Oxford comma](https://en.wikipedia.org/wiki/Serial_comma). */
export function formatOxfordComma(...items: (string | null | undefined)[]) {
  const filtered = items.filter((x) => x);
  if (filtered.length === 1) return filtered[0];
  return filtered
    .map((item, index) =>
      index === filtered.length - 1 ? `and ${item}` : item
    )
    .join(filtered.length > 2 ? ", " : " ");
}

export function formatTitleCaseString(input: string) {
  return startCase(toLower(input));
}

export function formatUrlString(input: string) {
  return input.toLowerCase().replace(/\s+/g, "_");
}

export interface FormatCamelCaseToSnakeOptions<Separator extends string = "_"> {
  separator?: Separator;
  keepSpecialCharacters?: boolean;
}

export function formatCamelCaseToSnake<
  S extends string,
  Separator extends string = "_"
>(
  input: S,
  options?: FormatCamelCaseToSnakeOptions<Separator>
): CamelToSnake<S, Separator> {
  const separator = options?.separator || "_";
  const keepSpecialCharacters = !!options?.keepSpecialCharacters;

  let formatted: string = input;
  if (!keepSpecialCharacters) {
    formatted = formatted.replace(/[^a-zA-Z0-9]/g, separator);
  }
  formatted = formatted
    .replace(/([a-z])([A-Z0-9])/g, `$1${separator}$2`)
    .toLowerCase();

  return formatted as CamelToSnake<S, Separator>;
}
