import { useQuery } from "@apollo/client";
import { useDeleteResidentialAddress } from "api/graphql/ResidentialAddress";
import cn from "classnames";
import { ButtonRow } from "components/ButtonRow";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import { updateQueryParams } from "utils/urls";
import { ListHeaderDescription } from "./ListHeaderDescription";
import MailingAddressQuestionWizard from "./MailingAddressQuestionWizard";
import ResidenceListItem from "./ResidenceListItem";
import RESIDENTIAL_ADDRESSES_MODULE from "./ResidentialAddressesModule.gql";
import { isComplete, sortAddresses } from "./utils";

interface Props {
  onEdit: (id: string) => any;
  createURL: string;
  prevURL: string;
  nextURL: string;
  requiredFrom: Date;
}

export default function ListView({
  requiredFrom,
  createURL,
  onEdit,
  nextURL,
  prevURL,
}: Props) {
  const { data } = useQuery<ResidentialAddressesModule>(
    RESIDENTIAL_ADDRESSES_MODULE
  );
  const history = useHistory();
  const [onDelete] = useDeleteResidentialAddress();
  const { path, url } = useNested();

  if (!data) return <Loading />;
  const {
    residentialAddresses: items,
    filingPerson: { mailingAddress },
  } = data;

  const sortedAddresses = sortAddresses(items);

  const onCreate = (index: number) => {
    history.push(`${updateQueryParams(createURL, { index })}`);
  };
  const readyToContinue = isComplete(items, requiredFrom);

  return (
    <div>
      <Title>Your Residential History</Title>
      <ListHeaderDescription requiredFrom={requiredFrom} />
      <Switch>
        <Route
          path={path("/use-current-mailing-address")}
          render={() => (
            <MailingAddressQuestionWizard
              backURL={prevURL}
              mailingAddress={mailingAddress}
              requiredFrom={requiredFrom}
              createURL={updateQueryParams(createURL, { index: 0 })}
            />
          )}
        />
        {!items.length && mailingAddress && (
          <Redirect to={url("/use-current-mailing-address")} />
        )}
        <Route
          path={path("/")}
          render={() => (
            <>
              {sortedAddresses.map((item, index) => {
                return (
                  <ResidenceListItem
                    key={item.id}
                    items={sortedAddresses}
                    item={item}
                    index={index}
                    requiredFrom={requiredFrom}
                    onCreate={onCreate}
                    onDelete={() => onDelete(item.id)}
                    onEdit={() => {
                      onEdit(item.id);
                    }}
                  />
                );
              })}

              <ButtonRow
                reverse
                left={
                  prevURL && (
                    <Link to={prevURL} className="btn">
                      <FaArrowLeft />
                      <span>Back</span>
                    </Link>
                  )
                }
                right={
                  nextURL && items.length ? (
                    <Link
                      to={nextURL}
                      className={cn("btn", "primary", {
                        disabled: !readyToContinue,
                      })}
                    >
                      <span>Continue</span> <FaArrowRight />
                    </Link>
                  ) : (
                    <button className="btn primary" onClick={() => onCreate(0)}>
                      <span>Get started</span> <FaArrowRight />
                    </button>
                  )
                }
              />
            </>
          )}
        />
      </Switch>
    </div>
  );
}
