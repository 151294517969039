import { useImportMxMember, useRemoveMxMember } from "api/graphql/MxMember";
import cn from "classnames";
import { ListItem } from "components/ListItem";
import { Loading } from "components/Loading";
import { MxConnectionStatus } from "globalTypes";
import { concat } from "lodash";
import { useState } from "react";
import { FaWrench } from "react-icons/fa";
import { formatFinancialAccountType, formatQuantity } from "utils/formatters";
import css from "./ImportView.module.css";

export default function MxMemberListItem({
  member,
  onReconnect,
}: {
  member: ArrayItemType<ListMxMembers["mxMembers"]>;
  onReconnect: (memberGUID: string) => any;
}) {
  const {
    connectionStatus,
    institution,
    financialAccounts,
    creditCardDebts,
    vehicleLoans,
  } = member;
  const accounts = concat<any[]>(
    financialAccounts,
    creditCardDebts,
    vehicleLoans
  );
  const [disconnect, { loading: disconnecting }] = useRemoveMxMember();
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [importError, setImportError] = useState(false);

  const waitingForAccounts =
    member.connectionStatus === MxConnectionStatus.Connected &&
    !accounts.length;

  return (
    <ListItem
      key={member.id}
      className={css.listItem}
      onDelete={() => disconnect({ variables: { guid: member.id } })}
      objectName="connection"
      relatedItems={{
        "financial account": financialAccounts.map(
          (a) =>
            `${formatFinancialAccountType(a.accountType)} account ${
              a.accountNumber
            }`
        ),
        "credit card": creditCardDebts.map(
          (c) => `Credit card ${c.accountNumber}`
        ),
        "vehicle loan": vehicleLoans.map(
          (l) => `Vehicle loan ${l.accountNumber}`
        ),
      }}
      otherButtons={
        <>
          {connectionStatus !== "CONNECTED" && (
            <button
              className={cn("btn always-show-text flat", { loading })}
              onClick={async () => {
                setLoading(true);
                await onReconnect(member.id);
                setLoading(false);
              }}
              title="Fix connection"
            >
              <span>Fix connection</span>
              <FaWrench />
            </button>
          )}
        </>
      }
    >
      <div className={css.listItem__content}>
        <img
          src={institution.logo}
          alt={`${institution.name} logo`}
          className={css.institutionLogo}
        />

        <div style={{ flexGrow: 1 }}>
          <h4 style={{ margin: 0 }}>{institution.name}</h4>
          {!!financialAccounts.length && (
            <div>
              {formatQuantity(financialAccounts.length, " financial account")}
            </div>
          )}
          {!!creditCardDebts.length && (
            <div>{formatQuantity(creditCardDebts.length, " credit card")}</div>
          )}
          {!!vehicleLoans.length && (
            <div>{formatQuantity(vehicleLoans.length, " vehicle loan")}</div>
          )}
          {waitingForAccounts && !failed && (
            <>
              <div className={cn("supporting-text", css.importLoader)}>
                <Loading
                  className={css.importLoading}
                  style={{ minHeight: 0 }}
                />
                <span>Importing</span>
              </div>
              {importError && (
                <div className="errors">
                  We&rsquo;re having trouble importing this account.
                  <br /> Please check back later.
                </div>
              )}
            </>
          )}
        </div>

        {failed && (
          <div className="supporting-text" style={{ marginTop: "4px" }}>
            We were unable to import any accounts from this institution. You can
            add these accounts manually later.
          </div>
        )}
      </div>
    </ListItem>
  );
}
