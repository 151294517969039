import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import { LoanType } from "globalTypes";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import STUDENT_LOANS_MODULE from "./StudentLoansModule.gql";

export default function StudentLoansSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<StudentLoansModule>(STUDENT_LOANS_MODULE, {
    returnPartialData: true,
  });

  const privateLoans = data?.studentLoans?.filter(
    (l) => l._type === LoanType.Private
  );
  const publicLoans = data?.studentLoans?.filter(
    (l) => l._type === LoanType.Public
  );

  return (
    <ModuleSummary {...props} progress={data?.progress.studentLoans}>
      {data?.filingProfile?.hasNoStudentLoans ? (
        <HasNoSummary>You don&rsquo;t have any student loans.</HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="public loan"
            maxValueLength={2}
            value={publicLoans?.length}
            pluralizeLabel
          />
          <NumericSummary
            label="private loan"
            maxValueLength={2}
            value={privateLoans?.length}
            pluralizeLabel
          />

          <NumericSummary
            label="total borrowed"
            valuePrefix="$"
            maxValueLength={6}
            value={fancyNumberSum(data?.studentLoans, "disbursedAmount")}
          />
          <NumericSummary
            label="total balance"
            valuePrefix="$"
            maxValueLength={6}
            value={fancyNumberSum(
              data?.studentLoans,
              "outstandingPrincipal",
              "outstandingInterest"
            )}
          />
          <NumericSummary
            label="monthly payments"
            valuePrefix="$"
            maxValueLength={4}
            value={fancyNumberSum(data?.studentLoans, "monthlyPayment")}
          />
          <NumericSummary
            label="overdue"
            valuePrefix="$"
            maxValueLength={4}
            value={fancyNumberSum(data?.studentLoans, "delinquentAmount")}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
