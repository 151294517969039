import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_SEIZURE from "./DeleteSeizure.gql";
import GET_SEIZURE from "./GetSeizure.gql";
import LIST_SEIZURE from "./ListSeizure.gql";
import UPSERT_SEIZURE from "./UpsertSeizure.gql";

const LIST_SEIZURE_IDS = gql`
  query ListSeizureIds {
    seizures {
      id
    }
  }
`;

export function useListSeizure(options?: QueryHookOptions<ListSeizure>) {
  return useQuery<ListSeizure>(LIST_SEIZURE, options);
}

export function useGetSeizure(id: string) {
  return useQuery<GetSeizure, GetSeizureVariables>(GET_SEIZURE, {
    variables: { id },
  });
}

export function useUpsertSeizure<
  Schema extends { seizure: any } = UpsertSeizure,
  Variables = UpsertSeizureVariables
>(query = UPSERT_SEIZURE) {
  return useUpsert<Schema, Variables, ListSeizureIds, SeizureInput>(
    query,
    LIST_SEIZURE_IDS,
    "seizure",
    "seizures",
    (cache) => updateFilingProfileCache(cache, "hasNoSeizures", false)
  );
}

export function useDeleteSeizure() {
  return useDelete<DeleteSeizure, ListSeizureIds>(
    DELETE_SEIZURE,
    LIST_SEIZURE_IDS,
    "deleteSeizure",
    "seizures"
  );
}
