import * as React from "react";
import { useCheckMounted } from "utils/hooks";
import { TableItem } from ".";
import {
  GetInitialTableStateArgs,
  getTableInitialState,
  getTableReducer,
} from "./reducer";

export const useBreakdownTableState = <Details = {}>(
  { items, total }: GetInitialTableStateArgs<Details> = { items: [], total: 0 }
) => {
  const [table, dispatch] = React.useReducer(
    React.useMemo(() => getTableReducer<Details>(), []),
    React.useMemo(() => getTableInitialState({ items, total }), [])
  );

  const addItem = React.useCallback(
    ({
      id,
      details,
      ...rest
    }: { id: UUID; details: Details } & Partial<
      Omit<TableItem<Details>, "id" | "details">
    >) => {
      dispatch({
        type: "AddItem",
        id,
        details,
        ...rest,
      });
    },
    []
  );

  const deleteItem = React.useCallback(({ id }: { id: UUID }) => {
    dispatch({
      type: "DeleteItem",
      id,
    });
  }, []);

  const changeItem = React.useCallback((updatedItem: TableItem<Details>) => {
    dispatch({
      type: "UpdateItem",
      updatedItem,
    });
  }, []);

  const changeTotal = React.useCallback(
    ({ value, displayValue }: { value: number; displayValue: string }) => {
      dispatch({
        type: "UpdateTotal",
        value,
        displayValue,
      });
    },
    []
  );

  const setDirty = React.useCallback((dirty = true) => {
    dispatch({
      type: "UpdateDirty",
      dirty,
    });
  }, []);

  const setItems = React.useCallback(
    ({ items }: { items: TableItem<Details>[] }) => {
      dispatch({
        type: "ReplaceAllItems",
        items,
      });
    },
    []
  );

  return React.useMemo(
    () => ({
      table,
      addItem,
      deleteItem,
      changeItem,
      setItems,
      changeTotal,
      setDirty,
    }),
    [table, addItem, deleteItem, changeItem, setItems, changeTotal, setDirty]
  );
};

export const useTemporarilyShowSnappingGuides = (dependencies: any[] = []) => {
  const [
    temporarilyShowSnappingGuides,
    setTemporarilyShowSnappingGuides,
  ] = React.useState(false);
  const clearGuides = React.useRef(null);
  const checkMounted = useCheckMounted();

  React.useEffect(() => {
    if (!dependencies.length || !checkMounted()) return;
    setTemporarilyShowSnappingGuides(true);
  }, dependencies);

  React.useEffect(() => {
    if (temporarilyShowSnappingGuides) {
      window.clearTimeout(clearGuides.current);
      clearGuides.current = window.setTimeout(() => {
        if (!checkMounted()) return;
        setTemporarilyShowSnappingGuides(false);
        clearGuides.current = null;
      }, 1500);
    }
  }, [temporarilyShowSnappingGuides]);

  const showGuides = React.useCallback(
    () => setTemporarilyShowSnappingGuides(true),
    []
  );

  return [temporarilyShowSnappingGuides, showGuides] as [boolean, () => void];
};
