import { scaleLinear } from "d3";
import { find, findIndex } from "lodash";

export const roundToTwoDecimalPlaces = (value: number) =>
  Math.round((value + Number.EPSILON) * 100) / 100;

export const toPercent = (value: number, min = 0, max = 100) =>
  min === max ? min : scaleLinear().domain([min, max]).range([0, 100])(value);

export const FUZZY_EPSILON = 10e-10;

export const fuzzyEquals = (a: number, b: number, EPSILON = FUZZY_EPSILON) =>
  Math.abs(a - b) <= EPSILON;

export const fuzzyGT = (a: number, b: number, EPSILON = FUZZY_EPSILON) =>
  fuzzyEquals(a, b, EPSILON) ? false : a - b > EPSILON;

export const fuzzyGTE = (a: number, b: number, EPSILON = FUZZY_EPSILON) =>
  fuzzyEquals(a, b, EPSILON) || fuzzyGT(a, b, EPSILON);

export const fuzzyLT = (a: number, b: number, EPSILON = FUZZY_EPSILON) =>
  fuzzyEquals(a, b, EPSILON) ? false : b - a > EPSILON;

export const fuzzyLTE = (a: number, b: number, EPSILON = FUZZY_EPSILON) =>
  fuzzyEquals(a, b, EPSILON) || fuzzyLT(a, b, EPSILON);

export const fuzzyMin = (a: number, b: number, EPSILON = FUZZY_EPSILON) =>
  fuzzyLTE(a, b, EPSILON) ? a : b;

export const fuzzyMax = (a: number, b: number, EPSILON = FUZZY_EPSILON) =>
  fuzzyGTE(a, b, EPSILON) ? a : b;

export const fuzzyGet = (
  collection: number[],
  value: number,
  defaultValue: any = undefined,
  EPSILON = FUZZY_EPSILON
) => {
  const found = find(collection, (collVal) =>
    fuzzyEquals(value, collVal, EPSILON)
  );
  return found === undefined ? defaultValue : found;
};

export const fuzzyIncludes = (
  collection: number[],
  value: number,
  EPSILON = FUZZY_EPSILON
) => fuzzyGet(collection, value, undefined, EPSILON) !== undefined;

export const fuzzyFindIndex = (
  collection: number[],
  value: number,
  EPSILON = FUZZY_EPSILON
) => findIndex(collection, (collVal) => fuzzyEquals(value, collVal, EPSILON));
