import * as React from "react";
import { getDateInterval } from "utils/dates";
import {
  formatDateIntervalDuration,
  formatNumberToWords,
} from "utils/formatters";

export interface ListHeaderDescriptionProps {
  requiredFrom: Date;
}

export function ListHeaderDescription({
  requiredFrom,
}: ListHeaderDescriptionProps) {
  const previousTimePeriod = formatDateIntervalDuration(
    getDateInterval(requiredFrom),
    { formatNumber: formatNumberToWords }
  );

  return (
    <p>
      We need to know your current residential address. If you&rsquo;ve also
      lived somewhere else in the last {previousTimePeriod}, the bankruptcy
      court will want to know your prior addresses as well.
    </p>
  );
}
