import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaMobile } from "react-icons/fa";
import { GigIncome } from "./GigIncome";
import GigIncomeSummary from "./Summary";

const GigIncomeSubmodule: SubModule = {
  path: "/gig_work",
  icon: FaMobile,
  title: "Gig Work",
  component: GigIncome,
  summary: GigIncomeSummary,
  statusName: "gigIncome",
};

export default GigIncomeSubmodule;
