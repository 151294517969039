import { sum } from "lodash";
import { formatFancyNumber } from "utils/formatters";
import { parseFancyNumber } from "utils/validators";

function isProp<T>(x: number | keyof T): x is keyof T {
  return typeof x === "string";
}

export function fancyNumberSum<T>(
  items: T[],
  decimalPlaces: number | keyof T,
  ...props: (keyof T)[]
) {
  const realProps = isProp(decimalPlaces) ? [decimalPlaces, ...props] : props;
  const realDecimalPlaces = isProp(decimalPlaces) ? 0 : decimalPlaces;

  return (
    items &&
    formatFancyNumber(
      items.reduce(
        (total, item) =>
          total +
          sum(realProps.map((p) => parseFancyNumber(`${item[p] || 0}`))),
        0
      ),
      realDecimalPlaces
    )
  );
}
