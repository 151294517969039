import { useQuery } from "@apollo/client";
import { useDeleteLawsuit } from "api/graphql/Lawsuit";
import { ButtonRow } from "components/ButtonRow";
import { LawsuitSchema } from "components/FormModules/Legal/Lawsuits/LawsuitWizard";
import { ListItem } from "components/ListItem";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import { truncate } from "lodash";
import React from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema } from "utils/formatters";
import { useNested } from "utils/hooks";
import SEIZURES_MODULE from "./LawsuitsModule.gql";

interface Props {
  onEdit: (id: string) => any;
  createURL: string;
  nextURL: string;
}

export function ListView({ onEdit, createURL, nextURL }: Props) {
  const { data, loading } = useQuery<LawsuitsModule>(SEIZURES_MODULE);
  const [onDelete] = useDeleteLawsuit();
  const { path, url } = useNested();

  if (loading) return <Loading />;
  const { lawsuits: items } = data;

  return (
    <>
      <Title>Your Lawsuits</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoLawsuits"
              createURL={createURL}
              nextURL={nextURL}
              addText="Add a lawsuit"
              question={
                <>
                  <p className="required">
                    <strong>
                      Have you been involved in any lawsuits in the last 6
                      years?
                    </strong>
                  </p>
                  <p>
                    Some of the most commonly filed lawsuits include but are not
                    limited to:
                  </p>
                  <ul>
                    <li>Personal injury</li>
                    <li>Product liability</li>
                    <li>Worker’s compensation</li>
                    <li>Medical malpractice</li>
                    <li>Wrongful termination</li>
                    <li>Drug recalls</li>
                    <li>Class action settlements</li>
                  </ul>
                </>
              }
              confirmation={
                <p>
                  You have indicated you have not been involved in any lawsuits
                  in the last 6 years. If that’s correct, you’re ready to
                  continue to the next section. Otherwise, you may add lawsuits
                  below.
                </p>
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Tell us about all the lawsuits you are involved in. Some of the
                most commonly filed lawsuits include but are not limited to:
              </p>
              <ul>
                <li>Personal injury</li>
                <li>Product liability</li>
                <li>Worker’s compensation</li>
                <li>Medical malpractice</li>
                <li>Wrongful termination</li>
                <li>Drug recalls</li>
              </ul>
              <p>
                If you were involved in a class action settlement (you will
                likely have received a letter notifying you of this), make sure
                to include here that as well.
              </p>
              <ButtonRow
                style={{ marginBottom: "8px" }}
                left={
                  <Link to={createURL} className="btn secondary">
                    <FaPlus />
                    <span>Add a lawsuit</span>
                  </Link>
                }
                right={
                  <Link to={nextURL} className="btn primary">
                    <span>Done</span>
                    <FaCheck />
                  </Link>
                }
              />
              {items.map((suit) => {
                return (
                  <ListItem
                    key={suit.id}
                    onEdit={() => onEdit(suit.id)}
                    onDelete={() => onDelete(suit.id)}
                    objectName="lawsuit"
                  >
                    <div>
                      {formatErrorsFromSchema(LawsuitSchema, suit)}
                      {suit.plaintiffName && suit.defendantName && (
                        <>
                          {suit.plaintiffName} v {suit.defendantName}
                        </>
                      )}
                      {suit.description && (
                        <>
                          {" "}
                          ({truncate(suit.description, { length: 30 })})<br />
                        </>
                      )}
                      {suit.caseNumber && <> #{suit.caseNumber}</>}
                      {suit.caseDate && <> filed {suit.caseDate}</>}
                    </div>
                  </ListItem>
                );
              })}
            </>
          )}
        />
      </Switch>
    </>
  );
}
