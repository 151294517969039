import OwnedBusinessForm from "components/FormModules/OwnedBusinesses/OwnedBusinessForm";
import { Title } from "components/Title";
import React from "react";
import { useHistory } from "react-router";
import { useNested } from "utils/hooks";

export default function CreateView({ listURL }: { listURL: string }) {
  const { url } = useNested();
  const history = useHistory();
  return (
    <>
      <Title>Add a Business</Title>
      <OwnedBusinessForm
        backURL={listURL}
        onDone={({ id }) => history.push(url(`../edit/${id}`))}
      />
    </>
  );
}
