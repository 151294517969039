import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import STORAGE_MODULE from "./StorageContainersModule.gql";

export default function StorageSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<StorageContainersModule>(STORAGE_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.storageContainers}>
      {data?.filingProfile?.hasNoStorage ? (
        <HasNoSummary>
          You aren&rsquo;t storing anything of value with a third-party.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="storage unit"
            maxValueLength={2}
            value={
              data?.storageContainers?.filter(
                (s) => s.storageType === "STORAGE_UNIT"
              ).length
            }
            pluralizeLabel
          />
          <NumericSummary
            label="deposit box"
            maxValueLength={2}
            value={
              data?.storageContainers?.filter(
                (s) => s.storageType === "DEPOSIT_BOX"
              ).length
            }
            pluralizeLabel
          />
          <NumericSummary
            label="other place"
            maxValueLength={2}
            value={
              data?.storageContainers?.filter((s) => s.storageType === "OTHER")
                .length
            }
            pluralizeLabel
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
