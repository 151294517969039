import { QueryHookOptions, useQuery } from "@apollo/client";
import { client } from "api/graphql/client";
import GET_ADDRESS from "./GetAddress.gql";
import LIST_ADDRESSES from "./ListAddresses.gql";

export function useListAddresses(
  options?: QueryHookOptions<ListAddresses, ListAddressesVariables>
) {
  return useQuery<ListAddresses>(LIST_ADDRESSES, options);
}

export function useGetAddress(
  options?: QueryHookOptions<GetAddress, GetAddressVariables>
) {
  return useQuery<GetAddress, GetAddressVariables>(GET_ADDRESS, options);
}

export function queryAddress(id: UUID) {
  return client.query<GetAddress, GetAddressVariables>({
    query: GET_ADDRESS,
    variables: { id },
  });
}
