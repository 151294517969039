import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaHome } from "react-icons/fa";
import { RealEstate } from "./RealEstate";
import RealEstateSummary from "./Summary";

const RealEstateSubmodule: SubModule = {
  path: "real_estate",
  icon: FaHome,
  title: "Real Estate",
  component: RealEstate,
  summary: RealEstateSummary,
  statusName: "realEstateProperty",
};

export default RealEstateSubmodule;
