import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import SEIZURES_MODULE from "./SeizuresModule.gql";

export default function SeizuresSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<SeizuresModule>(SEIZURES_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.seizures}>
      {data?.filingProfile?.hasNoSeizures ? (
        <HasNoSummary>
          Creditors haven&rsquo;t seized any assets you own.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="seized asset"
            maxValueLength={1}
            value={data?.seizures?.length}
            pluralizeLabel
          />
          <NumericSummary
            label="total value"
            maxValueLength={1}
            valuePrefix="$"
            value={fancyNumberSum(data?.seizures, "itemValue")}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
