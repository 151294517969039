import axios, { AxiosResponse } from "axios";

/**
 * Create a Read-only API endpoint with the given base URL and types.
 *
 * The Output type should describe the shape of the objects
 * returned by the API.
 *
 * The returned object includes methods for `list`, and `get` (retrieve) operations.
 * The implementation follows and expects [DRF](https://www.django-rest-framework.org/)
 * conventions for url structure.
 */
export class ReadOnlyEndpoint<Output> {
  public baseURL: string;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  list = (filterSpec?: Record<string, string | number | boolean>) => {
    const queryString = filterSpec
      ? `?${Object.entries(filterSpec)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join("&")}`
      : "";
    return axios.get(`${this.baseURL}${queryString}`) as Promise<
      AxiosResponse<Output[]>
    >;
  };

  retrieve = (id: UUID) => {
    return axios.get(`${this.baseURL}${id}/`) as Promise<AxiosResponse<Output>>;
  };
}

/**
 * Create an API endpoint with the given base URL and types.
 *
 * The Input and Output types should describe the shapes of the objects
 * expected and returned by the API, respectively.
 *
 * The returned object includes methods for `list`, `get`, `create (POST)`,
 * `update (PUT)`, and `delete`; operations. The implementation follows and expects
 * [DRF](https://www.django-rest-framework.org/) conventions for url structure.
 */
export class Endpoint<Input, Output> extends ReadOnlyEndpoint<Output> {
  update = (id: UUID, data: Input) => {
    return axios.put(`${this.baseURL}${id}/`, data) as Promise<
      AxiosResponse<Output>
    >;
  };

  create = (data: Input) => {
    return axios.post(this.baseURL, data) as Promise<AxiosResponse<Output>>;
  };

  delete = (id: UUID) => {
    return axios.delete(`${this.baseURL}${id}/`) as Promise<AxiosResponse<"">>;
  };
}

export interface FileAPIOutput {
  id: UUID;
  updated_at: ISODate;
  upload_file: URLString;
  upload_file_hash: string;
  file_name: string;
}

export class FileEndpoint<Output = FileAPIOutput> extends Endpoint<
  File,
  Output
> {
  requestOptions = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  private _get_request_data(data: File) {
    const formData = new FormData();
    formData.append("upload_file", data);
    return formData;
  }

  create = (file: File) => {
    return axios.post(
      this.baseURL,
      this._get_request_data(file),
      this.requestOptions
    ) as Promise<AxiosResponse<Output>>;
  };

  update = (id: UUID, file: File) => {
    return axios.put(
      this.baseURL,
      this._get_request_data(file),
      this.requestOptions
    ) as Promise<AxiosResponse<Output>>;
  };
}
