import cn from "classnames";
import { Caption, Container } from "components/Big";
import * as React from "react";
import css from "./styles.module.css";

export interface IconSpecificProps {
  caption?: React.ReactNode;
  children?: React.ReactNode;
  Wrapper?: any; // what exactly is the type of any (class, functional, fwdRef) component constructor?
}

export type BigIconProps<WrapperProps = React.HTMLAttributes<HTMLDivElement>> =
  IconSpecificProps & WrapperProps;

export function BigIcon<WrapperProps>({
  children,
  caption,
  Wrapper,
  ...wrapperProps
}: BigIconProps<WrapperProps>) {
  const Component = Wrapper || "div";
  return (
    <Container>
      <Component
        {...wrapperProps}
        className={cn((wrapperProps as any).className, css.iconWrapper)}
      >
        {React.Children.map(children, (child: React.ReactElement) => {
          const className = cn(child.props.className || "", css.Icon);
          return React.cloneElement(child, { ...child.props, className });
        })}
      </Component>
      {!!caption && <Caption>{caption}</Caption>}
    </Container>
  );
}
