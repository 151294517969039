import cn from "classnames";
import { YupSchemaContext } from "components/Form";
import { MaskedInput } from "components/MaskedInput";
import {
  TextInput,
  TextInputCommonProps,
  TextInputProps,
} from "components/TextInput";
import {
  format,
  formatISO,
  isValid,
  parse,
  parseISO,
  startOfDay,
} from "date-fns";
import { set } from "lodash";
import React, { RefObject, useCallback, useEffect } from "react";
import {
  DayModifiers,
  DayPickerInputProps,
  DayPickerProps,
  Modifier,
  ModifiersUtils,
} from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { Controller, useFormContext } from "react-hook-form";
import { serverDate } from "utils/formatters";
import { breakpoints, useBreakpoint, useFormField } from "utils/hooks";
import styles from "./DateInput.module.scss";
import { DateInputNav } from "./DateInputNav";
import { RootOverlay } from "./RootOverlay";

function checkDateInputSupported() {
  const input = document.createElement("input");
  const value = "a";
  input.setAttribute("type", "date");
  input.setAttribute("value", value);
  return input.value !== value;
}

function serverToInput(d: ISODate) {
  const date = parseISO(d);
  return isValid(date) ? format(date, "MM/dd/yyyy") : "";
}

interface DateInputProps
  extends Omit<DayPickerInputProps, "onChange" | "dayPickerProps">,
    TextInputCommonProps {
  className?: string;
  id?: string;
  placeholder?: string;
  /**
   * Passed along to [DayPickerInput](http://react-day-picker.js.org/api/DayPickerInput#dayPickerProps)
   *
   * `daypickerProps.disabledDays` and `daypickerProps.selectedDays` props are handled internally,
   * and will be overridden. Use `disabledDays` prop for the former.
   */
  dayPickerProps?: Omit<
    DayPickerInputProps["dayPickerProps"],
    "disabledDays" | "selectedDays"
  >;
  /**
   * A series of [react-day-picker date modifiers](http://react-day-picker.js.org/docs/matching-days) and associated error messages.
   *
   * Dates that match any of these modifiers will be disabled in the picker,
   * and if a user enters one with the keyboard, the associated validation message
   * will be displayed during form validation.
   */
  disabledDays?: { [errorMessage: string]: Modifier };
  /** Called when the date value changes. If the input contains an invalid date, the value will be `undefined` */
  onChange?: (date: ISODate) => any;
  /** The default value of the input. Works standalone or with react-hook-form */
  defaultValue?: Date | ISODate;
  autoFocus?: boolean;
}

type InnerInputProps = Pick<
  DateInputProps,
  "onChange" | "disabledDays" | "placeholder" | "name" | "value"
> & {
  dayPickerInputProps: DayPickerInputProps;
  dayPickerProps: DayPickerProps;
  defaultValue?: string;
  inputProps: Partial<TextInputProps>;
  inputRef: RefObject<any>;
};

const Input = ({
  value,
  onChange,
  dayPickerInputProps,
  dayPickerProps,
  inputProps,
  inputRef,
  disabledDays,
  defaultValue,
  placeholder,
  name,
}: InnerInputProps) => {
  const [month, setMonth] = React.useState<Date>();
  const schema = React.useContext(YupSchemaContext);
  const form = useFormContext();

  const [inputValue, setInputValue] = React.useState(
    form && form.watch(name)
      ? serverToInput(form.watch(name))
      : defaultValue && serverToInput(defaultValue)
  );

  // Determine the new form field state based on input event
  const handleChange = useCallback(
    (date: Date) => {
      let newValue = "";
      if (isValid(date) && date.getFullYear() > 999) {
        newValue = serverDate(date);
        setInputValue(format(date, "MM/dd/yyyy"));
      }
      if (newValue !== value) {
        onChange?.(newValue);
      }
    },
    [onChange, value]
  );

  // Update the internal value when the form value changes
  useEffect(() => {
    handleChange(value instanceof Date ? value : parseISO(value));
  }, [value, handleChange]);

  const schemaDisabledDays = (date: Date) => {
    if (!schema || !form) return false;
    try {
      schema.validateSyncAt(
        name,
        set(form.getValues(), name, formatISO(startOfDay(date)))
      );
      return false;
    } catch {
      return true;
    }
  };

  return (
    <DayPickerInput
      format={"M/D/YYYY"}
      value={inputValue}
      onDayChange={(
        date: Date,
        modifiers: DayModifiers,
        input: DayPickerInput
      ) => handleChange(date)}
      {...dayPickerInputProps}
      inputProps={{
        autoComplete: "nope",
        mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
        type: "text",
        ...inputProps,
        placeholder,
        // important: inputProps includes a ref that would register the
        // input with react-hook-form. If we're using a non-native input,
        // we don't want to use this input's value as the field value.
        ref: undefined,
      }}
      formatDate={(value) => {
        return format(value, "MM/dd/yyyy");
      }}
      parseDate={(date) => {
        const newDate = parse(date, "MM/dd/yyyy", new Date());
        if (isValid(newDate)) {
          return newDate;
        } else {
          return undefined;
        }
      }}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      overlayComponent={({ classNames, selectedDay, ...props }: any) => (
        <RootOverlay {...props} attachTo={inputRef.current} />
      )}
      component={MaskedInput}
      dayPickerProps={{
        ...dayPickerProps,
        month,
        navbarElement: function MyCaption(props) {
          return <DateInputNav {...props} onChange={setMonth} />;
        },
        captionElement: () => null,
        disabledDays: [...Object.values(disabledDays), schemaDisabledDays],
        selectedDays: form
          ? parseISO(form.getValues()[name])
          : parseISO(defaultValue),
      }}
    />
  );
};

export function DateInput({
  name,
  width,
  rules = {},
  label = "",
  className = "",
  id,
  placeholder = "MM/DD/YYYY",
  dayPickerProps = {},
  learnMore,
  helpText,
  defaultValue,
  disabledDays = {},
  onChange,
  autoFocus = false,
  addOnAfter,
  addOnBefore,
  value,
  ...otherProps
}: DateInputProps) {
  const combinedRules = {
    ...rules,
    validate: {
      isDisabled: (date: ISODate) => {
        const parsed = parseISO(date);
        for (const [msg, modifier] of Object.entries(disabledDays)) {
          if (ModifiersUtils.dayMatchesModifier(parsed, modifier)) {
            return msg;
          }
        }
        return true;
      },
      ...rules.validate,
    },
  };
  const { inputProps, form } = useFormField({
    label,
    name,
    rules: combinedRules,
  });

  const stringDefaultValue: string | undefined = React.useMemo(
    () =>
      defaultValue &&
      (typeof defaultValue === "string"
        ? defaultValue
        : formatISO(defaultValue as Date)),
    [defaultValue]
  );

  const isMobile = useBreakpoint(breakpoints.mobile);
  const useNative = isMobile && checkDateInputSupported();
  width = width || (useNative ? "150px" : "135px");

  const inputRef = React.useRef<HTMLDivElement | null>(null);
  const getInput = () => (
    <Input
      name={name}
      dayPickerInputProps={otherProps}
      dayPickerProps={dayPickerProps}
      inputProps={{
        autoFocus,
        ...otherProps.inputProps,
        ...inputProps,
      }}
      onChange={onChange}
      inputRef={inputRef}
      disabledDays={disabledDays}
      defaultValue={stringDefaultValue}
    />
  );

  return (
    <TextInput
      className={cn(className, styles.container)}
      id={id}
      style={{ width }}
      label={label}
      learnMore={learnMore}
      helpText={helpText}
      name={name}
      register={useNative}
      rules={rules}
      ref={inputRef}
      addOnAfter={addOnAfter}
      addOnBefore={addOnBefore}
      placeholder={placeholder}
      inputElement={
        useNative ? (
          <input
            {...otherProps.inputProps}
            className={cn({
              placeholder: !value && (!form || !form.watch(name)),
            })}
            type="date"
          />
        ) : form ? (
          <Controller
            name={name}
            rules={combinedRules}
            as={getInput()}
            defaultValue={stringDefaultValue}
            onChange={(value: any) => {
              onChange?.(value);
              return value;
            }}
          />
        ) : (
          getInput()
        )
      }
    />
  );
}
