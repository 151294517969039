import { TextArea } from "components/TextArea";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { object, string } from "yup";

export const InventorySchema = object({
  contentsDescription: string().required("This field is required."),
});

export function Inventory({ navProps, isEdit }: WizardRouteChildProps) {
  return (
    <>
      <TextArea
        name="contentsDescription"
        label="Content(s) description"
        autoFocus
        helpText="Describe what you store here."
        placeholder="I keep copies of my family photos in this deposit box."
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
