import { useCallback, useEffect, useRef, useState } from "react";
import usePageVisible from "./usePageVisible";

/** A hook for determining if the user has been active on the page in the last `timeout` seconds. */
export default function useActivityTimeout(timeout = 30) {
  const isVisible = usePageVisible();
  const [isActive, setActive] = useState(true);
  const timeoutHandle = useRef<NodeJS.Timeout | undefined>();

  const resetIdleTimer = useCallback(() => {
    clearTimeout(timeoutHandle.current);
    timeoutHandle.current = setTimeout(() => {
      setActive(false);
    }, timeout * 1000);
  }, [timeout]);

  useEffect(() => {
    // Restore active status when user switches back to page, regardless of interactions.
    // This also starts the idle timer at page start even if there have been no interactions.
    if (isVisible) {
      setActive(true);
      resetIdleTimer();
    }
  }, [isVisible, resetIdleTimer]);

  useEffect(() => {
    const listener = () => {
      if (!isActive) {
        setActive(true);
      }

      resetIdleTimer();
    };

    window.addEventListener("mousemove", listener);
    window.addEventListener("keypress", listener);
    window.addEventListener("touchstart", listener);

    return () => {
      window.removeEventListener("mousemove", listener);
      window.removeEventListener("keypress", listener);
      window.removeEventListener("touchstart", listener);
    };
  }, [isActive, resetIdleTimer]);

  return isVisible && isActive;
}
