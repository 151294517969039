import { QueryHookOptions, useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import FORCE_SUBMISSION from "./ForceSubmission.gql";
import REOPEN_INTAKE from "./ReopenIntake.gql";
import APPROVE_APPLICANT from "./ApproveApplicant.gql";
import GET_REFERRED_USER from "./GetReferredUser.gql";
import LIST_REFERRED_USERS from "./ListReferredUsers.gql";
import REJECT_APPLICANT from "./RejectApplicant.gql";
import UPDATE_ARCHIVED_STATUS from "./UpdateArchivedStatus.gql";
import REGENERATE_BUNDLE from "./RegenerateBundle.gql";

export function useListReferredUsers(
  options?: QueryHookOptions<ListReferredUsers>
) {
  return useQuery<ListReferredUsers>(LIST_REFERRED_USERS, options);
}

export function useGetReferredUser(id: string) {
  return useQuery<GetReferredUser, GetReferredUserVariables>(
    GET_REFERRED_USER,
    {
      variables: { id },
    }
  );
}

export function useForceSubmission() {
  const [forceSubmission, context] = useMutation<
    ForceSubmission,
    ForceSubmissionVariables
  >(FORCE_SUBMISSION, {
    update(cache, { data }) {
      cache.modify({
        id: cache.identify({
          __typename: "ReferredUser",
          id: data.forceSubmission.id,
        }),
        fields: {
          completionStatus: () => data.forceSubmission.completionStatus,
        },
      });
    },
  });

  const callback = React.useCallback(
    (variables: ForceSubmissionVariables) => {
      return forceSubmission({ variables });
    },
    [forceSubmission]
  );

  return React.useMemo(() => {
    return [callback, context];
  }, [callback, context]);
}

export function useReopenIntake() {
  const [reopenIntake, context] = useMutation<
    ReopenIntake,
    ReopenIntakeVariables
  >(REOPEN_INTAKE, {
    update(cache, { data }) {
      cache.modify({
        id: cache.identify({
          __typename: "ReferredUser",
          id: data.reopenIntake.id,
        }),
        fields: {
          completionStatus: () => data.reopenIntake.completionStatus,
        },
      });
    },
  });

  const callback = React.useCallback(
    (variables: ReopenIntakeVariables) => {
      return reopenIntake({ variables });
    },
    [reopenIntake]
  );

  return React.useMemo(() => {
    return [callback, context];
  }, [callback, context]);
}

export function useApproveApplicant() {
  const [approveApplicant] = useMutation<
    ApproveApplicant,
    ApproveApplicantVariables
  >(APPROVE_APPLICANT, {
    update(cache, { data }) {
      cache.modify({
        id: cache.identify({
          __typename: "ReferredUser",
          id: data.approveApplicant.id,
        }),
        fields: {
          completionStatus: () => data.approveApplicant.completionStatus,
        },
      });
    },
  });

  const callback = React.useCallback(
    (variables: ApproveApplicantVariables) => {
      return approveApplicant({ variables });
    },
    [approveApplicant]
  );

  return React.useMemo(() => [callback], [callback]);
}

export function useRejectApplicant() {
  const [rejectApplicant] = useMutation<
    RejectApplicant,
    RejectApplicantVariables
  >(REJECT_APPLICANT, {
    update(cache, { data }) {
      cache.modify({
        id: cache.identify({
          __typename: "ReferredUser",
          id: data.rejectApplicant.id,
        }),
        fields: {
          completionStatus: () => data.rejectApplicant.completionStatus,
        },
      });
    },
  });

  const callback = React.useCallback(
    (variables: RejectApplicantVariables) => {
      return rejectApplicant({ variables });
    },
    [rejectApplicant]
  );

  return React.useMemo(() => [callback], [callback]);
}

export function useUpdateUsersArchivedStatus() {
  const [upateArchivedStatus] = useMutation<
    UpdateArchivedStatus,
    UpdateArchivedStatusVariables
  >(UPDATE_ARCHIVED_STATUS, {
    update(cache, { data }) {
      data.updateUserArchivedStatus.map(({ id, isArchived }) => {
        cache.modify({
          id: cache.identify({
            __typename: "ReferredUser",
            id,
          }),
          fields: {
            isArchived: () => isArchived,
          },
        });
      });
    },
  });

  const callback = React.useCallback(
    (variables: UpdateArchivedStatusVariables) => {
      return upateArchivedStatus({ variables });
    },
    [upateArchivedStatus]
  );

  return React.useMemo(() => [callback], [callback]);
}

export function useRegenerateBundle() {
  const [regenerateBundle] = useMutation<
    RegenerateBundle,
    RegenerateBundleVariables
  >(REGENERATE_BUNDLE);

  const callback = React.useCallback(
    (variables: RegenerateBundleVariables) => {
      return regenerateBundle({ variables });
    },
    [regenerateBundle]
  );

  return React.useMemo(() => [callback], [callback]);
}
