import { useQuery } from "@apollo/client";
import { useDeleteHouseholdItem } from "api/graphql/HouseholdItem";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  formatErrorsFromSchema,
  formatName,
  formatOxfordComma,
} from "utils/formatters";
import { useNested } from "utils/hooks";
import HOUSEHOLD_ASSETS_MODULE from "../HouseholdItemsModule.gql";
import { HouseholdItemSchema } from "../HouseholdItemWizard";
import { CategoryDetail } from "./CategoryDetail";
import { Description } from "./Description";

interface Props {
  createURL: string;
  nextURL: string;
  prevURL: string;
  onEdit: (id: string) => any;
  categoryDetail: CategoryDetail;
}

export default function ListView(props: Props) {
  const {
    category,
    declarativeText,
    displayName,
    hasNoProfileBooleanName,
    nextSectionText,
    noAssetDeclarativeText,
    booleanInterrogativeText,
  } = props.categoryDetail;

  const { data } = useQuery<HouseholdItemsModule>(HOUSEHOLD_ASSETS_MODULE, {
    variables: { category },
  });
  const [onDelete] = useDeleteHouseholdItem();
  const { path, url } = useNested();

  if (!data) return <Loading />;
  const { householdItems: items } = data;

  return (
    <>
      <Title>Your {displayName}</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean={hasNoProfileBooleanName}
              createURL={props.createURL}
              nextURL={props.nextURL}
              addText="Add an item"
              question={<Description introText={booleanInterrogativeText} />}
              confirmation={
                <Description
                  introText={noAssetDeclarativeText}
                  footerText={nextSectionText}
                />
              }
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <Description introText={declarativeText} />

              <StatelessList
                {...props}
                emptyText="You haven’t added any items yet."
                addButtonText="Add an item"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(asset) => (
                  <div>
                    {formatErrorsFromSchema(HouseholdItemSchema, asset)}
                    <strong>
                      {asset.description} (${asset.currentValue})
                    </strong>
                    <div className="supporting-text">
                      {asset.hasNoCoowners ? (
                        "No co-owners"
                      ) : asset.coowners.length > 0 ? (
                        `Co-owned with ${formatOxfordComma(
                          ...asset.coowners.map((coowner) =>
                            formatName(coowner.person)
                          )
                        )}`
                      ) : (
                        <span className="warning">
                          You haven’t told us if you co-own this item with
                          anyone.
                        </span>
                      )}
                    </div>
                  </div>
                )}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
