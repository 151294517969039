import { useQuery } from "@apollo/client";
import GetOrCreate from "components/GetOrCreate";
import { Loading } from "components/Loading";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import gql from "graphql-tag";
import React from "react";
import { formatGraphAddress } from "utils/formatters";
import { object, string } from "yup";

export const PropertySchema = object({
  property: object({
    id: string().required("This step is required."),
  }).required(),
}).required();

export const GET_PROPERTY_CANDIDATES = gql`
  query GetPropertyCandidates {
    realEstateProperties {
      id
      address {
        id
        line1
        line2
        city
        state {
          abbr
        }
        zipCode
      }
    }
  }
`;

export default function Property({ navProps }: WizardRouteChildProps) {
  const { data, loading } = useQuery<GetPropertyCandidates>(
    GET_PROPERTY_CANDIDATES
  );

  return (
    <>
      <p>Which real estate property is this mortgage for?</p>
      {loading ? (
        <Loading />
      ) : (
        <GetOrCreate
          name="property.id"
          createURL="create_property"
          objectName="property"
          options={
            data?.realEstateProperties.map((item) => ({
              value: item.id,
              label: formatGraphAddress(item.address),
            })) || []
          }
        />
      )}
      <WizardNavigation {...navProps} />
    </>
  );
}
