import { useEditorBuffer } from "components/EditorState/hooks";
import { AddressForm, AddressSchema } from "components/FormSections/Address";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { object } from "yup";

export const EmployerAddressSchema = object({
  address: AddressSchema.required(),
}).required();

export function EmployerAddress({ isEdit, navProps }: WizardRouteChildProps) {
  const [state] = useEditorBuffer();
  const { companyName } = state.buffer;

  return (
    <>
      <p>
        Please provide the address of the office{" "}
        {companyName ? `for ${companyName}` : "for this job"}. If you or your
        family member work from home, enter your home address instead.
      </p>

      <AddressForm name="address" graphQL autoFocus={!isEdit} />
      <WizardNavigation {...navProps} />
    </>
  );
}
