import FilingPersonAPI from "api/FilingPerson";
import { AxiosResponse } from "axios";
import cn from "classnames";
import { FileInput, styles } from "components/FileInput";
import * as React from "react";
import { FaBomb, FaCheckCircle, FaSpinner } from "react-icons/fa";
import { hashFile } from "utils/hash";
import { breakpoints, useBreakpoint } from "utils/hooks";
import overrideStyles from "./FileUploader.module.css";

const checkForExistingFile = (response: AxiosResponse<any>) =>
  response.status === 400 &&
  Array.isArray(response.data) &&
  response.data.includes("You've already uploaded this file.");

const getUploadErrorMessage = (response: AxiosResponse<any>) =>
  response.data?.upload_file?.join(" ");

type UploaderStates = {
  state: "neutral" | "uploading" | "error" | "success";
  data?: any;
};

export interface FileUploaderProps {
  onSuggestionsReceived: (suggestions: object[]) => void;
}

export function FileUploader({ onSuggestionsReceived }: FileUploaderProps) {
  const [currentState, setCurrentState] = React.useState<UploaderStates>({
    state: "neutral",
  });

  function NeutralView() {
    const isMobile = useBreakpoint(breakpoints.mobile);
    return (
      <div className={styles.FileUploader}>
        <FileInput
          helpText={
            isMobile
              ? "Tap to select MyStudentData.txt"
              : "Drag MyStudentData.txt here or click to select"
          }
          onDrop={(acceptedFiles: File[]) => {
            const myStudentData = acceptedFiles[0];

            if (!myStudentData.name.toLowerCase().endsWith(".txt")) {
              throw new Error(
                "This upload must be the MyStudentData.txt file from nslds.ed.gov."
              );
            }

            setCurrentState({ state: "uploading", data: myStudentData });
          }}
          accept="text/plain"
          multiple={false}
        />
      </div>
    );
  }

  function ErrorView({ message }: { message?: string }) {
    React.useEffect(() => {
      const timeout = setTimeout(
        () => setCurrentState({ state: "neutral" }),
        3000
      );
      return () => clearTimeout(timeout);
    }, []);

    return (
      <div
        className={cn(
          styles.root,
          styles.error,
          overrideStyles.nonInteractive,
          styles.FileUPloader
        )}
      >
        <FaBomb className={styles.Icon} />
        <div className={styles.IconLabel}>
          {message || "There was a problem uploading. Please try again"}
        </div>
      </div>
    );
  }

  function SuccessView({
    studentLoanSuggestions,
  }: {
    studentLoanSuggestions: object[];
  }) {
    React.useEffect(() => {
      onSuggestionsReceived(studentLoanSuggestions);
    }, []);

    return (
      <div
        className={cn(
          styles.root,
          overrideStyles.nonInteractive,
          overrideStyles.FileUPloader
        )}
      >
        <FaCheckCircle className={styles.Icon} />
        <div className={styles.IconLabel}>
          Got it! Your My Student Data was successfully uploaded
        </div>
      </div>
    );
  }

  function UploadingView({ myStudentData }: { myStudentData: File }) {
    const api = FilingPersonAPI.myStudentData;

    React.useEffect(() => {
      const doUpload = async () => {
        let suggestedLoans;
        try {
          suggestedLoans = await api
            .create(myStudentData)
            .then((response) => response.data.suggested_data.loans);
        } catch (e) {
          if (checkForExistingFile(e.response)) {
            const upload_file_hash = await hashFile(myStudentData);
            const [file] = await api
              .list({ upload_file_hash })
              .then((response) => response.data);
            suggestedLoans = file.suggested_data.loans;
          } else {
            const message =
              getUploadErrorMessage(e.response) ||
              "We couldn't process your file. Please try again, and contact us if this problem persists.";
            setCurrentState({ state: "error", data: message });
            return;
          }
        }
        if (suggestedLoans.length) {
          setCurrentState({ state: "success", data: suggestedLoans });
        } else {
          setCurrentState({ state: "error" });
        }
      };

      doUpload();
    }, []);

    return (
      <div
        className={cn(
          styles.root,
          overrideStyles.nonInteractive,
          styles.FileUploader
        )}
      >
        <FaSpinner className={cn(styles.Icon, overrideStyles.loading)} />
        <div className={styles.IconLabel}>
          Just a minute... we&rsquo;re uploading your file
        </div>
      </div>
    );
  }

  switch (currentState.state) {
    case "neutral":
      return <NeutralView />;
    case "uploading":
      return <UploadingView myStudentData={currentState.data} />;
    case "error":
      return <ErrorView message={currentState.data} />;
    case "success":
      return <SuccessView studentLoanSuggestions={currentState.data} />;
  }
}
