import { CategoryDetail } from "components/FormModules/Assets/HouseholdItems/CategorySection/CategoryDetail";
import { HouseholdItemCategoryChoice } from "globalTypes";
import * as React from "react";
import { FaTableTennis } from "react-icons/fa";

const Examples = () => (
  <div>
    <p>Examples include:</p>
    <ul>
      <li>Sports, photographic, exercise, and other hobby equipment</li>
      <li>Bicycles, pool tables, golf clubs, skis</li>
      <li>Canoes and kayaks</li>
      <li>Carpentry tools</li>
      <li>Musical instruments</li>
    </ul>
  </div>
);

const SportsHobbyEquipmentDetail: CategoryDetail = {
  category: HouseholdItemCategoryChoice.SportHobbyEquipment,
  displayName: "Sports and Hobby Equipment",
  displayLowercaseName: "sports and hobby equipment",
  hasNoProfileBooleanName: "hasNoHouseholdSportHobbyEquipmentItems",
  singleInterrogativeText: "Tell us about this equipment.",
  noAssetDeclarativeText:
    "You have indicated that neither you nor any member of your household owns sports or hobby equipment.",
  booleanInterrogativeText: (
    <>
      <p className="required">
        <strong>
          Do you or any members of your household own any sports or hobby
          equipment?
        </strong>
      </p>
      <Examples />
    </>
  ),
  declarativeText: (
    <>
      <p className="required">
        <strong>
          Please itemize all of the sports and hobby equipment you and your
          family members own.
        </strong>
      </p>
      <Examples />
    </>
  ),
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add items below.",
  urlPathName: "sports_hobby_equipment",
  displayMenuName: "Sports & hobby equipment",
  menuIcon: FaTableTennis,
};

export default SportsHobbyEquipmentDetail;
