import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import {
  createCacheListUpdaters,
  useDelete,
  useUpsert,
} from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_FINANCIAL_ACCOUNTS from "./DeleteFinancialAccount.gql";
import GET_FINANCIAL_ACCOUNTS from "./GetFinancialAccount.gql";
import LIST_FINANCIAL_ACCOUNTS from "./ListFinancialAccount.gql";
import UPSERT_FINANCIAL_ACCOUNTS from "./UpsertFinancialAccount.gql";

const LIST_FINANCIAL_ACCOUNTS_IDS = gql`
  query ListFinancialAccountIds {
    financialAccounts {
      id
    }
  }
`;

export const [onFinancialAccountAdded, onFinancialAccountRemoved] =
  createCacheListUpdaters<UpsertFinancialAccount["financialAccount"]>(
    LIST_FINANCIAL_ACCOUNTS_IDS,
    "financialAccounts"
  );

export function useListFinancialAccount(
  options?: QueryHookOptions<ListFinancialAccount>
) {
  return useQuery<ListFinancialAccount>(LIST_FINANCIAL_ACCOUNTS, options);
}

export function useGetFinancialAccount(
  id: string,
  options?: QueryHookOptions<GetFinancialAccount>
) {
  return useQuery<GetFinancialAccount, GetFinancialAccountVariables>(
    GET_FINANCIAL_ACCOUNTS,
    {
      ...options,
      variables: { id },
    }
  );
}

export function useUpsertFinancialAccount<
  Schema extends { financialAccount: any } = UpsertFinancialAccount,
  Variables = UpsertFinancialAccountVariables
>(query = UPSERT_FINANCIAL_ACCOUNTS) {
  return useUpsert<
    Schema,
    Variables,
    ListFinancialAccountIds,
    FinancialAccountInput
  >(
    query,
    LIST_FINANCIAL_ACCOUNTS_IDS,
    "financialAccount",
    "financialAccounts",
    (cache) => updateFilingProfileCache(cache, "hasNoFinancialAccounts", false)
  );
}

export function useDeleteFinancialAccount() {
  return useDelete<DeleteFinancialAccount, ListFinancialAccountIds>(
    DELETE_FINANCIAL_ACCOUNTS,
    LIST_FINANCIAL_ACCOUNTS_IDS,
    "deleteFinancialAccount",
    "financialAccounts"
  );
}
