import {
  AutocompleteVehicleMake,
  AutocompleteVehicleModel,
} from "components/autocomplete/Vehicle";
import { NumberInput } from "components/NumberInput";
import { TextInput } from "components/TextInput";
import React from "react";
import { useFormContext } from "react-hook-form";
import { number, object, string } from "yup";

export const VehicleSchema = object()
  .shape(
    {
      year: number()
        .min(1920, "Model year can’t be before 1920.")
        .max(new Date().getFullYear() + 1, "Model year can’t be in the future.")
        .required("Please enter the model year."),
      make: string().required("Please enter the make."),
      model: string().required("Please enter the model."),
      vin: string()
        .nullable()
        .notRequired()
        .when("vin", {
          is: (val: string) => val?.length,
          then: (rule: any) => rule.length(17, "VIN must be 17 characters."),
        }),
      mileage: number()
        .min(0, "Mileage can’t be less than 0.")
        .required("Please enter the number of miles driven on this vehicle."),
    },
    [["vin", "vin"]]
  )
  .required();

interface Props {
  name?: string;
  autoFocus?: boolean;
}

export default function VehicleForm({
  name = "vehicle",
  autoFocus = false,
}: Props) {
  const form = useFormContext();
  const make = form.watch(`${name}.make`) as string | undefined;

  return (
    <div className="form-row">
      <NumberInput
        name={`${name}.year`}
        autoFocus={autoFocus}
        label="Model Year"
        width="130px"
        integerLimit={4}
      />
      <TextInput
        name={`${name}.make`}
        label="Make"
        width="170px"
        autoComplete="off"
      />
      <AutocompleteVehicleMake
        inputSelector={`[name="${name}.make"]`}
        onSelectMake={React.useCallback(
          (make: string) => {
            form.setValue(`${name}.make`, make);
            form.setValue(`${name}.model`, "");
          },
          [form]
        )}
      />

      <TextInput
        name={`${name}.model`}
        width="170px"
        label="Model"
        autoComplete="off"
      />
      <AutocompleteVehicleModel
        inputSelector={`[name="${name}.model"]`}
        onSelectModel={React.useCallback(
          (model: string) => {
            form.setValue(`${name}.model`, model);
          },
          [form]
        )}
        make={make}
      />
      <TextInput
        name={`${name}.vin`}
        width="200px"
        label="VIN"
        autoComplete="off"
      />

      <NumberInput
        name={`${name}.mileage`}
        label="How many miles are on it?"
        width="180px"
        data-testid="mileage"
        addOnAfter="miles"
        includeThousandsSeparator
      />
    </div>
  );
}
