import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaBoxes } from "react-icons/fa";
import { StorageContainer } from "./StorageContainers";
import StorageSummary from "./Summary";

const StorageSubmodule: SubModule = {
  path: "storage",
  icon: FaBoxes,
  title: "Storage",
  component: StorageContainer,
  summary: StorageSummary,
  statusName: "storageContainers",
};

export default StorageSubmodule;
