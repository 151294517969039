import { useMutation } from "@apollo/client";
import { CreditReportStatus } from "api/graphql/__generated__";
import { Card } from "components/Card";
import { Form } from "components/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextInput } from "components/TextInput";
import { WizardNavigation } from "components/WizardNavigation";
import gql from "graphql-tag";
import { FaArrowRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { breakpoints, useBreakpoint } from "utils/hooks";
import { object, string } from "yup";
import LearnMore from "./CreditReportsLearnMore";
import PasswordResetLink from "./reset";
import { SignupLink } from "./signup";

export interface Props {
  index: string;
}

const PULL_CREDIT_REPORT = gql`
  mutation PullCreditReport($email: String!, $password: String!) {
    pullCreditReport(email: $email, password: $password)
  }
`;

export default function LoginView(props: Props) {
  const [pullCreditReport] = useMutation<
    PullCreditReport,
    PullCreditReportVariables
  >(PULL_CREDIT_REPORT);
  const isMobile = useBreakpoint(breakpoints.mobile);
  const history = useHistory();

  const formSchema = object({
    email: string().email().required(),
    password: string().required(),
  }).required();
  const form = useForm({ resolver: yupResolver(formSchema) });

  return (
    <div style={{ maxWidth: "400px", width: "100%", marginTop: "10px" }}>
      <p>
        To import your credit report, log in with your{" "}
        <a
          href="https://credit.lexria.com/customer_login.asp"
          target="_blank"
          rel="noreferrer"
        >
          credit.lexria.com
        </a>{" "}
        username and password{" "}
        <span style={{ whiteSpace: "nowrap" }}>
          (<LearnMore />)
        </span>
        .
      </p>
      <Card style={{ marginTop: "16px", marginBottom: "16px" }}>
        <Form
          useForm={form}
          onSubmit={async (values: { email: string; password: string }) => {
            const { email, password } = values;
            try {
              await pullCreditReport({
                variables: { email, password },
                update: (cache, result) => {
                  if (result.data) {
                    cache.modify({
                      fields: {
                        pullCreditReportStatus: () =>
                          CreditReportStatus.Pending,
                      },
                    });
                  }
                },
              });
              setTimeout(() => {
                history.push(props.index, 0);
              });
            } catch (e) {
              console.error(e);
            }
          }}
        >
          <TextInput name="email" label="Email" />
          <TextInput
            name="password"
            label={
              (
                <>
                  Password (
                  <PasswordResetLink>reset my password</PasswordResetLink>)
                </>
              ) as any
            }
            type="password"
          />
          <WizardNavigation
            isComplete
            saveText="Import my credit report"
            saveIcon={<FaArrowRight />}
          />
        </Form>
      </Card>
      <p>
        If you didn’t fully complete the sign up process or still need to sign
        up, <SignupLink>{isMobile ? "tap" : "click"} here</SignupLink>.
      </p>
    </div>
  );
}
