import { useQuery } from "@apollo/client";
import { useDeleteBorrowedAsset } from "api/graphql/BorrowedAsset";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema, formatName } from "utils/formatters";
import { useNested } from "utils/hooks";
import BORROWED_ASSETS_MODULE from "./BorrowedAssetsModule.gql";
import { BorrowedAssetsSchema } from "./BorrowedAssetWizard";

interface ListViewProps {
  createURL: string;
  nextURL: string;
  prevURL: string;
  onEdit: (id: string) => any;
}

export function ListView(props: ListViewProps) {
  const { data } = useQuery<BorrowedAssetsModule>(BORROWED_ASSETS_MODULE);
  const [onDelete] = useDeleteBorrowedAsset();
  const { path, url } = useNested();

  if (!data) return <Loading />;
  const { borrowedAssets: items } = data;

  return (
    <>
      <Title>Your Held Assets</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              createURL={props.createURL}
              nextURL={props.nextURL}
              filingProfileBoolean="hasNoBorrowedAssets"
              addText="Add held assets"
              question={
                <p className="required">
                  <strong>
                    Do you have any property in your possession that you
                    borrowed from someone else, are storing for someone else, or
                    hold in trust for someone else?
                  </strong>
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you don’t have someone else’s property
                  in your possession or trust. If that’s correct, you’re ready
                  to continue to the next section. If you do have borrowed
                  property, you may add it below.
                </p>
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Please describe all the property in your possession that you
                have borrowed from, are storing for, or hold in trust for
                someone else.
              </p>
              <StatelessList
                {...props}
                emptyText="You haven’t added any borrowed property."
                addButtonText="Add held assets"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(a) => {
                  return (
                    <div>
                      {formatErrorsFromSchema(BorrowedAssetsSchema, a)}
                      <strong>
                        Items worth ${a.totalValue} borrowed from{" "}
                        {formatName(a.owner)}
                      </strong>
                      <div className="supporting-text">{a.items}</div>
                    </div>
                  );
                }}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
