import { useQuery } from "@apollo/client";
import { useDeleteFamilyMemberRecord } from "api/graphql/FamilyProfile";
import { ListViewProps } from "components/EditCreateList/stateless";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import React from "react";
import { Redirect } from "react-router-dom";
import { formatName } from "utils/formatters";
import { useNested } from "utils/hooks";
import OTHER_FAMILY_MODULE from "./OtherFamilyModule.gql";

export function ListView(props: ListViewProps) {
  const { data, loading } = useQuery<OtherFamilyModule>(OTHER_FAMILY_MODULE);
  const [onDelete] = useDeleteFamilyMemberRecord();
  const { url } = useNested();

  if (loading) return <Loading />;
  const items = data.familyProfile.familyMembers;
  const empty = !items.length;

  return (
    <>
      <Title>Other Family Members</Title>
      {!empty && (
        <p>
          Please list all family members who live with you, and all of your
          dependents regardless of whether they live with you or someone else.
        </p>
      )}
      {empty && <Redirect to={url("../query")} />}

      <StatelessList
        {...props}
        emptyText="You haven’t added any dependents yet."
        addButtonText="Add a family member"
        items={items}
        deleteItem={({ id }) => onDelete(id)}
        renderItem={({ relatedPerson, relationship }) => (
          <div>
            <strong>
              {formatName(relatedPerson)} ({relationship})
            </strong>
          </div>
        )}
      />
    </>
  );
}
