import { Card } from "components/Card";
import { EmailLink } from "components/ContactUs/ContactTypes";
import { OptionsList } from "components/OptionCard";
import React from "react";
import { Helmet } from "react-helmet";
import css from "../styles.module.css";

export function NewBankruptcyDenied() {
  return (
    <>
      <Helmet>
        <title>Debt relief through bankruptcy</title>
      </Helmet>

      <div className={css.container}>
        <Card className={css.infoCard}>
          <h3>We&rsquo;re sorry</h3>
          <p>
            Based on the data contained in your submission and the criteria set
            by your lawyer, we&rsquo;ve determined that you do not meet your
            attorney&rsquo;s requirements to move forward into bankruptcy. If
            your circumstances change, please let us know via a written, long
            form email.
          </p>
          <OptionsList>
            <EmailLink />
          </OptionsList>
        </Card>
      </div>
    </>
  );
}
