import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaBalanceScale } from "react-icons/fa";
import { BankruptcyCases } from "./BankruptcyCases";
import BankruptcyCasesSummary from "./Summary";

const BankruptcyCasesSubmodule: SubModule = {
  path: "prior_bankruptcies",
  icon: FaBalanceScale,
  title: "Prior Bankruptcies",
  component: BankruptcyCases,
  summary: BankruptcyCasesSummary,
  statusName: "bankruptcyCases",
};

export default BankruptcyCasesSubmodule;
