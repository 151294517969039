import { useUpsertTaxDebt } from "api/graphql/TaxDebt";
import { CreateView as CreateTaxReturnView } from "components/FormModules/TaxReturns/CreateView";
import { EditView as EditTaxReturnView } from "components/FormModules/TaxReturns/EditView";
import { CollectorForm } from "components/FormSections/Collector";
import { CosignerForm } from "components/FormSections/Cosigner";
import { Wizard } from "components/Wizard";
import { CollectorsSchema } from "components/WizardSteps/Collectors";
import { CosignersSchema } from "components/WizardSteps/Cosigners";
import React, { useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import Collectors from "./Collectors";
import Cosigners from "./Cosigners";
import Details, { DetailsSchema } from "./Details";
import TaxAuthority, { TaxAuthoritySchema } from "./TaxAuthority";
import TAX_DEBT_WIZARD_TAX_DEBT_UPSERT from "./TaxDebtWizardTaxDebtUpsert.gql";
import TaxReturn, { TaxReturnSchema } from "./TaxReturn";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
}

export const TaxDebtSchema = TaxReturnSchema.concat(TaxAuthoritySchema)
  .concat(DetailsSchema)
  .concat(CosignersSchema)
  .concat(CollectorsSchema);

export function TaxDebtWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: Props) {
  const { path, url } = useNested();
  const [upsertTaxDebt] = useUpsertTaxDebt<
    TaxDebtWizardTaxDebtUpsert,
    TaxDebtWizardTaxDebtUpsertVariables
  >(TAX_DEBT_WIZARD_TAX_DEBT_UPSERT);
  const history = useHistory();
  const [taxDebtId, setTaxDebtId] = useState(initialValue?.id);

  const cosignersURL = "/cosigners";
  const collectorsURL = "/collectors";

  return (
    <Switch>
      <Route
        path={path("/create_tax_return")}
        render={() => (
          <CreateTaxReturnView
            editURL="../edit_tax_return/"
            listURL={url("/tax_return")}
          />
        )}
      />
      <Route
        path={path("/edit_tax_return/:editId")}
        render={(routeProps) => {
          const id = routeProps?.match?.params?.editId;
          return <EditTaxReturnView listURL={url("/tax_return")} id={id} />;
        }}
      />

      <Route
        path={path("/create_cosigner")}
        render={() => (
          <CosignerForm
            title="Add a Tax Debt Co-debtor"
            returnURL={url(cosignersURL)}
            relatedId={taxDebtId}
            relatedTypename="TaxDebt"
          />
        )}
      />
      <Route
        path={path("/edit_cosigner/:cosignerId")}
        render={({ match }) => (
          <CosignerForm
            title="Updating Tax Debt Co-debtor"
            returnURL={url(cosignersURL)}
            relatedId={taxDebtId}
            relatedTypename="TaxDebt"
            id={match.params.cosignerId}
          />
        )}
      />

      <Route
        path={path("/create_collector")}
        render={() => (
          <CollectorForm
            title="Add a Tax Debt Collector"
            returnURL={url(collectorsURL)}
            relatedId={taxDebtId}
            relatedTypename="TaxDebt"
          />
        )}
      />
      <Route
        path={path("/edit_collector/:collectorId")}
        render={({ match }) => (
          <CollectorForm
            title="Updating Tax Debt Collector"
            returnURL={url(collectorsURL)}
            relatedId={taxDebtId}
            relatedTypename="TaxDebt"
            id={match.params.collectorId}
          />
        )}
      />

      <Wizard
        title={title}
        backURL={backURL}
        nextURL={nextURL}
        initialValue={initialValue}
        vertical
        steps={[
          {
            path: "/tax_return",
            title: "Tax Return",
            component: TaxReturn,
            schema: TaxReturnSchema,
          },
          {
            path: "/tax_authority",
            title: "Tax Authority",
            component: TaxAuthority,
            schema: TaxAuthoritySchema,
          },
          {
            path: "/details",
            title: "Tax Debt Details",
            component: Details,
            schema: DetailsSchema,
            async onSave(formState) {
              const { data } = await upsertTaxDebt(formState);
              const { id } = data.taxDebt;
              setTaxDebtId(id);
              if (taxDebtId) {
                history.push(url(cosignersURL));
              } else {
                history.push(`../edit/${id}${cosignersURL}`);
              }
              return data.taxDebt;
            },
          },
          {
            path: cosignersURL,
            title: "Co-debtors",
            component: Cosigners,
            schema: CosignersSchema,
          },
          {
            path: collectorsURL,
            title: "Collectors",
            component: Collectors,
            schema: CollectorsSchema,
          },
        ]}
      />
    </Switch>
  );
}
