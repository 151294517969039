import Axios from "axios";
import Chatwoot from "utils/chatwoot";
import ImportView from "components/ImportView";
import { MobileMenu } from "components/MobileMenu";
import { IgnoredUser } from "containers/holding_pens/IgnoredUser";
import { NewBankruptcyDeclined } from "containers/holding_pens/NewBankruptcyDeclined";
import { NewBankruptcyDenied } from "containers/holding_pens/NewBankruptcyDenied";
import { NewBankruptcyHandoff } from "containers/holding_pens/NewBankruptcyHandoff";
import { NewBankruptcyIdle } from "containers/holding_pens/NewBankruptcyIdle";
import { NewBankruptcyOnhold } from "containers/holding_pens/NewBankruptcyOnhold";
import { SettlementAssessment } from "containers/holding_pens/SettlementAssess";
import { LawyerPortal } from "containers/LawyerPortal";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import ReactNotification from "react-notifications-component";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ApplicationSubmitted } from "./ApplicationSubmitted";
import { PreBankruptcyForm } from "./PreBankruptcyForm";

function App() {
  return (
    <>
      <Helmet titleTemplate="%s | Lexria"></Helmet>
      <Router>
        <ReactNotification />
        <MobileMenu />
        <Chatwoot />
        <Switch>
          <Route path="/state-error" component={IgnoredUser} />
          <Route path="/new-declined" component={NewBankruptcyDeclined} />
          <Route path="/new-denied" component={NewBankruptcyDenied} />
          <Route path="/new-handoff" component={NewBankruptcyHandoff} />
          <Route path="/new-idle" component={NewBankruptcyIdle} />
          <Route path="/new-onhold" component={NewBankruptcyOnhold} />
          <Route path="/new-filing-review" component={ApplicationSubmitted} />
          <Route path="/new-filing/financial-import" component={ImportView} />
          <Route path="/new-filing" component={PreBankruptcyForm} />
          <Route path="/settlement-gather" component={SettlementAssessment} />
          <Route path="/law_firm" component={LawyerPortal} />
        </Switch>
      </Router>
    </>
  );
}

// Set up axios CSRF token
Axios.defaults.headers.common["X-CSRFToken"] = Cookies.get("csrftoken");

export default App;
