import { LearnMore } from "components/LearnMore";
import { DecimalInput } from "components/NumberInput";
import { Select } from "components/Select";
import { TextArea } from "components/TextArea";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import {
  OtherIncomeCategoryChoice,
  OtherIncomeFrequencyChoice,
} from "globalTypes";
import * as React from "react";
import { required } from "utils/defaultMessages";
import { useQueryParams } from "utils/hooks";
import { DecimalSchema } from "utils/validators/yup";
import { object, string } from "yup";
import { incomeTypes, otherIncomeFrequencies } from "../utils";

export const AboutSchema = object({
  category: string().oneOf(
    Object.values(OtherIncomeCategoryChoice),
    "Please select what type of income this is."
  ),
  frequency: string().oneOf(
    Object.values(OtherIncomeFrequencyChoice),
    "Please select the frequency with which you receive this income."
  ),
  amount: DecimalSchema.required("Please enter the amount you receive."),
  description: string().when("category", {
    is: OtherIncomeCategoryChoice.Other,
    then: string().required("Please enter a description of this income."),
    otherwise: string().optional().nullable(),
  }),
}).required();

export function About({ form, isEdit, navProps }: WizardRouteChildProps) {
  const { type } = useQueryParams();
  const [initialCategory] = React.useState(form.watch("category"));
  const category = initialCategory || type;
  const isOtherType =
    form.watch("category") === OtherIncomeCategoryChoice.Other;

  return (
    <>
      <p>
        Let&rsquo;s get some information about this income source.{" "}
        <LearnMore>
          <h4>What if the time between payments varies?</h4>
          <p>Pick the one that fits most closely.</p>
          <h4>How should I calculate the amount received?</h4>
          <p>
            Add up the gross income from this source for the last six months.
            Gross income is the total income prior to withholdings for taxes,
            health insurance, retirement savings, or anything similar.
          </p>
        </LearnMore>
      </p>
      {category ? (
        <input
          type="hidden"
          name="category"
          value={category}
          ref={form.register}
        />
      ) : (
        <Select name="category" label="Income type" options={incomeTypes} />
      )}
      <div className="form-row">
        <DecimalInput
          name="amount"
          label={
            category === OtherIncomeCategoryChoice.MoneyOwed
              ? "How much is owed to you?"
              : "Gross amount in last 6 mos."
          }
          addOnBefore="$"
          width="200px"
          autoFocus={!isEdit}
          allowDecimal
          includeThousandsSeparator
        />
        <Select
          name="frequency"
          label="Frequency"
          options={Object.entries(otherIncomeFrequencies).map(([k, v]) => ({
            value: k,
            label: v,
          }))}
          rules={{ required }}
          width="200px"
        />
      </div>
      <TextArea
        name="description"
        label={`Description ${isOtherType ? "" : "(optional)"}`}
        rules={{ required: isOtherType }}
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
