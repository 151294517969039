import * as React from "react";

export function useCheckMounted() {
  const mounted = React.useRef(true);
  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  return React.useCallback(() => mounted.current, []);
}
