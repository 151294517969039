import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaUser } from "react-icons/fa";
import { AboutYou } from "./AboutYou";
import AboutYouSummary from "./Summary";

const AboutYouSubmodule: SubModule = {
  path: "you",
  title: "You",
  icon: FaUser,
  component: AboutYou,
  summary: AboutYouSummary,
  statusName: "aboutYou",
};

export default AboutYouSubmodule;
