import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, { SummaryStatsRow } from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import HOUSEHOLD_ITEMS_MODULE from "./HouseholdItemsModule.gql";

export default function HouseholdItemsSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<HouseholdItemsModule>(HOUSEHOLD_ITEMS_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.householdItems}>
      <SummaryStatsRow>
        <NumericSummary
          label="valuable item"
          maxValueLength={2}
          value={data?.householdItems?.length}
          pluralizeLabel
        />

        <NumericSummary
          label="total value"
          maxValueLength={5}
          valuePrefix="$"
          value={fancyNumberSum(data?.householdItems, "currentValue")}
        />
      </SummaryStatsRow>
    </ModuleSummary>
  );
}
