import { useEditorBuffer } from "components/EditorState/hooks";
import { WizardRouteChildProps } from "components/Wizard";
import CofilersWizardStep from "components/WizardSteps/Cofilers";
import * as React from "react";
import { array, bool, boolean, object } from "yup";

export const AddCofilerSchema = object({
  hasNoCofilers: boolean().required("This question is required."),
  cofilers: array().when("hasNoCofilers", {
    is: (v) => !v,
    then: array(
      object({
        hasSsnItin: bool().oneOf([true]).required("Cofiler must have SSN/ITIN"),
        mailingAddress: object().required(
          "Cofiler must have a mailing address"
        ),
      }).required()
    ).required(),
    otherwise: array().nullable(),
  }),
});

export function AddCofiler(props: WizardRouteChildProps) {
  const [{ buffer }] = useEditorBuffer();
  const caseId = buffer.id;

  return (
    <CofilersWizardStep
      relatedTypename="BankruptcyCase"
      relatedId={caseId}
      {...props}
    />
  );
}
