import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_MORTGAGE from "./DeleteMortgage.gql";
import GET_MORTGAGE from "./GetMortgage.gql";
import LIST_MORTGAGE from "./ListMortgage.gql";
import UPSERT_MORTGAGE from "./UpsertMortgage.gql";

const LIST_MORTGAGE_IDS = gql`
  query ListMortgageIds {
    realEstateMortgages {
      id
    }
  }
`;

export function useListMortgage(options?: QueryHookOptions<ListMortgage>) {
  return useQuery<ListMortgage>(LIST_MORTGAGE, options);
}

export function useGetMortgage(id: string) {
  return useQuery<GetMortgage, GetMortgageVariables>(GET_MORTGAGE, {
    variables: { id },
  });
}

export function useUpsertMortgage<
  Schema extends { realEstateMortgage: any } = UpsertMortgage,
  Variables = UpsertMortgageVariables
>(query = UPSERT_MORTGAGE) {
  return useUpsert<Schema, Variables, ListMortgageIds, RealEstateMortgageInput>(
    query,
    LIST_MORTGAGE_IDS,
    "realEstateMortgage",
    "realEstateMortgages",
    (cache) =>
      updateFilingProfileCache(cache, "hasNoRealEstateMortgages", false)
  );
}

export function useDeleteMortgage() {
  return useDelete<DeleteMortgage, ListMortgageIds>(
    DELETE_MORTGAGE,
    LIST_MORTGAGE_IDS,
    "deleteRealEstateMortgage",
    "realEstateMortgages"
  );
}
