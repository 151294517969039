import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import FINANCIAL_INVESTMENTS_MODULE from "./FinancialInvestmentModule.gql";

export default function FinancialInvestmentsSummary(
  props: SubModuleSummaryProps
) {
  const { data } = useQuery<FinancialInvestmentModule>(
    FINANCIAL_INVESTMENTS_MODULE,
    { returnPartialData: true }
  );

  return (
    <ModuleSummary {...props} progress={data?.progress.financialInvestments}>
      {data?.filingProfile?.hasNoFinancialInvestments ? (
        <HasNoSummary>
          Your household doesn&rsquo;t have any financial investments.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="investment"
            maxValueLength={2}
            value={data?.financialInvestments?.length}
            pluralizeLabel
          />
          <NumericSummary
            label="total value"
            valuePrefix="$"
            maxValueLength={7}
            value={fancyNumberSum(data?.financialInvestments, "currentValue")}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
