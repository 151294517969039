import cn from "classnames";
import * as React from "react";
import s from "./ButtonRow.module.css";

export type ButtonRowDOMType = HTMLDivElement;

interface ButtonRowProps extends React.HTMLAttributes<ButtonRowDOMType> {
  /** Buttons or other react nodes to align left. */
  left?: React.ReactNode;
  /** Buttons or other react nodes to align right. */
  right?: React.ReactNode;
  /** Reverses the tab order of the buttons. Useful if most common actions are on the right. */
  reverse?: boolean;
  noMargin?: boolean;
}

function ButtonRow(
  { left, right, reverse, noMargin, className, ...etc }: ButtonRowProps,
  ref?: React.RefObject<any>
) {
  return (
    <div className={cn(className, s.buttonRow, { reverse })} {...etc} ref={ref}>
      {!reverse && (
        <div className={cn(s.left, { [s.noMargin]: noMargin })}>{left}</div>
      )}
      <div className={cn(s.right, { [s.noMargin]: noMargin })}>{right}</div>
      {reverse && (
        <div className={cn(s.left, { [s.noMargin]: noMargin })}>{left}</div>
      )}
    </div>
  );
}

const ButtonRowWithRef = React.forwardRef(ButtonRow);

export { ButtonRowWithRef as ButtonRow };
