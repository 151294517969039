import cn from "classnames";
import { FormGroup } from "components/FormGroup";
import * as React from "react";
import { RegisterOptions } from "react-hook-form/dist/types";
import { FaCaretDown } from "react-icons/fa";
import { useFormField } from "utils/hooks";
import styles from "./Select.module.css";

export type SelectOption = { value: string; label: string };

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name?: string;
  options: SelectOption[];
  label?: string;
  learnMore?: React.ReactNode;
  helpText?: React.ReactNode;
  rules?: RegisterOptions;
  className?: string;
  width?: string;
  emptyOption?: boolean;
}

export function Select({
  name,
  rules,
  label = "",
  className = "",
  width,
  options,
  helpText,
  learnMore,
  style,
  id,
  emptyOption = true,
  ...selectProps
}: SelectProps) {
  const { inputProps, error, Label, initialValue, form } = useFormField({
    name,
    label,
    rules,
  });

  React.useEffect(() => {
    // restore the initial value when options update (i.e. from async loading)
    if (form && options.length && !!initialValue && form.watch(name) === "") {
      form.setValue(name, initialValue);
    }
  }, [options]);

  return (
    <FormGroup
      id={id}
      className={cn(styles.wrapper, className)}
      style={{ ...style, width }}
      label={label && <Label />}
      helpText={helpText}
      learnMore={learnMore}
      error={error}
    >
      <div className={styles.selectContainer}>
        <select {...selectProps} {...inputProps}>
          {emptyOption && <option value=""></option>}
          {options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <FaCaretDown className={styles.dropdownArrow} />
      </div>
    </FormGroup>
  );
}
