import { LearnMore } from "components/LearnMore";
import { MonthlyExpenseCategoryChoice } from "globalTypes";
import React from "react";
import { FaBolt, FaDumpster, FaTint, FaToilet } from "react-icons/fa";
import { ExpensesCategoryDetail } from "../CategorySection/CategoryDetail";
import { LearnMoreCommon } from "../LearnMoreCommon";

export const electricUtilitiesCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.UtilityElectricHeat,
  displayName: "Electricity & Heating ",
  displayLowercaseName: "electric and heating utilities",
  hasNoProfileBooleanName: "hasNoUtilityElectricAndHeatingExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on electric and heating utilities over the last six months? Be sure to include spending for all household members.",
  urlPathName: "electricity",
  menuIcon: FaBolt,
  description: (
    <>
      <p>
        Electricity and heating expenses should be limited to whatever payments
        are necessary to provide power, heating, and cooling to property someone
        in your household owns or rents.
      </p>

      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};

export const sewerUtilitiesCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.UtilitySewer,
  displayName: "Sewer",
  displayLowercaseName: "sewer utilities",
  hasNoProfileBooleanName: "hasNoUtilitySewerExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on sewer utilities over the last six months? Be sure to include spending for all household members.",
  urlPathName: "sewer",
  menuIcon: FaToilet,
  description: (
    <>
      <p>
        Sewer utilities expenses should be limited to whatever payments are
        necessary to provide sewer utilities to property someone in your
        household owns.
      </p>

      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};

export const trashUtilitiesCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.UtilityTrash,
  displayName: "Garbage",
  displayLowercaseName: "garbage utilities",
  hasNoProfileBooleanName: "hasNoUtilityTrashExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on garbage utilities over the last six months? Be sure to include spending for all household members.",
  urlPathName: "garbage",
  menuIcon: FaDumpster,
  description: (
    <>
      <p>
        Garbage expenses should be limited to whatever payments are necessary to
        provide waste removal utilities to property someone in your household
        owns or rents.
      </p>

      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};

export const waterUtilitiesCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.UtilityWater,
  displayName: "Water",
  displayLowercaseName: "water utilities",
  hasNoProfileBooleanName: "hasNoUtilityWaterExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on water utilities over the last six months? Be sure to include spending for all household members.",
  urlPathName: "water",
  menuIcon: FaTint,
  description: (
    <>
      <p>
        Water expenses should be limited to whatever payments are necessary to
        provide water utilities to property someone in your household owns or
        rents.
      </p>
      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};
