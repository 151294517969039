import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  startOfToday,
} from "date-fns";
import { OptionalKeys } from "utils/types";

export enum TimeUnit {
  Year = "year",
  Month = "month",
  Week = "week",
  Day = "day",
}

export type Duration = {
  length: number;
  unit: TimeUnit;
};

export type DateInterval = {
  start: Date;
  end: Date;
  startInclusive?: boolean;
  endInclusive?: boolean;
};

export const addDuration = (date: Date, duration: Duration) => {
  let operator;
  switch (duration.unit) {
    case TimeUnit.Day:
      operator = addDays;
      break;
    case TimeUnit.Week:
      operator = addWeeks;
      break;
    case TimeUnit.Month:
      operator = addMonths;
      break;
    case TimeUnit.Year:
      operator = addYears;
      break;
  }
  return operator(date, duration.length);
};

export const subDuration = (date: Date, duration: Duration) =>
  addDuration(date, { ...duration, length: -duration.length });

export const getDateIntervalDuration = (
  interval: DateInterval,
  { length = 1, unit }: OptionalKeys<Duration, "length">
) => {
  let comparator;
  switch (unit) {
    case TimeUnit.Day:
      comparator = differenceInDays;
      break;
    case TimeUnit.Week:
      comparator = differenceInWeeks;
      break;
    case TimeUnit.Month:
      comparator = differenceInMonths;
      break;
    case TimeUnit.Year:
      comparator = differenceInYears;
      break;
  }

  const start = interval.startInclusive
    ? interval.start
    : addDuration(interval.start, { length, unit });

  const end = interval.endInclusive
    ? interval.end
    : subDuration(interval.end, { length, unit });

  return {
    length: Math.floor(comparator(end, start) / length),
    unit,
  } as Duration;
};

export const countDurationsInDateInterval = (
  duration: Duration,
  interval: DateInterval,
  covering = false
) => {
  const totalTimeElapsed = getDateIntervalDuration(interval, {
    unit: duration.unit,
  }); // in duration units
  const durationsElapsed = totalTimeElapsed.length / duration.length;
  const exact = durationsElapsed - Math.floor(durationsElapsed) === 0;

  if (exact) {
    return durationsElapsed;
  }

  return Math.floor(durationsElapsed) + (covering ? 1 : 0);
};

export const getPastDateIntervalFromDuration = (
  duration: Duration,
  includesToday = true
) => {
  const end = startOfToday();
  const start = subDuration(end, duration);
  return {
    start,
    end,
    startInclusive: true,
    endInclusive: includesToday,
  } as DateInterval;
};

export const getDateInterval = (
  start = startOfToday(),
  end = startOfToday(),
  startInclusive = true,
  endInclusive = true
): DateInterval => ({ start, end, startInclusive, endInclusive });
