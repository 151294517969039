import { useUpsertInsurancePolicy } from "api/graphql/InsurancePolicy";
import { Wizard } from "components/Wizard";
import React, { ReactNode, useState } from "react";
import { useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import Documentation, { DocumentationSchema } from "./Documentation";
import Policy, { PolicySchema } from "./Policy";
import SurrenderValue, { SurrenderValueSchema } from "./SurrenderValue";

interface Props {
  type: InsurancePolicyType;
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  /** The URL to switch to after the initial save. Created object ID will be appended */
  editURL?: string;
  subtitle?: ReactNode;
}

export const InsurancePolicySchema = PolicySchema.concat(
  SurrenderValueSchema
).concat(DocumentationSchema);

export default function InsurancePolicyWizard({
  title,
  subtitle,
  backURL,
  nextURL,
  initialValue,
  editURL = "edit",
  type,
}: Props) {
  const history = useHistory();
  const { url } = useNested();
  const [upsertPolicy] = useUpsertInsurancePolicy();
  const [policyId, setPolicyId] = useState(initialValue?.id);

  const needsBeneficiaryInfo = ["TERM_LIFE", "WHOLE_LIFE"].includes(type);

  const onSave = async (formState: Omit<InsurancePolicyInput, "type">) => {
    const { data } = await upsertPolicy(
      {
        ...formState,
        type,
      },
      undefined,
      ({ categoryFilter }) => categoryFilter === type || !categoryFilter
    );

    const { id } = data.insurancePolicy;
    setPolicyId(id);
    setTimeout(() => {
      if (policyId) {
        history.push(url("/documentation"));
      } else {
        history.push(`../${editURL}/${id}/documentation`);
      }
    }, 1);
    return data.insurancePolicy;
  };

  return (
    <Wizard
      title={title}
      subtitle={subtitle}
      backURL={backURL}
      nextURL={nextURL}
      initialValue={initialValue || { type }}
      vertical
      steps={[
        {
          path: "/policy",
          title: "Details",
          component: Policy,
          schema: PolicySchema,
          onSave: needsBeneficiaryInfo ? undefined : onSave,
        },
        needsBeneficiaryInfo && {
          path: "/value",
          title: "Value",
          component: SurrenderValue,
          schema: SurrenderValueSchema,
          onSave,
        },
        {
          path: "/documentation",
          title: "Documentation",
          component: Documentation,
          schema: DocumentationSchema,
        },
      ]}
    />
  );
}
