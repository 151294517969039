import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, { SummaryStatsRow } from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import HOUSEHOLD_EXPENSES_MODULE from "./ExpensesModule.gql";

export default function HouseholdExpensesSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<ExpensesModule>(HOUSEHOLD_EXPENSES_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.monthlyExpenses}>
      <SummaryStatsRow>
        <NumericSummary
          label="expense"
          maxValueLength={2}
          value={data?.monthlyExpenses?.length}
          pluralizeLabel
        />
        <NumericSummary
          label="per month"
          valuePrefix="$"
          maxValueLength={5}
          value={fancyNumberSum(data?.monthlyExpenses, "amount")}
        />
      </SummaryStatsRow>
    </ModuleSummary>
  );
}
