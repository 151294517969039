import { DateInput } from "components/DateInput";
import { TextInputCommonProps } from "components/TextInput";
import {
  differenceInYears,
  isValid,
  parseISO,
  startOfToday,
  subYears,
} from "date-fns";
import * as React from "react";
import { useFormField } from "utils/hooks";

type ErrorMessage = string | ((difference: number) => string);

interface BirthdayInputProps extends TextInputCommonProps {
  errorMessage?: ErrorMessage;
}

const getOldEnough = (message: ErrorMessage) => (isoDate: string) => {
  const date = parseISO(isoDate);
  if (!isValid(date)) return true;

  const age = differenceInYears(startOfToday(), date);
  const difference = 18 - age;

  if (age >= 18) {
    return true;
  } else if (typeof message === "function") {
    return message(difference);
  } else if (typeof message === "string") {
    return message;
  }
};

const defaultBirthdayErrorMessage = (difference: number) =>
  `You must be at least 18 years of age to use Lexria. Come back in ${
    difference === 1 ? "a year" : `${difference} years`
  }!`;

export function BirthdayInput({
  name,
  label,
  width,
  rules = {},
  errorMessage = defaultBirthdayErrorMessage,
  ...etc
}: BirthdayInputProps) {
  const { form } = useFormField({ name });
  const oldEnough = getOldEnough(errorMessage);
  const validate = rules.validate
    ? { ...rules.validate, oldEnough }
    : { oldEnough };

  let initialMonth = subYears(startOfToday(), 30);
  if (form && form.getValues()[name]) {
    initialMonth = undefined;
  }

  return (
    <DateInput
      name={name}
      label={label}
      rules={{
        ...rules,
        validate,
      }}
      disabledDays={{
        "You must have been born to use Lexria! Check your birth year.": {
          after: startOfToday(),
        },
      }}
      dayPickerProps={{
        initialMonth,
      }}
      inputProps={{
        autoComplete: "bday",
      }}
      width={width}
      {...etc}
    />
  );
}
