import { QueryHookOptions, useQuery } from "@apollo/client";
import { DocumentNode } from "graphql";
import gql from "graphql-tag";
import { CacheUpdater, useUpsert } from "../utils";
import CREATE_OTHER_PERSON from "./CreateOtherPerson.gql";
import GET_OTHER_PERSON from "./GetOtherPerson.gql";
import LIST_OTHER_PERSONS from "./ListOtherPersons.gql";

const LIST_OTHER_PERSON_IDS = gql`
  query ListOtherPersonIds {
    otherPersons {
      id
    }
  }
`;

export function useUpsertOtherPerson<
  Schema = CreateOtherPerson,
  Variables = CreateOtherPersonVariables
>(
  query?: DocumentNode,
  mutationReturnProp?: keyof Schema,
  customUpdate?: (cache: CacheUpdater, data: Schema) => any
) {
  return useUpsert<Schema, Variables, ListOtherPersonIds, OtherPersonInput>(
    query || CREATE_OTHER_PERSON,
    LIST_OTHER_PERSON_IDS,
    mutationReturnProp || ("otherPerson" as any),
    "otherPersons",
    customUpdate
  );
}

export function useGetOtherPerson(
  id: string,
  options?: QueryHookOptions<GetOtherPerson, GetOtherPersonVariables>
) {
  return useQuery<GetOtherPerson, GetOtherPersonVariables>(GET_OTHER_PERSON, {
    variables: { id },
    ...(options ? options : {}),
  });
}

export function useListOtherPersons() {
  return useQuery<ListOtherPersons>(LIST_OTHER_PERSONS);
}
