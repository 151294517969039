import { AddressForm, AddressSchema } from "components/FormSections/Address";
import { HiddenInput } from "components/HiddenInput";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { mixed, object, string } from "yup";
import { FinancialAccountTypeChoice } from "globalTypes";

export const InstitutionSchema = object({
  institutionName: string().required(
    "Please enter the name of the institution holding your account."
  ),
  contactAddress: AddressSchema.required(),
  otherDescription: string().when("accountType", {
    is: FinancialAccountTypeChoice.Other,
    then: string().required(
      "Please enter a description of what this account is for."
    ),
    otherwise: string().nullable(),
  }),
  accountType: mixed<FinancialAccountTypeChoice>()
    .required("This field is required.")
    .oneOf(Object.values(FinancialAccountTypeChoice)),
}).required();

interface InstitutionStepProps extends WizardRouteChildProps {
  accountType: FinancialAccountTypeChoice;
}

export default function Institution({
  isEdit,
  navProps,
  accountType,
}: InstitutionStepProps) {
  const showOtherDescription = accountType === FinancialAccountTypeChoice.Other;

  return (
    <>
      <div className="form-row">
        <TextInput
          name="institutionName"
          label="Institution Name"
          width="200px"
          autoFocus={!isEdit}
        />
      </div>
      {showOtherDescription && (
        <div className="form-row">
          <TextArea
            name="otherDescription"
            label="Description"
            placeholder="Tell us about what this financial account is for."
          />
        </div>
      )}

      <p>
        What is this institution&rsquo;s mailing address? This will allow us to
        contact them, if necessary.
      </p>
      <AddressForm graphQL name="contactAddress" />

      <HiddenInput name="accountType" value={accountType} />
      <WizardNavigation {...navProps} />
    </>
  );
}
