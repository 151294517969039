import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_STUDENT_LOANS from "./DeleteStudentLoan.gql";
import GET_STUDENT_LOANS from "./GetStudentLoan.gql";
import LIST_STUDENT_LOANS from "./ListStudentLoans.gql";
import UPSERT_STUDENT_LOANS from "./UpsertStudentLoan.gql";

const LIST_STUDENT_LOANS_IDS = gql`
  query ListStudentLoanIds {
    studentLoans {
      id
    }
  }
`;

export function useListStudentLoan(
  options?: QueryHookOptions<ListStudentLoans>
) {
  return useQuery<ListStudentLoans>(LIST_STUDENT_LOANS, options);
}

export function useGetStudentLoan(id: string) {
  return useQuery<GetStudentLoan, GetStudentLoanVariables>(GET_STUDENT_LOANS, {
    variables: { id },
  });
}

export function useUpsertStudentLoan<
  Schema extends { studentLoan: any } = UpsertStudentLoan,
  Variables = UpsertStudentLoanVariables
>(query = UPSERT_STUDENT_LOANS) {
  return useUpsert<Schema, Variables, ListStudentLoanIds, StudentLoanInput>(
    query,
    LIST_STUDENT_LOANS_IDS,
    "studentLoan",
    "studentLoans",
    (cache) => updateFilingProfileCache(cache, "hasNoStudentLoans", false)
  );
}

export function useDeleteStudentLoan() {
  return useDelete<DeleteStudentLoan, ListStudentLoanIds>(
    DELETE_STUDENT_LOANS,
    LIST_STUDENT_LOANS_IDS,
    "deleteStudentLoan",
    "studentLoans"
  );
}
