import * as React from "react";
import { Buffer, Meta, Save } from "./contexts";

export const useEditorBuffer = () => React.useContext(Buffer);
export const useEditorMeta = () => React.useContext(Meta);
export const useEditorSave = () => React.useContext(Save);
export const useEditorState = () => ({
  buffer: useEditorBuffer(),
  meta: useEditorMeta(),
  save: useEditorSave(),
});
