import { CategoryDetail } from "components/FormModules/Assets/HouseholdItems/CategorySection/CategoryDetail";
import { HouseholdItemCategoryChoice } from "globalTypes";
import * as React from "react";
import { FaBlackTie } from "react-icons/fa";

const Examples = () => (
  <div>
    <p>Examples include:</p>
    <ul>
      <li>Everyday clothing</li>
      <li>Shoes</li>
      <li>Furs</li>
      <li>Leather coats</li>
      <li>Designer wear</li>
      <li>Accessories</li>
    </ul>
    <strong>Do not include jewelry in this section.</strong>
  </div>
);

const ClothingDetail: CategoryDetail = {
  category: HouseholdItemCategoryChoice.Clothing,
  displayName: "Clothing",
  displayLowercaseName: "clothing",
  hasNoProfileBooleanName: "hasNoHouseholdClothingItems",
  singleInterrogativeText:
    "Tell us about either one high value article of clothing or provide a fair market value of your clothing item(s).",
  noAssetDeclarativeText:
    "You have indicated that neither you nor any member of your household has any high value items of clothing, or clothing you would like to claim as exempt.",
  booleanInterrogativeText: (
    <>
      <p className="required">
        <strong>
          Do you or any members of your household have any high value items of
          clothing or clothing you would like to claim as exempt?
        </strong>
      </p>
      <Examples />
    </>
  ),
  declarativeText: (
    <>
      <p className="required">
        <strong>
          Please itemize all items of clothing you and your family members own,
          or any clothing you would like to claim as exempt.
        </strong>
      </p>
      <Examples />
    </>
  ),
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add items below.",
  urlPathName: "clothing",
  displayMenuName: "Clothing",
  menuIcon: FaBlackTie,
};

export default ClothingDetail;
