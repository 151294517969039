import { BooleanInput } from "components/BooleanInput";
import GetOrCreateAddress, {
  getSchema,
} from "components/GetOrCreate/GetOrCreateAddress";
import { HiddenInput } from "components/HiddenInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { useFormContext } from "react-hook-form";
import { boolean, object } from "yup";

export const ResidenceSchema = object({
  isLivingWith: boolean().required("This field is required."),
  relatedPerson: object()
    .notRequired()
    .when("isLivingWith", {
      is: false,
      then: getSchema("mailingAddress", "mailingAddressId"),
    }),
}).required();

export function Residence(props: WizardRouteChildProps) {
  const form = useFormContext();
  const isLivingWith = props.form.watch("isLivingWith");

  return (
    <>
      <BooleanInput
        name="isLivingWith"
        label={`Does ${
          form.watch("relatedPerson.firstName") || "this person"
        } live with you?`}
      />

      {isLivingWith === false && (
        <>
          <p>Where do they live?</p>
          <GetOrCreateAddress
            name="relatedPerson.mailingAddress"
            idName="relatedPerson.mailingAddressId"
            objectName="mailing address"
          />
        </>
      )}
      {isLivingWith === true && (
        <HiddenInput name="relatedPerson.mailingAddress" value={null} />
      )}

      <WizardNavigation {...props.navProps} />
    </>
  );
}
