import { useQuery } from "@apollo/client";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { Title } from "components/Title";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { ListViewProps } from "components/EditCreateList/stateless";
import { useNested } from "utils/hooks";
import { useDeleteHouseholdContributor } from "api/graphql/HouseholdContributors";
import HOUSEHOLD_CONTRIBUTORS_MODULE from "./HouseholdContributorsModule.gql";
import { ButtonRow } from "components/ButtonRow";
import { FaCheck, FaPlus } from "react-icons/fa";
import { ListItem } from "components/ListItem";
import { formatName } from "utils/formatters";

export function ListView({ onEdit, createURL, nextURL }: ListViewProps) {
  const { data, loading } = useQuery<HouseholdContributorsModule>(
    HOUSEHOLD_CONTRIBUTORS_MODULE,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [onDelete] = useDeleteHouseholdContributor();
  const { path, url } = useNested();

  if (loading) return <Loading />;

  const { householdContributors: items } = data;

  return (
    <>
      <Title>Expense Contributors</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoHouseholdContributors"
              createURL={createURL}
              nextURL={nextURL}
              addText="Add an expense contributor"
              question={
                <>
                  <p>
                    Please provide a list of people who help pay expenses such
                    as rent, utilities, or groceries. You must list all
                    household contributors, even if they are not related to you.
                  </p>
                  <p>
                    <ul>
                      <li>
                        Do not include spousal income (should be listed under
                        employment).
                      </li>
                      <li>
                        For minor children, child support should be listed under
                        child support. If minors are old enough to work, and
                        give you money to cover their expenses, we do not need
                        that information.
                      </li>
                      <li>
                        Examples of things we do need: funds coming from the
                        mother who does not live with you, the roommate that
                        pays your half of expenses, the neighbor next door, etc.
                      </li>
                    </ul>
                  </p>
                  <p className="required">
                    <strong>
                      Are there any people that contribute to paying down your
                      household expenses?
                    </strong>
                  </p>
                </>
              }
              confirmation={
                <>
                  <p>
                    You&rsquo;ve indicated that there are no people that
                    contribute to paying down your household expenses.
                  </p>
                  <p>
                    If this is not correct, please add your contributors here.
                  </p>
                </>
              }
            />
          )}
        />
        {!items.length && <Redirect to={url("/query")} />}
        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Please list all expense contributors, even if they are not
                related to you.
              </p>
              <p>
                <ul>
                  <li>
                    Do not include spousal income (should be listed under
                    employment).
                  </li>
                  <li>
                    For minor children, child support should be listed under
                    child support. If minors are old enough to work, and give
                    you money to cover their expenses, we do not need that
                    information.
                  </li>
                  <li>
                    Examples of things we do need: funds coming from the mother
                    who does not live with you, the roommate that pays your half
                    of expenses, the neighbor next door, etc.
                  </li>
                </ul>
              </p>
              <ButtonRow
                style={{ marginBottom: "8px" }}
                left={
                  <Link to={createURL} className="btn secondary">
                    <FaPlus />
                    <span>Add an expense contributor</span>
                  </Link>
                }
                right={
                  <Link to={nextURL} className="btn primary">
                    <span>Done</span>
                    <FaCheck />
                  </Link>
                }
              />
              {items.map((item) => (
                <ListItem
                  key={item.id}
                  onEdit={() => onEdit(item.id)}
                  onDelete={() => onDelete(item.id)}
                >
                  <div>
                    <strong>
                      {formatName(item.relatedPerson)} ({item.relationship})
                    </strong>
                  </div>
                </ListItem>
              ))}
            </>
          )}
        />
      </Switch>
    </>
  );
}
