import { BooleanInput } from "components/BooleanInput";
import { HiddenInput } from "components/HiddenInput";
import { NumberInput } from "components/NumberInput";
import { RadioGroupInput } from "components/RadioGroupInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useQueryParams } from "utils/hooks";
import { boolean, number, object } from "yup";

export const MoneyOwedSchema = object({
  isOwingMoney: boolean().required("This field is required."),
  returnMultiplier: number().oneOf([1, -1]).required("This field is required."),
  returnAmount: number()
    .min(0, "This value can not be negative.")
    .required("This field is required."),
}).required();

export type MoneyOwedFormState = ReturnType<
  typeof MoneyOwedSchema.validateSync
>;

export default function MoneyOwed({ isEdit, navProps }: WizardRouteChildProps) {
  const form = useFormContext<Partial<MoneyOwedFormState>>();
  const { money_owed } = useQueryParams();

  return (
    <>
      {money_owed ? (
        <HiddenInput name="returnMultiplier" value={1} />
      ) : (
        <RadioGroupInput
          name="returnMultiplier"
          label="Did you get a refund, or did you have taxes due?"
          options={[
            { label: "Got a refund", value: -1 },
            { label: "Owed taxes", value: 1 },
          ]}
        />
      )}

      {form.watch("returnMultiplier") && (
        <NumberInput
          name="returnAmount"
          label={
            form.watch("returnMultiplier") > 0
              ? "How much did you originally owe?"
              : "What was your refund amount?"
          }
          addOnBefore="$"
          autoFocus={money_owed && !isEdit}
          width="300px"
          includeThousandsSeparator
          allowDecimal
        />
      )}

      {form.watch("returnMultiplier") === 1 && !money_owed ? (
        <BooleanInput
          name="isOwingMoney"
          label="Do you still owe money for this tax return?"
        />
      ) : (
        <HiddenInput name="isOwingMoney" value={!!money_owed} />
      )}
      <WizardNavigation {...navProps} />
    </>
  );
}
