import { Caption, Container } from "components/Big";
import * as React from "react";
import css from "./styles.module.css";

const State = React.lazy(() =>
  import("react-stateface").then((module) => ({ default: module.State }))
);

export interface BigStateProps {
  caption?: React.ReactNode;
  children: string;
}

export function BigState({ caption, children }: BigStateProps) {
  return (
    <Container>
      <div className={css.StateContainer}>
        <React.Suspense
          fallback={
            <div className={css.fallback}>{children.toUpperCase()}</div>
          }
        >
          <State state={children} />
        </React.Suspense>
        {!!caption && <Caption>{caption}</Caption>}
      </div>
    </Container>
  );
}
