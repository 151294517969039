import { HiddenInput } from "components/HiddenInput";
import { DecimalInput } from "components/NumberInput";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { DecimalSchema } from "utils/validators/yup";
import { object, string } from "yup";

export const SurrenderValueSchema = object({
  type: string().required(),
  surrenderValue: DecimalSchema.when("type", {
    is: "WHOLE_LIFE",
    then: DecimalSchema.required(
      "If this policy has no surrender value, enter 0."
    ),
    otherwise: DecimalSchema.nullable(),
  }),
  beneficiary: string().when("type", {
    is: (type) => ["TERM_LIFE", "WHOLE_LIFE"].includes(type),
    then: string().required(
      "Please enter the name of the beneficiary for this policy"
    ),
    otherwise: string().nullable(),
  }),
}).required();

export default function SurrenderValue({
  navProps,
  isEdit,
}: WizardRouteChildProps) {
  return (
    <>
      <p>
        Life insurance policies usually require you to name a beneficiary. Whole
        life policies often also have a surrender value, which is the amount you
        would receive if the policy was cancelled.
      </p>

      <HiddenInput name="type" />
      <TextInput
        name="beneficiary"
        label="Beneficiary"
        autoFocus={!isEdit}
        rules={{ required: true }}
      />
      <DecimalInput
        name="surrenderValue"
        label="Surrender value"
        helpText="This usually only applies to whole life insurance."
        includeThousandsSeparator
        allowDecimal
        addOnBefore="$"
        rules={{ required: true }}
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
