import cn from "classnames";
import * as React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import css from "./styles.module.scss";

export interface NavItemProps extends NavLinkProps {
  children: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  index?: number;
}

export function NavItem({
  children,
  index,
  disabled = false,
  hidden = false,
  ...etc
}: NavItemProps) {
  return hidden ? null : (
    <NavLink
      activeClassName={css.active}
      {...etc}
      className={cn(css.navLink, { disabled })}
    >
      <div className={css.Wrapper}>
        <div className={css.Wrapper__index}>{index + 1}</div>
        <div className={css.Wrapper__content}>{children}</div>
      </div>
    </NavLink>
  );
}
