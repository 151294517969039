import { useGetRealEstateDocumentRecord } from "api/graphql/RealEstatePropertyDocumentRecord";
import { useGetRealEstatePropertyValuation } from "api/graphql/RealEstatePropertyValuation";
import { BooleanInput } from "components/BooleanInput";
import { ButtonRow } from "components/ButtonRow";
import { DateInput } from "components/DateInput";
import { useEditorBuffer } from "components/EditorState/hooks";
import { formatAddressToLookupAddress } from "components/FormModules/Assets/RealEstate/utils";
import { LearnMore } from "components/LearnMore";
import { Loading } from "components/Loading";
import { DecimalInput } from "components/NumberInput";
import { WantToKeepLearnMore } from "components/WantToKeepLearnMore";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { formatDateAsWritten } from "utils/formatters";
import { DecimalSchema } from "utils/validators/yup";
import { mixed, object, string } from "yup";
import css from "./Financials.module.css";

export const FinancialsSchema = object({
  purchaseDate: string()
    .dateNotFuture("Purchase date can’t be in the future.")
    .required("Please enter the date you purchased this property."),
  purchasePrice: DecimalSchema.required(
    "Please enter the amount you purchased this property for."
  ),
  currentValue: DecimalSchema.required(
    "Please enter the current value of this property."
  ),
  wantToKeep: mixed<boolean | null>().required("This field is required."),
}).required();

export type FinancialsFormState = ReturnType<
  typeof FinancialsSchema.validateSync
>;
export default function Financials({
  isEdit,
  navProps,
}: WizardRouteChildProps) {
  const [{ buffer }] = useEditorBuffer();
  const formattedAddress = formatAddressToLookupAddress(buffer.address);
  const form = useFormContext<FinancialsFormState>();
  const [showPurchaseData, setShowPurchaseData] = useState(false);
  const [showValuationData, setShowValuationData] = useState(false);

  const { loading: valuationLoadingStatus, data: valuationData } =
    useGetRealEstatePropertyValuation(formattedAddress);
  const { loading: documentationLoadingStatus, data: documentationData } =
    useGetRealEstateDocumentRecord(formattedAddress);

  const shouldInquireForLookupData = !buffer.id;
  const valuation = valuationData?.realEstatePropertyValuation;
  const docRec = documentationData?.realEstatePropertyDocumentRecord;
  const jsPurchaseDate =
    !!docRec?.propertyPurchaseDate &&
    new Date(Date.parse(docRec.propertyPurchaseDate));
  useEffect(() => {
    if (shouldInquireForLookupData) {
      if (!documentationLoadingStatus) {
        setShowPurchaseData(
          !!docRec?.propertyPurchaseDate && !!docRec?.propertyPurchasePrice
        );
      }
      if (!valuationLoadingStatus) {
        setShowValuationData(!!valuation?.propertySuggestedValue);
      }
    }
  }, [buffer.address, valuationData, documentationData]);

  if (shouldInquireForLookupData && valuationLoadingStatus) return <Loading />;

  return (
    <>
      <div style={{ display: showPurchaseData ? "block" : "none" }}>
        <p>
          Our search of public records shows that this property was last
          purchased on {jsPurchaseDate && formatDateAsWritten(jsPurchaseDate)}
          {", "}
          for ${docRec?.propertyPurchasePrice}. Is this information correct?
        </p>
        <ButtonRow
          left={
            <button
              type="button"
              className="block btn primary"
              onClick={() => {
                form.setValue(
                  "purchasePrice",
                  docRec.propertyPurchasePrice.replace(/,/g, "")
                );
                form.setValue("purchaseDate", docRec.propertyPurchaseDate);
                setShowPurchaseData(false);
              }}
            >
              Yes
            </button>
          }
          right={
            <button
              type="button"
              className="block btn secondary"
              onClick={() => {
                setShowPurchaseData(false);
              }}
            >
              No
            </button>
          }
        />
      </div>
      <div
        style={{ display: showPurchaseData ? "none" : "flex" }}
        className="form-row"
      >
        <DecimalInput
          name="purchasePrice"
          label="Original purchase price"
          width="175px"
          autoFocus={!isEdit}
          addOnBefore="$"
          allowDecimal
          includeThousandsSeparator
        />
        <DateInput name="purchaseDate" label="Date purchased" />
      </div>
      <div className="form-row">
        <DecimalInput
          name="currentValue"
          label="Current value"
          addOnBefore="$"
          allowDecimal
          includeThousandsSeparator
          autoComplete="off"
          helpText={
            showValuationData ? (
              <>
                <div className={css.helpTextContainer}>
                  <div>
                    <div>
                      <span>
                        <strong>Zestimate®</strong>: $
                        {valuation.propertySuggestedValue}{" "}
                        <a
                          href=""
                          onClick={(event) => {
                            event.preventDefault();
                            form.setValue(
                              "currentValue",
                              valuation.propertySuggestedValue
                            );
                          }}
                          className={css.suggestedValueHelper}
                        >
                          Use this value
                        </a>
                      </span>
                    </div>
                    <div>
                      <strong>Estimated sales range</strong>: $
                      {valuation.propertySuggestedValueLow} - $
                      {valuation.propertySuggestedValueHigh}
                    </div>
                  </div>
                  <div>
                    <img
                      src="https://www.zillow.com/widgets/GetVersionedResource.htm?path=/static/logos/Zillowlogo_200x50.gif"
                      alt="Zillow Real Estate Search"
                      className={css.zillowLogo}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )
          }
          placeholder={
            showValuationData ? valuation.propertySuggestedValue : ""
          }
          learnMore={
            <LearnMore>
              <h4>How should I determine what my real estate is worth?</h4>
              <p>
                The standard method for valuing real estate in bankruptcy is
                fair market value. Fair market value is the price at which your
                home would sell on the open market on the date you file for
                bankruptcy.
              </p>
              <p>
                If your property has been formally appraised within the last
                three months, use that value. Otherwise, we recommend using our
                estimate or visiting&nbsp;
                <a href="https://www.zillow.com/">Zillow</a> to see their
                estimate of your home’s value.&nbsp;
                <strong>
                  Do not ever use your property tax assessment value; this
                  number is normally below the fair market value.
                </strong>
              </p>
              <div style={{ display: showValuationData ? "block" : "none" }}>
                <h4>What is Zillow&rsquo;s Zestimate®?</h4>
                <p>
                  The Zestimate® home valuation model is Zillow&rsquo;s estimate
                  of your home&rsquo;s market value. The Zestimate incorporates
                  public and user-submitted data, taking into account home
                  facts, location and market conditions. The Zestimate&rsquo;s
                  accuracy depends on location and the availability of data in
                  an area. Some areas have more detailed home information
                  available &mdash; such as square footage and number of
                  bedrooms or bathrooms &mdash; and other areas do not. The more
                  data available, the more accurate the Zestimate value will be.{" "}
                  <a
                    href={valuation?.propertyZillowUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    See more details for {buffer.address.line1} on Zillow
                  </a>
                  .
                </p>
                <p>
                  © Zillow, Inc., 2006-{new Date().getFullYear()}. Use is
                  subject to{" "}
                  <a
                    href="https://www.zillow.com/corp/Terms.htm"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>
                </p>
              </div>
            </LearnMore>
          }
        />
      </div>
      <div className="form-row">
        <BooleanInput
          name="wantToKeep"
          label="Do you want to keep this property after your bankruptcy?"
          learnMore={<WantToKeepLearnMore />}
        />
      </div>
      <WizardNavigation {...navProps} />
    </>
  );
}
