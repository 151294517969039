import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaChild } from "react-icons/fa";
import { OtherFamilyMembers } from "./OtherFamilyMembers";
import OtherFamilySummary from "./Summary";

const OtherFamilyMembersSubmodule: SubModule = {
  path: "other-family",
  title: "Other Family",
  icon: FaChild,
  component: OtherFamilyMembers,
  summary: OtherFamilySummary,
  statusName: "otherFamily",
};

export default OtherFamilyMembersSubmodule;
