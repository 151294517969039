import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaCashRegister } from "react-icons/fa";
import { AssetSales } from "./AssetSales";
import AssetSalesSummary from "./Summary";

const AssetSalesSubmodule: SubModule = {
  path: "sold_assets",
  icon: FaCashRegister,
  title: "Sold Assets",
  component: AssetSales,
  summary: AssetSalesSummary,
  statusName: "assetSales",
};

export default AssetSalesSubmodule;
