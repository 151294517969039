import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import OWNED_VEHICLES_MODULE from "./VehiclesModule.gql";

export default function OwnedVehiclesSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<VehiclesModule>(OWNED_VEHICLES_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.ownedVehicles}>
      {data?.filingProfile?.hasNoVehicles ? (
        <HasNoSummary>You don&rsquo;t own any vehicles.</HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="owned vehicle"
            maxValueLength={2}
            value={data?.ownedVehicles?.length}
            pluralizeLabel
          />
          <NumericSummary
            label="total value"
            valuePrefix="$"
            maxValueLength={6}
            value={fancyNumberSum(data?.ownedVehicles, "currentValue")}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
