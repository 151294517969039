import { useListRoot } from "components/EditCreateList/hooks";
import { FinancialInvestmentWizard } from "components/FormModules/Assets/FinancialInvestments/FinancialInvestmentWizard";
import { financialInvestmentTypeDetails } from "components/FormModules/Assets/FinancialInvestments/utils";
import { OptionCard, OptionsList } from "components/OptionCard";
import { Title } from "components/Title";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { formatTitleCaseString, formatUrlString } from "utils/formatters";
import { useNested } from "utils/hooks";

interface Props {
  listURL: string;
  investmentType: FinancialInvestmentTypeChoice;
}

export default function CreateView(props: Props) {
  const { url, rootPath, path } = useNested();
  const { url: rootURL } = useListRoot();

  return (
    <Switch>
      <Route
        exact
        path={rootPath}
        render={() => (
          <>
            <Title>Add a Financial Investment</Title>
            <p>What type of investment is this?</p>
            <OptionsList>
              {Object.entries(financialInvestmentTypeDetails).map(
                ([typename, details]) => (
                  <OptionCard
                    key={typename}
                    Icon={details.icon}
                    helpText={details.helpText}
                    href={url(formatUrlString(details.displayName))}
                  >
                    <span>{details.displayName}</span>
                  </OptionCard>
                )
              )}
            </OptionsList>
          </>
        )}
      />

      {Object.entries(financialInvestmentTypeDetails).map(
        ([typename, details]) => (
          <Route
            key={typename}
            path={path(formatUrlString(details.displayName))}
            render={() => (
              <FinancialInvestmentWizard
                title={`Add ${formatTitleCaseString(details.displayName)}`}
                investmentType={details.type}
                backURL={props.listURL}
                nextURL={props.listURL}
              />
            )}
          />
        )
      )}
      <Redirect to={rootURL} />
    </Switch>
  );
}
