import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_DEBT from "./DeleteOtherDebt.gql";
import GET_DEBT from "./GetOtherDebt.gql";
import LIST_DEBT from "./ListOtherDebt.gql";
import UPSERT_DEBT from "./UpsertOtherDebt.gql";

const LIST_DEBT_IDS = gql`
  query ListOtherDebtIds {
    otherDebts {
      id
    }
  }
`;

export function useListOtherDebt(options?: QueryHookOptions<ListOtherDebt>) {
  return useQuery<ListOtherDebt>(LIST_DEBT, options);
}

export function useGetOtherDebt(id: string) {
  return useQuery<GetOtherDebt, GetOtherDebtVariables>(GET_DEBT, {
    variables: { id },
  });
}

export function useUpsertOtherDebt<
  Schema extends { otherDebt: any } = UpsertOtherDebt,
  Variables = UpsertOtherDebtVariables
>(query = UPSERT_DEBT) {
  return useUpsert<Schema, Variables, ListOtherDebtIds, OtherDebtInput>(
    query,
    LIST_DEBT_IDS,
    "otherDebt",
    "otherDebts",
    (cache) => updateFilingProfileCache(cache, "hasNoOtherDebts", false)
  );
}

export function useDeleteOtherDebt() {
  return useDelete<DeleteOtherDebt, ListOtherDebtIds>(
    DELETE_DEBT,
    LIST_DEBT_IDS,
    "deleteOtherDebt",
    "otherDebts"
  );
}
