import cn from "classnames";
import * as React from "react";
import { createPortal } from "react-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { breakpoints, useBreakpoint } from "utils/hooks";
import css from "./styles.module.scss";

export function useRenderToMobileMenu(
  mobileMenuRootSelector = "#react-mobile-menu-portal-root"
) {
  const isMobile = useBreakpoint(breakpoints.mobile);

  return React.useCallback(
    (el: React.ReactElement) => {
      if (!isMobile) return el;

      const mobileMenuRoot = document.querySelector(mobileMenuRootSelector);
      if (!mobileMenuRoot) {
        console.error(
          `Mobile menu portal root (${mobileMenuRootSelector}) not found.`
        );
        return null;
      }

      return createPortal(el, mobileMenuRoot);
    },
    [isMobile, mobileMenuRootSelector]
  );
}

function useMobileMenuButtonRef(
  mobileButtonSelector = "#react-mobile-menu-button-root"
) {
  const button = React.useRef(document.querySelector(mobileButtonSelector));
  if (!button.current) {
    console.error(
      `Mobile menu button root (${mobileButtonSelector}) not found.`
    );
  }
  return button;
}

function useMobileMenuRef(mobileMenuSelector = ".mobile-menu") {
  const menu = React.useRef(document.querySelector(mobileMenuSelector));

  React.useEffect(() => {
    if (!menu.current) return;
    menu.current?.classList?.add("reactified");
    () => {
      menu.current?.classList?.remove("reactified");
    };
  }, []);

  if (!menu.current) {
    console.error(`Moble menu root (${mobileMenuSelector}) not found.`);
  }
  return menu;
}

export function MobileMenu() {
  const buttonRef = useMobileMenuButtonRef();
  const menuRef = useMobileMenuRef();

  const [menuOpen, setMenuOpen] = React.useState(
    menuRef.current?.classList?.contains("open") || false
  );

  const { pathname } = useLocation();
  const [lastPathname, setLastPathname] = React.useState(pathname);

  const closeMenu = React.useCallback(() => {
    menuRef.current?.classList?.remove("open");
    setMenuOpen(false);
  }, []);

  const openMenu = React.useCallback(() => {
    menuRef.current?.classList?.add("open");
    setMenuOpen(true);
  }, []);

  React.useEffect(() => {
    if (pathname !== lastPathname) {
      closeMenu();
      setLastPathname(pathname);
    }
  }, [pathname, closeMenu, lastPathname, setLastPathname]);

  const toggleMenu = React.useCallback(() => {
    if (!menuOpen) {
      openMenu();
    } else {
      closeMenu();
    }
  }, [menuOpen, openMenu, closeMenu]);

  const isMobile = useBreakpoint(breakpoints.mobile);

  if (!buttonRef.current || !isMobile) return null;

  return createPortal(
    <button
      type="button"
      onClick={toggleMenu}
      className={cn("btn flat", "unstyled", css.menuButton)}
    >
      {menuOpen ? <FaTimes size="24px" /> : <FaBars size="24px" />}
    </button>,
    buttonRef.current
  );
}
