import { useGetInsurancePolicy } from "api/graphql/InsurancePolicy";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import { InsuranceCategoryDetail } from "../../HouseholdExpenses/CategorySection/CategoryDetail";
import InsurancePolicyWizard from "../InsurancePolicyWizard";

interface Props {
  id: string;
  listURL: string;
  categoryDetail: InsuranceCategoryDetail;
}

export default function EditView(props: Props) {
  const { data, loading } = useGetInsurancePolicy(props.id);

  if (loading) {
    return <Loading />;
  } else if (!data?.insurancePolicy) {
    return <Redirect to={props.listURL} />;
  }

  return (
    <InsurancePolicyWizard
      type={props.categoryDetail.category}
      title={`Updating ${props.categoryDetail.displayName} Policy`}
      subtitle={props.categoryDetail.singleInterrogativeText}
      backURL={props.listURL}
      nextURL={props.listURL}
      initialValue={data.insurancePolicy}
    />
  );
}
