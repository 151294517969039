import { FarmAssetCategory } from "globalTypes";
import React from "react";
import { GiFarmTractor } from "react-icons/gi";
import { CategoryDetail } from "../CategorySection/CategoryDetail";

const FarmEquipmentDetail: CategoryDetail = {
  category: FarmAssetCategory.FarmEquipment,
  displayName: "Farming Equipment",
  displayLowercaseName: "farming equipment",
  hasNoProfileBooleanName: "hasNoFarmEquipment",
  singleInterrogativeText:
    "Tell us about a single piece of farming equipment, such as a machine, implement, or tool.",
  noAssetDeclarativeText:
    "You have indicated that you don’t own any farming equipment.",
  booleanInterrogativeText: (
    <p className="required">
      <strong>
        Do you own any farming equipment, such as machinery, implements, or
        tools?
      </strong>
    </p>
  ),
  declarativeText: (
    <p>
      Please itemize all high-value farming equipment, machinery, and tools you
      own.
    </p>
  ),
  nextSectionText:
    "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add equipment below.",
  urlPathName: "farm_equipment",
  menuIcon: GiFarmTractor,
};

export default FarmEquipmentDetail;
