import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { GiFarmer } from "react-icons/gi";
import FarmAssets from "./FarmAssets";
import FarmAssetsSummary from "./Summary";

const FarmAssetsSubmodule: SubModule = {
  path: "farm_assets",
  icon: GiFarmer,
  title: "Farming Assets",
  component: FarmAssets,
  summary: FarmAssetsSummary,
  statusName: "farmAssets",
};

export default FarmAssetsSubmodule;
