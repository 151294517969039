import { useDeleteStudentLoan } from "api/graphql/StudentLoan";
import cn from "classnames";
import { ListItem, ListItemProps } from "components/ListItem";
import { format, parseISO } from "date-fns";
import React from "react";
import {
  formatErrorsFromSchema,
  formatName,
  formatOxfordComma,
} from "utils/formatters";
import css from "./StudentLoans.module.scss";
import { StudentLoanSchema } from "./StudentLoanWizard";

interface LoanListItemProps extends Omit<ListItemProps, "children"> {
  loan: ArrayItemType<StudentLoansModule["studentLoans"]>;
  skipValidation?: boolean;
}

export default function LoanListItem({
  loan,
  onDelete,
  onEdit,
  skipValidation = false,
  className,
  ...listItemProps
}: LoanListItemProps) {
  const [deleteStudentLoan] = useDeleteStudentLoan();

  return (
    <ListItem
      onEdit={onEdit}
      onDelete={() => (onDelete ? onDelete() : deleteStudentLoan(loan.id))}
      className={cn(css.loanListItem, className)}
      {...listItemProps}
    >
      <div className={css.loanListItem__content}>
        {!skipValidation && formatErrorsFromSchema(StudentLoanSchema, loan)}

        <h4 className={css.lenderName}>{loan.lenderName}</h4>

        {loan.accountNumber && <div>Account {loan.accountNumber}</div>}

        <span>Issued {format(parseISO(loan.loanDate), "MM/dd/yyyy")}</span>

        {!!loan.cosigners.length && (
          <div className="supporting-text">
            Cosigned with {formatOxfordComma(...loan.cosigners.map(formatName))}
          </div>
        )}
      </div>
    </ListItem>
  );
}
