import { isArray, isPlainObject } from "lodash";
import { OmitRecursively } from "utils/types";

/**
 * Strips __typename properties out of Apollo responses recursively.
 *
 * This can be useful when using the mock server responses as data
 * factories, since __typename isn't included in any of our input
 * schemata.
 */
export default function stripTypename<T>(
  input: T
): OmitRecursively<T, "__typename"> {
  if (isPlainObject(input)) {
    const result: any = {};
    Object.entries(input).forEach(([k, v]) => {
      if (k === "__typename") return;
      result[k] = stripTypename(v);
    });
    return result as OmitRecursively<T, "__typename">;
  } else if (isArray(input)) {
    return input.map(stripTypename) as OmitRecursively<T, "__typename">;
  } else {
    return input as OmitRecursively<T, "__typename">;
  }
}
