import { useGetVehicleTitle } from "api/graphql/VehicleTitles";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import { VehicleWizard } from "./VehicleWizard";

interface Props {
  id: string;
  listURL: string;
}

export function EditView({ id, listURL }: Props) {
  const { data, loading } = useGetVehicleTitle(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.ownedVehicle) {
    return <Redirect to={listURL} />;
  }

  return (
    <VehicleWizard
      title="Updating Vehicle"
      backURL={listURL}
      nextURL={listURL}
      initialValue={data.ownedVehicle}
    />
  );
}
