import cn from "classnames";
import { LinkCard } from "components/LinkCard";
import { camelCase, startCase } from "lodash";
import React, { FC } from "react";
import styles from "./styles/FormModuleLink.module.css";

interface FormModuleLinkProps {
  title: string;
  href?: string;
  status?: {
    status: ModuleStatus;
    messages: string[];
  };
  icon?: FC<any>;
  id?: string;
}

export function FormModuleLink({
  title,
  href = "",
  status,
  icon: Icon,
  id,
}: FormModuleLinkProps) {
  return (
    <LinkCard to={href} className={styles.link} id={id}>
      {Icon && <Icon />}
      <h3 className={styles.statusText}>{title}</h3>
      {status && (
        <div
          className={cn(
            styles.moduleStatus,
            { [styles.primary]: status.status === "NOT_STARTED" },
            { [styles.secondary]: status.status === "IN_PROGRESS" }
          )}
        >
          <h5>{startCase(camelCase(status.status.replace(/_/g, " ")))}</h5>
        </div>
      )}
      {!!status?.messages?.length && (
        <ul className={styles.missing}>
          {status.messages.map((msg) => (
            <li key={msg}>{msg}</li>
          ))}
        </ul>
      )}
    </LinkCard>
  );
}
