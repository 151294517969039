import { find, has, isPlainObject, omit, omitBy } from "lodash";

/**
 * Migrates id values from nested.id to nestedId for pairs of nestedId nested keys.
 * Values without a matching partner (e.g. nestedId without nested) will be ignored.
 * Also removes null nestedId or nested.id values.
 *
 * Examples:
 *
 * `unnestIds({ foo: { id: 1, bar: "baz" }}) => { foo: { bar: "baz"}, fooId: 1 }`
 *
 * `unnestIds({ foo: { id: 2, bar: "baz" }, fooId: 1 }) => { foo: { bar: "baz"}, fooId: 1 }`
 *
 * `unnestIds({ foo: { id: null, bar: "baz" }}) => { foo: { bar: "baz" } }`
 *
 * `unnestIds({ foo: { id: null, bar: "baz" }, fooId: null }) => { foo: { bar: "baz" } }`
 *
 * `unnestIds({ foo: { id: null, bar: "baz" }, fooId: 1 }) => { foo: { bar: "baz"}, fooId: 1 }`
 *
 * `unnestIds({ fooId: null, bar: "baz" }) => { bar: "baz" }`
 */
export const unnestIds = (src: any, omitKeys?: string[]) => {
  if (!isPlainObject(src)) return src;

  const result = {} as any;
  for (const [key, value] of Object.entries(omit(src, omitKeys || []))) {
    if (has(value, "id")) {
      result[`${key}Id`] = (value as any).id;
    } else if (key.endsWith("Id") && value === null) {
      continue;
    }

    result[key] = unnestIds(value, ["id"]);
  }

  const props = Object.keys(result).filter((key) => !key.endsWith("Id"));
  const ids = Object.keys(result).filter((key) => key.endsWith("Id"));

  const pairs = new Set();
  for (const prop of props) {
    if (find(ids, (id) => id === `${prop}Id`)) {
      pairs.add(prop);
      pairs.add(`${prop}Id`);
    }
  }

  return omitBy(result, (value, key) => {
    return pairs.has(key) && value === null;
  });
};

/**
 * Migrates external nestedId values to nested.id for pairs of nestedId nested keys.
 * Values without a matching partner (e.g. nestedId withouth nested) will be ignored.
 * Also removes null nestedId or nested.id values.
 *
 * Examples:
 *
 * `nestIds({ foo: { bar: "baz"}, fooId: 1 }) => { foo: { id: 1, bar: "baz" }}`
 *
 * `nestIds({ foo: { bar: "baz", id: null }, fooId: 1 }) => { foo: { id: 1, bar: "baz" }}`
 *
 * `nestIds({ foo: { id: 2, bar: "baz" }, fooId: 1}) => { foo: { id: 1, bar: "baz" }}`
 *
 * `nestIds({ foo: { id: 2, bar: "baz" }, fooId: null}) => { foo: { id: 2, bar: "baz" }}`
 *
 * `nestIds({ fooId: null, bar: 1}) => { bar: 1 }`
 *
 * `nestIds({ foo: { id: null, bar: "baz" }}) => { foo: { bar: "baz"}}`
 */
export const nestIds = (src: any, addKeys?: Record<string, any>) => {
  if (!isPlainObject(src)) return src;

  const result = {} as any;
  for (const [key, value] of Object.entries({ ...src, ...(addKeys || {}) })) {
    if (key.endsWith("Id") && (has(src, key.slice(0, -2)) || value === null)) {
      continue;
    } else if (key === "id" && value === null) {
      continue;
    } else if (has(src, `${key}Id`) && src[`${key}Id`] !== null) {
      result[key] = nestIds(value, { id: src[`${key}Id`] });
    } else {
      result[key] = nestIds(value);
    }
  }
  return result;
};
