import React from "react";
import { HouseholdItemWizard } from "../HouseholdItemWizard";
import { CategoryDetail } from "./CategoryDetail";
import { Description } from "./Description";

interface Props {
  listURL: string;
  categoryDetail: CategoryDetail;
}

export default function CreateView(props: Props) {
  return (
    <HouseholdItemWizard
      title={`Add ${props.categoryDetail.displayName}`}
      subtitle={
        <Description introText={props.categoryDetail.singleInterrogativeText} />
      }
      category={props.categoryDetail.category}
      backURL={props.listURL}
      nextURL={props.listURL}
    />
  );
}
