import { useQuery } from "@apollo/client";
import { useDeleteServicePrepayment } from "api/graphql/ServicePrepayment";
import { StatelessList } from "components/EditCreateList/StatelessList";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import { ServicePrepaymentTypeChoice } from "globalTypes";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { formatErrorsFromSchema } from "utils/formatters";
import { useNested } from "utils/hooks";
import SERVICE_PREPAYMENTS_MODULE from "./ServicePrepaymentsModule.gql";
import { ServicePrepaymentSchema } from "./ServicePrepaymentWizard";
import { servicePrepaymentDetails } from "./utils";

interface ListViewProps {
  createURL: string;
  nextURL: string;
  prevURL: string;
  onEdit: (id: string) => any;
}

export function ListView(props: ListViewProps) {
  const { data } = useQuery<ServicePrepaymentsModule>(
    SERVICE_PREPAYMENTS_MODULE
  );
  const [onDelete] = useDeleteServicePrepayment();
  const { path, url } = useNested();

  if (!data) return <Loading />;
  const { servicePrepayments: items } = data;

  return (
    <>
      <Title>Your Security Deposits and Pre-payments</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              nextURL={props.nextURL}
              createURL={props.createURL}
              filingProfileBoolean="hasNoServicePrepayments"
              addText="Add a pre-payment"
              question={
                <p>
                  Do you have any gift cards, prepaid credit cards, security
                  deposits, or have you made any pre-payments for utilities,
                  rent, or other services?
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you don’t have any outstanding gift
                  cards, prepaid credit cards, security deposits or pre-payments
                  for utilities, rent, or other services. If that’s correct,
                  you’re ready to continue to the next section. If you do have
                  security deposits or pre-payments, you may add them below.
                </p>
              }
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                Please tell us about any outstanding gift cards, prepaid credit
                cards, security deposits or pre-payments that are owed to you
                for things like rent, utilities, and other services.
              </p>
              <StatelessList
                {...props}
                emptyText="You haven’t added any prepaid cards, security deposits, or pre-payments yet."
                addButtonText="Add a pre-payment"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(prepayment) => {
                  let prepaymentTypeDisplay =
                    servicePrepaymentDetails[prepayment.prepaymentType]
                      .displayName;
                  if (
                    prepayment.prepaymentType ===
                      ServicePrepaymentTypeChoice.Other &&
                    prepayment.description
                  ) {
                    prepaymentTypeDisplay = prepayment.description;
                  }

                  return (
                    <div>
                      {formatErrorsFromSchema(
                        ServicePrepaymentSchema,
                        prepayment
                      )}
                      <strong>
                        {prepaymentTypeDisplay}:{" "}
                        {prepayment.serviceProviderName}
                      </strong>
                      <div className="supporting-text">
                        Unused/owed amount: ${prepayment.unusedPrepaymentAmount}
                      </div>
                    </div>
                  );
                }}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
