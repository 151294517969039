import { useListLawFirmUsers } from "api/graphql/LawFirmUser";
import DataGrid from "components/DataGrid";
import { Loading } from "components/Loading";
import { CreateLawFirmUserButton } from "components/PortalModules/LawFirmUsersGrid/CreateLawFirmUserModal";
import {
  FormattedUser,
  getFormattedUser,
} from "components/PortalModules/LawFirmUsersGrid/utils";
import React, { useState } from "react";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import css from "./LawFirmUsersGrid.module.css";

export default function LawFirmUsersGrid() {
  const { data, loading } = useListLawFirmUsers();
  const [users, setUsers] = useState([]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 700,
      },
    },
  };

  React.useEffect(() => {
    if (data) {
      setUsers(data.lawFirmUsers.map((user) => getFormattedUser(user)));
    }
  }, [data]);

  if (loading) return <Loading />;

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      grow: 3,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      grow: 6,
    },
    {
      name: "Email Verified",
      grow: 1,
      center: true,
      cell(user: FormattedUser) {
        return (
          <div>
            {user.emailVerified ? (
              <FaCheckCircle title="Verified" className={css.verifiedIcon} />
            ) : (
              <FaExclamationCircle
                title="Verification pending"
                className={css.unverifiedIcon}
              />
            )}
          </div>
        );
      },
    },
    {
      name: "Last Login",
      grow: 2,
      sortable: true,
      selector: "lastLoginAt",
    },
  ];

  return (
    <DataGrid
      data={users}
      columns={columns}
      searchPlaceholderText={"🔍 Search law firm users..."}
      customStyles={customStyles}
      filterRecord={(record, searchText) => {
        return (
          record.name.toLowerCase().includes(searchText.toLowerCase()) ||
          record.email.toLowerCase().includes(searchText.toLowerCase())
        );
      }}
      subheaderElement={
        <div className="mr-4">
          <CreateLawFirmUserButton
            onCreate={(newUser: LawFirmUser) => {
              if (newUser) {
                setUsers(
                  [getFormattedUser(newUser)].concat(
                    data.lawFirmUsers.map((user) => getFormattedUser(user))
                  )
                );
              }
            }}
          />
        </div>
      }
    />
  );
}
