import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useUpsert, useDelete, updateQuery } from "api/graphql/utils";
import GET_HOUSEHOLD_CONTRIBUTOR from "./GetHouseholdContributor.gql";
import LIST_HOUSEHOLD_CONTRIBUTORS from "./ListHouseholdContributors.gql";
import UPSERT_HOUSEHOLD_CONTRIBUTOR from "./UpsertHouseholdContributor.gql";
import DELETE_HOUSEHOLD_CONTRIBUTOR from "./DeleteHouseholdContributor.gql";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";

const LIST_HOUSEHOLD_CONTRIBUTOR_IDS = gql`
  query ListHouseholdContributorIds {
    householdContributors {
      id
    }
  }
`;

export function useListHouseholdContributors() {
  return useQuery<ListHouseholdContributors>(LIST_HOUSEHOLD_CONTRIBUTORS);
}

export function useGetHouseholdContributor(id: string) {
  return useQuery<GetHouseholdContributor, GetHouseholdContributorVariables>(
    GET_HOUSEHOLD_CONTRIBUTOR,
    {
      variables: { id },
    }
  );
}

export function useUpsertHouseholdContributor() {
  return useUpsert(
    UPSERT_HOUSEHOLD_CONTRIBUTOR,
    GET_HOUSEHOLD_CONTRIBUTOR,
    "householdContributor",
    "householdContributors",
    (cache, data) => {
      const newlyAddedPerson = data.householdContributor.relatedPerson;
      updateQuery<GetHouseholdContributor, GetHouseholdContributorVariables>(
        cache,
        GET_HOUSEHOLD_CONTRIBUTOR,
        (state) => ({
          ...state,
          hasNoHouseholdContributors: false,
          householdContributor: newlyAddedPerson,
        }),
        { id: newlyAddedPerson.id }
      );
    }
  );
}

export function useDeleteHouseholdContributor() {
  return useDelete<DeleteHouseholdContributor, ListHouseholdContributorIds>(
    DELETE_HOUSEHOLD_CONTRIBUTOR,
    LIST_HOUSEHOLD_CONTRIBUTOR_IDS,
    "deleteHouseholdContributor",
    "householdContributors"
  );
}
