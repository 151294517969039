import React, { ReactNode } from "react";

export interface DescriptionProps {
  introText: ReactNode;
  footerText?: ReactNode;
}

export function Description({ introText, footerText }: DescriptionProps) {
  return (
    <div>
      <div>{introText}</div>
      <div>{footerText} </div>
    </div>
  );
}
