import DollarsInput from "components/DollarsInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import { DecimalSchema } from "utils/validators/yup";
import { object } from "yup";

export const FinancialsSchema = object({
  monthlyAmount: DecimalSchema.required("This question is required.").numMin(
    0.000001,
    "You must provide the monthly amount this person contributes to your expenses."
  ),
}).required();

export function Financials(props: WizardRouteChildProps) {
  return (
    <>
      <DollarsInput
        name="monthlyAmount"
        label={`What is the total amount this person contributes per month?`}
        min="0"
      />

      <WizardNavigation {...props.navProps} />
    </>
  );
}
