import { Card } from "components/Card";
import { EmailLink } from "components/ContactUs/ContactTypes";
import { OptionsList } from "components/OptionCard";
import React from "react";
import { Helmet } from "react-helmet";
import css from "../styles.module.css";

export function NewBankruptcyHandoff() {
  return (
    <>
      <Helmet>
        <title>Debt relief through bankruptcy</title>
      </Helmet>

      <div className={css.container}>
        <Card className={css.infoCard}>
          <h3>Your Lexria Intake Questionnaire has been submitted</h3>
          <p>
            Thank you for taking the time to take control of your financial
            situation. Your Lexria Intake Questionnaire has been submitted for
            review by your attorney. If you need anything related to your filing
            at this time, please reach out directly to your attorney. If you
            still have updates or questions about your Intake, you can contact
            us below.
          </p>
          <OptionsList>
            <EmailLink />
          </OptionsList>
        </Card>
      </div>
    </>
  );
}
