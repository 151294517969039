import cn from "classnames";
import { FormGroup } from "components/FormGroup";
import React, {
  cloneElement,
  forwardRef,
  ReactElement,
  ReactNode,
  RefObject,
} from "react";
import { RegisterOptions } from "react-hook-form";
import { useAutoFocus, useFormField } from "utils/hooks";
import css from "./TextInput.module.css";

export interface InputCommonProps {
  name?: string;
  rules?: RegisterOptions;

  /** Will be displayed alongside the label. Preferred location for LearnMore links. */
  learnMore?: ReactNode;

  /** Additional context about this input. Displayed below the input. */
  helpText?: ReactNode;

  /** The label text for this input. */
  label?: string;

  /** A CSS value to set for the width.
   *
   * Provided as a convenience for tweaking form layouts.
   */
  width?: string;
}

/** These props are shared amongst all the input components which present a text input. */
export interface TextInputCommonProps extends InputCommonProps {
  placeholder?: string;
  /** A non-editable prefix for the input value, displayed to the left of the text area. */
  addOnBefore?: ReactNode;

  /** A non-editable suffix for the input value, displayed to the right of the text area. */
  addOnAfter?: ReactNode;
}

export interface TextInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "width">,
    TextInputCommonProps {
  /** Whether or not the input should be registered with react-hook-form. */
  register?: boolean;

  inputElement?: ReactElement;
}

/**
 * Just like an HTML input element, but with optional built-in label and error feedback.
 *
 * If you don't need labels or errors, just use a regular `<input />`
 */
function TextInput(
  {
    name,
    rules,
    width,
    label = "",
    className = "",
    id,
    helpText,
    learnMore,
    addOnBefore,
    addOnAfter,
    autoFocus = false,
    inputElement,
    register = true,
    ...otherProps
  }: TextInputProps,
  ref?: RefObject<any>
) {
  const { error, Label, inputProps } = useFormField({
    name,
    label,
    rules,
    register,
  });
  const autoFocusProps = useAutoFocus(autoFocus);
  const mergedProps = {
    ...inputProps,
    ...otherProps,
    ...autoFocusProps,
    className: cn(
      inputProps.className,
      inputElement?.props.className,
      className,
      css.inputElement
    ),
  };

  return (
    <FormGroup
      id={id}
      className={className}
      style={{ width }}
      label={label && <Label />}
      learnMore={learnMore}
      helpText={helpText}
      error={error}
      ref={ref}
    >
      <label
        className={cn(css.inputContainer, "label", "input", {
          "has-error": !!error,
        })}
        htmlFor={inputProps.id}
      >
        {addOnBefore && <div className={css.addOnBefore}>{addOnBefore}</div>}

        {inputElement ? (
          cloneElement(inputElement, mergedProps)
        ) : (
          <input type="text" {...mergedProps} />
        )}

        {addOnAfter && <div className={css.addOnAfter}>{addOnAfter}</div>}
      </label>
    </FormGroup>
  );
}

const TextInputWithRef = forwardRef(TextInput);

export { TextInputWithRef as TextInput };
