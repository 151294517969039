import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import LinesSummary from "components/ModuleSummary/LinesSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import { uniqBy } from "lodash";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import { formatOxfordComma } from "utils/formatters";
import EMPLOYMENT_RECORDS_MODULE from "./EmploymentRecordsModule.gql";

export default function EmploymentSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<EmploymentRecordsModule>(
    EMPLOYMENT_RECORDS_MODULE,
    { returnPartialData: true }
  );

  const items = data?.employmentRecords?.filter((er) => !er.ownedBusiness);

  return (
    <ModuleSummary {...props} progress={data?.progress.employment}>
      {data?.filingProfile?.hasNoEmploymentIncome ? (
        <HasNoSummary>
          Your household hasn&rsquo;t received any employment income in the last
          6 months.
        </HasNoSummary>
      ) : (
        <>
          <LinesSummary
            requiredData={items}
            maxLines={1}
            style={{ textAlign: "center" }}
          >
            Your household worked at{" "}
            {formatOxfordComma(
              ...uniqBy(items || [], "companyName").map(
                (e: EmploymentRecord) => e.companyName
              )
            )}
            .
          </LinesSummary>

          <SummaryStatsRow>
            <NumericSummary
              label="job"
              maxValueLength={1}
              value={items?.length}
              pluralizeLabel
            />
            <NumericSummary
              label="current monthly salary"
              valuePrefix="$"
              maxValueLength={5}
              value={fancyNumberSum(
                items?.filter((e) => !e.endMonth),
                "monthlySalary"
              )}
            />
            <NumericSummary
              label="made in the last 6 months"
              valuePrefix="$"
              maxValueLength={6}
              value={fancyNumberSum(items, "lastSixMonthsGrossAmount")}
            />
          </SummaryStatsRow>
        </>
      )}
    </ModuleSummary>
  );
}
