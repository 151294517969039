import { ListItem } from "components/ListItem";
import * as React from "react";
import { formatGraphAddress } from "utils/formatters";

interface ResidenceCardProps extends React.HTMLAttributes<HTMLDivElement> {
  address: Address;
  onEdit?: () => void;
  onDelete?: () => Promise<any>;
}

export function ResidenceCard({ address, ...etc }: ResidenceCardProps) {
  return <ListItem {...etc}>{formatGraphAddress(address)}</ListItem>;
}
