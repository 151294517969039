import { AnimatedSwitch } from "components/AnimatedSwitch";
import { GuardedRoute } from "components/GuardedRoute";
import { Nav, NavItem } from "components/ProgressNav";
import React from "react";
import { Redirect } from "react-router-dom";
import { useNested } from "utils/hooks";
import { WizardRenderProps, WizardRouteChildProps } from ".";
import css from "./Wizard.module.css";

export default function HorizontalWizard({
  nav,
  children,
  lastEnabledStep,
  hideNavigation,
  hideButtons,
  form,
  onNav,
  error,
  isEdit,
}: WizardRenderProps) {
  const { url, path } = useNested();
  return (
    <>
      <AnimatedSwitch sequence={children.map((c) => url(c.props.path))}>
        {children.map((child, index) => {
          const routeDisabled = index > lastEnabledStep;
          const prevPath = children[index - 1]?.props?.path;
          const childProps: WizardRouteChildProps = {
            form,
            isEdit,
            navProps: nav.props,
          };
          return (
            <GuardedRoute
              key={child.props.path}
              path={path(child.props.path)}
              guard={!routeDisabled}
              renderFallback={() => (
                <Redirect
                  to={
                    prevPath
                      ? url(prevPath, { preserveSearch: true })
                      : url(children[0].props.path, { preserveSearch: true })
                  }
                />
              )}
              render={() => (
                <div>
                  {React.createElement(child.props.component, childProps)}
                  {error && <p className="errors">{error}</p>}
                </div>
              )}
            />
          );
        })}
      </AnimatedSwitch>
      {!hideButtons && nav}
      {!hideNavigation && (
        <Nav className={css.progressNav}>
          {children.map((child, index) => {
            const linkURL = url(child.props.path, { preserveSearch: true });
            return (
              <NavItem
                key={child.props.path}
                to={linkURL}
                onClick={(e) => onNav(e, linkURL)}
                disabled={index > lastEnabledStep}
              >
                {child.props.title}
              </NavItem>
            );
          })}
        </Nav>
      )}
    </>
  );
}
