import { LearnMore } from "components/LearnMore";
import * as React from "react";

export function IDLearnMore() {
  return (
    <LearnMore>
      <h4>What if I don’t have a Social Security number?</h4>
      <p>
        If you don’t have a Social Security number (SSN), please enter an
        Individual Taxpayer ID number (ITIN) in the SSN field. If you don’t have
        either, you’ll need to get one or the other before continuing with your
        application.
      </p>

      <h4>Applying for an SSN or ITIN</h4>
      <p>
        Applying for an SSN is easy if you qualify. Head over to the{" "}
        <a href="https://www.ssa.gov/forms/" target="blank" rel="noopener">
          Social Security Administration
        </a>{" "}
        and complete form SS-5. The form includes instructions for submitting
        your application to a Social Security office.
      </p>

      <p>
        If you don’t qualify for an SSN, you’ll need to apply for an ITIN. ITINs
        are issued by the Internal Revenue Service to foreign nationals and
        others who have federal tax requirements but who don’t qualify for SSNs.
        You can apply for an ITIN by completing{" "}
        <a
          href="https://www.irs.gov/forms-pubs/about-form-w-7"
          target="blank"
          rel="noopener"
        >
          Form W-7
        </a>{" "}
        on the IRS website.
      </p>
    </LearnMore>
  );
}
