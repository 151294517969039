import cn from "classnames";
import { ControlledNumberInput } from "components/NumberInput";
import { Progress } from "components/Progress";
import { Slider } from "components/Slider";
import { cumsum, fsum } from "d3-array";
import { motion } from "framer-motion";
import * as React from "react";
import { breakpoints, useBreakpoint } from "utils/hooks";
import { fuzzyEquals, roundToTwoDecimalPlaces } from "utils/math";
import { TableItem } from ".";
import { useTemporarilyShowSnappingGuides } from "./hooks";
import css from "./style.module.scss";
import { formatDisplayValue, unformatDisplayValue } from "./utils";

interface RenderProps<Details> {
  renderTableWarnings?: (
    total: number,
    itemTotals: number,
    items: TableItem<Details>[]
  ) => React.ReactNode;
  renderItemWarnings?: (
    item: TableItem<Details>,
    i: number,
    items: TableItem<Details>[]
  ) => React.ReactNode;
  renderItemEntry: (
    item: TableItem<Details>,
    i: number,
    items: TableItem<Details>[]
  ) => React.ReactNode;
  renderTotalHeading?: (
    total: number,
    sumOfItems: number,
    items: TableItem<Details>[]
  ) => React.ReactNode;
  renderItemHeading?: (
    total: number,
    sumOfItems: number,
    items: TableItem<Details>[]
  ) => React.ReactNode;
  renderItemValueHeading?: (
    total: number,
    sumOfItems: number,
    items: TableItem<Details>[]
  ) => React.ReactNode;
}

interface EventHandlers<Details> {
  onChangeItem: (updatedItem: TableItem<Details>) => void;
  onChangeTotal: ({
    value,
    displayValue,
  }: {
    value: number;
    displayValue: string;
  }) => void;
}

export interface BreakdownTableProps<Details>
  extends RenderProps<Details>,
    EventHandlers<Details> {
  items: TableItem<Details>[];
  total: { value: number; displayValue: string };
  snappingPoints?: { value: number; label: React.ReactNode }[];
  disabled?: boolean;

  getColorForItem?: (
    item: TableItem<Details>,
    i: number,
    items: TableItem<Details>[]
  ) => string;
  getBackgroundColorForItem?: (
    item: TableItem<Details>,
    i: number,
    items: TableItem<Details>[]
  ) => string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function BreakdownTable<Details = {}>({
  items,
  total,
  snappingPoints = [],
  disabled = false,

  getColorForItem = () => "var(--primary)",
  getBackgroundColorForItem = () => "var(--primary-bg)",

  renderTableWarnings,
  renderItemWarnings,
  renderItemEntry = () => "Total",
  renderItemHeading,
  renderItemValueHeading,
  renderTotalHeading,

  onChangeItem,
  onChangeTotal,
}: BreakdownTableProps<Details>) {
  const [tableActive, setTableActive] = React.useState(false);
  const [snappingGuidesTemporarilyActive, activateSnappingGuidesTemporarily] =
    useTemporarilyShowSnappingGuides([total]);

  const cumulativeItemSums = cumsum(items, (item) => item.value);
  const sumOfItems = fsum(items, (item) => item.value);

  const [totalBeforeEdit, setTotalBeforeEdit] = React.useState<{
    value: number;
    displayValue: string;
  }>(null);
  const [itemBeforeEdit, setItemBeforeEdit] =
    React.useState<TableItem<Details>>(null);
  const isMobile = useBreakpoint(breakpoints.mobile);

  return (
    <motion.div positionTransition>
      <div className={css["table-head"]}>
        <div className={cn(css.heading, css["total-heading"])}>
          {renderTotalHeading?.(total.value, sumOfItems, items)}
        </div>
        <div className={css["total-input"]}>
          <ControlledNumberInput
            disabled={disabled}
            min={0}
            includeThousandsSeparator
            allowDecimal
            decimalLimit={2}
            prefix="$"
            onFocus={() => {
              setTotalBeforeEdit(total);
            }}
            onBlur={(e: any) => {
              const inputValue = e.target.value;
              const stashed = totalBeforeEdit;
              setTotalBeforeEdit(null);

              if (inputValue === "") {
                onChangeTotal(stashed);
              } else {
                onChangeTotal({
                  value: total.value,
                  displayValue: formatDisplayValue(
                    unformatDisplayValue(inputValue)
                  ),
                });
              }
            }}
            value={total.displayValue}
            onChange={(inputValue) => {
              if (inputValue === "") {
                onChangeTotal({
                  value: totalBeforeEdit.value,
                  displayValue: "",
                });
              } else {
                const parsed = unformatDisplayValue(inputValue);
                const updatedValue = !fuzzyEquals(
                  roundToTwoDecimalPlaces(total.value),
                  parsed
                )
                  ? parsed
                  : total.value;

                onChangeTotal({
                  value: updatedValue,
                  displayValue: inputValue,
                });
              }
            }}
          />
        </div>
        <div className={css.summary}>
          <Progress
            value={items}
            max={total.value}
            getColorForValue={(_, i) => getColorForItem(items[i], i, items)}
            className={cn(css["summary-progress"], {
              [css.empty]: !items.length,
            })}
            backgroundColor="transparent"
          />
        </div>
        {(renderItemHeading || renderItemValueHeading) && (
          <hr className={css.divider} />
        )}
        <div className={cn(css.heading, css["item-heading"])}>
          {renderItemHeading?.(total.value, sumOfItems, items)}
        </div>
        <div className={cn(css.heading, css["item-value-heading"])}>
          {renderItemValueHeading?.(total.value, sumOfItems, items)}
        </div>
      </div>
      <div
        className={cn(css["table-body"], { [css.empty]: items.length === 0 })}
      >
        {items.map((item, i) => {
          const runningTotal = cumulativeItemSums[i - 1] || 0;
          const sliderWidth = `${
            (100 * (total.value - runningTotal)) / total.value || 0
          }%`;
          const stepSize =
            total.value <= 100
              ? 0.25
              : Math.pow(10, Math.floor(Math.log10(total.value)) - 3); // nearest power of 10 to total / 1000

          const highlightColor = getColorForItem(item, i, items);
          const secondaryColor = getBackgroundColorForItem(item, i, items);

          return (
            <motion.div
              key={item.id}
              className={cn(css.row, {
                [css.disabled]: disabled || fuzzyEquals(item.max, 0),
              })}
              positionTransition
              animate={{ height: "auto" }}
              initial={{ height: 0 }}
            >
              <div className={css["name-wrap"]}>
                <div
                  className={css.icon}
                  style={{ backgroundColor: highlightColor }}
                />
                <div className={css.name}>
                  {renderItemEntry(item, i, items)}
                </div>
              </div>
              <div
                className={cn(css["input-wrap"], {
                  [css.disabled]: disabled || fuzzyEquals(item.max, 0),
                })}
              >
                <ControlledNumberInput
                  disabled={disabled || fuzzyEquals(item.max, 0)}
                  min={0}
                  allowDecimal
                  includeThousandsSeparator
                  prefix="$"
                  onFocus={() => {
                    setItemBeforeEdit(item);
                    setTableActive(true);
                  }}
                  onBlur={(e: any) => {
                    const stashed = itemBeforeEdit;
                    setItemBeforeEdit(null);

                    if (e.target.value === "") {
                      onChangeItem(stashed);
                    } else {
                      const formatted = formatDisplayValue(
                        unformatDisplayValue(e.target.value)
                      );
                      if (formatted !== item.displayValue) {
                        onChangeItem({ ...item, displayValue: formatted });
                      }
                    }
                    setTableActive(false);
                  }}
                  value={item.displayValue}
                  onChange={(inputValue) => {
                    if (inputValue === "") {
                      onChangeItem({ ...itemBeforeEdit, displayValue: "" });
                      return;
                    }

                    const parsedInput = unformatDisplayValue(inputValue);
                    const value = isNaN(parsedInput)
                      ? itemBeforeEdit.value
                      : parsedInput;

                    onChangeItem({ ...item, value, displayValue: inputValue });
                    activateSnappingGuidesTemporarily();
                  }}
                />
              </div>
              <div className={css["slider-wrap"]}>
                <Progress
                  value={item.value}
                  max={total.value - runningTotal}
                  className={css["slider-progress"]}
                  style={{ width: sliderWidth }}
                  color={secondaryColor}
                  backgroundColor="transparent"
                >
                  <Slider
                    min={item.min}
                    max={item.max}
                    value={item.value}
                    step={stepSize}
                    showSnappingGuides={
                      tableActive || snappingGuidesTemporarilyActive
                    }
                    onActive={setTableActive}
                    snappingPoints={snappingPoints}
                    snappingTolerance={isMobile ? 10 : 5}
                    onChange={(value) => {
                      onChangeItem({ ...item, value });
                    }}
                    highlightColor={highlightColor}
                    secondaryColor={secondaryColor}
                    disabled={disabled || fuzzyEquals(item.max, 0)}
                  />
                </Progress>
              </div>
              {renderItemWarnings?.(item, i, items) ? (
                <div className={css["item-warning"]}>
                  {renderItemWarnings(item, i, items)}
                </div>
              ) : null}
            </motion.div>
          );
        })}
        {renderTableWarnings?.(total.value, sumOfItems, items) ? (
          <div className={css["table-warning"]}>
            {renderTableWarnings(total.value, sumOfItems, items)}
          </div>
        ) : null}
      </div>
    </motion.div>
  );
}
