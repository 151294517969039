export default {
  "application/msword": "wiz",
  "application/octet-stream": "so",
  "application/oda": "oda",
  "application/pdf": "pdf",
  "application/postscript": "ps",
  "application/vnd.ms-excel": "xlsx",
  "application/vnd.ms-powerpoint": "pwz",
  "application/x-cdf": "cdf",
  "application/x-csh": "csh",
  "application/x-dvi": "dvi",
  "application/x-gtar": "gtar",
  "application/x-hdf": "hdf",
  "application/x-javascript": "js",
  "application/x-latex": "latex",
  "application/x-mif": "mif",
  "application/x-netcdf": "nc",
  "application/x-pkcs12": "pfx",
  "application/x-pn-realaudio": "ram",
  "application/x-python-code": "pyo",
  "application/x-sh": "sh",
  "application/x-shar": "shar",
  "application/x-shockwave-flash": "swf",
  "application/x-tar": "tar",
  "application/x-tcl": "tcl",
  "application/x-tex": "tex",
  "application/x-texinfo": "texinfo",
  "application/x-troff": "tr",
  "application/x-troff-man": "man",
  "application/x-troff-me": "me",
  "application/x-troff-ms": "ms",
  "application/x-ustar": "ustar",
  "application/x-wais-source": "src",
  "application/xml": "xsl",
  "application/zip": "zip",
  "audio/basic": "snd",
  "audio/mpeg": "mp3",
  "audio/x-aiff": "aiff",
  "audio/x-pn-realaudio": "ra",
  "audio/x-wav": "wav",
  "image/gif": "gif",
  "image/jpeg": "jpg",
  "image/png": "png",
  "image/tiff": "tiff",
  "image/x-cmu-raster": "ras",
  "image/x-ms-bmp": "bmp",
  "image/x-portable-anymap": "pnm",
  "image/x-portable-bitmap": "pbm",
  "image/x-portable-graymap": "pgm",
  "image/x-portable-pixmap": "ppm",
  "image/x-rgb": "rgb",
  "image/x-xbitmap": "xbm",
  "image/x-xpixmap": "xpm",
  "image/x-xwindowdump": "xwd",
  "message/rfc822": "nws",
  "text/css": "css",
  "text/html": "html",
  "text/plain": "txt",
  "text/richtext": "rtx",
  "text/tab-separated-values": "tsv",
  "text/x-python": "py",
  "text/x-setext": "etx",
  "text/x-sgml": "sgml",
  "text/x-vcard": "vcf",
  "text/xml": "xml",
  "video/mp4": "mp4",
  "video/mpeg": "mpg",
  "video/quicktime": "qt",
  "video/x-msvideo": "avi",
  "video/x-sgi-movie": "movie",
} as { [mimetype: string]: string };
