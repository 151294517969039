import { useListRoot } from "components/EditCreateList/hooks";
import {} from "components/EditCreateList/stateless";
import * as React from "react";
import { EnvironmentalCleanupNoticeWizard } from "./EnvironmentalCleanupNoticeWizard";

export function CreateView({ listURL }: { listURL: string }) {
  const root = useListRoot();
  const [nextURL, setNextURL] = React.useState(`${root.url}/summary`);
  return (
    <EnvironmentalCleanupNoticeWizard
      title="Add a Cleanup Notice"
      backURL={`${root.url}/summary`}
      nextURL={nextURL}
    />
  );
}
