import { useListStudentLoan } from "api/graphql/StudentLoan";
import Axios from "axios";
import { StatelessEditCreateList } from "components/EditCreateList";
import { CreateViewProps } from "components/EditCreateList/stateless";
import { Loading } from "components/Loading";
import { filter, last } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  StudentLoanStatement,
  StudentLoanStatementSchema,
} from "../AddStatementWizard";
import CreateView from "./CreateView";
import EditView from "./EditView";
import ListView from "./ListView";
import { LoanWizardOutput } from "components/FormModules/StudentLoans/StudentLoanWizard";

export interface StatementViewProps {
  doneURL: string;
  prevURL: string;
  statementHash: string;
  statement?: StudentLoanStatement;
  onCreate?: (loan: LoanWizardOutput) => void;
}

export default function StatementView({
  doneURL,
  prevURL,
  statementHash,
  statement,
  onCreate,
}: StatementViewProps) {
  const { data, loading } = useListStudentLoan();

  const loans = useMemo(
    () =>
      filter(data?.studentLoans, (l) => l.statementFileHash === statementHash),
    [data, statementHash]
  );

  const [_statement, setStatement] = useState(statement);

  useEffect(() => {
    if (statement || !loans.length) return;
    Axios.get(`${loans[0].statementFile.url}`, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    }).then(({ data }) => {
      const newStatement = StudentLoanStatementSchema.validateSync(
        {
          ...loans[0],
          statementFile: new File(
            [data],
            last(loans[0].statementFile.name.split("/"))
          ),
        },
        { stripUnknown: true }
      );
      setStatement(newStatement);
    });
  }, [loans, statement]);

  const CreateWithStatement = useCallback(
    (props: CreateViewProps) => (
      <CreateView {...props} statement={_statement} onCreate={onCreate} />
    ),
    [_statement, onCreate]
  );

  if (loading) return <Loading />;
  if (!loans.length && !_statement) return <Redirect to={doneURL} />;
  if (loans.length && !_statement) return <Loading />;

  return (
    <StatelessEditCreateList
      prev={prevURL}
      next={doneURL}
      CreateView={CreateWithStatement}
      EditView={EditView}
      ListView={(props) => (
        <ListView {...props} items={loans} statement={statement} />
      )}
    />
  );
}
