import { LearnMore } from "components/LearnMore";
import { OptionsList } from "components/OptionCard";
import React from "react";
import { createPortal } from "react-dom";
import { EmailLink } from "./ContactTypes";

export function ContactUs() {
  const contactRoot = document.getElementById("react-contact-root");
  if (!contactRoot) return null;

  return createPortal(
    <LearnMore linkText="Contact us">
      <p>
        Please don’t hesitate to reach out with any questions or concerns. We’re
        here to help you however we can.
      </p>

      <OptionsList minWidth={140}>
        <EmailLink />
      </OptionsList>
    </LearnMore>,
    contactRoot
  );
}
