import cn from "classnames";
import { cumsum } from "d3-array";
import { clamp, isArray, isNumber } from "lodash";
import * as React from "react";
import css from "./style.module.scss";

type RecordWithValue<Details = any> = { value: number } & Details;
type AggregateValue = number[] | RecordWithValue[];
type Value = number | AggregateValue;
type ValueType<V> = V extends number ? number : V extends (infer U)[] ? U : V;

interface ProgressProps<V extends Value>
  extends React.HTMLAttributes<HTMLDivElement> {
  max?: number;
  value: V;
  color?: string;
  gapColor?: string;
  getColorForValue?: (v: ValueType<V>, i: number) => string;
  backgroundColor?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export function Progress<V extends Value>({
  max = 100,
  value,
  color = "var(--primary)",
  gapColor = "#eee",
  getColorForValue,
  backgroundColor = "var(--primary-bg)",
  children = null,
  style = {},
  className,
  ...etc
}: ProgressProps<V>) {
  let percentages: number[] = [];
  if (isNumber(value)) {
    percentages = [clamp((100 * value) / max, 0, 100)];
  } else {
    for (const item of value as AggregateValue) {
      percentages.push(
        clamp((100 * (isNumber(item) ? item : item.value)) / max, 0, 100)
      );
    }
  }

  const totals = Array.from(cumsum(percentages));

  const colorFor = (value: ValueType<V>, i?: number) => {
    if (getColorForValue) {
      return getColorForValue(value, i);
    } else {
      return color;
    }
  };

  const gradientStops = totals
    .map((pct, i) => {
      const currentItem: any = isNumber(value)
        ? value
        : isArray(value)
        ? value[i]
        : undefined;

      const nextItem: any = isNumber(value)
        ? undefined
        : isArray(value)
        ? value[i + 1]
        : undefined;

      const addGap = totals.length > 1;
      const gapWidth = addGap ? "1px" : "0px";
      const gapCss = addGap
        ? `${gapColor} ${pct}%, ${gapColor} calc(${pct}% + ${gapWidth}),`
        : "";

      const currentItemCss = `${colorFor(currentItem, i)} ${pct}%`;
      const nextItemCss =
        nextItem === undefined
          ? ""
          : `, ${gapCss} ${colorFor(
              nextItem,
              i + 1
            )} calc(${pct}% + ${gapWidth})`;

      return currentItemCss + nextItemCss;
    })
    .join(", ");

  const backgroundImage = percentages.length
    ? `linear-gradient(to right, ${gradientStops}, ${backgroundColor} ${
        totals[totals.length - 1]
      }%, ${backgroundColor})`
    : "unset";

  /* jsdom has no background-image support, so give our tests a little help */
  return (
    <div
      style={
        {
          ...style,
          backgroundImage,
          "--test-background-image": backgroundImage,
        } as React.CSSProperties
      }
      className={cn(css.progress, className)}
      role="progress"
      {...etc}
    >
      {children}
    </div>
  );
}
