import { useApolloClient } from "@apollo/client";
import { useListMxMembers, useImportMxMember } from "api/graphql/MxMember";
import cn from "classnames";
import { ButtonRow } from "components/ButtonRow";
import { Card } from "components/Card";
import { Loading } from "components/Loading";
import { Modal } from "components/Modal";
import { Title } from "components/Title";
import { MxConnectionStatus } from "globalTypes";
import gql from "graphql-tag";
import { sortBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { FaArrowLeft, FaCheck, FaDownload, FaPlus } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useQueryParams } from "utils/hooks";
import useActivityTimeout from "utils/hooks/useActivityTimeout";
import css from "./ImportView.module.css";
import MxMemberListItem from "./MxMemberListItem";
import LawFirmNotice from "components/LawFirmNotice";

export default function ImportView() {
  const isActive = useActivityTimeout();
  const client = useApolloClient();
  const { data, error: listMembersError } = useListMxMembers({
    pollInterval: isActive ? 5000 : 0,
  });
  const [importMember] = useImportMxMember();
  const { from = "/new-filing", startImport } = useQueryParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [connectURLError, setConnectURLError] = useState(false);
  const [loadingConnectURL, setLoadingConnectURL] = useState(false);
  const history = useHistory();

  const [connectURL, setConnectURL] = useState("");

  const refreshConnect = useCallback(
    (memberGUID?: string) =>
      new Promise((res, rej) => {
        client
          .mutate<GetConnectUrl>({
            mutation: gql`
              mutation GetConnectURL($memberGUID: String) {
                mxConnectUrl(memberGUID: $memberGUID)
              }
            `,
            variables: { memberGUID },
          })
          .then((rsp) => {
            setConnectURL(rsp.data.mxConnectUrl);
            setConnectURLError(false);
            res(rsp.data.mxConnectUrl);
          })
          .catch((e) => {
            setConnectURLError(true);
            rej(e);
          });
      }),
    [client]
  );

  const onDone = () => {
    history.push(from);
  };

  useEffect(() => {
    const listener = async (e: MessageEvent) => {
      if (!e.origin.endsWith("moneydesktop.com")) return;
      // Insurance against MX changing the event schema again
      const parsedData =
        typeof e.data === "string" ? JSON.parse(e.data) : e.data;
      const { type } = parsedData;

      if (type === "mx/connect/memberConnected") {
        const { metadata } = parsedData;
        const { member_guid: memberGuid } = metadata;

        // Send the connected member GUID to the server for importing
        try {
          await importMember({ variables: { guid: memberGuid } });
        } catch (e) {
          console.error(e);
        }
      }

      if (type === "logout") {
        try {
          await refreshConnect();
        } catch (e) {
          setModalOpen(false);
        }
      }
    };

    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, [refreshConnect, importMember]);

  const openModal = useCallback(
    async (memberGUID?: string) => {
      // MxMemberListItem handles its own loading state
      if (!memberGUID) setLoadingConnectURL(true);

      try {
        await refreshConnect(memberGUID);
        setModalOpen(true);
      } catch (e) {
        // pass
      } finally {
        setLoadingConnectURL(false);
      }
    },
    [refreshConnect]
  );

  const isLoaded = !!data;

  useEffect(() => {
    if (isLoaded && startImport) openModal();
  }, [openModal, startImport, isLoaded]);

  if (!data && !listMembersError) return <Loading />;
  const mxMembers = data?.mxMembers || [];

  const activeConnections = sortBy(mxMembers, "institution.name").filter(
    (m) =>
      m.connectionStatus &&
      ![MxConnectionStatus.Created, MxConnectionStatus.Failed].includes(
        m.connectionStatus as any
      )
  );

  const errors = (connectURLError || listMembersError) && (
    <div className="errors">
      We are having trouble connecting with our import service. Please wait a
      while and try again.
    </div>
  );

  return (
    <>
      <LawFirmNotice />
      <div className={css.container}>
        <Title>Import your financial accounts</Title>

        {activeConnections.length === 0 ? (
          <>
            <p>
              To import your data, you must log in to your online financial
              accounts. Use the following form to log in to all of your
              accounts, including:
            </p>
            <ul>
              <li>checking/savings accounts</li>
              <li>vehicle loans</li>
              <li>retirement accounts</li>
              <li>investment accounts, or</li>
              <li>health savings accounts (HSAs)</li>
            </ul>

            <ButtonRow
              left={
                <Link className="btn" to={from}>
                  <FaArrowLeft />
                  <span>Back</span>
                </Link>
              }
              right={
                <button
                  onClick={() => openModal()}
                  className={cn("btn primary", { loading: loadingConnectURL })}
                  disabled={!!listMembersError}
                >
                  <span>Start import</span>
                  <FaDownload />
                </button>
              }
            />

            {errors}
          </>
        ) : (
          <>
            <p>
              Continue connecting your financial institutions until as many of
              your accounts as possible are shown below. We currently support
              importing the following account types:
            </p>

            <ul>
              <li>checking/savings accounts</li>
              <li>vehicle loans</li>
              <li>retirement accounts</li>
              <li>investment accounts, or</li>
              <li>health savings accounts (HSAs)</li>
            </ul>

            <ButtonRow
              left={
                <button
                  onClick={() => openModal()}
                  className={cn("btn secondary", {
                    loading: loadingConnectURL,
                  })}
                >
                  <FaPlus />
                  <span>Connect more</span>
                </button>
              }
              right={
                <button className="primary btn" onClick={onDone}>
                  <span>Done</span>
                  <FaCheck />
                </button>
              }
            />

            {errors}
          </>
        )}

        <Modal isOpen={modalOpen}>
          <Card className={css.modalCard}>
            <iframe
              src={connectURL}
              width="100%"
              height="800px"
              frameBorder="0"
              style={{ flexShrink: 1 }}
              data-testid="connect-iframe"
            />
            <button
              className="btn primary"
              onClick={() => setModalOpen(false)}
              style={{ margin: "20px" }}
            >
              Done
            </button>
          </Card>
        </Modal>

        {activeConnections.length > 0 && (
          <>
            <h3 style={{ marginTop: "32px" }}>Your connected institutions</h3>
            {activeConnections.map((m) => (
              <MxMemberListItem
                key={m.id}
                member={m}
                onReconnect={() => openModal(m.id)}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
}
