import { useQuery } from "@apollo/client";
import { useDeleteVehicleLoan } from "api/graphql/VehicleLoan";
import { ListViewProps } from "components/EditCreateList/stateless";
import { StatelessList } from "components/EditCreateList/StatelessList";
import ImportEntry from "components/ImportView/Entry";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import React from "react";
import { FaDownload } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import {
  formatErrorsFromSchema,
  formatName,
  formatOxfordComma,
  formatQuantity,
  formatVehicleName,
} from "utils/formatters";
import { useNested } from "utils/hooks";
import VEHICLE_LOANS_MODULE from "./VehicleLoansModule.gql";
import { VehicleLoanSchema } from "./VehicleLoanWizard";
import { useGetSession } from "api/graphql/Session";

export default function ListView(props: ListViewProps) {
  const { data, loading } = useQuery<VehicleLoansModule>(VEHICLE_LOANS_MODULE);
  const [onDelete] = useDeleteVehicleLoan();
  const { path, url } = useNested();
  const { data: sessionData, loading: sessionLoading } = useGetSession();

  if (loading || sessionLoading) return <Loading />;
  const { vehicleLoans: items } = data;
  const { enableImportFinancialAccounts } =
    sessionData?.session.user.referralFirm.featureFlags;

  return (
    <>
      <Title>Your Vehicle Loans</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <QuestionView
              filingProfileBoolean="hasNoVehicleLoans"
              createURL={url("/decision")}
              nextURL={props.nextURL}
              addText="Add a vehicle loan"
              question={
                <p className="required">
                  <strong>Do you have any vehicle loans?</strong>
                </p>
              }
              confirmation={
                <p>
                  You have indicated that you don&rsquo;t have any loans for
                  vehicles you own. If that&rsquo;s correct, you&rsquo;re ready
                  to continue to the next section. Otherwise, you may add auto
                  loans below.
                </p>
              }
            />
          )}
        />
        <Route
          path={path("/decision")}
          render={() => (
            <ImportEntry
              manualCreateURL={props.createURL}
              nextURL={url("/")}
              items={items}
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <p>
                In order to file your bankruptcy, we need to know about all your
                outstanding loans for your vehicles, including any you have
                cosigned.
              </p>

              {enableImportFinancialAccounts && (
                <Link
                  to={`/new-filing/financial-import?from=${window.location.pathname}&startImport=true`}
                  className="btn"
                  style={{ alignSelf: "flex-start", marginTop: "8px" }}
                >
                  <FaDownload />
                  <span>Import loans</span>
                </Link>
              )}

              <StatelessList
                {...props}
                emptyText="You haven’t added any vehicle loans yet."
                addButtonText="Add a vehicle loan"
                items={items}
                deleteItem={({ id }) => onDelete(id)}
                renderItem={(l) => (
                  <div>
                    {formatErrorsFromSchema(VehicleLoanSchema, l)}
                    <strong>
                      Vehicle loan {l.accountNumber} from {l.lenderName}{" "}
                      {l.vehicle &&
                        `for your ${formatVehicleName(l.vehicle.vehicle)}`}
                    </strong>
                    {!!l.cosigners.length && (
                      <div className="supporting-text">
                        Cosigned with{" "}
                        {formatOxfordComma(...l.cosigners.map(formatName))}
                      </div>
                    )}
                    {!!l.collectors.length && (
                      <div className="supporting-text">
                        {formatQuantity(l.collectors.length, " debt collector")}
                      </div>
                    )}
                  </div>
                )}
              />
            </>
          )}
        />
      </Switch>
    </>
  );
}
