import React from "react";

export function LearnMoreCommon() {
  return (
    <>
      <h4>Should I include expenses for everyone in my household?</h4>
      <p>
        Yes. Include expenses for everyone you document as a member of your
        household.
      </p>

      <h4>How specific should I get in breaking down expenses?</h4>
      <p>
        Being specific when listing and documenting your expenses allows your
        attorney to determine which expenses are allowable. This will make your
        actual filing simpler and bolster your argument that you are an honest
        debtor looking to obtain a fresh start.
      </p>
      <p>
        Expenses that are unique to a household member and their personal
        circumstances should be documented as individual expenses, such as
        physical therapy for an individual. Common household expenses like food
        from multiple grocery stores or mobile phones for the entire household
        should be combined.
      </p>

      <h4>How should I handle expenses with changing monthly amounts?</h4>
      <p>
        If the monthly total changes, such as purchasing new books or an
        electric bill, add up how much you have spent on this over the last six
        months, then divide this total by six to get a monthly average.
      </p>
    </>
  );
}
