import { Card } from "components/Card";
import { Title } from "components/Title";
import * as React from "react";
import { Wizard } from "./Wizard";

export interface MyStudentDataUploaderProps {
  next: string;
  prev: string;
  previousLoansCount: number;
}

export function MyStudentDataUploader({
  next,
  prev,
  previousLoansCount,
}: MyStudentDataUploaderProps) {
  return (
    <>
      <Title>Import public loans</Title>
      <Card>
        <Wizard
          previousLoansCount={previousLoansCount}
          abortURL={prev}
          nextURL={next}
        />
      </Card>
    </>
  );
}
