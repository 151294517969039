import { BigIcon } from "components/Big";
import { BigIconProps } from "components/Big/BigIcon/BigIcon";
import * as React from "react";
import {
  FaCheckCircle,
  FaCloudUploadAlt,
  FaExclamationTriangle,
} from "react-icons/fa";

export function OverviewIcon(props: BigIconProps) {
  return (
    <BigIcon {...props}>
      <FaCloudUploadAlt />
    </BigIcon>
  );
}

export function SuccessIcon(props: BigIconProps) {
  return (
    <BigIcon {...props}>
      <FaCheckCircle />
    </BigIcon>
  );
}

export function ErrorIcon(props: BigIconProps) {
  return (
    <BigIcon {...props}>
      <FaExclamationTriangle />
    </BigIcon>
  );
}
