import {
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useQuery,
} from "@apollo/client";
import { client } from "api/graphql/client";
import RESEND_VERIFICATION_EMAIL from "../Session/ResendVerificationEmail.gql";
import GET_SESSION from "./GetSession.gql";

export function useGetSession(options?: QueryHookOptions) {
  return useQuery<GetSession>(GET_SESSION, {
    fetchPolicy: "no-cache",
    ...(options || {}),
  });
}

export async function querySession() {
  const { data } = await client.query<GetSession>({ query: GET_SESSION });
  return data;
}

export function useResendVerificationEmail(
  options?: MutationHookOptions<ResendVerificationEmail>
) {
  return useMutation<ResendVerificationEmail>(
    RESEND_VERIFICATION_EMAIL,
    options
  );
}
