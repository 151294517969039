import { StatelessEditCreateList } from "components/EditCreateList";
import { EditView } from "./EditView";
import { ListView } from "./ListView";
import { CreateView } from "./CreateView";

export interface Props {
  prev: string;
  next: string;
}

export function HouseholdContributors({ prev, next }: Props) {
  return (
    <StatelessEditCreateList
      ListView={ListView}
      EditView={EditView}
      CreateView={CreateView}
      prev={prev}
      next={next}
    />
  );
}
