import { useGetEmploymentRecord } from "api/graphql/EmploymentRecord";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import { EmploymentWizard } from "./EmploymentWizard";

interface Props {
  id: string;
  listURL: string;
}

export function EditView({ id, listURL }: Props) {
  const { data, loading } = useGetEmploymentRecord(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.employmentRecord) {
    return <Redirect to={listURL} />;
  }

  return (
    <EmploymentWizard
      title="Updating Job"
      backURL={listURL}
      nextURL={listURL}
      initialValue={data.employmentRecord}
    />
  );
}
