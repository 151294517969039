import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary from "components/ModuleSummary";
import React from "react";
import COFILER_MODULE from "./CofilerModule.gql";

export default function ConsultationsSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<CofilerModule>(COFILER_MODULE, {
    returnPartialData: true,
  });

  return <ModuleSummary {...props} progress={data?.progress.cofiler} />;
}
