import cn from "classnames";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import { LinkCard } from "components/LinkCard";
import { ModuleStatus } from "globalTypes";
import { startCase, toLower } from "lodash";
import React, { CSSProperties, ReactNode } from "react";
import { IconType } from "react-icons";
import {
  FaCheckCircle,
  FaDotCircle,
  FaExclamationCircle,
  FaRegSquare,
} from "react-icons/fa";
import css from "./ModuleSummary.module.scss";

interface Props extends SubModuleSummaryProps {
  progress?: ModuleProgress | null;
  children?: ReactNode;
}

export default function ModuleSummary({
  href,
  icon: Icon,
  title,
  children,
  progress,
}: Props) {
  const { status, messages } = progress || {};

  const progressIndicators: Record<ModuleStatus, [IconType, string]> = {
    [ModuleStatus.Complete]: [FaCheckCircle, "var(--green)"],
    [ModuleStatus.InProgress]: [FaExclamationCircle, "var(--secondary-dark)"],
    [ModuleStatus.NotStarted]: [FaDotCircle, "var(--primary)"],
  };

  const [ProgressIcon, color] = progressIndicators[status];
  const hasContent = !!children && status !== ModuleStatus.NotStarted;

  return (
    <LinkCard to={href} className={css.container}>
      <div className={css.header}>
        <Icon style={{ marginRight: "8px" }} />
        <h3 style={{ flexGrow: 1, margin: 0 }}>{title}</h3>
        <div style={{ color }} className={css.progressIndicator}>
          <div>{startCase(toLower(status))}</div>
          <ProgressIcon size="24px" />
        </div>
      </div>

      {!!messages?.length && (
        <div className={css.messages}>
          <h5 style={{ marginTop: "4px" }}>To Do:</h5>
          {messages?.map((m) => (
            <div key={m} className={css.feedback}>
              <FaRegSquare /> <span>{m}</span>
            </div>
          ))}
        </div>
      )}

      {hasContent && <div className={cn(css.summary)}>{children}</div>}
    </LinkCard>
  );
}

export function SummaryStatsRow({
  children,
  style,
}: {
  children: ReactNode;
  style?: CSSProperties;
}) {
  return (
    <div className={css.summaryStatsRow} style={style}>
      {children}
    </div>
  );
}

export function SummaryOverlay({ children }: { children: ReactNode }) {
  return <div className={css.summaryOverlay}>{children}</div>;
}

export function HasNoSummary({ children }: { children: ReactNode }) {
  return <div className={css.hasNoSummary}>{children}</div>;
}
