import { LearnMore } from "components/LearnMore";
import { MonthlyExpenseCategoryChoice } from "globalTypes";
import React from "react";
import {
  FaCertificate,
  FaQuestionCircle,
  FaShoppingCart,
  FaTshirt,
} from "react-icons/fa";
import { MdHealing } from "react-icons/md";
import { ExpensesCategoryDetail } from "../CategorySection/CategoryDetail";
import { LearnMoreCommon } from "../LearnMoreCommon";

export const clothingCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.HouseholdClothing,
  displayName: "Clothing",
  displayLowercaseName: "clothing",
  hasNoProfileBooleanName: "hasNoHouseholdClothingExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on clothing over the last six months? Be sure to include spending for all household members.",
  urlPathName: "clothing",
  menuIcon: FaTshirt,
  description: (
    <>
      <p>Clothing expenses include but are not limited to purchases of:</p>
      <ul>
        <li>Clothing</li>
        <li>Footwear</li>
        <li>Material and patterns for making clothes</li>
        <li>Alterations or repairs</li>
        <li>Storage</li>
        <li>Watches or jewelry</li>
        <li>Laundry and dry cleaning</li>
      </ul>

      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};

export const foodCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.HouseholdFood,
  displayName: "Food",
  displayLowercaseName: "food",
  hasNoProfileBooleanName: "hasNoHouseholdFoodExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on food over the last six months? Be sure to include spending for all household members.",
  urlPathName: "food",
  menuIcon: FaShoppingCart,
  description: (
    <>
      <p>
        Food includes goods purchased to eat at home and meals purchased away
        from the home at restaurants, takeout, and delivery. For meals purchased
        away from home, include tips.
      </p>

      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};

export const healthCareCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.HouseholdMedicalExpenses,
  displayName: "Health Care",
  displayLowercaseName: "health care",
  hasNoProfileBooleanName: "hasNoHouseholdMedicalExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on health care over the last six months? Be sure to include spending for all household members.",
  urlPathName: "health_care",
  menuIcon: MdHealing,
  description: (
    <>
      <p>Health care expenses include but are not limited to purchases of:</p>
      <ul>
        <li>Products for personal health care like bandages and supports</li>
        <li>Medications</li>
        <li>Physical therapy</li>
        <li>Mental health therapy</li>
        <li>Glasses or contacts</li>
      </ul>

      <p>
        You should <em>not</em> include health insurance costs. Those are
        covered in another section.
      </p>

      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};

export const otherCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.HouseholdMisc,
  displayName: "Other",
  displayLowercaseName: "other household",
  hasNoProfileBooleanName: "hasNoHouseholdMiscExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on other types of household expenses over the last six months? Be sure to include spending for all household members.",
  urlPathName: "other",
  menuIcon: FaQuestionCircle,
  description: (
    <>
      <p>
        Don&rsquo;t include insurance expenses, which are covered in the next
        section.
      </p>
      <p>Common expenditures include:</p>
      <ul>
        <li>Government fees</li>
        <li>Bank fees and charges</li>
        <li>
          Unexpired contracts for services (for example: equipment leases, spa
          memberships, service contracts)
        </li>
      </ul>
      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};

export const personalCareCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.HouseholdPersonalCare,
  displayName: "Personal Care",
  displayLowercaseName: "personal care",
  hasNoProfileBooleanName: "hasNoHouseholdPersonalCareExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on personal care over the last six months? Be sure to include spending for all household members.",
  urlPathName: "personal_care",
  menuIcon: FaCertificate,
  description: (
    <>
      <p>Personal care expenses include but are not limited to purchases of:</p>
      <ul>
        <li>Products for hair like shampoo</li>
        <li>Oral hygiene products such as toothpaste</li>
        <li>Shaving needs including shaving cream and razors</li>
        <li>Cosmetics such as makeup</li>
        <li>
          Electric personal care products like electric razors and toothbrushes
        </li>
      </ul>

      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};
