import * as React from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { Schema } from "yup";

export function formatErrorsFromSchema(schema: Schema<any>, object: any) {
  try {
    schema.validateSync(object, { abortEarly: false });
  } catch (e) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color: "var(--secondary-dark)",
          marginBottom: "5px",
        }}
      >
        <FaExclamationCircle />
        <strong style={{ marginLeft: "5px" }}>Incomplete</strong>
      </div>
    );
  }
  return null;
}
