import { CategorySection } from "components/FormModules/Assets/HouseholdItems/CategorySection";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { useNested } from "utils/hooks";
import HouseholdItemCategories from "./HouseholdItemCategories";
import sectionCategories from "./ItemTypes";

export function HouseholdItems({ prev, next }: { prev: string; next: string }) {
  const { path, url } = useNested();

  return (
    <Switch>
      {sectionCategories.map((categoryDetail, index) => {
        return (
          <Route
            key={index}
            path={path(`${categoryDetail.urlPathName}`)}
            render={() => (
              <CategorySection
                prev={url("/")}
                next={url("/")}
                categoryDetail={categoryDetail}
              />
            )}
          />
        );
      })}
      <Route
        exact
        path={path("/")}
        render={() => <HouseholdItemCategories doneURL={next} />}
      />
    </Switch>
  );
}
