import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaUserFriends } from "react-icons/fa";
import { Spouse } from "./Spouse";
import SpouseSummary from "./Summary";

const SpouseSubmodule: SubModule = {
  path: "partner",
  title: "Your Partner/Spouse",
  icon: FaUserFriends,
  component: Spouse,
  summary: SpouseSummary,
  statusName: "spouse",
};

export default SpouseSubmodule;
