import { useQuery } from "@apollo/client";
import GetOrCreate from "components/GetOrCreate";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import gql from "graphql-tag";
import React from "react";
import { formatVehicleName } from "utils/formatters";
import { object, string } from "yup";

export const VehicleLoanVehicleSchema = object({
  vehicle: object({
    id: string().required("This step is required.").nullable(),
  }).required("This step is required."),
}).required();

export const GET_OWNED_VEHICLE_CANDIDATES = gql`
  query GetOwnedVehicleCandidates {
    ownedVehicles {
      id
      vehicle {
        id
        year
        make
        model
      }
    }
  }
`;

export default function Vehicle({ navProps }: WizardRouteChildProps) {
  const { data, loading } = useQuery<GetOwnedVehicleCandidates>(
    GET_OWNED_VEHICLE_CANDIDATES
  );

  return (
    <>
      <p>Which vehicle is this loan for?</p>
      <GetOrCreate
        name="vehicle.id"
        createURL="create_vehicle"
        objectName="vehicle"
        loading={loading}
        options={data?.ownedVehicles?.map((item) => ({
          value: item.id,
          label: formatVehicleName(item.vehicle),
        }))}
      />
      <WizardNavigation {...navProps} />
    </>
  );
}
