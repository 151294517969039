import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDeleteEvict, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_EMPLOYMENT_RECORD from "./DeleteEmploymentRecord.gql";
import GET_EMPLOYMENT_RECORD from "./GetEmploymentRecord.gql";
import LIST_EMPLOYMENT_RECORD from "./ListEmploymentRecord.gql";
import UPSERT_EMPLOYMENT_RECORD from "./UpsertEmploymentRecord.gql";

const LIST_EMPLOYMENT_RECORD_IDS = gql`
  query ListEmploymentRecordIds {
    employmentRecords {
      id
    }
  }
`;

export function useListEmploymentRecord(
  options?: QueryHookOptions<ListEmploymentRecord>
) {
  return useQuery<ListEmploymentRecord>(LIST_EMPLOYMENT_RECORD, options);
}

export function useGetEmploymentRecord(id: string) {
  return useQuery<GetEmploymentRecord, GetEmploymentRecordVariables>(
    GET_EMPLOYMENT_RECORD,
    {
      variables: { id },
    }
  );
}

export function useUpsertEmploymentRecord<
  Schema extends UpsertEmploymentRecord = UpsertEmploymentRecord,
  Variables = UpsertEmploymentRecordVariables
>(query = UPSERT_EMPLOYMENT_RECORD) {
  return useUpsert<
    Schema,
    Variables,
    ListEmploymentRecordIds,
    EmploymentRecordInput
  >(
    query,
    LIST_EMPLOYMENT_RECORD_IDS,
    "employmentRecord",
    "employmentRecords",
    (cache, data) => {
      const { ownedBusiness } = data.employmentRecord;
      if (ownedBusiness) {
        cache.modify({
          id: cache.identify({
            id: ownedBusiness.id,
            __typename: "OwnedBusiness",
          }),
          fields: {
            hasNoPersonalSalaries: () => false,
            personalSalaries: (existing, { toReference, readField }) => [
              toReference(data.employmentRecord),
              ...existing.filter(
                (ref: any) => readField("id", ref) !== data.employmentRecord.id
              ),
            ],
          },
        });
      } else {
        updateFilingProfileCache(cache, "hasNoEmploymentIncome", false);
      }
    }
  );
}

export function useDeleteEmploymentRecord() {
  return useDeleteEvict<DeleteEmploymentRecord>(
    DELETE_EMPLOYMENT_RECORD,
    "deleteEmploymentRecord",
    "EmploymentRecord"
  );
}
