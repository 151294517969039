import cn from "classnames";
import { ButtonRow } from "components/ButtonRow";
import { Card } from "components/Card";
import { Modal } from "components/Modal";
import { isArray } from "lodash";
import React, { ReactElement, ReactNode, useState } from "react";
import { FaExclamationCircle, FaTrashAlt } from "react-icons/fa";
import {
  formatOxfordComma,
  formatPlural,
  formatQuantity,
} from "utils/formatters";
import { useCheckMounted } from "utils/hooks";
import css from "./DeleteConfirmationModal.module.css";

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  onClose: () => any;
  onDelete: () => any;
  relatedItems: {
    [objectName: string]: ReactNode[] | ReactNode | null;
  };
  /** The singular, lowercase noun that describes the root object to be deleted */
  objectName: string;
}

/** A confirmation modal to alert users when deleting an object will also delete related objects. */
export default function DeleteConfirmationModal({
  isOpen,
  onClose,
  onDelete,
  relatedItems,
  objectName,
}: DeleteConfirmationModalProps) {
  const [performingDelete, setPerformingDelete] = useState(false);
  const isMounted = useCheckMounted();
  const relatedPairs = Object.entries(relatedItems)
    .map(
      ([name, entry]) =>
        [name, isArray(entry) ? entry : [entry].filter((x) => x)] as [
          string,
          ReactNode[]
        ]
    )
    .filter((x) => x[1].length > 0);
  const relatedQuantities = relatedPairs.map(([name, entries]) =>
    formatQuantity(entries.length, ` ${name}`)
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Card>
        <div className={css.warningTitle}>
          <FaExclamationCircle size="20px" />
          <h3>Heads up!</h3>
        </div>
        <p>
          Deleting this {objectName} will also delete{" "}
          {formatOxfordComma(...relatedQuantities)}.
        </p>
        {relatedPairs.map(([name, objects]) => (
          <div key={name}>
            <strong>
              {objects.length === 1 ? "This" : "These"}{" "}
              {formatPlural(objects.length, ` ${name}`)} will be deleted:
            </strong>
            <ul>
              {objects.map((o, index) => (
                <li key={index}>{o}</li>
              ))}
            </ul>
          </div>
        ))}
        <ButtonRow
          right={
            <>
              <button className="btn flat" onClick={onClose}>
                Never mind
              </button>
              <button
                className={cn("btn", "destructive", {
                  loading: performingDelete,
                })}
                onClick={async () => {
                  try {
                    setPerformingDelete(true);
                    await onDelete();
                  } catch (e) {
                    console.error(e);
                    setPerformingDelete(false);
                  }
                  if (!isMounted()) return;
                  setPerformingDelete(false);
                  onClose();
                }}
              >
                Delete {formatOxfordComma(objectName, ...relatedQuantities)}
              </button>
            </>
          }
        />
      </Card>
    </Modal>
  );
}

export interface DeleteButtonWithConfirmProps
  extends Partial<
    Pick<DeleteConfirmationModalProps, "relatedItems" | "objectName">
  > {
  onDelete: () => any;
  icon?: ReactElement;
  className?: string;
}

export function DeleteButtonWithConfirm({
  onDelete,
  className,
  icon = <FaTrashAlt />,
  relatedItems,
  objectName,
}: DeleteButtonWithConfirmProps) {
  const [isOpen, setOpen] = useState(false);
  const [deletingWithoutModal, setDeletingWithoutModal] = useState(false);
  const isMounted = useCheckMounted();

  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  const onClick = async () => {
    if (
      relatedItems &&
      objectName &&
      Object.values(relatedItems).filter((x) => (isArray(x) ? x.length : x))
        .length > 0
    ) {
      openModal();
    } else {
      try {
        setDeletingWithoutModal(true);
        await onDelete();
      } catch (e) {
        setDeletingWithoutModal(false);
        console.error(e);
      }
      if (!isMounted()) return;
      setDeletingWithoutModal(false);
    }
  };

  return (
    <>
      {relatedItems && objectName && (
        <DeleteConfirmationModal
          relatedItems={relatedItems}
          objectName={objectName}
          isOpen={isOpen}
          onClose={closeModal}
          onDelete={onDelete}
        />
      )}
      <button
        type="button"
        className={cn(className, "btn", "destructive ", {
          loading: deletingWithoutModal,
        })}
        onClick={onClick}
        title="Delete"
      >
        {icon}
        <span>Delete</span>
      </button>
    </>
  );
}
