import { BooleanInput } from "components/BooleanInput";
import { DateInput } from "components/DateInput";
import { useEditorBuffer } from "components/EditorState/hooks";
import { HiddenInput } from "components/HiddenInput";
import { LearnMore } from "components/LearnMore";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { DateSchema } from "utils/validators/yup";
import { boolean, object, ref } from "yup";

export const TimelineSchema = object({
  filingDate: DateSchema.dateNotFuture(
    "Filing date can’t be in the future."
  ).required("Please enter the date you filed this case."),
  closureDate: DateSchema.dateNotFuture("Closure date can’t be in the future.")
    .dateMin(ref("filing_date"), "Closure date can’t be before filing date.")
    .nullable(),
  inRepayment: boolean().required("This field is required."),
}).required();

export function Timeline({ form, isEdit, navProps }: WizardRouteChildProps) {
  const [{ buffer }] = useEditorBuffer();
  return (
    <>
      <p>When did this bankruptcy filing take place?</p>
      <div className="form-row">
        <DateInput
          name="filingDate"
          label="Filing date"
          width="175px"
          autoFocus={!isEdit}
          onChange={() => {
            if (form.formState.isSubmitted) {
              form.trigger("closureDate");
            }
          }}
          learnMore={
            <LearnMore>
              <h4>What date should I use as the filing date?</h4>
              <p>
                The filing date is the official day your bankruptcy case began,
                and is the day you or your lawyer filed all the paperwork
                required to open your case in the bankruptcy court.
              </p>
            </LearnMore>
          }
        />
        <DateInput
          name="closureDate"
          label="Closure date"
          width="175px"
          onChange={() => {
            if (form.formState.isSubmitted) {
              form.trigger("filingDate");
            }
          }}
          learnMore={
            <LearnMore>
              <h4>What date should I use as the closure date?</h4>
              <p>
                The closure date is the official day that your bankruptcy case
                was closed by the court. On that day, a final decree was issued
                that ended your case.
              </p>
              <h4>What if my bankruptcy case is still open?</h4>
              <p>Leave the closure date field blank.</p>
            </LearnMore>
          }
        />
      </div>

      {buffer.chapter === 13 ? (
        <BooleanInput
          name="inRepayment"
          label="Are you still completing your chapter 13 repayment plan?"
        />
      ) : (
        <HiddenInput name="inRepayment" value={false} />
      )}

      <WizardNavigation {...navProps} />
    </>
  );
}
