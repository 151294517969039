import { Title } from "components/Title";
import React from "react";
import { SpouseForm } from "./SpouseForm";

export interface SpouseProps {
  prev?: string;
  next?: string;
}

export function Spouse({ prev, next }: SpouseProps) {
  return (
    <>
      <Title>Your partner/spouse</Title>
      <SpouseForm prev={prev} next={next} />
    </>
  );
}
