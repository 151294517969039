import {
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useQuery,
} from "@apollo/client";
import React from "react";
import GET_FILING_PERSON from "./GetFilingPerson.gql";
import UPDATE_FILING_PERSON from "./UpdateFilingPerson.gql";
import UPSERT_IDENTITY_DOCUMENT from "./UpsertIdentityDocument.gql";
import UPSERT_SSN_DOCUMENT from "./UpsertSsnDocument.gql";
import UPSERT_MISC_DOCUMENT from "./UpsertMiscDocument.gql";
import DELETE_MISC_DOCUMENT from "./DeleteMiscDocument.gql";

export function useGetFilingPerson(options?: QueryHookOptions) {
  return useQuery<GetFilingPerson>(GET_FILING_PERSON, options);
}

export function useUpdateFilingPerson(
  options?: MutationHookOptions<UpdateFilingPerson, UpdateFilingPersonVariables>
) {
  return useMutation<UpdateFilingPerson, UpdateFilingPersonVariables>(
    UPDATE_FILING_PERSON,
    options
  );
}

export function useUpsertIdentityDocument() {
  const [upsertIdentityDocument] = useMutation<
    UpsertIdentityDocument,
    UpsertIdentityDocumentVariables
  >(UPSERT_IDENTITY_DOCUMENT, {
    update(cache, { data }) {
      const { id, idDoc } = data.upsertIdentityDocument;

      cache.modify({
        id: cache.identify({ __typename: "FilingPerson", id }),
        fields: {
          idDoc: () => idDoc,
        },
      });
    },
  });

  const callback = React.useCallback(
    (variables: UpsertIdentityDocumentVariables) => {
      return upsertIdentityDocument({ variables });
    },
    [upsertIdentityDocument]
  );

  return React.useMemo(() => [callback], [callback]);
}

export function useUpsertSsnDocument() {
  const [upsertSsnDocument] = useMutation<
    UpsertSsnDocument,
    UpsertSsnDocumentVariables
  >(UPSERT_SSN_DOCUMENT, {
    update(cache, { data }) {
      const { id, ssnDoc } = data.upsertSsnDocument;

      cache.modify({
        id: cache.identify({ __typename: "FilingPerson", id }),
        fields: {
          ssnDoc: () => ssnDoc,
        },
      });
    },
  });

  const callback = React.useCallback(
    (variables: UpsertSsnDocumentVariables) => {
      return upsertSsnDocument({ variables });
    },
    [upsertSsnDocument]
  );

  return React.useMemo(() => [callback], [callback]);
}

export function useUpsertMiscDocument() {
  const [upsertMiscDocument] = useMutation<
    UpsertMiscDocument,
    UpsertMiscDocumentVariables
  >(UPSERT_MISC_DOCUMENT);

  const callback = React.useCallback(
    (variables: UpsertMiscDocumentVariables) => {
      return upsertMiscDocument({ variables });
    },
    [upsertMiscDocument]
  );

  return React.useMemo(() => [callback], [callback]);
}

export function useDeleteMiscDocument() {
  const [deleteMiscDocument] = useMutation<
    DeleteMiscDocument,
    DeleteMiscDocumentVariables
  >(DELETE_MISC_DOCUMENT);

  const callback = React.useCallback(
    (variables: DeleteMiscDocumentVariables) => {
      return deleteMiscDocument({ variables });
    },
    [deleteMiscDocument]
  );

  return React.useMemo(() => [callback], [callback]);
}
