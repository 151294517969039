import { useFilingProfileBooleanMutation } from "api/graphql/FilingProfile";
import { useGetSession } from "api/graphql/Session";
import { BigIcon } from "components/Big";
import { Walkthrough, WalkthroughStep } from "components/Walkthrough";
import React from "react";
import {
  FaClipboardList,
  FaFileSignature,
  FaLifeRing,
  FaRunning,
} from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { breakpoints, useBreakpoint } from "utils/hooks";

function useCompleteWalkthroughOnNav(setCompleted: () => Promise<any>) {
  const history = useHistory();
  const currentLocation = useLocation();
  const requestPending = React.useRef(false);
  React.useEffect(() => {
    return history.listen((location) => {
      if (
        currentLocation.pathname !== location.pathname &&
        !requestPending.current
      ) {
        requestPending.current = true;
        setCompleted().then(() => (requestPending.current = false));
      }
    });
  }, []);
}

export default function DashboardWalkthrough({
  shouldRun,
}: {
  shouldRun: boolean;
}) {
  const { data: sessionData } = useGetSession();

  const [markWalkthroughCompleted] = useFilingProfileBooleanMutation(
    "hasCompletedDashboardWalkthrough"
  );

  const setCompleted = React.useCallback(async () => {
    await markWalkthroughCompleted(true);
  }, [markWalkthroughCompleted]);

  useCompleteWalkthroughOnNav(setCompleted);
  const isMobile = useBreakpoint(breakpoints.mobile);

  const firstName = sessionData?.session?.user?.firstName;

  return (
    <Walkthrough setCompleted={setCompleted} shouldRun={shouldRun}>
      <WalkthroughStep target="#dashboard-title" placement="bottom">
        <BigIcon>
          <FaClipboardList />
        </BigIcon>
        <p>
          Welcome to your detailed financial assessment
          {firstName ? `, ${firstName}` : ""}!
        </p>
        <p>
          This information will help your lawyer recommend the best debt relief
          option for you.
        </p>
      </WalkthroughStep>
      <WalkthroughStep
        target="#dashboard-modules-list"
        placement="top"
        disableOverlay={false}
      >
        <div>
          <BigIcon>
            <FaFileSignature />
          </BigIcon>
          <p>This is all of the information we need from you.</p>
          <p>
            The assessment is designed to be completed in small steps, so you
            can provide the information you have ready now and finish later once
            you’ve gathered anything else you may need.
          </p>
        </div>
      </WalkthroughStep>

      <WalkthroughStep
        target="#dashboard-module-link-about-you"
        placement="bottom"
      >
        <BigIcon>
          <FaRunning />
        </BigIcon>
        Great! Let’s get started. {isMobile ? "Tap" : "Click"} “About You” to
        tell us some introductory information.
      </WalkthroughStep>
    </Walkthrough>
  );
}
