import { client } from "api/graphql/client";
import UPDATE_OTHER_PERSON from "api/graphql/OtherPerson/UpdateOtherPerson.gql";
import { IDInput } from "components/IDInput";
import { Title } from "components/Title";
import { WizardStepProps } from "components/Wizard";
import { omit } from "lodash";
import * as React from "react";
import { formatIndefinite } from "utils/formatters";
import { unnestIds } from "utils/ids";
import stripTypename from "utils/tests/apollo/stripTypename";
import { bool, object, string } from "yup";

export class SSN implements WizardStepProps<any, any> {
  otherPerson: Partial<FullOtherPerson>;
  relatedDisplayName: string;

  constructor(
    otherPerson: Partial<FullOtherPerson>,
    context: { relatedDisplayName: string }
  ) {
    this.otherPerson = otherPerson;
    this.relatedDisplayName = context.relatedDisplayName;
  }

  path = "/ssn";
  title = "SSN Information";
  schema = object({
    ssnItin: string().required("This information is required"),
  });

  component = () => {
    return (
      <>
        <Title>Add SSN Information</Title>
        <p>
          In order to add {this.otherPerson.firstName || "this person"} as
          {formatIndefinite(this.relatedDisplayName)}, we need to know their
          Social Security number or IRS tax identification number.
        </p>
        <br />
        <IDInput name="ssnItin" required={true} />
      </>
    );
  };
  onSave = async (formState: any) => {
    const update = await client.mutate<
      UpdateOtherPerson,
      UpdateOtherPersonVariables
    >({
      mutation: UPDATE_OTHER_PERSON,
      variables: {
        id: this.otherPerson.id,
        input: {
          ...unnestIds(
            stripTypename(omit(this.otherPerson, "hasSsnItin", "isItin"))
          ),
          ...unnestIds(formState),
        },
      },
    });

    if (!update.data) {
      return formState;
    }

    return unnestIds(update.data.otherPerson);
  };
  navigateAfterSave = true;
}

export const existingObjectRequiredDataSchema = object({
  hasSsnItin: bool().oneOf([true]),
});

export default {
  step: SSN,
  existingObjectRequiredDataSchema,
};
