import { useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { BirthdayInput } from "components/BirthdayInput";
import { Form } from "components/Form";
import { useGatherMissingOtherPersonData } from "components/GatherMissingDataWizard/hooks";
import GetOrCreate from "components/GetOrCreate";
import { HiddenInput } from "components/HiddenInput";
import { IDInput } from "components/IDInput";
import { InputRow } from "components/InputRow";
import { Loading } from "components/Loading";
import { TextInput } from "components/TextInput";
import { Title } from "components/Title";
import { WizardNavigation } from "components/WizardNavigation";
import { omit } from "lodash";
import * as React from "react";
import { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa";
import {
  Link,
  Redirect,
  Route,
  RouteChildrenProps,
  Switch,
  useHistory,
} from "react-router-dom";
import { formatGraphAddress, formatName } from "utils/formatters";
import { useNested } from "utils/hooks";
import { ErrorTranslator } from "utils/hooks/useBackendErrorTranslation";
import { unnestIds } from "utils/ids";
import { DateSchema } from "utils/validators/yup";
import { object, string } from "yup";
import { AddressForm, AddressSchema } from "../Address";
import ALL_OTHER_PERSON_OPTIONS from "./AllOtherPersonOptions.gql";
import OTHER_PERSON_FORM_INITIAL_VALUE from "./OtherPersonFormInitialValue.gql";
import OTHER_PERSON_MAILING_ADDRESS_OPTIONS from "./OtherPersonMailingAddressOptions.gql";

const CREATE_PERSON = "/create_person";
const EDIT_PERSON = "/edit_person";
const CREATE_ADDRESS = "/create_address";

export type Candidate = {
  id: UUID;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
};

type InitialValue = OtherPersonFormInitialValue["otherPerson"] & {
  mailingAddressId?: UUID;
};

export type OtherPersonFormProps = {
  /** The ID of the initially selected OtherPerson */
  id?: string;
  /** The URL to navigate to when hitting save */
  nextURL?: string;
  /** The URL to navigate to when hitting back */
  backURL?: string;
  onAbort?: () => void;
  onSubmit: (data: OtherPersonFormState) => Promise<any>;
  saveText?: string;
  /** Manually indicate saving state in the form navigation */
  saving?: boolean;
  /** Optional title to be displayed above the form and in the document title */
  title?: string;
  /** The initial value of the OtherPerson. If this isn't provided but id is, the id will be used
   * to fetch the initial value.
   */
  initialValue?: InitialValue;
  /** The OtherPersons which can be selected. If not provided, candidates will be all OtherPersons */
  candidates?: Candidate[];
  candidateFilter?: (candidate: Candidate) => boolean;
  withSSN?: boolean;
  testHasRequiredData?: (otherPerson?: FullOtherPerson) => Promise<boolean>;
  getMissingDataUpdateURL?: ({ id }: { id: UUID }) => string;
  /** When true, the form will display the GetOrCreate with a link to edit when an id is provided.
   *  When false or omitted, the edit form will be immediately displayed, and selecting a new
   *  person isn't allowed.
   */
  allowReselection?: boolean;
  /** When true, the edit view back button will return to the select page.
   * The create view will also return to the select page if there are no candidates.
   */
  returnToSelect?: boolean;
  /** The text to display above the GetOrCreate */
  label?: ReactNode;
};

export const OtherPersonSchema = object({
  firstName: string().required("Please enter this person’s first name."),
  middleName: string().nullable(),
  lastName: string().required("Please enter this person’s last name."),
  suffix: string().nullable(),
  birthday: DateSchema.required("Please enter this person’s birth date."),
  mailingAddressId: string().when("mailingAddress", {
    is: (v) => !v,
    then: string().required(
      "You must select an existing address or provide a new address."
    ),
    otherwise: string().optional().nullable(),
  }),
  mailingAddress: AddressSchema.nullable(),
}).required();

const OtherPersonWithRequiredSSNSchema = OtherPersonSchema.concat(
  object({
    ssnItin: string().required("Please enter this person’s SSN"),
  }).required()
);

export const ExistingPersonSchema = object({
  id: string().required("Please select or add a person"),
});

export type OtherPersonFormState = ReturnType<
  | typeof OtherPersonSchema.validateSync
  | typeof ExistingPersonSchema.validateSync
>;

function ExistingPersonForm({
  id,
  backURL,
  onSubmit,
  onAbort,
  saveText,
  title,
  candidates,
  initialValue,
  testHasRequiredData,
  getMissingDataUpdateURL,
  saving,
  label = "Select a person from your application",
}: OtherPersonFormProps) {
  const { url } = useNested();
  const history = useHistory();

  const form = useForm<OtherPersonFormState>({
    resolver: yupResolver(ExistingPersonSchema),
    defaultValues: initialValue,
  });

  const [
    wizardNavigationProps,
    moreDataNeededMessaging,
  ] = useGatherMissingOtherPersonData({
    form,
    testHasRequiredData,
    getMissingDataUpdateURL,
  });

  if (!candidates.length) {
    return <Redirect to={url(CREATE_PERSON)} />;
  }

  return (
    <Form<OtherPersonFormState> onSubmit={onSubmit} useForm={form}>
      {title && <Title>{title}</Title>}
      <p>{label}</p>
      <GetOrCreate
        name="id"
        objectName="person"
        createURL={url(CREATE_PERSON)}
        initialValue={id}
        onEdit={
          id
            ? (idToEdit) => history.push(`${url(EDIT_PERSON)}/${idToEdit}`)
            : undefined
        }
        options={candidates.map((p) => ({
          value: p.id,
          label: formatName(p),
        }))}
      />
      {moreDataNeededMessaging}
      <WizardNavigation
        backURL={backURL}
        onAbort={onAbort}
        saveText={saveText}
        {...wizardNavigationProps}
        saving={wizardNavigationProps.saving || saving}
      />
    </Form>
  );
}

function EditCreatePersonForm({
  id,
  backURL,
  onSubmit,
  onAbort,
  saveText,
  title,
  initialValue: passedInitialValue,
  candidates,
  saving,
  withSSN = false,
}: OtherPersonFormProps) {
  const { url, path } = useNested();

  const initialValueQuery = useQuery<
    OtherPersonFormInitialValue,
    OtherPersonFormInitialValueVariables
  >(OTHER_PERSON_FORM_INITIAL_VALUE, {
    variables: { otherPersonID: id },
    skip: !id || !!(passedInitialValue && passedInitialValue.id === id),
  });

  const mailingOptions = useQuery<OtherPersonMailingAddressOptions>(
    OTHER_PERSON_MAILING_ADDRESS_OPTIONS,
    { fetchPolicy: "cache-and-network" }
  );

  const submit = React.useCallback(
    async (
      updatedData: OtherPersonFormState,
      event: React.FormEvent,
      translateErrors: ErrorTranslator
    ) => {
      try {
        await onSubmit(updatedData);
      } catch (e) {
        translateErrors(e);
      }
    },
    [onSubmit]
  );

  if (initialValueQuery.loading || mailingOptions.loading)
    return <Loading size="L" />;

  const initialValue = initialValueQuery?.data?.otherPerson
    ? (omit(
        unnestIds(initialValueQuery.data.otherPerson),
        "mailingAddress"
      ) as InitialValue)
    : passedInitialValue;

  const schema = withSSN
    ? !initialValue?.hasSsnItin
      ? OtherPersonWithRequiredSSNSchema
      : OtherPersonSchema
    : OtherPersonSchema;

  return (
    <Form<OtherPersonFormState>
      initialValue={initialValue}
      schema={schema}
      onSubmit={submit}
    >
      {title && <Title>{title}</Title>}
      {!!initialValue && <HiddenInput name="id" />}
      <InputRow label="Legal name">
        <TextInput
          name="firstName"
          placeholder="First"
          aria-label="First name"
          autoFocus={!id}
          maxLength={255}
          autoComplete="given-name"
        />
        <TextInput
          name="middleName"
          placeholder="Middle"
          aria-label="Middle name"
          maxLength={255}
          autoComplete="additional-name"
        />
        <TextInput
          name="lastName"
          placeholder="Last"
          aria-label="Last name"
          maxLength={255}
          autoComplete="family-name"
        />
        <TextInput
          name="suffix"
          placeholder="Suffix"
          aria-label="Name suffix"
          width="100px"
          maxLength={12}
          autoComplete="honorific-suffix"
        />
      </InputRow>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          columnGap: "1em",
        }}
      >
        <BirthdayInput name="birthday" label="Birth date" />
        {withSSN && (
          <IDInput
            name="ssnItin"
            isSaved={initialValue?.hasSsnItin}
            isITIN={initialValue?.isItin}
          />
        )}
      </div>
      {!!candidates.length && !id && (
        <Link to={url("../")} className="flat primary btn">
          <FaChevronLeft />
          <span>Select existing person</span>
        </Link>
      )}
      <hr />

      <p>What is this person’s mailing address?</p>
      <Switch>
        <Route path={path(CREATE_ADDRESS)}>
          <AddressForm graphQL name="mailingAddress" />
          <Link to={url("/")} className="flat primary btn">
            <FaChevronLeft />
            <span>Select existing mailing address</span>
          </Link>
        </Route>
        <Route>
          <>
            <GetOrCreate
              name="mailingAddressId"
              objectName="mailing address"
              createURL={url(CREATE_ADDRESS)}
              initialValue={initialValue?.mailingAddressId}
              loading={mailingOptions.loading}
              options={mailingOptions.data?.mailingAddresses.map((a) => ({
                value: a.id,
                label: formatGraphAddress(a),
              }))}
            />
            <HiddenInput name="mailingAddress" value={null} />
          </>
        </Route>
      </Switch>
      <WizardNavigation
        isComplete
        backURL={backURL}
        onAbort={onAbort}
        saveText={saveText}
        saving={saving}
      />
    </Form>
  );
}

export function OtherPersonInnerForm(props: OtherPersonFormProps) {
  const { path, url } = useNested();

  const renderExistingPersonForm = React.useCallback(() => {
    return <ExistingPersonForm {...props} />;
  }, [props]);

  const renderEditForm = React.useCallback(
    ({ match }: RouteChildrenProps<{ id: string }>) => {
      return (
        <EditCreatePersonForm
          {...props}
          id={match.params.id}
          backURL={props.returnToSelect ? url("/") : props.backURL}
        />
      );
    },
    [props]
  );

  const renderCreateForm = React.useCallback(() => {
    return (
      <EditCreatePersonForm
        {...omit(props, "id", "initialValue")}
        backURL={
          props.returnToSelect && props.candidates.length
            ? url("/")
            : props.backURL
        }
      />
    );
  }, [props]);

  return (
    <Switch>
      <Route path={`${path(EDIT_PERSON)}/:id`} render={renderEditForm} />
      {!props.allowReselection && props.id && (
        <Redirect to={`${url(EDIT_PERSON)}/${props.id}`} />
      )}
      <Route path={path(CREATE_PERSON)} render={renderCreateForm} />
      <Route render={renderExistingPersonForm} />
    </Switch>
  );
}

export function OtherPersonForm(props: OtherPersonFormProps) {
  const { nextURL } = props;

  const otherPersonsQuery = useQuery(ALL_OTHER_PERSON_OPTIONS, {
    fetchPolicy: "cache-and-network",
    skip: !!props.candidates?.length,
  });

  const history = useHistory();
  const onSubmit = React.useCallback(
    async (data) => {
      await props.onSubmit(data);
      if (nextURL) history.push(nextURL);
    },
    [nextURL, props.onSubmit]
  );

  if (otherPersonsQuery.loading) return <Loading size="L" />;

  const candidates = props.candidates || otherPersonsQuery.data.otherPersons;

  return (
    <OtherPersonInnerForm
      {...props}
      onSubmit={onSubmit}
      candidates={
        props.candidateFilter
          ? candidates.filter(props.candidateFilter)
          : candidates
      }
    />
  );
}
