import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import EditView from "./EditView";
import ListView from "./ListView";

export interface NSLDSViewProps {
  backURL: string;
  reImportURL?: string;
}

export default function NSLDSView({ backURL, reImportURL }: NSLDSViewProps) {
  const { url, path } = useNested();
  const history = useHistory();

  return (
    <Switch>
      <Route exact path={path("/")}>
        <ListView
          backURL={backURL}
          reImportURL={reImportURL}
          onEdit={(id) => {
            history.push(url(`/edit/${id}`));
          }}
        />
      </Route>
      <Route
        path={path("/edit/:loanID")}
        render={({ match }) => (
          <EditView id={match.params.loanID} listURL={url("/")} />
        )}
      />
    </Switch>
  );
}
