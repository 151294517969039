import { LearnMore } from "components/LearnMore";
import { MonthlyExpenseCategoryChoice } from "globalTypes";
import React from "react";
import {
  FaBalanceScale,
  FaFileContract,
  FaHammer,
  FaHome,
} from "react-icons/fa";
import { ExpensesCategoryDetail } from "../CategorySection/CategoryDetail";
import { LearnMoreCommon } from "../LearnMoreCommon";

export const realEstateHoaCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.RealEstateHoaDues,
  displayName: "Real Estate Owners Association",
  displayLowercaseName: "real estate owners association",
  hasNoProfileBooleanName: "hasNoRealEstateHoaExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on real estate owners association expenses over the last six months? Be sure to include spending for all household members.",
  urlPathName: "real_estate_hoa",
  menuIcon: FaFileContract,
  description: (
    <>
      <p>
        Real estate owners association expenses include fees you pay for
        membership within a homeowner or condominium association.
      </p>

      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};

export const realEstateRentCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.RealEstateRent,
  displayName: "Real Estate Rental",
  displayLowercaseName: "real estate rental",
  hasNoProfileBooleanName: "hasNoRealEstateRentalExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on real estate rental expenses over the last six months? Be sure to include spending for all household members.",
  urlPathName: "real_estate_rental",
  menuIcon: FaHome,
  description: (
    <>
      <p>
        Real estate rental expenses include rental payments for a home,
        apartment, trailer, land, timeshare, or business lease. You should{" "}
        <em>not</em> include mortgage payments, which are covered in another
        section.
      </p>

      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};

export const realEstateTaxesCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.RealEstateTaxes,
  displayName: "Real Estate Tax",
  displayLowercaseName: "real estate tax",
  hasNoProfileBooleanName: "hasNoRealEstateTaxExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on real estate property taxes over the last six months? Be sure to include spending for all household members.",
  urlPathName: "real_estate_taxes",
  menuIcon: FaBalanceScale,
  description: (
    <>
      <p>
        Real estate tax expenses include payments you make on property taxes for
        homes, land, or other property you own.
      </p>

      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};

export const realEstateUpkeepCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.RealEstateUpkeep,
  displayName: "Real Estate Maintenance",
  displayLowercaseName: "real estate upkeep and maintenance",
  hasNoProfileBooleanName: "hasNoRealEstateUpkeepExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on real estate upkeep and maintenance expenses over the last six months? Be sure to include spending for all household members.",
  urlPathName: "real_estate_maintenance",
  menuIcon: FaHammer,
  description: (
    <div>
      <p>
        Real estate upkeep expenses covers any spending you have incurred
        maintaining your property, such as:
      </p>
      <ul>
        <li>Repairs and maintenance</li>
        <li>Upkeep, such as painting</li>
      </ul>
      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </div>
  ),
};
