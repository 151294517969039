import { useGetRetirementAccountLoan } from "api/graphql/RetirementAccountLoan";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";
import { RetirementAccountLoanWizard } from "./RetirementAccountLoanWizard";

interface Props {
  id: string;
  listURL: string;
}

export default function EditView({ id, listURL }: Props) {
  const { data, loading } = useGetRetirementAccountLoan(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.retirementAccountLoan) {
    return <Redirect to={listURL} />;
  }

  return (
    <RetirementAccountLoanWizard
      title="Updating Retirement Account Loan"
      backURL={listURL}
      nextURL={listURL}
      initialValue={data.retirementAccountLoan}
    />
  );
}
