import { AddressForm, AddressSchema } from "components/FormSections/Address";
import PrivacyInput from "components/PrivacyInput";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { object, string } from "yup";

export const TaxAuthoritySchema = object({
  entityName: string().required(
    "Please enter the name of the entity you owe this debt to."
  ),
  accountNumber: string().required(
    "Please enter the account number for this debt."
  ),
  contactAddress: AddressSchema.required(),
}).required();

export default function TaxAuthority({
  isEdit,
  navProps,
}: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <TextInput
          name="entityName"
          label="Taxing authority name"
          width="200px"
          autoFocus={!isEdit}
        />
        {/* @ts-ignore Switch out text-mask to something up to date */}
        <PrivacyInput
          name="accountNumber"
          label="Debt tracking or account number"
          width="200px"
        />
      </div>

      <p>
        What is the taxing authority&rsquo;s mailing address? This will allow us
        to contact the entity, if necessary.
      </p>
      <AddressForm graphQL name="contactAddress" />

      <WizardNavigation {...navProps} />
    </>
  );
}
