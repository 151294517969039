import { BooleanInput } from "components/BooleanInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { useFormContext } from "react-hook-form";
import { boolean, object } from "yup";

export const DepositsSchema = object({
  hasNoMajorRecentDeposits: boolean().required("This field is required."),
}).required();

export type DepositsFormState = ReturnType<typeof DepositsSchema.validateSync>;

export default function Deposits({ isEdit, navProps }: WizardRouteChildProps) {
  const form = useFormContext<DepositsFormState>();
  const hasNoMajorRecentDeposits = form.watch("hasNoMajorRecentDeposits");

  return (
    <>
      <div className="form-row">
        <BooleanInput
          name="hasNoMajorRecentDeposits"
          label="Have you made any non-regular deposits of over five hundred dollars in the last six months?"
          yesLabel="No"
          noLabel="Yes"
          helpText="Regular income deposits include deposits such as income from your job or social security benefits. If you only made these type of deposits over $500, answer no."
          defaultValue={hasNoMajorRecentDeposits}
        />
      </div>
      <WizardNavigation {...navProps} />
    </>
  );
}
