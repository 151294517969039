import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useUpsert, useDelete } from "api/graphql/utils";
import GET_GIG_INCOME from "./GetGigIncome.gql";
import LIST_GIG_INCOMES from "./ListGigIncomes.gql";
import UPSERT_GIG_INCOME from "./UpsertGigIncome.gql";
import DELETE_GIG_INCOME from "./DeleteGigIncome.gql";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";

const LIST_GIG_INCOME_IDS = gql`
  query ListGigIncomeIds {
    gigIncomes {
      id
    }
  }
`;

export function useListGigIncomes() {
  return useQuery<ListGigIncomes>(LIST_GIG_INCOMES);
}

export function useGetGigIncome(id: string) {
  return useQuery<GetGigIncome, GetGigIncomeVariables>(GET_GIG_INCOME, {
    variables: { id },
  });
}

export function useUpsertGigIncome<
  Schema extends { gigIncome: any } = UpsertGigIncome,
  Variables = UpsertGigIncomeVariables
>(query = UPSERT_GIG_INCOME) {
  return useUpsert<Schema, Variables, ListGigIncomeIds, GigIncomeInput>(
    query,
    LIST_GIG_INCOME_IDS,
    "gigIncome",
    "gigIncomes",
    (cache) => updateFilingProfileCache(cache, "hasNoGigIncome", false)
  );
}

export function useDeleteGigIncome() {
  return useDelete<DeleteGigIncome, ListGigIncomeIds>(
    DELETE_GIG_INCOME,
    LIST_GIG_INCOME_IDS,
    "deleteGigIncome",
    "gigIncomes"
  );
}
