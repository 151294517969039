import * as React from "react";
import { useCheckMounted } from "utils/hooks";

/**
 * Almost-cancellable JavaScript promises.
 *
 * This hook takes a promise (some task to complete),
 * and a side effect callback, and will only call the callback
 * on the last registered task.
 *
 * Useful for ignoring the results of stale tasks/requests,
 * and avoiding janky or out-of-order ui updates.
 */
export function useLatestResult() {
  const pending = React.useRef(null);
  const callback = React.useRef(null);
  const checkMounted = useCheckMounted();

  const waitForResults = async (task: Promise<any>) => {
    const shouldBail = () => !checkMounted() || pending.current !== task;

    try {
      const result = await task;

      if (shouldBail()) return;

      callback.current(result);
    } catch (e) {
      console.error(e);
      if (!shouldBail()) throw e;
    }

    pending.current = null;
    callback.current = null;
  };

  const enqueue = React.useCallback(
    (task: Promise<any>, callbackFn: (result: any) => any) => {
      pending.current = task;
      callback.current = callbackFn;
      waitForResults(task);
    },
    []
  );

  return React.useMemo(() => [enqueue], []);
}
