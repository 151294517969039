import { useQuery } from "@apollo/client";
import { useUpsertOtherPerson } from "api/graphql/OtherPerson";
import { useEditorBuffer } from "components/EditorState/hooks";
import { useSubmitForm } from "components/Form";
import {
  OtherPersonDataValidationType,
  OtherPersonWithMissingDataValidationsForm,
} from "components/FormSections/OtherPersonWithMissingDataValidation";
import { HiddenInput } from "components/HiddenInput";
import { Loading } from "components/Loading";
import { WizardRouteChildProps } from "components/Wizard";
import React from "react";
import { useHistory } from "react-router";
import { useNested } from "utils/hooks";
import { object, string } from "yup";
import LIST_OWNER_CANDIDATES from "./ListOwnerCandidates.gql";

export const OwnerSchema = object({
  ownerId: string().required(),
}).required();

export default function Owner({ form, navProps }: WizardRouteChildProps) {
  const [{ buffer }] = useEditorBuffer();
  const { data } = useQuery<ListOwnerCandidates>(LIST_OWNER_CANDIDATES, {
    variables: { id: buffer.id },
    fetchPolicy: "cache-and-network",
  });
  const { url } = useNested();
  const [upsert] = useUpsertOtherPerson();
  const submitForm = useSubmitForm();
  const ownerId = form.watch("ownerId");
  const history = useHistory();

  const onSubmit = async (input: any) => {
    const isSelect = input.id && !input.firstName;
    const isEdit = input.id && input.firstName;

    if (isSelect) {
      form.setValue("ownerId", input.id);
    } else {
      // edit or create
      const { data } = await upsert(input);
      form.setValue("ownerId", data.otherPerson.id);
    }

    // If edited, let the user see it's still selected before continuing
    if (!isEdit) {
      setTimeout(submitForm, 0);
    }
  };

  if (!data) return <Loading />;

  return (
    <>
      <HiddenInput name="ownerId" />
      <p className="required">Who are you holding this property for?</p>
      <OtherPersonWithMissingDataValidationsForm
        label=""
        validations={[OtherPersonDataValidationType.MailingAddress]}
        candidates={data.borrowedAssetOwnerCandidates}
        id={ownerId}
        relatedId={"arcoehur"}
        relatedTypename={"AssetSale"}
        relatedDisplayName="owner"
        backURL={navProps.abortURL}
        nextURL={url("/")}
        onAbort={() => history.push(navProps.abortURL)}
        onSubmit={onSubmit}
        saving={navProps.saving}
        saveText="Save"
        onFinalizeMissingDataCollection={({ otherPersonId }) => {
          form.setValue("ownerId", otherPersonId);
        }}
        allowReselection
        returnToSelect
      />
    </>
  );
}
