import { Card } from "components/Card";
import * as React from "react";
import { Helmet } from "react-helmet";
import css from "../styles.module.css";

export function NewBankruptcyIdle() {
  return (
    <>
      <Helmet>
        <title>Get back on the path to debt relief through bankruptcy</title>
      </Helmet>

      <div className={css.container}>
        <Card className={css.infoCard}>
          <h3>Welcome back</h3>
          <p>It has been some time since we&rsquo;ve heard from you!</p>
        </Card>
      </div>
    </>
  );
}
