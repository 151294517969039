import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaQuestionCircle } from "react-icons/fa";
import { OtherDebts } from "./OtherDebts";
import OtherDebtsSummary from "./Summary";

const OtherDebtsSubmodule: SubModule = {
  path: "other_debts",
  title: "Other Debts",
  icon: FaQuestionCircle,
  component: OtherDebts,
  summary: OtherDebtsSummary,
  statusName: "otherDebts",
};

export default OtherDebtsSubmodule;
