import cn from "classnames";
import { YupSchemaContext } from "components/Form";
import { find, get } from "lodash";
import * as React from "react";
import {
  UseFormMethods,
  useFormContext,
  RegisterOptions,
} from "react-hook-form";
import { v4 } from "uuid";

interface UseFormFieldOptions {
  name?: string;
  label?: string;
  rules?: RegisterOptions;
  register?: boolean;
}

/** Hook for connecting inputs to a react hook form, while also allowing use outside of one. */
export function useFormField({
  name = "",
  label,
  rules,
  register: requestsRegistration = true,
}: UseFormFieldOptions) {
  const schema = React.useContext(YupSchemaContext);
  const form: UseFormMethods | undefined = useFormContext();
  const {
    errors = {},
    getValues,
    register,
    setValue = (): null => null,
  } = form || {};
  const [initialValue] = React.useState((getValues?.() || {})[name]);

  const schemaRequired = React.useMemo(() => {
    const fieldSchema =
      schema && get(schema.describe().fields, name.split(".").join(".fields."));
    return (
      fieldSchema &&
      find((fieldSchema as any)?.tests, (t) => t.name === "required")
    );
  }, [schema, name]);

  const required: boolean = !!rules?.required || !!schemaRequired;

  const { message: error } = get(errors, name, {});

  const id = React.useMemo(
    () =>
      (label || name) &&
      `input-${name ? name : `${label.replace(/\s/g, "-")}`}`,
    [label, name]
  );

  const Label = React.useCallback(
    (props: React.LabelHTMLAttributes<HTMLLabelElement>) =>
      label ? (
        <label
          {...props}
          className={cn(props.className, "label", { required })}
          htmlFor={props.htmlFor || id}
        >
          {label}
        </label>
      ) : null,
    [id, label, required]
  );

  const getRef = React.useCallback(
    () => (register && rules ? register(rules) : register),
    [register, rules]
  );

  const inputProps = React.useMemo(
    () => ({
      id,
      name,
      className: cn({ "has-error": error }),
      ref: requestsRegistration && name ? getRef() : undefined,
    }),
    [error, getRef, id, name, requestsRegistration]
  );

  return {
    error,
    Label,
    initialValue,
    form,
    setValue,
    inputProps,
    getRef,
    required,
  };
}
