import { Title } from "components/Title";
import { WizardStepProps } from "components/Wizard";
import { capitalize } from "lodash";
import * as React from "react";
import { formatIndefinite } from "utils/formatters";
import { object } from "yup";

export class Finalize implements WizardStepProps<any, any> {
  otherPerson: Partial<FullOtherPerson>;
  relatedId: UUID;
  relatedTypename: string;
  title: string;
  relatedDisplayName: string;

  constructor(
    otherPerson: { id: UUID; firstName?: string },
    context: {
      relatedId: UUID;
      relatedTypename: string;
      relatedDisplayName: string;
    }
  ) {
    this.otherPerson = otherPerson;
    this.relatedId = context.relatedId;
    this.relatedTypename = context.relatedTypename;
    this.relatedDisplayName = context.relatedDisplayName;

    Object.defineProperty(this, "title", {
      enumerable: true,
      get() {
        const name = this.otherPerson.firstName;
        return `Add ${name ? name + " as" : ""} ${formatIndefinite(
          capitalize(this.relatedDisplayName)
        )}`;
      },
    });
  }

  path = "done";
  schema = object();

  missingTest = async () => false;

  component = () => (
    <>
      <Title>
        Add as {formatIndefinite(capitalize(this.relatedDisplayName))}
      </Title>
      <p>
        You’re ready to add {this.otherPerson.firstName || "this person"} as{" "}
        {formatIndefinite(this.relatedDisplayName)}. Click save to add them.
      </p>
    </>
  );
}

export default {
  step: Finalize,
  existingObjectRequiredDataSchema: undefined,
};
