import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaGraduationCap } from "react-icons/fa";
import { Education } from "./Education";
import EducationSummary from "./Summary";

const EducationSubmodule: SubModule = {
  path: "education",
  title: "Education",
  icon: FaGraduationCap,
  component: Education,
  summary: EducationSummary,
  statusName: "education",
};

export default EducationSubmodule;
