import cn from "classnames";
import { Card } from "components/Card";
import * as React from "react";
import { TooltipRenderProps } from "react-joyride";
import css from "./styles.module.css";

export function Tooltip(props: TooltipRenderProps) {
  const {
    tooltipProps,
    step,
    skipProps,
    backProps,
    primaryProps,
    index,
    isLastStep,
  } = props;
  const { content, hideFooter, hideBackButton } = step;
  return (
    <div {...tooltipProps} className={css.Tooltip}>
      <Card>
        {content}
        {!hideFooter && (
          <div className={css.Tooltip__footer}>
            <button
              className={cn("btn flat", css.Tooltip__skip)}
              {...skipProps}
            >
              Skip
            </button>
            <div>
              {index > 0 && !hideBackButton && (
                <button {...backProps} className={cn("btn", css.Tooltip__back)}>
                  Back
                </button>
              )}
              <button className="btn primary" {...primaryProps}>
                {isLastStep ? "Done" : "Next"}
              </button>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}
