import { DateInput } from "components/DateInput";
import { LearnMore } from "components/LearnMore";
import { DecimalInput } from "components/NumberInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { DateSchema, DecimalSchema } from "utils/validators/yup";
import { InferType, object, ref } from "yup";

export const DetailsSchema = object({
  outstandingBalance: DecimalSchema.required(
    "Please enter your outstanding balance on this mortgage."
  ),
  delinquentBalance: DecimalSchema.nullable(),
  monthlyPayment: DecimalSchema.required(
    "Please enter your monthly payment for this mortgage."
  ),
  originationDate: DateSchema.dateNotFuture(
    "Origination date can’t be in the future."
  ).required("Origination date is required."),
  lastPaymentDate: DateSchema.dateNotFuture(
    "Last payment date can’t be in the future."
  )
    .dateMin(
      ref("originationDate"),
      "Last payment date can’t be before origination date."
    )
    .required("Last payment date is required."),
}).required();

export type DetailsFormState = InferType<typeof DetailsSchema>;

export default function Details({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <DateInput
          name="originationDate"
          label="Origination date"
          autoFocus={!isEdit}
          learnMore={
            <LearnMore title="Origination date">
              <p>
                The origination date is the date on which this mortgage was
                funded. Usually this is the date you signed the deed.
              </p>
            </LearnMore>
          }
        />
        <DateInput name="lastPaymentDate" label="Last payment date" />
      </div>

      <div className="form-row">
        <DecimalInput
          name="outstandingBalance"
          label="Outstanding balance"
          addOnBefore="$"
          width="150px"
          includeThousandsSeparator
          allowDecimal
        />
        <DecimalInput
          name="monthlyPayment"
          label="Monthly payment"
          addOnBefore="$"
          width="150px"
          includeThousandsSeparator
          allowDecimal
        />
        <DecimalInput
          name="delinquentBalance"
          label="Delinquent balance"
          addOnBefore="$"
          width="150px"
          includeThousandsSeparator
          allowDecimal
        />
      </div>

      <WizardNavigation {...navProps} />
    </>
  );
}
