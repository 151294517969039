import { useQuery } from "@apollo/client";
import { ButtonRow } from "components/ButtonRow";
import ImportEntry from "components/ImportView/Entry";
import { Loading } from "components/Loading";
import QuestionView from "components/QuestionView";
import { Title } from "components/Title";
import { find, sortBy } from "lodash";
import React from "react";
import { FaCheck, FaDownload, FaPlus } from "react-icons/fa";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { useNested } from "utils/hooks";
import { Description } from "./Description";
import FINANCIAL_ACCOUNTS_MODULE from "./FinancialAccountModule.gql";
import { FinancialInstitutionListItem } from "./ListItems";
import { useGetSession } from "api/graphql/Session";

interface Props {
  createURL: string;
  nextURL: string;
  prevURL: string;
  onEdit: (id: string) => any;
}

export default function ListView(props: Props) {
  const { createURL, onEdit } = props;
  const { data } = useQuery<FinancialAccountModule>(FINANCIAL_ACCOUNTS_MODULE);
  const { data: sessionData, loading: sessionLoading } = useGetSession();

  const { path, url } = useNested();

  if (!data || sessionLoading) return <Loading />;
  const { financialAccounts: items } = data;
  const { enableImportFinancialAccounts } =
    sessionData?.session.user.referralFirm.featureFlags;

  const institutions: {
    name: string;
    accounts: FinancialAccountModule["financialAccounts"];
  }[] = [];
  items.forEach((account) => {
    const existing = find(
      institutions,
      (i) => i.name === account.institutionName
    );
    if (existing) {
      existing.accounts.push(account);
    } else {
      institutions.push({ name: account.institutionName, accounts: [account] });
    }
  });

  return (
    <>
      <Title>Your Financial Accounts</Title>
      <Switch>
        <Route
          path={path("/query")}
          render={() => (
            <>
              <QuestionView
                nextURL={props.nextURL}
                createURL={url("decision")}
                filingProfileBoolean="hasNoFinancialAccounts"
                addText="Add a financial account"
                question={
                  <Description
                    introText={
                      "Do you or any members of your household have any financial accounts or have you closed any accounts in the last year?"
                    }
                  />
                }
                confirmation={
                  <Description
                    introText={
                      "You have indicated that you and all members of your household have no financial accounts, nor have you had any in the last year."
                    }
                    footerText={
                      "If that\u2019s correct, you\u2019re ready to continue to the next section. Otherwise, you may add accounts below."
                    }
                  />
                }
              />
              <div className="mt-8 font-bold">
                By selecting 'No', you are attesting that you do not have ANY
                financial accounts such as savings, checking, brokerage,
                retirement, or the like.
              </div>
            </>
          )}
        />
        <Route
          path={path("/decision")}
          render={() => (
            <ImportEntry
              manualCreateURL={createURL}
              nextURL={url("/")}
              items={items}
            />
          )}
        />

        {!items.length && <Redirect to={url("/query")} />}

        <Route
          path={path("/")}
          render={() => (
            <>
              <Description
                introText="Tell us about all the financial accounts owned by you or other members of your household."
                footerText="Include all accounts you closed in the last year."
              />

              {enableImportFinancialAccounts && (
                <Link
                  to={`/new-filing/financial-import?from=${window.location.pathname}&startImport=true`}
                  className="btn"
                  style={{ alignSelf: "flex-start", marginTop: "8px" }}
                >
                  <FaDownload />
                  <span>Import accounts</span>
                </Link>
              )}

              <ButtonRow
                style={{ marginBottom: "8px" }}
                left={
                  <Link to={props.createURL} className="btn secondary">
                    <FaPlus />
                    <span>Add an institution</span>
                  </Link>
                }
                right={
                  <Link to={props.nextURL} className="btn primary">
                    <span>Done</span> <FaCheck />
                  </Link>
                }
              />

              {sortBy(institutions, "name").map((props) => (
                <FinancialInstitutionListItem
                  key={props.name}
                  {...props}
                  showFeedback
                  onEdit={onEdit}
                  logo={
                    find(props.accounts, (a) => !!a.institutionLogoUrl)
                      ?.institutionLogoUrl
                  }
                  headerButton={
                    <Link
                      className="btn"
                      to={`${createURL}?inherits=${props.accounts[0].id}`}
                    >
                      <FaPlus />
                      <span>Add account</span>
                    </Link>
                  }
                />
              ))}
            </>
          )}
        />
      </Switch>
    </>
  );
}
