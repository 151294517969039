import { useQuery } from "@apollo/client";
import { useUpdateFamilyProfile } from "api/graphql/FamilyProfile";
import { ButtonRow } from "components/ButtonRow";
import { Loading } from "components/Loading";
import React from "react";
import { FaArrowRight, FaPlus } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import { unnestIds } from "utils/ids";
import OTHER_FAMILY_MODULE from "./OtherFamilyModule.gql";

export function QuestionView({ nextURL }: { nextURL?: string }) {
  const { data, loading } = useQuery<OtherFamilyModule>(OTHER_FAMILY_MODULE);
  const update = useUpdateFamilyProfile();
  const markNoDependents = React.useCallback(async () => {
    await update(
      unnestIds({
        ...data.familyProfile,
        hasNoFamilyMembers: true,
      })
    );
  }, [update, data]);

  if (loading) return <Loading />;

  if (data.familyProfile.familyMembers.length) {
    return <Redirect to="./" />;
  }

  if (data.familyProfile.hasNoFamilyMembers) {
    return (
      <>
        <p>
          You told us that you do not have any dependents or family members who
          live with you. If this is still the case, you’re finished with this
          section and can continue.
        </p>
        <ButtonRow
          left={
            <Link to="create" className="btn secondary">
              <FaPlus />
              <span>Add a family member</span>
            </Link>
          }
          right={
            <Link to={nextURL} className="btn primary">
              <span>Continue</span>
              <FaArrowRight />
            </Link>
          }
        />
      </>
    );
  } else {
    return (
      <>
        <p className="required">
          <strong>
            Do you have any dependents or other family members who live with you
            (besides your spouse)?
          </strong>
        </p>
        <p>
          Include children who live with you – or that you pay or receive child
          support for – adults and adult children who you pay 50% or more of
          their bills.
        </p>
        <ButtonRow
          left={
            <button
              type="button"
              className="block btn"
              onClick={markNoDependents}
            >
              No
            </button>
          }
          right={
            <Link to="create" className="block btn primary">
              Yes
            </Link>
          }
        />
      </>
    );
  }
}
