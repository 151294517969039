import { AddressForm, AddressSchema } from "components/FormSections/Address";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { object, string } from "yup";

export const LenderSchema = object({
  lenderName: string().required("Lender name is required."),
  loanNumber: string().required("Loan number is required."),
  lenderAddress: AddressSchema.nullable(),
}).required();

export default function Lender({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <TextInput
          name="lenderName"
          label="Lender name"
          width="200px"
          autoFocus={!isEdit}
        />
        <TextInput name="loanNumber" label="Loan number" width="200px" />
      </div>

      <p>
        What is this lender&rsquo;s mailing address? This will allow us to
        contact the lender, if necessary.
      </p>
      <AddressForm graphQL name="lenderAddress" />

      <WizardNavigation {...navProps} />
    </>
  );
}
