import * as React from "react";
import { StorageContainerWizard } from "./StorageContainerWizard";

export function CreateView({
  listURL,
  editURL,
}: {
  listURL: string;
  editURL?: string;
}) {
  return (
    <StorageContainerWizard
      title="Add storage"
      backURL={listURL}
      nextURL={listURL}
      editURL={editURL}
    />
  );
}
