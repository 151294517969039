import { useQuery } from "@apollo/client";
import { getBusinessesCashFlow } from "components/FormModules/OwnedBusinesses/BusinessExpensesSummary";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import { flatten } from "lodash";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import OWNED_BUSINESSES_MODULE from "./OwnedBusinessesModule.gql";

export default function OwnedBusinessesSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<OwnedBusinessesModule>(OWNED_BUSINESSES_MODULE, {
    returnPartialData: true,
  });

  const items = data?.ownedBusinesses;
  const salaries = items && flatten(items?.map((b) => b.personalSalaries));

  return (
    <ModuleSummary {...props} progress={data?.progress?.ownedBusinesses}>
      {data?.filingProfile?.hasNoBusinesses ? (
        <HasNoSummary>
          You don&rsquo;t own any businesses for which you are personally
          liable.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="business"
            maxValueLength={1}
            value={items?.length}
            pluralizeLabel
          />
          <NumericSummary
            label="Monthly cash flow"
            maxValueLength={6}
            value={getBusinessesCashFlow(items)}
          />
          <NumericSummary
            label="Monthly household salary"
            valuePrefix="$"
            maxValueLength={6}
            value={fancyNumberSum(salaries, "monthlySalary")}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
