import cn from "classnames";
import * as React from "react";
import css from "./style.module.scss";

interface ExcerptProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

export function Excerpt({ children, className, ...props }: ExcerptProps) {
  return (
    <div className={cn(css.excerpt, className)} {...props}>
      {children}
    </div>
  );
}
