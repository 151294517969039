import { useUpsertMonthlyExpense } from "api/graphql/MonthlyExpense";
import { Wizard } from "components/Wizard";
import React, { ReactNode, useState } from "react";
import { useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import Documentation, { DocumentationSchema } from "./Documentation";
import Expense, { ExpenseSchema } from "./Expense";

interface Props {
  category: MonthlyExpenseCategoryChoice;
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  /** The URL to switch to after the initial save. Created object ID will be appended */
  editURL?: string;
  subtitle?: ReactNode;
}

export const MonthlyExpenseSchema = ExpenseSchema.concat(DocumentationSchema);

export function MonthlyExpenseWizard({
  category,
  backURL,
  nextURL,
  initialValue,
  title,
  editURL = "edit",
  subtitle,
}: Props) {
  const { path, url } = useNested();
  const [upsertMonthlyExpense] = useUpsertMonthlyExpense(undefined, category);
  const history = useHistory();
  const [expenseId, setExpenseId] = useState(initialValue?.id);

  return (
    <Wizard
      title={title}
      subtitle={subtitle}
      backURL={backURL}
      nextURL={nextURL}
      initialValue={initialValue}
      vertical
      steps={[
        {
          path: "/monthly_expense",
          title: "Monthly Expense",
          component: Expense,
          schema: ExpenseSchema,
          async onSave(formState) {
            const { data } = await upsertMonthlyExpense(
              { ...formState, category },
              undefined,
              ({ categoryFilter }) =>
                categoryFilter === category || !categoryFilter
            );

            const { id } = data.monthlyExpense;
            setExpenseId(id);
            if (expenseId) {
              history.push(url("/documentation"));
            } else {
              history.push(`../${editURL}/${id}/documentation`);
            }
            return data.monthlyExpense;
          },
        },
        {
          path: "/documentation",
          title: "Documentation",
          component: Documentation,
          schema: DocumentationSchema,
        },
      ]}
    />
  );
}
