import SectionSummary from "components/FormModuleSubMenu/SectionSummary";
import { SectionIndexProps } from "components/FormModuleSubMenu/SubmenuModule";
import React from "react";
import liabilitiesIllustration from "./liabilities.svg";
import LIABILITIES_INDEX from "./LiabilitiesIndex.gql";

export default function LiabilitiesSummary(props: SectionIndexProps) {
  return (
    <SectionSummary<LiabilitiesIndex>
      {...props}
      query={LIABILITIES_INDEX}
      image={
        <img
          src={liabilitiesIllustration}
          alt="A fat banker with a cane, tuxedo, and top hat holding a sack of cash"
          width={406}
          height={306}
        />
      }
      heroCopy={
        <p>
          When filing for bankruptcy, your lawyer will need to know about any
          debts and liabilities you have incurred. We&rsquo;ll walk you through
          different types of debts, like mortgages and credit card debts, so you
          can build an accurate picture for them.
        </p>
      }
      completedCopy={
        <p>
          Nicely done! You&rsquo;ve completed all the liabilities sections. If
          the information below is accurate and complete, you&rsquo;re all done
          entering your liabilities.
        </p>
      }
    />
  );
}
