import cn from "classnames";
import { Card } from "components/Card";
import { Title } from "components/Title";
import { WizardRouter, WizardStep } from "components/Wizard";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { breakpoints, useBreakpoint, useQueryParams } from "utils/hooks";
import { object } from "yup";
import { Actions } from "./ProgressIndicator";
import css from "./styles.module.css";

export { ProgressIndicator } from "./ProgressIndicator";

export function StepHeading({ children }: { children: React.ReactNode }) {
  return <h2 className={css.stepHeading}>{children}</h2>;
}

export interface StepProps extends React.HTMLAttributes<HTMLDivElement> {
  completed: number;
  total: number;
  actions?: Actions;
}

export interface AboutTheProcessProps
  extends React.HTMLAttributes<HTMLDivElement> {
  completedURL: string;
  steps: {
    component: React.FC<StepProps>;
    path: string;
    title: string;
  }[];
}

export default function AboutTheProcess({
  completedURL,
  steps,
  ...props
}: AboutTheProcessProps) {
  const history = useHistory();

  const { pathname } = useLocation();
  const isLastStep = !!pathname.match(/\/whats-next/);

  const { intro } = useQueryParams();
  const isInitialWalkthrough = intro !== "false";

  const isMobile = useBreakpoint(breakpoints.mobile);

  return (
    <div {...props} className={cn(props.className, css.wizardWrapper)}>
      <Title style={{ textAlign: "center" }}>Understanding Bankruptcy</Title>
      <WizardRouter
        backURL="../"
        nextURL={completedURL}
        abortURL={isLastStep ? "" : completedURL}
        abortText={isInitialWalkthrough ? "Skip" : "Cancel"}
        saveText={isInitialWalkthrough ? "Get started" : "Done"}
        backText={isMobile ? "" : "Back"}
        nextText={isMobile ? "" : "Next"}
        hideNavigation
      >
        {steps.map((step, i) => (
          <WizardStep
            component={() => {
              const actions = {} as Actions;
              for (let j = 0; j < steps.length; j++) {
                if (j != i) {
                  actions[
                    j + 1 /* one-based indexing for # of steps complete */
                  ] = () => {
                    history.push(`.${steps[j].path}`);
                  };
                }
              }
              return (
                <Card>
                  <step.component
                    total={steps.length}
                    className={css.stepWrapper}
                    completed={
                      i + 1 /* one-based indexing for # of steps complete */
                    }
                    actions={actions}
                  />
                </Card>
              );
            }}
            path={step.path}
            key={step.path}
            title={step.title}
            schema={object()}
          />
        ))}
      </WizardRouter>
    </div>
  );
}
