import { useUpsertStudentLoan } from "api/graphql/StudentLoan";
import { CosignerForm } from "components/FormSections/Cosigner";
import { Wizard } from "components/Wizard";
import { CosignersSchema } from "components/WizardSteps/Cosigners";
import { omit } from "lodash";
import React, { useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import { RequiredKeys } from "utils/types";
import { object, string } from "yup";
import { Cosigner } from "./Cosigner";
import { Lender, LenderSchema } from "./Lender";
import { LoanDetails, LoanDetailsSchema } from "./LoanDetails";

export const StudentLoanSchemaWithoutCosigners = LenderSchema.concat(
  LoanDetailsSchema
).concat(
  object({
    id: string().optional().nullable(),
  })
);

export const StudentLoanSchema =
  StudentLoanSchemaWithoutCosigners.concat(CosignersSchema);

export type LoanWizardOutput = RequiredKeys<
  ArrayItemType<StudentLoansModule["studentLoans"]>,
  "accountNumber" | "statementFile" | "statementFileHash"
>;

interface LoanWizardProps {
  backURL: string;
  nextURL?: string;
  initialValue?: any;
  title: string;
  onLoanCreated?: (loan: LoanWizardOutput) => void;
}

export default function StudentLoanWizard({
  backURL,
  nextURL,
  initialValue,
  title,
  onLoanCreated,
}: LoanWizardProps) {
  const history = useHistory();
  const { path, url } = useNested();
  const [studentLoanId, setStudentLoanId] = useState(initialValue?.id);
  const [upsertStudentLoan] = useUpsertStudentLoan();

  const cosignersURL = "/cosigners";

  return (
    <Switch>
      <Route
        path={path("/create_cosigner")}
        render={() => (
          <CosignerForm
            title="Add a Student Loan Cosigner"
            returnURL={url(cosignersURL)}
            relatedTypename="StudentLoan"
            relatedId={studentLoanId}
          />
        )}
      />
      <Route
        path={path("/edit_cosigner/:cosignerId")}
        render={({ match }) => (
          <CosignerForm
            title="Updating Student Loan Cosigner"
            returnURL={url(cosignersURL)}
            relatedId={studentLoanId}
            relatedTypename="StudentLoan"
            id={match.params.cosignerId}
          />
        )}
      />

      <Wizard
        title={title}
        nextURL={nextURL}
        backURL={backURL}
        initialValue={initialValue}
        vertical
        steps={[
          {
            path: "/lender",
            title: "Lender",
            component: Lender,
            schema: LenderSchema,
          },
          {
            path: "/loan-details",
            title: "Loan details",
            component: LoanDetails,
            schema: LoanDetailsSchema,
            async onSave(formValues) {
              const { statementFile } = formValues;
              const otherVars =
                statementFile instanceof File ? { statementFile } : {};

              const { data } = await upsertStudentLoan(
                omit(formValues, "statementFile"),
                otherVars
              );
              const { id } = data.studentLoan;
              setStudentLoanId(id);

              if (studentLoanId) {
                history.push(url(cosignersURL));
              } else {
                history.push(`../edit/${id}${cosignersURL}`);
              }
              onLoanCreated?.(data.studentLoan as LoanWizardOutput);
              return data.studentLoan;
            },
          },
          {
            path: cosignersURL,
            title: "Cosigners",
            component: Cosigner,
            schema: CosignersSchema,
          },
        ]}
      />
    </Switch>
  );
}
