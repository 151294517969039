import { useEditorBuffer } from "components/EditorState/hooks";
import { WizardRouteChildProps } from "components/Wizard";
import CosignersWizardStep from "components/WizardSteps/Cosigners";
import React from "react";

export default function Cosigners(props: WizardRouteChildProps) {
  const [{ buffer }] = useEditorBuffer();
  const taxDebtID = buffer.id;

  return (
    <CosignersWizardStep
      relatedTypename="TaxDebt"
      relatedId={taxDebtID}
      cosignerName="co-debtor"
      {...props}
    />
  );
}
