import { useUpsertBorrowedAsset } from "api/graphql/BorrowedAsset";
import { OtherPersonSchema } from "components/FormSections/OtherPerson";
import { Wizard } from "components/Wizard";
import React from "react";
import { object } from "yup";
import Items, { ItemsSchema } from "./Items";
import Owner, { OwnerSchema } from "./Owner";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  /** The URL to switch to after the initial save. Created object ID will be appended */
  editURL?: string;
}

export const BorrowedAssetsSchema = ItemsSchema.concat(
  object({
    owner: OtherPersonSchema.required(),
  })
);

export default function BorrowedAssetWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: Props) {
  const [upsertBorrowedAsset] = useUpsertBorrowedAsset();

  return (
    <Wizard
      title={title}
      subtitle={<p>Describe assets you are holding for a single person.</p>}
      backURL={backURL}
      nextURL={nextURL}
      initialValue={initialValue}
      vertical
      steps={[
        {
          path: "/items",
          title: "Items",
          component: Items,
          schema: ItemsSchema,
        },
        {
          path: "/owner",
          title: "Owner",
          component: Owner,
          schema: OwnerSchema,
          includeSubpaths: true,
          async onSave(values) {
            const { data } = await upsertBorrowedAsset(values);
            return data.borrowedAsset;
          },
        },
      ]}
    />
  );
}
