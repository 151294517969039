import { QueryHookOptions, useMutation, useQuery } from "@apollo/client";
import { LawFirmUserInput } from "globalTypes";
import React from "react";
import CREATE_LAW_FIRM_USER from "./CreateLawFirmUser.gql";
import GET_LAW_FIRM_USER from "./GetLawFirmUser.gql";
import LIST_LAW_FIRM_USERS from "./ListLawFirmUsers.gql";

export function useListLawFirmUsers(
  options?: QueryHookOptions<ListLawFirmUsers>
) {
  return useQuery<ListLawFirmUsers>(LIST_LAW_FIRM_USERS, options);
}

export function useGetLawFirmUser(id: string) {
  return useQuery<GetLawFirmUser, GetLawFirmUserVariables>(GET_LAW_FIRM_USER, {
    variables: { id },
  });
}

export function useCreateLawFirmUser<
  Schema extends { createLawFirmUser: any } = CreateLawFirmUser,
  Variables = CreateLawFirmUserVariables
>(query = CREATE_LAW_FIRM_USER) {
  const [doMutation] = useMutation<
    CreateLawFirmUser,
    CreateLawFirmUserVariables
  >(CREATE_LAW_FIRM_USER, {
    update(cache, { data }) {
      const existingUsers: ListLawFirmUsers = cache.readQuery({
        query: LIST_LAW_FIRM_USERS,
      });
      cache.writeQuery({
        query: LIST_LAW_FIRM_USERS,
        data: {
          lawFirmUsers: [
            ...existingUsers.lawFirmUsers,
            { ...data.createLawFirmUser },
          ],
        },
      });
    },
  });

  return React.useCallback(
    (input: LawFirmUserInput) => {
      return doMutation({
        variables: { input: input },
      });
    },
    [doMutation]
  );
}
