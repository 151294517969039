export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Decimal: any;
  ISODate: any;
  ISODateTime: any;
  JSONBlob: any;
  Money: any;
  UUID: any;
  Upload: any;
};


export type AddressInput = {
  id?: Maybe<Scalars['UUID']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: StateInput;
  zipCode: Scalars['String'];
};




export type AssetLossInput = {
  id?: Maybe<Scalars['UUID']>;
  lossDate: Scalars['ISODate'];
  assetValue: Scalars['Decimal'];
  assetDescription: Scalars['String'];
  lossDescription: Scalars['String'];
};


export type AssetSaleInput = {
  id?: Maybe<Scalars['UUID']>;
  saleDate: Scalars['ISODate'];
  saleAmount: Scalars['Decimal'];
  saleDescription?: Maybe<Scalars['String']>;
  itemDescription: Scalars['String'];
};


export type BankruptcyCaseInput = {
  id?: Maybe<Scalars['UUID']>;
  caseNumber: Scalars['String'];
  bankruptcyCourt: BankruptcyCourtInput;
  chapter: Scalars['Int'];
  filingDate: Scalars['ISODate'];
  closureDate?: Maybe<Scalars['ISODate']>;
  hasNoCofilers?: Maybe<Scalars['Boolean']>;
  inRepayment?: Maybe<Scalars['Boolean']>;
  humanReviewed: Scalars['Boolean'];
};


export type BankruptcyCourtInput = {
  name: Scalars['String'];
  state?: Maybe<StateInput>;
};

export type BorrowedAssetInput = {
  id?: Maybe<Scalars['UUID']>;
  items: Scalars['String'];
  totalValue: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  owner?: Maybe<OtherPersonInput>;
};



export type ChildSupportInput = {
  id?: Maybe<Scalars['UUID']>;
  pays: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
};




export type CoOwnerDetailsInput = {
  coOwnedValue: Scalars['Decimal'];
};




export type CollectionAgencyInput = {
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  accountNumber: Scalars['String'];
  mailingAddress?: Maybe<AddressInput>;
  mailingAddressId?: Maybe<Scalars['UUID']>;
  phoneNumber?: Maybe<Scalars['String']>;
};




export type ConsultationInput = {
  id?: Maybe<Scalars['UUID']>;
  consultantName: Scalars['String'];
  consultantAddress?: Maybe<AddressInput>;
  consultantAddressId?: Maybe<Scalars['UUID']>;
  consultationType: ConsultationTypeChoice;
};


export type ConsultationPaymentInput = {
  id?: Maybe<Scalars['UUID']>;
  paymentDate: Scalars['ISODate'];
  amount: Scalars['Decimal'];
};

export const ConsultationTypeChoice = {
  LegalBankruptcyAdvice: 'LEGAL_BANKRUPTCY_ADVICE',
  DebtSettlement: 'DEBT_SETTLEMENT'
} as const;

export type ConsultationTypeChoice = typeof ConsultationTypeChoice[keyof typeof ConsultationTypeChoice];


export type CreditCardDebtInput = {
  id?: Maybe<Scalars['UUID']>;
  lenderName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountCreationMonth: Scalars['ISODate'];
  billingAddress?: Maybe<AddressInput>;
  monthlyPayment?: Maybe<Scalars['Decimal']>;
  lastPaymentDate?: Maybe<Scalars['ISODate']>;
  outstandingAmount: Scalars['Decimal'];
  hasNoCosigners?: Maybe<Scalars['Boolean']>;
  hasNoCollectors?: Maybe<Scalars['Boolean']>;
  delinquentAmount: Scalars['Decimal'];
  lastSixMonthsDebt: Scalars['Decimal'];
  lastNinetyDayCashAdvance?: Maybe<Scalars['Decimal']>;
};


export type CreditCounselingInput = {
  id?: Maybe<Scalars['UUID']>;
  completed: Scalars['Boolean'];
  dateCompleted?: Maybe<Scalars['ISODate']>;
  certificateNumber?: Maybe<Scalars['String']>;
  providerName?: Maybe<Scalars['String']>;
};

export const CreditReportStatus = {
  Pending: 'PENDING',
  Issued: 'ISSUED',
  Error: 'ERROR',
  Invalid: 'INVALID'
} as const;

export type CreditReportStatus = typeof CreditReportStatus[keyof typeof CreditReportStatus];





export type EducationalInstitutionInput = {
  id?: Maybe<Scalars['UUID']>;
  displayName?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};


export type EducationalStudyInput = {
  id?: Maybe<Scalars['UUID']>;
  school: Scalars['String'];
  graduated: Scalars['Boolean'];
  yearsAttended: Array<Scalars['Int']>;
  firstYear: Scalars['Int'];
  lastYear: Scalars['Int'];
  degree: Scalars['String'];
  educationalInstitution?: Maybe<EducationalInstitutionInput>;
};



export type EmployerLinkTokenInput = {
  provider: Scalars['String'];
  employerId: Scalars['String'];
  accountId?: Maybe<Scalars['String']>;
};


export type EmploymentRecordInput = {
  id?: Maybe<Scalars['UUID']>;
  companyName: Scalars['String'];
  jobTitle: Scalars['String'];
  address?: Maybe<AddressInput>;
  startMonth?: Maybe<Scalars['ISODate']>;
  endMonth?: Maybe<Scalars['ISODate']>;
  monthlySalary?: Maybe<Scalars['Decimal']>;
  lastSixMonthsGrossAmount?: Maybe<Scalars['Decimal']>;
  payFrequency?: Maybe<EmploymentRecordPayFrequencyChoice>;
  ownedBusinessId?: Maybe<Scalars['UUID']>;
  employerLinkToken?: Maybe<EmployerLinkTokenInput>;
};

export const EmploymentRecordPayFrequencyChoice = {
  Weekly: 'WEEKLY',
  EveryTwoWeeks: 'EVERY_TWO_WEEKS',
  TwiceAMonth: 'TWICE_A_MONTH',
  Monthly: 'MONTHLY',
  Other: 'OTHER'
} as const;

export type EmploymentRecordPayFrequencyChoice = typeof EmploymentRecordPayFrequencyChoice[keyof typeof EmploymentRecordPayFrequencyChoice];

export type EnvironmentalCleanupNoticeInput = {
  id?: Maybe<Scalars['UUID']>;
  governmentalUnitName: Scalars['String'];
  siteAddress: AddressInput;
  noticeDate: Scalars['ISODate'];
  wasReceived: Scalars['Boolean'];
  lawName?: Maybe<Scalars['String']>;
};



export type FamilyMemberRecordInput = {
  id?: Maybe<Scalars['UUID']>;
  relatedPerson?: Maybe<OtherPersonInput>;
  relatedPersonId?: Maybe<Scalars['UUID']>;
  relationship: Scalars['String'];
  isDependent: Scalars['Boolean'];
  isLivingWith: Scalars['Boolean'];
  childSupportPaid: Scalars['Boolean'];
  childSupportReceived: Scalars['Boolean'];
  isBackChildSupportOwed: Scalars['Boolean'];
  childSupportAmount?: Maybe<Scalars['Decimal']>;
  overdueChildSupportAmount?: Maybe<Scalars['Decimal']>;
};


export type FamilyProfileInput = {
  id?: Maybe<Scalars['UUID']>;
  maritalStatus?: Maybe<FamilyProfileMaritalChoice>;
  spouse?: Maybe<OtherPersonInput>;
  spouseId?: Maybe<Scalars['UUID']>;
  divorceDate?: Maybe<Scalars['ISODate']>;
  hasNoFamilyMembers: Scalars['Boolean'];
  spouseFiling?: Maybe<Scalars['Boolean']>;
};

export const FamilyProfileMaritalChoice = {
  Married: 'MARRIED',
  Separated: 'SEPARATED',
  Divorced: 'DIVORCED',
  Widowed: 'WIDOWED',
  Unmarried: 'UNMARRIED'
} as const;

export type FamilyProfileMaritalChoice = typeof FamilyProfileMaritalChoice[keyof typeof FamilyProfileMaritalChoice];

export const FarmAssetCategory = {
  FarmAnimals: 'FARM_ANIMALS',
  MarketableCrops: 'MARKETABLE_CROPS',
  FarmEquipment: 'FARM_EQUIPMENT',
  CommercialFishingEquipment: 'COMMERCIAL_FISHING_EQUIPMENT',
  FarmSupplies: 'FARM_SUPPLIES',
  CommercialFishingSupplies: 'COMMERCIAL_FISHING_SUPPLIES'
} as const;

export type FarmAssetCategory = typeof FarmAssetCategory[keyof typeof FarmAssetCategory];
export type FarmAssetInput = {
  id?: Maybe<Scalars['UUID']>;
  category: FarmAssetCategory;
  currentValue: Scalars['Decimal'];
  assetDescription: Scalars['String'];
};



export type FilingPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  firstName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  suffix?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  ssnItin?: Maybe<Scalars['String']>;
  birthday: Scalars['ISODate'];
  mailingAddress?: Maybe<AddressInput>;
};



export type FilingProfileInput = {
  documentsDueDate?: Maybe<Scalars['ISODate']>;
  lawFirmContact?: Maybe<Scalars['String']>;
};


export type FinancialAccountInput = {
  id?: Maybe<Scalars['UUID']>;
  accountType: FinancialAccountTypeChoice;
  accountNumber: Scalars['String'];
  accountCreationMonth?: Maybe<Scalars['ISODate']>;
  accountClosureMonth?: Maybe<Scalars['ISODate']>;
  contactAddress?: Maybe<AddressInput>;
  beneficiary?: Maybe<Scalars['String']>;
  currentValue: Scalars['Decimal'];
  hasNoMajorRecentDeposits?: Maybe<Scalars['Boolean']>;
  hasNoMajorRecentWithdrawals?: Maybe<Scalars['Boolean']>;
  hasNoCoowners?: Maybe<Scalars['Boolean']>;
  institutionName: Scalars['String'];
  otherDescription?: Maybe<Scalars['String']>;
};

export const FinancialAccountTypeChoice = {
  Checking: 'CHECKING',
  Savings: 'SAVINGS',
  Retirement: 'RETIREMENT',
  InvestmentOther: 'INVESTMENT_OTHER',
  Hsa: 'HSA',
  MortgageEscrow: 'MORTGAGE_ESCROW',
  InvestmentEducational: 'INVESTMENT_EDUCATIONAL',
  Other: 'OTHER'
} as const;

export type FinancialAccountTypeChoice = typeof FinancialAccountTypeChoice[keyof typeof FinancialAccountTypeChoice];

export type FinancialInvestmentInput = {
  id?: Maybe<Scalars['UUID']>;
  currentValue: Scalars['Decimal'];
  description?: Maybe<Scalars['String']>;
  entityName: Scalars['String'];
  entityOwnedPercentage?: Maybe<Scalars['Decimal']>;
  hasNoCoowners?: Maybe<Scalars['Boolean']>;
  investmentType: FinancialInvestmentTypeChoice;
};

export const FinancialInvestmentTypeChoice = {
  Annuity: 'ANNUITY',
  Bond: 'BOND',
  Cash: 'CASH',
  CertificateOfDeposit: 'CERTIFICATE_OF_DEPOSIT',
  InstrumentNegotiable: 'INSTRUMENT_NEGOTIABLE',
  IntellectualProperty: 'INTELLECTUAL_PROPERTY',
  InvestmentFund: 'INVESTMENT_FUND',
  LicenseOrFranchise: 'LICENSE_OR_FRANCHISE',
  Other: 'OTHER',
  Prepayment: 'PREPAYMENT',
  Royalty: 'ROYALTY',
  StockPublic: 'STOCK_PUBLIC',
  StockPrivate: 'STOCK_PRIVATE',
  Trust: 'TRUST'
} as const;

export type FinancialInvestmentTypeChoice = typeof FinancialInvestmentTypeChoice[keyof typeof FinancialInvestmentTypeChoice];

export type GigIncomeInput = {
  id?: Maybe<Scalars['UUID']>;
  description?: Maybe<Scalars['String']>;
};


export const GovernmentDisbursementCategoryChoice = {
  Foodshare: 'FOODSHARE',
  SocialSecurity: 'SOCIAL_SECURITY'
} as const;

export type GovernmentDisbursementCategoryChoice = typeof GovernmentDisbursementCategoryChoice[keyof typeof GovernmentDisbursementCategoryChoice];
export type GovernmentDisbursementInput = {
  id?: Maybe<Scalars['UUID']>;
  category: GovernmentDisbursementCategoryChoice;
  uploadFile?: Maybe<Scalars['Upload']>;
};

export type HasNoSupportingDocumentationInput = {
  id: Scalars['UUID'];
  typename: Scalars['String'];
  hasNoSupportingDocumentation: Scalars['Boolean'];
};


export type HouseholdContributorInput = {
  id?: Maybe<Scalars['UUID']>;
  relatedPerson?: Maybe<OtherPersonInput>;
  relatedPersonId?: Maybe<Scalars['UUID']>;
  relationship: Scalars['String'];
  isLivingWith: Scalars['Boolean'];
  monthlyAmount: Scalars['Decimal'];
};


export const HouseholdItemCategoryChoice = {
  GoodsAndFurnishings: 'GOODS_AND_FURNISHINGS',
  Electronics: 'ELECTRONICS',
  Collectibles: 'COLLECTIBLES',
  SportHobbyEquipment: 'SPORT_HOBBY_EQUIPMENT',
  Firearms: 'FIREARMS',
  Clothing: 'CLOTHING',
  Jewelry: 'JEWELRY',
  NonFarmAnimals: 'NON_FARM_ANIMALS',
  Other: 'OTHER'
} as const;

export type HouseholdItemCategoryChoice = typeof HouseholdItemCategoryChoice[keyof typeof HouseholdItemCategoryChoice];
export type HouseholdItemInput = {
  id?: Maybe<Scalars['UUID']>;
  category: HouseholdItemCategoryChoice;
  currentValue: Scalars['Decimal'];
  description: Scalars['String'];
  wantToKeep: Scalars['Boolean'];
  hasNoCoowners?: Maybe<Scalars['Boolean']>;
};




export type InsurancePolicyInput = {
  id?: Maybe<Scalars['UUID']>;
  type: InsurancePolicyType;
  premium: Scalars['Decimal'];
  companyName: Scalars['String'];
  beneficiary?: Maybe<Scalars['String']>;
  surrenderValue?: Maybe<Scalars['Decimal']>;
  description?: Maybe<Scalars['String']>;
  hasNoSupportingDocumentation?: Maybe<Scalars['Boolean']>;
  wantToKeep: Scalars['Boolean'];
};

export const InsurancePolicyType = {
  Auto: 'AUTO',
  RealEstate: 'REAL_ESTATE',
  DisabilityShort: 'DISABILITY_SHORT',
  DisabilityLong: 'DISABILITY_LONG',
  TermLife: 'TERM_LIFE',
  WholeLife: 'WHOLE_LIFE',
  Health: 'HEALTH',
  Dental: 'DENTAL',
  Vision: 'VISION'
} as const;

export type InsurancePolicyType = typeof InsurancePolicyType[keyof typeof InsurancePolicyType];


export type LawFirmInput = {
  name: Scalars['String'];
  displayName: Scalars['String'];
};



export type LawFirmUserInput = {
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  email: Scalars['String'];
};


export type LawsuitInput = {
  id?: Maybe<Scalars['UUID']>;
  plaintiffName: Scalars['String'];
  defendantName: Scalars['String'];
  description: Scalars['String'];
  caseNumber?: Maybe<Scalars['String']>;
  caseDate?: Maybe<Scalars['ISODate']>;
  filingState?: Maybe<StateInput>;
  amountReceived?: Maybe<Scalars['Decimal']>;
  amountPaid?: Maybe<Scalars['Decimal']>;
  amountOwed?: Maybe<Scalars['Decimal']>;
  paymentDate?: Maybe<Scalars['ISODate']>;
};

export const LoanCategory = {
  Personal: 'PERSONAL',
  Student: 'STUDENT',
  DirectSubsidized: 'DIRECT_SUBSIDIZED',
  DirectUnsubsidized: 'DIRECT_UNSUBSIDIZED',
  Perkins: 'PERKINS',
  DirectPlus: 'DIRECT_PLUS',
  ParentPlus: 'PARENT_PLUS',
  DirectConsolidation: 'DIRECT_CONSOLIDATION',
  IncomeContingent: 'INCOME_CONTINGENT'
} as const;

export type LoanCategory = typeof LoanCategory[keyof typeof LoanCategory];
export const LoanType = {
  Public: 'PUBLIC',
  Private: 'PRIVATE'
} as const;

export type LoanType = typeof LoanType[keyof typeof LoanType];

export type MedicalDebtInput = {
  id?: Maybe<Scalars['UUID']>;
  providerName: Scalars['String'];
  accountNumber: Scalars['String'];
  billingAddress?: Maybe<AddressInput>;
  monthlyPayment: Scalars['Decimal'];
  lastPaymentDate?: Maybe<Scalars['ISODate']>;
  outstandingAmount: Scalars['Decimal'];
  stillUse?: Maybe<Scalars['Boolean']>;
  hasNoCosigners?: Maybe<Scalars['Boolean']>;
  hasNoCollectors?: Maybe<Scalars['Boolean']>;
  delinquentAmount: Scalars['Decimal'];
};



export const ModuleStatus = {
  NotStarted: 'NOT_STARTED',
  InProgress: 'IN_PROGRESS',
  Complete: 'COMPLETE'
} as const;

export type ModuleStatus = typeof ModuleStatus[keyof typeof ModuleStatus];


export const MonthlyExpenseCategoryChoice = {
  RealEstateRent: 'REAL_ESTATE_RENT',
  RealEstateTaxes: 'REAL_ESTATE_TAXES',
  RealEstateUpkeep: 'REAL_ESTATE_UPKEEP',
  RealEstateHoaDues: 'REAL_ESTATE_HOA_DUES',
  UtilityElectricHeat: 'UTILITY_ELECTRIC_HEAT',
  UtilityWater: 'UTILITY_WATER',
  UtilitySewer: 'UTILITY_SEWER',
  UtilityTrash: 'UTILITY_TRASH',
  TelephonyLandline: 'TELEPHONY_LANDLINE',
  TelephonyTelevision: 'TELEPHONY_TELEVISION',
  TelephonyInternet: 'TELEPHONY_INTERNET',
  TelephonyMobilePhone: 'TELEPHONY_MOBILE_PHONE',
  ChildCare: 'CHILD_CARE',
  ChildSchool: 'CHILD_SCHOOL',
  Alimony: 'ALIMONY',
  HouseholdFood: 'HOUSEHOLD_FOOD',
  HouseholdClothing: 'HOUSEHOLD_CLOTHING',
  HouseholdPersonalCare: 'HOUSEHOLD_PERSONAL_CARE',
  HouseholdMedicalExpenses: 'HOUSEHOLD_MEDICAL_EXPENSES',
  HouseholdMisc: 'HOUSEHOLD_MISC',
  TransportationGeneral: 'TRANSPORTATION_GENERAL',
  Recreation: 'RECREATION',
  Charity: 'CHARITY'
} as const;

export type MonthlyExpenseCategoryChoice = typeof MonthlyExpenseCategoryChoice[keyof typeof MonthlyExpenseCategoryChoice];
export type MonthlyExpenseInput = {
  id?: Maybe<Scalars['UUID']>;
  category: MonthlyExpenseCategoryChoice;
  amount: Scalars['Decimal'];
  description?: Maybe<Scalars['String']>;
  hasNoSupportingDocumentation?: Maybe<Scalars['Boolean']>;
};

export const MutableProfileField = {
  HasNoCofiler: 'hasNoCofiler',
  HasNoEmploymentIncome: 'hasNoEmploymentIncome',
  HasNoOtherIncome: 'hasNoOtherIncome',
  HasNoGigIncome: 'hasNoGigIncome',
  HasNoHouseholdContributors: 'hasNoHouseholdContributors',
  HasNoFoodshare: 'hasNoFoodshare',
  HasNoSocialSecurity: 'hasNoSocialSecurity',
  HasNoRealEstate: 'hasNoRealEstate',
  HasNoRealEstateMortgages: 'hasNoRealEstateMortgages',
  HasNoVehicles: 'hasNoVehicles',
  HasNoVehicleLeases: 'hasNoVehicleLeases',
  HasNoVehicleLoans: 'hasNoVehicleLoans',
  HasNoTaxDebts: 'hasNoTaxDebts',
  HasNoCreditCardDebts: 'hasNoCreditCardDebts',
  HasNoMedicalDebts: 'hasNoMedicalDebts',
  HasNoRetirementAccountLoans: 'hasNoRetirementAccountLoans',
  HasNoOtherDebts: 'hasNoOtherDebts',
  HasNoPreviousBankruptcies: 'hasNoPreviousBankruptcies',
  HasNoFinancialAccounts: 'hasNoFinancialAccounts',
  HasNoFinancialInvestments: 'hasNoFinancialInvestments',
  HasNoAssetSales: 'hasNoAssetSales',
  HasNoNsldsLoans: 'hasNoNsldsLoans',
  HasNoRealEstateRentalExpenses: 'hasNoRealEstateRentalExpenses',
  HasNoRealEstateTaxExpenses: 'hasNoRealEstateTaxExpenses',
  HasNoRealEstateInsuranceExpenses: 'hasNoRealEstateInsuranceExpenses',
  HasNoRealEstateUpkeepExpenses: 'hasNoRealEstateUpkeepExpenses',
  HasNoRealEstateHoaExpenses: 'hasNoRealEstateHoaExpenses',
  HasNoUtilityElectricAndHeatingExpenses: 'hasNoUtilityElectricAndHeatingExpenses',
  HasNoUtilityWaterExpenses: 'hasNoUtilityWaterExpenses',
  HasNoUtilitySewerExpenses: 'hasNoUtilitySewerExpenses',
  HasNoUtilityTrashExpenses: 'hasNoUtilityTrashExpenses',
  HasNoTelephonyLandlineExpenses: 'hasNoTelephonyLandlineExpenses',
  HasNoTelephonyTelevisionExpenses: 'hasNoTelephonyTelevisionExpenses',
  HasNoTelephonyInternetExpenses: 'hasNoTelephonyInternetExpenses',
  HasNoTelephonyMobileExpenses: 'hasNoTelephonyMobileExpenses',
  HasNoChildCareExpenses: 'hasNoChildCareExpenses',
  HasNoChildEducationExpenses: 'hasNoChildEducationExpenses',
  HasNoHouseholdFoodExpenses: 'hasNoHouseholdFoodExpenses',
  HasNoHouseholdClothingExpenses: 'hasNoHouseholdClothingExpenses',
  HasNoHouseholdPersonalCareExpenses: 'hasNoHouseholdPersonalCareExpenses',
  HasNoHouseholdMedicalExpenses: 'hasNoHouseholdMedicalExpenses',
  HasNoHouseholdMiscExpenses: 'hasNoHouseholdMiscExpenses',
  HasNoHouseholdInsuranceTermExpenses: 'hasNoHouseholdInsuranceTermExpenses',
  HasNoHouseholdInsuranceWholeExpenses: 'hasNoHouseholdInsuranceWholeExpenses',
  HasNoHouseholdInsuranceHealthcareExpenses: 'hasNoHouseholdInsuranceHealthcareExpenses',
  HasNoHouseholdInsuranceDentalExpenses: 'hasNoHouseholdInsuranceDentalExpenses',
  HasNoHouseholdInsuranceVisionExpenses: 'hasNoHouseholdInsuranceVisionExpenses',
  HasNoHouseholdInsuranceShortTermDisabilityExpenses: 'hasNoHouseholdInsuranceShortTermDisabilityExpenses',
  HasNoHouseholdInsuranceLongTermDisabilityExpenses: 'hasNoHouseholdInsuranceLongTermDisabilityExpenses',
  HasNoTransportationGeneralExpenses: 'hasNoTransportationGeneralExpenses',
  HasNoTransportationInsuranceExpenses: 'hasNoTransportationInsuranceExpenses',
  HasNoRecreationExpenses: 'hasNoRecreationExpenses',
  HasNoCharityExpenses: 'hasNoCharityExpenses',
  HasNoAlimonyExpenses: 'hasNoAlimonyExpenses',
  HasNoServicePrepayments: 'hasNoServicePrepayments',
  HasCompletedNewFilingWelcome: 'hasCompletedNewFilingWelcome',
  HasCompletedDashboardWalkthrough: 'hasCompletedDashboardWalkthrough',
  ShouldGetMonthlyIncome: 'shouldGetMonthlyIncome',
  HasNoHouseholdGoodsAndFurnishingItems: 'hasNoHouseholdGoodsAndFurnishingItems',
  HasNoHouseholdElectronicsItems: 'hasNoHouseholdElectronicsItems',
  HasNoHouseholdCollectiblesItems: 'hasNoHouseholdCollectiblesItems',
  HasNoHouseholdSportHobbyEquipmentItems: 'hasNoHouseholdSportHobbyEquipmentItems',
  HasNoHouseholdFirearmsItems: 'hasNoHouseholdFirearmsItems',
  HasNoHouseholdClothingItems: 'hasNoHouseholdClothingItems',
  HasNoHouseholdJewelryItems: 'hasNoHouseholdJewelryItems',
  HasNoHouseholdNonFarmAnimalsItems: 'hasNoHouseholdNonFarmAnimalsItems',
  HasNoHouseholdOtherItems: 'hasNoHouseholdOtherItems',
  HasNoSeizures: 'hasNoSeizures',
  HasNoAssetLosses: 'hasNoAssetLosses',
  HasNoBorrowedAssets: 'hasNoBorrowedAssets',
  HasNoPreviousNames: 'hasNoPreviousNames',
  HasNoStorage: 'hasNoStorage',
  HasNoLawsuits: 'hasNoLawsuits',
  HasNoEnvironmentalCleanupNotices: 'hasNoEnvironmentalCleanupNotices',
  HasNoBankruptcyConsultations: 'hasNoBankruptcyConsultations',
  HasNoBusinesses: 'hasNoBusinesses',
  HasNoStudentLoans: 'hasNoStudentLoans',
  HasNoEducationalStudies: 'hasNoEducationalStudies',
  HasNoFarmAnimals: 'hasNoFarmAnimals',
  HasNoMarketableCrops: 'hasNoMarketableCrops',
  HasNoFarmEquipment: 'hasNoFarmEquipment',
  HasNoCommercialFishingEquipment: 'hasNoCommercialFishingEquipment',
  HasNoFarmSupplies: 'hasNoFarmSupplies',
  HasNoCommercialFishingSupplies: 'hasNoCommercialFishingSupplies',
  EnableCreditReport: 'enableCreditReport',
  DocumentsDueDate: 'documentsDueDate',
  LawFirmContact: 'lawFirmContact'
} as const;

export type MutableProfileField = typeof MutableProfileField[keyof typeof MutableProfileField];

export const MxConnectionStatus = {
  Created: 'CREATED',
  Prevented: 'PREVENTED',
  Denied: 'DENIED',
  Challenged: 'CHALLENGED',
  Rejected: 'REJECTED',
  Locked: 'LOCKED',
  Connected: 'CONNECTED',
  Impeded: 'IMPEDED',
  Reconnected: 'RECONNECTED',
  Degraded: 'DEGRADED',
  Disconnected: 'DISCONNECTED',
  Discontinued: 'DISCONTINUED',
  Closed: 'CLOSED',
  Delayed: 'DELAYED',
  Failed: 'FAILED',
  Updated: 'UPDATED',
  Disabled: 'DISABLED',
  Imported: 'IMPORTED',
  Resumed: 'RESUMED',
  Expired: 'EXPIRED',
  Impaired: 'IMPAIRED',
  Pending: 'PENDING'
} as const;

export type MxConnectionStatus = typeof MxConnectionStatus[keyof typeof MxConnectionStatus];





export type OtherDebtInput = {
  id?: Maybe<Scalars['UUID']>;
  entityName: Scalars['String'];
  accountNumber: Scalars['String'];
  description: Scalars['String'];
  billingAddress?: Maybe<AddressInput>;
  monthlyPayment: Scalars['Decimal'];
  lastPaymentDate?: Maybe<Scalars['ISODate']>;
  outstandingAmount: Scalars['Decimal'];
  hasNoCosigners?: Maybe<Scalars['Boolean']>;
  hasNoCollectors?: Maybe<Scalars['Boolean']>;
  delinquentAmount: Scalars['Decimal'];
  secured: Scalars['Boolean'];
  securedDescription?: Maybe<Scalars['String']>;
  wantToKeep?: Maybe<Scalars['Boolean']>;
};


export const OtherIncomeCategoryChoice = {
  Alimony: 'ALIMONY',
  ChildSupport: 'CHILD_SUPPORT',
  CapitalGains: 'CAPITAL_GAINS',
  FoodStamps: 'FOOD_STAMPS',
  RealEstate: 'REAL_ESTATE',
  Pension: 'PENSION',
  Disability: 'DISABILITY',
  Annuity: 'ANNUITY',
  Unemployment: 'UNEMPLOYMENT',
  MoneyOwed: 'MONEY_OWED',
  Other: 'OTHER'
} as const;

export type OtherIncomeCategoryChoice = typeof OtherIncomeCategoryChoice[keyof typeof OtherIncomeCategoryChoice];
export const OtherIncomeFrequencyChoice = {
  OneTime: 'ONE_TIME',
  Weekly: 'WEEKLY',
  Biweekly: 'BIWEEKLY',
  Monthly: 'MONTHLY',
  BiAnnually: 'BI_ANNUALLY',
  Annually: 'ANNUALLY'
} as const;

export type OtherIncomeFrequencyChoice = typeof OtherIncomeFrequencyChoice[keyof typeof OtherIncomeFrequencyChoice];
export type OtherIncomeInput = {
  id?: Maybe<Scalars['UUID']>;
  category: OtherIncomeCategoryChoice;
  frequency: OtherIncomeFrequencyChoice;
  amount: Scalars['Decimal'];
  description?: Maybe<Scalars['String']>;
};



export type OtherPersonInput = {
  id?: Maybe<Scalars['UUID']>;
  firstName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  suffix?: Maybe<Scalars['String']>;
  birthday: Scalars['ISODate'];
  mailingAddressId?: Maybe<Scalars['UUID']>;
  mailingAddress?: Maybe<AddressInput>;
  ssnItin?: Maybe<Scalars['String']>;
  hasSsnItin?: Maybe<Scalars['Boolean']>;
  isItin?: Maybe<Scalars['Boolean']>;
};



export type OwnedBusinessInput = {
  id?: Maybe<Scalars['UUID']>;
  businessName?: Maybe<Scalars['String']>;
  percentOwnership?: Maybe<Scalars['Decimal']>;
  lastTwelveMonthsGrossIncome?: Maybe<Scalars['Decimal']>;
  expectedGrossMonthlyIncome?: Maybe<Scalars['Decimal']>;
  monthlyEmployeePayroll?: Maybe<Scalars['Decimal']>;
  monthlyPayrollTaxes?: Maybe<Scalars['Decimal']>;
  monthlyUnemploymentTaxes?: Maybe<Scalars['Decimal']>;
  monthlyWorkersCompensation?: Maybe<Scalars['Decimal']>;
  monthlyOtherTaxes?: Maybe<Scalars['Decimal']>;
  monthlyInventoryPurchasesCost?: Maybe<Scalars['Decimal']>;
  monthlyFarmSuppliesCost?: Maybe<Scalars['Decimal']>;
  monthlyRent?: Maybe<Scalars['Decimal']>;
  monthlyUtilities?: Maybe<Scalars['Decimal']>;
  monthlyOfficeExpenses?: Maybe<Scalars['Decimal']>;
  monthlyRepairsMaintenance?: Maybe<Scalars['Decimal']>;
  monthlyVehicleExpenses?: Maybe<Scalars['Decimal']>;
  monthlyTravelEntertainment?: Maybe<Scalars['Decimal']>;
  monthlyEquipmentRental?: Maybe<Scalars['Decimal']>;
  monthlyProfessionalFees?: Maybe<Scalars['Decimal']>;
  monthlyInsurance?: Maybe<Scalars['Decimal']>;
  monthlyEmployeeBenefits?: Maybe<Scalars['Decimal']>;
  monthlyDebtPayments?: Maybe<Scalars['Decimal']>;
  businessDebtsDescription?: Maybe<Scalars['String']>;
  monthlyOtherExpenses?: Maybe<Scalars['Decimal']>;
  otherExpensesDescription?: Maybe<Scalars['String']>;
  hasNoPersonalSalaries?: Maybe<Scalars['Boolean']>;
};

export type OwnedVehicleInput = {
  id?: Maybe<Scalars['UUID']>;
  purchaseDate?: Maybe<Scalars['ISODate']>;
  currentValue: Scalars['Decimal'];
  wantToKeep: Scalars['Boolean'];
  hasNoCoowners?: Maybe<Scalars['Boolean']>;
  vehicle?: Maybe<VehicleInput>;
  vehicleId?: Maybe<Scalars['UUID']>;
};






export type PreviousNameInput = {
  id?: Maybe<Scalars['UUID']>;
  legalName: Scalars['String'];
  monthChanged?: Maybe<Scalars['ISODate']>;
};



export type RealEstateMortgageInput = {
  id?: Maybe<Scalars['UUID']>;
  property: RealEstateMortgagePropertyInput;
  lenderName: Scalars['String'];
  lenderAddress?: Maybe<AddressInput>;
  lenderAddressId?: Maybe<Scalars['UUID']>;
  loanNumber: Scalars['String'];
  originationDate: Scalars['ISODate'];
  outstandingBalance?: Maybe<Scalars['Decimal']>;
  delinquentBalance?: Maybe<Scalars['Decimal']>;
  lastPaymentDate: Scalars['ISODate'];
  monthlyPayment?: Maybe<Scalars['Decimal']>;
  hasNoCosigners?: Maybe<Scalars['Boolean']>;
  hasNoCollectors?: Maybe<Scalars['Boolean']>;
};

export type RealEstateMortgagePropertyInput = {
  id: Scalars['UUID'];
};




export type RealEstatePropertyInput = {
  id?: Maybe<Scalars['UUID']>;
  purchaseDate?: Maybe<Scalars['ISODate']>;
  purchasePrice?: Maybe<Scalars['Decimal']>;
  currentValue: Scalars['Decimal'];
  wantToKeep: Scalars['Boolean'];
  address?: Maybe<AddressInput>;
  addressId?: Maybe<Scalars['UUID']>;
  hasNoCoowners?: Maybe<Scalars['Boolean']>;
};

export type RealEstatePropertyLookupInput = {
  line1: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};



export type ReferredUserFilter = {
  /** Case insensitive, position insensitive filter for name */
  name?: Maybe<Scalars['String']>;
  /** Case insensitive, position insensitive filter for email */
  email?: Maybe<Scalars['String']>;
  completionStatus?: Maybe<ReferredUserStatus>;
};

export const ReferredUserStatus = {
  NotStarted: 'NOT_STARTED',
  InProgress: 'IN_PROGRESS',
  Completed: 'COMPLETED',
  LexriaReviewing: 'LEXRIA_REVIEWING',
  Abandoned: 'ABANDONED',
  QualificationReview: 'QUALIFICATION_REVIEW',
  Rejected: 'REJECTED'
} as const;

export type ReferredUserStatus = typeof ReferredUserStatus[keyof typeof ReferredUserStatus];

export type ResidentialAddressInput = {
  id?: Maybe<Scalars['UUID']>;
  residentFrom: Scalars['ISODate'];
  residentTo?: Maybe<Scalars['ISODate']>;
  address?: Maybe<AddressInput>;
  addressId?: Maybe<Scalars['UUID']>;
  livedWithSpouseCommunityProperty?: Maybe<Scalars['Boolean']>;
  isNotARenter: Scalars['Boolean'];
  landlordName?: Maybe<Scalars['String']>;
  landlordAddress?: Maybe<AddressInput>;
  landlordAddressId?: Maybe<Scalars['UUID']>;
  landlordHasSentEvictionNotice?: Maybe<Scalars['Boolean']>;
  landlordHasReceivedEvictionJudgement?: Maybe<Scalars['Boolean']>;
  delinquentRent?: Maybe<Scalars['Decimal']>;
};



export type RetirementAccountLoanInput = {
  id?: Maybe<Scalars['UUID']>;
  accountNumber: Scalars['String'];
  billingAddress?: Maybe<AddressInput>;
  monthlyPayment: Scalars['Decimal'];
  lastPaymentDate?: Maybe<Scalars['ISODate']>;
  outstandingAmount: Scalars['Decimal'];
  hasNoCollectors?: Maybe<Scalars['Boolean']>;
  retirementAccount: RetirementAccountLoanRetirementAccountInput;
  delinquentAmount: Scalars['Decimal'];
};

export type RetirementAccountLoanRetirementAccountInput = {
  id: Scalars['UUID'];
};


export type SeizureInput = {
  id?: Maybe<Scalars['UUID']>;
  creditorName: Scalars['String'];
  creditorAddress: AddressInput;
  itemTakenDate?: Maybe<Scalars['ISODate']>;
  itemValue: Scalars['Decimal'];
  itemDescription: Scalars['String'];
};


export type ServicePrepaymentInput = {
  id?: Maybe<Scalars['UUID']>;
  unusedPrepaymentAmount: Scalars['Decimal'];
  description?: Maybe<Scalars['String']>;
  serviceProviderName: Scalars['String'];
  prepaymentType: ServicePrepaymentTypeChoice;
  wantToKeep: Scalars['Boolean'];
};

export const ServicePrepaymentTypeChoice = {
  Electric: 'ELECTRIC',
  Gas: 'GAS',
  HeatingOil: 'HEATING_OIL',
  RentalSecurityDeposit: 'RENTAL_SECURITY_DEPOSIT',
  RentalPrepaidRent: 'RENTAL_PREPAID_RENT',
  Telephone: 'TELEPHONE',
  Water: 'WATER',
  RentedFurniture: 'RENTED_FURNITURE',
  PrepaidCard: 'PREPAID_CARD',
  Other: 'OTHER'
} as const;

export type ServicePrepaymentTypeChoice = typeof ServicePrepaymentTypeChoice[keyof typeof ServicePrepaymentTypeChoice];



export type SetCoownersipDetailsCoownerInput = {
  details: CoOwnerDetailsInput;
  person: Scalars['UUID'];
};


export type StateInput = {
  abbr: Scalars['String'];
};


export type StorageContainerInput = {
  id?: Maybe<Scalars['UUID']>;
  entityName: Scalars['String'];
  entityAddress: AddressInput;
  storageType: StorageContainerStorageTypeChoice;
  thirdPartyIdentifier: Scalars['String'];
  contentsDescription: Scalars['String'];
};

export const StorageContainerStorageTypeChoice = {
  StorageUnit: 'STORAGE_UNIT',
  DepositBox: 'DEPOSIT_BOX',
  Other: 'OTHER'
} as const;

export type StorageContainerStorageTypeChoice = typeof StorageContainerStorageTypeChoice[keyof typeof StorageContainerStorageTypeChoice];

export type StudentLoanInput = {
  id?: Maybe<Scalars['UUID']>;
  lenderName: Scalars['String'];
  lenderAddress: AddressInput;
  accountNumber?: Maybe<Scalars['String']>;
  _type: LoanType;
  category: LoanCategory;
  disbursedAmount: Scalars['Decimal'];
  outstandingPrincipal: Scalars['Decimal'];
  outstandingInterest: Scalars['Decimal'];
  loanDate: Scalars['ISODate'];
  lastPaymentDate?: Maybe<Scalars['ISODate']>;
  delinquentAmount?: Maybe<Scalars['Decimal']>;
  monthlyPayment: Scalars['Decimal'];
  interestRate?: Maybe<Scalars['Decimal']>;
  hasNoCosigners?: Maybe<Scalars['Boolean']>;
  fromNsldsImport?: Maybe<Scalars['Boolean']>;
  requiresStatement?: Maybe<Scalars['Boolean']>;
};


export type TaxDebtInput = {
  id?: Maybe<Scalars['UUID']>;
  entityName: Scalars['String'];
  accountNumber: Scalars['String'];
  contactAddress?: Maybe<AddressInput>;
  outstandingAmount: Scalars['Decimal'];
  isPaidThroughPlan: Scalars['Boolean'];
  hasNoCosigners?: Maybe<Scalars['Boolean']>;
  hasNoCollectors?: Maybe<Scalars['Boolean']>;
  taxReturn: TaxDebtTaxReturnInput;
};

export type TaxDebtTaxReturnInput = {
  id: Scalars['UUID'];
};


export type TaxReturnInput = {
  id?: Maybe<Scalars['UUID']>;
  returnYear?: Maybe<Scalars['Int']>;
  returnFiled?: Maybe<Scalars['Boolean']>;
  _type?: Maybe<TaxReturnTypeChoice>;
  otherDescription?: Maybe<Scalars['String']>;
  totalTaxesDue?: Maybe<Scalars['Decimal']>;
  totalIncome?: Maybe<Scalars['Decimal']>;
  adjustedGrossIncome?: Maybe<Scalars['Decimal']>;
  isOwingMoney?: Maybe<Scalars['Boolean']>;
  isCofiled?: Maybe<Scalars['Boolean']>;
  isPrimaryFiler?: Maybe<Scalars['Boolean']>;
  otherFiler?: Maybe<OtherPersonInput>;
  otherFilerId?: Maybe<Scalars['UUID']>;
};

export const TaxReturnTypeChoice = {
  FederalIncome: 'FEDERAL_INCOME',
  StateIncome: 'STATE_INCOME',
  LocalIncome: 'LOCAL_INCOME',
  Other: 'OTHER'
} as const;

export type TaxReturnTypeChoice = typeof TaxReturnTypeChoice[keyof typeof TaxReturnTypeChoice];




export type VehicleInput = {
  id?: Maybe<Scalars['UUID']>;
  year?: Maybe<Scalars['Int']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
};


export type VehicleLeaseInput = {
  id?: Maybe<Scalars['UUID']>;
  leaseholderName: Scalars['String'];
  accountNumber: Scalars['String'];
  billingAddress?: Maybe<AddressInput>;
  monthlyPayment: Scalars['Decimal'];
  lastPaymentDate?: Maybe<Scalars['ISODate']>;
  payoffAmount: Scalars['Decimal'];
  wantToKeep: Scalars['Boolean'];
  hasNoCosigners?: Maybe<Scalars['Boolean']>;
  hasNoCollectors?: Maybe<Scalars['Boolean']>;
  vehicle: VehicleInput;
  delinquentAmount: Scalars['Decimal'];
};


export type VehicleLoanInput = {
  id?: Maybe<Scalars['UUID']>;
  lenderName: Scalars['String'];
  accountNumber: Scalars['String'];
  billingAddress?: Maybe<AddressInput>;
  monthlyPayment: Scalars['Decimal'];
  lastPaymentDate?: Maybe<Scalars['ISODate']>;
  outstandingAmount: Scalars['Decimal'];
  isSecured?: Maybe<Scalars['Boolean']>;
  hasNoCosigners?: Maybe<Scalars['Boolean']>;
  hasNoCollectors?: Maybe<Scalars['Boolean']>;
  vehicle: VehicleLoanVehicleInput;
  delinquentAmount: Scalars['Decimal'];
};

export type VehicleLoanVehicleInput = {
  id: Scalars['UUID'];
};

