import { client } from "api/graphql/client";
import { useListMailingAddresses } from "api/graphql/MailingAddress";
import UPDATE_OTHER_PERSON from "api/graphql/OtherPerson/UpdateOtherPerson.gql";
import { AddressForm, AddressSchema } from "components/FormSections/Address";
import GetOrCreate from "components/GetOrCreate";
import { HiddenInput } from "components/HiddenInput";
import { Title } from "components/Title";
import { WizardStepProps } from "components/Wizard";
import { omit } from "lodash";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa";
import { Link, Route, Switch } from "react-router-dom";
import { formatGraphAddress, formatIndefinite } from "utils/formatters";
import { useNested } from "utils/hooks";
import { unnestIds } from "utils/ids";
import stripTypename from "utils/tests/apollo/stripTypename";
import { object, string } from "yup";

export class MailingAddress implements WizardStepProps<any, any> {
  otherPerson: Partial<FullOtherPerson>;
  relatedDisplayName: string;

  constructor(
    otherPerson: Partial<FullOtherPerson>,
    context: { relatedDisplayName: string }
  ) {
    this.otherPerson = otherPerson;
    this.relatedDisplayName = context.relatedDisplayName;
  }

  path = "/mailing_address";
  includeSubpaths = true;
  title = "Mailing Address";
  schema = object({
    mailingAddressId: string().when("mailingAddress", {
      is: (v) => !v,
      then: string().required(
        "You must select an existing address or provide a new address."
      ),
      otherwise: string().optional().nullable(),
    }),
    mailingAddress: AddressSchema.nullable(),
  });

  component = () => {
    const { url, path } = useNested();
    const mailingOptions = useListMailingAddresses();
    const form = useFormContext();
    const initialValue =
      form.getValues("mailingAddressId") || this.otherPerson.mailingAddress?.id;

    return (
      <>
        <Title>Add Mailing Address</Title>
        <p>
          In order to add {this.otherPerson.firstName || "this person"} as{" "}
          {formatIndefinite(this.relatedDisplayName)}, we need to know their
          mailing address.
        </p>
        <Switch>
          <Route path={path("/create_address")}>
            <AddressForm graphQL name="mailingAddress" />
            <Link to={url("/")} className="flat primary btn">
              <FaChevronLeft />
              <span>Select existing mailing address</span>
            </Link>
          </Route>
          <Route>
            <>
              <GetOrCreate
                name="mailingAddressId"
                objectName="mailing address"
                createURL={url("/create_address")}
                initialValue={initialValue}
                loading={mailingOptions.loading}
                options={mailingOptions.data?.mailingAddresses.map((a) => ({
                  value: a.id,
                  label: formatGraphAddress(a),
                }))}
              />
              <HiddenInput name="mailingAddress" value={null} />
            </>
          </Route>
        </Switch>
      </>
    );
  };
  onSave = async (formState: any) => {
    const input = {
      ...unnestIds(
        stripTypename(omit(this.otherPerson, "hasSsnItin", "isItin"))
      ),
      ...unnestIds(formState),
    };

    const update = await client.mutate({
      mutation: UPDATE_OTHER_PERSON,
      variables: {
        id: this.otherPerson.id,
        input,
      },
    });

    if (!update.data) {
      return formState;
    }

    return unnestIds(update.data.otherPerson);
  };
  navigateAfterSave = true;
}

export const existingObjectRequiredDataSchema = object({
  mailingAddress: object().required(),
});

export default {
  step: MailingAddress,
  existingObjectRequiredDataSchema,
};
