import { useQuery } from "@apollo/client";
import { useUpsertIdentityDocument } from "api/graphql/FilingPerson";
import cn from "classnames";
import { ButtonRow } from "components/ButtonRow";
import { Card } from "components/Card";
import { ControlledFileInput } from "components/FileInput";
import { Form } from "components/Form";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import { last, omit } from "lodash";
import React from "react";
import { useForm } from "react-hook-form";
import { FaCheck } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useCheckMounted } from "utils/hooks";
import { mixed, object } from "yup";
import GET_PHOTO_ID from "./GetPhotoId.gql";

interface FormProps {
  next?: string;
  prev?: string;
}

const FormSchema = object({
  idDoc: mixed<File | UploadedFile>()
    .required("Please upload a copy of your photo ID document.")
    .test(
      "non-empty",
      "Please upload a copy of your photo ID document.",
      (value) => {
        return !!value;
      }
    ),
});

type FormState = ReturnType<typeof FormSchema.validateSync>;

export function PhotoId({ prev, next }: FormProps) {
  const { data, loading } = useQuery<GetPhotoIdDocument>(GET_PHOTO_ID);
  const [upsertIdentityDocument] = useUpsertIdentityDocument();
  const [saving, setSaving] = React.useState(false);
  const checkMounted = useCheckMounted();
  const history = useHistory();
  const initialValue = data?.filingPerson?.idDoc;
  const form = useForm({ defaultValues: data?.filingPerson });
  // TODO: remove the any once I figure out how to type this
  const uploadFile: any = form.watch("idDoc") || initialValue;

  const onSubmit = React.useCallback(
    async (formState) => {
      setSaving(true);
      try {
        let vars = formState;
        if (!(formState.idDoc instanceof File)) {
          vars = omit(formState, ["idDoc"]);
        }
        await upsertIdentityDocument(vars);
        if (next) history.push(next);
      } catch (e) {
        console.error(e);
      } finally {
        if (checkMounted()) {
          setSaving(false);
        }
      }
    },
    [upsertIdentityDocument, data, loading]
  );

  if (loading) return <Loading />;

  return (
    <>
      <Title>Your Identity Documentation</Title>
      <Card>
        <p>Help us out by providing a state issued photo ID.</p>
        <p>Acceptable examples include but are not limited to:</p>
        <ul>
          <li>Driver&rsquo;s License</li>
          <li>Passport</li>
          <li>Government employee ID</li>
          <li>Native American Tribal ID</li>
        </ul>
        <Form useForm={form} onSubmit={onSubmit}>
          <ControlledFileInput
            accept="image/jpeg, image/png, application/pdf"
            name="idDoc"
            fileName={last(uploadFile?.name?.split("/")) || undefined}
            downloadUrl={uploadFile?.url || undefined}
            multiple={false}
            helpText={last(uploadFile?.name.split("/")) || undefined}
          />

          <ButtonRow
            left={null}
            right={
              <button className={cn("btn primary", { loading: saving })}>
                <span>Save</span>
                <FaCheck />
              </button>
            }
          />
        </Form>
      </Card>
    </>
  );
}
