import {
  ConsultationTypeChoice,
  FinancialAccountTypeChoice,
  FinancialInvestmentTypeChoice,
  TaxReturnTypeChoice,
} from "globalTypes";
import { capitalize } from "lodash";

export const formatTaxReturnName = (taxReturn: {
  returnFiled: boolean;
  returnYear: number;
  _type: TaxReturnTypeChoice;
}) => {
  const prettyReturnFiled = !taxReturn.returnFiled ? "unfiled " : "";
  return capitalize(
    `${prettyReturnFiled}${taxReturn._type.replace("_", " ")} taxes in ${
      taxReturn.returnYear
    }`
  );
};

export const formatVehicleName = (vehicle: {
  make?: string;
  model?: string;
  year?: number;
}) => {
  return `${vehicle.year} ${vehicle.make} ${vehicle.model}`
    .replace(/\s+/g, " ")
    .trim();
};

export function formatFinancialAccountType(
  type: FinancialAccountTypeChoice
): string {
  switch (type) {
    case FinancialAccountTypeChoice.Checking:
      return "Checking";
    case FinancialAccountTypeChoice.Savings:
      return "Savings";
    case FinancialAccountTypeChoice.Retirement:
      return "Retirement";
    case FinancialAccountTypeChoice.InvestmentOther:
      return "Investment";
    case FinancialAccountTypeChoice.InvestmentEducational:
      return "Educational Savings";
    case FinancialAccountTypeChoice.MortgageEscrow:
      return "Mortage Escrow";
    case FinancialAccountTypeChoice.Hsa:
      return "HSA";
    case FinancialAccountTypeChoice.Other:
      return "Other";
  }
}

export function formatFinancialInvestmentType(
  type: FinancialInvestmentTypeChoice
): string {
  switch (type) {
    case FinancialInvestmentTypeChoice.Annuity:
      return "Annuity";
    case FinancialInvestmentTypeChoice.Bond:
      return "Bond";
    case FinancialInvestmentTypeChoice.Cash:
      return "Cash";
    case FinancialInvestmentTypeChoice.CertificateOfDeposit:
      return "Certificate of deposit";
    case FinancialInvestmentTypeChoice.InstrumentNegotiable:
      return "Undeposited payment";
    case FinancialInvestmentTypeChoice.IntellectualProperty:
      return "Intellectual property";
    case FinancialInvestmentTypeChoice.InvestmentFund:
      return "Investment fund";
    case FinancialInvestmentTypeChoice.LicenseOrFranchise:
      return "Franchise or business license";
    case FinancialInvestmentTypeChoice.Prepayment:
      return "Security deposit or prepayment";
    case FinancialInvestmentTypeChoice.Other:
      return "Other";
    case FinancialInvestmentTypeChoice.Royalty:
      return "Royalty";
    case FinancialInvestmentTypeChoice.StockPublic:
      return "Public stock";
    case FinancialInvestmentTypeChoice.StockPrivate:
      return "Private stock";
    case FinancialInvestmentTypeChoice.Trust:
      return "Trust";
  }
}

export function formatConsultationType(type: ConsultationTypeChoice): string {
  switch (type) {
    case ConsultationTypeChoice.DebtSettlement:
      return "Debt settlement";
    case ConsultationTypeChoice.LegalBankruptcyAdvice:
      return "Bankruptcy legal advice";
  }
}
