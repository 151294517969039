import { useGetServicePrepayment } from "api/graphql/ServicePrepayment";
import { Loading } from "components/Loading";
import { ServicePrepaymentTypeChoice } from "globalTypes";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { ServicePrepaymentWizard } from "./ServicePrepaymentWizard";

interface Props {
  id: string;
  listURL: string;
  editURL?: string;
}

export function EditView({ id, listURL, editURL }: Props) {
  const { data, loading } = useGetServicePrepayment(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.servicePrepayment) {
    return <Redirect to={listURL} />;
  }

  const prepayment = data.servicePrepayment;
  const displayType =
    prepayment.prepaymentType ===
    ServicePrepaymentTypeChoice.RentalSecurityDeposit
      ? "Security deposit"
      : "Pre-payment";

  return (
    <ServicePrepaymentWizard
      title={`Updating ${displayType}`}
      backURL={listURL}
      nextURL={listURL}
      editURL={editURL}
      initialValue={prepayment}
    />
  );
}
