import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import {
  createCacheListUpdaters,
  useDelete,
  useUpsert,
} from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_VEHICLE_LOANS from "./DeleteVehicleLoan.gql";
import GET_VEHICLE_LOANS from "./GetVehicleLoan.gql";
import LIST_VEHICLE_LOANS from "./ListVehicleLoan.gql";
import UPSERT_VEHICLE_LOANS from "./UpsertVehicleLoan.gql";

const LIST_VEHICLE_LOANS_IDS = gql`
  query ListVehicleLoanIds {
    vehicleLoans {
      id
    }
  }
`;

export const [
  onVehicleLoanAdded,
  onVehicleLoanRemoved,
] = createCacheListUpdaters<UpsertVehicleLoan["vehicleLoan"]>(
  LIST_VEHICLE_LOANS_IDS,
  "vehicleLoans"
);

export function useListVehicleLoan(
  options?: QueryHookOptions<ListVehicleLoan>
) {
  return useQuery<ListVehicleLoan>(LIST_VEHICLE_LOANS, options);
}

export function useGetVehicleLoan(id: string) {
  return useQuery<GetVehicleLoan, GetVehicleLoanVariables>(GET_VEHICLE_LOANS, {
    variables: { id },
  });
}

export function useUpsertVehicleLoan<
  Schema extends { vehicleLoan: any } = UpsertVehicleLoan,
  Variables = UpsertVehicleLoanVariables
>(query = UPSERT_VEHICLE_LOANS) {
  return useUpsert<Schema, Variables, ListVehicleLoanIds, VehicleLoanInput>(
    query,
    LIST_VEHICLE_LOANS_IDS,
    "vehicleLoan",
    "vehicleLoans",
    (cache) => updateFilingProfileCache(cache, "hasNoVehicleLoans", false)
  );
}

export function useDeleteVehicleLoan() {
  return useDelete<DeleteVehicleLoan, ListVehicleLoanIds>(
    DELETE_VEHICLE_LOANS,
    LIST_VEHICLE_LOANS_IDS,
    "deleteVehicleLoan",
    "vehicleLoans"
  );
}
