import { useUpsertVehicleTitle } from "api/graphql/VehicleTitles";
import { Wizard } from "components/Wizard";
import {
  CoOwnersSchema,
  CoownersWizardStepProps,
  useDelegatedOnSave,
} from "components/WizardSteps/Coowners";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useNested } from "utils/hooks";
import Coowners from "./Coowners";
import Vehicle, { OwnedVehicleSchema } from "./Vehicle";

export const VehicleTitleSchema = OwnedVehicleSchema.concat(CoOwnersSchema);

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
}

export function VehicleWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: Props) {
  const { url } = useNested();
  const [upsertVehicleTitle] = useUpsertVehicleTitle();
  const history = useHistory();
  const [vehicleId, setVehicleId] = useState(initialValue?.id);
  const location = useLocation();
  const [registerOnSaveCoowners, onSaveCoowners] = useDelegatedOnSave();

  const coownersURL = "/coowners";

  return (
    <Wizard
      title={title}
      backURL={backURL}
      nextURL={nextURL}
      initialValue={
        initialValue && {
          ...initialValue,
          year: initialValue.year && String(initialValue.year),
          mileage: initialValue.mileage && String(initialValue.mileage),
        }
      }
      vertical
      steps={[
        {
          path: "/vehicle",
          title: "Vehicle",
          component: Vehicle,
          schema: OwnedVehicleSchema,
          async onSave(formState) {
            const { data } = await upsertVehicleTitle(formState);
            const { id } = data.ownedVehicle;
            setVehicleId(id);
            if (vehicleId) {
              setTimeout(() => history.push(url(coownersURL)), 1);
            } else {
              const isVehiclePrimaryObject = location.pathname.startsWith(
                "/new-filing/section/assets/vehicle"
              );
              const editUrlPathname = isVehiclePrimaryObject
                ? "edit"
                : "edit_vehicle";
              setTimeout(
                () => history.push(`../${editUrlPathname}/${id}${coownersURL}`),
                1
              );
            }
            return data.ownedVehicle;
          },
        },
        {
          path: coownersURL,
          title: "Co-owners",
          component: React.useCallback(
            (props: CoownersWizardStepProps) => (
              <Coowners {...props} registerOnSave={registerOnSaveCoowners} />
            ),
            [registerOnSaveCoowners]
          ),
          schema: CoOwnersSchema,
          includeSubpaths: true,
          onSave: onSaveCoowners,
        },
      ]}
    />
  );
}
