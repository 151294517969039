import cn from "classnames";
import { extent, scaleLinear } from "d3";
import { dropRightWhile, dropWhile, first, last } from "lodash";
import * as React from "react";
import { useCssVariable } from "utils/hooks/useCssVariable";
import { toPercent } from "utils/math";
import { onlyText } from "utils/react";
import { useAdaptiveSliderBounds } from "./bounds";
import { useDragControl, useKeyboardControl } from "./inputControls";
import { SnappingPointDefinition, useSnapping } from "./snapping";
import css from "./style.module.css";
import { useTicks } from "./ticks";

export interface SliderProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  ticks?: number[];
  snappingEnabled?: boolean;
  snappingPoints?: SnappingPointDefinition[];
  snappingTolerance?: number;
  showSnappingGuides?: boolean;
  disabled?: boolean;
  onActive?: (isActive: boolean) => void;
  highlightColor?: string;
  secondaryColor?: string;
}

/**
 * A controlled, snappable range component.
 *
 * - The `min`, `max` and `step` props all closely follow the html5 rang input api
 *
 * - `ticks` are similar to the html5 `datalist` property, but are an array of numbers
 * that define allowable slider values. Ticks are deferred to if provided, rather than
 * creating a range based on step size.
 *
 * - `snappingEnabled` toggles the snapping behavior of the range. Disabled, it's basically
 * just a normal range input.
 *
 * - `snappingPoints` are special values that are "sticky" within a certain configurable
 * tolerance. They can either be an array of `number`s, or an array of `{ value: number | ({ min, max, step }) => number; label: string }`
 * objects, allowing for custom snapping labels, and snapping points that will be computed
 * if the slider bounds change (i.e. add a snapping point at 3/4 of the way between `min` and `max`)
 *
 * - `snappingTolerance` is a the distance in pixels around the point that is considered "sticky", and will
 * capture and snap the slider thumb to that value.
 *
 * - `showSnappingGuides` programatically keeps the snapping labels and indicators active even if the user is not
 * interacting with the component.
 */
export function Slider({
  value,
  onChange,
  min: controlledMin = 0,
  max: controlledMax = 100,
  step = 1,
  snappingEnabled = true,
  snappingPoints = [],
  snappingTolerance = 10,
  showSnappingGuides,
  ticks: controlledTicks,
  disabled = false,
  onActive,
  highlightColor,
  secondaryColor,
  className,
  ...divProps
}: SliderProps) {
  const sliderRef = React.useRef<HTMLDivElement>(null);

  const [min, max] = controlledTicks
    ? extent(controlledTicks)
    : [controlledMin, controlledMax];

  const [active, setActive] = React.useState(false);
  const [lastActive, setLastActive] = React.useState(false);
  React.useEffect(() => setLastActive(active), [active]);
  React.useEffect(() => {
    if (onActive && active !== lastActive) onActive(active);
  }, [active, lastActive, onActive]);

  useAdaptiveSliderBounds({ value, min, max, onChange });

  const cssHighlightColor = useCssVariable(sliderRef, "highlight-color");
  React.useEffect(() => {
    cssHighlightColor.current = highlightColor;
  }, [highlightColor]);

  const cssSecondaryColor = useCssVariable(sliderRef, "secondary-color");
  React.useEffect(() => {
    cssSecondaryColor.current = secondaryColor;
  }, [secondaryColor]);

  const { snappingValues, snapLabel, isSnapped, snapScaledValue } = useSnapping(
    {
      snappingEnabled,
      snappingPoints,
      value,
      min,
      max,
      step,
    }
  );

  const { ticks, alignDataValueToTick } = useTicks({
    min,
    max,
    step,
    controlledTicks,
    otherValuesToInclude: snappingValues,
  });

  const cssPct = useCssVariable(sliderRef, "progress-percent", "%");
  React.useEffect(() => {
    cssPct.current = toPercent(value, min, max);
  }, [value, min, max]);

  const { onMouseDown, onTouchStart } = useDragControl({
    ref: sliderRef,
    onChange: React.useCallback(
      (pixelValue, [left, right]) => {
        const dataToPixels = scaleLinear()
          .clamp(true)
          .domain([min, max])
          .range([left, right]);
        const snappedValue = snapScaledValue(
          pixelValue,
          dataToPixels,
          snappingTolerance
        );
        onChange(
          !isNaN(snappedValue)
            ? snappedValue
            : alignDataValueToTick(pixelValue, dataToPixels)
        );
      },
      [onChange, snapScaledValue, alignDataValueToTick, min, max]
    ),
    onActive: setActive,
  });

  const { onKeyDown } = useKeyboardControl({
    value,
    ticks,
    onChange: React.useCallback(
      (nextValue, direction, e) => {
        if (e.shiftKey) {
          const compare =
            direction === "increasing"
              ? (a: number, b: number) => a <= b
              : (a: number, b: number) => a >= b;

          const fallback = direction === "increasing" ? max : min;

          const drop = direction === "increasing" ? dropWhile : dropRightWhile;

          const extract = direction === "increasing" ? first : last;

          const nextSnap = extract(
            drop(snappingValues, (val) => compare(val, nextValue))
          );

          onChange(nextSnap !== undefined ? nextSnap : fallback);
        } else {
          onChange(nextValue);
        }
      },
      [onChange, snappingValues, max, min]
    ),
  });

  return (
    <div
      ref={sliderRef}
      className={cn(
        css.slider,
        {
          [css.active]: active,
          [css.snapped]: isSnapped,
          [css.disabled]: disabled,
          [css["snap-visible"]]:
            showSnappingGuides === undefined ? active : showSnappingGuides,
        },
        className
      )}
      tabIndex={0}
      {...divProps}
      onKeyDown={React.useCallback(
        (e) => {
          onKeyDown(e);
          divProps.onKeyDown?.(e);
        },
        [onKeyDown, divProps.onKeyDown]
      )}
      onFocus={React.useCallback(
        (e) => {
          setActive(true);
          divProps.onFocus?.(e);
        },
        [setActive, divProps.onFocus]
      )}
      onBlur={React.useCallback(
        (e) => {
          setActive(false);
          divProps.onBlur?.(e);
        },
        [setActive, divProps.onBlur]
      )}
    >
      <div className={css["snap-indicator"]}>{snapLabel}</div>
      <div
        className={css.track}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
      >
        <div className={css.background} />
        <div className={css["snap-guides"]}>
          {snappingValues.map((value, i) => (
            <div
              key={i}
              className={css.guide}
              style={{
                left: `${toPercent(value, min, max)}%`,
              }}
            />
          ))}
        </div>
        <div className={css.highlight} />
        <div
          className={css.thumb}
          role="slider"
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={value}
          aria-valuetext={snapLabel ? onlyText(snapLabel) : undefined}
        />
      </div>
    </div>
  );
}
