import DollarsInput from "components/DollarsInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";

export default function Facilities({ navProps }: WizardRouteChildProps) {
  return (
    <>
      <p>In the average month, how much does this company spend on:</p>

      <div className="form-row">
        <DollarsInput
          name="monthlyRent"
          label="Rent (for offices, storage, etc.)"
          helpText="Exclude rent for your primary residence"
          autoFocus
        />
        <DollarsInput
          name="monthlyUtilities"
          label="Utilities for company spaces"
        />
      </div>

      <div className="form-row">
        <DollarsInput
          name="monthlyOfficeExpenses"
          label="Office expenses & supplies"
        />
        <DollarsInput
          name="monthlyRepairsMaintenance"
          label="Repairs & Maintenance"
        />
      </div>

      <WizardNavigation {...navProps} />
    </>
  );
}
