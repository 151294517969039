import { ScaleContinuousNumeric } from "d3";
import { reduceRight } from "lodash";
import * as React from "react";
import {
  fuzzyEquals,
  fuzzyFindIndex,
  fuzzyGet,
  fuzzyGTE,
  fuzzyIncludes,
  fuzzyLTE,
  FUZZY_EPSILON,
  roundToTwoDecimalPlaces,
} from "utils/math";

const genericSnap = <Point>(
  measureDistance: (a: Point, b: Point) => number
) => (anchor: Point) => (value: Point, threshold = 0) =>
  measureDistance(anchor, value) <= threshold ? anchor : value;

const snapToPoint = genericSnap((a: number, b: number) => Math.abs(b - a));

const snapToPoints = (points: number[]) => (value: number, threshold = 0) =>
  reduceRight(
    points.map(snapToPoint),
    (snapped, snap) => snap(snapped, threshold),
    value
  );

export type SnappingPoint = { value: number; label: React.ReactNode };

export type SnappingPointDefinition =
  | {
      value:
        | number
        | (({
            min,
            max,
            step,
          }: {
            min: number;
            max: number;
            step?: number;
          }) => number);
      label?: React.ReactNode;
    }
  | number;

export const defaultSnappingPointDefinitions: SnappingPointDefinition[] = [
  {
    value: ({ min, max }: { min: number; max: number }) =>
      roundToTwoDecimalPlaces(min + (1 / 4) * (max - min)),
    label: "¼",
  },
  {
    value: ({ min, max }: { min: number; max: number }) =>
      roundToTwoDecimalPlaces(min + (1 / 3) * (max - min)),
    label: "⅓",
  },
  {
    value: ({ min, max }: { min: number; max: number }) =>
      roundToTwoDecimalPlaces(min + (1 / 2) * (max - min)),
    label: "½",
  },
  {
    value: ({ min, max }: { min: number; max: number }) =>
      roundToTwoDecimalPlaces(min + (2 / 3) * (max - min)),
    label: "⅔",
  },
  {
    value: ({ min, max }: { min: number; max: number }) =>
      roundToTwoDecimalPlaces(min + (3 / 4) * (max - min)),
    label: "¾",
  },
];

export const computeSnappingPoints = (
  { min, max }: { min: number; max: number },
  definitions = defaultSnappingPointDefinitions
) =>
  definitions.map((def) => {
    const value =
      typeof def === "number"
        ? def
        : typeof def.value === "number"
        ? def.value
        : def.value({ min, max });
    const label =
      typeof def === "number"
        ? def
        : def.label === undefined
        ? value
        : def.label;

    return { value, label };
  });

export interface UseSnappingArgs {
  snappingEnabled?: boolean;
  snappingPoints?: SnappingPointDefinition[];
  snappingTolerance?: number;
  value: number;
  min: number;
  max: number;
  step: number;
  EPSILON?: number;
}

export function useSnapping({
  snappingEnabled = true,
  snappingPoints = defaultSnappingPointDefinitions,
  snappingTolerance = FUZZY_EPSILON,
  value,
  min,
  max,
  step,
  EPSILON = FUZZY_EPSILON,
}: UseSnappingArgs) {
  const [isSnapped, setIsSnapped] = React.useState(false);
  const [snappedValue, setSnappedValue] = React.useState(NaN);
  const [snapLabel, setSnapLabel] = React.useState<React.ReactNode>(null);

  const snappingValues = React.useMemo(
    () =>
      snappingPoints
        .map((point) => {
          let value;
          if (typeof point === "number") {
            value = point;
          } else if (typeof point.value === "number") {
            value = point.value;
          } else {
            value = point.value({ min, max, step });
          }
          return value;
        })
        .filter(
          (value) =>
            fuzzyLTE(value, max, EPSILON) && fuzzyGTE(value, min, EPSILON)
        ),
    [min, max, step, snappingPoints, EPSILON]
  );

  React.useEffect(() => {
    if (!snappingEnabled) {
      if (!isNaN(snappedValue)) setSnappedValue(NaN);
      if (snapLabel) setSnapLabel(null);
      if (isSnapped) setIsSnapped(false);
      return;
    }

    const nextSnappedValue = snapToPoints(snappingValues)(
      value,
      snappingTolerance
    );
    const nextIsSnapped = fuzzyIncludes(snappingValues, value, EPSILON);

    if (!fuzzyEquals(snappedValue, nextSnappedValue, EPSILON))
      setSnappedValue(nextSnappedValue);
    if (isSnapped !== nextIsSnapped) setIsSnapped(nextIsSnapped);

    const snapDefinition =
      snappingPoints[fuzzyFindIndex(snappingValues, nextSnappedValue, EPSILON)];
    const nextSnapLabel =
      !nextIsSnapped || !snapDefinition || max === min
        ? null
        : typeof snapDefinition === "number"
        ? snapDefinition
        : snapDefinition?.label || null;

    if (snapLabel !== nextSnapLabel) setSnapLabel(nextSnapLabel);
  }, [
    value,
    snappingEnabled,
    snappingValues,
    snappingTolerance,
    snapLabel,
    snappedValue,
    max,
    min,
    isSnapped,
    EPSILON,
  ]);

  const snapScaledValue = React.useCallback(
    (
      valueToSnap: number,
      scale?: ScaleContinuousNumeric<number, number>,
      tolerance = snappingTolerance
    ): number => {
      if (!snappingEnabled) return NaN;

      const searchValues = scale ? snappingValues.map(scale) : snappingValues;
      const snappedPoint = snapToPoints(searchValues)(valueToSnap, tolerance);
      const snappedDataPoint = scale
        ? scale.invert(snappedPoint)
        : snappedPoint;
      return fuzzyGet(snappingValues, snappedDataPoint, NaN, EPSILON);
    },
    [snappingValues, snappingTolerance, snappingEnabled, EPSILON]
  );

  return React.useMemo(
    () => ({
      snappedValue,
      isSnapped,
      snapLabel,
      snappingValues: snappingEnabled ? snappingValues : [],
      snapScaledValue,
    }),
    [
      snappedValue,
      isSnapped,
      snapLabel,
      snappingValues,
      snappingEnabled,
      snapScaledValue,
    ]
  );
}
