import * as React from "react";

export function LearnMoreCommon() {
  return (
    <>
      <h4>
        Why do you need to know about my household items and personal property?
      </h4>
      <p>
        Your lawyer will need to provide the court a detailed list of all the
        property that you or any members of your household own. This information
        helps your lawyer determine which bankruptcy exemptions you may be
        eligible for and, therefore, which property you are able to safeguard
        from your creditors.
      </p>
      <h4>What are bankruptcy exemptions?</h4>
      <p>
        Exemptions are laws that allow you to keep certain property by shielding
        it from claims by your creditors. These laws protect you during — and
        after — bankruptcy. To use the exemptions, it’s important that you list
        all property that you own when you file your petition with the court.{" "}
        <strong>
          If you fail to list property on your schedules, you generally won’t be
          able to protect that property — even if it would otherwise be eligible
          for an exemption.
        </strong>
        .
      </p>
      <h4>Does this mean I will lose my property if I list it?</h4>
      <p>
        No, the bankruptcy exemption laws determine which property you can keep.
        Although rules vary by state, it’s worth noting that in many chapter 7
        bankruptcy cases the petitioner got to keep <strong>all</strong> of
        their claimed property in what’s known as a no-asset case [
        <a
          href="https://www.abi.org/abi-journal/chapter-7-asset-cases"
          target="_blank"
          rel="noopener noreferrer"
        >
          source
        </a>
        ].
      </p>
      <p>
        Whatever your situation, your lawyer can only help you maximize your
        exemptions if they have a complete and accurate picture of your property
        and assets.
      </p>
      <h4>Which sorts of property do I need to list?</h4>
      <p>
        You must list all property in which you have any ownership interest.
      </p>
      <h4>Can I combine multiple items within a category?</h4>
      <p>
        Please list as many items as possible separately as you can. If you wish
        to combine items within a category, list it as “misc” and provide the
        total value of the items in question.
      </p>
      <h4>What if I’m unsure about an item’s value?</h4>
      <p>
        If you could sell the item for $500 or more, estimate and include the
        sale value of the item. If you’re unsure about the sale value of an
        item, ask an appraiser or consult a pawn shop. If your item has no
        resale value, put down $0.
      </p>
      <h4>What if I’m unsure about listing an item?</h4>
      <p>
        {/* https://www.masslegalhelp.org/consumer/bankruptcy/workbook/property/schedule-ab-property */}
        You should add any items that you are unsure about. Your lawyer needs to
        know about all the property you own, so it’s better to be as detailed as
        possible.
      </p>
    </>
  );
}
