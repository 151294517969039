import { DateInput } from "components/DateInput";
import { DecimalInput } from "components/NumberInput";
import { RadioGroupInput } from "components/RadioGroupInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { DateSchema, DecimalSchema } from "utils/validators/yup";
import { object, string } from "yup";

export const FinancialDetailsSchema = object({
  _payment: string().oneOf(["pay", "receive", "none"]).nullable(),
  amountReceived: DecimalSchema.nullable(),
  amountPaid: DecimalSchema.nullable(),
  amountOwed: DecimalSchema.nullable(),
  paymentDate: DateSchema.nullable(),
}).required();

export function FinancialDetails({ isEdit, navProps }: WizardRouteChildProps) {
  const form = useFormContext();
  return (
    <>
      <div className="form-row">
        <RadioGroupInput
          label="Did you pay or receive money in this lawsuit?"
          options={[
            { label: "I paid money", value: "pay" },
            { label: "I received money", value: "receive" },
            { label: "Neither", value: "none" },
          ]}
          name="_payment"
          defaultValue="none"
        />

        <div
          style={{
            display:
              form.watch("_payment") && form.watch("_payment") !== "none"
                ? "block"
                : "none",
          }}
        >
          <div
            style={{
              display: form.watch("_payment") === "receive" ? "block" : "none",
            }}
          >
            <DecimalInput
              name="amountReceived"
              label="Amount received"
              addOnBefore="$"
              allowDecimal
              includeThousandsSeparator
            />
          </div>
          <div
            style={{
              display: form.watch("_payment") === "pay" ? "block" : "none",
            }}
          >
            <DecimalInput
              name="amountPaid"
              label="Amount paid"
              addOnBefore="$"
              allowDecimal
              includeThousandsSeparator
            />
            <DecimalInput
              name="amountOwed"
              label="Amount still owed"
              addOnBefore="$"
              allowDecimal
              includeThousandsSeparator
            />
          </div>
          <DateInput name="paymentDate" label="Payment date" />
        </div>
      </div>
      <WizardNavigation {...navProps} />
    </>
  );
}
