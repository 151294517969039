import { useQuery, QueryHookOptions } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDeleteEvict, useUpsertModify } from "api/graphql/utils";
import DELETE_GOVERNMENT_DISBURSEMENT from "./DeleteGovernmentDisbursement.gql";
import GET_GOVERNMENT_DISBURSEMENT from "./GetGovernmentDisbursement.gql";
import LIST_GOVERNMENT_DISBURSEMENT_BY_CATEGORY from "./ListGovernmentDisbursementFilterByCategory.gql";
import UPSERT_GOVERNMENT_DISBURSEMENT from "./UpsertGovernmentDisbursement.gql";
import { GovernmentDisbursementCategoryChoice } from "globalTypes";

export function useListGovernmentDisbursement(
  options?: QueryHookOptions<
    ListGovernmentDisbursementFilterByCategory,
    ListGovernmentDisbursementFilterByCategoryVariables
  >
) {
  return useQuery(LIST_GOVERNMENT_DISBURSEMENT_BY_CATEGORY, {
    ...options,
    fetchPolicy: "network-only",
  });
}

export function useGetGovernmentDisbursement(id: string) {
  return useQuery<
    GetGovernmentDisbursement,
    GetGovernmentDisbursementVariables
  >(GET_GOVERNMENT_DISBURSEMENT, {
    variables: { id },
    fetchPolicy: "network-only",
  });
}

export const getCategoryHasNoBooleanName = (
  category: GovernmentDisbursementCategoryChoice
) => {
  switch (category) {
    case GovernmentDisbursementCategoryChoice.Foodshare:
      return "hasNoFoodshare";
    case GovernmentDisbursementCategoryChoice.SocialSecurity:
      return "hasNoSocialSecurity";
    default:
      throw new Error(
        `unexpected government disbursement category ${category}`
      );
  }
};

export function useUpsertGovernmentDisbursement<
  Schema extends { governmentDisbursement: any } = UpsertGovernmentDisbursement,
  Variables = UpsertGovernmentDisbursementVariables
>(
  query = UPSERT_GOVERNMENT_DISBURSEMENT,
  category: GovernmentDisbursementCategoryChoice
) {
  return useUpsertModify<
    Schema,
    Variables,
    GovernmentDisbursementInput,
    QueryGovernmentDisbursementArgs
  >(query, "governmentDisbursement", "governmentDisbursements", (cache) => {
    updateFilingProfileCache(
      cache,
      getCategoryHasNoBooleanName(category),
      false
    );
  });
}

export function useDeleteGovernmentDisbursement() {
  return useDeleteEvict<DeleteGovernmentDisbursement>(
    DELETE_GOVERNMENT_DISBURSEMENT,
    "deleteGovernmentDisbursement",
    "GovernmentDisbursement"
  );
}
