import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React, { useMemo } from "react";
import { formatFancyNumber } from "utils/formatters";
import { parseFancyNumber } from "utils/validators";
import OTHER_FAMILY_MODULE from "./OtherFamilyModule.gql";

export default function OtherFamilySummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<OtherFamilyModule>(OTHER_FAMILY_MODULE, {
    returnPartialData: true,
  });

  const familyMembers = data?.familyProfile?.familyMembers;

  const totalChildSupport = useMemo(
    () =>
      familyMembers?.reduce((total, m) => {
        let amount = 0;
        if (m.childSupportPaid) amount = parseFancyNumber(m.childSupportAmount);
        if (m.childSupportReceived)
          amount = 0 - parseFancyNumber(m.childSupportAmount);
        return total + amount;
      }, 0),
    [familyMembers]
  );

  return (
    <ModuleSummary {...props} progress={data?.progress.otherFamily}>
      {data?.familyProfile?.hasNoFamilyMembers ? (
        <HasNoSummary>
          You have no non-spouse family members in your household.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            maxValueLength={2}
            label="family member"
            pluralizeLabel
            value={data?.familyProfile?.familyMembers?.length}
          />
          <NumericSummary
            maxValueLength={2}
            label="dependent"
            pluralizeLabel
            value={
              data?.familyProfile?.familyMembers?.filter((m) => m.isDependent)
                .length
            }
          />
          <NumericSummary
            maxValueLength={2}
            label={`child support ${
              totalChildSupport >= 0 ? "paid" : "received"
            }`}
            valuePrefix="$"
            value={
              totalChildSupport &&
              formatFancyNumber(Math.abs(totalChildSupport), 0)
            }
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
