import cn from "classnames";
import { GuardedRoute } from "components/GuardedRoute";
import { motion } from "framer-motion";
import React from "react";
import { NavLink, Redirect } from "react-router-dom";
import { useNested } from "utils/hooks";
import theme from "utils/theme";
import { WizardRenderProps, WizardRouteChildProps } from ".";
import css from "./Wizard.module.css";

export default function VerticalWizard({
  children,
  lastEnabledStep,
  currentStep,
  form,
  error,
  nav,
  onNav,
  isEdit,
}: WizardRenderProps) {
  const { url, path } = useNested();
  return (
    <div className={css.verticalContainer}>
      {children.map((child, index) => {
        const isActive = currentStep === index;
        const routeDisabled = index > lastEnabledStep;
        const prevPath = children[index - 1]?.props?.path;
        const childProps: WizardRouteChildProps = {
          form,
          isEdit,
          navProps: nav.props,
        };
        const linkURL = url(child.props.path, { preserveSearch: true });
        return (
          <motion.div
            key={child.props.path}
            animate={routeDisabled ? "disabled" : isActive ? "open" : "closed"}
            initial="closed"
          >
            <NavLink
              to={linkURL}
              activeClassName={css.active}
              className={cn(css.verticalStepTitle, {
                [css.disabled]: routeDisabled,
              })}
              onClick={(e) => onNav(e, linkURL)}
              key={child.props.path}
            >
              <motion.span
                className={css.verticalStepTitle__index}
                variants={{
                  open: {
                    backgroundColor: theme.color("primary"),
                    color: theme.color("gray-6"),
                  },
                  closed: {
                    backgroundColor: "transparent",
                    color: theme.color("primary"),
                  },
                  disabled: {
                    backgroundColor: "transparent",
                    color: theme.color("gray-3"),
                  },
                }}
              >
                {index + 1}
              </motion.span>
              <h3>{child.props.title}</h3>
            </NavLink>

            <div
              className={cn(css.childContainer, {
                [css.disabled]: routeDisabled,
              })}
              key={child.props.path + "-content"}
            >
              <motion.div
                variants={{
                  open: { height: "auto" },
                  closed: { height: 0 },
                  disabled: { height: 0 },
                }}
                transition={{
                  type: "spring",
                  damping: 12,
                  stiffness: 120,
                  mass: 0.3,
                }}
              >
                <GuardedRoute
                  key={child.props.path}
                  path={path(child.props.path)}
                  guard={!routeDisabled}
                  renderFallback={() => (
                    <Redirect
                      to={
                        prevPath
                          ? url(prevPath, { preserveSearch: true })
                          : url(children[0].props.path, {
                              preserveSearch: true,
                            })
                      }
                    />
                  )}
                  render={() => (
                    <div className={css.child}>
                      {React.createElement(child.props.component, childProps)}
                      {error && <p className="errors">{error}</p>}
                    </div>
                  )}
                />
              </motion.div>
            </div>
          </motion.div>
        );
      })}
    </div>
  );
}
