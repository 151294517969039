import { useMutation, useQuery } from "@apollo/client";
import cn from "classnames";
import { BirthdayInput } from "components/BirthdayInput";
import { ButtonRow } from "components/ButtonRow";
import { Card } from "components/Card";
import { Form } from "components/Form";
import { HiddenInput } from "components/HiddenInput";
import { IDInput } from "components/IDInput";
import { InputRow } from "components/InputRow";
import { Loading } from "components/Loading";
import { PhoneNumberInput } from "components/PhoneNumberInput";
import { TextInput } from "components/TextInput";
import { Title } from "components/Title";
import React from "react";
import { FaCheck } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { required } from "utils/defaultMessages";
import { useCheckMounted } from "utils/hooks";
import { ErrorTranslator } from "utils/hooks/useBackendErrorTranslation";
import ABOUT_YOU_FORM_INITIAL_VALUE from "./AboutYouFormInitialValue.gql";
import UPDATE_ABOUT_YOU from "./UpdateAboutYou.gql";

interface FormProps {
  next?: string;
  prev?: string;
}

type AboutYouFormState = {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  birthday: ISODate;
  isItin: boolean;
};

export function AboutYou({ prev, next }: FormProps) {
  const initialValue = useQuery<AboutYouFormInitialValue>(
    ABOUT_YOU_FORM_INITIAL_VALUE
  );
  const [updateAboutYou] = useMutation<UpdateAboutYou>(UPDATE_ABOUT_YOU);
  const [saving, setSaving] = React.useState(false);
  const checkMounted = useCheckMounted();
  const history = useHistory();

  const onSubmit = React.useCallback(
    async (
      updatedData: FilingPersonInput,
      event: React.FormEvent,
      translateErrors: ErrorTranslator
    ) => {
      setSaving(true);
      try {
        await updateAboutYou({ variables: { input: updatedData } });
        if (next) {
          history.push(next);
        }
      } catch (e) {
        translateErrors(e);
        console.error(e);
      } finally {
        if (checkMounted()) {
          setSaving(false);
        }
      }
    },
    [updateAboutYou, setSaving, next]
  );

  if (initialValue.loading) return <Loading />;

  return (
    <>
      <Title>Your Personal Info</Title>
      <Card>
        <p>Let’s start by getting to know you…</p>
        <Form<AboutYouFormState>
          initialValue={initialValue.data?.filingPerson}
          onSubmit={onSubmit}
        >
          {!!initialValue.data && <HiddenInput name="id" />}

          <InputRow label="Legal name">
            <TextInput
              name="firstName"
              placeholder="First"
              aria-label="First name"
              autoFocus
              maxLength={255}
              rules={{ required: "Please enter your first name." }}
              autoComplete="given-name"
            />
            <TextInput
              name="middleName"
              placeholder="Middle"
              aria-label="Middle name"
              maxLength={255}
              autoComplete="additional-name"
            />
            <TextInput
              name="lastName"
              placeholder="Last"
              aria-label="Last name"
              maxLength={255}
              rules={{ required: "Please enter your last name." }}
              autoComplete="family-name"
            />
            <TextInput
              name="suffix"
              placeholder="Suffix"
              aria-label="Name suffix"
              width="120px"
              maxLength={12}
              style={{ minWidth: "50px" }}
              autoComplete="honorific-suffix"
            />
          </InputRow>
          <div className="form-row">
            <BirthdayInput
              name="birthday"
              label="Birth date"
              rules={{ required }}
            />

            <PhoneNumberInput />

            <IDInput
              name="ssnItin"
              isSaved={initialValue?.data.filingPerson.hasSsnItin}
              isITIN={initialValue?.data.filingPerson.isItin}
              required
            />
          </div>

          <ButtonRow
            left={null}
            right={
              <button className={cn("btn", "primary", { loading: saving })}>
                <span>Save</span>
                <FaCheck />
              </button>
            }
          />
        </Form>
      </Card>
    </>
  );
}
