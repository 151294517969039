import { startCase } from "lodash";
import * as React from "react";
import { StudentLoanStatement } from "../AddStatementWizard";
import StudentLoanWizard, { LoanWizardOutput } from "../StudentLoanWizard";

export default function CreateView({
  listURL,
  statement,
  onCreate,
}: {
  listURL: string;
  statement: StudentLoanStatement;
  onCreate?: (loan: LoanWizardOutput) => void;
}) {
  return (
    <StudentLoanWizard
      title={`Add a ${startCase(statement._type.toLowerCase())} Loan`}
      initialValue={statement}
      backURL={listURL}
      nextURL={listURL}
      onLoanCreated={onCreate}
    />
  );
}
