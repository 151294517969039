import * as React from "react";
import { FaBomb } from "react-icons/fa";
import s from "./ErrorBoundary.module.css";

export class ErrorBoundary extends React.Component<
  any,
  { hasError: boolean },
  any
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error | null) {
    return { hasError: true };
  }

  componentDidCatch(error: Error | null, errorInfo: object) {
    // TODO: replace this with something more useful
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={s.ErrorBoundary}>
          <h1>
            <FaBomb />
            Houston, we have a problem.
          </h1>
          <p>Something has gone wrong, please try refreshing the page.</p>
        </div>
      );
    }
    return this.props.children;
  }
}
