import { useUpsertFarmAsset } from "api/graphql/FarmAsset";
import { Form } from "components/Form";
import { HiddenInput } from "components/HiddenInput";
import { LearnMore } from "components/LearnMore";
import { DecimalInput } from "components/NumberInput";
import { TextArea } from "components/TextArea";
import { Title } from "components/Title";
import { WizardNavigation } from "components/WizardNavigation";
import { FarmAssetCategory } from "globalTypes";
import React from "react";
import { useHistory } from "react-router-dom";
import { DecimalSchema } from "utils/validators/yup";
import { mixed, object, string } from "yup";

interface Props {
  category: FarmAssetCategory;
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  subtitle?: React.ReactElement;
}

export const FarmAssetSchema = object({
  currentValue: DecimalSchema.required("This field is required."),
  assetDescription: string().required("This field is required."),
  category: mixed<FarmAssetCategory>().required("This field is required."),
}).required();

export default function FarmAssetForm({
  category,
  backURL,
  nextURL,
  initialValue,
  title,
  subtitle,
}: Props) {
  const history = useHistory();
  const [upsert, { loading }] = useUpsertFarmAsset();

  const onSubmit = async (
    formValues: ReturnType<typeof FarmAssetSchema.validateSync>
  ) => {
    const saveData = { ...initialValue, ...formValues };
    await upsert(saveData);
    history.push(nextURL);
  };

  return (
    <Form
      onSubmit={onSubmit}
      schema={FarmAssetSchema}
      initialValue={initialValue}
    >
      <div style={{ marginBottom: "16px" }}>
        {title && <Title>{title}</Title>}
        {subtitle}
      </div>

      <HiddenInput name="category" value={category} />

      <DecimalInput
        name="currentValue"
        label="Current value"
        addOnBefore="$"
        includeThousandsSeparator
        allowDecimal
        autoFocus={!initialValue}
        learnMore={
          <LearnMore>
            <h4>What if I’m not sure what these assets are worth?</h4>
            <p>
              If you’re unsure about the value of an asset, estimate the value
              you could sell it for on the market. If your item has no resale
              value, put down $0.
            </p>
          </LearnMore>
        }
      />

      <TextArea
        name="assetDescription"
        label="Description"
        placeholder="Please succinctly describe this item."
      />

      <WizardNavigation
        isComplete
        backURL={backURL}
        saving={loading}
        nextIsSubmit
      />
    </Form>
  );
}
