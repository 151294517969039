import { StatelessEditCreateList } from "components/EditCreateList";
import React from "react";
import { CreateView } from "./CreateView";
import { EditView } from "./EditView";
import { ListView } from "./ListView";

export interface Props {
  prev?: string;
  next?: string;
}

export function Education({ prev, next }: Props) {
  return (
    <StatelessEditCreateList
      prev={next}
      next={prev}
      ListView={ListView}
      EditView={EditView}
      CreateView={CreateView}
    />
  );
}
