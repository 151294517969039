import { useQuery } from "@apollo/client";
import CategoriesList from "components/CategoriesList";
import { FormModuleProps } from "components/FormFlow";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import { sortBy, sumBy } from "lodash";
import { Route, Switch } from "react-router-dom";
import { breakpoints, useBreakpoint, useNested } from "utils/hooks";
import * as categories from "./categories";
import { CategorySection } from "./CategorySection";
import GOVERNMENT_DISBURSEMENTS_MODULE from "./GovernmentDisbursementsModule.gql";
import { GovernmentDisbursementSchema } from "./GovernmentDisbursementWizard";

const sections = [
  categories.foodshareCategoryDetail,
  categories.socialSecurityCategoryDetail,
];

export default function GovernmentDisbursements({
  prev,
  next,
}: FormModuleProps<undefined>) {
  const isMobile = useBreakpoint(breakpoints.mobile);
  const { path, url } = useNested();
  const { data } = useQuery<
    GovernmentDisbursementsModule,
    GovernmentDisbursementsModuleVariables
  >(GOVERNMENT_DISBURSEMENTS_MODULE);

  if (!data) return <Loading />;

  return (
    <>
      <Switch>
        {sections.map((categoryDetail, index) => {
          return (
            <Route
              key={index}
              path={path(`${categoryDetail.urlPathName}`)}
              render={() => (
                <CategorySection
                  key={index}
                  next={url("/")}
                  prev={url("/")}
                  categoryDetail={categoryDetail}
                />
              )}
            />
          );
        })}
        <Route
          exact
          path={path("/")}
          render={() => (
            <div>
              <Title>Government Disbursements</Title>
              <p>
                When filing for bankruptcy, your lawyer will need to know about
                benefits you receive from the government.{" "}
                {isMobile ? "Tap " : "Click "} each of the following sections to
                give us information about that benefit.
              </p>
              <CategoriesList
                doneURL={next}
                categories={sortBy(sections, (c) => {
                  // Sort incomplete categories to the top
                  const items = data.governmentDisbursements.filter(
                    (disbursement) => disbursement.category === c.category
                  );
                  const done =
                    !!items.length ||
                    data.filingProfile[c.hasNoProfileBooleanName];
                  return done ? 1 : 0;
                }).map((c) => {
                  const items = data.governmentDisbursements.filter(
                    (disbursement) => disbursement.category === c.category
                  );

                  return {
                    name: c.displayName,
                    href: url(c.urlPathName),
                    Icon: c.menuIcon,
                    hasNone: data.filingProfile[c.hasNoProfileBooleanName],
                    items,
                    itemName: "disbursement",
                    schema: GovernmentDisbursementSchema,
                    itemsSummary: c.displayName,
                  };
                })}
              />
            </div>
          )}
        />
      </Switch>
    </>
  );
}
