import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { ErrorIcon } from "./Icons";

export interface ErrorViewProps {
  backURL: string;
  abortURL: string;
}

export function ErrorView({ backURL, abortURL }: ErrorViewProps) {
  return (
    <div>
      <ErrorIcon />
      <p>
        There was a problem creating your public loans. Please try uploading
        again, and if you still get this error, contact us.
      </p>
      <WizardNavigation abortURL={abortURL} backURL={backURL} />
    </div>
  );
}
