import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { get } from "lodash";
import { updateFilingProfileCache } from "../FilingProfile";
import { updateQuery, useDelete, useUpsert } from "../utils";
import DELETE_REAL_ESTATE from "./DeleteRealEstate.gql";
import GET_REAL_ESTATE from "./GetRealEstate.gql";
import LIST_REAL_ESTATE from "./ListRealEstate.gql";
import UPSERT_REAL_ESTATE from "./UpsertRealEstate.gql";

const LIST_REAL_ESTATE_IDS = gql`
  query ListRealEstateIds {
    realEstateProperties {
      id
    }
  }
`;

export function useListRealEstate(options?: QueryHookOptions<ListRealEstate>) {
  return useQuery<ListRealEstate>(LIST_REAL_ESTATE, options);
}

export function useGetRealEstate(id: string) {
  return useQuery<GetRealEstate, GetRealEstateVariables>(GET_REAL_ESTATE, {
    variables: { id },
  });
}

export function useUpsertRealEstate() {
  return useUpsert<
    UpsertRealEstate,
    UpsertRealEstateVariables,
    ListRealEstateIds,
    RealEstatePropertyInput
  >(
    UPSERT_REAL_ESTATE,
    LIST_REAL_ESTATE_IDS,
    "realEstateProperty",
    "realEstateProperties",
    (cache) => updateFilingProfileCache(cache, "hasNoRealEstate", false)
  );
}

const LIST_MORTGAGES_TO_DELETE = gql`
  query ListMortgagesForDelete {
    realEstateMortgages {
      id
      property {
        id
      }
    }
  }
`;

export function useDeleteRealEstate() {
  return useDelete<DeleteRealEstate, ListRealEstateIds>(
    DELETE_REAL_ESTATE,
    LIST_REAL_ESTATE_IDS,
    "deleteRealEstateProperty",
    "realEstateProperties",
    (cache, id) => {
      updateQuery<ListMortgagesForDelete>(
        cache,
        LIST_MORTGAGES_TO_DELETE,
        (state) => ({
          realEstateMortgages: get(state, "realEstateMortgages", []).filter(
            (m) => m.property.id !== id
          ),
        })
      );
    }
  );
}
