import { LearnMore } from "components/LearnMore";
import { MonthInput } from "components/MonthInput";
import { DecimalInput } from "components/NumberInput";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { DateSchema, DecimalSchema } from "utils/validators/yup";
import { object, string } from "yup";

export const ItemSchema = object({
  assetDescription: string().required(
    "Please describe the item or asset that was lost."
  ),
  lossDescription: string().required(
    "Please describe how this item or asset was lost."
  ),
  lossDate: DateSchema.dateNotFuture(
    "Date lost must not be in the future"
  ).required("Please indicate what month this item or asset was lost."),
  assetValue: DecimalSchema.required("This field is required.").test(
    "min",
    "Must be greater than zero.",
    (value: string) => value && Number(value.replace(/,/g, "")) > 0
  ),
}).required();

export function Item({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <TextArea
          name="assetDescription"
          label="Description"
          placeholder={"Describe the item that was lost."}
          rows={5}
          autoFocus={!isEdit}
        />
      </div>
      <div className="form-row">
        <DecimalInput
          name="assetValue"
          label="Value"
          addOnBefore="$"
          includeThousandsSeparator
          allowDecimal
          learnMore={
            <LearnMore>
              <h4>What if I don’t know the value?</h4>
              <p>
                If it is a physical item, check ebay for recent sales. Otherwise
                contact our support team.
              </p>
            </LearnMore>
          }
        />
        <MonthInput name="lossDate" label="Month Loss Occurred" width="175px" />
      </div>
      <div className="form-row">
        <TextInput
          name="lossDescription"
          label="How was this item lost?"
          placeholder="Examples: fire, flood, theft"
        />
      </div>
      <WizardNavigation {...navProps} />
    </>
  );
}
