import { MutationHookOptions, useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import ASSIGN_ASSET_SALE_BUYER from "./AssignAssetSaleBuyer.gql";
import DELETE_ASSET_SALE_BUYER from "./DeleteAssetSaleBuyer.gql";
import GET_ASSET_SALE_BUYER_CANDIDATES from "./GetAssetSaleBuyerCandidates.gql";
import UPSERT_ASSET_SALE_BUYER from "./UpsertAssetSaleBuyer.gql";

export function useAssignAssetSaleBuyer() {
  const [assignAssetSaleBuyer] = useMutation<
    AssignAssetSaleBuyer,
    AssignAssetSaleBuyerVariables
  >(ASSIGN_ASSET_SALE_BUYER);

  const callback = React.useCallback(
    (variables: AssignAssetSaleBuyerVariables) => {
      return assignAssetSaleBuyer({ variables });
    },
    [assignAssetSaleBuyer]
  );

  return React.useMemo(() => [callback], [callback]);
}

export function useGetAssetSaleBuyerCandidates(
  variables: GetAssetSaleBuyerCandidatesVariables
) {
  return useQuery<
    GetAssetSaleBuyerCandidates,
    GetAssetSaleBuyerCandidatesVariables
  >(GET_ASSET_SALE_BUYER_CANDIDATES, {
    fetchPolicy: "network-only",
    variables,
  });
}

export function useUpsertAssetSaleBuyer(
  options?: MutationHookOptions<
    UpsertAssetSaleBuyer,
    UpsertAssetSaleBuyerVariables
  >
) {
  return useMutation<UpsertAssetSaleBuyer, UpsertAssetSaleBuyerVariables>(
    UPSERT_ASSET_SALE_BUYER,
    options
  );
}

export function useDeleteAssetSaleBuyer(
  options?: MutationHookOptions<
    DeleteAssetSaleBuyer,
    DeleteAssetSaleBuyerVariables
  >
) {
  return useMutation<DeleteAssetSaleBuyer, DeleteAssetSaleBuyerVariables>(
    DELETE_ASSET_SALE_BUYER,
    options
  );
}
