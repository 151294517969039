import { Spinner } from "components/Spinner";
import { Badge } from "@mantine/core";

function ConnectionStatus({ connections, label }) {
  // Iterate over connections. If any are not connected, return red.
  // If all are connected, return green.
  // If none exist, return gray.

  const connected = connections.every((c) => c.status === "connected");

  return (
    <Badge
      color={connections.length === 0 ? "gray" : connected ? "green" : "red"}
      size="xs"
    >
      {label}
    </Badge>
  );
}

export default function ConnectionStatusWidget({ loading, statuses }) {
  if (loading) return <Spinner />;

  const title = "Connection Statuses";

  return (
    <div className="flex items-center w-full h-full gap-1 py-1" title={title}>
      <ConnectionStatus connections={statuses.financial} label="Fin" />
      <ConnectionStatus connections={statuses.employment} label="Emp" />
    </div>
  );
}
