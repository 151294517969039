import cn from "classnames";
import { FileInput, FileInputProps } from "components/FileInput";
import * as React from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { useFormField } from "utils/hooks";
import { FileCard } from "./FileCard";
import styles from "./FileList.module.css";
import { last } from "lodash";

export interface FileListFile {
  id: string;
  name: string;
  type?: string;
  uploadFile: UploadedFile;
}

interface FileListProps extends FileInputProps {
  name?: string;
  rules?: RegisterOptions;
  error?: React.ReactNode;
  files: FileListFile[];
  onChange?: (files: FileListFile[]) => any;
  onDelete?: (fileName: string, index: number) => any;
  onDownload?: (file: UploadedFile, index: number) => any;
  disabled?: boolean;
}

function FileList({
  error,
  files,
  onChange,
  name,
  onDelete = () => null,
  onDownload = () => null,
  disabled = false,
  ...dropzoneOptions
}: FileListProps): JSX.Element {
  const form = useFormContext();
  React.useEffect(() => {
    if (typeof onChange === "function" && form.watch(name) !== files) {
      onChange(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, onChange]);

  const displayedError = error || form?.errors[name]?.message;
  return (
    <>
      {displayedError && <div className="errors">{displayedError}</div>}
      <div className="flex-row">
        <FileInput
          {...dropzoneOptions}
          disabled={disabled}
          className={cn(styles.listItem, dropzoneOptions.className, {
            "has-error": !!displayedError,
          })}
        />
        {files.map((file, index) => (
          <FileCard
            key={file.id}
            {...file}
            name={last(file.uploadFile?.name.split("/"))}
            className={styles.listItem}
            onDelete={(): void => onDelete(file.name, index)}
            onDownload={(): void => {
              onDownload(file.uploadFile, index);
            }}
            disabled={disabled}
          />
        ))}
      </div>
    </>
  );
}

function WrappedFileList({ name, rules = {}, ...props }: FileListProps) {
  const { form, error } = useFormField({
    name,
    rules,
  });

  const input = <FileList error={error} {...props} name={name} />;
  return form && name ? (
    <Controller name={name} as={input} rules={rules} />
  ) : (
    input
  );
}

export { WrappedFileList as FileList };
