import { useGetConsultation } from "api/graphql/Consultation";
import { StatelessEditCreateList } from "components/EditCreateList";
import { useEditorBuffer } from "components/EditorState/hooks";
import { HiddenInput } from "components/HiddenInput";
import { Loading } from "components/Loading";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useNested } from "utils/hooks";
import { array, object } from "yup";
import { CreateView } from "./CreateView";
import { EditView } from "./EditView";
import { ListView } from "./ListView";

export const PaymentsSchema = object({
  payments: array().min(1, "Please add all payments."),
}).required();

export function Payments({ isEdit, navProps }: WizardRouteChildProps) {
  const [{ buffer }] = useEditorBuffer();
  const { data } = useGetConsultation(buffer.id);
  const { url } = useNested();
  const form = useFormContext();
  if (!data) return <Loading />;

  return (
    <>
      <HiddenInput name="payments" value={data.consultation.payments} />
      <StatelessEditCreateList
        ListView={(props) => (
          <>
            <ListView {...props} />
            {!!form?.errors?.payments && (
              <div className="errors">{form.errors.payments.message}</div>
            )}
            <WizardNavigation {...navProps} />
          </>
        )}
        EditView={EditView}
        CreateView={CreateView}
        prev={url(".")}
        next={url(".")}
      />
    </>
  );
}
