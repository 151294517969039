import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaBriefcase } from "react-icons/fa";
import { EmploymentRecords } from "./Employment";
import EmploymentSummary from "./Summary";

const EmploymentRecordsSubmodule: SubModule = {
  path: "employment",
  icon: FaBriefcase,
  title: "Employment",
  component: EmploymentRecords,
  summary: EmploymentSummary,
  statusName: "employment",
};

export default EmploymentRecordsSubmodule;
