import { client } from "api/graphql/client";
import GET_OTHER_PERSON from "api/graphql/OtherPerson/GetOtherPerson.gql";
import {
  OtherPersonForm,
  OtherPersonFormProps,
} from "components/FormSections/OtherPerson";
import { GatherMissingDataWizard } from "components/GatherMissingDataWizard";
import { capitalize } from "lodash";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useNested } from "utils/hooks";
import { object } from "yup";
import { Finalize, MailingAddress, SSN } from "./validations";

export enum OtherPersonDataValidationType {
  MailingAddress,
  SSN,
}

const getValidationByType = (validation: OtherPersonDataValidationType) => {
  switch (validation) {
    case OtherPersonDataValidationType.MailingAddress:
      return MailingAddress;
    case OtherPersonDataValidationType.SSN:
      return SSN;
    default:
      throw new Error("No gathering missing data step for validation");
  }
};

interface OtherPersonWithMissingDataValidationsFormProps
  extends OtherPersonFormProps {
  validations: OtherPersonDataValidationType[];
  onFinalizeMissingDataCollection?: ({
    otherPersonId,
    relatedId,
    relatedTypename,
  }: {
    otherPersonId: UUID;
    relatedId: UUID;
    relatedTypename: string;
  }) => any;
  relatedDisplayName: string;
  relatedId: UUID;
  relatedTypename: string;
}

export function OtherPersonWithMissingDataValidationsForm({
  validations,
  onFinalizeMissingDataCollection,
  relatedDisplayName,
  relatedId,
  relatedTypename,
  ...otherPersonFormProps
}: OtherPersonWithMissingDataValidationsFormProps) {
  const { url, path } = useNested();

  const gatheringSteps = React.useMemo(
    () => [
      ...validations.map((validationName) => {
        const validation = getValidationByType(validationName);
        return {
          testHasRequiredData:
            validation.existingObjectRequiredDataSchema || (() => false),
          step: validation.step,
        };
      }),
      {
        testHasRequiredData: () => false,
        step: Finalize.step,
      },
    ],
    [validations]
  );

  const testHasRequiredData = React.useMemo(() => {
    let schema = object();
    for (const validationName of validations) {
      const validation = getValidationByType(validationName);
      if (validation.existingObjectRequiredDataSchema) {
        schema = schema.concat(validation.existingObjectRequiredDataSchema);
      }
    }

    return (data: any) => schema.isValid(data);
  }, [validations]);

  const loadOtherPerson = async (id: UUID) =>
    await client
      .query<GetOtherPerson, GetOtherPersonVariables>({
        query: GET_OTHER_PERSON,
        variables: { id },
      })
      .then((response) => response.data.otherPerson)
      .catch(console.error);

  return (
    <Switch>
      <Route
        path={path("/add-missing-info/:otherPersonId")}
        render={({ match }) => {
          const { otherPersonId } = match.params;
          if (!otherPersonId) return <Redirect to={url("/")} />;

          return (
            <GatherMissingDataWizard<
              any,
              {
                relatedTypename: string;
                relatedDisplayName: string;
                relatedId: UUID;
              }
            >
              object={loadOtherPerson(otherPersonId)}
              nextURL={otherPersonFormProps.nextURL}
              backURL={url("/")}
              gatheringSteps={gatheringSteps}
              subtitle={`New ${capitalize(relatedDisplayName)}`}
              context={{
                relatedDisplayName,
                relatedTypename,
                relatedId,
              }}
              onSave={async () => {
                await onFinalizeMissingDataCollection?.({
                  otherPersonId,
                  relatedId,
                  relatedTypename,
                });
              }}
            />
          );
        }}
      />
      <OtherPersonForm
        {...otherPersonFormProps}
        testHasRequiredData={testHasRequiredData}
        getMissingDataUpdateURL={({ id }) => url(`/add-missing-info/${id}`)}
      />
    </Switch>
  );
}
