import { BooleanInput } from "components/BooleanInput";
import { DecimalInput } from "components/NumberInput";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";
import { WantToKeepLearnMore } from "components/WantToKeepLearnMore";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { DecimalSchema } from "utils/validators/yup";
import { mixed, object, string } from "yup";

export const PolicySchema = object({
  premium: DecimalSchema.required("This field is required."),
  companyName: string().required("This field is required."),
  description: string().required("This field is required."),
  wantToKeep: mixed<boolean | null>().required("This field is required."),
}).required();

export default function Policy({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        <TextInput
          name="companyName"
          label="Provider name"
          autoFocus={!isEdit}
          placeholder="e.g. Blue Cross Blue Shield"
        />

        <DecimalInput
          name="premium"
          label="Monthly premium"
          addOnBefore="$"
          includeThousandsSeparator
          allowDecimal
          width="150px"
        />
      </div>

      <TextArea
        name="description"
        label="Description"
        placeholder="Include who is covered by the policy, and any other relevant details."
      />

      <div className="form-row">
        <BooleanInput
          name="wantToKeep"
          label="Do you want to keep this policy after your bankruptcy?"
          learnMore={<WantToKeepLearnMore />}
        />
      </div>
      <WizardNavigation {...navProps} />
    </>
  );
}
