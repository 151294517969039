import cn from "classnames";
import {
  DeleteButtonWithConfirm,
  DeleteButtonWithConfirmProps,
} from "components/DeleteConfirmationModal";
import React, { HTMLProps, ReactNode } from "react";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { Card } from "../Card";
import css from "./ListItem.module.scss";

export interface ListItemProps extends HTMLProps<HTMLDivElement> {
  children: ReactNode;
  onDelete?: () => Promise<any>;
  onEdit?: () => void;
  otherButtons?: ReactNode;
  relatedItems?: DeleteButtonWithConfirmProps["relatedItems"];
  objectName?: DeleteButtonWithConfirmProps["objectName"];
}

/** Displays a card representing an item in a list.
 *
 * Delete, edit, and/or review buttons will be displayed if the associated
 * callbacks are provided.
 *
 * If both relatedItems and objectName are provided, a delete confirmation
 * modal will be presented before deletion letting the user know that related
 * items will also be deleted.
 */
export function ListItem({
  children,
  onDelete,
  onEdit,
  className,
  relatedItems,
  objectName,
  otherButtons,
  ...etc
}: ListItemProps) {
  return (
    <Card className={cn(css.container, className)} {...etc}>
      <div className={css.content}>{children}</div>
      <div className={css.buttons}>
        {otherButtons}
        {onEdit && (
          <button
            type="button"
            onClick={onEdit}
            title="Edit"
            className="btn flat"
          >
            <FaPencilAlt size="19px" />
            <span>Edit</span>
          </button>
        )}
        {onDelete && (
          <DeleteButtonWithConfirm
            onDelete={onDelete}
            icon={<FaTrashAlt size="19px" />}
            className="flat"
            relatedItems={relatedItems}
            objectName={objectName}
          />
        )}
      </div>
    </Card>
  );
}
