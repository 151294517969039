import * as React from "react";
import { useLocation } from "react-router-dom";

const AutoScrollContext = React.createContext<React.MutableRefObject<boolean>>(
  null
);

export function usePauseAutoScroll() {
  const state = React.useContext(AutoScrollContext);
  React.useEffect(() => {
    if (!state) {
      return;
    }

    const initialState = state.current;
    state.current = false;

    return () => {
      state.current = initialState;
    };
  }, [state]);
}

export function AutoScrollToTop({ children }: { children: React.ReactNode }) {
  const state = React.useRef(true);
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (state.current) window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <AutoScrollContext.Provider value={state}>
      {children}
    </AutoScrollContext.Provider>
  );
}
