import { QueryHookOptions, useQuery } from "@apollo/client";
import { updateFilingProfileCache } from "api/graphql/FilingProfile";
import { useDelete, useUpsert } from "api/graphql/utils";
import gql from "graphql-tag";
import DELETE_ASSET_LOSS from "./DeleteAssetLoss.gql";
import GET_ASSET_LOSS from "./GetAssetLoss.gql";
import LIST_ASSET_LOSS from "./ListAssetLoss.gql";
import UPSERT_ASSET_LOSS from "./UpsertAssetLoss.gql";

const LIST_ASSET_LOSS_IDS = gql`
  query ListAssetLossIds {
    assetLosses {
      id
    }
  }
`;

export function useListAssetLoss(options?: QueryHookOptions<ListAssetLoss>) {
  return useQuery<ListAssetLoss>(LIST_ASSET_LOSS, options);
}

export function useGetAssetLoss(id: string) {
  return useQuery<GetAssetLoss, GetAssetLossVariables>(GET_ASSET_LOSS, {
    variables: { id },
  });
}

export function useUpsertAssetLoss<
  Schema extends { assetLoss: any } = UpsertAssetLoss,
  Variables = UpsertAssetLossVariables
>(query = UPSERT_ASSET_LOSS) {
  return useUpsert<Schema, Variables, ListAssetLossIds, AssetLossInput>(
    query,
    LIST_ASSET_LOSS_IDS,
    "assetLoss",
    "assetLosses",
    (cache) => updateFilingProfileCache(cache, "hasNoAssetLosses", false)
  );
}

export function useDeleteAssetLoss() {
  return useDelete<DeleteAssetLoss, ListAssetLossIds>(
    DELETE_ASSET_LOSS,
    LIST_ASSET_LOSS_IDS,
    "deleteAssetLoss",
    "assetLosses"
  );
}
