import { useQuery } from "@apollo/client";
import GET_REAL_ESTATE_PROPERTY_VALUATION from "./GetRealEstatePropertyValuation.gql";

export function useGetRealEstatePropertyValuation(
  lookupAddress: RealEstatePropertyLookupInput
) {
  return useQuery<
    GetRealEstatePropertyValuation,
    GetRealEstatePropertyValuationVariables
  >(GET_REAL_ESTATE_PROPERTY_VALUATION, {
    variables: { lookupAddress },
  });
}
