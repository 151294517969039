import React from "react";
import { CreditCardDebtWizard } from "./CreditCardDebtWizard";

export default function CreateView({ listURL }: { listURL: string }) {
  return (
    <CreditCardDebtWizard
      title="Add a Credit Card"
      backURL={listURL}
      nextURL={listURL}
    />
  );
}
