import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import { fancyNumberSum } from "utils/aggregators/fancyNumbers";
import OTHER_DEBTS_MODULE from "./OtherDebtsModule.gql";

export default function OtherDebtsSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<OtherDebtsModule>(OTHER_DEBTS_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress?.otherDebts}>
      {data?.filingProfile?.hasNoOtherDebts ? (
        <HasNoSummary>
          You don&rsquo;t have any other debts not covered in the other
          sections.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="other debt"
            maxValueLength={2}
            value={data?.otherDebts?.length}
            pluralizeLabel
          />
          <NumericSummary
            label="total balance"
            valuePrefix="$"
            maxValueLength={5}
            value={fancyNumberSum(data?.otherDebts, "outstandingAmount")}
          />
          <NumericSummary
            label="monthly payments"
            valuePrefix="$"
            maxValueLength={4}
            value={fancyNumberSum(data?.otherDebts, "monthlyPayment")}
          />
          <NumericSummary
            label="overdue"
            valuePrefix="$"
            maxValueLength={4}
            value={fancyNumberSum(data?.otherDebts, "delinquentAmount")}
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
