import { merge } from "lodash";
import * as React from "react";
import { DeepPartial } from "react-hook-form";
import { Buffer, Meta, Save } from "./contexts";
import {
  BufferActionType,
  bufferReducer,
  BufferStateType,
  metaReducer,
  MetaStateType,
} from "./reducers";

export type isDirty = ({
  buffer,
  meta,
}: {
  buffer: BufferStateType<any>;
  meta: MetaStateType;
}) => boolean;

interface EditorStateProps<T> {
  onSave?: ({
    buffer,
    meta,
  }: {
    buffer: BufferStateType<T>;
    meta: MetaStateType;
  }) => Promise<T | DeepPartial<T>>;
  initialValue?: DeepPartial<T>;
  children?: React.ReactNode;
  isDirty?: isDirty;
}

export function EditorState<T>({
  children,
  initialValue,
  isDirty,
  onSave,
}: EditorStateProps<T>) {
  const isMounted = React.useRef(true);
  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [bufferState, bufferDispatch] = React.useReducer(bufferReducer, {
    committed: { ...(initialValue || {}) },
    buffer: { ...(initialValue || {}) },
  }) as [BufferStateType<T>, React.Dispatch<BufferActionType<T>>];

  const [metaState, metaDispatch] = React.useReducer(metaReducer, {
    working: false,
    errorMessage: null,
    dirty: false,
  });

  React.useEffect(() => {
    if (!isDirty || !isMounted) {
      return;
    }

    const dirty = isDirty({ buffer: bufferState, meta: metaState });
    if (dirty === metaState.dirty) {
      return;
    }

    if (dirty) {
      metaDispatch({ type: "SET_DIRTY" });
    } else {
      metaDispatch({ type: "UNSET_DIRTY" });
    }
  }, [isMounted, isDirty, bufferState, metaState]);

  const save = React.useCallback(async () => {
    try {
      metaDispatch({ type: "SET_WORKING" });
      metaDispatch({ type: "SET_ERROR_MESSAGE", message: null });

      let item;
      if (onSave) {
        item = await onSave({ buffer: bufferState, meta: metaState });
      } else {
        item = bufferState.buffer;
      }
      if (isMounted.current && item) {
        bufferDispatch({
          type: "FLUSH",
          values: merge({}, bufferState.buffer, item),
        });
        metaDispatch({ type: "UNSET_DIRTY" });
      }
    } catch (e) {
      const data = e?.response?.data;
      const message = Array.isArray(data)
        ? data.join(" ")
        : "There was a problem saving, please try again later.";

      metaDispatch({ type: "SET_ERROR_MESSAGE", message });
      throw e;
    } finally {
      if (isMounted.current) {
        metaDispatch({ type: "UNSET_WORKING" });
      }
    }
  }, [onSave, bufferState, metaState]);

  return (
    <Meta.Provider value={[metaState, metaDispatch]}>
      <Buffer.Provider value={[bufferState, bufferDispatch]}>
        <Save.Provider value={save}>{children}</Save.Provider>
      </Buffer.Provider>
    </Meta.Provider>
  );
}
