import { DateInput } from "components/DateInput";
import { DecimalInput } from "components/NumberInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import * as React from "react";
import { DateSchema, DecimalSchema } from "utils/validators/yup";
import { object, ref } from "yup";

export const LoanDetailsSchema = object({
  loanDate: DateSchema.dateNotFuture(
    "Loan date can’t be in the future."
  ).required("Please enter the date this loan was issued."),
  lastPaymentDate: DateSchema.dateMin(
    ref("loanDate"),
    "Last payment date should be after the loan date."
  )
    .dateNotFuture("Last payment date can’t be in the future.")
    .optional()
    .nullable(),
  disbursedAmount: DecimalSchema.required(
    "Please enter the amount of money you received for this loan."
  ),
  outstandingPrincipal: DecimalSchema.required(
    "Please enter the amount of principal you have left to pay off on this loan."
  ),
  outstandingInterest: DecimalSchema.required(
    "Please enter the amount of interest you have left to pay off on this loan."
  ),
  monthlyPayment: DecimalSchema.required(
    "Please enter your monthly payment for this loan."
  ),
  delinquentAmount: DecimalSchema.optional().nullable(),
  interestRate: DecimalSchema.optional().nullable(),
}).required();

export function LoanDetails({ isEdit, navProps }: WizardRouteChildProps) {
  const gridFieldWidth = "155px";
  return (
    <>
      <div className="form-row">
        <DateInput name="loanDate" label="Loan date" autoFocus={!isEdit} />
        <DateInput name="lastPaymentDate" label="Last payment date" />
      </div>
      <p>What are the numbers associated with this loan?</p>
      <div className="bottom form-row">
        <DecimalInput
          name="disbursedAmount"
          label="Disbursed amount"
          addOnBefore="$"
          width={gridFieldWidth}
          allowDecimal
          includeThousandsSeparator
        />
        <DecimalInput
          name="outstandingPrincipal"
          label="Outstanding principal"
          addOnBefore="$"
          width={gridFieldWidth}
          allowDecimal
          includeThousandsSeparator
        />
        <DecimalInput
          name="outstandingInterest"
          label="Outstanding interest"
          addOnBefore="$"
          width={gridFieldWidth}
          allowDecimal
          includeThousandsSeparator
        />
        <DecimalInput
          name="monthlyPayment"
          label="Monthly payment"
          addOnBefore="$"
          width={gridFieldWidth}
          allowDecimal
          includeThousandsSeparator
        />
        <DecimalInput
          name="delinquentAmount"
          label="Overdue amount"
          addOnBefore="$"
          width={gridFieldWidth}
          allowDecimal
          includeThousandsSeparator
        />
        <DecimalInput
          name="interestRate"
          label="Interest rate"
          addOnAfter="%"
          width={gridFieldWidth}
          integerLimit={3}
          decimalLimit={3}
          allowDecimal
        />
      </div>

      <WizardNavigation {...navProps} />
    </>
  );
}
