import cn from "classnames";
import { isNull } from "lodash";
import React, { HTMLProps, ReactNode } from "react";
import ContentLoader from "react-content-loader";
import css from "./ModuleSummary.module.scss";

interface Props extends HTMLProps<HTMLDivElement> {
  requiredData: any;
  maxLines: number;
  maxWidth?: string;
  children: ReactNode;
}

export default function LinesSummary({
  requiredData,
  maxLines,
  children,
  maxWidth = "100%",
  className,
  ...etc
}: Props) {
  const loading = typeof requiredData === "undefined";

  if (isNull(requiredData)) return null;

  return (
    <div {...etc} className={cn(className, css.linesSummary)}>
      {loading
        ? new Array(maxLines).fill(0).map((_, i) => (
            <ContentLoader
              key={i}
              speed={2}
              width={100}
              height={100}
              preserveAspectRatio="none"
              style={{
                width: "100%",
                height: "1.1em",
                margin: "0.2em auto",
                maxWidth,
                display: "block",
              }}
              viewBox="0 0 100 100"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="3" ry="3" width="100%" height="100" />
            </ContentLoader>
          ))
        : children}
    </div>
  );
}
