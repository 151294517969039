import { TextInput } from "components/TextInput";
import React from "react";
import DataTable, {
  IDataTableColumn,
  IDataTableStyles,
} from "react-data-table-component";

const FilterComponent = ({
  searchPlaceholderText,
  filterText,
  onFilter,
}: {
  searchPlaceholderText: string;
  filterText: string;
  onFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <TextInput
    placeholder={searchPlaceholderText}
    value={filterText}
    onChange={onFilter}
  />
);

export interface GridProps<T = any> {
  data: T[];
  columns: IDataTableColumn[];
  customStyles: IDataTableStyles;
  searchPlaceholderText: string;
  filterRecord: (record: T, searchText: string) => boolean;
  onRowsSelected?: (rows: T[]) => void;
  handleRowClicked?: (row: T) => void;
  clearSelectedRows?: boolean; // Stupid. See docs.
  subheaderElement?: React.ReactNode;
}

export default function DataGrid<T>({
  data,
  columns,
  customStyles,
  searchPlaceholderText,
  filterRecord,
  onRowsSelected,
  handleRowClicked,
  clearSelectedRows,
  subheaderElement = null,
}: GridProps<T>) {
  const [filterText, setFilterText] = React.useState("");
  const [filteredData, setFilteredData] = React.useState(data);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="flex items-start justify-end w-full">
        {subheaderElement}
        <div>
          <FilterComponent
            searchPlaceholderText={searchPlaceholderText}
            onFilter={(event) => setFilterText(event.target.value)}
            filterText={filterText}
          />
        </div>
      </div>
    );
  }, [filterText, searchPlaceholderText, subheaderElement]);

  React.useEffect(() => {
    if (data) {
      setFilteredData(
        data.filter((record: T) => filterRecord(record, filterText))
      );
    }
  }, [data, filterText, filterRecord]);

  const handleSelectedRowsChange = (state: any) => {
    if (onRowsSelected) {
      onRowsSelected(state.selectedRows);
    }
  };

  return (
    <div className={"datagrid"}>
      <DataTable
        data={filteredData}
        columns={columns}
        pagination={true}
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationPerPage={20}
        selectableRows={onRowsSelected ? true : false}
        onSelectedRowsChange={handleSelectedRowsChange}
        clearSelectedRows={clearSelectedRows}
        noHeader={true}
        subHeader={true}
        subHeaderComponent={subHeaderComponentMemo}
        customStyles={customStyles}
        highlightOnHover={true}
        dense={true}
        onRowClicked={handleRowClicked}
      />
    </div>
  );
}
