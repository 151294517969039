import { GovernmentDisbursementCategoryChoice } from "globalTypes";
import React from "react";
import { FaDonate } from "react-icons/fa";
import { DisbursementsCategoryDetail } from "../CategorySection/CategoryDetail";

export const foodshareCategoryDetail: DisbursementsCategoryDetail = {
  category: GovernmentDisbursementCategoryChoice.Foodshare,
  displayName: "Foodshare",
  displayLowercaseName: "foodshare",
  hasNoProfileBooleanName: "hasNoFoodshare",
  singleInterrogativeText:
    "Please upload a copy of your most recent award letter.",
  urlPathName: "foodshare",
  menuIcon: FaDonate,
  description: (
    <>
      {/* <p>
        Have you received any Foodshare benefits for you or members of your
        household?
      </p>
      <div>
        If so,{" "}
        <span className="font-semibold">
          please upload a copy of your most recent award letter.
        </span>
      </div> */}
    </>
  ),
};
