import { useQuery } from "@apollo/client";
import { useFilingProfileBooleanMutation } from "api/graphql/FilingProfile";
import { BooleanInput } from "components/BooleanInput";
import { ButtonRow } from "components/ButtonRow";
import { Form } from "components/Form";
import { LearnMore } from "components/LearnMore";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import { WizardNavigation } from "components/WizardNavigation";
import { FaArrowRight, FaPencilAlt } from "react-icons/fa";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { formatName } from "utils/formatters";
import { useNested } from "utils/hooks";
import { boolean, object } from "yup";
import COFILER_MODULE from "./CofilerModule.gql";

export interface Props {
  prev: string;
  next: string;
}

// TODO: reinstante the schema when we figure out how to do it cleanly
// const CofilerSchema = object()
//   .shape({
//     hasNoCofiler: boolean().required("This question is required."),
//   })
//   .required();

export function Cofiler({ prev, next }: Props) {
  const history = useHistory();
  const { url } = useNested();

  const { data, loading } = useQuery<CofilerModule>(COFILER_MODULE);
  const [updateProfile] = useFilingProfileBooleanMutation("hasNoCofiler");

  if (loading) return <Loading />;

  return (
    <>
      <Title>Joint Filing</Title>
      <p>
        Married couples can file together in a joint bankruptcy that combines
        property and debts into the same bankruptcy case. A couple does not have
        to file together, you can choose to file separate cases or to have only
        one person file.
      </p>
      <LearnMore>
        <h4>
          Can I file with someone other than my spouse, such as a divorced
          partner?
        </h4>
        <p>
          The rules vary throughout the country - contact your lawyer to answer
          this question.
        </p>
      </LearnMore>
      {!data.familyProfile?.maritalStatus ||
        (["UNMARRIED", "WIDOWED"].includes(
          data.familyProfile.maritalStatus
        ) && (
          <>
            <p>
              <strong>
                The information you have provided so far about your marital
                status indicates that you cannot jointly file for bankruptcy.
              </strong>
            </p>
            <ButtonRow
              left={
                <Link
                  to={url("../../about-you/partner")}
                  className="btn secondary"
                >
                  <FaPencilAlt />
                  <span>Update Marital Status</span>
                </Link>
              }
              right={
                <Link to={next} className="btn primary">
                  <span>Continue</span>
                  <FaArrowRight />
                </Link>
              }
            />
          </>
        )) || (
          <div>
            <Form
              // schema={CofilerSchema}
              onSubmit={async (formState) => {
                await updateProfile(formState.hasNoCofiler);
                if (next) {
                  history.push(next);
                }
              }}
              initialValue={data.filingProfile}
            >
              <BooleanInput
                name="hasNoCofiler"
                noLabel="Yes"
                yesLabel="No"
                label={`Do you want to file for bankruptcy with ${formatName(
                  data.familyProfile.spouse
                )}?`}
                required="Please choose yes or no."
              />
              <WizardNavigation backURL={prev} isComplete={true} />
            </Form>
          </div>
        )}
    </>
  );
}
