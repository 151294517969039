import { useEditorBuffer } from "components/EditorState/hooks";
import { WizardRouteChildProps } from "components/Wizard";
import CollectorsWizardStep from "components/WizardSteps/Collectors";
import React from "react";

export default function Collectors(props: WizardRouteChildProps) {
  const [{ buffer }] = useEditorBuffer();
  const vehicleLoanID = buffer.id;

  return (
    <CollectorsWizardStep
      relatedId={vehicleLoanID}
      relatedTypename="VehicleLoan"
      {...props}
    />
  );
}
