import { useQuery } from "@apollo/client";
import { SubModuleSummaryProps } from "components/FormModuleSubMenu/SubmenuModule";
import ModuleSummary, {
  HasNoSummary,
  SummaryStatsRow,
} from "components/ModuleSummary";
import NumericSummary from "components/ModuleSummary/NumericSummary";
import React from "react";
import PREVIOUS_NAMES_MODULE from "./PreviousNamesModule.gql";

export default function PreviousNamesSummary(props: SubModuleSummaryProps) {
  const { data } = useQuery<PreviousNamesModule>(PREVIOUS_NAMES_MODULE, {
    returnPartialData: true,
  });

  return (
    <ModuleSummary {...props} progress={data?.progress.previousNames}>
      {data?.filingProfile?.hasNoPreviousNames ? (
        <HasNoSummary>
          You&rsquo;ve never legally changed your name.
        </HasNoSummary>
      ) : (
        <SummaryStatsRow>
          <NumericSummary
            label="name change"
            maxValueLength={1}
            value={data?.previousNames?.length}
            pluralizeLabel
          />
        </SummaryStatsRow>
      )}
    </ModuleSummary>
  );
}
