import React from "react";
import { RetirementAccountLoanWizard } from "./RetirementAccountLoanWizard";

export default function CreateView({ listURL }: { listURL: string }) {
  return (
    <RetirementAccountLoanWizard
      title="Add a Retirement Account Loan"
      backURL={listURL}
      nextURL={listURL}
    />
  );
}
