import { useUpsertOtherDebt } from "api/graphql/OtherDebt";
import { CollectorForm } from "components/FormSections/Collector";
import { CosignerForm } from "components/FormSections/Cosigner";
import { Wizard } from "components/Wizard";
import { CollectorsSchema } from "components/WizardSteps/Collectors";
import { CosignersSchema } from "components/WizardSteps/Cosigners";
import React, { useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useNested } from "utils/hooks";
import Collectors from "./Collectors";
import Cosigners from "./Cosigners";
import Details, { DetailsSchema } from "./Details";
import Lender, { LenderSchema } from "./Lender";
import OTHER_DEBT_WIZARD_OTHER_DEBT_UPSERT from "./OtherDebtWizardOtherDebtUpsert.gql";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
}

export const OtherDebtSchema = LenderSchema.concat(DetailsSchema)
  .concat(CosignersSchema)
  .concat(CollectorsSchema);

export function OtherDebtWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: Props) {
  const { path, url } = useNested();
  const [upsertOtherDebt] = useUpsertOtherDebt<
    OtherDebtWizardOtherDebtUpsert,
    OtherDebtWizardOtherDebtUpsertVariables
  >(OTHER_DEBT_WIZARD_OTHER_DEBT_UPSERT);
  const history = useHistory();
  const [otherDebtId, setOtherDebtId] = useState(initialValue?.id);

  const cosignersURL = "/cosigners";
  const collectorsURL = "/collectors";

  return (
    <Switch>
      <Route
        path={path("/create_cosigner")}
        render={() => (
          <CosignerForm
            title="Add a Cosigner"
            returnURL={url(cosignersURL)}
            relatedId={otherDebtId}
            relatedTypename="OtherDebt"
          />
        )}
      />
      <Route
        path={path("/edit_cosigner/:cosignerId")}
        render={({ match }) => (
          <CosignerForm
            title="Updating Cosigner"
            returnURL={url(cosignersURL)}
            relatedId={otherDebtId}
            relatedTypename="OtherDebt"
            id={match.params.cosignerId}
          />
        )}
      />

      <Route
        path={path("/create_collector")}
        render={() => (
          <CollectorForm
            title="Add a Debt Collector"
            returnURL={url(collectorsURL)}
            relatedId={otherDebtId}
            relatedTypename="OtherDebt"
          />
        )}
      />
      <Route
        path={path("/edit_collector/:collectorId")}
        render={({ match }) => (
          <CollectorForm
            title="Updating Debt Collector"
            returnURL={url(collectorsURL)}
            relatedId={otherDebtId}
            relatedTypename="OtherDebt"
            id={match.params.collectorId}
          />
        )}
      />

      <Wizard
        title={title}
        backURL={backURL}
        nextURL={nextURL}
        initialValue={initialValue}
        vertical
        steps={[
          {
            path: "/lender",
            title: "Lender",
            component: Lender,
            schema: LenderSchema,
          },
          {
            path: "/details",
            title: "Debt details",
            component: Details,
            schema: DetailsSchema,
            async onSave(formState) {
              const { data } = await upsertOtherDebt(formState);
              const { id } = data.otherDebt;
              setOtherDebtId(id);
              setTimeout(() => {
                if (otherDebtId) {
                  history.push(url(cosignersURL));
                } else {
                  history.push(`../edit/${id}${cosignersURL}`);
                }
              }, 0);
              return data.otherDebt;
            },
          },
          {
            path: cosignersURL,
            title: "Cosigners",
            component: Cosigners,
            schema: CosignersSchema,
          },
          {
            path: collectorsURL,
            title: "Collectors",
            component: Collectors,
            schema: CollectorsSchema,
          },
        ]}
      />
    </Switch>
  );
}
