import { SubModule } from "components/FormModuleSubMenu/SubmenuModule";
import { FaBriefcase } from "react-icons/fa";
import { Lawsuits } from "./Lawsuits";
import LawsuitsSummary from "./Summary";

const LawsuitsSubmodule: SubModule = {
  path: "/lawsuits",
  icon: FaBriefcase,
  title: "Lawsuits",
  component: Lawsuits,
  summary: LawsuitsSummary,
  statusName: "lawsuits",
};

export default LawsuitsSubmodule;
