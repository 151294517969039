import { useGetBorrowedAsset } from "api/graphql/BorrowedAsset";
import BorrowedAssetWizard from "components/FormModules/Assets/BorrowedAssets/BorrowedAssetWizard";
import { Loading } from "components/Loading";
import React from "react";
import { Redirect } from "react-router-dom";

export function EditView({ id, listURL }: { id: UUID; listURL: string }) {
  const { data, loading } = useGetBorrowedAsset(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.borrowedAsset) {
    return <Redirect to={listURL} />;
  }

  const { owner } = data.borrowedAsset;

  return (
    <BorrowedAssetWizard
      title="Updating Borrowed Assets"
      backURL={listURL}
      nextURL={listURL}
      initialValue={{ ...data.borrowedAsset, ownerId: owner.id }}
    />
  );
}
