import { LearnMore } from "components/LearnMore";
import { MonthlyExpenseCategoryChoice } from "globalTypes";
import React from "react";
import { FaBowlingBall } from "react-icons/fa";
import { ExpensesCategoryDetail } from "../CategorySection/CategoryDetail";
import { LearnMoreCommon } from "../LearnMoreCommon";

export const recreationCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.Recreation,
  displayName: "Recreation & Entertainment",
  displayLowercaseName: "recreation",
  hasNoProfileBooleanName: "hasNoRecreationExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on recreational expenses over the last six months? Be sure to include spending for all household members.",
  urlPathName: "recreation_and_entertainment",
  menuIcon: FaBowlingBall,
  description: (
    <>
      <p>
        Recreational and entertainment expenses include but are not limited to:
      </p>
      <ul>
        <li>Movie streaming service</li>
        <li>Theater tickets</li>
        <li>Camping costs</li>
        <li>Newspaper subscriptions</li>
        <li>Books</li>
      </ul>

      <p>
        <LearnMore>
          <h4>Entertainment expenses are allowed in bankruptcy?</h4>
          <p>
            Yes. The bankruptcy trustee will evaluate to determine if they are
            reasonable.
          </p>

          <LearnMoreCommon />
        </LearnMore>
      </p>
    </>
  ),
};
