import { clamp } from "lodash";
import * as React from "react";
import { fuzzyFindIndex } from "utils/math";

export function useKeyboardControl({
  value,
  ticks,
  onChange,
}: {
  value: number;
  ticks: number[];
  onChange: (
    value: number,
    direction?: "decreasing" | "increasing",
    event?: KeyboardEvent
  ) => void;
}) {
  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      const currentIndex = fuzzyFindIndex(ticks, value);

      const nextIndex =
        currentIndex === -1
          ? ticks.length - 1
          : clamp(currentIndex + 1, 0, ticks.length - 1);
      const nextTick = ticks[nextIndex];

      const prevIndex = clamp(currentIndex - 1, 0, ticks.length - 1);
      const prevTick = ticks[prevIndex];

      switch (e.key) {
        case "ArrowLeft":
        case "ArrowDown":
          e.preventDefault();
          onChange(prevTick, "decreasing", e.nativeEvent);
          break;
        case "ArrowRight":
        case "ArrowUp":
          e.preventDefault();
          onChange(nextTick, "increasing", e.nativeEvent);
          break;
      }
    },
    [value, ticks, onChange]
  );
  return { onKeyDown };
}

export function useDragControl({
  ref,
  onChange,
  onActive,
}: {
  ref: React.MutableRefObject<HTMLElement>;
  onChange: (pixelValue: number, pixelExtent: [number, number]) => void;
  onActive?: (isActive: boolean) => void;
}) {
  const [pressActive, setPressActive] = React.useState(false);

  const lastX = React.useRef<number>(null);
  React.useEffect(() => {
    lastX.current = null;
  }, [onChange]);

  const onDrag = React.useMemo(
    () => (e: MouseEvent | TouchEvent) => {
      if (!ref.current) return;
      e.preventDefault();

      const { clientX: xCoord } = e instanceof MouseEvent ? e : e.touches[0];
      const { left, right } = ref.current.getBoundingClientRect();

      if (lastX.current !== xCoord) {
        lastX.current = xCoord;
        onChange(xCoord, [left, right]);
      }
    },
    [onChange, onActive]
  );

  React.useEffect(() => {
    if (pressActive) {
      document.addEventListener("mousemove", onDrag);
      document.addEventListener("touchmove", onDrag);
    }

    return () => {
      document.removeEventListener("mousemove", onDrag);
      document.removeEventListener("touchmove", onDrag);
    };
  }, [onDrag, pressActive]);

  const onRelease = React.useCallback(() => {
    onActive?.(false);
    setPressActive(false);
  }, [onActive]);

  React.useEffect(() => {
    if (pressActive) {
      document.addEventListener("mouseup", onRelease);
      document.addEventListener("touchend", onRelease);
    }

    return () => {
      document.removeEventListener("mouseup", onRelease);
      document.removeEventListener("touchend", onRelease);
    };
  }, [onRelease, pressActive]);

  const onPress = React.useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      onActive?.(true);
      setPressActive(true);
      onDrag(e.nativeEvent);
    },
    [onDrag, onActive]
  );

  return { onMouseDown: onPress, onTouchStart: onPress };
}
