import { BooleanInput } from "components/BooleanInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { useFormContext } from "react-hook-form";
import { boolean, object } from "yup";

export const WithdrawalsSchema = object({
  hasNoMajorRecentWithdrawals: boolean().required("This field is required."),
}).required();

export type WithdrawalsFormState = ReturnType<
  typeof WithdrawalsSchema.validateSync
>;

export default function Withdrawals({
  isEdit,
  navProps,
}: WizardRouteChildProps) {
  const form = useFormContext<WithdrawalsFormState>();
  const hasNoMajorRecentWithdrawals = form.watch("hasNoMajorRecentWithdrawals");

  return (
    <>
      <div className="form-row">
        <BooleanInput
          name="hasNoMajorRecentWithdrawals"
          label="Have you made any non-regular withdrawals of over five hundred dollars in the last six months?"
          yesLabel="No"
          noLabel="Yes"
          helpText="Regular withdrawals include payments of expected expenses such as a mortgage or rent payments, vehicle loan payments,  and monthly or semi-annual vehicle insurance payments. If you only made these type of withdrawals over $500, answer no."
          defaultValue={hasNoMajorRecentWithdrawals}
        />
      </div>
      <WizardNavigation {...navProps} />
    </>
  );
}
