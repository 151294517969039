import { useUpsertStorageContainer } from "api/graphql/StorageContainer";
import { Wizard } from "components/Wizard";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Details, DetailsSchema } from "./Details";
import { Inventory, InventorySchema } from "./Inventory";

export const StorageContainerSchema = InventorySchema.concat(DetailsSchema);

interface StorageContainerWizardProps {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
  /** The URL to switch to after the initial save. Created object ID will be appended */
  editURL?: string;
  subtitle?: React.ReactElement;
}

export function StorageContainerWizard({
  backURL,
  nextURL,
  initialValue,
  title,
}: StorageContainerWizardProps) {
  const [upsertStorageContainer] = useUpsertStorageContainer();
  const history = useHistory();

  return (
    <Wizard
      title={title}
      subtitle={
        <p>
          <strong>
            Tell us about a single third party location where you store items of
            value.
          </strong>
        </p>
      }
      backURL={backURL}
      nextURL={nextURL}
      initialValue={initialValue}
      vertical
      onSave={async (formState) => {
        await upsertStorageContainer(formState);
        history.push(nextURL);
        return formState;
      }}
      steps={[
        {
          path: "/details",
          title: "Location Details",
          component: Details,
          schema: DetailsSchema,
        },
        {
          path: "/inventory",
          title: "Storage Inventory",
          component: Inventory,
          schema: InventorySchema,
        },
      ]}
    />
  );
}
