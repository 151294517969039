import { LearnMore } from "components/LearnMore";
import { MonthlyExpenseCategoryChoice } from "globalTypes";
import React from "react";
import { FaChild, FaGraduationCap } from "react-icons/fa";
import { ExpensesCategoryDetail } from "../CategorySection/CategoryDetail";
import { LearnMoreCommon } from "../LearnMoreCommon";

export const childCareCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.ChildCare,
  displayName: "Child Care",
  displayLowercaseName: "child care",
  hasNoProfileBooleanName: "hasNoChildCareExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on child care over the last six months? Be sure to include spending for all household members.",
  urlPathName: "dependent_care",
  menuIcon: FaChild,
  description: (
    <div>
      <p>Child care expenses include but are not limited to:</p>
      <ul>
        <li>Day care</li>
        <li>After school care</li>
        <li>Babysitting</li>
      </ul>

      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </div>
  ),
};

export const childEducationCategoryDetail: ExpensesCategoryDetail = {
  category: MonthlyExpenseCategoryChoice.ChildSchool,
  displayName: "Child Education",
  displayLowercaseName: "child education",
  hasNoProfileBooleanName: "hasNoChildEducationExpenses",
  singleInterrogativeText:
    "What was the average monthly amount you spent on child education over the last six months? Be sure to include spending for all household members.",
  urlPathName: "dependent_education",
  menuIcon: FaGraduationCap,
  description: (
    <div>
      <p>Child education expenses include but are not limited to:</p>
      <ul>
        <li>school supplies</li>
        <li>school fees</li>
        <li>tutoring</li>
      </ul>

      <p>
        You should <em>not</em> include:
      </p>
      <ul>
        <li>A dependent&rsquo;s college expenses</li>
        <li>A dependent&rsquo;s post-high school vocational expenses</li>
      </ul>

      <p>
        <LearnMore>
          <LearnMoreCommon />
        </LearnMore>
      </p>
    </div>
  ),
};
