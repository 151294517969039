import { IconBaseProps } from "react-icons/lib";
import { ServicePrepaymentTypeChoice } from "globalTypes";
import { FC, ReactNode } from "react";

type Detail = {
  type?: ServicePrepaymentTypeChoice;
  displayName: string;
  icon?: FC<IconBaseProps>;
  helpText?: ReactNode;
  descriptionInstructionText?: ReactNode;
};

export const servicePrepaymentDetails: {
  [key in ServicePrepaymentTypeChoice]: Detail;
} = {
  [ServicePrepaymentTypeChoice.Electric]: {
    displayName: "Electric utility",
  },
  [ServicePrepaymentTypeChoice.Gas]: {
    displayName: "Gas utility",
  },
  [ServicePrepaymentTypeChoice.HeatingOil]: {
    displayName: "Heating oil",
  },
  [ServicePrepaymentTypeChoice.RentalPrepaidRent]: {
    displayName: "Pre-paid rent",
  },
  [ServicePrepaymentTypeChoice.RentalSecurityDeposit]: {
    displayName: "Rental security deposit",
  },
  [ServicePrepaymentTypeChoice.RentedFurniture]: {
    displayName: "Rented furniture",
  },
  [ServicePrepaymentTypeChoice.Telephone]: {
    displayName: "Telephone/Internet utility",
  },
  [ServicePrepaymentTypeChoice.Water]: {
    displayName: "Water utility",
  },
  [ServicePrepaymentTypeChoice.PrepaidCard]: {
    displayName: "Prepaid credit card, gift card, or similar",
  },
  [ServicePrepaymentTypeChoice.Other]: {
    displayName: "Other security deposit or pre-payment",
  },
};
