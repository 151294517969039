import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import GET_LAW_FIRM from "./GetLawFirm.gql";
import UPDATE_LAW_FIRM from "./UpdateLawFirm.gql";

export function useGetLawFirm() {
  return useQuery<GetLawFirm>(GET_LAW_FIRM, {});
}

export function useUpdateLawFirm<
  Schema extends { lawFirm: any } = UpdateLawFirm,
  Variables = UpdateLawFirmVariables
>(query = UPDATE_LAW_FIRM) {
  const [doMutation] = useMutation<UpdateLawFirm, UpdateLawFirmVariables>(
    query
  );
  return React.useCallback(
    (input: LawFirmInput) => {
      return doMutation({
        variables: { input: input },
      });
    },
    [doMutation]
  );
}
