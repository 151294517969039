import { useApolloClient } from "@apollo/client";
import React, { useCallback } from "react";
import TypeAhead from "../TypeAhead";
import css from "./AutocompleteEducationalInstitution.module.scss";
import SEARCH_EDUCATIONAL_INSTITUTIONS from "./SearchEducationalInstitutions.gql";

interface Props {
  /** The CSS selector of the input to attach to. */
  inputSelector: string;
  /** The function to be called when a place suggestion is selected by the user. */
  onEducationalInstitutionSelected: (
    result: ArrayItemType<
      SearchEducationalInstitution["educationalInstitutions"]
    >
  ) => void;
}

export function formatEducationalInstitution(
  institution: ArrayItemType<
    SearchEducationalInstitution["educationalInstitutions"]
  >
) {
  const { address } = institution;
  return (
    <div>
      <span className={css.university}>{institution.displayName}</span>
      <br />
      {address && (
        <div className={css.address}>
          {!!address.line1 && address.line1}
          {!!address.line1 && !!address.line2 && (
            <>
              {", "}
              {address.line2}
            </>
          )}
          {!!address.line1 && <br />}
          {!!address.city && `${address.city}, `} {address.stateProvince}
          {!!address.zipCode && ` ${address.zipCode}`}
          <br />
          {address.country !== "U.S.A." && address.country}
        </div>
      )}
    </div>
  );
}

/** Renders a dropdown allowing users to autofill an educational institution based on an input's value.
 *
 * The picker UI will be rendered
 * under the input, not inside the component.
 */
export default function AutocompleteEducationalInstitution({
  inputSelector,
  onEducationalInstitutionSelected,
}: Props) {
  const client = useApolloClient();

  const getSuggestions = useCallback(
    async (query: string) => {
      const { data } = await client.query<
        SearchEducationalInstitution,
        SearchEducationalInstitutionVariables
      >({
        query: SEARCH_EDUCATIONAL_INSTITUTIONS,
        variables: { displayName: query },
      });

      return data.educationalInstitutions.slice(0, 10);
    },
    [client]
  );

  return (
    <TypeAhead
      inputSelector={inputSelector}
      getSuggestions={getSuggestions}
      onSelect={onEducationalInstitutionSelected}
      formatSuggestion={formatEducationalInstitution}
      footer={({ suggestions }) => (
        <div className={css.helpText}>
          {suggestions.length >= 10 && "Keep typing to refine your search.  "}
          If you cannot find your educational institution via search, just type
          in the name as best you know it.
        </div>
      )}
    />
  );
}
