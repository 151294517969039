import React from "react";
import ReactDom from "react-dom";
import ReactNotification, { store } from "react-notifications-component";

export default function initNotifications() {
  const notificationsRoot = document.getElementById("notifications-root");
  if (notificationsRoot) {
    ReactDom.render(<ReactNotification />, notificationsRoot);
  }

  if (
    location.pathname === "/core/authentication/login/" &&
    location.search.includes("reason=re-auth")
  ) {
    const id = "session-expired";
    store.removeNotification(id);
    store.addNotification({
      id,
      title: "Your Session Expired",
      message: "For your security, please log in again to continue.",
      container: "top-right",
      type: "info",
    });
  }
}
