import { MonthlyExpenseWizard } from "components/FormModules/Expenses/HouseholdExpenses/MonthlyExpenseWizard";
import React from "react";
import { ExpensesCategoryDetail } from "./CategoryDetail";

interface Props {
  listURL: string;
  categoryDetail: ExpensesCategoryDetail;
}

export default function CreateView(props: Props) {
  return (
    <MonthlyExpenseWizard
      title={`Add ${props.categoryDetail.displayName} Expense`}
      subtitle={props.categoryDetail.singleInterrogativeText}
      category={props.categoryDetail.category}
      backURL={props.listURL}
      nextURL={props.listURL}
    />
  );
}
