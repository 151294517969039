import { useUpsertOwnedBusiness } from "api/graphql/OwnedBusiness";
import {
  BusinessEmployeesSchema,
  BusinessExpendituresSchema,
  BusinessFacilitiesSchema,
  BusinessMiscExpensesSchema,
  BusinessTaxesSchema,
} from "components/FormModules/OwnedBusinesses/validations";
import { Wizard } from "components/Wizard";
import React from "react";
import Employees from "./Employees";
import Expenditures from "./Expenditures";
import Facilities from "./Facilities";
import Miscellaneous from "./Miscellaneous";
import Taxes from "./Taxes";

interface Props {
  backURL: string;
  nextURL: string;
  title?: string;
  initialValue?: any;
}

export function BusinessExpensesWizard({
  backURL,
  nextURL,
  initialValue,
  title = "Business Expenses",
}: Props) {
  const [upsert] = useUpsertOwnedBusiness();

  const onSave = async (values: Partial<OwnedBusinessInput>) => {
    const { data } = await upsert(values);
    return data.ownedBusiness;
  };

  return (
    <Wizard
      title={title}
      subtitle={
        initialValue?.businessName && `For ${initialValue.businessName}`
      }
      backURL={backURL}
      nextURL={nextURL}
      initialValue={initialValue}
      vertical
      steps={[
        {
          path: "/personnel",
          title: "Personnel",
          component: Employees,
          schema: BusinessEmployeesSchema,
          onSave,
          navigateAfterSave: true,
        },
        {
          path: "/facilities",
          title: "Facilities",
          component: Facilities,
          schema: BusinessFacilitiesSchema,
          onSave,
          navigateAfterSave: true,
        },
        {
          path: "/expenditures",
          title: "Regular Expenditures",
          component: Expenditures,
          schema: BusinessExpendituresSchema,
          onSave,
          navigateAfterSave: true,
        },
        {
          path: "/taxes",
          title: "Taxes",
          component: Taxes,
          schema: BusinessTaxesSchema,
          onSave,
          navigateAfterSave: true,
        },
        {
          path: "/misc",
          title: "Miscellaneous",
          component: Miscellaneous,
          schema: BusinessMiscExpensesSchema,
          onSave,
        },
      ]}
    />
  );
}
