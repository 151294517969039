import { useGetEnvironmentalCleanupNotice } from "api/graphql/EnvironmentalCleanupNotice";
import { Loading } from "components/Loading";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { EnvironmentalCleanupNoticeWizard } from "./EnvironmentalCleanupNoticeWizard";

export function EditView({
  id,
  listURL,
  ...props
}: {
  id: UUID;
  listURL: string;
}) {
  const { data, loading } = useGetEnvironmentalCleanupNotice(id);

  if (loading) {
    return <Loading />;
  } else if (!data?.environmentalCleanupNotice) {
    return <Redirect to={listURL} />;
  }

  return (
    <EnvironmentalCleanupNoticeWizard
      title={"Updating Cleanup Notice"}
      backURL={listURL}
      nextURL={listURL}
      initialValue={data.environmentalCleanupNotice}
    />
  );
}
