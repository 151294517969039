import React from "react";
import InputMask, { MaskedInputProps } from "react-text-mask";
import { mergeRefs } from "utils/mergeRefs";

export interface Props extends MaskedInputProps {
  inputRef?: React.Ref<unknown>;
}

/**
 * Renders a text input using `react-input-mask`.
 *
 * Note that we must use a class component to make it compatible with
 * `react-day-picker`'s DayPickerInput, which expects a class component
 * with a focus method when specifying a custom input component. You may
 * also use <InputMask /> directly in other scenarios.
 *
 * If you need to treat this like a regular input, feel free to add
 * proxy methods to the class.
 */
class MaskedInput extends React.Component<Props> {
  public static defaultProps = {
    guide: false,
    // Disable masking by default
    mask: () => false,
  };
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: MaskedInputProps) {
    super(props);
    this.inputRef = React.createRef<HTMLInputElement>();
  }

  render() {
    const { inputRef, keepCharPositions = true, ...otherProps } = this.props;
    return (
      <InputMask
        {...otherProps}
        keepCharPositions={keepCharPositions}
        render={(ref, props) => (
          <input {...props} ref={mergeRefs(inputRef, this.inputRef, ref)} />
        )}
      />
    );
  }

  focus() {
    this.inputRef.current.focus();
  }

  getBoundingClientRect() {
    return this.inputRef.current.getBoundingClientRect();
  }
}

const forwarded = React.forwardRef<any, MaskedInputProps>((props, ref) => (
  <MaskedInput {...props} inputRef={ref} />
));

export { forwarded as MaskedInput };
