import { useMutation } from "@apollo/client";
import { useDeleteEmploymentRecord } from "api/graphql/EmploymentRecord";
import { useGetOwnedBusiness } from "api/graphql/OwnedBusiness";
import cn from "classnames";
import { ButtonRow } from "components/ButtonRow";
import { Card } from "components/Card";
import { EmploymentWizard } from "components/FormModules/Income/Employment/EmploymentWizard";
import { renderEmploymentRecord } from "components/FormModules/Income/Employment/ListView";
import BusinessExpensesSummary from "components/FormModules/OwnedBusinesses/BusinessExpensesSummary";
import { BusinessExpensesWizard } from "components/FormModules/OwnedBusinesses/BusinessExpensesWizard";
import OwnedBusinessForm from "components/FormModules/OwnedBusinesses/OwnedBusinessForm";
import {
  OwnedBusinessExpensesSchema,
  OwnedBusinessSchema,
} from "components/FormModules/OwnedBusinesses/validations";
import { ListItem } from "components/ListItem";
import { Loading } from "components/Loading";
import { Title } from "components/Title";
import gql from "graphql-tag";
import { find } from "lodash";
import React from "react";
import { FaArrowLeft, FaCheck, FaPencilAlt, FaPlus } from "react-icons/fa";
import { useHistory } from "react-router";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { formatFancyNumber } from "utils/formatters";
import { useNested } from "utils/hooks";
import { unnestIds } from "utils/ids";
import { parseFancyNumber } from "utils/validators";
import css from "./OwnedBusinesses.module.scss";

interface Props {
  id: string;
  listURL: string;
}

export default function EditView({ id, listURL }: Props) {
  const { data } = useGetOwnedBusiness(id);
  const { path, url } = useNested();
  const history = useHistory();
  const [deleteSalary] = useDeleteEmploymentRecord();
  const [markNoSalaries, { loading }] = useMutation(gql`
    mutation MarkNoSalaries($id: UUID!) {
      markNoOwnedBusinessSalaries(id: $id) {
        id
        hasNoPersonalSalaries
      }
    }
  `);

  if (!data) return <Loading />;
  if (!data.ownedBusiness) return <Redirect to={listURL} />;

  const {
    businessName,
    percentOwnership,
    lastTwelveMonthsGrossIncome,
    expectedGrossMonthlyIncome,
    hasNoPersonalSalaries,
    personalSalaries,
  } = data.ownedBusiness;

  const expensesComplete = OwnedBusinessExpensesSchema.isValidSync(
    data.ownedBusiness
  );
  const pastMonthlyRevenue = parseFancyNumber(lastTwelveMonthsGrossIncome) / 12;

  return (
    <Switch>
      <Route
        path={path("/")}
        exact
        render={() => (
          <>
            <Card style={{ marginBottom: "16px" }}>
              <div className="button-header">
                <h2>{businessName}</h2>
                <Link
                  to={url("/details")}
                  title="Edit business details"
                  className="btn"
                  style={{ flexShrink: 0 }}
                >
                  <FaPencilAlt size="16px" />
                </Link>
              </div>

              <p>{percentOwnership.replace(/\.?0+$/, "")}% owned by you</p>
              <table className={css.businessTable}>
                <tbody>
                  <tr>
                    <th>Past monthly revenue</th>
                    <td>${formatFancyNumber(pastMonthlyRevenue)}</td>
                  </tr>
                  <tr>
                    <th>Expected future monthly revenue</th>
                    <td>${expectedGrossMonthlyIncome}</td>
                  </tr>
                </tbody>
              </table>

              <hr />

              <div className="button-header">
                <h3>Monthly Expenses</h3>
                {expensesComplete && (
                  <Link
                    to={url("/expenses")}
                    title="Edit business expenses"
                    className="btn"
                    style={{ flexShrink: 0 }}
                  >
                    <FaPencilAlt size="16px" />
                  </Link>
                )}
              </div>

              {expensesComplete ? (
                <BusinessExpensesSummary business={data.ownedBusiness} />
              ) : (
                <>
                  <p>We need to know about this business&rsquo;s expenses.</p>
                  <Link to={url("/expenses")} className="primary btn">
                    <FaPlus />
                    <span>Add expenses</span>
                  </Link>
                </>
              )}

              {expensesComplete && (
                <>
                  <hr />

                  <div className="button-header">
                    <h3>Salaries</h3>
                    {(hasNoPersonalSalaries || personalSalaries.length > 0) && (
                      <Link
                        to={url("/add_income")}
                        title="Add personal salaries"
                        className="btn"
                        style={{ flexShrink: 0 }}
                      >
                        <FaPlus size="16px" />
                      </Link>
                    )}
                  </div>
                  {personalSalaries.length ? (
                    <div>
                      <p>
                        If multiple people in your household receive salaries
                        from this business, please add them below.
                      </p>
                      {personalSalaries.map((item) => {
                        return (
                          <ListItem
                            key={item.id}
                            onDelete={() => deleteSalary(item.id)}
                            onEdit={() =>
                              history.push(url(`/edit_income/${item.id}`))
                            }
                          >
                            {renderEmploymentRecord(item)}
                          </ListItem>
                        );
                      })}
                    </div>
                  ) : hasNoPersonalSalaries ? (
                    <p>
                      You have indicated that nobody in your household draws a
                      salary from this business.
                    </p>
                  ) : (
                    <>
                      <p>
                        Does anyone in your household draw a salary from this
                        business?
                      </p>
                      <ButtonRow
                        noMargin
                        left={
                          <button
                            className={cn("btn secondary block", { loading })}
                            onClick={() =>
                              markNoSalaries({
                                variables: { id },
                              })
                            }
                          >
                            No
                          </button>
                        }
                        right={
                          <Link
                            className="block primary btn"
                            to={url("/add_income")}
                          >
                            Yes
                          </Link>
                        }
                      />
                    </>
                  )}
                </>
              )}
            </Card>

            {OwnedBusinessSchema.isValidSync(data.ownedBusiness) ? (
              <Link to={listURL} className="block primary btn">
                <span>Done</span>
                <FaCheck />
              </Link>
            ) : (
              <Link to={listURL} className="btn">
                <FaArrowLeft />
                <span>Back</span>
              </Link>
            )}
          </>
        )}
      />

      <Route
        path={path("/details")}
        render={() => (
          <>
            <Title>Updating Owned Business</Title>
            <OwnedBusinessForm
              backURL={url("/")}
              onDone={() => history.push(url("/"))}
              initialValue={data.ownedBusiness}
            />
          </>
        )}
      />

      <Route
        path={path("/expenses")}
        render={() => (
          <BusinessExpensesWizard
            backURL={url("/")}
            nextURL={url("/")}
            initialValue={data.ownedBusiness}
          />
        )}
      />

      <Route
        path={path("/add_income")}
        render={() => (
          <EmploymentWizard
            title={"Add personal salary"}
            subtitle={`From ${businessName}`}
            backURL={url("/")}
            nextURL={url("/")}
            initialValue={{ companyName: businessName, ownedBusinessId: id }}
            editURL="edit_income"
          />
        )}
      />
      <Route
        path={path("/edit_income/:id")}
        render={({ match }) => {
          const employmentRecord = find(
            personalSalaries,
            (er) => er.id === match.params.id
          );
          return employmentRecord ? (
            <EmploymentWizard
              title={"Update personal salary"}
              subtitle={`From ${businessName}`}
              backURL={url("/")}
              nextURL={url("/")}
              initialValue={unnestIds(employmentRecord)}
            />
          ) : (
            <Redirect to={url("/")} />
          );
        }}
      />
    </Switch>
  );
}
