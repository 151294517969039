import AccountMaskInput from "components/AccountMaskInput";
import { MonthInput } from "components/MonthInput";
import { DecimalInput } from "components/NumberInput";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React from "react";
import { DateSchema, DecimalSchema } from "utils/validators/yup";
import { object, ref, string } from "yup";

export const AccountSchema = object({
  accountCreationMonth: DateSchema.dateNotFuture(
    "Account creation month must be this month or before."
  )
    .required("This field is required.")
    .nullable(),
  accountClosureMonth: DateSchema.dateNotFuture(
    "Account closure month must be this month or before."
  )
    .dateMin(
      ref("accountCreationMonth"),
      "The account closure month can't be before the account opening month."
    )
    .nullable(),
  accountNumber: string()
    .required("This field is required.")
    .min(4, "You must provide the last four characters of the account number.")
    .max(4, "You must provide the last four characters of the account number."),
  beneficiary: string().optional().nullable(),
  currentValue: DecimalSchema.required("This field is required."),
}).required();

export default function Account({ isEdit, navProps }: WizardRouteChildProps) {
  return (
    <>
      <div className="form-row">
        {/* @ts-ignore Switch out text-mask to something up to date */}
        <AccountMaskInput name="accountNumber" autoFocus={!isEdit} />
      </div>

      <div className="form-row">
        <MonthInput
          name="accountCreationMonth"
          label="Account opened month"
          width="200px"
        />
        <MonthInput
          name="accountClosureMonth"
          label="Account closed month"
          width="200px"
          helpText="Leave blank if this account is still open"
        />
      </div>

      <div className="form-row">
        <DecimalInput
          name="currentValue"
          label="Current balance"
          addOnBefore="$"
          includeThousandsSeparator
          width="200px"
          allowDecimal
        />
        <TextInput
          name="beneficiary"
          label="Beneficiary"
          helpText="Leave this blank if you haven’t designated a beneficiary."
          width="250px"
          maxLength={64}
        />
      </div>

      <WizardNavigation {...navProps} />
    </>
  );
}
